import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useNavigate } from './useNavigate';

export function useInMaintenance() {
    const navigate = useNavigate();
    const { inMaintenanceMode } = useFlags();

    useEffect(() => {
        if (inMaintenanceMode) {
            navigate({
                to: '/maintenance',
                search: {
                    ol: window.location.href,
                },
            });
        }
    }, [inMaintenanceMode]);
}
