import { CheckCircleOutlined, DeleteOutlined, MoreOutlined, ReloadOutlined } from '@ant-design/icons';
import { CollapsiblePanel } from '@client/components/Common/CollapsiblePanel';
import { UploadButton } from '@client/components/Common/Documents/UploadButton';
import { errorMessage } from '@client/components/Common/errorMessage';
import { Link } from '@client/components/Common/Link';
import { message } from '@client/components/Common/message';
import { modal } from '@client/components/Common/modal';
import { errorNotification } from '@client/components/Common/Notification/errorNotification';
import { User } from '@client/components/Common/User';
import { EmailDetailsAction, EmailDetailsDrawer } from '@client/components/EmailClassification/EmailDetailsDrawer';
import {
    EmailFilterButton,
    EmailFilterForm,
    EmailFiltersForm,
} from '@client/components/EmailClassification/EmailFilters';
import { PageContent } from '@client/components/Layout/PageContent';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useOrg } from '@client/hooks/Org/useOrg';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUser } from '@client/hooks/User/useUser';
import { useTrpc } from '@client/hooks/useTrpc';
import { useZodState } from '@client/hooks/useZodState';
import { sleep } from '@client/utils/general';
import { S3File } from '@shared/types/S3File';
import { capitalize } from '@shared/utils/string';
import { createFileRoute } from '@tanstack/react-router';
import {
    Badge,
    Button,
    Dropdown,
    Empty,
    Form,
    MenuProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { canDeleteEmailClassification } from 'server/permissions/emailClassification';
import { listEmailClassificationInputSchema } from 'server/schemas/emailClassification';
import { CONFIDENCE_MAP, EmailClassification, ISSUE_TYPE_MAP } from './config';

export const Route = createFileRoute('/console/$orgId/tools/auto-detect/inbox')({
    staticData: {
        breadcrumb: {
            title: 'Inbox',
        },
    },
    component: Inbox,
});

function Inbox() {
    const orgId = useOrgId();
    const user = useUser();
    const org = useOrg();
    const { trpcUtils, trpc } = useTrpc();
    const { mutateAsync: deleteEmailClassification } = trpc.emailClassification.deleteEmailClassification.useMutation();
    const { mutateAsync: rerunClassification } = trpc.emailClassification.rerunClassification.useMutation();
    const { mutateAsync: processEmail } = trpc.emailClassification.processEmailFile.useMutation();
    const [selectedAiModel, setSelectedAiModel] = useState<bigint | undefined>(undefined);
    const [queryParams, setQueryParams] = useZodState(listEmailClassificationInputSchema);
    const { formatDateTime } = useFormatter();
    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState(false);
    const [emailDetailsAction, setEmailDetailsAction] = useState<EmailDetailsAction>(['hide']);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [form] = Form.useForm<EmailFiltersForm>();

    const { data, isLoading, refetch } = trpc.emailClassification.listEmailClassification.useQuery(queryParams, {
        refetchInterval: 1000 * 5,
    });

    const { data: tags } = trpc.emailClassification.loadTags.useQuery();
    const { data: aiModels, isLoading: isLoadingAiModels } = trpc.emailClassification.loadAiModels.useQuery();

    useEffect(() => {
        if (aiModels?.length) {
            setSelectedAiModel(aiModels[0].id);
        }
    }, [aiModels]);

    function onSearch() {
        const values = form.getFieldsValue();
        const conditions: NonNullable<typeof queryParams.where>[] = [];

        if (values.issueType?.length) {
            conditions.push({ issueType: { in: values.issueType } });
        }

        if (values.status?.length) {
            conditions.push({ status: { in: values.status } });
        }

        if (values.searchText) {
            conditions.push({
                OR: [
                    { subject: { contains: values.searchText } },
                    { bodyText: { contains: values.searchText } },
                    { from: { array_contains: values.searchText } },
                    { to: { array_contains: values.searchText } },
                    { cc: { array_contains: values.searchText } },
                ],
            });
        }

        if (values.isBreach !== undefined) {
            conditions.push({ isBreachOfRegulation: values.isBreach });
        }

        if (values.dateRange?.[0]) {
            conditions.push({
                uploadedAt: {
                    gte: DateTime.fromJSDate(values.dateRange[0]).startOf('day').toJSDate(),
                },
            });
        }

        if (values.dateRange?.[1]) {
            conditions.push({
                uploadedAt: {
                    lte: DateTime.fromJSDate(values.dateRange[1]).endOf('day').toJSDate(),
                },
            });
        }

        if (values.tags?.length) {
            const tagConditions = values.tags.map((tag) => ({ tags: { has: tag } }));
            conditions.push({ OR: tagConditions });
        }

        setQueryParams((prev) => ({
            ...prev,
            where: conditions.length ? { AND: conditions } : undefined,
        }));
    }

    const { tableProps } = useAntdTable<EmailClassification>({
        rowKey: 'id',
        data: {
            rows: data?.rows || [],
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setQueryParams((prev) => ({
                ...prev,
                limit: options?.limit ?? prev.limit,
                offset: options?.offset ?? prev.offset,
                orderBy: options?.orderBy ?? prev.orderBy,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
                sorter: true,
                render: (value) => <Tag>{value.toString()}</Tag>,
            },
            {
                title: 'Issue type',
                dataIndex: 'issueType',
                sorter: true,
                render: (value: EmailClassification['issueType'], record) => (
                    <div>
                        {record.isBreachOfRegulation && <Tag color="red">Breach</Tag>}
                        <Tag color={ISSUE_TYPE_MAP[value].color}>{ISSUE_TYPE_MAP[value].label}</Tag>
                    </div>
                ),
            },
            {
                title: 'Certainty',
                dataIndex: 'confidence',
                sorter: true,
                render: (_, record) =>
                    record.confidence ? (
                        <Tag color={CONFIDENCE_MAP[record.confidence].color}>
                            {CONFIDENCE_MAP[record.confidence].label}
                        </Tag>
                    ) : (
                        '-'
                    ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (_, record) => (
                    <Space direction="vertical" size={2}>
                        <Badge
                            status={
                                record.status === 'PROCESSED'
                                    ? 'success'
                                    : record.status === 'ERROR'
                                      ? 'error'
                                      : record.status === 'REVIEWED'
                                        ? 'success'
                                        : 'processing'
                            }
                            text={capitalize(record.status?.toLowerCase() || '')}
                            className="whitespace-nowrap"
                        />
                    </Space>
                ),
            },
            {
                title: 'Date Uploaded',
                dataIndex: 'uploadedAt',
                sorter: true,
                render: (_, record) => (
                    <Typography.Text type="secondary">
                        {record.uploadedAt ? formatDateTime(record.uploadedAt) : '-'}
                    </Typography.Text>
                ),
            },
            {
                title: 'Uploaded By',
                dataIndex: 'uploadedById',
                render: (uploadedById: bigint) => <User userId={uploadedById} inline />,
            },
            {
                title: 'Reviewed By',
                dataIndex: 'reviewedById',
                render: (reviewedById: bigint | null) => (reviewedById ? <User userId={reviewedById} inline /> : '-'),
            },
            {
                title: 'Tags',
                dataIndex: 'tags',
                render: (tags: string[]) => (
                    <Space>
                        {tags.map((tag) => (
                            <Tag key={tag} color="blue">
                                {tag}
                            </Tag>
                        ))}
                    </Space>
                ),
            },
            {
                title: 'Actions',
                fixed: 'right',
                width: 30,
                render: (_, record) => {
                    const menuItems: MenuProps['items'] = [
                        {
                            key: 'review',
                            icon: <CheckCircleOutlined className="text-green-500" />,
                            label: 'Review',
                            onClick: () =>
                                navigate({
                                    to: '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification',
                                    params: { orgId, id: record.id },
                                }),
                        },
                        {
                            key: 'reprocess',
                            icon: <ReloadOutlined className="text-blue-500" />,
                            label: 'Re-process',
                            disabled: !!record.complaintId || !!record.incidentId,
                            onClick: () => handleRerunClassification(record),
                        },
                    ];

                    if (canDeleteEmailClassification(user, record)) {
                        menuItems.push({
                            key: 'delete',
                            icon: <DeleteOutlined className="text-red-500" />,
                            label: 'Delete',
                            danger: true,
                            onClick: () => {
                                modal.confirm({
                                    title: 'Delete email classification',
                                    content: 'Are you sure you want to delete this email classification?',
                                    okText: 'Delete',
                                    cancelText: 'Cancel',
                                    onOk: () => handleDeleteEmail(record.id),
                                    okButtonProps: {
                                        className: 'bg-red-500',
                                    },
                                });
                            },
                        });
                    }

                    return (
                        <Space className="flex w-full justify-end">
                            {renderCreateRecordButton(record)}
                            <Dropdown menu={{ items: menuItems }} trigger={['click']} placement="bottomRight">
                                <Button type="text" icon={<MoreOutlined />} size="large" />
                            </Dropdown>
                        </Space>
                    );
                },
            },
        ],
        paginationConfig: {
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total: number) => `Total ${total} items`,
        },
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const hasSelected = selectedRowKeys.length > 0;

    async function handleRerunClassification(record: EmailClassification) {
        try {
            message.loading('Rerunning classification. Please wait...');
            void rerunClassification({
                emailClassificationId: record.id,
                aiPromptId: selectedAiModel,
            });
            await sleep(1000);
            trpcUtils.emailClassification.invalidate();
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    }

    function renderCreateRecordButton(record: EmailClassification) {
        if (record.complaintId || record.incidentId) {
            return (
                <Link
                    to={
                        record.issueType === 'INCIDENT'
                            ? `/console/$orgId/compliance/incidents/details/$id/$section`
                            : `/console/$orgId/compliance/complaints/details/$id`
                    }
                    params={{
                        orgId,
                        id: (record.complaintId || record.incidentId) as bigint,
                        section: 'manage',
                    }}
                >
                    View {record.issueType === 'INCIDENT' ? 'incident' : 'complaint'}
                </Link>
            );
        }

        if (record.issueType === 'INCIDENT' || record.issueType === 'COMPLAINT') {
            return (
                <Tooltip
                    title={
                        org.enabledModules.includes('compliance')
                            ? record.issueType === 'INCIDENT'
                                ? 'Create incident'
                                : 'Create complaint'
                            : 'Purchase compliance module to create incidents and complaints'
                    }
                >
                    <Link
                        to={
                            record.issueType === 'INCIDENT'
                                ? '/console/$orgId/compliance/incidents/add'
                                : '/console/$orgId/compliance/complaints/add'
                        }
                        search={{
                            emailClassificationId: record.id,
                        }}
                        params={{
                            orgId,
                        }}
                        type="default"
                        disabled={!org.enabledModules.includes('compliance')}
                    >
                        {record.issueType === 'INCIDENT' ? 'Create incident' : 'Create complaint'}
                    </Link>
                </Tooltip>
            );
        }
        return null;
    }

    async function handleDeleteEmail(emailClassificationId: bigint) {
        try {
            message.loading('Deleting email classification...', 0);

            await deleteEmailClassification({ emailClassificationId });
            await refetch();

            message.success('Email classification deleted successfully.');
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    }

    async function handleProcessEmail(uploadedFile: S3File) {
        try {
            message.loading('Uploading email...', 0);

            const result = await processEmail({
                fileKey: uploadedFile.key,
            });
            if (!result.success) {
                errorNotification(result.error);
                return;
            }
            await sleep(1000);
            await refetch();

            message.success('Email uploaded successfully. It will be processed in the background.');
        } catch {
            message.destroy();
        }
    }

    async function handleProcessSelected() {
        message.loading('Processing emails. Please wait...');
        await Promise.all(
            selectedRowKeys.map((key) =>
                rerunClassification({
                    emailClassificationId: BigInt(key),
                    aiPromptId: selectedAiModel,
                }),
            ),
        );
        await refetch();
        setSelectedRowKeys([]);
    }

    async function handleDeleteSelected() {
        message.loading('Deleting emails. Please wait...');
        await Promise.all(
            selectedRowKeys.map((key) => deleteEmailClassification({ emailClassificationId: BigInt(key) })),
        );
        await refetch();
        message.success('Emails deleted successfully.');
        setSelectedRowKeys([]);
    }

    const renderAiModelSelector = () => {
        if (!aiModels || aiModels.length === 0) {
            return null;
        }

        return (
            <Space align="center">
                <Typography.Text>AI Model:</Typography.Text>
                <Select
                    placeholder="Select AI Model"
                    loading={isLoadingAiModels}
                    value={selectedAiModel}
                    onChange={(value) => setSelectedAiModel(value)}
                    allowClear
                    options={aiModels.map((model) => ({
                        label: model.title,
                        value: model.id,
                    }))}
                    popupMatchSelectWidth={false}
                />
            </Space>
        );
    };

    return (
        <PageContent>
            <PageLayout.Header rightAddon={renderAiModelSelector()} />
            <Space className="mb-4 flex justify-between">
                <Typography.Text type="secondary">Drag and drop your email files here to upload.</Typography.Text>
                <Space>
                    <EmailFilterButton setShowFilter={setShowFilter} />

                    <Popconfirm
                        title="Are you sure you want to delete these emails?"
                        onConfirm={handleDeleteSelected}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="text" disabled={!hasSelected} icon={<DeleteOutlined />} />
                    </Popconfirm>

                    <Button type="default" onClick={handleProcessSelected} disabled={!hasSelected}>
                        Process selected
                    </Button>

                    <UploadButton
                        buttonText="Upload email(s)"
                        accept="message/rfc822"
                        multiple={false}
                        onUploaded={handleProcessEmail}
                    />
                </Space>
            </Space>

            <CollapsiblePanel open={showFilter}>
                <EmailFilterForm form={form} onSearch={onSearch} tags={tags} />
            </CollapsiblePanel>

            <Table
                {...tableProps}
                rowSelection={rowSelection}
                scroll={{ x: 'max-content' }}
                className="shadow-xs"
                locale={{
                    emptyText: (
                        <Empty className="flex h-[450px] flex-col justify-center" description="No emails to display." />
                    ),
                }}
                size="small"
            />

            <EmailDetailsDrawer action={emailDetailsAction} onClose={() => setEmailDetailsAction(['hide'])} />
        </PageContent>
    );
}
