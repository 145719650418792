import { PlayCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useUser } from '@client/hooks/User/useUser';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Form, InputNumber, Modal, Popconfirm, Switch, Table, TableColumnType, Tag, Tooltip } from 'antd';
import { format } from 'date-fns';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/advanced/db-upgrades')({
    staticData: {
        breadcrumb: {
            title: 'DB upgrades',
        },
        ui: {
            title: 'DB upgrades',
            subtitle: 'List of DB upgrades.',
        },
    },
    component: DbUpgrades,
});

type DbUpgrade = RouterOutputs['admin']['dbUpgrade']['listDbUpgrades']['rows'][number];

type QueryVariables = RouterInputs['admin']['dbUpgrade']['listDbUpgrades'];

type RetryParams = {
    useTransaction: boolean;
    txTimeout: number;
    txWait: number;
};

function DbUpgrades() {
    const currentUser = useUser();
    const trpcClient = useTrpcClient();
    const [selectedError, setSelectedError] = useState<string | null>(null);
    const [retryModalVisible, setRetryModalVisible] = useState(false);
    const [selectedUpgrade, setSelectedUpgrade] = useState<DbUpgrade | null>(null);
    const [form] = Form.useForm<RetryParams>();
    const [useTransaction, setUseTransaction] = useState(true);

    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
        orderBy: { id: 'desc' },
    });

    const { data: dbUpgrades, isLoading, refetch } = trpc.admin.dbUpgrade.listDbUpgrades.useQuery(queryVariables);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: dbUpgrades?.rows || [],
            total: dbUpgrades?.total || 0,
            loading: isLoading,
        },

        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (value) => BigInt(value).toString(),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                className: 'whitespace-nowrap',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                className: 'whitespace-nowrap',
                render: (status: string) => {
                    const color =
                        status === 'completed'
                            ? 'success'
                            : status === 'failed'
                              ? 'error'
                              : status === 'pending'
                                ? 'warning'
                                : 'default';

                    return <Tag color={color}>{status}</Tag>;
                },
            },
            {
                title: 'First Run At',
                dataIndex: 'createdAt',
                className: 'whitespace-nowrap',
                render: (date: string) => (date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '-'),
            },
            {
                title: 'Last Run At',
                dataIndex: 'lastRunAt',
                className: 'whitespace-nowrap',
                render: (date: string) => (date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '-'),
            },
            {
                title: 'Dependencies',
                dataIndex: 'dependsOn',
                render: (dependsOn: string[]) => dependsOn.join(', '),
            },
            { title: 'Records found', dataIndex: 'recordsFound' },
            { title: 'Records updated', dataIndex: 'recordsUpdated' },
            {
                title: 'Error',
                dataIndex: 'error',
                render: (error: string) =>
                    error ? (
                        <div
                            className="max-w-40 cursor-pointer truncate text-ellipsis text-red-500 hover:opacity-80"
                            onClick={() => setSelectedError(error)}
                        >
                            {error}
                        </div>
                    ) : (
                        '-'
                    ),
            },
            ...(currentUser.isPrivilegedSuperAdmin
                ? [
                      {
                          title: 'Actions',
                          key: 'actions',
                          fixed: 'right',
                          render: (_: unknown, record) => (
                              <div className="flex gap-2">
                                  <Tooltip title="Retry upgrade">
                                      <Button
                                          icon={<RedoOutlined />}
                                          type="primary"
                                          disabled={record.status === 'completed'}
                                          onClick={() => {
                                              setSelectedUpgrade(record);
                                              setRetryModalVisible(true);
                                              form.setFieldsValue({
                                                  useTransaction: true,
                                                  txTimeout: 60 * 5,
                                                  txWait: 60 * 5,
                                              });
                                          }}
                                      />
                                  </Tooltip>
                              </div>
                          ),
                      } satisfies TableColumnType<DbUpgrade>,
                  ]
                : []),
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                ...options,
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    const handleRetry = async (values: RetryParams) => {
        if (!selectedUpgrade) return;

        try {
            message.loading('Retrying...');
            await trpcClient.admin.dbUpgrade.setDbUpgradeStatus.mutate({
                id: Number(selectedUpgrade.id),
                status: 'retry',
                ...values,
            });
            await trpcClient.admin.dbUpgrade.doDbUpgrade.mutate({
                txMaxWait: values.txWait * 1000,
                txTimeout: values.txTimeout * 1000,
                useTransaction: values.useTransaction,
            });
            void refetch();
            message.success('Retry finished.');
            setRetryModalVisible(false);
        } catch (error) {
            errorMessage.show(error);
        } finally {
            message.destroy();
        }
    };

    return (
        <>
            <div className="mb-4 flex justify-end">
                <Popconfirm
                    title="Are you sure you want to run all pending upgrades?"
                    onConfirm={async () => {
                        try {
                            message.loading('Running upgrades...');
                            await trpcClient.admin.dbUpgrade.doDbUpgrade.mutate({});
                            refetch();
                            message.destroy();
                            message.success('Upgrades completed successfully.');
                        } catch (error) {
                            errorMessage.show(error);
                        }
                    }}
                >
                    <Button type="primary" icon={<PlayCircleOutlined />}>
                        Run Upgrades
                    </Button>
                </Popconfirm>
            </div>

            <Card>
                <Table {...tableProps} size="small" scroll={{ x: true }} />
            </Card>

            <Modal
                title="Retry Upgrade Parameters"
                open={retryModalVisible}
                onCancel={() => {
                    setRetryModalVisible(false);
                    setSelectedUpgrade(null);
                    form.resetFields();
                    setUseTransaction(true);
                }}
                onOk={form.submit}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleRetry}
                    initialValues={{
                        useTransaction: true,
                        txTimeout: 60 * 5,
                        txWait: 60 * 5,
                    }}
                >
                    <Form.Item name="useTransaction" label="Use Transaction" valuePropName="checked">
                        <Switch onChange={(checked) => setUseTransaction(checked)} />
                    </Form.Item>
                    <Form.Item
                        name="txTimeout"
                        label="Transaction Timeout (seconds)"
                        rules={[
                            {
                                required: useTransaction,
                                message: 'Please input transaction timeout',
                            },
                        ]}
                    >
                        <InputNumber min={1} max={60 * 10} disabled={!useTransaction} />
                    </Form.Item>
                    <Form.Item
                        name="txWait"
                        label="Transaction Wait (seconds)"
                        rules={[
                            {
                                required: useTransaction,
                                message: 'Please input transaction wait time',
                            },
                        ]}
                    >
                        <InputNumber min={1} max={60 * 10} disabled={!useTransaction} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Error Details"
                open={!!selectedError}
                onCancel={() => setSelectedError(null)}
                footer={null}
                width={800}
            >
                <pre className="rounded-sm bg-red-50 p-4 break-words whitespace-pre-wrap text-red-500">
                    {selectedError}
                </pre>
            </Modal>
        </>
    );
}
