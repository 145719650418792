import { grey } from '@ant-design/colors';
import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { CollapsiblePanel } from '@client/components/Common/CollapsiblePanel';
import { ComponentPreview } from '@client/components/Common/ComponentPreview';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { OrgSelector } from '@client/components/Common/OrgSelector';
import { ControlLibrarySelector } from '@client/components/Control/ControlLibrarySelect';
import { CountrySelect } from '@client/components/Obligation/CountrySelect';
import { LegislationLibrarySelect } from '@client/components/Obligation/LegislationLibrarySelect';
import { SubObligationLibrarySelect } from '@client/components/Obligation/SubObligationLibrarySelect';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { htmlToText } from '@client/utils/text';
import { listObligationLibraryInputSchema } from '@server/schemas/obligationLibrary';
import { createFileRoute } from '@tanstack/react-router';
import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/obligations/library/')({
    staticData: {
        ui: {
            title: 'Obligations library',
            subtitle: 'Manage your obligations library.',
        },
    },
    component: ObligationsLibrary,
});

type ObligationLibraryItem = RouterOutputs['admin']['obligationLibrary']['listObligationLibrary']['rows'][number];

type FilterForm = {
    id: bigint;
    orgIds: bigint[];
    countries: string[];
    controlIds: number[];
    legislations: string[];
    sections: string[];
    customLabels: { [key: string]: string | string[] };
};

type SendToOrgForm = {
    orgIds: bigint[];
};

function ObligationsLibrary() {
    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ObligationLibraryItem[]>([]);
    const [queryParams, setQueryParams] = useZodState(listObligationLibraryInputSchema);

    const { data: obligationLegislations } = useConfigServiceLoader((service) =>
        service.fetchObligation_LegislationItems(),
    );
    const { data: customLabelsConfig } = useConfigServiceLoader((service) => service.fetchObligationLibraryLabels());

    const { data, isPending, refetch } = trpc.admin.obligationLibrary.listObligationLibrary.useQuery(queryParams);
    const { mutate: deleteObligation } = trpc.obligation.deleteObligation.useMutation();
    const { mutateAsync: sendObligationsToOrganizationsMutation, isPending: sendToOrgLoading } =
        trpc.admin.obligationLibrary.sendObligationsToOrganizations.useMutation();

    const [filterForm] = Form.useForm<FilterForm>();
    const [sendToOrgForm] = Form.useForm<SendToOrgForm>();

    const customLabelColumns = customLabelsConfig
        ? customLabelsConfig
              .filter((labelConfig) => labelConfig.showInTable)
              .map((labelConfig) => ({
                  title: labelConfig.label,
                  dataIndex: `customLabels.${labelConfig.id}`,
                  render: (value: string | string[]) => {
                      if (Array.isArray(value)) {
                          return value
                              .map((val) => {
                                  const option = labelConfig.options.find((option) => option.internalName === val);
                                  return option ? option.label : val;
                              })
                              .join(', ');
                      } else {
                          const option = labelConfig.options.find((option) => option.internalName === value);
                          return option ? option.label : value;
                      }
                  },
              }))
        : [];

    const { tableProps } = useAntdTable<ObligationLibraryItem>({
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        paginationConfig: { hideOnSinglePage: true },
        onQueryVariableChange(options) {
            setQueryParams((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : queryParams.orderBy,
            }));
        },
        rowKey: 'id',
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                render: String,
            },
            {
                title: 'Legislation',
                dataIndex: 'legislation',
                render: (value) => {
                    const legislation = obligationLegislations?.find((l) => l.internalName === value);
                    return legislation?.label || value || '-';
                },
            },
            {
                title: 'Sub obligation',
                dataIndex: 'section',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph ellipsis style={{ maxWidth: 250, minWidth: 100, marginBottom: 0 }}>
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Core obligation',
                dataIndex: 'coreObligation',
                className: 'max-w-xs',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                title: 'Exact wording from legislation',
                dataIndex: 'legislationDescription',
                className: 'max-w-xs',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                title: 'Federal register of legislation URLs',
                dataIndex: 'federalRegisterOfLegislationURLs',
                className: 'max-w-xs',
                render: (links?: { url: string; name?: string }) => {
                    if (!Array.isArray(links)) {
                        return '-';
                    }

                    return (
                        <ComponentPreview noOfLines={3}>
                            {links.map((link) => (
                                <Typography.Paragraph key={link} ellipsis={{ rows: 1 }} className="mb-0 text-primary">
                                    <a
                                        key={link.url}
                                        href={link.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {link.name || link.url}
                                    </a>
                                </Typography.Paragraph>
                            ))}
                        </ComponentPreview>
                    );
                },
            },
            {
                title: 'Regulator',
                dataIndex: 'regulator',
            },
            {
                title: 'Key controls',
                dataIndex: 'Controls',
                render: (_, record) =>
                    record.Controls?.map((item) => (
                        <Tag className="max-w-50" rootClassName="whitespace-normal" key={item.id}>
                            {item.objective}
                        </Tag>
                    )),
            },
            {
                title: 'Assigned to',
                dataIndex: 'AssignedTo',
                render: (_, record) => record.AssignedTo?.map((org) => org.name).join(', '),
            },
            ...customLabelColumns,
            {
                ...commonActionColumn,
                render: (_, record) => (
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure?"
                            onPopupClick={(e) => {
                                e.stopPropagation();
                            }}
                            onConfirm={() => {
                                void onDelete(record.id);
                            }}
                            okButtonProps={{ danger: true }}
                            okText="Delete"
                        >
                            <Button
                                type="text"
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>
                ),
            },
        ],
    });

    const rowSelection: TableRowSelection<ObligationLibraryItem> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: ObligationLibraryItem[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    const onSearch = () => {
        const values = filterForm.getFieldsValue();
        const conditions: NonNullable<typeof queryParams.where>[] = [];

        if (values.id) {
            conditions.push({ id: values.id });
        }
        if (values.orgIds?.length) {
            conditions.push({
                OrgObligations: {
                    some: {
                        orgId: { in: values.orgIds },
                    },
                },
            });
        }
        if (values.countries?.length) {
            conditions.push({
                country: { in: values.countries },
            });
        }
        if (values.controlIds?.length) {
            conditions.push({
                Controls: {
                    some: {
                        controlLibraryItemId: { in: values.controlIds },
                    },
                },
            });
        }
        if (values.legislations?.length) {
            conditions.push({
                legislation: { in: values.legislations },
            });
        }
        if (values.sections?.length) {
            conditions.push({
                section: { in: values.sections },
            });
        }
        if (values.customLabels) {
            Object.keys(values.customLabels).forEach((labelId) => {
                const labelValues = values.customLabels[labelId];

                if (Array.isArray(labelValues) && labelValues.length > 0) {
                    const labelConditions = labelValues.map((val) => ({
                        customLabels: {
                            path: [labelId],
                            equals: val,
                        },
                    }));

                    conditions.push({
                        OR: labelConditions,
                    });
                }
            });
        }

        setQueryParams((prev) => ({ ...prev, where: conditions.length ? { AND: conditions } : undefined }));
    };

    const onSendToOrganizations = async (values: SendToOrgForm) => {
        const { orgIds } = values;

        try {
            message.loading('Sending to organizations...');
            await sendObligationsToOrganizationsMutation({
                obligationIds: selectedRows.flatMap(({ id }) => BigInt(id)),
                orgIds,
            });
            message.success('Successfully sent to organizations');
            setIsModalVisible(false);
            sendToOrgForm.resetFields();
            setSelectedRows([]);
            setSelectedRowKeys([]);
            void refetch();
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onDelete = async (id: bigint) => {
        try {
            message.loading('Deleting...');
            await deleteObligation({
                id,
            });
            message.success('Deleted.');
            void refetch();
        } catch (e) {
            errorMessage.show(e);
        }
    };

    return (
        <>
            <Modal
                confirmLoading={sendToOrgLoading}
                open={isModalVisible}
                maskClosable={false}
                title="Assign controls to register"
                onCancel={() => {
                    setIsModalVisible(false);
                    sendToOrgForm.resetFields();
                }}
                okText="Submit"
                okButtonProps={{
                    autoFocus: true,
                    htmlType: 'submit',
                    form: 'sendToOrgForm',
                }}
            >
                <Form form={sendToOrgForm} layout="vertical" id="sendToOrgForm" onFinish={onSendToOrganizations}>
                    <Form.Item label="Select the organization(s)" name="orgIds" rules={[{ required: true }]}>
                        <OrgSelector mode="multiple" />
                    </Form.Item>
                </Form>
            </Modal>

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={filterForm} layout="vertical" onFinish={onSearch}>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item label="Country" name="countries">
                                    <CountrySelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="ID" name="id">
                                    <InputNumber className="w-full" min="0" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Legislation" name="legislations">
                                    <LegislationLibrarySelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Sub Obligation" name="sections">
                                    <SubObligationLibrarySelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Key Controls" name="controls">
                                    <ControlLibrarySelector mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Organization" name="orgIds">
                                    <OrgSelector mode="multiple" />
                                </Form.Item>
                            </Col>
                            {customLabelsConfig?.map((labelConfig) => (
                                <Col span={6} key={labelConfig.id}>
                                    <Form.Item label={labelConfig.label} name={['customLabels', labelConfig.id]}>
                                        <Select
                                            mode="multiple"
                                            options={labelConfig.options.map((option) => ({
                                                label: option.label,
                                                value: option.internalName,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            filterForm.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-between">
                <Button type="primary" onClick={() => setIsModalVisible(true)} disabled={!(selectedRowKeys.length > 0)}>
                    Send to organization
                </Button>
                <Space>
                    <Tooltip title="Filters">
                        <Button type="text" size="large" title="Filter" onClick={() => setShowFilter(!showFilter)}>
                            <FilterOutlined />
                        </Button>
                    </Tooltip>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() =>
                            navigate({
                                to: '/admin-console/obligations/library/add',
                            })
                        }
                    >
                        New obligation
                    </Button>
                </Space>
            </Space>

            <Card>
                <Table
                    {...tableProps}
                    rowSelection={rowSelection}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick(e) {
                            navigate(
                                {
                                    to: '/admin-console/obligations/library/$id/$section',
                                    params: {
                                        id,
                                        section: 'details',
                                    },
                                },
                                e,
                            );
                        },
                    })}
                    size="small"
                    scroll={{ x: 'max-content' }}
                />
            </Card>
        </>
    );
}
