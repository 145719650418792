import { ComplaintForm } from '@client/components/Complaint/ComplaintForm';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { Card } from 'antd';

type Props = {
    action: ['add'] | ['edit', { complaintId: bigint }];
    emailClassificationId?: bigint;
};

export function ComplaintFormWrapper({ action, emailClassificationId }: Props) {
    const orgId = useOrgId();
    const navigate = useNavigate();

    const complaintId = action[1]?.complaintId;
    const actionType = action[0];

    return (
        <Card>
            <ComplaintForm
                emailClassificationId={emailClassificationId}
                onCancel={() => {
                    if (actionType === 'add') {
                        navigate({ moveInHistory: 'back' });
                        return;
                    }

                    if (!complaintId) {
                        return;
                    }

                    navigate({
                        to: '/console/$orgId/compliance/complaints/details/$id/manage',
                        params: {
                            orgId,
                            id: complaintId,
                        },
                    });
                }}
                onAfterSaveAccessDenied={() => {
                    navigate({
                        to: '/console/$orgId/compliance/complaints',
                        params: {
                            orgId,
                        },
                    });
                }}
                onAfterSaveCancel={({ id }) => {
                    navigate({
                        to: '/console/$orgId/compliance/complaints/details/$id/manage',
                        params: {
                            orgId,
                            id,
                        },
                    });
                }}
            />
        </Card>
    );
}
