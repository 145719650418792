import { claimFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';
import { DocumentLibrary } from '@client/components/Common/Documents/DocumentLibrary';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/library')({
    component: Library,
});

function Library() {
    const claim = claimFormStore.use.formData();

    return (
        <DocumentLibrary
            entityType="claim"
            entityId={Number(claim.id)}
            showDownloadAllButton
            fileNamePrefix={`${claim.generatedId || claim.id}`}
        />
    );
}
