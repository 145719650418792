import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { uuid } from '@shared/utils/general';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill-new';
// @ts-expect-error Missing declaration file
import ImageResize from 'quill-image-resize-module-react';
import { MentionBlot, Mention as MentionClass } from 'quill-mention';
import './styles';

Quill.register('modules/imageResize', ImageResize);
Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': MentionClass });

export type Mention = {
    id: number | string;
    value: string;
};

type Props = Pick<ReactQuill.ReactQuillProps, 'value' | 'onChange' | 'readOnly'> & {
    mentions?: Mention[];
    enableImageUpload?: boolean;
};

const defaultMentions: Mention[] = [];

export const RichTextInput = observer(
    ({ value, onChange, mentions = defaultMentions, readOnly, enableImageUpload }: Props) => {
        const quillRef = useRef<ReactQuill>(null);
        const id = useMemo(() => uuid(), []);

        const trpcClient = useTrpcClient();
        const orgId = useOrgId();

        const source = useCallback(
            (searchTerm: string, renderList: (mentions: Mention[], searchTerm: string) => void) => {
                const matches = searchTerm
                    ? mentions?.filter((item) => item.value.toLowerCase().includes(searchTerm.toLowerCase()))
                    : mentions;

                renderList(matches, searchTerm);
            },
            [],
        );

        const modules: Record<string, any> = {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ],
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize'],
            },
            clipboard: {
                matchVisual: false,
            },
        };

        if (mentions?.length) {
            modules.mention = {
                allowedChars: /^[A-Za-z_\s]*$/,
                mentionDenotationChars: ['@'],
                showDenotationChar: true,
                source,
                mentionContainerClass: 'crm-rich-text-mention-list-container ql-mention-list-container',
                listItemClass: 'crm-rich-text-mention-list-item ql-mention-list-item',
            };
        }

        const image = useCallback(() => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.click();

            input.onchange = async () => {
                try {
                    const file = input?.files?.[0];

                    if (!file) {
                        return;
                    }

                    message.loading('Uploading image...');

                    const { url, fields } = await trpcClient.file.getUploadPresignedUrl.mutate({
                        bucketType: 'public',
                        filename: file.name,
                        orgId,
                    });

                    const formData = new FormData();
                    Object.keys(fields).forEach((key) => {
                        formData.append(key, fields[key]);
                    });
                    formData.append('file', file);

                    await axios.post(url, formData);

                    const fileUrl = `${import.meta.env.VITE_S3_PUBLIC_BUCKET_URL}/${fields.key}`;

                    const quill = quillRef.current?.getEditor();

                    if (!quill) {
                        return;
                    }

                    const range = quill.getSelection();

                    if (!range) {
                        return;
                    }

                    quill.insertEmbed(range.index, 'image', fileUrl);

                    message.success('Image uploaded.');
                } catch (e) {
                    errorMessage.show(e);
                }
            };
        }, []);

        if (enableImageUpload) {
            modules.toolbar.container[2].push('image');
            modules.toolbar.handlers = {
                image,
            };
        }

        return (
            <ReactQuill
                id={id}
                ref={quillRef}
                theme="snow"
                value={value || ''}
                onChange={onChange}
                modules={modules}
                readOnly={readOnly}
                data-text-editor="name"
                bounds={`[id="${id}"]`}
                formats={[
                    'header',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'list',
                    'link',
                    'indent',
                    'image',
                    'mention',
                ]}
            />
        );
    },
);
