import { TaskTable } from '@client/components/Task/TaskTable';

import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Card } from 'antd';
import { useGetWorkloadManagerTaskFilters } from '../~route';

export const Route = createFileRoute('/console/$orgId/workload-manager/tasks/list')({
    staticData: {
        metadata: {
            title: 'Task list',
        },
        breadcrumb: {
            title: 'Tasks',
        },
    },
    component: TaskList,
});

function TaskList() {
    const filters = useGetWorkloadManagerTaskFilters();

    return (
        <>
            <Outlet />
            <Card>
                <TaskTable
                    features={[{ feature: 'reassign' }]}
                    from="/console/$orgId/workload-manager/tasks/list"
                    filters={filters}
                />
            </Card>
        </>
    );
}
