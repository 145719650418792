import { grey, red } from '@ant-design/colors';
import { DeleteOutlined, ExclamationCircleOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { CollapsiblePanel } from '@client/components/Common/CollapsiblePanel';
import { ComponentPreview } from '@client/components/Common/ComponentPreview';
import { DatePicker } from '@client/components/Common/DatePicker';
import { errorMessage } from '@client/components/Common/errorMessage';
import { NewTabLink } from '@client/components/Common/NewTabLink';
import { UserSelector } from '@client/components/Common/UserSelector';
import { RiskAdd } from '@client/components/Risk/RiskAdd';
import { RiskCategoryCascader } from '@client/components/Risk/RiskCategoryCascader';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useSafePath } from '@client/hooks/useSafePath';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { Action } from '@client/types/common';
import { SortOrder } from '@client/utils/general';
import { commonActionColumn } from '@client/utils/table';
import { htmlToText } from '@client/utils/text.ts';
import { Falsy, isTruthy } from '@shared/utils/boolean';
import { capitalize } from '@shared/utils/string';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Button, Card, Col, Form, Input, Popconfirm, Row, Space, Switch, Table, Tooltip, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { z } from 'zod';

export const Route = createFileRoute('/console/$orgId/risk/register/')({
    validateSearch: zodValidator(
        z.object({
            assessmentRowIndex: z.coerce.number().optional(),
            assessmentColIndex: z.coerce.number().optional(),
            assessmentType: z.enum(['inherent', 'residual']).optional(),
        }),
    ),
    component: RiskRegisterSearch,
});

type Risk = RouterOutputs['risk']['listRisks']['rows'][number];

type Filter = {
    title: string;
    categoryId: number;
    dateIdentified: Date;
    ownerId: number;
    residualRiskOutOfAppetite: boolean;
};

function RiskRegisterSearch() {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const { fDateShortDayJs } = useFormatter();
    const config = useConfigServiceLoader((configService) => configService.fetchRiskMatrix());

    const [action, setAction] = useState<Action>(['none']);
    const [variables, setVariables] = useState<RouterInputs['risk']['listRisks']>({
        where: undefined,
        orderBy: {
            id: SortOrder.desc,
        },
        limit: 10,
        offset: 0,
    });
    const [showFilter, setShowFilter] = useState(false);

    const [form] = Form.useForm<Filter>();
    const safePath = useSafePath<Filter>();

    const { data, isPending, refetch } = trpc.risk.listRisks.useQuery(variables);
    const deleteRiskMutation = trpc.risk.deleteRisk.useMutation();

    const {
        assessmentRowIndex: rawAssessmentRowIndex,
        assessmentColIndex: rawAssessmentColIndex,
        assessmentType,
    } = Route.useSearch();

    useEffect(() => {
        const assessmentRowIndex = Number(rawAssessmentRowIndex);
        const assessmentColIndex = Number(rawAssessmentColIndex);

        if (
            isNaN(assessmentRowIndex) ||
            isNaN(assessmentColIndex) ||
            (assessmentType !== 'inherent' && assessmentType !== 'residual')
        ) {
            return;
        }

        setTimeout(() => {
            setVariables((prev) => ({
                ...prev,
                where: {
                    ...prev.where,
                    riskAssessment: {
                        path: ['selected' + capitalize(assessmentType) + 'Cell'],
                        equals: {
                            rowIndex: assessmentRowIndex,
                            colIndex: assessmentColIndex,
                        },
                    },
                },
            }));
        }, 0);
    }, [rawAssessmentRowIndex, rawAssessmentColIndex, assessmentType]);

    const onDelete = async (id: bigint) => {
        try {
            await deleteRiskMutation.mutateAsync({
                id,
            });

            void refetch();
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onSearch = () => {
        const values = form.getFieldsValue();
        const filters: (Falsy | RouterInputs['risk']['listRisks']['where'])[] = [
            values.title && {
                title: { contains: `${values.title}`, mode: 'insensitive' },
            },
            values.categoryId && {
                LevelOneRiskCategory: { id: { equals: values.categoryId } },
            },
            values.dateIdentified && {
                AND: [
                    {
                        dateIdentified: {
                            gte: DateTime.fromJSDate(values.dateIdentified).startOf('day').toJSDate(),
                        },
                    },
                    {
                        dateIdentified: {
                            lte: DateTime.fromJSDate(values.dateIdentified).endOf('day').toJSDate(),
                        },
                    },
                ],
            },
            values.ownerId && { ownerId: { equals: values.ownerId } },
            values.residualRiskOutOfAppetite && {
                riskAssessment: {
                    path: ['isResidualRiskOutsideAppetite'],
                    equals: true,
                },
            },
        ];

        const where: RouterInputs['risk']['listRisks']['where'] = {
            AND: filters.filter(isTruthy),
        };

        setVariables((prev) => ({ ...prev, where }));
    };

    const { tableProps } = useAntdTable<Risk>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending || deleteRiskMutation.isPending,
            total: data?.total,
        },
        paginationConfig: { hideOnSinglePage: true },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit,
                offset: options?.offset,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                width: 25,
                render: (_, record) =>
                    !!record.riskAssessment?.isResidualRiskOutsideAppetite && (
                        <Tooltip title="Residual risk is outside of risk appetite">
                            <ExclamationCircleOutlined
                                style={{ color: red.primary }}
                                className="align-middle text-xl"
                            />
                        </Tooltip>
                    ),
            },
            {
                title: 'Risk No.',
                dataIndex: 'id',
                fixed: 'left',
                render: (value, record) => {
                    const matrixType = record.riskAssessment?.matrixType;
                    const isOutOfSync = !config.loading && config.data?.matrixType !== matrixType;

                    return (
                        <>
                            {record.LevelOneCategory?.riskNumberPrefix
                                ? `${record.LevelOneCategory?.riskNumberPrefix}-${value}`
                                : Number(value)}
                            {isOutOfSync && (
                                <Tooltip title="Risk matrix is out of sync with current configuration">
                                    (Out of sync){' '}
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Category',
                dataIndex: 'LevelOneCategory',
                render: (value) => value?.name,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                render: (value) => (
                    <Tooltip title={value}>
                        <Typography.Paragraph ellipsis style={{ maxWidth: 500, minWidth: 100, marginBottom: 0 }}>
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Impact',
                dataIndex: 'riskAssessment',
                render: (value) => value.impacts[value?.selectedResidualCell?.rowIndex] || '',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <NewTabLink
                            to="/console/$orgId/risk/register/details/$id/$section"
                            params={{
                                orgId,
                                id: value,
                                section: 'assessment',
                            }}
                        />
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure?"
                                onPopupClick={(e) => e.stopPropagation()}
                                onConfirm={() => onDelete(value)}
                                okButtonProps={{ danger: true }}
                                okText="Delete"
                            >
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            },
        ],
    });

    if (config.loading) {
        return <Card loading />;
    }

    return (
        <>
            <RiskAdd
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={form} layout="vertical" onFinish={onSearch}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Category" name={safePath('categoryId')}>
                                    <RiskCategoryCascader level={1} showSearch />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Title" name={safePath('title')}>
                                    <Input className="w-full" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Date identified" name={safePath('dateIdentified')}>
                                    <DatePicker className="w-full" format={fDateShortDayJs} maxDate={new Date()} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Owner" name={safePath('ownerId')}>
                                    <UserSelector />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Residual risk is outside of appetite"
                                    name={safePath('residualRiskOutOfAppetite')}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Space className="flex justify-end">
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    onSearch();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Space>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-end">
                <Tooltip title="Filters">
                    <Button type="text" size="large" title="Filter" onClick={() => setShowFilter((prev) => !prev)}>
                        <FilterOutlined />
                    </Button>
                </Tooltip>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    Add risk
                </Button>
            </Space>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/risk/register/details/$id/$section',
                                    params: {
                                        orgId,
                                        id,
                                        section: 'assessment',
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
