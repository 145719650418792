import { RequireAuth } from '@client/components/Auth/RequireAuth';
import { AuthenticatedLayout } from '@client/components/Layout/AuthenticatedLayout';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId')({
    params: {
        parse({ orgId }) {
            return { orgId: BigInt(orgId) };
        },
    },
    component: Console,
});

function Console() {
    return (
        <RequireAuth loginUrl="/login">
            <AuthenticatedLayout>
                <Outlet />
            </AuthenticatedLayout>
        </RequireAuth>
    );
}
