import { message } from '@client/components/Common/message';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { fileExtension } from '@shared/utils/file';
import { Button, theme, Tooltip } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { FileIcon } from 'react-file-icon';
import { PuffLoader } from 'react-spinners';
import { errorMessage } from '../errorMessage';
const { useToken } = theme;

interface Props {
    fileKey: string;
    /** 
     The name is important as it is used to determine the file type
     If the name should not be shown, use hideName
     */
    fileName: string;
    target?: string;
    label?: ReactNode;
    size?: SizeType;
    icon?: ReactNode;
    tooltip?: string;
    hideName?: boolean;
}

export function FileLink({ fileKey, fileName, target, label, size = 'small', icon, tooltip, hideName = false }: Props) {
    const [loading, setLoading] = useState(false);
    const { token } = useToken();

    const trpcClient = useTrpcClient();

    const openFile = async () => {
        setLoading(true);
        message.loading(`Opening ${fileName}...`);
        try {
            setLoading(true);
            const url = await trpcClient.file.getDownloadUrl.query({ key: fileKey });
            window.open(url, target);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            message.destroy();
            setLoading(false);
        }
    };

    return (
        <Tooltip title={tooltip}>
            <Button
                target={target}
                type="link"
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    openFile();
                }}
                size={size}
                className="h-auto"
            >
                <div className="flex items-center">
                    {loading ? (
                        <div className={clsx('flex w-6 mr-2')}>
                            <PuffLoader color={token.colorPrimary} size={20} />
                        </div>
                    ) : (
                        <div className={clsx('flex w-6', !hideName && 'mr-2')}>
                            {icon || (
                                <FileIcon extension={fileExtension(fileName || fileKey)} color={token.colorPrimary} />
                            )}
                        </div>
                    )}
                    {!hideName && (label || fileName)}
                </div>
            </Button>
        </Tooltip>
    );
}
