import { errorMessage } from '@client/components/Common/errorMessage';
import { History } from '@client/components/Common/History';
import { message } from '@client/components/Common/message';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { trpc } from '@client/trpc/client';
import { RecordNotFoundError } from '@client/utils/error';
import { CONTROL_LIBRARY_SECTIONS, ControlLibrarySection } from '@shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';
import { Skeleton, Tabs } from 'antd';
import { ControlLibraryItemDetailsEdit } from './ControlLibraryItemDetailsEdit';
import { controlLibraryFormStore } from './store';

export const Route = createFileRoute('/admin-console/controls/library/$id/$section')({
    staticData: {
        breadcrumb: {
            title: 'Control details',
        },
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as ControlLibrarySection,
            };
        },
    },
    component: ControlLibraryItemDetails,
});

function ControlLibraryItemDetails() {
    const { id } = Route.useParams();
    const navigate = useNavigate();
    const { trpcClient } = useTrpc();
    const { mutateAsync: updateControlLibraryItem } = trpc.admin.controlLibrary.updateControlLibraryItem.useMutation();

    const { formData: control } = controlLibraryFormStore.useInit({
        entity: 'control',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.loading('Saving...');

                await updateControlLibraryItem({
                    id: currentValue.id!,
                    set: newValue,
                });

                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.admin.controlLibrary.getControlLibraryItem.query({ id });

                return {
                    success: true,
                    data: data,
                };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync("The control you are trying to access either doesn't exist.");
                navigate({ to: '/admin-console/controls/library' });
            } else {
                errorMessage.show(error);
            }
        },
    });

    return control && id === control.id ? (
        <Tabs
            type="line"
            onTabClick={(key) => {
                navigate({
                    to: '/admin-console/controls/library/$id/$section',
                    params: {
                        id,
                        section: key as ControlLibrarySection,
                    },
                });
            }}
            items={[
                {
                    key: CONTROL_LIBRARY_SECTIONS.DETAILS,
                    label: 'Manage control',
                    children: <ControlLibraryItemDetailsEdit />,
                },
                {
                    key: CONTROL_LIBRARY_SECTIONS.HISTORY,
                    label: 'History',
                    children: <History idValue={Number(id)} idPropertyName="controlLibraryId" />,
                },
            ]}
        />
    ) : (
        <Skeleton active />
    );
}
