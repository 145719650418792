import { trpcClient } from '@client/trpc/client';
import { logInDev } from '@client/utils/general';
import { DEFAULT_ORG_SETTINGS } from '@shared/definitions/orgSettings';
import { OrgSettings } from '@shared/types/org';
import { create } from 'zustand';

type OrgSettingsState = {
    isInitialized: boolean;
    orgSetting: OrgSettings;
    init: (orgId: bigint) => Promise<void>;
    reload: (orgId: bigint) => Promise<void>;
};
export const useOrgSettingsStore = create<OrgSettingsState>((set, get) => ({
    isInitialized: false,
    trpc: undefined,
    orgSetting: DEFAULT_ORG_SETTINGS,
    client: null,
    canBeInitialized: false,

    init: async (orgId: bigint) => {
        try {
            set({ isInitialized: false });
            const data = await trpcClient.org.getOrgSettings.query({ orgId });
            set({
                orgSetting: data,
                isInitialized: true,
            });
        } catch (e) {
            logInDev('OrgSettingStore.init failed', e);
        }
    },

    reload: async (orgId: bigint) => {
        await get().init(orgId);
    },
}));

// Selector functions
export const selectIsInitialized = (state: OrgSettingsState) => state.isInitialized;
export const selectAllSettings = (state: OrgSettingsState) => state.orgSetting;
