import { createFileRoute, Navigate } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { useEffect } from 'react';
import { z } from 'zod';

export const Route = createFileRoute('/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId')({
    validateSearch: zodValidator(z.object({ data: z.string().optional() })),
    component: ClaimLodgementDeprecated,
    params: {
        parse: (params) => ({
            orgId: z.coerce.bigint().parse(params.orgId),
            pdsVersionId: z.coerce.bigint().parse(params.pdsVersionId),
            questionnaireId: z.coerce.bigint().parse(params.questionnaireId),
            productTypeId: z.coerce.bigint().parse(params.productTypeId),
        }),
    },
});

function ClaimLodgementDeprecated() {
    const params = Route.useParams();
    const searchParams = Route.useSearch();

    useEffect(() => {
        console.log('Redirecting...');
    }, []);

    return (
        <Navigate
            to="/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId"
            params={params}
            search={searchParams}
        />
    );
}
