import { ComplaintFormWrapper } from '@client/components/Complaint/ComplaintFormWrapper';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/add')({
    staticData: {
        breadcrumb: {
            title: 'New complaint',
        },
        ui: {
            variant: 'compact',
        },
    },
    validateSearch: zodValidator(
        z.object({
            emailClassificationId: z.coerce.bigint().optional().catch(undefined),
        }),
    ),
    component() {
        const { emailClassificationId } = Route.useSearch();

        return <ComplaintFormWrapper action={['add']} {...(emailClassificationId && { emailClassificationId })} />;
    },
});
