import { useUser } from '@client/hooks/User/useUser';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { RouterInputs } from '@client/trpc/client';
import { SYSTEM_ORG_IDS } from '@shared/definitions/org';
import { uuid } from '@shared/utils/general';
import { GetRef, Select } from 'antd';
import { forwardRef, Ref, useCallback, useMemo, useState } from 'react';
import { errorMessage } from '../errorMessage';
import { AsyncSelect, AsyncSelectProps } from '../Select/Async';

type HandlingPartySelectProps = Pick<
    AsyncSelectProps<bigint[], { key: string; value: bigint; label: string }>,
    'onChange' | 'value' | 'placeholder' | 'defaultOpen' | 'className'
> & {
    onLoaded?: () => void;
    forAdminShowAllOrgs?: boolean;
    showUnallocated?: boolean;
    showAllOrgs?: boolean;
    orgId?: bigint;
    containerId?: string;
    /**
     * If true, the select will be auto-filled with all fetched handling parties.
     */
    autoSelectAll?: boolean;
};

type SelectRef = Ref<GetRef<typeof Select>>;

export const HandlingPartySelect = forwardRef(
    (
        {
            onLoaded,
            forAdminShowAllOrgs,
            showAllOrgs: showAllOrgsProp,
            showUnallocated,
            orgId,
            placeholder,
            containerId,
            onChange,
            autoSelectAll,
            ...props
        }: HandlingPartySelectProps,
        ref: SelectRef,
    ) => {
        const user = useUser();
        const trpcClient = useTrpcClient();

        const [id] = useState(containerId || uuid());

        const showAllOrgs = useMemo(
            () => (user.roles.includes('org_admin') && forAdminShowAllOrgs) || showAllOrgsProp || user.isSuperAdmin,
            [user.roles, forAdminShowAllOrgs, showAllOrgsProp],
        );

        const fetchOptions = useCallback(async () => {
            try {
                const data = await trpcClient.handlingParty.listHandlingParties.query({
                    orgId,
                    where: {
                        isDisabled: false,
                        ...(showAllOrgs
                            ? {}
                            : ({
                                  UsersInHandlingParty: { some: { userId: user.id } },
                              } satisfies RouterInputs['handlingParty']['listHandlingParties']['where'])),
                    },
                });

                onLoaded?.();

                const options = [
                    ...data.rows.map((item) => ({ key: item.id.toString(), value: item.id, label: item.name })),
                    ...(showUnallocated && user.isOrgAdmin
                        ? [
                              {
                                  key: String(SYSTEM_ORG_IDS.UNALLOCATED),
                                  value: SYSTEM_ORG_IDS.UNALLOCATED,
                                  label: 'Unallocated',
                              },
                          ]
                        : []),
                ];

                if (autoSelectAll) {
                    onChange?.(options.map((item) => item.value));
                }

                return options;
            } catch (e) {
                errorMessage.show(e);
                return [];
            }
        }, [orgId, showAllOrgs, trpcClient, user.id, user.isOrgAdmin]);

        return (
            <AsyncSelect
                allowClear
                ref={ref}
                fetchOptions={fetchOptions}
                fetchOnInit
                optionFilterProp="label"
                placeholder={placeholder}
                getPopupContainer={() => document.getElementById(id)!}
                mode="multiple"
                onChange={onChange}
                popupMatchSelectWidth={false}
                {...props}
            />
        );
    },
);
