import { MinusCircleOutlined } from '@ant-design/icons';
import { message } from '@client/components/Common/message';
import { QuestionnaireService } from '@client/services/QuestionnaireService';
import { CardChild } from '@shared/model/CardChild';
import { SpecifiedItem } from '@shared/types/specifiedItem';
import { Button, Divider, Form, Input, Typography } from 'antd';
import { IObservableArray, observable } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useImperativeHandle } from 'react';

export interface SingleChildSpecifiedItemHandle {
    goToNext: (goToNextParent: () => void) => void;
    goToPrev: (goToPrevParent: () => void) => void;
}

interface State {
    step: 0 | 1;
}

interface Props {
    child: CardChild;
    readOnly: boolean;
    service: QuestionnaireService;
}

export const ChildSpecifiedItem = observer(
    React.forwardRef(({ child, service }: Props, ref) => {
        const state = useLocalObservable<State>(() => ({
            step: 0,
        }));

        if (child.type === 'SPECIFIED_ITEM' && !child.value) {
            child.value = observable([]);
        }
        const specifiedItems = child.value! as IObservableArray<SpecifiedItem | null>;

        useImperativeHandle(ref, () => ({
            goToNext: async (goToNextParent: () => void) => {
                try {
                    message.loading('Please wait...');
                    if (state.step === 0) {
                        const emptyItems = specifiedItems.filter((si) => !si);
                        emptyItems.forEach((ei) => {
                            specifiedItems.remove(ei);
                        });

                        for (let i = 0; i < specifiedItems.length; i++) {
                            let prevValues: any[] = [];
                            if (specifiedItems[i]!.attributes) {
                                prevValues = specifiedItems[i]!.attributes!.map((el) => el.value);
                            }
                            // Other
                            if (specifiedItems[i]!.id === -1) {
                                specifiedItems[i]!.attributes = [
                                    { name: 'Item description', value: '', type: 'TEXT' },
                                    { name: 'Brand', value: '', type: 'TEXT' },
                                    { name: 'Value', value: null, type: 'NUMBER', unit: '$' },
                                    {
                                        name: 'Size',
                                        value: null,
                                        type: 'NUMBER',
                                        unit: 'square metres',
                                    },
                                    { name: 'Age', value: null, type: 'NUMBER', unit: 'months' },
                                ];
                            } // else {
                            //     specifiedItems[i]!.attributes =
                            //         await service.fetchSpecifiedItemAttributes(
                            //             specifiedItems[i]!.id,
                            //         );
                            // }

                            specifiedItems[i]!.attributes!.forEach((el, index) => {
                                el.value = prevValues[index];
                            });
                        }
                        state.step = 1;
                    } else {
                        // Load PDS ids
                        // This functionality is currently parked
                        // for (let i = 0; i < specifiedItems.length; i++) {
                        //     const si = specifiedItems[i]!;
                        //     const { data } = await client.query<GetPdsConfigItemIds, GetPdsConfigItemIdsVariables>({
                        //         query: GET_PDS_CONFIG_ITEM_IDS,
                        //         variables: { specifiedItemId: si.id },
                        //         fetchPolicy: 'network-only',
                        //     });
                        //     si.pdsIds = data.SpecifiedItem_PdsConfigItem.map((i) => i.pdsConfigItemId);
                        // }
                        goToNextParent();
                    }
                } finally {
                    message.destroy();
                }
            },
            goToPrev: async (goToPrevParent: () => void) => {
                if (state.step === 1) {
                    state.step = 0;
                } else {
                    goToPrevParent();
                }
            },
        }));

        useEffect(() => {
            if (service.direction === 'backward') {
                state.step = 1;
            } else {
                state.step = 0;
            }
        }, [service.direction]);

        return (
            <>
                {state.step === 0 && (
                    <>
                        {specifiedItems.map((item, index) => (
                            <div className="mb-4" key={index}>
                                {/* Not used atm */}
                                {/*<SpecifiedItemSelector*/}
                                {/*    value={item}*/}
                                {/*    readonly={readOnly}*/}
                                {/*    onChange={(value) => {*/}
                                {/*        specifiedItems[index] = value;*/}
                                {/*        if (value !== null) {*/}
                                {/*            specifiedItems[index]!.attributes = [];*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <Button
                                    type="text"
                                    onClick={() => {
                                        specifiedItems.remove(item);
                                    }}
                                    title="Remove"
                                >
                                    <MinusCircleOutlined />
                                </Button>
                            </div>
                        ))}
                        <div className="mt-2">
                            <Button
                                type="primary"
                                onClick={() => {
                                    specifiedItems.push(null);
                                }}
                            >
                                Add new item
                            </Button>
                        </div>
                    </>
                )}
                {state.step === 1 && (
                    <>
                        {specifiedItems.map((sp, index) => (
                            <div key={index}>
                                <div className="mb-2">
                                    <div>
                                        <Typography.Text type="secondary">Item {index + 1}</Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text>{sp?.labelOther || sp?.label}</Typography.Text>
                                    </div>
                                </div>
                                {sp?.attributes?.map((el, index) => (
                                    <Form.Item key={index} label={`${el.name}${el.unit ? ` (${el.unit})` : ''}`}>
                                        <Input
                                            value={el.value || ''}
                                            onChange={(event) => {
                                                el.value = event.target.value;
                                            }}
                                        />
                                    </Form.Item>
                                ))}
                                <Divider />
                            </div>
                        ))}
                    </>
                )}
            </>
        );
    }),
);
