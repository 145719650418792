import { Tabs } from '@client/components/Navigation/Tabs';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/workload-manager')({
    staticData: {
        metadata: {
            title: 'Workload manager',
        },
        breadcrumb: {
            inactive: true,
        },
        roles: ['user', 'org_admin'],
        modules: ['claim', 'compliance', 'risk'],
    },
    component: WorkloadManager,
});

function WorkloadManager() {
    return (
        <Tabs
            items={[
                {
                    label: 'Tasks',
                    to: '/console/$orgId/workload-manager/tasks',
                },
                {
                    label: 'Emails',
                    to: '/console/$orgId/workload-manager/emails',
                },
            ]}
        />
    );
}
