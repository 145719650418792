import { ArrowRightOutlined } from '@ant-design/icons';
import { message } from '@client/components/Common/message';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterInputs, RouterOutputs } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Form, Input, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/advanced/indicators/incidents')({ component: Indicators });

type Form = RouterInputs['admin']['indicator']['reprocessIncidentIndicator'];
type LogEntry = RouterOutputs['admin']['indicator']['reprocessIncidentIndicator']['logs'][number];
type MilestoneEntry = RouterOutputs['admin']['indicator']['reprocessIncidentIndicator']['milestones'][number];

function Indicators() {
    const { trpc } = useTrpc();
    const [form] = Form.useForm<Form>();
    const [processing, setProcessing] = useState(false);
    const [logs, setLogs] = useState<LogEntry[]>([]);
    const [milestones, setMilestones] = useState<MilestoneEntry[]>([]);
    const reprocessIncidentIndicator = trpc.admin.indicator.reprocessIncidentIndicator.useMutation();

    const handleReprocessIndicators = async () => {
        try {
            setProcessing(true);
            setLogs([]);
            setMilestones([]);
            message.loading('Reprocessing incident indicator...');
            const values = await form.validateFields();
            const { logs, milestones } = await reprocessIncidentIndicator.mutateAsync({
                orgId: BigInt(values.orgId),
                incidentId: BigInt(values.incidentId),
            });

            setLogs(logs.sort((a, b) => a.priority - b.priority));
            setMilestones(milestones);
            message.success('Incident indicators reprocessed successfully');
        } finally {
            setProcessing(false);
        }
    };

    const columns: ColumnsType<LogEntry> = [
        { title: 'Def ID', dataIndex: 'defId', key: 'defId', fixed: 'left' },
        { title: 'Inst ID', dataIndex: 'instanceId', key: 'instanceId', fixed: 'left' },
        { title: 'Title', dataIndex: ['def', 'title'], key: 'title', className: 'whitespace-nowrap', fixed: 'left' },
        {
            title: 'IsOn',
            fixed: 'left',
            className: 'whitespace-nowrap',
            render: (_, record) => (
                <div>
                    <Tag color={record.currentInstanceIsOn ? 'green' : 'red'}>
                        {record.currentInstanceIsOn ? 'On' : 'Off'}
                    </Tag>
                    <ArrowRightOutlined />
                    &nbsp;
                    <Tag color={record.isOn ? 'green' : 'red'}>{record.isOn ? 'On' : 'Off'}</Tag>
                </div>
            ),
        },

        { title: 'Priority', dataIndex: 'priority', key: 'priority', fixed: 'left' },
        { title: 'DBM', dataIndex: 'daysBetweenMilestones', key: 'daysBetweenMilestones', fixed: 'left' },
        { title: 'MDBM', dataIndex: 'maxDaysBetweenMilestones', key: 'maxDaysBetweenMilestones', fixed: 'left' },
        { title: 'Day Interval Type', dataIndex: 'dayIntervalType', key: 'dayIntervalType', fixed: 'left' },
        {
            title: 'Def Color',
            render: (_, record) => <div className="h-6 w-6 rounded-sm" style={{ backgroundColor: record.def.color }} />,
        },
        {
            title: 'Start ML',
            dataIndex: 'milestoneStartName',
            key: 'milestoneStartName',
            className: 'whitespace-nowrap',
        },
        { title: 'End ML', dataIndex: 'milestoneEndName', key: 'milestoneEndName', className: 'whitespace-nowrap' },
        {
            title: 'Start ML Completed',
            dataIndex: 'milestoneStartCompleted',
            key: 'milestoneStartCompleted',
            className: 'whitespace-nowrap',
            render: (date: Date | null) => (date ? date.toLocaleString() : '-'),
        },
        {
            title: 'End ML Completed',
            dataIndex: 'milestoneEndCompleted',
            key: 'milestoneEndCompleted',
            className: 'whitespace-nowrap',
            render: (date: Date | null) => (date ? date.toLocaleString() : '-'),
        },
        {
            title: 'Closed ML Completed',
            dataIndex: 'closedMilestoneCompleted',
            key: 'closedMilestoneCompleted',
            className: 'whitespace-nowrap',
            render: (date: Date | null) => (date ? date.toLocaleString() : '-'),
        },
        {
            title: 'Current Instance Last Processed',
            dataIndex: 'currentInstanceLastProcessed',
            key: 'currentInstanceLastProcessed',
            className: 'whitespace-nowrap',
            render: (date: Date | null) => (date ? date.toLocaleString() : '-'),
        },
    ];

    const milestoneColumns: ColumnsType<MilestoneEntry> = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Label', dataIndex: 'label', key: 'label' },
        { title: 'Internal Name', dataIndex: 'internalName', key: 'internalName' },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (date: Date | null) => (date ? date.toLocaleString() : '-'),
        },
        {
            title: 'Completed At',
            dataIndex: 'completedTimestamp',
            render: (date: string | null) => (date ? new Date(date).toLocaleString() : '-'),
        },
    ];

    return (
        <Card title="Incident Indicators">
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Organization ID"
                    name="orgId"
                    className="max-w-xs"
                    rules={[
                        { required: true, message: 'Please input the organization ID' },
                        { pattern: /^\d+$/, message: 'Please input a valid number' },
                    ]}
                >
                    <Input placeholder="Enter organization ID" />
                </Form.Item>
                <Form.Item
                    label="Incident ID"
                    name="incidentId"
                    className="max-w-xs"
                    rules={[
                        { required: true, message: 'Please input the incident ID' },
                        { pattern: /^\d+$/, message: 'Please input a valid number' },
                    ]}
                >
                    <Input placeholder="Enter incident ID" />
                </Form.Item>
                <Form.Item>
                    <Button onClick={handleReprocessIndicators} loading={processing} type="primary">
                        Reprocess incident indicator
                    </Button>
                </Form.Item>
            </Form>
            {logs.length > 0 && (
                <div className="mt-4">
                    <Typography.Text type="secondary" className="mb-4 block">
                        <ul className="m-0 list-none p-0">
                            <li>
                                <strong>DBM:</strong> Days Between Milestones
                            </li>
                            <li>
                                <strong>MDBM:</strong> Max Days Between Milestones
                            </li>
                            <li>
                                <strong>ML:</strong> Milestone
                            </li>
                        </ul>
                    </Typography.Text>
                    <Typography.Title level={5}>Processing Logs - Indicators:</Typography.Title>
                    <Table
                        dataSource={logs}
                        columns={columns}
                        rowKey={(record) => `${record.defId}`}
                        size="small"
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            )}
            {milestones.length > 0 && (
                <div className="mt-8">
                    <Typography.Title level={5}>Milestones:</Typography.Title>
                    <Table
                        dataSource={milestones}
                        columns={milestoneColumns}
                        rowKey={(record) => `${record.id}`}
                        size="small"
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            )}
        </Card>
    );
}
