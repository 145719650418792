import { grey } from '@ant-design/colors';
import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ArrayInput } from '@client/components/Common/ArrayInput';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { RichTextInput } from '@client/components/Common/RichTextInput';
import { ControlLibraryTable } from '@client/components/Control/ControlLibraryTable';
import { BreachExamples } from '@client/components/Obligation/BreachExamples';
import { BreachExamplesProvider } from '@client/components/Obligation/BreachExamples/BreachExamplesContext';
import { CountrySelect } from '@client/components/Obligation/CountrySelect';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useNavigate } from '@client/hooks/useNavigate';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Drawer, Form, Input, List, Row, Select, Space, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/admin-console/obligations/library/add')({
    staticData: {
        breadcrumb: {
            title: 'New obligation',
        },
    },
    component: ObligationsLibraryItemAdd,
});

type ObligationLibraryInput = RouterInputs['admin']['obligationLibrary']['createObligationLibraryItem'];

type ControlLibraryItem = RouterOutputs['admin']['controlLibrary']['listControlLibrary']['rows'][number];

const FormItem = Form.Item<ObligationLibraryInput>;

function ObligationsLibraryItemAdd() {
    const { data: customLabelsConfig, loading: customLabelsLoading } = useConfigServiceLoader((service) =>
        service.fetchObligationLibraryLabels(),
    );
    const { data: legislations, loading: legislationsLoading } = useConfigServiceLoader((service) =>
        service.fetchObligation_LegislationItems(),
    );
    const { mutateAsync: createObligationLibraryItem, isPending: createLoading } =
        trpc.admin.obligationLibrary.createObligationLibraryItem.useMutation();
    const navigate = useNavigate();

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [obligationControls, setObligationControls] = useState<ControlLibraryItem[]>([]);
    const [breachExamples, setBreachExamples] = useState<string[]>([]);

    const [form] = Form.useForm<ObligationLibraryInput>();

    const onFinish = async (values: ObligationLibraryInput) => {
        try {
            message.loading('Saving...');
            console.log(values);
            const { id } = await createObligationLibraryItem({
                ...values,
                breachExamples,
                controlIds: obligationControls.map(({ id }) => id),
            });

            message.success('Saved.');
            navigate({
                to: '/admin-console/obligations/library/$id/$section',
                params: {
                    id,
                    section: 'details',
                },
            });
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };
    useEffect(() => {
        form.resetFields();
    }, [open]);

    const handleSelectControls = (controls: ControlLibraryItem[]) => {
        const selectedControlIds = controls.map((control) => control?.id);
        const updatedObligationControls = [
            ...obligationControls.filter((control) => selectedControlIds.includes(control?.id)),
            ...controls.filter((control) => !obligationControls.some((c) => c.id === control?.id)),
        ];
        setObligationControls(updatedObligationControls);
        setDrawerVisible(false);
    };

    return (
        <>
            <Drawer
                title="Select Controls"
                width={900}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                destroyOnClose
            >
                <ControlLibraryTable
                    onSelect={handleSelectControls}
                    onCancel={() => setDrawerVisible(false)}
                    selectedControls={obligationControls}
                />
            </Drawer>

            <Form form={form} id="obligationEdit" layout="vertical" onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card title="New Obligation">
                                    <Row gutter={[16, 16]}>
                                        <Col span={8}>
                                            <FormItem
                                                label="Legislation"
                                                name="legislation"
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Select
                                                    loading={legislationsLoading}
                                                    showSearch
                                                    options={legislations?.map(({ label, internalName }) => ({
                                                        label,
                                                        value: internalName,
                                                    }))}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem label="Country" name="country" rules={[{ required: true }]}>
                                                <CountrySelect />
                                            </FormItem>
                                        </Col>
                                        <Col span={18}>
                                            <FormItem
                                                label="Core obligation"
                                                name="coreObligation"
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem label="Owner" name="owner" required rules={[{ required: true }]}>
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                label="What it means"
                                                name="whatItMeans"
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <RichTextInput />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Card title="Source of obligation">
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <FormItem label="Sub obligation / section / paragraph" name="section">
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                label="Exact wording from the legislation"
                                                name="legislationDescription"
                                            >
                                                <RichTextInput />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Austlii link">
                                                <ArrayInput
                                                    name="austLIIURLs"
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [{ type: 'url', required: true }],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Federal Register of Legislation link">
                                                <ArrayInput
                                                    name="federalRegisterOfLegislationURLs"
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [{ type: 'url', required: true }],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Card title="Regulatory guidance">
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <FormItem label="Regulatory guidance" name="regulatoryGuidance">
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Regulator" name="regulator">
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                label="Exact wording from the Regulatory guide"
                                                name="regulatoryGuidanceDescription"
                                            >
                                                <RichTextInput />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Links">
                                                <ArrayInput
                                                    name="regulatoryGuidanceURLs"
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [{ type: 'url', required: true }],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <BreachExamplesProvider
                                    saveField={(value) => {
                                        setBreachExamples(value);
                                    }}
                                >
                                    <BreachExamples breachExamples={breachExamples || []} />
                                </BreachExamplesProvider>
                            </Col>

                            <Col span={24}>
                                <Card title="Labels" loading={customLabelsLoading}>
                                    <Row gutter={[16, 16]}>
                                        {customLabelsConfig?.map((labelConfig) => {
                                            const isMultiSelect = labelConfig.type === 'MULTI_SELECT';
                                            return (
                                                <Col span={6} key={labelConfig.id}>
                                                    <Form.Item
                                                        label={labelConfig.label}
                                                        name={['customLabels', labelConfig.id]}
                                                    >
                                                        <Select
                                                            mode={isMultiSelect ? 'multiple' : undefined}
                                                            options={labelConfig.options.map((option) => ({
                                                                label: option.label,
                                                                value: option.internalName,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Space className="flex justify-between">
                                    <Button
                                        icon={<ArrowLeftOutlined />}
                                        onClick={() => {
                                            navigate({
                                                to: '/admin-console/obligations/library',
                                            });
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        form="obligationEdit"
                                        loading={createLoading}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card
                                    title="Key controls"
                                    extra={
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={() => {
                                                setDrawerVisible(true);
                                            }}
                                        >
                                            Add control
                                        </Button>
                                    }
                                >
                                    <List
                                        dataSource={obligationControls}
                                        size="small"
                                        loading={createLoading}
                                        className="overflow-auto"
                                        renderItem={(item) => (
                                            <List.Item
                                                className="flex w-full items-center justify-between p-2"
                                                actions={[
                                                    <Button
                                                        key={item.id}
                                                        type="text"
                                                        icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                                        onClick={() => {
                                                            setObligationControls(
                                                                obligationControls.filter(
                                                                    (control) => control.id !== item.id,
                                                                ),
                                                            );
                                                        }}
                                                    />,
                                                ]}
                                            >
                                                <div className="flex-1 overflow-hidden">
                                                    <Tooltip title={item.objective}>
                                                        <Typography.Paragraph
                                                            ellipsis
                                                            className="m-0 overflow-hidden text-ellipsis whitespace-nowrap"
                                                        >
                                                            {item.objective}
                                                        </Typography.Paragraph>
                                                    </Tooltip>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
