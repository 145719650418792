import { useConfigService } from '@client/hooks/Configuration/useConfigService';
import { ConfigService } from '@client/services/ConfigService';
import { useEffect, useState } from 'react';

export function useConfigServiceLoader<T>(loader: (configService: ConfigService) => Promise<T>) {
    const configService = useConfigService();

    const [result, setResult] = useState<{ loading: boolean; data?: T }>({
        loading: true,
        data: undefined,
    });

    useEffect(() => {
        loader(configService).then((data) => setResult({ loading: false, data }));
    }, []);

    return result;
}
