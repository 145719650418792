import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export function usePolicyOutsideOfTerms({
    policyStatus,
    policyTermFrom,
}: {
    policyStatus: string;
    policyTermFrom: Date | null | undefined;
}) {
    const [outside, setOutside] = useState(false);
    useEffect(() => {
        if (!policyTermFrom) {
            return;
        }
        const outside =
            policyStatus?.toLowerCase() === 'expired' ||
            DateTime.now().diff(DateTime.fromJSDate(policyTermFrom), 'months').months > 12;
        setOutside(outside);
    }, [policyStatus, policyTermFrom]);

    return outside;
}
