import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DatePicker } from '@client/components/Common/DatePicker';
import { Link } from '@client/components/Common/Link';
import { TaskTable } from '@client/components/Task/TaskTable';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { getTaskStatus, getTaskStatusUI } from '@client/utils/task';
import { formatDate } from '@shared/utils/formatting';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Calendar, Card, Col, Row, Segmented, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useGetWorkloadManagerTaskFilters } from '../~route';

const VIEW_OPTIONS = ['day', 'week', 'month'] as const;

type ViewOption = (typeof VIEW_OPTIONS)[number];

export const Route = createFileRoute('/console/$orgId/workload-manager/tasks/calendar')({
    staticData: {
        metadata: {
            title: 'Task calendar',
        },
        breadcrumb: {
            title: 'Tasks',
        },
    },
    validateSearch: zodValidator(
        z.object({
            view: z.enum(VIEW_OPTIONS).catch('month'),
            viewDate: z.coerce.date().optional(),
            date: z.coerce.date().catch(new Date()),
        }),
    ),
    component: TaskCalendar,
});

function LeftCalendar() {
    const { date } = Route.useSearch();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    // Workaround for warning in browser console when we set open={true} directly
    useEffect(() => {
        setOpen(true);
    }, []);

    const CLASS_NAME = 'left-calendar-container';

    return (
        <Card classNames={{ body: clsx(CLASS_NAME, 'pt-4 pb-3') }}>
            <DatePicker
                open={open}
                value={date}
                getPopupContainer={() => document.querySelector(`.${CLASS_NAME}`)!}
                className="hidden"
                popupClassName={clsx(
                    'static',
                    '[&_.ant-picker-panel-container]:shadow-none',
                    '[&_.ant-picker-month-panel]:w-auto',
                    '[&_.ant-picker-year-panel]:w-auto',
                    '[&_.ant-picker-decade-panel]:w-auto',
                    '[&_.ant-picker-header]:px-0',
                    '[&_.ant-picker-body]:px-0 [&_.ant-picker-body]:pb-0',
                    '[&_.ant-picker-date-panel]:w-full',
                )}
                onChange={(date) =>
                    navigate({
                        from: Route.fullPath,
                        search(prev) {
                            return {
                                ...prev,
                                date,
                                viewDate: undefined,
                            };
                        },
                    })
                }
            />
        </Card>
    );
}

function DayView() {
    const searchParams = Route.useSearch();
    const filters = useGetWorkloadManagerTaskFilters();

    const date = searchParams.viewDate || searchParams.date;
    const now = DateTime.now();

    return (
        <TaskTable
            from="/console/$orgId/workload-manager/tasks/calendar"
            filters={{
                dueDate:
                    date < now.startOf('day').toJSDate()
                        ? {
                              // Workaround to not fetch any tasks that are due in the past
                              gt: now.plus({ days: 1 }).toJSDate(),
                              lt: now.minus({ days: 1 }).toJSDate(),
                          }
                        : {
                              ...(formatDate(date) !== formatDate(new Date()) && {
                                  gte: DateTime.fromJSDate(date).startOf('day').toJSDate(),
                              }),
                              lte: DateTime.fromJSDate(date).endOf('day').toJSDate(),
                          },
                ...filters,
            }}
        />
    );
}

function TaskTag({
    id,
    title,
    dueDate,
    isCompleted,
    className,
}: Pick<RouterOutputs['task']['getTask'], 'id' | 'title' | 'dueDate' | 'isCompleted'> & { className?: string }) {
    const orgId = useOrgId();

    return (
        <Tooltip title={title}>
            <Link
                to="/console/$orgId/workload-manager/tasks/calendar/details/$id"
                params={{ orgId, id }}
                type="link"
                className={clsx('mb-2 flex w-full', className)}
                search={(prev) => prev}
                onClick={(e) => e.stopPropagation()}
            >
                <Tag
                    color={`${getTaskStatusUI({ dueDate, isCompleted }).color}-inverse`}
                    className="mr-0 w-full truncate"
                >
                    {title}
                </Tag>
            </Link>
        </Tooltip>
    );
}

function WeekView() {
    const searchParams = Route.useSearch();
    const filters = useGetWorkloadManagerTaskFilters();
    const navigate = useNavigate();

    const date = searchParams.viewDate || searchParams.date;

    const { data, isPending } = trpc.task.listTasks.useQuery(
        {
            where: {
                dueDate: {
                    lte: dayjs(date).endOf('week').toDate(),
                },
                isCompleted: false,
                ...filters,
            },
            orderBy: [{ dueDate: 'asc' }, { title: 'asc' }],
            limit: 100,
            offset: 0,
        },
        {
            select(data) {
                const now = new Date();

                return {
                    rows: [
                        data.rows.reduce(
                            (prev, item) => {
                                const key = formatDate(getTaskStatus(item) === 'OVERDUE' ? now : item.dueDate);

                                return {
                                    ...prev,
                                    [key]: [...(prev[key] || []), item],
                                };
                            },
                            {} as Record<string, typeof data.rows>,
                        ),
                    ],
                    total: 1,
                };
            },
        },
    );

    type Row = NonNullable<typeof data>['rows'][number];

    const { tableProps } = useAntdTable<Row>({
        rowKey() {
            return 'key';
        },
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        columns: Array.from({ length: 7 }, (_, i) => {
            const updatedDate = dayjs(date).startOf('week').add(i, 'day').toDate();
            const dateShortFormat = formatDate(updatedDate);
            const isSelectedDate = dateShortFormat === formatDate(searchParams.date);

            return {
                title: (
                    <>
                        {formatDate(updatedDate, 'EEEE')}
                        <br />
                        {updatedDate.getDate()}
                    </>
                ),
                dataIndex: dateShortFormat,
                align: 'center',
                onCell() {
                    return {
                        className: clsx(
                            'cursor-pointer p-0 align-top',
                            dateShortFormat === formatDate(new Date()) && 'border-t-2 border-t-primary',
                            // This color (#fff8f0) is hardcoded because antd css variable for this color is defined only inside Calendar component
                            isSelectedDate && 'bg-[#fff8f0] hover:bg-[#fff8f0]!',
                            !isSelectedDate && 'hover:bg-(--ant-control-item-bg-hover)',
                        ),
                        onClick(e) {
                            navigate(
                                {
                                    from: Route.fullPath,
                                    search(prev) {
                                        return {
                                            ...prev,
                                            date: updatedDate,
                                            view: 'day',
                                        };
                                    },
                                },
                                e,
                            );
                        },
                    };
                },
                render(value: Row[string]) {
                    return (
                        <div className="max-h-100 min-h-20 overflow-y-auto p-(--ant-table-cell-padding-block)">
                            {value?.map((item) => <TaskTag key={item.id} className="last:mb-0" {...item} />)}
                        </div>
                    );
                },
            };
        }),
    });

    return (
        <Table
            rowHoverable={false}
            tableLayout="fixed"
            bordered
            onRow={() => ({
                className: 'border-b-0',
            })}
            {...tableProps}
        />
    );
}

function MonthView() {
    const searchParams = Route.useSearch();
    const filters = useGetWorkloadManagerTaskFilters();
    const navigate = useNavigate();

    const date = searchParams.viewDate || searchParams.date;

    const { data, isPending } = trpc.task.listTasks.useQuery(
        {
            where: {
                dueDate: {
                    lte: DateTime.fromJSDate(date).endOf('month').toJSDate(),
                },
                isCompleted: false,
                ...filters,
            },
            orderBy: [{ dueDate: 'asc' }, { title: 'asc' }],
            limit: 100,
            offset: 0,
        },
        {
            select(data) {
                const now = new Date();

                return {
                    ...data,
                    rows: data.rows.reduce(
                        (prev, item) => {
                            const key = formatDate(getTaskStatus(item) === 'OVERDUE' ? now : item.dueDate);

                            return {
                                ...prev,
                                [key]: [...(prev[key] || []), item],
                            };
                        },
                        {} as Record<string, typeof data.rows>,
                    ),
                };
            },
        },
    );
    return (
        <Spin spinning={isPending} className="max-h-none">
            <Calendar
                headerRender={() => null}
                value={dayjs(date)}
                className={clsx(
                    searchParams.viewDate &&
                        formatDate(searchParams.viewDate) !== formatDate(searchParams.date) &&
                        [
                            '[&_.ant-picker-cell-selected>.ant-picker-cell-inner]:bg-(--ant-color-bg-container)',
                            '[&_.ant-picker-cell-selected>.ant-picker-cell-inner]:hover:bg-(--ant-control-item-bg-hover)',
                            '[&_.ant-picker-cell-selected_.ant-picker-calendar-date-value]:text-(--ant-color-text)',
                        ].join(' '),
                )}
                onSelect={(date) =>
                    navigate({
                        from: Route.fullPath,
                        search(prev) {
                            return { ...prev, date: date.toDate(), view: 'day' };
                        },
                    })
                }
                cellRender={(date) =>
                    data?.rows[formatDate(date.toDate())]?.map((item) => <TaskTag key={item.id} {...item} />)
                }
            />
        </Spin>
    );
}

function DateSelectorLabel() {
    const { view, ...searchParams } = Route.useSearch();

    const date = searchParams.viewDate || searchParams.date;

    if (view === 'day') {
        return formatDate(date, 'dd MMM yyyy');
    }

    if (view === 'week') {
        const startOfWeek = dayjs(date).startOf('week');
        const endOfWeek = dayjs(date).endOf('week');

        return `${startOfWeek.date()} ${
            endOfWeek.date() < startOfWeek.date()
                ? formatDate(startOfWeek.toDate(), startOfWeek.year() < endOfWeek.year() ? 'MMM yyyy' : 'MMM')
                : ''
        } - ${endOfWeek.date()} ${formatDate(endOfWeek.toDate(), 'MMM yyyy')}`;
    }

    return formatDate(date, 'MMM yyyy');
}

function TaskCalendar() {
    const { view } = Route.useSearch();
    const navigate = useNavigate();

    return (
        <>
            <Outlet />
            <Row gutter={16}>
                <Col span={6}>
                    <LeftCalendar />
                </Col>
                <Col span={18}>
                    <Card>
                        <div className="relative mb-4 flex justify-center">
                            <div className="absolute left-0 flex items-center gap-2 text-gray-500">
                                <Link
                                    icon={<LeftOutlined />}
                                    type="text"
                                    from={Route.fullPath}
                                    search={(prev) => ({
                                        ...prev,
                                        viewDate: DateTime.fromJSDate(prev.viewDate || prev.date)
                                            .minus({
                                                [view === 'day' ? 'days' : view === 'week' ? 'weeks' : 'months']: 1,
                                            })
                                            .toJSDate(),
                                    })}
                                />
                                <Typography.Text strong>
                                    <DateSelectorLabel />
                                </Typography.Text>
                                <Link
                                    icon={<RightOutlined />}
                                    type="text"
                                    from={Route.fullPath}
                                    search={(prev) => ({
                                        ...prev,
                                        viewDate: DateTime.fromJSDate(prev.viewDate || prev.date)
                                            .plus({
                                                [view === 'day' ? 'days' : view === 'week' ? 'weeks' : 'months']: 1,
                                            })
                                            .toJSDate(),
                                    })}
                                />
                            </div>
                            <Segmented<ViewOption>
                                value={view}
                                options={[
                                    { label: 'Day', value: 'day' },
                                    { label: 'Week', value: 'week' },
                                    { label: 'Month', value: 'month' },
                                ]}
                                onChange={(value) =>
                                    navigate({
                                        from: Route.fullPath,
                                        search(prev) {
                                            return {
                                                ...prev,
                                                view: value,
                                            };
                                        },
                                    })
                                }
                            />
                        </div>
                        {view === 'day' && <DayView />}
                        {view === 'week' && <WeekView />}
                        {view === 'month' && <MonthView />}
                    </Card>
                </Col>
            </Row>
        </>
    );
}
