import { IsNever } from 'type-fest';
import { Route } from './navRoutes';
import { mapObjectValues } from '../utils/object';

function invariant(value: boolean, message?: string) {
    if (value === false || value === null || typeof value === 'undefined') {
        throw new Error(message);
    }
}

type _PathParam<Path extends string> =
    // split path into individual path segments
    Path extends `${infer L}/${infer R}`
        ? _PathParam<L> | _PathParam<R>
        : // find params after `$`
          Path extends `${string}$${infer Param}`
          ? Param
          : // otherwise, there aren't any params present
            never;

type PathParam<Path extends string> =
    // check if path is just a wildcard
    Path extends '*'
        ? '*'
        : // look for wildcard at the end of the path
          Path extends `${infer Rest}/*`
          ? '*' | _PathParam<Rest>
          : // look for params in the absence of wildcards
            _PathParam<Path>;

export function generatePath<R extends Route>(
    route: R,
    params: IsNever<PathParam<R['path']>> extends true
        ? undefined
        : Record<PathParam<R['path']>, string | number | bigint>,
    searchParams?: Partial<Record<keyof R['searchParams'], string | number | bigint>>,
    anchor?: string,
) {
    const path = params
        ? route.path
              .replace(/\$(\w+)/g, (_, key: PathParam<R['path']>) => {
                  invariant(params[key] !== null, `Missing ":${key}" param`);
                  return String(params[key]);
              })
              .replace(/(\/?)\*/, (_, prefix, __, str) => {
                  const star = '*' as PathParam<R['path']>;

                  if (params[star] == null) {
                      // If no splat was provided, trim the trailing slash _unless_ it's
                      // the entire path
                      return str === '/*' ? '/' : '';
                  }

                  // Apply the splat
                  return `${prefix}${params[star]}`;
              })
        : route.path;

    const modifiedSearchParams = searchParams
        ? `?${new URLSearchParams(mapObjectValues(searchParams, ([, value]) => String(value))).toString()}`
        : '';
    const modifiedAnchor = anchor ? `#${anchor}` : '';

    return `${path}${modifiedSearchParams}${modifiedAnchor}`;
}
