export namespace SupplierDefs {
    export const aryCategory = ['assessment', 'quote', 'repair', 'restoration', 'make safe'];
    export const states: { [key: string]: string[] } = {
        Australia: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
    };
    export const aryServicingAreaState = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
    export const aryAfslStatus = ['holder', 'authorized representative', 'none'];

    export enum JobStatusEnum {
        new = 'New',
        accepted = 'Accepted',
        inProgress = 'In progress',
        completed = 'Completed',
    }

    export const aryJobStatus = [
        JobStatusEnum.new,
        JobStatusEnum.accepted,
        JobStatusEnum.inProgress,
        JobStatusEnum.completed,
    ];
}
