import { useTrpc } from '@client/hooks/useTrpc';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Alert, Card } from 'antd';
import { useEffect } from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { InvalidInvite } from './steps/InvalidInvite';
import { InviteDetails } from './steps/InviteDetails';
import { Loading } from './steps/Loading';
import { PasswordForm } from './steps/PasswordForm';
import { ProcessingSignUp } from './steps/ProcessingSignUp';
import { SignInMethodSelection } from './steps/SignInMethodSelection';
import { SignUpComplete } from './steps/SignUpComplete';
import { UserAlreadyExists } from './steps/UserAlreadyExists';
import { useStore } from './store';

const searchParamsSchema = z.object({
    inviteSecret: z.string(),
    email: z.string(),
});

export const Route = createFileRoute('/invite')({
    component: InviteSignUp,
    validateSearch: zodValidator(searchParamsSchema),
});

function InviteSignUp() {
    const { inviteSecret, email } = Route.useSearch();
    const { trpc } = useTrpc();
    const currentStep = useStore((state) => state.currentStep);
    const errorCode = useStore((state) => state.errorCode);
    const errorMessage = useStore((state) => state.errorMessage);
    const { setInviteSecret, setCurrentStep, setInviteData, setError } = useStore((state) => state.actions);
    const { mutate: validateInviteLink } = trpc.user.validateInviteLink.useMutation({
        onError() {
            return false;
        },
    });

    useEffect(() => {
        // Set loading state
        setCurrentStep('loading');

        validateInviteLink(
            { inviteSecret },
            {
                onSuccess: () => {
                    setInviteSecret(inviteSecret);
                    setInviteData({
                        email: email,
                        firstName: '',
                        lastName: '',
                        inviteSecret,
                    });
                    setCurrentStep('inviteDetails');
                },
                onError: (error) => {
                    match(error.data?.domainCode)
                        .with('invite.linkNotFound', () => setCurrentStep('invalidInvite'))
                        .with('invite.linkExpired', () => setCurrentStep('invalidInvite'))
                        .with('invite.userAlreadyExists', () => setCurrentStep('userAlreadyExists'))
                        .otherwise(() => {
                            setError({
                                errorCode: 'unknown',
                                errorMessage: error.message,
                            });
                        });
                },
            },
        );
    }, [inviteSecret]);

    const renderStep = () => {
        return match(currentStep)
            .with('loading', () => <Loading />)
            .with('inviteDetails', () => <InviteDetails />)
            .with('signInMethodSelection', () => <SignInMethodSelection />)
            .with('passwordForm', () => <PasswordForm />)
            .with('processingSignUp', () => <ProcessingSignUp />)
            .with('signUpComplete', () => <SignUpComplete />)
            .with('invalidInvite', () => <InvalidInvite />)
            .with('userAlreadyExists', () => <UserAlreadyExists />)
            .exhaustive();
    };

    return (
        <div className="min-h-screen w-full bg-gray-50">
            <div className="flex min-h-screen items-center justify-center">
                <div className="w-full max-w-sm">
                    {errorCode && (
                        <Alert message="Error" description={errorMessage} type="error" className="mx-4 mb-4" />
                    )}
                    <Card className="mx-4 my-8">{renderStep()}</Card>
                </div>
            </div>
        </div>
    );
}
