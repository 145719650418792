import React from 'react';

export function CardTitle({ title, subTitle }: { title: string; subTitle?: string }) {
    return (
        <>
            <div>{title}</div>
            {subTitle && <div className="font-normal pt-2 text-gray-400">{subTitle}</div>}
        </>
    );
}
