import { RISK_CONFIGURATION_SECTIONS, RiskConfigurationSection } from '@shared/navigation/navRoutes';
import { Tabs, TabsProps } from 'antd';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { ConfigurationRiskAppetite } from './RiskAppetite';
import { ConfigurationRiskCategories } from './RiskCategories';
import { ConfigurationRiskMatrix } from './RiskMatrix';
import { RiskMatrixContextProvider } from '@client/components/Risk/RiskMatrix/RiskMatrixContext';
import { RiskMatrixActions } from '@client/components/Risk/RiskMatrixActions';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@client/hooks/useNavigate';

export const Route = createFileRoute('/console/$orgId/configuration/risk/$section')({
    staticData: {
        ui: {
            title: 'Risk configuration',
        },
    },
    params: {
        parse({ section }) {
            return {
                section: section as RiskConfigurationSection,
            };
        },
    },
    validateSearch({ newCategoryId }): Partial<{ newCategoryId: string }> {
        return {
            newCategoryId: newCategoryId ? String(newCategoryId) : undefined,
        };
    },
    component: ConfigurationRisk,
});

function ConfigurationRisk() {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const { section } = Route.useParams();

    const items: TabsProps['items'] = [
        {
            key: RISK_CONFIGURATION_SECTIONS.APPETITE,
            label: 'Risk Appetite',
            destroyInactiveTabPane: true,
            children: <ConfigurationRiskAppetite />,
        },
        {
            key: RISK_CONFIGURATION_SECTIONS.CATEGORIES,
            label: 'Risk Categories',
            destroyInactiveTabPane: true,
            children: <ConfigurationRiskCategories />,
        },
        {
            key: RISK_CONFIGURATION_SECTIONS.MATRIX,
            label: 'Risk Matrix',
            destroyInactiveTabPane: true,
            children: <ConfigurationRiskMatrix />,
        },
    ];

    return (
        <RiskMatrixContextProvider>
            <Tabs
                type="line"
                activeKey={section}
                onTabClick={(key) =>
                    navigate({
                        to: '/console/$orgId/configuration/risk/$section',
                        params: {
                            orgId,
                            section: key as RiskConfigurationSection,
                        },
                    })
                }
                items={items}
                tabBarExtraContent={{
                    right: section === RISK_CONFIGURATION_SECTIONS.MATRIX ? <RiskMatrixActions /> : null,
                }}
            />
        </RiskMatrixContextProvider>
    );
}
