import * as React from 'react';
import { Dayjs } from 'dayjs';
import { TimePicker as AntdTimePicker } from 'antd';
import { isoToDayJs } from '@client/utils/dateTime';
import { DateTime } from 'luxon';
import { PickerTimeProps } from 'antd/es/time-picker';

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'value' | 'onChange'> {
    value?: string | null | undefined;
    onChange?: (value: string | null | undefined) => void;
}

export const TimePicker = React.forwardRef<any, TimePickerProps>((props: TimePickerProps, ref) => {
    const { value, onChange, ...rest } = props;
    const _value = isoToDayJs(value);
    return (
        <AntdTimePicker
            {...rest}
            value={_value}
            onChange={(dtValue) => {
                if (dtValue === null) {
                    onChange?.(null);
                } else {
                    onChange?.(DateTime.fromJSDate(dtValue.toDate()).toISO());
                }
            }}
            ref={ref}
        />
    );
});

TimePicker.displayName = 'TimePicker';
