import { errorNotification } from '@client/components/Common/Notification/errorNotification';
import { useAuthStore } from '@client/stores/AuthStore';
import { isAuthRoute } from '@client/utils/auth';
import { isJwtExpired } from '@client/utils/jwt';
import type { AppRouter } from '@server/routes/root';
import { decodeJwtToken } from '@shared/utils/jwt';
import { QueryClient } from '@tanstack/react-query';
import { TRPCLink, httpBatchStreamLink } from '@trpc/client';
import { createTRPCQueryUtils, createTRPCReact, inferReactQueryProcedureOptions } from '@trpc/react-query';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { observable } from '@trpc/server/observable';
import Decimal from 'decimal.js';
import { DateTime } from 'luxon';
import { load } from 'recaptcha-v3';
import { SuperJSON } from 'superjson';

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;

export type RouterInputs = inferRouterInputs<AppRouter>;

export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export type TrpcClient = ReturnType<typeof trpc.useUtils>;

export type StaleTime = number | 'immediate' | 'infinite' | 'default';

export function getStaleTime(staleTime?: StaleTime) {
    if (!staleTime || typeof staleTime === 'number') {
        return staleTime;
    }

    return {
        immediate: 0,
        infinite: Infinity,
        default: undefined,
    }[staleTime];
}

// Replace notification.error calls in the QueryClient's defaultOptions:
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            enabled: () => {
                if (useAuthStore.getState().authTokenExpired && isAuthRoute()) {
                    return false;
                }
                return true;
            },
            throwOnError(error) {
                if (useAuthStore.getState().authTokenExpired && isAuthRoute()) {
                    return false;
                }
                errorNotification(error);
                return false;
            },
        },
        mutations: {
            retry: false,
            onError(error) {
                errorNotification(error);
            },
            throwOnError() {
                return false;
            },
        },
    },
});

SuperJSON.registerCustom<Decimal, string>(
    {
        isApplicable(value): value is Decimal {
            return Decimal.isDecimal(value);
        },
        serialize(value) {
            return value.toJSON();
        },
        deserialize(value) {
            return new Decimal(value);
        },
    },
    'decimal.js',
);

const authTokenCheckLink: TRPCLink<AppRouter> = () => {
    return ({ next, op }) => {
        return observable((observer) => {
            const authStore = useAuthStore.getState();
            const token = authStore.getAuthToken();
            // If token is not set, it is expired
            const isExpired = !token || isTokenExpired(token);

            if (isExpired) {
                if (isAuthRoute()) {
                    authStore.setAuthTokenExpired(true);
                    authStore.clearAuthToken();
                    observer.complete();
                    return;
                }
                authStore.clearAuthToken();
            }

            const unsubscribe = next(op).subscribe({
                next(value) {
                    observer.next(value);
                },
                complete() {
                    observer.complete();
                },
                error(error) {
                    observer.error(error);
                },
            });

            return unsubscribe;
        });
    };
};

export const trpcClient = trpc.createClient({
    links: [
        authTokenCheckLink,
        httpBatchStreamLink({
            url: (import.meta.env.VITE_API_URL || location.origin) + '/trpc',
            maxItems: 50,
            maxURLLength: 10000,
            transformer: SuperJSON,
            async headers(opts) {
                const headers: Record<string, string> = {
                    'x-page-url': window.location.href,
                    'x-user-timezone': DateTime.now().zoneName,
                };
                if (opts.opList.some((op) => op.context?.reCaptcha === true)) {
                    const recaptcha = await load(import.meta.env.VITE_RECAPTCHA_SITE_KEY);
                    const actionName = opts.opList[0].path;
                    // Sanitize action name
                    const sanitizedActionName = actionName.replace(/[^a-zA-Z]/g, '_');
                    const token = await recaptcha.execute(sanitizedActionName);
                    headers['x-recaptcha-token'] = token;
                }
                const token = useAuthStore.getState().getAuthToken();
                if (token) {
                    headers['authorization'] = `Bearer ${token}`;
                }
                return headers;
            },
        }),
    ],
});

export const trpcQueryUtils = createTRPCQueryUtils({
    queryClient,
    client: trpcClient,
});

function isTokenExpired(token: string | undefined) {
    if (!token) {
        return true;
    }
    const decoded = decodeJwtToken(token);
    if (decoded) {
        return isJwtExpired(decoded.exp);
    }
    return true;
}
