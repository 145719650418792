import { EmailDetails } from '@client/components/Email/EmailDetails';
import { EmailsTable } from '@client/components/Email/EmailsTable';
import { RouterInputs } from '@client/trpc/client';
import { createFileRoute, useSearch } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Alert, Card } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { z } from 'zod';

export const Route = createFileRoute('/console/$orgId/workload-manager/emails')({
    staticData: {
        metadata: {
            title: 'Emails',
        },
    },
    validateSearch: zodValidator(z.object({ selectedId: z.coerce.bigint().optional() })),
    component: Emails,
});

function Emails() {
    const { enableEmailWorkloadManager } = useFlags();
    const { selectedId } = useSearch({ strict: false });
    const hasSelectedEmail = Boolean(selectedId);

    if (!enableEmailWorkloadManager) {
        return <Alert message="This feature is currently under development." type="warning" />;
    }

    const emailFilters: RouterInputs['email']['listEmails']['where'] = {
        // createdAt: {
        //     gte: DateTime.now().minus({ days: 30 }).toUTC().toJSDate(),
        // },
    };

    const emailsPath = '/console/$orgId/workload-manager/emails';

    return (
        <div className="flex h-[calc(100vh-180px)] min-h-[400px] gap-2 overflow-hidden pb-[10px]">
            <div
                className={`h-full transition-all duration-300 ease-in-out ${
                    hasSelectedEmail ? 'w-[400px] flex-shrink-0' : 'w-full'
                }`}
            >
                <EmailsTable
                    compact={hasSelectedEmail}
                    selectedId={selectedId ? BigInt(selectedId) : undefined}
                    filters={emailFilters}
                    urlPath={emailsPath}
                />
            </div>

            <div
                className={`h-full transition-all duration-300 ease-in-out ${
                    hasSelectedEmail
                        ? 'flex-1 translate-x-0 opacity-100'
                        : 'pointer-events-none w-0 translate-x-8 opacity-0'
                }`}
            >
                <Card
                    className="h-full"
                    styles={{
                        body: {
                            padding: '0 10px 10px 10px',
                            height: '100%',
                            overflow: 'auto',
                        },
                    }}
                >
                    <EmailDetails urlPath={emailsPath} />
                </Card>
            </div>
        </div>
    );
}
