import { Spin } from 'antd';
import clsx from 'clsx';

type Props = {
    loading: boolean;
    size?: 'small' | 'default' | 'large';
    blur?: boolean;
    children: React.ReactNode;
    spinnerPosition?: 'center' | 'end';
};

export const LoadingOverlay = ({
    loading,
    size = 'small',
    blur = true,
    spinnerPosition = 'center',
    children,
}: Props) => {
    return (
        <div className="relative">
            {loading && (
                <div
                    className={clsx(
                        'absolute inset-0 z-50 flex items-center bg-white/50',
                        blur && 'backdrop-blur-[1px]',
                        spinnerPosition === 'center' && 'justify-center',
                        spinnerPosition === 'end' && 'justify-end pr-2',
                    )}
                >
                    <Spin size={size} />
                </div>
            )}
            <div className={clsx(loading && 'pointer-events-none')}>{children}</div>
        </div>
    );
};
