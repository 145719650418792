import { observer } from 'mobx-react-lite';
import React from 'react';
import { ClaimFormState } from '@client/hooks/Claim/useClaimFormState';
import { Button, Drawer, Form, Input } from 'antd';

interface Props {
    formState: ClaimFormState;
    open: boolean;
    onClose: () => void;
    readOnly: boolean;
}

export const AddNote = observer(({ formState, onClose, open, readOnly }: Props) => {
    return (
        <Drawer
            open={open}
            width={600}
            title="Add note"
            onClose={onClose}
            footer={
                <Button
                    type="primary"
                    size="large"
                    className="w-full"
                    htmlType="submit"
                    form="claimAddNoteForm"
                    onClick={onClose}
                >
                    Close
                </Button>
            }
        >
            <Form layout="vertical">
                <Form.Item label="Note">
                    <Input.TextArea
                        autoSize={{
                            minRows: 6,
                        }}
                        value={formState.note || ''}
                        onChange={(e) => {
                            formState.note = e.target.value;
                        }}
                        disabled={readOnly}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
});
