import { DatePicker } from '@client/components/Common/DatePicker';
import { message } from '@client/components/Common/message';
import { UserSelector } from '@client/components/Common/UserSelector';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUserId } from '@client/hooks/User/useUserId';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterInputs, trpc } from '@client/trpc/client';
import { TASK_RELATED_ENTITIES } from '@shared/definitions/task';
import { Button, Form, FormInstance, Input, Switch } from 'antd';
import { DateTime } from 'luxon';
import { useId } from 'react';
import { z } from 'zod';
import { OverlayPage } from '../Layout/OverlayPage';

export const TaskAddSearchSchema = z.union([
    z.object({
        relatedEntity: z.enum(TASK_RELATED_ENTITIES),
        relatedEntityId: z.coerce.bigint(),
    }),
    z
        .object({
            relatedEntity: z.enum(TASK_RELATED_ENTITIES),
            relatedEntityId: z.coerce.bigint(),
        })
        .partial(),
]);

type Task = RouterInputs['task']['createTask'];

const FormItem = Form.Item<Task>;

function Content({ form, formId }: { form: FormInstance<Task>; formId: string }) {
    const { relatedEntity, relatedEntityId } = {
        relatedEntity: undefined,
        relatedEntityId: undefined,
    } as z.infer<typeof TaskAddSearchSchema>;
    // const { relatedEntity, relatedEntityId } = useSearch({ strict: false });
    const { mutate } = trpc.task.createTask.useMutation();
    const { onClose } = OverlayPage.useContext();

    function onFinish(values: Task) {
        message.loading('Saving...');

        mutate(
            {
                ...values,
                dueDate: DateTime.fromJSDate(values.dueDate).startOf('day').toJSDate(),
                ...(values.assignedToId && { assignedToId: BigInt(values.assignedToId) }),
                ...(relatedEntity && relatedEntityId && { [`${relatedEntity}Id`]: BigInt(relatedEntityId) }),
            },
            {
                onSuccess() {
                    message.success('Saved.');
                    onClose();
                },
            },
        );
    }

    return (
        <Form form={form} layout="vertical" id={formId} onFinish={onFinish}>
            <FormItem name="title" label="Task name" rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem name="description" label="Description">
                <Input.TextArea autoSize={{ minRows: 6, maxRows: 18 }} />
            </FormItem>
            <FormItem name="dueDate" label="Due date" rules={[{ required: true }]}>
                <DatePicker />
            </FormItem>
            <FormItem name="assignedToId" label="Assigned to">
                <UserSelector />
            </FormItem>
            <FormItem name="isCompleted" label="Completed" valuePropName="checked">
                <Switch defaultChecked={false} />
            </FormItem>
        </Form>
    );
}

export function TaskEdit() {
    const userId = useUserId();
    const formId = useId();
    const { trpcUtils } = useTrpc();
    const navigate = useNavigate();

    const [form] = Form.useForm<Task>();

    return (
        <OverlayPage
            title="New task"
            form={form}
            initForm={() => form.setFieldsValue({ assignedToId: userId })}
            afterClose={() => {
                void trpcUtils.task.invalidate();
                navigate({ moveInMatches: -1, keepSearch: true });
            }}
            footer={({ onClose }) => (
                <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form={formId}>
                        Save
                    </Button>
                </>
            )}
        >
            <Content form={form} formId={formId} />
        </OverlayPage>
    );
}
