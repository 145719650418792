import { Link } from '@client/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';
import { Result } from 'antd';

export const Route = createFileRoute('/not-found')({
    component: NotFound,
});

function NotFound() {
    return (
        <div className="grid h-screen w-full place-items-center bg-gray-50">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to="/">Back to Home</Link>}
                className="mx-auto w-full max-w-lg rounded-lg bg-white p-8 shadow-xs"
            />
        </div>
    );
}
