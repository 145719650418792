import { BugOutlined, SyncOutlined } from '@ant-design/icons';
import { message } from '@client/components/Common/message';
import { useAppStateStore } from '@client/stores/AppStateStore';
import { selectUser, useAuthStore } from '@client/stores/AuthStore';
import { useDebugStore } from '@client/stores/DebugStore';
import { isAuthRoute } from '@client/utils/auth';
import { refreshSwCache } from '@client/utils/serviceWorkerUtils';
import { TanStackRouterDevtools } from '@tanstack/react-router-devtools';
import { Button, Collapse, Divider, FloatButton, Popover, Space, Table, Tag, Tooltip } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useState } from 'react';
import ReactJson from 'react-json-view';

export function DebugInfo() {
    const flags = useFlags();
    const user = useAuthStore(selectUser);
    const { isForceDebugEnabled, setIsForceDebugEnabled, isForceJwtExpiredEnabled, setIsForceJwtExpiredEnabled } =
        useDebugStore();
    const appBuildVersion = useAppStateStore((state) => state.appBuildVersion);
    const appBuildLastUpdatedAt = useAppStateStore((state) => state.appBuildLastUpdatedAt);
    const [open, setOpen] = useState(false);
    const authTokenExpired = useAuthStore((state) => state.authTokenExpired);
    const authToken = useAuthStore((state) => state.getAuthToken());
    if ((!flags.enableDebugInfo || !user) && !isForceDebugEnabled) {
        return null;
    }

    const flagsData = Object.entries(flags).map(([key, value]) => ({
        key,
        flag: key,
        value: JSON.stringify(value),
    }));

    const { data: claims, ...userWithoutClaims } = user || {};

    return (
        <>
            <TanStackRouterDevtools />
            <Popover
                title={
                    <div className="flex items-center justify-between">
                        <span className="text-lg font-semibold">Debug Information</span>
                        <Button type="text" size="small" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                }
                open={open}
                content={
                    <Collapse
                        size="small"
                        className="w-[600px]"
                        items={[
                            {
                                key: '1',
                                label: 'Feature Flags',
                                children: (
                                    <Table
                                        dataSource={flagsData}
                                        columns={[
                                            {
                                                title: 'Flag',
                                                dataIndex: 'flag',
                                                key: 'flag',
                                                width: '40%',
                                            },
                                            {
                                                title: 'Value',
                                                dataIndex: 'value',
                                                key: 'value',
                                                width: '60%',
                                                render: (text) => <code className="text-xs break-all">{text}</code>,
                                            },
                                        ]}
                                        pagination={false}
                                        size="small"
                                        scroll={{ y: 300 }}
                                    />
                                ),
                            },
                            ...(user
                                ? [
                                      {
                                          key: '2',
                                          label: 'User',
                                          children: (
                                              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                  <ReactJson
                                                      src={userWithoutClaims}
                                                      displayDataTypes={false}
                                                      displayObjectSize={false}
                                                      enableClipboard={false}
                                                      theme="rjv-default"
                                                      collapsed={1}
                                                  />
                                              </div>
                                          ),
                                      },
                                  ]
                                : []),
                            ...(claims
                                ? [
                                      {
                                          key: '3',
                                          label: 'Claims',
                                          children: (
                                              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                  <ReactJson
                                                      src={claims}
                                                      displayDataTypes={false}
                                                      enableClipboard={false}
                                                      displayObjectSize={false}
                                                      theme="rjv-default"
                                                      collapsed={1}
                                                  />
                                              </div>
                                          ),
                                      },
                                  ]
                                : []),
                            {
                                key: '4',
                                label: 'Authentication Status',
                                children: (
                                    <div className="flex flex-col gap-4">
                                        <div className="rounded-lg bg-gray-50 p-4 shadow-sm">
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-xs text-gray-500">JWT Token Status</span>
                                                    <Tooltip
                                                        title={
                                                            authTokenExpired
                                                                ? 'JWT token has expired'
                                                                : 'JWT token is valid'
                                                        }
                                                    >
                                                        <Tag
                                                            color={authTokenExpired ? 'error' : 'success'}
                                                            className="w-fit"
                                                        >
                                                            {authTokenExpired ? 'Expired' : 'Valid'}
                                                        </Tag>
                                                    </Tooltip>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-xs text-gray-500">Auth Route</span>
                                                    <Tag
                                                        color={isAuthRoute() ? 'processing' : 'default'}
                                                        className="w-fit"
                                                    >
                                                        {isAuthRoute() ? 'Yes' : 'No'}
                                                    </Tag>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-xs text-gray-500">Auth Token</span>
                                                    <Tag color={authToken ? 'success' : 'warning'} className="w-fit">
                                                        {authToken ? 'Present' : 'Missing'}
                                                    </Tag>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-xs text-gray-500">Debug Mode</span>
                                                    <Tag
                                                        color={isForceDebugEnabled ? 'purple' : 'default'}
                                                        className="w-fit"
                                                    >
                                                        {isForceDebugEnabled ? 'Forced' : 'Normal'}
                                                    </Tag>
                                                </div>
                                            </div>
                                        </div>

                                        <Divider orientation="left" plain className="my-2 text-xs text-gray-500">
                                            Authentication Actions
                                        </Divider>

                                        <Space className="flex flex-wrap gap-2">
                                            <Button
                                                type={isForceJwtExpiredEnabled ? 'primary' : 'default'}
                                                danger={isForceJwtExpiredEnabled}
                                                onClick={() => setIsForceJwtExpiredEnabled(!isForceJwtExpiredEnabled)}
                                                size="small"
                                            >
                                                {isForceJwtExpiredEnabled ? 'Disable' : 'Enable'} Force JWT Expired
                                            </Button>

                                            <Button
                                                onClick={() => useAuthStore.getState().clearAuthToken()}
                                                size="small"
                                            >
                                                Clear Auth Token
                                            </Button>

                                            <Button
                                                onClick={() => setIsForceDebugEnabled(!isForceDebugEnabled)}
                                                type={isForceDebugEnabled ? 'primary' : 'default'}
                                                size="small"
                                            >
                                                {isForceDebugEnabled ? 'Disable' : 'Enable'} Force Debug
                                            </Button>
                                        </Space>
                                    </div>
                                ),
                            },
                            {
                                key: '5',
                                label: 'App Build Version',
                                children: (
                                    <div className="flex flex-col gap-4">
                                        <div className="rounded-lg bg-gray-50 p-4 shadow-sm">
                                            <h4 className="mb-2 text-sm font-medium text-gray-700">
                                                Local App Build Information
                                            </h4>
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-xs text-gray-500">Version</span>
                                                    <Tag color={appBuildVersion ? 'blue' : 'default'} className="w-fit">
                                                        {appBuildVersion || 'Not available'}
                                                    </Tag>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-xs text-gray-500">Last Updated</span>
                                                    <Tag
                                                        color={appBuildLastUpdatedAt ? 'green' : 'default'}
                                                        className="w-fit"
                                                    >
                                                        {appBuildLastUpdatedAt?.toLocaleString() || 'Not available'}
                                                    </Tag>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex justify-end">
                                            <Button
                                                size="small"
                                                danger
                                                icon={<BugOutlined />}
                                                onClick={() => {
                                                    useAppStateStore.getState().actions.setAppBuildVersion('');
                                                    useAppStateStore.getState().actions.setAppBuildLastUpdatedAt(null);
                                                }}
                                            >
                                                Clear Build Info
                                            </Button>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                key: '6',
                                label: 'Service Worker',
                                children: (
                                    <div className="flex flex-col gap-4">
                                        <div className="rounded-lg bg-gray-50 p-4 shadow-sm">
                                            <h4 className="mb-2 text-sm font-medium text-gray-700">
                                                Service Worker Controls
                                            </h4>
                                            <div className="flex flex-col gap-3">
                                                <Button
                                                    icon={<SyncOutlined />}
                                                    onClick={async () => {
                                                        const result = await refreshSwCache();
                                                        if (result) {
                                                            message.success('Service Worker cache refresh initiated');
                                                        } else {
                                                            message.error('Failed to refresh Service Worker cache');
                                                        }
                                                    }}
                                                >
                                                    Refresh Service Worker Cache
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                }
                placement="leftTop"
                trigger="click"
                classNames={{ root: 'debug-info-popover' }}
            >
                <FloatButton icon={<BugOutlined />} onClick={() => setOpen(!open)} tooltip={<div>Debug Tools</div>} />
            </Popover>
        </>
    );
}
