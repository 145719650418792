import { useNavigate } from '@client/hooks/useNavigate';
import { Button, Result } from 'antd';

export function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-50">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button type="link" onClick={() => navigate({ moveInHistory: 'back' })}>
                        Go back
                    </Button>
                }
                className="mx-auto w-full max-w-lg rounded-lg bg-white p-8 shadow-xs"
            />
        </div>
    );
}
