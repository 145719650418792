import { grey } from '@ant-design/colors';
import { CopyOutlined, DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { CollapsiblePanel } from '@client/components/Common/CollapsiblePanel';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { OrgSelector } from '@client/components/Common/OrgSelector';
import { ControlLibraryItemAction, ControlLibraryItemEdit } from '@client/components/Control/ControlLibraryItemEdit';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { listControlLibraryInputSchema } from '@server/schemas/controlLibrary';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Space, Table, Tooltip, Typography } from 'antd';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/controls/library/')({
    staticData: {
        ui: {
            title: 'Controls library',
            subtitle: 'Manage your controls library.',
        },
    },
    component: ControlLibrary,
});

type ControlLibraryItem = RouterOutputs['admin']['controlLibrary']['listControlLibrary']['rows'][number];

type FilterForm = {
    id?: bigint;
    name?: string;
    objective?: string;
    owner?: string;
    orgIds?: bigint[];
};

type SendToOrgForm = {
    orgIds: bigint[];
};

function ControlLibrary() {
    const navigate = useNavigate();

    const [queryParams, setQueryParams] = useZodState(listControlLibraryInputSchema);

    const [controlLibraryItemAction, setControlLibraryItemAction] = useState<ControlLibraryItemAction>(['none']);
    const [showFilter, setShowFilter] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ControlLibraryItem[]>([]);

    const { data, isPending, refetch } = trpc.admin.controlLibrary.listControlLibrary.useQuery(queryParams);
    const { mutateAsync: createControlLibraryItem, isPending: createControlLibraryItemLoading } =
        trpc.admin.controlLibrary.createControlLibraryItem.useMutation();
    const { mutateAsync: deleteControlLibraryItem } = trpc.admin.controlLibrary.deleteControlLibraryItem.useMutation();
    const { mutateAsync: createManyControls, isPending: createManyControlsLoading } =
        trpc.admin.control.createManyControls.useMutation();

    const [filterForm] = Form.useForm<FilterForm>();
    const [sendToOrgForm] = Form.useForm<SendToOrgForm>();

    const { tableProps } = useAntdTable<ControlLibraryItem>({
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        paginationConfig: { hideOnSinglePage: true },
        onQueryVariableChange(options) {
            setQueryParams((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : queryParams.orderBy,
            }));
        },
        rowKey: 'id',
        columns: [
            {
                title: 'Control No.',
                dataIndex: 'id',
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
            },
            {
                title: 'Objective',
                dataIndex: 'objective',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph ellipsis style={{ maxWidth: 250, minWidth: 100, marginBottom: 0 }}>
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph ellipsis style={{ maxWidth: 250, minWidth: 100, marginBottom: 0 }}>
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
            },
            {
                title: 'Assigned to',
                dataIndex: 'AssignedTo',
                render: (_, record) => record.AssignedTo?.map((org) => org.name).join(', '),
            },
            {
                ...commonActionColumn,
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Create a copy">
                            <Button
                                type="link"
                                size="small"
                                icon={<CopyOutlined />}
                                loading={createControlLibraryItemLoading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCopy(record);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure?"
                                onPopupClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onConfirm={() => {
                                    void onDelete(record.id);
                                }}
                                okButtonProps={{ danger: true }}
                                okText="Delete"
                            >
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            },
        ],
    });

    const rowSelection: TableRowSelection<ControlLibraryItem> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: ControlLibraryItem[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    const onSearch = () => {
        const values = filterForm.getFieldsValue();
        const conditions: NonNullable<typeof queryParams.where>[] = [];

        if (values.id) {
            conditions.push({ id: values.id });
        }
        if (values.name) {
            conditions.push({ name: { contains: values.name, mode: 'insensitive' } });
        }

        if (values.owner) {
            const words = values.owner
                .split(' ')
                .map((word) => (word || '').trim())
                .filter((word) => word.length > 0);
            conditions.push({
                OR: words.map((word) => ({ owner: { contains: word, mode: 'insensitive' } })),
            });
        }
        if (values.objective) {
            const words = values.objective
                .split(' ')
                .map((word) => (word || '').trim())
                .filter((word) => word.length > 0);
            conditions.push({
                OR: words.map((word) => ({ objective: { contains: word, mode: 'insensitive' } })),
            });
        }

        if (values.orgIds) {
            conditions.push({
                OrgControls: {
                    some: {
                        orgId: { in: values.orgIds },
                    },
                },
            });
        }

        setQueryParams((prev) => ({ ...prev, where: conditions.length ? { AND: conditions } : undefined }));
    };

    const onSendToOrganizations = async (values: SendToOrgForm) => {
        const { orgIds } = values;
        const controlsToInsert = selectedRows.flatMap(({ id, objective, description, name, owner }) =>
            orgIds.map((orgId) => ({
                objective,
                description,
                name: name || '',
                ownerHint: owner || '',
                status: 'DRAFT',
                libraryItemId: id,
                orgId,
            })),
        );

        try {
            message.loading('Sending to organizations...');
            await createManyControls(controlsToInsert);
            message.success('Successfully sent to organizations.');
            setIsModalVisible(false);
            sendToOrgForm.resetFields();
            void refetch();
            setSelectedRowKeys([]);
            setSelectedRows([]);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onCopy = async (record: ControlLibraryItem) => {
        try {
            message.loading('Copying...');

            const { description, name, objective, owner } = record;
            const control = await createControlLibraryItem({
                objective,
                description,
                owner: owner || '',
                name: name || '',
            });

            message.success(`Control copied successfully. Copy Control No: ${control?.id}`);

            void refetch();
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onDelete = async (id: bigint) => {
        try {
            message.loading('Deleting...');
            await deleteControlLibraryItem({
                id,
            });
            message.success('Deleted.');
            void refetch();
        } catch (e) {
            errorMessage.show(e);
        }
    };

    return (
        <>
            <ControlLibraryItemEdit
                action={controlLibraryItemAction}
                onClose={() => {
                    setControlLibraryItemAction(['none']);
                    void refetch();
                }}
            />

            <Modal
                confirmLoading={createManyControlsLoading}
                open={isModalVisible}
                maskClosable={false}
                title="Assign controls to register"
                onCancel={() => {
                    setIsModalVisible(false);
                    sendToOrgForm.resetFields();
                }}
                okText="Submit"
                okButtonProps={{
                    autoFocus: true,
                    htmlType: 'submit',
                    form: 'sendToOrgForm',
                }}
            >
                <Form form={sendToOrgForm} layout="vertical" id="sendToOrgForm" onFinish={onSendToOrganizations}>
                    <Form.Item label="Select the organization(s)" name="orgIds">
                        <OrgSelector mode="multiple" />
                    </Form.Item>
                </Form>
            </Modal>

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={filterForm} layout="vertical" onFinish={onSearch}>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item label="Control No." name="id">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Name" name="name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Objective" name="objective">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Owner" name="owner">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Assigned to" name="orgIds">
                                    <OrgSelector mode="multiple" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            filterForm.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-between">
                <Button type="primary" onClick={() => setIsModalVisible(true)} disabled={!(selectedRowKeys.length > 0)}>
                    Send to organization
                </Button>
                <Space>
                    <Tooltip title="Filters">
                        <Button type="text" size="large" title="Filter" onClick={() => setShowFilter(!showFilter)}>
                            <FilterOutlined />
                        </Button>
                    </Tooltip>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setControlLibraryItemAction(['add']);
                        }}
                    >
                        Add control
                    </Button>
                </Space>
            </Space>

            <Card>
                <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    rowSelection={rowSelection}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) => {
                            navigate(
                                {
                                    to: '/admin-console/controls/library/$id/$section',
                                    params: {
                                        id,
                                        section: 'details',
                                    },
                                },
                                e,
                            );
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
