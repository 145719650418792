import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import './index.sass';
import { useUuid } from '@client/hooks/useUuid';

interface Props {
    onChange?: (value: string | undefined) => void;
    value?: string;
    options: { value: string; label?: string }[];
    onValidate?: () => void;
    otherLabel?: string;
    selectPlaceholder?: string;
    disabled?: boolean;
}

export const SelectWithOther = observer(
    ({ value, options, onChange, onValidate, otherLabel, selectPlaceholder, disabled }: Props) => {
        const state = useLocalObservable<{
            hasFocus: boolean;
            mounted: boolean;
            selectOpen: boolean;
            changedSinceFocused: boolean;
        }>(() => ({
            hasFocus: false,
            mounted: false,
            selectOpen: false,
            changedSinceFocused: false,
        }));
        const [otherSelected, setOtherSelected] = useState(false);
        const [disableFocus, setDisableFocus] = useState(false);
        const inputRef = useRef<any>(null);
        const selectRef = useRef<any>(null);
        const propOtherLabel = otherLabel || 'Other - please specify';
        const uuidSelect = useUuid();
        const uuidInput = useUuid();

        useEffect(() => {
            if (state.mounted) {
                if (disableFocus) {
                    setDisableFocus(false);
                    return;
                }
                if (otherSelected) {
                    inputRef.current.focus();
                } else {
                    selectRef.current.focus();
                }
            }
            setDisableFocus(false);
        }, [otherSelected]);

        const onBlur = () => {
            if (!state.hasFocus && state.changedSinceFocused) {
                onValidate?.();
            }
        };

        useEffect(() => {
            const inOptions = options.some((o) => o.value === value);
            if (!inOptions && !!value) {
                setDisableFocus(true);
                setOtherSelected(true);
            }
            state.mounted = true;
        }, []);

        return (
            <div>
                {otherSelected ? (
                    <Input
                        className={`crm-select-with-other crm${uuidInput!}`}
                        ref={inputRef}
                        value={value}
                        onChange={(e) => {
                            state.changedSinceFocused = true;
                            onChange?.(e.target.value);
                            onValidate?.();
                        }}
                        placeholder={propOtherLabel}
                        onFocus={() => {
                            state.changedSinceFocused = false;
                            state.hasFocus = true;
                        }}
                        onBlur={() => {
                            state.hasFocus = false;
                            setTimeout(() => onBlur(), 300);
                        }}
                        suffix={
                            <Button
                                type="link"
                                className="-m-1 h-auto p-0"
                                onClick={() => {
                                    setOtherSelected(false);
                                    onChange?.(undefined);
                                }}
                                title="Clear"
                            >
                                <CloseCircleFilled style={{ color: grey[0] }} />
                            </Button>
                        }
                        disabled={disabled}
                    />
                ) : (
                    <Select
                        ref={selectRef}
                        onFocus={() => {
                            state.hasFocus = true;
                            state.changedSinceFocused = false;
                        }}
                        className={`crm${uuidSelect!}`}
                        placeholder={selectPlaceholder}
                        value={value}
                        options={[...options, { label: propOtherLabel, value: '#last-option' }]}
                        onChange={(value1) => {
                            state.changedSinceFocused = true;
                            if (value1 === '#last-option') {
                                setOtherSelected(true);
                                onChange?.(undefined);
                            } else {
                                onChange?.(value1);
                                onValidate?.();
                            }
                        }}
                        open={state.selectOpen}
                        onBlur={() => {
                            state.hasFocus = false;
                            setTimeout(() => onBlur(), 300);
                        }}
                        onDropdownVisibleChange={(open) => {
                            state.selectOpen = open;
                        }}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    },
);
