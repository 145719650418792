import { FileLink } from '@client/components/Common/FileLink';
import { Link } from '@client/components/Common/Link';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useZodState } from '@client/hooks/useZodState';
import { trpc } from '@client/trpc/client';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Card, Spin, Table } from 'antd';
import { listReportsInputSchema } from 'server/schemas/report';

export const Route = createFileRoute('/console/$orgId/reporting')({
    staticData: {
        breadcrumb: {
            title: 'Reporting',
        },
        roles: ['user', 'org_admin'],
        modules: ['claim', 'compliance'],
        restrictToEmployee: false,
    },
    component: Reports,
});

function Reports() {
    const orgId = useOrgId();
    const { formatDate, formatDateTime } = useFormatter();

    const [input, setInput] = useZodState(listReportsInputSchema);

    const { data, isPending } = trpc.report.listReports.useQuery(input);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setInput((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : input.orderBy,
            }));
        },
        columns: [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'Start date',
                dataIndex: 'startDate',
                sorter: true,
                render: (value) => formatDate(value),
            },
            {
                title: 'End date',
                dataIndex: 'endDate',
                sorter: true,
                render: (value) => formatDate(value),
            },
            {
                title: 'Link',
                dataIndex: 'id',
                render: (_, { File }) =>
                    File.length ? (
                        <FileLink fileKey={File[0].key} fileName={File[0].name} label="Download" />
                    ) : (
                        <Spin spinning />
                    ),
            },
            {
                title: 'Created at',
                dataIndex: 'createdAt',
                sorter: true,
                render: (value) => formatDateTime(value),
            },
        ],
    });

    return (
        <>
            <PageLayout.Header
                primaryActionAddon={
                    <Link.Add
                        to="/console/$orgId/reporting/add"
                        params={{ orgId }}
                        type="primary"
                        label="Generate report"
                    />
                }
            />

            <Outlet />

            <Card>
                <Table scroll={{ x: true }} size="small" {...tableProps} />
            </Card>
        </>
    );
}
