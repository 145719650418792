import { DownloadOutlined } from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { Alerts } from '@client/components/Indicator/Alerts';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUser } from '@client/hooks/User/useUser';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { FieldError } from '@client/types/error';
import { RecordNotFoundError } from '@client/utils/error';
import { COMPLAINT_DETAILS_SECTIONS } from '@shared/navigation/navRoutes';
import { COMPLAINT_STATUS } from '@shared/types/complaint';
import { createFileRoute, Outlet, useLocation } from '@tanstack/react-router';
import { Button, Skeleton, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { useValidateComplaintBeforeClosing } from '../hooks';
import { CloseComplaintDrawer } from './CloseComplaintDrawer';
import { useCanEditComplaint, useExportComplaint } from './hooks';
import { complaintFormStore } from './store';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/details/$id')({
    staticData: { breadcrumb: { title: 'Complaint details' } },
    params: {
        parse({ id }) {
            return { id: BigInt(id) };
        },
    },
    component: ComplaintDetails,
});

function ComplaintDetails() {
    const orgId = useOrgId();
    const { isOrgAdmin, id: userId } = useUser();
    const { id } = Route.useParams();
    const trpcClient = useTrpcClient();
    const canEdit = useCanEditComplaint();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [exportComplaint, { loading: exportComplaintLoading }] = useExportComplaint();
    const {
        validateTimeline,
        validateRequiredFields,
        loading: validateComplaintBeforeClosingLoading,
    } = useValidateComplaintBeforeClosing();

    const [requiredFieldsErrors, setRequiredFieldsErrors] = useState<FieldError[]>([]);

    const { formData: complaint, save } = complaintFormStore.useInit({
        entity: 'complaint',
        loadDependencies: [id],

        async onSave(currentValue, changedValues) {
            try {
                message.loading('Saving...');

                await trpcClient.complaint.updateComplaint.mutate({ id: currentValue.id, set: changedValues });

                message.success(
                    changedValues.status === COMPLAINT_STATUS.RE_OPENED
                        ? 'Complaint re-opened successfully.'
                        : changedValues.status === COMPLAINT_STATUS.CLOSED
                          ? 'Complaint closed successfully.'
                          : 'Saved.',
                );

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.complaint.getComplaint.query({ id });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The complaint you are trying to access either doesn't exist or you don't have permissions to access it.",
                );

                navigate({ to: '/console/$orgId/compliance/complaints', params: { orgId } });
                return;
            }

            errorMessage.show(error);
        },
    });

    const canReopen = useMemo(() => {
        if (!complaint) {
            return false;
        }

        return isOrgAdmin || (complaint.ownerId != null && complaint.ownerId === userId);
    }, [complaint, userId, isOrgAdmin]);

    const reopenComplaint = async () => {
        await save({ status: COMPLAINT_STATUS.RE_OPENED });
    };

    const closeComplaint = async () => {
        if (!complaint) {
            return;
        }

        const timelineErrors = await validateTimeline(complaint);

        if (timelineErrors.length) {
            errorMessage.show(timelineErrors[0].errors[0]);
            return;
        }

        const requiredFieldsErrors = validateRequiredFields(complaint);

        if (requiredFieldsErrors.length) {
            setRequiredFieldsErrors(requiredFieldsErrors);
            return;
        }

        if (
            complaint.outcomes?.includes('Decision changed') &&
            (!complaint.monetaryRemedy || complaint.monetaryRemedy <= 0)
        ) {
            errorMessage.show(
                'A monetary remedy amount is required. Please enter an amount greater than $0 prior to closure.',
            );
            return;
        }

        await save({ status: COMPLAINT_STATUS.CLOSED });
    };

    return (
        <>
            {complaint && (
                <CloseComplaintDrawer
                    open={!!requiredFieldsErrors.length}
                    onSave={async (data) => {
                        await save({ ...data, status: COMPLAINT_STATUS.CLOSED });
                    }}
                    onClose={() => {
                        setRequiredFieldsErrors([]);
                    }}
                    errors={requiredFieldsErrors}
                />
            )}

            {complaint && id === BigInt(complaint.id) ? (
                <Tabs
                    type="line"
                    destroyInactiveTabPane
                    activeKey={pathname.split('/')[7]}
                    tabBarExtraContent={{
                        left: (
                            <div className="mx-4 size-4">
                                <Alerts id={id} entity="complaint" key={id} />
                            </div>
                        ),
                        right: (
                            <>
                                {complaint.status !== COMPLAINT_STATUS.CLOSED && canEdit && (
                                    <Button
                                        loading={validateComplaintBeforeClosingLoading}
                                        type="primary"
                                        onClick={closeComplaint}
                                    >
                                        Close complaint
                                    </Button>
                                )}
                                {complaint.status === COMPLAINT_STATUS.CLOSED && canReopen && (
                                    <Button type="primary" onClick={reopenComplaint}>
                                        Open complaint
                                    </Button>
                                )}
                                <Button
                                    loading={exportComplaintLoading}
                                    type="link"
                                    icon={<DownloadOutlined />}
                                    onClick={() => {
                                        void exportComplaint(id, orgId);
                                    }}
                                >
                                    Download complaint
                                </Button>
                            </>
                        ),
                    }}
                    onTabClick={(key) => {
                        navigate({
                            to: `/console/$orgId/compliance/complaints/details/$id/${key}`,
                            params: { orgId, id },
                        });
                    }}
                    items={[
                        { key: COMPLAINT_DETAILS_SECTIONS.MANAGE, label: 'Manage Complaint', children: <Outlet /> },
                        { key: COMPLAINT_DETAILS_SECTIONS.LIBRARY, label: 'Library', children: <Outlet /> },
                        { key: COMPLAINT_DETAILS_SECTIONS.HISTORY, label: 'History', children: <Outlet /> },
                    ]}
                />
            ) : (
                <Skeleton active />
            )}
        </>
    );
}
