import { Link } from '@client/components/Common/Link';
import { PageContent } from '@client/components/Layout/PageContent';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Table, Typography } from 'antd';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/organizations/$orgId/subscriptions')({
    staticData: {
        metadata: {
            title: 'Subscriptions',
        },
        ui: {
            title: 'Subscriptions',
            subtitle: 'Manage organization subscriptions here.',
        },
    },
    component: Subscriptions,
});

type QueryVariables = RouterInputs['admin']['subscription']['listSubscriptions'];
type Subscription = RouterOutputs['admin']['subscription']['listSubscriptions']['rows'][number];

function Subscriptions() {
    const { orgId } = Route.useParams();

    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
        where: {
            orgId,
        },
        orderBy: {
            id: 'desc',
        },
    });

    const { data: subscriptions, isLoading } = trpc.admin.subscription.listSubscriptions.useQuery(queryVariables);

    const { tableProps } = useAntdTable<Subscription>({
        rowKey: 'id',
        data: {
            rows: subscriptions?.rows || [],
            total: subscriptions?.total || 0,
            loading: isLoading,
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
            },
            {
                dataIndex: 'Service.name',
                title: 'Service',
                sorter: true,
                render: (_, record) => <Typography.Text>{record.Service?.name}</Typography.Text>,
            },
            {
                dataIndex: ['Tier', 'name'],
                title: 'Tier',
                sorter: true,
            },
            {
                dataIndex: 'startedAt',
                title: 'Started At',
                sorter: true,
                render: (value) => new Date(value).toLocaleDateString(),
            },
            {
                dataIndex: 'expiresAt',
                title: 'Expires At',
                sorter: true,
                render: (value) => (value ? new Date(value).toLocaleDateString() : 'Never'),
            },
            {
                dataIndex: 'isActive',
                title: 'Status',
                sorter: true,
                render: (value) => (value ? 'Active' : 'Inactive'),
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev: QueryVariables) => ({
                ...prev,
                ...options,
                orderBy: options?.orderBy ?? prev.orderBy,
                where: {
                    ...prev.where,
                },
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    return (
        <PageContent>
            <PageLayout.Header
                features={[
                    {
                        feature: 'search',
                        onSearch(value) {
                            setQueryVariables((prev) => ({
                                ...prev,
                                where: value
                                    ? ({
                                          ...prev.where,
                                          OR: [
                                              { Service: { name: { contains: value, mode: 'insensitive' } } },
                                              { Tier: { code: { contains: value, mode: 'insensitive' } } },
                                          ],
                                      } satisfies QueryVariables['where'])
                                    : ({
                                          orgId,
                                      } satisfies QueryVariables['where']),
                            }));
                        },
                    },
                ]}
                primaryActionAddon={<Link.Add type="primary" label="New Subscription" to="." />}
            />

            <Card>
                <Table {...tableProps} size="small" />
            </Card>
        </PageContent>
    );
}
