import { RefObject, useEffect, useRef } from 'react';

export function useClickOutsideDetector(onClickOutside: () => void): RefObject<HTMLInputElement | null> {
    const ref = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClickOutside]);

    return ref;
}
