import { useAsyncOptions } from '@client/hooks/useAsyncOptions';
import { forwardRefWithGenerics } from '@client/utils/components';
import { GetRef, Select } from 'antd';
import { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/es/select';
import { Ref } from 'react';

export type FetchOptions<OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType> = (
    search?: string,
) => Promise<OptionType[]>;

export type AsyncSelectProps<
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> = Omit<SelectProps<ValueType, OptionType>, 'options' | 'children' | 'loading'> & {
    fetchOptions: FetchOptions<OptionType>;
    readonly?: boolean;
    fetchOnInit?: boolean;
};

export const AsyncSelect = forwardRefWithGenerics(
    <ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
        { readonly, onChange, fetchOnInit, fetchOptions, ...props }: AsyncSelectProps<ValueType, OptionType>,
        ref: Ref<GetRef<typeof Select>>,
    ) => {
        const { loading, options } = useAsyncOptions({
            fetchOnInit,
            fetchOptions,
        });

        return (
            <Select
                ref={ref}
                onChange={readonly ? undefined : onChange}
                options={options}
                disabled={readonly}
                loading={loading}
                {...props}
            />
        );
    },
);
