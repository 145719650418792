import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/suppliers')({
    staticData: {
        breadcrumb: {
            title: 'Suppliers',
        },
        modules: ['claim'],
    },
    component: Outlet,
});
