/**
 * Service Worker Utilities
 */

/**
 * Check if the browser supports service workers
 */
export const isServiceWorkerSupported = (): boolean => {
    return 'serviceWorker' in navigator;
};

/**
 * Register the service worker
 */
export const registerServiceWorker = async (): Promise<ServiceWorkerRegistration | null> => {
    if (!isServiceWorkerSupported()) {
        console.warn('Service workers are not supported by this browser');
        return null;
    }

    try {
        // Check if there's already an active service worker
        const existingRegistration = await navigator.serviceWorker.getRegistration();
        if (existingRegistration) {
            console.log('Existing service worker found, updating if needed');
            await existingRegistration.update();
            window.swRegistration = existingRegistration;
            return existingRegistration;
        }

        // Register a new service worker
        const registration = await navigator.serviceWorker.register('/assets/service-worker.js');

        console.log('Service Worker registered with scope:', registration.scope);

        // Store the registration in window for global access
        window.swRegistration = registration;

        // Wait for the service worker to be activated
        if (registration.installing) {
            console.log('Service worker installing');

            // Wait for the service worker to be activated
            await new Promise<void>((resolve) => {
                registration.installing?.addEventListener('statechange', (e) => {
                    const sw = e.target as ServiceWorker;
                    console.log('Service worker state changed to:', sw.state);
                    if (sw.state === 'activated') {
                        resolve();
                    }
                });
            });

            console.log('Service worker activated');
        } else if (registration.waiting) {
            console.log('Service worker waiting');
        } else if (registration.active) {
            console.log('Service worker already active');
        }

        return registration;
    } catch (error) {
        console.error('Service Worker registration failed:', error);
        return null;
    }
};

/**
 * Unregister all service workers and remove any cached data
 */
export const unregisterServiceWorkers = async (): Promise<boolean> => {
    if (!isServiceWorkerSupported()) {
        return false;
    }

    try {
        const registrations = await navigator.serviceWorker.getRegistrations();

        await Promise.all(
            registrations.map(async (registration) => {
                const unregistered = await registration.unregister();
                if (unregistered) {
                    console.log('Service worker unregistered successfully');
                }
            }),
        );

        // Clear caches
        if ('caches' in window) {
            const cacheKeys = await caches.keys();
            await Promise.all(
                cacheKeys.map(async (cacheKey) => {
                    await caches.delete(cacheKey);
                    console.log(`Cache ${cacheKey} deleted successfully`);
                }),
            );
        }

        return true;
    } catch (error) {
        console.error('Error unregistering service workers:', error);
        return false;
    }
};

/**
 * Update the app by sending a message to the service worker
 */
export const refreshSwCache = async (): Promise<boolean> => {
    if (!isServiceWorkerSupported() || !window.swRegistration) {
        console.warn('Service worker is not available for update');
        return false;
    }

    const swRegistration = window.swRegistration;

    // Check for updates first
    try {
        await swRegistration.update();
        console.log('Service worker updated');
    } catch (error) {
        console.error('Error updating service worker:', error);
    }

    // Send message to active service worker to update the app
    if (swRegistration.active) {
        swRegistration.active.postMessage({
            type: 'UPDATE_APP',
        });
        console.log('Update message sent to service worker');
        return true;
    } else {
        console.warn('No active service worker found');
        return false;
    }
};

/**
 * Listen for messages from the service worker
 * @param callback Function to call when a message is received
 */
export const listenForServiceWorkerMessages = (callback: (event: MessageEvent) => void): (() => void) => {
    if (!isServiceWorkerSupported()) {
        return () => {};
    }

    const handler = (event: MessageEvent) => {
        callback(event);
    };

    navigator.serviceWorker.addEventListener('message', handler);

    // Return a function to remove the event listener
    return () => {
        navigator.serviceWorker.removeEventListener('message', handler);
    };
};
