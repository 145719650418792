import { gql } from '@client/__generated__';

export const InsertFiles = gql(/* GraphQL */ `
    mutation InsertFiles($objects: [FileInsertInput!] = {}) {
        insertFiles(objects: $objects) {
            affectedRows
        }
    }
`);

export const InsertFileOne = gql(/* GraphQL */ `
    mutation InsertFileOne($object: FileInsertInput!) {
        insertFileOne(object: $object) {
            id
        }
    }
`);

export const UpdateFileCategoriesByPk = gql(/* GraphQL */ `
    mutation UpdateFileCategoriesByPk($id: uuid = "", $categories: jsonb = "") {
        updateFileByPk(pkColumns: { id: $id }, _set: { categories: $categories }) {
            id
        }
    }
`);
