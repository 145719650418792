import { PlusOutlined } from '@ant-design/icons';
import { Link } from '@client/components/Common/Link';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { ShareableRouteContext } from '@client/types/router';
import { commonActionColumn } from '@client/utils/table';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Table, Tag } from 'antd';
import { useState } from 'react';
import { listPdsVersionInputSchema } from 'server/schemas/pdsVersion';
import { PdsVersionAction, PdsVersionByPdsEdit } from './PdsVersionByPdsEdit';
export const Route = createFileRoute('/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId')({
    staticData: { ui: { title: 'PDS version configuration' } },
    params: {
        parse({ pdsId }) {
            return { pdsId: BigInt(pdsId) };
        },
    },
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.pds.getPds.ensureData({ id: params.pdsId });

        return {
            breadcrumb: {
                async title() {
                    const pds = await trpcUtils.pds.getPds.ensureData({ id: params.pdsId });

                    return pds.name;
                },
            },
        };
    },
    component: ConfigurationPdsVersionsByPds,
});

type PdsVersion = RouterOutputs['pdsVersion']['listPdsVersion']['rows'][number];

function ConfigurationPdsVersionsByPds() {
    const orgId = useOrgId();
    const { pdsId, productTypeId } = Route.useParams();
    const { formatDate } = useFormatter();
    const [action, setAction] = useState<PdsVersionAction>(['none']);
    const [queryParams, setQueryParams] = useZodState(listPdsVersionInputSchema);
    const { data, isPending, refetch } = trpc.pdsVersion.listPdsVersion.useQuery(
        { ...queryParams, where: { ...queryParams.where, pdsId } },
        { enabled: !!pdsId },
    );
    const { data: pds } = trpc.pds.getPds.useQuery({ id: pdsId }, { enabled: !!pdsId });

    const { tableProps } = useAntdTable<PdsVersion>({
        rowKey: 'id',
        data: { rows: data?.rows, loading: isPending, total: data?.total },
        onQueryVariableChange: (options) => {
            setQueryParams((prev) => ({ ...prev, ...options }));
        },
        columns: [
            { title: 'ID', dataIndex: 'id', sorter: true, render: String },
            { title: 'Name', dataIndex: 'name', sorter: true },
            { title: 'Start date', dataIndex: 'startDate', render: (value) => formatDate(value) },
            { title: 'End date', dataIndex: 'endDate', render: (value) => formatDate(value) },
            { title: 'Questionnaire', dataIndex: 'Questionnaire.name' },
            { title: 'Workflow', dataIndex: 'Workflow.name' },
            {
                title: 'Enabled',
                dataIndex: 'isEnabled',
                render: (value) => (value ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>),
            },
            {
                ...commonActionColumn,
                render: (_, { id, questionnaireId }) =>
                    !!questionnaireId && (
                        <Link
                            to="/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId"
                            params={{ orgId, pdsVersionId: id, questionnaireId, productTypeId }}
                            search={{ data: window.btoa(JSON.stringify({ insurerName: pds?.Insurer?.name })) }}
                            target="_blank"
                            type="dashed"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Public lodgement
                        </Link>

                        // THIS IS THE OLD LINK - Leaving it here for reference
                        // <Link
                        //     to="/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId"
                        //     params={{ orgId, pdsVersionId: id, questionnaireId, productTypeId }}
                        //     search={{ data: window.btoa(JSON.stringify({ insurerName: pds?.PdsByPk?.Insurer?.name })) }}
                        //     target="_blank"
                        //     type="dashed"
                        //     onClick={(e) => e.stopPropagation()}
                        // >
                        //     Public lodgement
                        // </Link>
                    ),
            },
        ],
    });

    return (
        <>
            <PdsVersionByPdsEdit
                pdsId={pdsId}
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    New PDS version
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick() {
                            setAction(['edit', id]);
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
