import { forwardRef, Ref, useCallback, useEffect, useState } from 'react';
import { Cascader } from 'antd';
import { CascaderAutoProps, CascaderRef, DefaultOptionType } from 'antd/es/cascader';
import { useConfigService } from '@client/hooks/Configuration/useConfigService';
import { BrandNode } from '@shared/types/complaintBrand';
import { JsonUtils } from '@client/utils/json';

type Props = Pick<
    CascaderAutoProps<BrandNode>,
    'options' | 'disabled' | 'defaultOpen' | 'getPopupContainer' | 'allowClear' | 'suffixIcon'
> & {
    value?: string | null | undefined;
    onChange?: (value: string) => void;
};

interface BrandNodeExtended extends BrandNode {
    value: string;
}

export const ComplaintBrandSelector = forwardRef(
    ({ value, onChange, options, allowClear = true, ...props }: Props, ref: Ref<CascaderRef>) => {
        const [opts, setOpts] = useState<BrandNode[]>([]);
        const configService = useConfigService();

        const traverseBrandNodes = (nodes: BrandNodeExtended[]) => {
            nodes.forEach((node) => {
                node.value = node.label;

                if (node.children) {
                    traverseBrandNodes(node.children as BrandNodeExtended[]);
                }
            });

            return nodes;
        };

        useEffect(() => {
            if (options) {
                setOpts(traverseBrandNodes(JsonUtils.deepClone(options) as BrandNodeExtended[]));
                return;
            }

            const loadOptions = async () => {
                const brandConfig = await configService.fetchComplaintBrandConfiguration();
                const brandTree = traverseBrandNodes(brandConfig.brandTree as BrandNodeExtended[]);
                setOpts(brandTree || []);
            };

            void loadOptions();
        }, [options]);

        const cascaderFormat = (value: string | null | undefined, separator?: string) =>
            typeof value === 'string' ? value.split(separator || ';') : [];

        const _onChange = useCallback(
            (value: any) => {
                onChange?.(value.length ? value.join(';') : '');
            },
            [value],
        );

        return (
            <Cascader
                ref={ref}
                value={cascaderFormat(value)}
                onChange={_onChange}
                options={opts}
                allowClear={allowClear}
                onClear={() => _onChange([])}
                displayRender={(label) => (Array.isArray(label) ? label.join(' > ') : label)}
                showSearch={{
                    filter: (inputValue, options: DefaultOptionType[]) =>
                        options.some((option) =>
                            option?.value?.toString()?.toLowerCase().includes(inputValue.toLowerCase()),
                        ),
                }}
                {...props}
            />
        );
    },
);
