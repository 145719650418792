import { mobxToJson } from '../global/mobx';

/**
 * Curried function.
 * Converts the obj parameter to JSON, removes properties specified as keysToRemove and throws an error if the resulting
 * object has an extra properties against TTo type.
 * @example
 *  toJSON<TTo>()({firstName: 'David', orgId: 1},'orgId')
 */
const toJSON =
    <TTo extends Record<any, any>>() =>
    <TFrom extends Record<any, any> = { test: string }, TKeys extends (keyof TFrom)[] = (keyof TFrom)[]>(
        obj: TFrom,
        ...keysToRemove: TKeys
    ) => {
        let json: TFrom = mobxToJson(obj) as TFrom;
        if (keysToRemove && keysToRemove.length > 0) {
            keysToRemove.forEach((el) => {
                delete json[el];
            });
        }

        return json as unknown as TTo extends {
            [Property in keyof Omit<TFrom, TKeys[number]>]: TTo[Property];
        }
            ? Omit<TFrom, TKeys[number]>
            : {
                  [Property in keyof Omit<Omit<TFrom, TKeys[number]>, keyof TTo>]: 'EXTRA PROPERTY';
              };
    };

function safeStringify(
    input: any,
    options: { throwError: boolean; onErrorResult: any } = { throwError: false, onErrorResult: '' },
) {
    try {
        return JSON.stringify(input);
    } catch (e) {
        if (options.throwError) {
            throw e;
        }
        return options.onErrorResult;
    }
}

function deepClone(obj: any, defaultValue: any = undefined) {
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch (e) {
        return defaultValue;
    }
}

export const JsonUtils = {
    toJSON,
    safeStringify,
    deepClone,
};
