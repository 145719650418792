import { errorMessage } from '@client/components/Common/errorMessage';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useZodState } from '@client/hooks/useZodState';
import { trpc, type RouterOutputs } from '@client/trpc/client';
import { Override } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Descriptions, Drawer, Input, InputNumber, Modal, Space, Table, Tag, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { systemLogListInputSchema } from 'server/schemas/systemLog';

export const Route = createFileRoute('/admin-console/advanced/system-logs')({
    staticData: {
        ui: {
            title: 'System Logs',
        },
        breadcrumb: {
            title: 'System Logs',
        },
    },
    component: SystemLogs,
});

type SystemLog = Override<
    RouterOutputs['admin']['systemLog']['listSystemLogs']['rows'][number],
    {
        jsonDetails: Record<string, unknown>;
        jsonRequest: Record<string, unknown>;
        input: Record<string, unknown>;
    }
>;

function SystemLogs() {
    const [queryVariables, setQueryVariables] = useZodState(systemLogListInputSchema);
    const [selectedLog, setSelectedLog] = useState<SystemLog | null>(null);
    const [daysToDelete, setDaysToDelete] = useState<number>(30);

    const { data, error, isPending, refetch } = trpc.admin.systemLog.listSystemLogs.useQuery(queryVariables);
    const deleteMutation = trpc.admin.systemLog.deleteSystemLogs.useMutation({
        onSuccess: () => {
            Modal.success({
                title: 'Success',
                content: 'System logs deleted successfully',
            });
            refetch();
        },
        onError: (error) => {
            errorMessage.show(error);
        },
    });

    const { tableProps } = useAntdTable<SystemLog>({
        rowKey: 'id',
        data: {
            rows: data?.rows as SystemLog[],
            total: data?.total,
            loading: isPending,
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
                render: (value) => <Tag color="blue">{value}</Tag>,
            },
            {
                dataIndex: 'severity',
                title: 'Severity',
                sorter: true,
                render: (value) => {
                    const color = value === 'error' ? 'red' : value === 'warning' ? 'orange' : 'green';
                    return <Tag color={color}>{value}</Tag>;
                },
            },
            {
                dataIndex: 'message',
                title: 'Message',
                sorter: true,
                render: (value) => <Typography.Text copyable={!!value}>{value}</Typography.Text>,
            },
            {
                dataIndex: 'source',
                title: 'Source',
                sorter: true,
            },
            {
                dataIndex: 'area',
                title: 'Area',
                sorter: true,
            },
            {
                dataIndex: 'path',
                title: 'Path',
                sorter: true,
            },
            {
                dataIndex: 'createdAt',
                title: 'Created',
                sorter: true,
                className: 'whitespace-nowrap',
                render: (value) => DateTime.fromJSDate(value).toFormat('yyyy-MM-dd HH:mm:ss'),
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (_, record) => (
                    <Typography.Link onClick={() => setSelectedLog(record)}>View Details</Typography.Link>
                ),
            },
        ],
        onQueryVariableChange: (options) => {
            setQueryVariables({
                ...queryVariables,
                limit: options?.limit || queryVariables.limit,
                offset: options?.offset || queryVariables.offset,
                orderBy: options?.orderBy || queryVariables.orderBy,
            });
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    const onSearch = (searchValue: string) => {
        const where = {
            OR: [] as Array<{
                message?: { contains: string; mode: 'insensitive' };
                source?: { contains: string; mode: 'insensitive' };
                area?: { contains: string; mode: 'insensitive' };
                path?: { contains: string; mode: 'insensitive' };
            }>,
        };
        if (searchValue) {
            where.OR.push({
                message: { contains: `%${searchValue}%`, mode: 'insensitive' },
            });
            where.OR.push({
                source: { contains: `%${searchValue}%`, mode: 'insensitive' },
            });
            where.OR.push({
                area: { contains: `%${searchValue}%`, mode: 'insensitive' },
            });
            where.OR.push({
                path: { contains: `%${searchValue}%`, mode: 'insensitive' },
            });
        }

        setQueryVariables({
            ...queryVariables,
            where: where.OR.length ? where : undefined,
        });
    };

    const handleDelete = () => {
        Modal.confirm({
            title: 'Delete System Logs',
            content: `Are you sure you want to delete system logs older than ${daysToDelete} days?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteMutation.mutate({ olderThanDays: daysToDelete });
            },
        });
    };

    if (error) {
        errorMessage.show(error);
    }

    return (
        <>
            <div className="mb-4 flex items-center justify-between">
                <Input.Search
                    placeholder="Search logs"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                    className="w-96"
                />
                <Space>
                    <InputNumber
                        min={1}
                        value={daysToDelete}
                        onChange={(value) => setDaysToDelete(value || 30)}
                        addonAfter="days"
                        className="w-32"
                    />
                    <Button type="primary" danger onClick={handleDelete} loading={deleteMutation.isPending}>
                        Delete Old Logs
                    </Button>
                </Space>
            </div>

            <Card>
                <Table scroll={{ x: true }} {...tableProps} size="small" />
            </Card>

            <Drawer title="Log Details" open={!!selectedLog} onClose={() => setSelectedLog(null)} width={1200}>
                {selectedLog && (
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="ID">
                            <Tag color="blue">{selectedLog.id}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Severity">
                            <Tag
                                color={
                                    selectedLog.severity === 'error'
                                        ? 'red'
                                        : selectedLog.severity === 'warning'
                                          ? 'orange'
                                          : 'green'
                                }
                            >
                                {selectedLog.severity}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Message">
                            <Typography.Text copyable>{selectedLog.message}</Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Source">{selectedLog.source}</Descriptions.Item>
                        <Descriptions.Item label="Area">{selectedLog.area}</Descriptions.Item>
                        <Descriptions.Item label="Path">{selectedLog.path}</Descriptions.Item>
                        <Descriptions.Item label="Created At">
                            {DateTime.fromJSDate(selectedLog.createdAt).toFormat('yyyy-MM-dd HH:mm:ss')}
                        </Descriptions.Item>
                        {selectedLog.jsonDetails && (
                            <Descriptions.Item label="Details">
                                <pre>
                                    <Typography.Text copyable>
                                        {JSON.stringify(selectedLog.jsonDetails as Record<string, unknown>, null, 2)}
                                    </Typography.Text>
                                </pre>
                            </Descriptions.Item>
                        )}
                        {selectedLog.jsonRequest && (
                            <Descriptions.Item label="Request">
                                <pre>
                                    <Typography.Text copyable>
                                        {JSON.stringify(selectedLog.jsonRequest as Record<string, unknown>, null, 2)}
                                    </Typography.Text>
                                </pre>
                            </Descriptions.Item>
                        )}
                        {selectedLog.input && (
                            <Descriptions.Item label="Input">
                                <pre>
                                    <Typography.Text copyable>
                                        {JSON.stringify(selectedLog.input as Record<string, unknown>, null, 2)}
                                    </Typography.Text>
                                </pre>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                )}
            </Drawer>
        </>
    );
}
