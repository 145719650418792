import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/product-types')({
    staticData: {
        breadcrumb: {
            title: 'Product types & PDS',
        },
        modules: ['claim'],
    },
    component: Outlet,
});
