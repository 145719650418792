export enum NodeTypeEnum {
    // FLOW CONTROL
    system = 'system',
    wait = 'wait',
    mergeOr = 'mergeOr',
    mergeAnd = 'mergeAnd',
    // TIMELINE
    logToTimeline = 'logToTimeline',
    // TASKS
    allocateSupplier = 'allocate_supplier',
    changeStatus = 'change_status',
    changeTriageOutcome = 'change_triage_outcome',
    makeDecision = 'make_decision',
    makePayment = 'make_payment',
    updateReserve = 'update_reserve',
    sendMessageEmail = 'send_message_email',
    sendMessageText = 'send_message_text',
    decision = 'decision',
}

export enum NodeCategoryEnum {
    task = 'task',
    system = 'system',
    control = 'control',
    timeline = 'timeline',
}

export type DateDef = {
    numberOfDays: number | null;
    dayType: 'business_days' | 'calendar_days';
    field: string;
};

export type AssignedTo = 'owner';

type TaskConfigBase = {
    title: string;
    nodeCategory: NodeCategoryEnum.task;
    assignedTo: AssignedTo;
    category?: string;
    description?: string;
    dueDate: DateDef | null;
    milestoneName?: string | null;
};

//=== Task node types
export type SendEmailTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.sendMessageEmail;
    subject?: string;
    commTemplate?: string;
    message?: string;
    sendTo?: string[];
};
export type SendTextMessageTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.sendMessageText;
    commTemplate?: string;
    message?: string;
    sendTo?: string[];
};

export type ChangeStatusTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.changeStatus;
};

export type ChangeTriageOutcomeTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.changeTriageOutcome;
};

export type MakeDecisionTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.makeDecision;
};

export type MakePaymentTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.makePayment;
};

export type UpdateReserveTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.updateReserve;
};
export type AllocateSupplierTaskConfig = TaskConfigBase & {
    nodeType: NodeTypeEnum.allocateSupplier;
    supplierCompany?: number;
    supplierStaffMember?: number;
    jobType?: string;
    commTemplate?: string;
    message?: string;
};

//=== Control node types
export type WaitNodeConfig = {
    nodeType: NodeTypeEnum.wait;
    nodeCategory: NodeCategoryEnum.control;
    wait: DateDef;
};

export type MergeAndNodeConfig = {
    nodeType: NodeTypeEnum.mergeAnd;
    nodeCategory: NodeCategoryEnum.control;
};

export type MergeOrNodeConfig = {
    nodeType: NodeTypeEnum.mergeOr;
    nodeCategory: NodeCategoryEnum.control;
};

export type SystemNodeConfig = {
    nodeType: NodeTypeEnum.system;
    nodeCategory: NodeCategoryEnum.system;
};

//== Timeline node types
export type LogToTimelineNodeConfig = {
    nodeCategory: NodeCategoryEnum.timeline;
    nodeType: NodeTypeEnum.logToTimeline;
    label: string;
};

export type TaskConfig =
    | ChangeStatusTaskConfig
    | ChangeTriageOutcomeTaskConfig
    | SendEmailTaskConfig
    | SendTextMessageTaskConfig
    | MakeDecisionTaskConfig
    | MakePaymentTaskConfig
    | UpdateReserveTaskConfig
    | AllocateSupplierTaskConfig;

export type TimelineNodeConfig = LogToTimelineNodeConfig;

export type ControlNodeConfig = WaitNodeConfig | MergeAndNodeConfig | MergeOrNodeConfig;

export type CrmNodeConfig = TaskConfig | ControlNodeConfig | SystemNodeConfig | TimelineNodeConfig;

//=== NODE STATE
type NodeStateBase = {
    nodeId: string;
    isCompleted: boolean;
    completedAt?: Date;
};

export type TaskNodeState = NodeStateBase & {
    nodeCategory: NodeCategoryEnum.task;
    nodeType:
        | NodeTypeEnum.changeStatus
        | NodeTypeEnum.changeTriageOutcome
        | NodeTypeEnum.makeDecision
        | NodeTypeEnum.makePayment
        | NodeTypeEnum.updateReserve
        | NodeTypeEnum.allocateSupplier
        | NodeTypeEnum.sendMessageEmail
        | NodeTypeEnum.sendMessageText;
    taskId: bigint;
};

export type WaitNodeState = NodeStateBase & {
    nodeCategory: NodeCategoryEnum.control;
    nodeType: NodeTypeEnum.wait;
    scheduledEventId?: string;
};

export type MergeAndNodeState = NodeStateBase & {
    nodeCategory: NodeCategoryEnum.control;
    nodeType: NodeTypeEnum.mergeAnd;
};

export type MergeOrNodeState = NodeStateBase & {
    nodeCategory: NodeCategoryEnum.control;
    nodeType: NodeTypeEnum.mergeOr;
};

export type ControlNodeState = WaitNodeState | MergeAndNodeState | MergeOrNodeState;

export type SystemNodeState = NodeStateBase & {
    nodeCategory: NodeCategoryEnum.system;
    nodeType: NodeTypeEnum.system;
};

export type TimelineNodeState = NodeStateBase & {
    nodeCategory: NodeCategoryEnum.timeline;
    nodeType: NodeTypeEnum.logToTimeline;
};

export type NodeState = TaskNodeState | ControlNodeState | SystemNodeState | TimelineNodeState;

export type EdgeState = {
    edgeId: string;
    isCompleted: boolean;
    completedAt?: Date;
};

export enum systemNodes {
    start = 'start',
    finish = 'finish',
}

export interface CreateScheduleHttpReq {
    scheduled_time: string;
    payload: { nodeId: string; workflowId: bigint; orgId: bigint; claimId: bigint };
    created_at: string;
    id: string;
}

export interface WorkflowState {
    nodes: NodeState[];
    logs: WfLog[];
    edges: EdgeState[];
}

export interface WfLog {
    message: string;
    timestamp: Date;
}

export interface TargetCell {
    port?: {
        ports: Array<{ id: string; connected: boolean }>;
    };
}
