import { gql } from '@client/__generated__/gql';

export const ClaimDs = gql(/* GraphQL */ `
    query ClaimDs($limit: Int!, $offset: Int!, $orderBy: [ClaimOrderBy!], $where: ClaimBoolExp) {
        ClaimAggregate(where: $where) {
            aggregate {
                count
            }
        }
        Claims(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            id
            excess
            recovery
            excessReceived
            recoveryReceived
            policyNo
            policyStatus
            policyTermFrom
            policyTermTo
            policyIssuedOn
            policyName
            updatedAt
            createdAt
            claimReportedAt
            claimOccurredAt
            causeOfLoss
            status
            decision
            brokerName
            policyOtherInfo
            contacts
            generatedId
            ownerId
            Owner {
                userId
                name
                fullName
                photoUrl
            }
        }
    }
`);

export const ClaimByPk = gql(/* GraphQL */ `
    query ClaimByPk($id: bigint!, $orgId: bigint!) {
        ClaimByPk(id: $id, orgId: $orgId) {
            externalId
            causeOfLoss
            brokerBranch
            brokerCompany
            claimOccurredAt
            claimReportedAt
            createdAt
            contacts
            externalPolicyData
            formData
            id
            phPayId
            phABN
            phAccountName
            phAccountBsb
            phAccountNo
            phCompany
            phITC
            phBusinessType
            phIsRegisteredForGst
            productTypeData
            excess
            recovery
            excessReceived
            recoveryReceived
            policyNo
            policyStatus
            policyTermFrom
            policyTermTo
            policyIssuedOn
            policyName
            updatedAt
            notes
            jsonData
            accessKey
            additionalInfo
            decision
            status
            triageOutcome
            ownerId
            productTypeId
            rootFormQuestionId
            insurerId
            pdsVersionId
            policyOtherInfo
            generatedId
            Insurer {
                name
                applyGst
            }
            Notes(orderBy: [{ createdAt: DESC }]) {
                id
                note
                createdAt
                Author {
                    name
                }
            }
            Owner {
                userFirstName
                userLastName
                email
                fullName
            }
            CreatedBy {
                name
            }
            ExternalOrgs {
                ExternalOrg {
                    id
                    name
                }
            }
            ProductType {
                id
                name
            }
        }
    }
`);

export const ClaimContacts = gql(/* GraphQL */ `
    query ClaimContacts($claimId: bigint!, $orgId: bigint!) {
        ClaimByPk(id: $claimId, orgId: $orgId) {
            contacts
            Owner {
                email
                userFirstName
                userLastName
            }
        }
    }
`);
