import { useNavigate } from '@client/hooks/useNavigate';
import { useAuthStore } from '@client/stores/AuthStore';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/(authentication)/logout')({
    component: Logout,
});

function Logout() {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    useEffect(() => {
        authStore.logout();
        navigate({ to: '/login' });
    }, []);

    return null;
}
