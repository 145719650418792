import { ComponentPreview } from '@client/components/Common/ComponentPreview';
import { DatePicker } from '@client/components/Common/DatePicker';
import { Link } from '@client/components/Common/Link';
import { User } from '@client/components/Common/User';
import { UserSelectorMultiple } from '@client/components/Common/UserSelectorMultiple';
import { ProductSelector } from '@client/components/Complaint/ProductSelector';
import { Filters } from '@client/components/Filters';
import { Alerts } from '@client/components/Indicator/Alerts';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { IncidentDefs } from '@client/global/incident';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { useZodState } from '@client/hooks/useZodState';
import { RouterInputs } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { Falsy, isTruthy } from '@shared/utils/boolean';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Form, Input, InputNumber, Select, Table, Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { listIncidentsInputSchema } from 'server/schemas/incident';

export const Route = createFileRoute('/console/$orgId/compliance/incidents/')({
    staticData: {
        ui: {
            title: 'Incidents',
            subtitle: 'Create, track, and manage compliance incidents and their resolution status',
        },
    },
    component: IncidentSearch,
});

type FiltersForm = {
    id: string | undefined;
    productImpacted: string[] | undefined;
    products: string[] | undefined;
    risk: string[] | undefined;
    typeId: bigint[] | undefined;
    potentialImpactSeverity: string[] | undefined;
    potentialImpactFrequency: string[] | undefined;
    dtIdentified: Date | null;
    status: string[] | undefined;
    ownerIds: bigint[] | undefined;
    externalClaimId: string | undefined;
    policyNo: string | undefined;
};

const FormItem = Form.Item<FiltersForm>;

function IncidentSearch() {
    const orgId = useOrgId();
    const { trpc } = useTrpc();
    const { data: { productsImpacted, risks, types } = {} } = trpc.incident.getIncidentColumnsForFiltering.useQuery();
    const { formatDate, fDateShortDayJs } = useFormatter();
    const navigate = useNavigate();

    const [input, setInput] = useZodState(listIncidentsInputSchema);

    const { data, isPending } = trpc.incident.listIncidents.useQuery(input);

    const [form] = Form.useForm<FiltersForm>();
    const ownerIds = Form.useWatch(({ ownerIds }) => ownerIds, form);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setInput((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : input.orderBy,
            }));
        },
        columns: [
            {
                key: 'indicator',
                render: (_, item) => <Alerts id={item.id} entity="incident" key={item.id} />,
            },
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (_, item) => (
                    <>
                        {item.isBreachWithoutObligations && (
                            <Tooltip
                                title={
                                    <>
                                        This incident is a breach. Please specify which obligation(s) this incident has
                                        breached.
                                    </>
                                }
                            >
                                (Out of sync){' '}
                            </Tooltip>
                        )}
                        {String(item.id)}
                    </>
                ),
            },
            { title: 'Product or service', dataIndex: 'productImpacted', sorter: true },
            {
                title: 'Type',
                dataIndex: 'IncidentType.label',
                sorter: true,
                render: (_, { IncidentType }) =>
                    IncidentType && (
                        <Tag className={clsx(IncidentType.isBreach && 'border-error bg-error text-red-50')}>
                            {IncidentType?.label}
                        </Tag>
                    ),
            },
            {
                title: 'Severity',
                dataIndex: 'potentialImpactSeverity',
                sorter: true,
                render: (value?: IncidentDefs.enumPotentialImpactSeverity) =>
                    value && <Tag color={IncidentDefs.severityToColor[value]}>{value}</Tag>,
            },
            {
                title: 'Incident description',
                dataIndex: 'description',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
            },
            { title: 'Date identified', dataIndex: 'dtIdentified', sorter: true, render: (value) => formatDate(value) },
            { title: 'Date closed', dataIndex: 'dtClosed', sorter: true, render: (value) => formatDate(value) },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (value?: IncidentDefs.IncidentStatus) =>
                    value && (
                        <Tag color={IncidentDefs.statusToColor[value]}>
                            {IncidentDefs.INCIDENT_STATUSES[value] || value}
                        </Tag>
                    ),
            },
            {
                title: 'Owner',
                dataIndex: 'Owner.firstName',
                sorter: true,
                render: (_, { Owner }) => (
                    <User userId={Owner.userId} photoUrl={Owner.photoUrl} name={Owner.fullName} />
                ),
            },
            {
                ...commonActionColumn,
                align: 'center',
                className: 'h-full',
                render: (value) => (
                    <Link.NewTab
                        to="/console/$orgId/compliance/incidents/details/$id/$section"
                        params={{ orgId, id: value, section: 'manage' }}
                    />
                ),
            },
        ],
    });

    function onSearch() {
        const item = form.getFieldsValue();

        const filters: (Falsy | RouterInputs['incident']['listIncidents']['where'])[] = [
            item.id && { id: BigInt(item.id) },
            item.productImpacted?.length && { productImpacted: { in: item.productImpacted } },
            item.products?.length && {
                OR: item.products.map((product) => ({ products: { string_contains: product } })),
            },
            item.risk?.length && { risk: { in: item.risk } },
            item.typeId?.length && { typeId: { in: item.typeId } },
            item.potentialImpactSeverity?.length && { potentialImpactSeverity: { in: item.potentialImpactSeverity } },
            item.potentialImpactFrequency?.length && {
                potentialImpactFrequency: { in: item.potentialImpactFrequency },
            },
            item.dtIdentified && {
                AND: [
                    { dtIdentified: { gte: DateTime.fromJSDate(item.dtIdentified).startOf('day').toJSDate() } },
                    { dtIdentified: { lte: DateTime.fromJSDate(item.dtIdentified).endOf('day').toJSDate() } },
                ],
            },
            item.status?.length && { status: { in: item.status } },
            item.ownerIds?.length && { Owner: { userId: { in: item.ownerIds } } },
            item.externalClaimId && { externalClaimId: { contains: item.externalClaimId } },
            item.policyNo && { policyNo: { contains: item.policyNo } },
        ];

        const where: RouterInputs['incident']['listIncidents']['where'] = { AND: filters.filter(isTruthy) };

        setInput((prev) => ({ ...prev, where }));
    }

    return (
        <>
            <PageLayout.Header
                features={[
                    {
                        feature: 'filters',
                        form,
                        onSearch,
                        children: (
                            <>
                                <Filters.Item>
                                    <FormItem label="Incident ID" name="id">
                                        <InputNumber className="w-full" min="0" />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Part of the business impacted" name="productImpacted">
                                        <Select
                                            options={productsImpacted?.map((item) => ({
                                                label: item || '-',
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Products" name="products">
                                        <ProductSelector />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Risk" name="risk">
                                        <Select
                                            options={risks?.map((item) => ({ label: item || '-', value: item }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>

                                <Filters.Item>
                                    <FormItem label="Type" name="typeId">
                                        <Select
                                            options={types?.map(({ label, id }) => ({
                                                label: label || '-',
                                                value: id,
                                            }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Impact severity" name="potentialImpactSeverity">
                                        <Select
                                            options={IncidentDefs.aryPotentialImpactSeverity.map((item) => ({
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Impact frequency" name="potentialImpactFrequency">
                                        <Select
                                            options={IncidentDefs.aryPotentialImpactFrequency.map((item) => ({
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Date identified" name="dtIdentified">
                                        <DatePicker
                                            placeholder=""
                                            className="w-full"
                                            format={fDateShortDayJs}
                                            maxDate={new Date()}
                                        />
                                    </FormItem>
                                </Filters.Item>

                                <Filters.Item>
                                    <FormItem label="Status" name="status">
                                        <Select
                                            options={Object.keys(IncidentDefs.INCIDENT_STATUSES).map((key) => ({
                                                label: IncidentDefs.INCIDENT_STATUSES[
                                                    key as IncidentDefs.IncidentStatus
                                                ],
                                                value: key,
                                            }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Owner" name="ownerIds">
                                        <UserSelectorMultiple value={ownerIds || []} />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Claim #" name="externalClaimId">
                                        <Input />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Policy #" name="policyNo">
                                        <Input />
                                    </FormItem>
                                </Filters.Item>
                            </>
                        ),
                    },
                ]}
                primaryActionAddon={
                    <Link.Add
                        to="/console/$orgId/compliance/incidents/add"
                        params={{ orgId }}
                        type="primary"
                        label="New incident"
                    />
                }
            />

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick(e) {
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/incidents/details/$id/$section',
                                    params: { orgId, id, section: 'manage' },
                                },
                                e,
                            );
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
