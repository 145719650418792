import { LiteralUnion } from 'type-fest';

/** CORE CONTACT TYPE without the third party type (driver and custom defined) */
export const CORE_CONTACT_TYPE = ['broker', 'policy_holder', 'insurer', 'supplier'];
export type ContactType = 'broker' | 'policy_holder' | 'insurer' | 'supplier' | 'third_party';
export type ContactTypeLiteralUnion = LiteralUnion<ContactType, string>;
export const THIRD_PARTY_SYSTEM_CONTACT_TYPE = ['driver'];
export type ThirdPartyContactType = 'driver';

export type ContactBase = {
    type: ContactType;
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    isPrimaryForType: boolean;
    description?: string;
    note?: string;
    isPrimary: boolean;
};

export type BrokerContact = ContactBase & {
    type: 'broker';
    // For claim
    branch: string;
    ccIntoAllClaimCommunications: boolean;
};

export type InsurerContact = ContactBase & {
    type: 'insurer';
    // For claim
};

export type SupplierContact = ContactBase & {
    type: 'supplier';
    position: string;
};

export type PolicyHolderContact = ContactBase & {
    type: 'policy_holder';
    // For claim
    dob?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressSuburb?: string;
    addressCity?: string;
    addressState?: string;
    addressPostcode?: string;
    addressCountry?: string;
    preferredContactMethod?: PreferredContactMethod;
};

export type ThirdPartyContactBase = ContactBase & {
    type: 'third_party';
    typeName: string;
    isSystemType: boolean;
};

export type ThirdPartyContact = ThirdPartyContactBase & {};

export type DriverContact = ThirdPartyContactBase & {
    isSystemType: true;
    addressLine1?: string;
    addressLine2?: string;
    addressSuburb?: string;
    addressCity?: string;
    addressState?: string;
    addressPostcode?: string;
    addressCountry?: string;
    dob?: string;
    licenseNo?: string;
    licenseExpiry?: string;
};

export type Contact =
    | BrokerContact
    | PolicyHolderContact
    | InsurerContact
    | SupplierContact
    | ThirdPartyContact
    | DriverContact;

export type ContactCollection<T extends ContactBase = Contact> = Record<string, T>;

export const PREFERRED_CONTACT_METHOD = {
    email: 'Email',
    phone: 'Phone',
    post: 'Post',
} as const;

export type PreferredContactMethod = keyof typeof PREFERRED_CONTACT_METHOD;
