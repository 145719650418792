import { createFileRoute } from '@tanstack/react-router';
import { ClaimForm } from '../ClaimLodgement';

export const Route = createFileRoute('/console/$orgId/claims/view/$id')({
    staticData: {
        breadcrumb: {
            title: 'View claim',
        },
    },
    component() {
        return <ClaimForm formAction="view" />;
    },
});
