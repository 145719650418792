import { DownloadOutlined } from '@ant-design/icons';
import { Chart } from '@client/components/Common/Chart';
import { DatePicker } from '@client/components/Common/DatePicker';
import { errorNotification } from '@client/components/Common/Notification/errorNotification';
import { message } from '@client/components/Common/message';
import { Dashboard } from '@client/components/Dashboard';
import { useFormatter } from '@client/hooks/useFormatter';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { downloadBlob } from '@client/utils/blob';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import { parse } from 'json2csv';
import { DateTime } from 'luxon';
import { useId, useState } from 'react';

export const Route = createFileRoute('/console/$orgId/tools/auto-detect/dashboard')({
    staticData: {
        breadcrumb: {
            title: 'Dashboard',
        },
    },
    component: ComplianceAIInboxDashboard,
});

type Filters = {
    dateFrom: Date | null;
    dateTo: Date | null;
};

const FormItem = Form.Item<Filters>;

type ExportedItem = RouterOutputs['emailClassification']['listEmailClassificationForExport'][number];

const issueTypeLabels: Record<ExportedItem['issueType'], string> = {
    COMPLAINT: 'Complaint',
    INCIDENT: 'Incident',
    UNKNOWN: 'Unknown',
    NO_ISSUE: 'No issue',
};

const confidenceLabels: Record<NonNullable<ExportedItem['confidence']>, string> = {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
};

const statusLabels: Record<ExportedItem['status'], string> = {
    ERROR: 'Error',
    PENDING: 'Pending',
    PROCESSED: 'Processed',
    REVIEWED: 'Reviewed',
};

const KEYS = [
    'ID',
    'Issue type',
    'Is a breach',
    'Certainty',
    'status',
    'Date uploaded',
    'UploadedBy',
    'ReviewedBy',
    'Breached regulation',
    'Justification',
] as const;

function DownloadReport() {
    const formId = useId();
    const { trpcUtils } = useTrpc();
    const { formatDate } = useFormatter();

    const [open, setOpen] = useState(false);

    const [form] = Form.useForm<Filters>();
    const dateFrom = Form.useWatch(({ dateFrom }) => dateFrom, form);
    const dateTo = Form.useWatch(({ dateTo }) => dateTo, form);

    async function exportReport({ dateFrom, dateTo }: Filters) {
        try {
            message.loading('Generating report...');

            const data = await trpcUtils.emailClassification.listEmailClassificationForExport.fetch({
                ...(dateFrom && { dateFrom: DateTime.fromJSDate(dateFrom).startOf('day').toJSDate() }),
                ...(dateTo && { dateTo: DateTime.fromJSDate(dateTo).endOf('day').toJSDate() }),
            });

            const rows = data.map(
                (item) =>
                    ({
                        ID: item.id,
                        'Issue type': issueTypeLabels[item.issueType],
                        'Is a breach': item.isBreachOfRegulation ? 'Breach' : 'Not a breach',
                        Certainty: item.confidence ? confidenceLabels[item.confidence] : '',
                        status: statusLabels[item.status],
                        'Date uploaded': formatDate(item.uploadedAt),
                        UploadedBy: item.UploadedBy.fullName,
                        ReviewedBy: item.ReviewedBy?.fullName || '-',
                        'Breached regulation': item.breachedRegulation || '-',
                        Justification: item.justification || '-',
                    }) satisfies Record<(typeof KEYS)[number], any>,
            );

            const csv = parse(rows, { fields: KEYS as any as string[] });

            downloadBlob(
                new Blob([csv], { type: 'text/csv;charset=utf-8' }),
                `email-classification-report-${formatDate(new Date())}.csv`,
            );

            message.success('Report generated successfully.');
            setOpen(false);
        } catch (e) {
            errorNotification(e as Error);
        }
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={() => setOpen(false)}
                title="Download report"
                okText="Create report"
                okButtonProps={{ form: formId, htmlType: 'submit' }}
            >
                <Typography.Paragraph>
                    Create a report of all issues detected by the Curium Auto-detect tool between the following dates:
                </Typography.Paragraph>
                <Form id={formId} form={form} layout="vertical" onFinish={exportReport}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <FormItem label="Date from" name="dateFrom">
                                <DatePicker className="w-full" maxDate={dateTo || undefined} allowClear />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="Date to" name="dateTo">
                                <DatePicker className="w-full" minDate={dateFrom || undefined} allowClear />
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Button type="primary" icon={<DownloadOutlined />} onClick={() => setOpen(true)}>
                Download report
            </Button>
        </>
    );
}

function ComplianceAIInboxDashboard() {
    const { data, isPending } = trpc.dashboard.complianceAIInboxDashboard.useQuery();

    return (
        <>
            <div className="mb-4 flex justify-end">
                <DownloadReport />
            </div>

            <Dashboard.Row>
                <Dashboard.Item title="Emails scanned last week" colSpan={12}>
                    <Chart
                        loading={isPending}
                        height={350}
                        items={[
                            {
                                type: 'interval',
                                data: data?.emailClassificationsInLastWeek || [],
                                encode: { x: 'day', y: 'count', color: 'type' },
                                transform: [{ type: 'stackY' }],
                                axis: {
                                    y: { title: 'Number of emails' },
                                },
                                labels: ['percentage', 'total'],
                            },
                        ]}
                    />
                </Dashboard.Item>
                <Dashboard.Item title="Emails scanned last 6 months" colSpan={12}>
                    <Chart
                        loading={isPending}
                        height={350}
                        items={[
                            {
                                type: 'interval',
                                data: data?.emailClassificationsInLast6Month || [],
                                encode: { x: 'month', y: 'count', color: 'type' },
                                transform: [{ type: 'stackY' }],
                                axis: {
                                    y: { title: 'Number of emails' },
                                },
                                labels: ['percentage', 'total'],
                            },
                        ]}
                    />
                </Dashboard.Item>
                <Dashboard.Item title="Confirmed issues by month" colSpan={12}>
                    <Chart
                        loading={isPending}
                        height={350}
                        items={[
                            {
                                type: 'line',
                                data: data?.confirmedIssuesByMonth || [],
                                encode: { x: 'month', y: 'count', color: 'type' },
                                axis: { y: { title: 'Number of issues' } },
                            },
                            {
                                type: 'point',
                                data: data?.confirmedIssuesByMonth || [],
                                encode: { x: 'month', y: 'count', color: 'type' },
                            },
                        ]}
                    />
                </Dashboard.Item>
            </Dashboard.Row>
        </>
    );
}
