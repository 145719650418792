import { TaskEdit } from '@client/components/Task/TaskEdit';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/workload-manager/tasks/calendar/add')({
    staticData: {
        metadata: {
            title: 'New task',
        },
        displayType: 'overlay',
    },
    component() {
        return <TaskEdit />;
    },
});
