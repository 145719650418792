import { NoteEdit } from '@client/components/Claim/NoteEdit';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/manage/strategies/add')({
    component: StrategyAdd,
});

function StrategyAdd() {
    const { id } = Route.useParams();
    const orgId = useOrgId();
    const { trpcUtils } = useTrpc();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    return (
        <NoteEdit
            entityId={id}
            entity="claim"
            open={open}
            type="STRATEGY"
            title="Add strategy"
            onClose={() => {
                setOpen(false);
                trpcUtils.note.invalidate();

                navigate({
                    to: '/console/$orgId/claims/details/$id/manage',
                    params: {
                        orgId,
                        id,
                    },
                    fromComponent: 'drawer',
                });
            }}
        />
    );
}
