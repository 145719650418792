export enum ComplaintSources {
    internal = 'internal',
    external = 'external',
}

export const COMPLAINT_STATUS = {
    OPEN: 'open',
    CLOSED: 'closed',
    RE_OPENED: 're-opened',
} as const;

export type ComplaintStatus = (typeof COMPLAINT_STATUS)[keyof typeof COMPLAINT_STATUS];

export const COMPLAINANT_TYPE = {
    INDIVIDUAL: 'Individual or couple',
    SMALL_BUSINESS: 'Small business',
    OTHER: 'Other',
    UNKNOWN: 'Unknown',
} as const;

export type ComplainantType = (typeof COMPLAINANT_TYPE)[keyof typeof COMPLAINANT_TYPE];
