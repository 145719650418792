export function replaceIf<T>(arr: T[], predicate: (item: T) => boolean, newElement: T): T[] {
    return arr.map((item) => (predicate(item) ? newElement : item));
}

export function addIfUnique<T>(arr: T[], isUnique: (item: T) => boolean, newElement: T): T[] {
    if (arr.every(isUnique)) {
        arr.push(newElement);
    }
    return arr;
}

export function sortBy<T, K>(
    arr: T[] | undefined,
    iterateeOrProperty: ((item: T) => K) | keyof T,
    sortOrder: 'asc' | 'desc' = 'asc',
) {
    if (!arr) {
        return [];
    }

    return arr.toSorted((a, b) => {
        const [valueA, valueB] =
            typeof iterateeOrProperty === 'function'
                ? [iterateeOrProperty(a), iterateeOrProperty(b)]
                : [a[iterateeOrProperty], b[iterateeOrProperty]];

        if (
            (sortOrder === 'asc' && (valueB === undefined || valueB === null)) ||
            (sortOrder === 'desc' && (valueA === undefined || valueA === null))
        ) {
            return -1;
        }

        const order = +valueA - +valueB;
        return sortOrder === 'asc' ? order : -order;
    });
}
