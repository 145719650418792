import { format, parseISO, set } from 'date-fns';
import moment, { Moment } from 'moment';
import dayjs from 'dayjs';

/**
 * @deprecated Use useFormatter hook instead
 */
export function formatISODateTime(isoDt: string | undefined | null, dtFormat?: string): string {
    const _dtFormat = dtFormat || 'dd/MM/yyy HH:mm';
    if (!isoDt) {
        return '';
    }
    const isoDateWithZone = isoDt.toLowerCase().endsWith('z') ? isoDt : isoDt + 'Z';
    const date = parseISO(isoDateWithZone);
    return format(date, _dtFormat);
}

export function parseISOToDate(isoDate: string) {
    const dt = parseISO(isoDate);
    return set(dt, { hours: 0, minutes: 0, milliseconds: 0 });
}

export function dayJsToISO(value: dayjs.Dayjs | null): string | null {
    return value ? value.toISOString() : null;
}

export function isoToDayJs(isoDate: string | null | undefined): dayjs.Dayjs | null {
    return isoDate ? dayjs(isoDate) : null;
}

export function dateToDayJs(date: Date | null | undefined): dayjs.Dayjs | null {
    return date ? dayjs(date) : null;
}

export function isoToMoment(isoDate: string | null | undefined): Moment | null {
    return isoDate ? moment(isoDate) : null;
}

export function numberToMonth(month: number, monthFormat: string = 'MMMM'): string {
    const date = new Date(2000, month, 1);
    return format(date, monthFormat);
}

export function getMonthName(month: number) {
    if (month >= 0 && month <= 11) {
        return dayjs().locale('en').month(month).format('MMMM');
    } else {
        return null;
    }
}
