import { makeObservable } from 'mobx';
import Joi, { ValidationResult } from 'joi';

import {
    CardDef,
    CardDefBase,
    CardDefForm,
    CardDefMultiSelect,
    CardDefSingleSelect,
    CardDefSpecifiedItems,
    CardDefText,
    CardType,
    FormValues,
} from '../types/questionnaire/definition';
import { Except, JsonObject } from 'type-fest';
import { CardChild, CardChildBase, CardChildFactory, CardChildOption } from './CardChild';
import { cloneDeep } from '../utils/general';

export abstract class CardBase implements CardDefBase {
    id: string = '';
    title: string = '';
    titleInsured: string = '';
    titleSystem: string = '';
    logicalGroup1: string = '';
    logicalGroup2: string = '';
    logicalGroup3: string = '';
    logicalGroup4: string = '';
    logicalGroup5: string = '';
    internalName: string = '';
    nextCardId: string = '';
    type: CardType = 'FORM';
    isRoot?: boolean = false;
    seqNo: number = -1;
    parentCard?: CardBase = undefined;
    nextCard?: CardBase = undefined;
    answered: boolean = false;
    description?: string;
    validationResults: ValidationResult[] = [];

    constructor(data?: CardBase | CardDefBase, parentCard?: CardBase) {
        const _data = cloneDeep(data);
        if (_data && 'children' in _data) {
            delete _data['children'];
        }
        if (data) {
            Object.assign(this, JSON.parse(JSON.stringify(data)));
            if (parentCard) {
                this.parentCard = parentCard;
            }
            if ('nextCard' in data && data.nextCard) {
                this.nextCard = CardFactory.create(data.nextCard, this);
            }
        }
        makeObservable(this, {
            answered: true,
            id: true,
            internalName: true,
            isRoot: true,
            logicalGroup1: true,
            logicalGroup2: true,
            logicalGroup3: true,
            logicalGroup4: true,
            logicalGroup5: true,
            nextCard: true,
            nextCardId: true,
            parentCard: true,
            seqNo: true,
            title: true,
            titleInsured: true,
            titleSystem: true,
            type: true,
            validate: true,
            validationErrors: true,
            validationResults: true,
            description: true,
        });
    }

    get validationErrors(): ValidationResult[] {
        return this.validationResults.filter((vr) => !!vr.error);
    }

    /**
     * Validate the card and set's the validationResults
     * @param values
     */
    abstract validate(values: FormValues): void;

    toJS() {
        const json = JSON.parse(
            JSON.stringify(this, (key, value) => {
                if (key === 'parentCard' || key === 'validationResults' || key === 'validationErrors') {
                    return undefined;
                }
                return value;
            }),
        );
        if (this.nextCard) {
            json.nextCard = this.nextCard.toJS();
        }
        return json as JsonObject;
    }
}

type CardDefWithoutTypeAndChildren<T extends { type: any; children?: any }> = Except<T, 'type' | 'children'>;

export class CardSingleSelect extends CardBase implements CardDefWithoutTypeAndChildren<CardDefSingleSelect> {
    children: Array<CardChildOption> = [];
    required: boolean = false;

    constructor(data: CardSingleSelect, parentCard?: CardBase) {
        super(data, parentCard);
        if ('children' in data && data.children && data.children.length > 0) {
            this.children = data.children.map((c) => {
                if (c.type === 'OPTION') {
                    return new CardChildOption(c, this);
                } else {
                    throw new Error(`Unknown child type: ${c['type']}`);
                }
            });
        }
        makeObservable(this, { children: true, required: true });
    }

    toJS() {
        const json = super.toJS();
        json.children = this.children.map((c) => c.toJS());
        json.required = this.required;
        return json as JsonObject;
    }

    validate() {
        if (this.required) {
            this.validationResults = [
                Joi.custom((value: CardChildBase[], helpers) => {
                    const isValid = value.some((o) => o.isSelected || false);
                    return isValid ? true : helpers.error('form.select_at_least_one');
                })
                    .message('Please select at least one option.')
                    .validate(this.children),
            ];
        }
    }
}

export class CardMultiSelect extends CardBase implements CardDefWithoutTypeAndChildren<CardDefMultiSelect> {
    children: Array<CardChildOption> = [];
    required: boolean = false;

    constructor(data: CardMultiSelect, parentCard?: CardBase) {
        super(data, parentCard);
        if ('children' in data && data.children && data.children.length > 0) {
            this.children = data.children.map((c) => {
                if (c.type === 'OPTION') {
                    return new CardChildOption(c, this);
                } else {
                    throw new Error(`Unknown child type: ${c['type']}`);
                }
            });
        }
        makeObservable(this, { children: true, required: true });
    }

    toJS() {
        const json = super.toJS();
        json.children = this.children.map((c) => c.toJS());
        json.required = this.required;
        return json as JsonObject;
    }

    validate() {
        this.validationResults = [
            Joi.custom((value: CardChildBase[], helpers) => {
                const isValid = value.some((o) => o.isSelected || false);
                return isValid ? true : helpers.error('form.select_at_least_one');
            })
                .message('Please select at least one option.')
                .validate(this.children),
        ];
    }
}

export class CardForm extends CardBase implements CardDefWithoutTypeAndChildren<CardDefForm> {
    children: CardChild[] = [];
    constructor(data: CardForm, parentCard?: CardBase) {
        super(data, parentCard);
        if ('children' in data && data.children && data.children.length > 0) {
            this.children = data.children.map((c) => CardChildFactory.create(c, this));
        }
        makeObservable(this, {
            children: true,
        });
    }

    toJS() {
        const json = super.toJS();
        json.children = this.children.map((c) => c.toJS());
        return json as JsonObject;
    }

    validate(values: FormValues) {
        this.children.map((c) => {
            c.validate(values);
        });
        this.validationResults = this.children.map((o) => o.validationError).filter((ve) => !!ve) as ValidationResult[];
    }
}

export class CardSpecifiedItems extends CardBase implements CardDefWithoutTypeAndChildren<CardDefSpecifiedItems> {
    children: CardChild[] = [];

    constructor(data: CardSpecifiedItems, parentCard?: CardBase) {
        super(data, parentCard);
        if ('children' in data && data.children && data.children.length > 0) {
            this.children = data.children.map((c) => CardChildFactory.create(c, this));
        }
        makeObservable(this, {
            children: true,
        });
    }

    toJS() {
        const json = super.toJS();
        json.children = this.children.map((c) => c.toJS());
        return json as JsonObject;
    }

    validate(values: FormValues) {
        this.children.map((c) => c.validate(values));
        this.validationResults = this.children.map((o) => o.validationError).filter((ve) => !!ve) as ValidationResult[];
    }
}

export class CardText extends CardBase implements CardDefWithoutTypeAndChildren<CardDefText> {
    content: string = '';
    constructor(data: CardText, parentCard?: CardBase) {
        super(data, parentCard);
        this.content = data.content || '';

        makeObservable(this, {
            content: true,
        });
    }

    toJS() {
        const json = super.toJS();
        json.content = this.content;
        return json as JsonObject;
    }

    validate() {
        this.validationResults = [];
    }
}

export class CardFactory {
    static create(data: CardBase | CardDef, parentCard?: CardBase): Card {
        switch (data.type) {
            case 'SINGLE_SELECT':
                return new CardSingleSelect(data as CardSingleSelect, parentCard);
            case 'MULTI_SELECT':
                return new CardMultiSelect(data as CardMultiSelect, parentCard);
            case 'FORM':
                return new CardForm(data as CardForm, parentCard);
            case 'SPECIFIED_ITEMS':
                return new CardSpecifiedItems(data as CardSpecifiedItems, parentCard);
            case 'TEXT':
                return new CardText(data as CardText, parentCard);
            default:
                throw new Error(`Unknown card type: ${data['type']}`);
        }
    }
}

export type Card = CardSingleSelect | CardMultiSelect | CardForm | CardSpecifiedItems | CardText;
