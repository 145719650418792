import { TaskDetails } from '@client/components/Task/TaskDetails';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/workload-manager/tasks/list/details/$id')({
    staticData: {
        metadata: {
            title: 'Task details',
        },
        displayType: 'overlay',
    },
    params: {
        parse({ id }) {
            return { id: BigInt(id) };
        },
    },
    component() {
        return <TaskDetails id={Route.useParams().id} />;
    },
});
