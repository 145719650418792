import { useTrpc } from '@client/hooks/useTrpc';
import { RouterOutputs } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { App, Badge, Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/advanced/monitoring')({
    staticData: { ui: { title: 'Monitoring' }, breadcrumb: { title: 'System Logs' } },
    component: Monitoring,
});

type SubsystemCheckResult = RouterOutputs['admin']['monitor']['checkSubsystems'];

function Monitoring() {
    const { message } = App.useApp();
    const { trpcClient } = useTrpc();
    const [subsystems, setSubsystems] = useState<SubsystemCheckResult>([]);
    const [loading, setLoading] = useState(false);

    const handleCheckSubsystems = async () => {
        try {
            setLoading(true);
            message.loading('Checking subsystems...');
            const results = await trpcClient.admin.monitor.checkSubsystems.query();
            setSubsystems(results);
            console.log(results);
            message.success('Subsystems checked successfully');
        } catch {
            message.error('Failed to check subsystems');
        } finally {
            setLoading(false);
            message.destroy();
        }
    };

    const columns: ColumnsType<SubsystemCheckResult[number]> = [
        { title: 'Subsystem', dataIndex: 'name', key: 'name' },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: boolean) => (
                <Badge status={status ? 'success' : 'error'} text={status ? 'Healthy' : 'Error'} />
            ),
        },
        { title: 'Version', dataIndex: 'version', key: 'version' },
        { title: 'Message', dataIndex: 'message', key: 'message' },
    ];

    return (
        <>
            <div className="mb-6">
                <Typography.Title level={4}>System Health Check</Typography.Title>
                <Typography.Paragraph className="text-gray-600">
                    Check the status of various system components and services.
                </Typography.Paragraph>
            </div>

            <Button type="primary" onClick={handleCheckSubsystems} loading={loading} className="mb-6">
                Check Subsystems
            </Button>

            <Table
                dataSource={subsystems}
                columns={columns}
                rowKey="name"
                loading={loading}
                pagination={false}
                className="shadow-xs"
            />
        </>
    );
}
