import { StoreWithSelectors } from '@client/types/zustand';
import superjson from 'superjson';
import { StoreApi, useStore } from 'zustand';
import { PersistStorage } from 'zustand/middleware';

export function createSelectorsForVanillaStore<S extends StoreApi<object>>(store: S) {
    const storeWithSelectors = store as StoreWithSelectors<typeof store>;

    storeWithSelectors.use = Object.keys(storeWithSelectors.getState()).reduce((prev, item) => {
        prev[item] = () => useStore(storeWithSelectors, (state) => state[item as keyof typeof state]);

        return prev;
    }, {} as any);

    return storeWithSelectors;
}

/**
 * Creates a persister that uses localStorage and superjson to persist the store.
 * @returns
 */
export function createLocalStoragePersister<T>(): PersistStorage<T> {
    return {
        getItem: (name: string) => {
            const str = localStorage.getItem(name);
            if (!str) return null;
            return superjson.parse(str);
        },
        setItem: (name, value) => {
            localStorage.setItem(name, superjson.stringify(value));
        },
        removeItem: (name) => localStorage.removeItem(name),
    };
}
