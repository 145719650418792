import superjson from 'superjson';
import { create } from 'zustand';
import { persist, PersistStorage } from 'zustand/middleware';

const storage: PersistStorage<DebugStore> = {
    getItem: (name) => {
        const str = localStorage.getItem(name);
        if (!str) return null;
        return superjson.parse(str);
    },
    setItem: (name, value) => {
        localStorage.setItem(name, superjson.stringify(value));
    },
    removeItem: (name) => localStorage.removeItem(name),
};

export const useDebugStore = create<DebugStore>()(
    persist<DebugStore>(
        (set) => ({
            isForceJwtExpiredEnabled: false,
            isForceDebugEnabled: false,
            setIsForceJwtExpiredEnabled: (value) => set({ isForceJwtExpiredEnabled: value }),
            setIsForceDebugEnabled: (value) => set({ isForceDebugEnabled: value }),
        }),
        {
            name: 'debug',
            storage: storage,
        },
    ),
);

type DebugStore = {
    isForceJwtExpiredEnabled: boolean;
    isForceDebugEnabled: boolean;
    setIsForceJwtExpiredEnabled: (value: boolean) => void;
    setIsForceDebugEnabled: (value: boolean) => void;
};
