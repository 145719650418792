import { FilterOutlined } from '@ant-design/icons';
import { ClaimReassignmentAction, ClaimReassignmentDrawer } from '@client/components/Claim/ClaimReassignmentDrawer';
import { CollapsiblePanel } from '@client/components/Common/CollapsiblePanel';
import { DatePicker } from '@client/components/Common/DatePicker';
import { HandlingPartySelect } from '@client/components/Common/HandlingPartySelect';
import { Link } from '@client/components/Common/Link';
import { User } from '@client/components/Common/User';
import { UserSelectorMultiple } from '@client/components/Common/UserSelectorMultiple';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useZodState } from '@client/hooks/useZodState';
import { trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { listClaimsInputSchema } from '@server/schemas/claim';
import { CLAIM_STATUSES } from '@shared/definitions/claim';
import { SYSTEM_ACCOUNT_IDS } from '@shared/definitions/user';
import { getContactFullName, getFirstContactForType, getPrimaryContactForType } from '@shared/utils/contact';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Form, Input, Row, Select, Space, Table, Tag, Tooltip } from 'antd';
import { Key } from 'antd/es/table/interface';
import { DateTime } from 'luxon';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/claims/')({
    staticData: {
        ui: {
            title: 'View claims',
            subtitle: 'Search and manage claims',
        },
    },
    component: ClaimSearch,
});

type FiltersForm = Partial<{
    generatedId: string;
    causeOfLoss: string;
    policyNo: string;
    phName: string;
    phCompany: string;
    insurerName: string;
    brokerName: string;
    dateOfLoss: Date | null;
    ownerIds: bigint[];
    statuses: string[];
    handlingPartyIds: bigint[];
    externalId: string;
}>;

const FormItem = Form.Item<FiltersForm>;

function ClaimSearch() {
    const orgId = useOrgId();
    const { formatDate } = useFormatter();
    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [reassignmentAction, setReassignmentAction] = useState<ClaimReassignmentAction>(['none']);
    const [showFilter, setShowFilter] = useState(false);
    const [queryParams, setQueryParams] = useZodState(listClaimsInputSchema);

    const { data, isLoading } = trpc.claim.listClaims.useQuery(queryParams);

    const [form] = Form.useForm<FiltersForm>();
    const handlingPartyIds = Form.useWatch(({ handlingPartyIds }) => handlingPartyIds, form);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setQueryParams((prev) => ({
                ...prev,
                limit: options?.limit ?? prev.limit,
                offset: options?.offset ?? prev.offset,
                orderBy: options?.orderBy ?? prev.orderBy,
            }));
        },
        columns: [
            {
                title: 'Claim#',
                dataIndex: 'generatedId',
                sorter: true,
                className: 'font-bold',
                render: (_, { generatedId, id }) => generatedId || id,
            },
            {
                title: 'Owner',
                dataIndex: 'ownerId',
                sorter: true,
                render: (_, { Owner }) => (
                    <User userId={Owner?.userId} name={Owner?.fullName} photoUrl={Owner?.photoUrl} />
                ),
            },
            {
                title: 'Claim status',
                dataIndex: 'status',
                sorter: true,
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                title: 'Policy#',
                dataIndex: 'policyNo',
                sorter: true,
                className: 'font-bold',
            },
            {
                title: 'Decision',
                dataIndex: 'decision',
                sorter: true,
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                title: 'First name',
                dataIndex: 'contacts',
                sorter: true,
                render: (value) =>
                    getPrimaryContactForType(value, 'policy_holder')?.firstName ||
                    getFirstContactForType(value, 'policy_holder')?.firstName ||
                    '',
            },
            {
                title: 'Surname',
                dataIndex: 'contacts',
                sorter: true,
                render: (value) =>
                    getPrimaryContactForType(value, 'policy_holder')?.lastName ||
                    getFirstContactForType(value, 'policy_holder')?.lastName ||
                    '',
            },
            {
                title: 'Cause of loss',
                dataIndex: 'causeOfLoss',
                sorter: true,
            },
            {
                title: 'Date of loss',
                dataIndex: 'claimOccurredAt',
                sorter: true,
                render: (value) => formatDate(value),
            },
            {
                title: 'Broker',
                dataIndex: 'contacts',
                sorter: true,
                render: (value) =>
                    getContactFullName(getPrimaryContactForType(value, 'broker')) ||
                    getContactFullName(getFirstContactForType(value, 'broker')),
            },
            {
                title: 'Claim submitted',
                dataIndex: 'createdAt',
                sorter: true,
                render: (value) => formatDate(value),
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Link.NewTab
                        to="/console/$orgId/claims/details/$id/manage"
                        params={{
                            orgId,
                            id: value,
                        }}
                    />
                ),
            },
        ],
    });

    function onSearch() {
        const values = form.getFieldsValue();
        const conditions: NonNullable<typeof queryParams.where>[] = [];

        if (values.generatedId) {
            conditions.push({ generatedId: { contains: values.generatedId, mode: 'insensitive' } });
        }
        if (values.causeOfLoss) {
            conditions.push({ causeOfLoss: { contains: values.causeOfLoss, mode: 'insensitive' } });
        }
        if (values.phName) {
            conditions.push({
                searchContent: {
                    contains: `%policy_holder_start!%${values.phName}%!policy_holder_end%`,
                    mode: 'insensitive',
                },
            });
        }
        if (values.phCompany) {
            conditions.push({
                phCompany: {
                    contains: values.phCompany,
                    mode: 'insensitive',
                },
            });
        }
        if (values.insurerName) {
            conditions.push({
                searchContent: {
                    contains: `%insurer_start!%${values.insurerName}%!insurer_end%`,
                    mode: 'insensitive',
                },
            });
        }
        if (values.brokerName) {
            conditions.push({
                searchContent: {
                    contains: `%broker_start!%${values.brokerName}%!broker_end%`,
                    mode: 'insensitive',
                },
            });
        }
        if (values.dateOfLoss) {
            conditions.push({
                AND: [
                    { claimOccurredAt: { gte: DateTime.fromJSDate(values.dateOfLoss).startOf('day').toJSDate() } },
                    {
                        claimOccurredAt: {
                            lt: DateTime.fromJSDate(values.dateOfLoss).plus({ days: 1 }).toJSDate(),
                        },
                    },
                ],
            });
        }
        if (values.policyNo) {
            conditions.push({ policyNo: { contains: values.policyNo, mode: 'insensitive' } });
        }

        if (values.ownerIds?.length) {
            const ownerConditions = values.ownerIds.map((ownerId) =>
                BigInt(ownerId) === SYSTEM_ACCOUNT_IDS.UNALLOCATED ? { ownerId: null } : { ownerId: ownerId },
            );
            conditions.push({ OR: ownerConditions });
        }

        if (values.statuses?.length) {
            conditions.push({
                status: { in: values.statuses },
            });
        }

        if (values.handlingPartyIds?.length) {
            conditions.push({
                Claim_HandlingParty: {
                    some: {
                        handlingPartyId: { in: values.handlingPartyIds },
                    },
                },
            });
        }

        if (values.externalId) {
            conditions.push({
                externalId: { contains: values.externalId, mode: 'insensitive' },
            });
        }

        setQueryParams((prev) => ({
            ...prev,
            where: conditions.length ? { AND: conditions } : undefined,
        }));
    }

    return (
        <>
            <PageLayout.Header
                rightAddon={
                    <>
                        <Tooltip title="Filters">
                            <Button
                                type="text"
                                size="large"
                                title="Filter"
                                onClick={() => {
                                    setShowFilter((prev) => !prev);
                                }}
                            >
                                <FilterOutlined />
                            </Button>
                        </Tooltip>
                    </>
                }
            />

            <ClaimReassignmentDrawer
                onClose={(resetSelectedRowKeys) => {
                    setReassignmentAction(['none']);
                    if (resetSelectedRowKeys) {
                        setSelectedRowKeys([]);
                    }
                }}
                action={reassignmentAction}
            />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form layout="vertical" form={form}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Claim#" name="generatedId">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Policy Holder name" name="phName">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Cause of loss" name="causeOfLoss">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Policy Holder company" name="phCompany">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Date of loss" name="dateOfLoss">
                                    <DatePicker className="w-full" placeholder="" />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Insurer contact" name="insurerName">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Policy Number" name="policyNo">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Broker contact" name="brokerName">
                                    <Input />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Status" name="statuses">
                                    <Select
                                        options={Object.values(CLAIM_STATUSES).map((value) => ({
                                            value,
                                        }))}
                                        mode="multiple"
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Claim owner" name="ownerIds">
                                    <UserSelectorMultiple handlingPartyIds={handlingPartyIds} showUnallocated />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Handling parties" name="handlingPartyIds">
                                    <HandlingPartySelect
                                        forAdminShowAllOrgs
                                        onChange={() => {
                                            form.resetFields(['ownerIds']);
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="External#" name="externalId">
                                    <Input />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            form.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit" onClick={onSearch}>
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Card>
                <Button
                    className="mb-4"
                    disabled={!selectedRowKeys.length}
                    onClick={() => setReassignmentAction(['selected', selectedRowKeys as bigint[]])}
                >
                    Reassign selected claims
                </Button>
                <Table
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick(e) {
                            navigate(
                                {
                                    to: '/console/$orgId/claims/details/$id/manage',
                                    params: {
                                        orgId,
                                        id,
                                    },
                                },
                                e,
                            );
                        },
                    })}
                    rowSelection={{
                        selectedRowKeys,
                        preserveSelectedRowKeys: true,
                        onChange(selectedRowKeys: Key[]) {
                            setSelectedRowKeys(selectedRowKeys);
                        },
                        columnTitle: (originalNode) => <Tooltip title="Select all claims">{originalNode}</Tooltip>,
                    }}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
