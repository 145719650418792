import { useOrgId } from '@client/hooks/Org/useOrgId';
import { createFileRoute, Navigate } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/tools/')({
    component() {
        const orgId = useOrgId();

        return <Navigate to="/console/$orgId/tools/auto-detect" params={{ orgId }} />;
    },
});
