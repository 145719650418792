import Joi from 'joi';
import { DateTime } from 'luxon';

export function validateLossDate({
    claimOccurredAt,
    policyTermFrom,
    policyTermTo,
}: {
    claimOccurredAt: Date;
    policyTermFrom: Date;
    policyTermTo: Date;
}) {
    const schema = Joi.date()
        .label('What is the date of loss?')
        .required()
        .ruleset.greater(policyTermFrom!)
        .less(policyTermTo!)
        .rule({ message: 'Loss date outside of policy period' });
    return schema.validate(claimOccurredAt);
}

export function validateReportedAt({
    claimReportedAt,
    claimOccurredAt,
    policyTermFrom,
    policyTermTo,
}: {
    claimReportedAt: Date;
    claimOccurredAt: Date;
    policyTermFrom: Date;
    policyTermTo: Date;
}) {
    const schema = Joi.date()
        .label('When was the loss reported?')
        .required()
        .ruleset.greater(policyTermFrom!)
        .less(policyTermTo!)
        .rule({ message: 'Loss date outside of policy period' })
        .ruleset.min(claimOccurredAt!)
        .rule({ message: 'The claim must be reported after it happened.' })
        .less('now')
        .message("{{#label}} can't be in the future");
    return schema.validate(claimReportedAt);
}

export function isOutsideOfPolicyTerms({
    policyStatus,
    policyTermFrom,
}: {
    policyStatus: string;
    policyTermFrom: Date;
}) {
    return (
        policyStatus?.toLowerCase() === 'expired' ||
        DateTime.fromJSDate(policyTermFrom).diffNow().months > 12
    );
}
