import {
    AppstoreOutlined,
    BankOutlined,
    CreditCardOutlined,
    InfoCircleOutlined,
    LockOutlined,
    TeamOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { Link } from '@client/components/Common/Link';
import { message } from '@client/components/Common/message';
import { PageContent } from '@client/components/Layout/PageContent';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { OnFinishFailedFn } from '@client/types/form';
import { ShareableRouteContext } from '@client/types/router';
import { APP_MODULES } from '@shared/definitions/app';
import { AUTH0_CONNECTIONS } from '@shared/definitions/auth0';
import { createFileRoute } from '@tanstack/react-router';
import { Alert, Button, Card, Checkbox, Form, Input, Select, Skeleton, Tabs } from 'antd';
import { useEffect, useId, useState } from 'react';
import invariant from 'tiny-invariant';

export const Route = createFileRoute('/admin-console/organizations/$orgId')({
    params: {
        parse({ orgId }) {
            return { orgId: BigInt(orgId) };
        },
    },
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.admin.org.getOrg.fetch({ orgId: params.orgId });

        async function title() {
            const org = await trpcUtils.admin.org.getOrg.fetch({ orgId: params.orgId });
            return org?.name || '';
        }

        return { breadcrumb: { title }, ui: { title, subtitle: 'Manage organization settings and users' } };
    },
    component: OrgForm,
});

type Org = Pick<
    NonNullable<RouterOutputs['admin']['org']['getOrg']>,
    | 'name'
    | 'enabledModules'
    | 'enabledAuth0Connections'
    | 'emailId'
    | 'isEnabled'
    | 'stripeId'
    | 'maxAllowedUsers'
    | 'isInitialized'
    | 'restrictions'
    | 'defaultEmailAddress'
    | 'replyToEmailAddress'
    | 'lastLoginAt'
    | 'createdAt'
>;

const FormItem = Form.Item<Org>;

const moduleOptions = [
    { label: 'Claims Module', value: APP_MODULES.CLAIM, icon: <AppstoreOutlined /> },
    { label: 'Compliance Module', value: APP_MODULES.COMPLIANCE, icon: <AppstoreOutlined /> },
    { label: 'Risk Module', value: APP_MODULES.RISK, icon: <AppstoreOutlined /> },
    { label: 'Expenses & Billing', value: APP_MODULES.BILLING, icon: <AppstoreOutlined /> },
    { label: 'Tools Module', value: APP_MODULES.TOOLS, icon: <AppstoreOutlined /> },
];

function OrgForm() {
    const formId = useId();
    const { orgId } = Route.useParams();
    const [form] = Form.useForm<Org>();
    const { trpcClient, trpcUtils } = useTrpc();
    const [saving, setSaving] = useState(false);
    const [activeTab, setActiveTab] = useState('basic');

    const { data, isPending } = trpc.admin.org.getOrg.useQuery({ orgId }, {});

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data?.id]);

    const onSave = async (values: Org) => {
        try {
            setSaving(true);
            message.loading('Saving...');
            invariant(orgId, 'orgId is required');
            console.log(values);
            await trpcClient.admin.org.updateOrg.mutate({
                orgId,
                set: {
                    name: values.name,
                    isEnabled: values.isEnabled,
                    enabledModules: values.enabledModules,
                    enabledAuth0Connections: values.enabledAuth0Connections,
                    emailId: values.emailId,
                    stripeId: values.stripeId,
                    maxAllowedUsers: values.maxAllowedUsers,
                    isInitialized: values.isInitialized,
                    restrictions: values.restrictions,
                    defaultEmailAddress: values.defaultEmailAddress ?? undefined,
                    replyToEmailAddress: values.replyToEmailAddress ?? undefined,
                },
            });

            message.success('Organization settings saved successfully.');
            void trpcUtils.admin.org.invalidate();
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    };

    const onFinishFailed: OnFinishFailedFn<Org> = (errorInfo) => {
        errorMessage.validation(errorInfo.errorFields.map((field) => field.errors));
    };

    if (isPending) {
        return <Skeleton active />;
    }

    return (
        <PageContent>
            <Form<Org>
                layout="vertical"
                form={form}
                onFinish={onSave}
                onFinishFailed={onFinishFailed}
                className="space-y-6"
                id={formId}
            >
                <PageLayout.Header
                    rightAddon={
                        <>
                            <Link
                                to="/admin-console/organizations/$orgId/users"
                                params={{ orgId }}
                                type="default"
                                icon={<TeamOutlined />}
                            >
                                Users
                            </Link>
                            <Link
                                to="/admin-console/organizations/$orgId/wallet"
                                params={{ orgId }}
                                type="default"
                                icon={<WalletOutlined />}
                            >
                                Wallet
                            </Link>
                            <Link
                                to="/admin-console/organizations/$orgId/subscriptions"
                                params={{ orgId }}
                                type="default"
                                icon={<CreditCardOutlined />}
                            >
                                Subscriptions
                            </Link>
                        </>
                    }
                    primaryActionAddon={
                        <Button type="primary" form={formId} htmlType="submit" loading={saving}>
                            Save Changes
                        </Button>
                    }
                />

                {!data?.isEnabled && (
                    <Alert
                        message="Organization Inactive"
                        description="This organization is currently inactive. Users cannot access the platform until it is activated."
                        type="warning"
                        showIcon
                        className="mb-6"
                    />
                )}

                <Tabs
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    items={[
                        {
                            key: 'basic',
                            icon: <BankOutlined />,
                            label: 'Basic Information',
                            children: (
                                <Card className="shadow-xs rounded-tl-none border-t-0">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <FormItem
                                            label="Organization Name"
                                            required
                                            name="name"
                                            rules={[
                                                { required: true, message: 'Please enter the organization name' },
                                                { min: 2, message: 'Name must be at least 2 characters' },
                                            ]}
                                            tooltip={{
                                                title: 'The display name of the organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input placeholder="Enter organization name" />
                                        </FormItem>
                                        <FormItem
                                            name="emailId"
                                            label="Organization Email ID"
                                            tooltip={{
                                                title: 'The email local name specific to this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                            help="The organization will be able to send emails to this email ID, e.g. contoso@ai-inbox.curium.app"
                                        >
                                            <Input placeholder="Enter email ID, e.g. contoso" />
                                        </FormItem>
                                        <FormItem
                                            label="Stripe ID"
                                            name="stripeId"
                                            tooltip={{
                                                title: 'The Stripe identifier for billing purposes',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input placeholder="Enter Stripe ID" />
                                        </FormItem>
                                        <FormItem
                                            label="Organization is active"
                                            name="isEnabled"
                                            valuePropName="checked"
                                            tooltip={{
                                                title: 'Disable to temporarily suspend organization access',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Checkbox />
                                        </FormItem>
                                        <FormItem
                                            label="Max Allowed Users"
                                            name="maxAllowedUsers"
                                            tooltip={{
                                                title: 'Maximum number of users allowed in the organization (set to -1 for unlimited)',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                            help="Enter -1 for unlimited users"
                                        >
                                            <Input type="number" placeholder="Enter max users (-1 for unlimited)" />
                                        </FormItem>
                                        <FormItem
                                            label="Default Email Address"
                                            name="defaultEmailAddress"
                                            tooltip={{
                                                title: 'The default email address used for sending emails',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input placeholder="Enter default email address" type="email" />
                                        </FormItem>
                                        <FormItem
                                            label="Reply-To Email Address"
                                            name="replyToEmailAddress"
                                            tooltip={{
                                                title: 'The email address that will receive replies',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input placeholder="Enter reply-to email address" type="email" />
                                        </FormItem>
                                        <FormItem
                                            label="Organization is initialized"
                                            name="isInitialized"
                                            valuePropName="checked"
                                            tooltip={{
                                                title: 'Indicates if the organization has completed initial setup',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Checkbox />
                                        </FormItem>
                                        <FormItem
                                            label="Last Login"
                                            tooltip={{
                                                title: 'The last time any user logged into this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input
                                                value={
                                                    data?.lastLoginAt
                                                        ? new Date(data.lastLoginAt).toLocaleString()
                                                        : 'Never'
                                                }
                                                readOnly
                                                className="bg-gray-50"
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Created At"
                                            tooltip={{
                                                title: 'When this organization was created',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input
                                                value={
                                                    data?.createdAt
                                                        ? new Date(data.createdAt).toLocaleString()
                                                        : 'Unknown'
                                                }
                                                readOnly
                                                className="bg-gray-50"
                                            />
                                        </FormItem>
                                        <FormItem
                                            name="restrictions"
                                            label="Restrictions"
                                            tooltip={{
                                                title: 'Special restrictions applied to this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select restrictions"
                                                allowClear
                                                className="w-full"
                                                options={[
                                                    {
                                                        label: (
                                                            <span className="flex items-center gap-2">
                                                                <LockOutlined />
                                                                Payment Required for Login
                                                            </span>
                                                        ),
                                                        value: 'payment_required_for_login',
                                                    },
                                                ]}
                                            />
                                        </FormItem>
                                    </div>
                                </Card>
                            ),
                        },
                        {
                            key: 'features',
                            icon: <AppstoreOutlined />,
                            label: 'Features',
                            children: (
                                <Card className="shadow-xs rounded-tl-none border-t-0">
                                    <FormItem
                                        name="enabledModules"
                                        label="Enabled Modules"
                                        tooltip={{
                                            title: 'Select the modules that should be enabled for this organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Checkbox.Group
                                            options={moduleOptions}
                                            className="grid grid-cols-1 gap-4 md:grid-cols-2"
                                        />
                                    </FormItem>
                                </Card>
                            ),
                        },
                        {
                            key: 'authentication',
                            icon: <LockOutlined />,
                            label: 'Authentication',
                            children: (
                                <Card className="shadow-xs rounded-tl-none border-t-0">
                                    <FormItem
                                        name="enabledAuth0Connections"
                                        label="Authentication Methods"
                                        tooltip={{
                                            title: 'Select the allowed authentication methods for this organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Select authentication methods"
                                            allowClear
                                            className="w-full"
                                            options={[
                                                {
                                                    label: (
                                                        <span className="flex items-center gap-2">
                                                            <LockOutlined />
                                                            {AUTH0_CONNECTIONS.DATABASE.label}
                                                        </span>
                                                    ),
                                                    value: AUTH0_CONNECTIONS.DATABASE.name,
                                                },
                                                {
                                                    label: (
                                                        <span className="flex items-center gap-2">
                                                            <LockOutlined />
                                                            {AUTH0_CONNECTIONS.AZURE_AD.label}
                                                        </span>
                                                    ),
                                                    value: AUTH0_CONNECTIONS.AZURE_AD.name,
                                                },
                                            ]}
                                        />
                                    </FormItem>
                                </Card>
                            ),
                        },
                    ]}
                />
            </Form>
        </PageContent>
    );
}
