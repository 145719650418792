import { DownOutlined } from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { Link } from '@client/components/Common/Link';
import { message } from '@client/components/Common/message';
import { PageContent } from '@client/components/Layout/PageContent';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useDeleteConfirmation } from '@client/hooks/UI/useDeleteConfirmation';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUser } from '@client/hooks/User/useUser';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Dropdown, Space, Table, Tag } from 'antd';
import { useState } from 'react';
import invariant from 'tiny-invariant';

export const Route = createFileRoute('/admin-console/organizations')({
    staticData: {
        metadata: { title: 'Organizations' },
        ui: { title: 'Organizations', subtitle: 'Organizations can be configured here.' },
    },
    component: AdminPageOrganizations,
});

type QueryVariables = RouterInputs['admin']['org']['listOrgs'];
type Org = RouterOutputs['admin']['org']['listOrgs']['rows'][number];

function AdminPageOrganizations() {
    const trpcClient = useTrpcClient();
    const currentUser = useUser();
    const navigate = useNavigate();

    const [orgIdToDelete, setOrgIdToDelete] = useState<bigint | undefined>();
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
        orderBy: { id: 'desc' },
    });

    const { data: orgs, isLoading, refetch } = trpc.admin.org.listOrgs.useQuery(queryVariables);

    const {
        showConfirmationModal,
        hideConfirmationModal,
        deleteConfirmationModal: DeleteConfirmationModal,
    } = useDeleteConfirmation({
        entityDisplayName: 'organization',
        async onConfirm(confirmationText) {
            try {
                invariant(orgIdToDelete, 'orgIdToDelete is required');
                message.loading('Deleting organization...');
                await trpcClient.admin.org.deleteOrg.mutate({
                    orgId: BigInt(orgIdToDelete),
                    orgName: confirmationText,
                });
                message.success('Organization deleted successfully');
                refetch();
            } catch (error) {
                errorMessage.show(error);
            } finally {
                hideConfirmationModal();
            }
        },
    });

    const { tableProps } = useAntdTable<Org>({
        rowKey: 'id',
        data: { rows: orgs?.rows || [], total: orgs?.total || 0, loading: isLoading },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
                render: (value) => BigInt(value).toString(),
            },
            { dataIndex: 'name', title: 'Name', sorter: true, className: 'whitespace-nowrap' },
            {
                dataIndex: 'isEnabled',
                title: 'Is enabled?',
                sorter: true,
                render: (value) => (value ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>),
            },
            { dataIndex: 'emailId', title: 'Email ID', render: (value) => (value ? <Tag>{value}</Tag> : '') },
            {
                title: 'Modules',
                className: 'whitespace-nowrap',
                render: (_, row) => (
                    <>
                        {row.enabledModules.map((module) => (
                            <Tag key={module} color="blue">
                                {module}
                            </Tag>
                        ))}
                    </>
                ),
            },
            {
                dataIndex: 'lastLoginAt',
                title: 'Last login',
                sorter: true,
                className: 'whitespace-nowrap',
                render: (_, record) => (record.lastLoginAt ? record.lastLoginAt.toLocaleString() : 'Never'),
            },
            {
                dataIndex: 'markedForDeletion',
                title: 'Marked for deletion?',
                sorter: true,
                render: (value) => (value ? <Tag color="error">Yes</Tag> : <Tag color="success">No</Tag>),
            },
            {
                dataIndex: 'createdAt',
                title: 'Created at',
                sorter: true,
                className: 'whitespace-nowrap',
                render: (value) => value.toLocaleString(),
            },
            {
                fixed: 'right',
                align: 'center',
                render: (_, record) => (
                    <Space>
                        <Link size="small" to="/admin-console/organizations/$orgId" params={{ orgId: record.id }}>
                            Edit
                        </Link>
                        <Dropdown
                            trigger={['click']}
                            menu={{
                                items: [
                                    {
                                        key: 'users',
                                        label: 'Manage users',
                                        onClick: () =>
                                            navigate({
                                                to: '/admin-console/organizations/$orgId/users',
                                                params: { orgId: record.id },
                                            }),
                                    },
                                    {
                                        key: 'wallet',
                                        label: 'Manage wallet',
                                        onClick: () =>
                                            navigate({
                                                to: '/admin-console/organizations/$orgId/wallet',
                                                params: { orgId: record.id },
                                            }),
                                    },
                                    {
                                        key: 'subscriptions',
                                        label: 'Subscriptions',
                                        onClick: () =>
                                            navigate({
                                                to: '/admin-console/organizations/$orgId/subscriptions',
                                                params: { orgId: record.id },
                                            }),
                                    },
                                ],
                            }}
                        >
                            <Button size="small">
                                Shortcuts <DownOutlined />
                            </Button>
                        </Dropdown>
                        {(currentUser.isPrivilegedSuperAdmin || import.meta.env.VITE_ENV !== 'production') && (
                            <Button
                                size="small"
                                danger
                                onClick={() => {
                                    setOrgIdToDelete(record.id);
                                    showConfirmationModal(record.name);
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </Space>
                ),
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({ ...prev, ...options, orderBy: options?.orderBy ?? prev.orderBy }));
        },
        paginationConfig: { defaultPageSize: 10, showSizeChanger: true },
    });

    return (
        <PageContent>
            {DeleteConfirmationModal}

            <PageLayout.Header
                features={[
                    {
                        feature: 'search',
                        onSearch(value) {
                            setQueryVariables({
                                ...queryVariables,
                                where: value ? { name: { contains: value, mode: 'insensitive' } } : undefined,
                            });
                        },
                    },
                ]}
            />

            <Card>
                <Table {...tableProps} size="small" />
            </Card>
        </PageContent>
    );
}
