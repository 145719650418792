import { LOCAL_STORAGE_KEY } from '@shared/definitions/localStorage';
import { uuid } from '@shared/utils/general';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

export async function getLaunchDarklyProvider() {
    let userKey = uuid();

    try {
        userKey = localStorage.getItem(LOCAL_STORAGE_KEY.LD_ANONYMOUS_USER_KEY) || uuid();
        localStorage.setItem(LOCAL_STORAGE_KEY.LD_ANONYMOUS_USER_KEY, userKey);
    } catch (e) {
        console.error('Error setting LaunchDarkly anonymous user key', e);
    }

    return await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID!,
        context: {
            kind: 'user',
            anonymous: true,
            key: userKey,
            type: /Android|iPhone|iPad/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            browserAgent: navigator.userAgent,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
        },
    });
}
