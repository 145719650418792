import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useCreateSignalStore } from '@client/context/signal';
import { useOrg } from '@client/hooks/Org/useOrg';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useCustomDocumentTitle } from '@client/hooks/useCustomDocumentTitle';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { useZodState } from '@client/hooks/useZodState';
import { trpc } from '@client/trpc/client';
import { RecordNotFoundError } from '@client/utils/error';
import { getUnreadNotificationCountSchema } from '@server/schemas/notification';
import { CLAIM_STATUSES } from '@shared/definitions/claim';
import { CLAIM_DETAILS_SECTIONS } from '@shared/navigation/navRoutes';
import { ThirdPartyContactConfig } from '@shared/types/claim';
import { createFileRoute, Outlet, useLocation } from '@tanstack/react-router';
import { Badge, Skeleton, Tabs } from 'antd';
import { useState } from 'react';
import { Actions } from './Actions';
import { ClaimContext } from './ClaimContext';
import { ClaimSignalContext, ClaimSignals } from './ClaimSignalContext';
import { Header } from './Header';
import { claimFormStore } from './store';

export const Route = createFileRoute('/console/$orgId/claims/details/$id')({
    staticData: {
        breadcrumb: {
            title: 'Claim details',
        },
    },
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: ClaimDetails,
});

function ClaimDetails() {
    const orgId = useOrgId();
    const currentOrg = useOrg();
    const { id } = Route.useParams();
    const signalsCtx = useCreateSignalStore<ClaimSignals>();
    const { pathname } = useLocation();
    const { trpcUtils } = useTrpc();
    const { mutateAsync } = trpc.claim.updateClaim.useMutation();
    const navigate = useNavigate();

    const [productTypeDataTabName, setProductTypeDataTabName] = useState<string | undefined>();
    const [thirdPartyContacts, setThirdPartyContacts] = useState<ThirdPartyContactConfig>([]);
    const [input] = useZodState(getUnreadNotificationCountSchema, {
        where: {
            isActive: true,
            CommLog: {
                claimId: id,
            },
        },
    });

    const { data: unreadNotificationCount } = trpc.notification.getUnreadNotificationCount.useQuery(input, {
        refetchInterval: 1000 * 60 * 5,
    });

    const { formData: claim } = claimFormStore.useInit({
        entity: 'claim',
        loadDependencies: [id],

        async onSave(_, changedValues) {
            try {
                message.loading('Saving...');

                await mutateAsync({
                    id,
                    set: changedValues,
                });

                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad({ setReadonly }) {
            try {
                const data = await trpcUtils.claim.getClaim.fetch({ id });

                setReadonly(
                    [CLAIM_STATUSES.CLOSED, CLAIM_STATUSES.CLOSED_IN_RECOVERY_ONLY].includes(data.status as any),
                );

                if (data.productTypeId) {
                    const productType = await trpcUtils.productType.getProductType.fetch({
                        id: data.productTypeId,
                        orgId,
                    });

                    if (productType.definition) {
                        setProductTypeDataTabName(productType.definition.claimTabLabel);
                    }

                    setThirdPartyContacts(productType.thirdPartyContactTypes || []);
                }
                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The claim you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({
                    to: '/console/$orgId/claims',
                    params: { orgId },
                });
            } else {
                errorMessage.show(error);
            }
        },
    });

    useCustomDocumentTitle(`Claim - ${claim?.generatedId || id} - Curium`);

    const isProductTypeDataExists = Object.values(claim?.productTypeData || {}).length;

    return claim && id === claim.id ? (
        <ClaimContext.Provider
            value={{
                thirdPartyContactConfig: thirdPartyContacts,
            }}
        >
            <ClaimSignalContext.Provider value={signalsCtx}>
                <Header />
                <Actions />
                <Tabs
                    destroyInactiveTabPane
                    type="line"
                    activeKey={pathname.split('/')[6]}
                    onTabClick={(key) =>
                        navigate({
                            to: `/console/$orgId/claims/details/$id/${key}`,
                            params: {
                                orgId,
                                id,
                            },
                        })
                    }
                    items={[
                        {
                            key: CLAIM_DETAILS_SECTIONS.MANAGE,
                            label: 'Manage claim',
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.POLICY_INFO,
                            label: 'Policy info',
                            children: <Outlet />,
                        },
                        ...(isProductTypeDataExists
                            ? [
                                  {
                                      key: CLAIM_DETAILS_SECTIONS.PRODUCT_TYPE_DATA,
                                      label: productTypeDataTabName || 'Product Type Data',
                                      children: <Outlet />,
                                  },
                              ]
                            : []),
                        {
                            key: CLAIM_DETAILS_SECTIONS.COMMS,
                            label: (
                                <Badge count={unreadNotificationCount?.count || 0} size="small" offset={[5, -5]}>
                                    Communication
                                </Badge>
                            ),
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.CONTACTS,
                            label: 'Contacts',
                            children: <Outlet />,
                        },
                        ...(currentOrg.enabledModules.includes('billing')
                            ? [
                                  {
                                      key: CLAIM_DETAILS_SECTIONS.EXPENSES,
                                      label: 'Expenses & Billing',
                                      children: <Outlet />,
                                  },
                              ]
                            : []),
                        {
                            key: CLAIM_DETAILS_SECTIONS.FINANCIALS,
                            label: 'Financials',
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.LIBRARY,
                            label: 'Library',
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.HISTORY,
                            label: 'History',
                            children: <Outlet />,
                        },
                    ]}
                />
            </ClaimSignalContext.Provider>
        </ClaimContext.Provider>
    ) : (
        <Skeleton active />
    );
}
