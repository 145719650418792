import { PolicyHolder } from './PolicyHolder';
import { Broker } from './Broker';
import { Insurer } from './Insurer';
import { Suppliers } from './Suppliers';
import { ThirdPartyContacts } from './ThirdPartyContacts';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/contacts')({
    component: Contacts,
});

function Contacts() {
    return (
        <>
            <PolicyHolder />
            <Broker />
            <Insurer />
            <Suppliers />
            <ThirdPartyContacts />
        </>
    );
}
