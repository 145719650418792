import { UserAddOutlined } from '@ant-design/icons';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { USER_SYSTEM_USAGES, UserSystemUsage } from '@shared/definitions/user';
import { createFileRoute } from '@tanstack/react-router';
import { Avatar, Button, Card, Checkbox, Input, Space, Table, Tag, Typography } from 'antd';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { EditUser, EditUserAction } from './EditUser';
import { InviteUser } from './InviteUser';

export const Route = createFileRoute('/console/$orgId/configuration/users')({
    staticData: {
        ui: {
            title: 'Team management',
            subtitle: 'Manage your team members, assign roles and permissions, and invite new users',
        },
        breadcrumb: { title: 'Team management' },
    },
    component: ConfigurationUsers,
});

type User = RouterOutputs['user']['listUsers']['rows'][number];

type QueryVariables = RouterInputs['user']['listUsers'];

const DEFAULT_FILTER = {
    AND: [{ userId: { gt: 1 } }, { User: { isSupportAccount: false } }],
} satisfies QueryVariables['where'];

function ConfigurationUsers() {
    const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false);
    const [userAction, setUserAction] = useState<EditUserAction>(['none']);
    const { formatDate } = useFormatter();

    const [queryVariables, setQueryVariables] = useState<RouterInputs['user']['listUsers']>({
        where: DEFAULT_FILTER,
        orderBy: [{ firstName: 'asc' }],
        limit: 10,
        offset: 0,
    });

    const { data, isLoading, refetch } = trpc.user.listUsers.useQuery({
        where: queryVariables.where,
        orderBy: queryVariables.orderBy,
        limit: queryVariables.limit,
        offset: queryVariables.offset,
    });

    const { tableProps } = useAntdTable<User>({
        rowKey: 'userId',
        data: { rows: data?.rows, loading: isLoading, total: data?.total },
        columns: [
            {
                dataIndex: 'userId',
                title: 'ID',
                sorter: true,
                align: 'center',
                fixed: 'left',
                render: (value) => <span>{Number(value)}</span>,
            },
            { dataIndex: 'firstName', title: 'First name', sorter: true, fixed: 'left' },
            { title: 'Last name', dataIndex: 'lastName', sorter: true, fixed: 'left' },
            { title: 'Position', dataIndex: 'position', sorter: true },
            {
                dataIndex: 'User.email',
                title: 'E-mail',
                sorter: true,
                render: (_, row) => (
                    <div className="flex items-center">
                        <Avatar src={row.photoUrl} className="mr-2" size={25} />
                        <Typography.Link href={`mailto:${row.email}`} copyable>
                            {row.email}
                        </Typography.Link>
                    </div>
                ),
            },
            {
                dataIndex: 'disabled',
                title: 'Disabled',
                sorter: true,
                align: 'center',
                render: (value) => <Checkbox checked={!!value} />,
            },
            {
                dataIndex: 'canViewUnallocatedClaims',
                title: 'View unallocated claims',
                sorter: true,
                align: 'center',
                render: (value) => <Checkbox checked={!!value} />,
            },
            {
                dataIndex: 'systemUsage',
                title: 'Primary use of the system',
                sorter: true,
                render: (value) => USER_SYSTEM_USAGES[value as UserSystemUsage] || value,
            },
            {
                dataIndex: 'roles',
                title: 'Roles',
                align: 'center',
                render: (value) => value?.map((item: string) => <Tag key={item}>{item}</Tag>),
            },
            {
                title: 'Handling parties',
                render: (_, { HandlingParties }) => HandlingParties.map(({ name }) => name).join(', '),
            },
            { title: 'Last login', dataIndex: 'lastLoginAt', render: (value) => formatDate(value, 'date-time') },
            {
                dataIndex: 'createdAt',
                title: 'Created',
                sorter: true,
                render: (value) => formatDate(value, 'date-time'),
            },
        ],
        onQueryVariableChange: (options) => {
            setQueryVariables({
                ...queryVariables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy,
            });
        },
        paginationConfig: { defaultPageSize: 10, showSizeChanger: true },
    });

    const onSearch = (searchValue: string) => {
        const where: QueryVariables['where'] = { OR: [] } as const;
        invariant(where.OR, 'where.OR is not defined');
        if (searchValue) {
            where.OR.push({ firstName: { contains: `%${searchValue}%`, mode: 'insensitive' } });
            where.OR.push({ lastName: { contains: `%${searchValue}%`, mode: 'insensitive' } });
            where.OR.push({ User: { email: { contains: `%${searchValue}%`, mode: 'insensitive' } } });
        }

        setQueryVariables({
            ...queryVariables,
            where: { AND: [...DEFAULT_FILTER.AND, ...(where.OR.length ? [where] : [])] },
        });
    };

    return (
        <>
            <Space className="mb-4 flex items-center justify-between">
                <Input.Search
                    placeholder="Search users"
                    allowClear
                    enterButton="Search"
                    onSearch={onSearch}
                    className="w-96"
                />
                <Button icon={<UserAddOutlined />} onClick={() => setOpenInviteUserDialog(true)}>
                    Invite user
                </Button>
            </Space>
            <InviteUser
                open={openInviteUserDialog}
                onClose={() => {
                    setOpenInviteUserDialog(false);
                    void refetch();
                }}
            />
            <EditUser
                action={userAction}
                onClose={() => {
                    setUserAction(['none']);
                    void refetch();
                }}
            />

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ userId }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setUserAction(['edit', userId]),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
