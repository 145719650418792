import { InputNumber, InputNumberProps } from 'antd';
import { Decimal } from 'decimal.js';
import { forwardRef } from 'react';

export type NumberInputProps = Pick<
    InputNumberProps,
    'controls' | 'prefix' | 'className' | 'addonAfter' | 'min' | 'precision' | 'step' | 'placeholder'
> &
    (
        | {
              valueType?: 'number';
              value?: number | null;
              onChange?: (value: number | null) => void;
              maxFractionDigits?: number;
          }
        | {
              valueType: 'bigint';
              value?: bigint | null;
              onChange?: (value: bigint | null) => void;
              maxFractionDigits: never;
          }
        | {
              valueType: 'decimal';
              value?: Decimal | null;
              onChange?: (value: Decimal | null) => void;
              maxFractionDigits?: number;
          }
    ) & {
        roundingMode?: 'up' | 'down' | 'nearest' | 'none';
    };

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    (
        {
            value: valueProp,
            onChange,
            valueType = 'number',
            maxFractionDigits: maxFractionDigitsProp,
            precision,
            roundingMode,
            ...props
        },
        ref,
    ) => {
        function formatMaxFractionDigits(value: string | number | undefined) {
            const maxFractionDigits = valueType === 'bigint' ? 0 : maxFractionDigitsProp;
            const modifiedValue = value?.toString();

            if (maxFractionDigits === undefined || !modifiedValue) {
                return modifiedValue || '';
            }

            const [integer, fraction] = modifiedValue.split('.');
            return `${integer}${fraction && maxFractionDigits ? '.' + fraction.slice(0, maxFractionDigits) : ''}`;
        }

        function applyRounding(value: number): number {
            switch (roundingMode) {
                case 'up':
                    return Math.ceil(value);
                case 'down':
                    return Math.floor(value);
                case 'nearest':
                    return Math.round(value);
                case 'none':
                default:
                    return value;
            }
        }

        function handleBlur() {
            if (valueProp === null) {
                onChange?.(valueProp);
                return;
            }

            if (valueProp === undefined) {
                return;
            }

            const valueToFormat =
                typeof valueProp === 'bigint' || valueProp instanceof Decimal ? valueProp.toString() : valueProp;

            const formattedValue = formatMaxFractionDigits(valueToFormat);
            const roundedValue = applyRounding(+formattedValue);

            onChange?.(
                (valueType === 'decimal'
                    ? new Decimal(formattedValue)
                    : valueType === 'bigint'
                      ? BigInt(formattedValue)
                      : +roundedValue) as any,
            );
        }

        return (
            <InputNumber
                ref={ref}
                value={valueProp as any}
                onChange={
                    precision === undefined
                        ? (value) => {
                              if (value === null) {
                                  onChange?.(value);
                                  return;
                              }

                              const formattedValue = formatMaxFractionDigits(value);

                              onChange?.(
                                  (valueType === 'decimal'
                                      ? new Decimal(formattedValue)
                                      : valueType === 'bigint'
                                        ? BigInt(formattedValue)
                                        : +formattedValue) as any,
                              );
                          }
                        : onChange
                }
                onBlur={handleBlur}
                precision={precision}
                stringMode={valueType === 'decimal' || valueType === 'bigint'}
                {...props}
            />
        );
    },
);
