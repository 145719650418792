import { message } from '@client/components/Common/message';
import { VDatePicker } from '@client/components/Common/ViewForm/VDatePicker';
import { VReadOnly } from '@client/components/Common/ViewForm/VReadOnly';
import { TaskStatus } from '@client/components/Task/TaskStatus';
import { taskFormStore } from '@client/components/Task/TaskView/store';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { trpc } from '@client/trpc/client';
import { RecordNotFoundError } from '@client/utils/error';
import { debounce } from '@client/utils/general';
import { getTaskRelatedEntityTitle } from '@client/utils/task';
import { useParentMatches } from '@tanstack/react-router';
import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useState } from 'react';
import { errorMessage } from '../../Common/errorMessage';
// import { User } from '../../Common/User';
import { VUserSelector } from '../../Common/ViewForm/VUserSelector';
import { Task } from '../types';
import { RelatedEntityLink } from './RelatedEntityLink';
import { TaskComments } from './TaskComments';
// import { TasksStatuses } from './TasksStatuses';

export type TaskViewProps = {
    taskId: bigint;
    onUpdate?: (data: Task & { wasCompleted: boolean }) => void;
    // hideRelatedEntityTooltip?: boolean;
};

export function TaskView({ taskId, onUpdate }: TaskViewProps) {
    const { trpcUtils } = useTrpc();
    const parentMatches = useParentMatches();
    const { formatDate, fDateShortDayJs } = useFormatter();
    const navigate = useNavigate();
    const { mutateAsync } = trpc.task.updateTask.useMutation();

    // const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const { formData: task, save } = taskFormStore.useInit({
        entity: 'task',
        loadDependencies: [taskId],

        async onSave(currentValues, changedValues, { suppressMessages }) {
            try {
                if (!suppressMessages) {
                    message.loading('Saving...');
                }

                await mutateAsync({
                    id: currentValues.id,
                    set: {
                        ...changedValues,
                        assignedToId: changedValues.assignedToId && BigInt(changedValues.assignedToId),
                    },
                });

                trpcUtils.task.invalidate();

                if (!suppressMessages) {
                    message.success('Saved.');
                }

                if (onUpdate && currentValues) {
                    onUpdate({
                        ...currentValues,
                        wasCompleted:
                            !!changedValues.isCompleted && currentValues.isCompleted !== changedValues.isCompleted,
                    });
                }

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad({ setReadonly }) {
            try {
                // setLoading(true);

                const data = await trpcUtils.task.getTask.fetch({ id: taskId });

                if (!data) {
                    throw new RecordNotFoundError();
                }

                if (data.isCompleted) {
                    setReadonly(true);
                }

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            } finally {
                // setLoading(false);
            }
        },

        async onError(error) {
            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The task you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({ href: parentMatches.at(-1)?.pathname });
                return;
            }

            errorMessage.show(error);
        },
    });

    const relatedEntityId = task?.relatedEntity ? task[`${task.relatedEntity}Id`] : null;

    const markAsDone = debounce(async () => {
        try {
            setSaving(true);

            message.loading('Saving...');

            const success = await save({ isCompleted: true }, { suppressMessages: true });

            if (!success) {
                return;
            }

            message.success('Task completed.');
        } finally {
            setSaving(false);
        }
    });

    // const taskStatuses = useMemo(
    //     () => (task?.assignedToId && !loading ? <TasksStatuses userId={task.assignedToId} /> : null),
    //     [task?.assignedToId, loading],
    // );

    if (taskId !== task?.id) {
        return <Skeleton active />;
    }

    return (
        <>
            {/* {showUserStatistic && (
                <div className="mb-10 h-56 rounded-md bg-zinc-100 p-6">
                    {!!task.assignedToId && (
                        <>
                            <User
                                userId={task.assignedToId}
                                photoUrl={task.AssignedTo?.photoUrl}
                                name={task.AssignedTo?.fullName}
                            />
                            {taskStatuses}
                        </>
                    )}
                </div>
            )} */}
            <TaskStatus size="large" data={task} />
            <Typography.Title level={3} className="pt-3 text-gray-600">
                {task.title}
            </Typography.Title>
            {task.description && (
                <Typography.Paragraph className="text-gray-600">{task.description}</Typography.Paragraph>
            )}
            <Row gutter={[16, 16]} className="mb-5">
                <Col span={12}>
                    <VDatePicker
                        label="Due"
                        field="dueDate"
                        formStore={taskFormStore}
                        formatValueFn={(value) => formatDate(value)}
                        format={fDateShortDayJs}
                        readonly={!!task.isSystemGenerated || !!task.isCompleted}
                    />
                </Col>
                {!!relatedEntityId && (
                    <Col span={12}>
                        <VReadOnly
                            label={getTaskRelatedEntityTitle(task)}
                            formStore={taskFormStore}
                            valueOverride={<RelatedEntityLink entity={task.relatedEntity} entityId={relatedEntityId} />}
                        />
                    </Col>
                )}
                <Col span={12}>
                    <VUserSelector
                        label="Assignee"
                        field="assignedToId"
                        formStore={taskFormStore}
                        handlingPartyIds={task.handlingPartyIds}
                        removeAnonymousAccount
                        removeSystemAccount
                        readonly={!!task.isCompleted}
                        helpMessage="Only users from assigned handling parties can be selected"
                    />
                </Col>
            </Row>
            {!task?.isCompleted && (
                <div className="flex justify-end">
                    <Button type="primary" onClick={markAsDone} loading={saving}>
                        Mark as done
                    </Button>
                </div>
            )}
            <Divider />
            <TaskComments taskId={taskId} />
        </>
    );
}
