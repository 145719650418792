import { VFieldLayout } from '@client/components/Common/ViewForm/VFieldLayout';
import { DefaultFormData } from '@client/stores/FormStore/types';
import { observer } from 'mobx-react-lite';
import { VInputProps } from '../InputWrapper';

type Props<TFormData extends DefaultFormData> = Pick<
    VInputProps<TFormData>,
    | 'label'
    | 'className'
    | 'layout'
    | 'icon'
    | 'valueOverride'
    | 'formStore'
    | 'formatValueFn'
    | 'disableTooltip'
    | 'customTooltip'
> &
    Partial<Pick<VInputProps<TFormData>, 'field'>>;

export const VReadOnly = observer(
    <TFormData extends DefaultFormData>({
        formatValueFn,
        valueOverride,
        field,
        formStore,
        disableTooltip = true,
        ...props
    }: Props<TFormData>) => {
        const formData = formStore.use.formData({ throwOnUndefined: false });
        const getFieldValue = formStore.use.getFieldValue();

        return (
            <VFieldLayout
                readonly
                disableTooltip={disableTooltip}
                value={
                    formatValueFn && field
                        ? formatValueFn(getFieldValue(field), formData!) || '-'
                        : valueOverride
                          ? valueOverride
                          : (field && getFieldValue(field)) || '-'
                }
                {...props}
            />
        );
    },
);
