import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { CollapsiblePanel } from '@client/components/Common/CollapsiblePanel';
import { message } from '@client/components/Common/message';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterInputs } from '@client/trpc/client';
import { sanitizeHtml } from '@client/utils/text';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Divider, Drawer, Form, Input, Row, Select, Switch, Tag } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { CONFIDENCE_MAP, ISSUE_TYPE_MAP } from '../config';

export const Route = createFileRoute('/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification')({
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: ReviewEmailClassification,
});

type FormValues = RouterInputs['emailClassification']['reviewEmailClassification']['set'];

const FormItem = Form.Item<FormValues>;
type EmailAddress = { name?: string; email: string };

function ReviewEmailClassification() {
    const { id } = Route.useParams();
    const orgId = useOrgId();
    const { trpc, trpcUtils } = useTrpc();
    const navigate = useNavigate();
    const { enableDebugInfo } = useFlags();
    const [open, setOpen] = useState(true);
    const [isEmailDetailsOpen, setIsEmailDetailsOpen] = useState(false);
    const [isDebugInfoOpen, setIsDebugInfoOpen] = useState(false);
    const { data, isPending } = trpc.emailClassification.getEmailClassification.useQuery({ id });
    const { mutate: updateEmailClassification, isPending: isSubmitting } =
        trpc.emailClassification.reviewEmailClassification.useMutation();
    const { data: availableTags } = trpc.emailClassification.loadTags.useQuery();

    const [form] = Form.useForm<FormValues>();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                issueType: data?.issueType || 'UNKNOWN',
                confidence: data?.confidence || 'LOW',
                isBreachOfRegulation: data?.isBreachOfRegulation || false,
                breachedRegulation: data?.breachedRegulation || '',
                justification: data?.justification || '',
                tags: data?.tags || [],
            });
        }
    }, [data]);

    async function handleSubmit(values: FormValues) {
        if (!data) {
            return;
        }

        message.loading('Reviewing email classification...');
        updateEmailClassification(
            {
                id: Number(data.id),
                set: {
                    issueType: values.issueType,
                    confidence: values.confidence,
                    isBreachOfRegulation: values.isBreachOfRegulation,
                    breachedRegulation: values.breachedRegulation,
                    justification: values.justification,
                    tags: values.tags,
                },
            },
            {
                onSuccess() {
                    message.success('Email classification reviewed successfully.');
                    trpcUtils.emailClassification.invalidate();

                    setOpen(false);
                },
            },
        );
    }

    return (
        <Drawer
            title="Review e-mail"
            open={open}
            onClose={() => setOpen(false)}
            afterOpenChange={(open) => {
                if (!open) {
                    navigate({
                        to: '/console/$orgId/tools/auto-detect/inbox',
                        params: { orgId },
                    });
                }
            }}
            width={1000}
            classNames={{ body: 'bg-body' }}
            footer={
                <>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="primary"
                        loading={isSubmitting}
                        htmlType="submit"
                        form={`review-email-classification-${id}`}
                    >
                        Submit Review
                    </Button>
                </>
            }
            loading={isPending}
        >
            {data && (
                <>
                    <Card
                        title={
                            <div
                                className="flex cursor-pointer items-center justify-between"
                                onClick={() => setIsEmailDetailsOpen(!isEmailDetailsOpen)}
                            >
                                <span>Email Details</span>
                                <Button
                                    type="text"
                                    icon={isEmailDetailsOpen ? <UpOutlined /> : <DownOutlined />}
                                    className="ml-2"
                                />
                            </div>
                        }
                        className="mb-6"
                        classNames={{ body: 'p-0', header: 'cursor-pointer' }}
                    >
                        <CollapsiblePanel open={isEmailDetailsOpen}>
                            <div className="px-8 py-6">
                                <div>
                                    {data.subject && <div className="mb-3 text-lg font-medium">{data.subject}</div>}
                                    <div className="space-y-1 text-sm">
                                        {data.from && (
                                            <div className="grid grid-cols-[60px_1fr] gap-2">
                                                <span className="text-gray-500">From:</span>
                                                <span>{formatEmailAddresses(data.from)}</span>
                                            </div>
                                        )}
                                        {data.to && (
                                            <div className="grid grid-cols-[60px_1fr] gap-2">
                                                <span className="text-gray-500">To:</span>
                                                <span>{formatEmailAddresses(data.to)}</span>
                                            </div>
                                        )}
                                        {data.cc && formatEmailAddresses(data.cc) && (
                                            <div className="grid grid-cols-[60px_1fr] gap-2">
                                                <span className="text-gray-500">Cc:</span>
                                                <span>{formatEmailAddresses(data.cc)}</span>
                                            </div>
                                        )}
                                        {data.bcc && formatEmailAddresses(data.bcc) && (
                                            <div className="grid grid-cols-[60px_1fr] gap-2">
                                                <span className="text-gray-500">Bcc:</span>
                                                <span>{formatEmailAddresses(data.bcc)}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Divider className="my-4" />

                                <div>
                                    <div
                                        className="prose max-w-none"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(data.bodyHtml) || data.bodyText,
                                        }}
                                    />
                                </div>
                            </div>
                        </CollapsiblePanel>
                    </Card>

                    <Card title="Review Classification">
                        <Form<FormValues>
                            form={form}
                            id={`review-email-classification-${id}`}
                            layout="vertical"
                            onFinish={handleSubmit}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <FormItem
                                        name="issueType"
                                        label="Issue Type"
                                        rules={[{ required: true, message: 'Please select an issue type' }]}
                                    >
                                        <Select
                                            size="large"
                                            options={Object.entries(ISSUE_TYPE_MAP).map(
                                                ([value, { label, color }]) => ({
                                                    label: (
                                                        <Tag color={color} className="px-[8px] py-[2px] text-base">
                                                            {label}
                                                        </Tag>
                                                    ),
                                                    value,
                                                }),
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        name="confidence"
                                        label="Certainty"
                                        rules={[{ required: true, message: 'Please enter a certainty' }]}
                                    >
                                        <Select
                                            size="large"
                                            options={Object.entries(CONFIDENCE_MAP).map(
                                                ([value, { label, color }]) => ({
                                                    label: (
                                                        <Tag color={color} className="px-[8px] py-[2px] text-base">
                                                            {label}
                                                        </Tag>
                                                    ),
                                                    value,
                                                }),
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            <FormItem
                                name="isBreachOfRegulation"
                                label="Is Breach of Regulation?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </FormItem>

                            <FormItem name="breachedRegulation" label="Breached Regulation">
                                <Input.TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
                            </FormItem>

                            <FormItem name="justification" label="Justification">
                                <Input.TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
                            </FormItem>

                            <FormItem name="tags" label="Tags">
                                <Select
                                    mode="tags"
                                    size="large"
                                    placeholder="Select or create tags"
                                    style={{ width: '100%' }}
                                    options={(availableTags || []).map((tag: string) => ({ label: tag, value: tag }))}
                                    tokenSeparators={[',']}
                                />
                            </FormItem>

                            {data?.aiResponse && (
                                <>
                                    <Divider />
                                    <div className="mb-4 text-lg font-medium">AI Analysis</div>

                                    <div className="space-y-4 rounded-md bg-gray-50 p-4">
                                        {data.aiResponse.obligation_number && (
                                            <div>
                                                <div className="text-sm font-medium text-gray-500">
                                                    Obligation Number
                                                </div>
                                                <div className="mt-1">{data.aiResponse.obligation_number}</div>
                                            </div>
                                        )}
                                        {data.aiResponse.legislation_title && (
                                            <div>
                                                <div className="text-sm font-medium text-gray-500">
                                                    Legislation Title
                                                </div>
                                                <div className="mt-1">{data.aiResponse.legislation_title}</div>
                                            </div>
                                        )}
                                        {data.aiResponse.legislation && (
                                            <div>
                                                <div className="text-sm font-medium text-gray-500">Legislation</div>
                                                <div className="mt-1">{data.aiResponse.legislation}</div>
                                            </div>
                                        )}
                                        {data.aiResponse.section && (
                                            <div>
                                                <div className="text-sm font-medium text-gray-500">
                                                    Sub-obligation / Section / Paragraph
                                                </div>
                                                <div className="mt-1">{data.aiResponse.section}</div>
                                            </div>
                                        )}
                                        {data.aiResponse.regulation && (
                                            <div>
                                                <div className="text-sm font-medium text-gray-500">Regulation</div>
                                                <div className="mt-1">{data.aiResponse.regulation}</div>
                                            </div>
                                        )}
                                        {data.aiResponse.recommended_next_actions && (
                                            <div>
                                                <div className="text-sm font-medium text-gray-500">
                                                    Recommended Next Actions
                                                </div>
                                                <div className="mt-1">{data.aiResponse.recommended_next_actions}</div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </Form>
                    </Card>

                    {enableDebugInfo && (
                        <Card
                            title={
                                <div
                                    className="flex cursor-pointer items-center justify-between"
                                    onClick={() => setIsDebugInfoOpen(!isDebugInfoOpen)}
                                >
                                    <span>Debug Info</span>
                                    <Button
                                        type="text"
                                        icon={isDebugInfoOpen ? <UpOutlined /> : <DownOutlined />}
                                        className="ml-2"
                                    />
                                </div>
                            }
                            className="mt-6"
                            classNames={{ body: 'p-0', header: 'cursor-pointer' }}
                        >
                            <CollapsiblePanel open={isDebugInfoOpen}>
                                <div className="px-8 py-6">
                                    <pre>{JSON.stringify(data, null, 2)}</pre>
                                </div>
                            </CollapsiblePanel>
                        </Card>
                    )}
                </>
            )}
        </Drawer>
    );
}

function formatEmailAddress(email: EmailAddress) {
    return email.name ? `${email.name} <${email.email}>` : email.email;
}

function formatEmailAddresses(emails: EmailAddress | EmailAddress[] | null | undefined) {
    if (!emails) {
        return '';
    }

    const emailArray = Array.isArray(emails) ? emails : [emails];
    return emailArray.map(formatEmailAddress).join(', ');
}
