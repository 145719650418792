import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Drawer, Form, Input, Select, Space } from 'antd';
// import { CallButton } from '@client/components/Common/CallButton';
import React, { useEffect, useMemo } from 'react';
import {
    Contact,
    ContactType,
    CORE_CONTACT_TYPE,
    PREFERRED_CONTACT_METHOD,
    PreferredContactMethod,
    THIRD_PARTY_SYSTEM_CONTACT_TYPE,
    ThirdPartyContactType,
} from '@shared/types/contact';
import { useSafePath } from '@client/hooks/useSafePath';
import { DatePickerOld } from '@client/components/Common/DatePickerOld';
import { uuid } from '@shared/utils/general';
import { useFormatter } from '@client/hooks/useFormatter';
import { PhoneNumberInput } from '@client/components/Common/PhoneNumberInput';
import { validatePhoneNumber } from '@client/utils/phone';

export type ContactEditAction = 'add' | 'edit' | 'none';

type Props<T extends Contact = Contact> = {
    action: ContactEditAction;
    data: T | undefined;
    /** If contactType is provided, this will be used as the type field */
    contactType: ContactType;
    onChange: (data: T) => void;
    onClose: () => void;
    contactTypeTitle?: string;
    /** If contact type is third_party, this will be used to populate the select control */
    thirdPartyContactTypes?: string[];
};

const PRIMARY_CONTACT_BLACKLIST: ContactType[] = ['supplier', 'insurer'] as const;

export const ContactEdit = observer(
    <T extends Contact = Contact>({
        action,
        data,
        onChange,
        onClose,
        contactType,
        contactTypeTitle,
        thirdPartyContactTypes,
    }: Props<T>) => {
        const { fDateShortDayJs } = useFormatter();

        const [form] = Form.useForm<Contact>();
        const safePath = useSafePath<Contact>();
        const type = Form.useWatch<Contact['type']>(safePath('type'), form);
        const typeName = Form.useWatch<ThirdPartyContactType>(safePath('typeName'), form);
        // const phoneNumber = Form.useWatch<Contact['phone']>(safePath('phone'), form);

        const isOpen = useMemo(() => action === 'add' || action === 'edit', [action]);

        useEffect(() => {
            if (isOpen) {
                form.resetFields();
                if (action === 'edit') {
                    form.setFieldsValue(data!);
                } else {
                    form.setFieldsValue({
                        id: uuid(),
                        isPrimaryForType: false,
                        type: contactType as ContactType,
                    });
                }
            }
        }, [isOpen, action, data]);

        const isDriver = type === 'third_party' && typeName === 'driver';

        const onFinish = (values: Contact) => {
            const isSystemType =
                values.type === 'third_party' && THIRD_PARTY_SYSTEM_CONTACT_TYPE.includes(values.typeName);
            onChange({ ...values, isSystemType: isSystemType } as T);
            onClose();
        };

        return (
            <Drawer
                open={isOpen}
                title={action === 'edit' ? 'Edit contact' : 'Add contact'}
                width="500px"
                destroyOnClose
                onClose={onClose}
                footer={
                    <Space className="flex w-full justify-end">
                        <Button block onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" form="editContact" block>
                            Save
                        </Button>
                    </Space>
                }
                maskClosable={false}
            >
                <Form<Contact> form={form} onFinish={onFinish} layout="vertical" name="editContact">
                    <Form.Item name={safePath('id')} hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name={safePath('type')} hidden>
                        <Input />
                    </Form.Item>
                    {contactType === 'third_party' && thirdPartyContactTypes && (
                        <Form.Item label="Type" name={safePath('typeName')} rules={[{ required: true }]} required>
                            <Select options={thirdPartyContactTypes.map((item) => ({ value: item }))} />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="First Name"
                        name={safePath('firstName')}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        required
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Last name" name={safePath('lastName')} rules={[{ required: true }]} required>
                        <Input />
                    </Form.Item>
                    {type === 'supplier' && (
                        <Form.Item label="Position" name={safePath('position')}>
                            <Input />
                        </Form.Item>
                    )}
                    {type === 'broker' && (
                        <Form.Item label="Branch" name={safePath('branch')}>
                            <Input />
                        </Form.Item>
                    )}
                    <Form.Item label="E-mail" name={safePath('email')} rules={[{ type: 'email' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        name={safePath('phone')}
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!validatePhoneNumber(value)) throw new Error('Invalid phone number');
                                },
                            },
                        ]}
                    >
                        <PhoneNumberInput
                        // addonAfter={<CallButton phoneNumber={phoneNumber} />}
                        />
                    </Form.Item>
                    {(type === 'policy_holder' || isDriver) && (
                        <>
                            <Form.Item label="Date of birth" name={safePath('dob')}>
                                <DatePickerOld className="w-full" format={fDateShortDayJs} />
                            </Form.Item>
                            <Form.Item label="Address line 1" name={safePath('addressLine1')}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Address line 2" name={safePath('addressLine2')}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Suburb" name={safePath('addressSuburb')}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="State" name={safePath('addressState')}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Postcode" name={safePath('addressPostcode')}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                    {isDriver && (
                        <>
                            <Form.Item label="Driver's licence number" name={safePath('licenseNo')}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Licence expiry" name={safePath('licenseExpiry')}>
                                <DatePickerOld className="w-full" format={fDateShortDayJs} />
                            </Form.Item>
                        </>
                    )}
                    {type && !CORE_CONTACT_TYPE.includes(type) && (
                        <Form.Item label="Description" name={safePath('description')}>
                            <Input />
                        </Form.Item>
                    )}
                    {!!type && (
                        <Form.Item
                            label={`Is primary contact for ${contactTypeTitle || type}?`}
                            name={safePath('isPrimaryForType')}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    )}
                    {!PRIMARY_CONTACT_BLACKLIST.includes(type) && (
                        <Form.Item label="Is main claim contact?" name={safePath('isPrimary')} valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                    )}
                    {type === 'broker' && (
                        <Form.Item
                            label="CC into all claim communications"
                            name={safePath('ccIntoAllClaimCommunications')}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    )}
                    {type === 'policy_holder' && (
                        <Form.Item name={safePath('preferredContactMethod')} label="Preferred contact method">
                            <Select
                                options={Object.keys(PREFERRED_CONTACT_METHOD).map((key) => ({
                                    label: PREFERRED_CONTACT_METHOD[key as PreferredContactMethod],
                                    value: key,
                                }))}
                            />
                        </Form.Item>
                    )}
                    <Form.Item label="Contact notes" name={safePath('note')}>
                        <Input.TextArea autoSize style={{ minHeight: 100 }} />
                    </Form.Item>
                </Form>
            </Drawer>
        );
    },
);
