import { Link } from '@client/components/Common/Link';
import { errorNotification } from '@client/components/Common/Notification/errorNotification';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useTrpc } from '@client/hooks/useTrpc';
import { TaskRelatedEntity } from '@shared/definitions/task';
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

export function RelatedEntityLink({
    entity,
    entityId,
    labelOverride,
}: {
    entity: TaskRelatedEntity | null;
    entityId: bigint;
    labelOverride?: string | null;
}) {
    const orgId = useOrgId();
    const { trpcUtils } = useTrpc();

    const [label, setLabel] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!entity) {
            setLabel(null);
            return;
        }

        async function asyncWrapper() {
            try {
                setLoading(true);

                if (entity === 'obligation') {
                    const obligation = await trpcUtils.obligation.getObligation.fetch({ id: entityId });
                    setLabel(`#${obligation.order}`);
                    return;
                }

                if (entity === 'risk') {
                    const risk = await trpcUtils.risk.getRisk.fetch({ id: entityId });
                    const prefix = risk.LevelOneCategory.riskNumberPrefix;
                    setLabel(prefix ? `${prefix}-${risk.id}` : `#${risk.id}`);
                    return;
                }

                setLabel(labelOverride || `#${entityId.toString().padStart(5, '0')}`);
            } catch (e) {
                errorNotification(e);
            } finally {
                setLoading(false);
            }
        }

        void asyncWrapper();
    }, [entity, entityId, labelOverride]);

    if (!entity || !label) {
        return null;
    }

    if (loading) {
        return <Skeleton.Input active size="small" />;
    }

    switch (entity) {
        case 'claim':
            return (
                <Link
                    to="/console/$orgId/claims/details/$id/manage"
                    params={{
                        orgId,
                        id: entityId,
                    }}
                    type="link"
                >
                    {label}
                </Link>
            );
        case 'complaint':
            return (
                <Link
                    to="/console/$orgId/compliance/complaints/details/$id/manage"
                    params={{
                        orgId,
                        id: entityId,
                    }}
                    type="link"
                >
                    {label}
                </Link>
            );
        case 'obligation':
            return (
                <Link
                    to="/console/$orgId/compliance/obligations/details/$id/$section"
                    params={{
                        orgId,
                        id: entityId,
                        section: 'manage',
                    }}
                    type="link"
                >
                    {label}
                </Link>
            );
        case 'risk':
            return (
                <Link
                    to="/console/$orgId/risk/register/details/$id/$section"
                    params={{
                        orgId,
                        id: entityId,
                        section: 'assessment',
                    }}
                    type="link"
                >
                    {label}
                </Link>
            );
        case 'control':
            return (
                <Link
                    to="/console/$orgId/controls/register/details/$id"
                    params={{
                        orgId,
                        id: entityId,
                    }}
                    type="link"
                >
                    {label}
                </Link>
            );
        case 'controlTest':
            return <div className="text-gray-600">{label}</div>;
        case 'incident':
            return (
                <Link
                    to="/console/$orgId/compliance/incidents/details/$id/$section"
                    params={{
                        orgId,
                        id: entityId,
                        section: 'manage',
                    }}
                    type="link"
                >
                    {label}
                </Link>
            );
        default:
            return null;
    }
}
