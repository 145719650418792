import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { VDropdown } from '@client/components/Common/ViewForm/VDropdown';
import { VHandlingPartySelect } from '@client/components/Common/ViewForm/VHandlingPartySelect';
import { VSwitch } from '@client/components/Common/ViewForm/VSwitch';
import { VTagsSelect } from '@client/components/Common/ViewForm/VTagsSelect';
import { VTextInput } from '@client/components/Common/ViewForm/VTextInput';
import { ContactList } from '@client/components/Contact/ContactList';
import { SupplierDefs } from '@client/global/supplier';
import { useConfigService } from '@client/hooks/Configuration/useConfigService';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { trpc } from '@client/trpc/client';
import { RecordNotFoundError } from '@client/utils/error';
import { requiredFieldRule } from '@client/utils/form';
import { validatePhoneNumber } from '@client/utils/phone';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { Card, Checkbox, Col, Row, Skeleton, Space, Tag } from 'antd';
import Joi from 'joi';
import { supplierFormStore } from './store';

export const Route = createFileRoute('/console/$orgId/configuration/suppliers/details/$id')({
    staticData: {
        breadcrumb: {
            title: 'Supplier details',
        },
    },
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: SupplierDetails,
});

export function SupplierDetails() {
    const { id } = useParams({
        from: '/console/$orgId/configuration/suppliers/details/$id',
    });
    const configService = useConfigService();
    const trpcUtils = trpc.useUtils();
    const updateSupplierMutation = trpc.supplier.updateSupplier.useMutation();
    const navigate = useNavigate();
    const orgId = useOrgId();
    const { formatPhoneNumber } = useFormatter();

    const { formData: supplier, save } = supplierFormStore.useInit({
        entity: 'supplier',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.loading('Saving...');

                await updateSupplierMutation.mutateAsync({
                    id: currentValue.id,
                    set: newValue,
                });

                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcUtils.supplier.getSupplier.fetch({ id });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The supplier you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({
                    to: '/console/$orgId/configuration/suppliers',
                    params: { orgId },
                });
                return;
            }

            errorMessage.show(error);
        },
    });

    return id === supplier?.id ? (
        <Space direction="vertical" size={16} className="w-full">
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <Card title="Supplier Information" size="small" className="h-full">
                        <Row gutter={[16, 16]} className="p-2">
                            <Col span={12}>
                                <VTextInput
                                    label="Name"
                                    field="name"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                />
                            </Col>
                            <Col span={12}>
                                <VDropdown
                                    label="Category"
                                    field="category"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                    loaderFn={async () => {
                                        const data = await configService.fetchSupplier_Categories();

                                        return data.map((category) => ({ value: category }));
                                    }}
                                    showSearch
                                />
                            </Col>
                            <Col span={24}>
                                <VHandlingPartySelect
                                    label="Handling parties"
                                    field="handlingPartyIds"
                                    formStore={supplierFormStore}
                                    valueOverride={
                                        <div className="flex flex-wrap gap-1">
                                            {supplier.HandlingParties?.length
                                                ? supplier.HandlingParties.map((item) => (
                                                      <Tag
                                                          key={item?.HandlingParty?.id}
                                                          rootClassName="whitespace-normal m-0"
                                                      >
                                                          {item?.HandlingParty?.name}
                                                      </Tag>
                                                  ))
                                                : '-'}
                                        </div>
                                    }
                                    showAllOrgs
                                />
                            </Col>
                            <Col span={12}>
                                <VTextInput
                                    label="Contact Person"
                                    field="contactPerson"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                />
                            </Col>
                            <Col span={12}>
                                <VTextInput
                                    label="Email"
                                    field="email"
                                    formStore={supplierFormStore}
                                    rules={[
                                        {
                                            async validator(_, value) {
                                                if (!value) throw new Error('Field is required');
                                                const res = Joi.string()
                                                    .allow(null, '')
                                                    .email({ tlds: { allow: false } })
                                                    .label('Email')
                                                    .validate(value);
                                                if (res.error?.message) throw new Error(res.error?.message);
                                            },
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={12}>
                                <VTextInput
                                    label="Address line 1"
                                    field="addressLine1"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                />
                            </Col>
                            <Col span={12}>
                                <VTextInput label="Address line 2" field="addressLine2" formStore={supplierFormStore} />
                            </Col>
                            <Col span={12}>
                                <VDropdown
                                    label="Country"
                                    field="country"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                    options={Object.keys(SupplierDefs.states).map((country) => ({
                                        label: country,
                                        value: country,
                                    }))}
                                    showSearch
                                />
                            </Col>
                            <Col span={12}>
                                <VDropdown
                                    label="State"
                                    field="stateOrProvince"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                    options={
                                        supplier.country
                                            ? SupplierDefs.states[supplier.country].map((state) => ({
                                                  label: state,
                                                  value: state,
                                              }))
                                            : []
                                    }
                                    showSearch
                                />
                            </Col>
                            <Col span={12}>
                                <VTextInput
                                    label="Phone number"
                                    field="phone"
                                    formStore={supplierFormStore}
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!validatePhoneNumber(supplier.phone || ''))
                                                    throw new Error('Invalid phone number');
                                            },
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={12}>
                                <VDropdown
                                    label="Servicing area states"
                                    field="servicingAreaStates"
                                    formStore={supplierFormStore}
                                    formatValueFn={(value) => value?.join?.(', ')}
                                    options={SupplierDefs.aryServicingAreaState.map((state) => ({
                                        label: state,
                                        value: state,
                                    }))}
                                    multiple
                                    rules={requiredFieldRule}
                                    showSearch
                                />
                            </Col>
                            <Col span={12}>
                                <VTagsSelect
                                    label="Servicing area postcodes"
                                    field="servicingAreaPostcodes"
                                    formStore={supplierFormStore}
                                    formatValueFn={(value) => value?.join?.(', ')}
                                    rules={[
                                        {
                                            validator: async (_, value) => {
                                                const postcodeRegExp =
                                                    /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/;
                                                let invalidInputs = [];
                                                if (value) {
                                                    invalidInputs = value.filter(
                                                        (code: string) => !code.match(postcodeRegExp),
                                                    );
                                                }
                                                if (!invalidInputs.length) return;
                                                if (invalidInputs.length === 1) {
                                                    throw new Error(
                                                        invalidInputs.join('') + ' is not a valid postcode',
                                                    );
                                                } else {
                                                    throw new Error(
                                                        invalidInputs.slice(0, -1).join(', ') +
                                                            ' and ' +
                                                            invalidInputs.slice(-1) +
                                                            ' are not valid postcodes',
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={12}>
                                <VDropdown
                                    label="AFSL Status"
                                    field="asfStatus"
                                    formStore={supplierFormStore}
                                    options={SupplierDefs.aryAfslStatus.map((status) => ({
                                        label: status,
                                        value: status,
                                    }))}
                                    showSearch
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Financial details" size="small" className="h-full">
                        <Row gutter={[16, 16]} className="p-2">
                            <Col span={24}>
                                <VTextInput
                                    label="BSB"
                                    field="accountBsb"
                                    formStore={supplierFormStore}
                                    rules={[
                                        {
                                            async validator(_, value) {
                                                if (!value) throw new Error('Field is required');
                                                const res = Joi.string()
                                                    .pattern(/^\d{3}-?\d{3}$/)
                                                    .label('BSB')
                                                    .messages({
                                                        'string.pattern.base': 'Invalid BSB code',
                                                    })
                                                    .required()
                                                    .validate(value);
                                                if (res.error?.message) throw new Error(res.error?.message);
                                            },
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={24}>
                                <VTextInput
                                    label="Account number"
                                    field="accountNo"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                />
                            </Col>
                            <Col span={24}>
                                <VSwitch
                                    label="Registered for GST"
                                    field="isRegisteredForGst"
                                    formStore={supplierFormStore}
                                />
                            </Col>
                            <Col span={24}>
                                <VTextInput
                                    label="ABN"
                                    field="abn"
                                    formStore={supplierFormStore}
                                    rules={requiredFieldRule}
                                />
                            </Col>
                            <Col span={24}>
                                <VTextInput
                                    label="Remittance"
                                    field="remittanceEmail"
                                    formStore={supplierFormStore}
                                    rules={[
                                        {
                                            async validator(_, value) {
                                                const res = Joi.string()
                                                    .allow(null, '')
                                                    .email({ tlds: { allow: false } })
                                                    .label('Remittance')
                                                    .validate(value);
                                                if (res.error?.message) throw new Error(res.error?.message);
                                            },
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <ContactList
                        contactType="supplier"
                        customColumns={[
                            {
                                dataIndex: 'firstName',
                                title: 'First name',
                            },
                            {
                                dataIndex: 'lastName',
                                title: 'Surname',
                            },
                            {
                                dataIndex: 'position',
                                title: 'Position',
                            },
                            {
                                dataIndex: 'email',
                                title: 'Email',
                            },
                            {
                                dataIndex: 'phone',
                                title: 'Phone',
                                render: (value) => formatPhoneNumber(value),
                            },
                            {
                                dataIndex: 'isPrimaryForType',
                                title: 'Primary',
                                render: (value) => <Checkbox checked={value} onChange={() => {}} />,
                            },
                        ]}
                        headerClassName="font-semibold"
                        hideIsPrimaryColumn={true}
                        onChange={async (contacts) => {
                            await save({
                                contacts,
                            });
                        }}
                        title="Supplier contacts"
                        value={supplier.contacts || {}}
                    />
                </Col>
            </Row>
        </Space>
    ) : (
        <Skeleton active />
    );
}
