import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from '@client/components/Common/Button';
import { message } from '@client/components/Common/message';
import { modal } from '@client/components/Common/modal';
import { useUser } from '@client/hooks/User/useUser';
import { useAuthStore } from '@client/stores/AuthStore';
import { AuthStoreUser } from '@client/stores/AuthStore/types';
import { trpc } from '@client/trpc/client';
import { AUTH0_CONNECTIONS } from '@shared/definitions/auth0';
import { createFileRoute } from '@tanstack/react-router';
import { Avatar, Card, Form, Input, Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/user-settings/profile')({
    staticData: {
        metadata: {
            title: 'Profile',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: ProfileSettings,
});

type User = Pick<AuthStoreUser, 'fullName' | 'email' | 'provider'>;

const FormItem = Form.Item<User>;

function ProfileSettings() {
    const user = useUser();
    const { enableSsoMigration } = useFlags();
    const allowMigrationToSso = useAuthStore((state) => state.allowMigrationToSso);
    const { mutate } = trpc.user.allowMigrationToSso.useMutation();

    const [isMigrationToSsoAllowed, setIsMigrationToSsoAllowed] = useState(false);

    const [form] = Form.useForm();

    const isSsoProvider = user.isSsoActivated || user.provider === 'curium-ad';

    return (
        <Card>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={{
                    fullName: user.fullName,
                    email: user.email,
                    provider: Object.entries(AUTH0_CONNECTIONS).find(
                        ([, value]) =>
                            (value.name === 'curium-ad' && isSsoProvider) ||
                            (user.provider === value.name && !isSsoProvider),
                    )?.[1].label,
                }}
            >
                <Avatar src={user.photoUrl} size={96} className="mx-auto mb-4 block" />
                <FormItem label="Name" name="fullName">
                    <Input disabled />
                </FormItem>
                <FormItem label="E-mail" name="email">
                    <Input disabled />
                </FormItem>
                <FormItem label="Authentication method" name="provider">
                    <Input
                        disabled
                        addonAfter={
                            !isSsoProvider &&
                            enableSsoMigration && (
                                <Button
                                    type="primary"
                                    size="small"
                                    className="w-28.5"
                                    disabled={isMigrationToSsoAllowed}
                                    icon={isMigrationToSsoAllowed && <CheckCircleFilled />}
                                    onClick={async () => {
                                        modal.confirm({
                                            title: 'Migrate to Single Sign-On',
                                            content: (
                                                <>
                                                    <Typography.Paragraph>
                                                        You are about to switch to Single Sign-On (SSO). After this, you
                                                        will be able to use your account to sign in via SSO. Password
                                                        sign in will be disabled.
                                                    </Typography.Paragraph>
                                                    <Typography.Paragraph>
                                                        To sign in with SSO, you will need to log out and then click the{' '}
                                                        <Typography.Text strong>
                                                            "Continue with Azure AD"
                                                        </Typography.Text>{' '}
                                                        button when signing in.
                                                    </Typography.Paragraph>
                                                    <Typography.Paragraph>
                                                        Are you sure you want to proceed?
                                                    </Typography.Paragraph>
                                                </>
                                            ),
                                            onOk() {
                                                console.log('user.allowMigrationToSso', user.allowMigrationToSso);

                                                if (user.allowMigrationToSso) {
                                                    setIsMigrationToSsoAllowed(true);
                                                    message.warning('Migration to SSO is already allowed.');
                                                    return;
                                                }

                                                mutate(undefined, {
                                                    onSuccess() {
                                                        allowMigrationToSso();
                                                        setIsMigrationToSsoAllowed(true);
                                                        message.success('Migration to SSO is allowed.');
                                                    },
                                                });
                                            },
                                        });
                                    }}
                                >
                                    {!isMigrationToSsoAllowed && 'Migrate to SSO'}
                                </Button>
                            )
                        }
                    />
                </FormItem>
            </Form>
        </Card>
    );
}
