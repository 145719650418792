import { message } from '@client/components/Common/message';
import { useUserId } from '@client/hooks/User/useUserId';
import { RouterInputs, trpc } from '@client/trpc/client';
import { debounce } from '@client/utils/general';
import { skipToken } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Checkbox, Divider, Form, Skeleton } from 'antd';
import { useEffect } from 'react';

export const Route = createFileRoute('/console/$orgId/user-settings/notifications')({
    staticData: {
        metadata: {
            title: 'Notifications',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: NotificationSettings,
});

type UserNotificationInput = RouterInputs['user']['updateUserNotifications']['set'];

const FormItem = Form.Item<UserNotificationInput>;

function NotificationSettings() {
    const userId = useUserId();
    const { data, isPending } = trpc.user.getUserNotifications.useQuery(userId ? { userId } : skipToken);
    const { mutate, isPending: isUpdating } = trpc.user.updateUserNotifications.useMutation();

    const [form] = Form.useForm<UserNotificationInput>();

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data]);

    const onFinish = debounce((values: UserNotificationInput) => {
        message.loading('Saving...');

        mutate(
            { userId, set: values },
            {
                onSuccess() {
                    message.success('Saved.');
                },
            },
        );
    });

    return (
        <Card>
            {isPending ? (
                <Skeleton active />
            ) : (
                <Form form={form} onFinish={onFinish}>
                    <Divider plain orientation="left">
                        Tasks
                    </Divider>
                    <FormItem name="notificationAssignedToTask" valuePropName="checked">
                        <Checkbox>Assigned to task</Checkbox>
                    </FormItem>
                    <FormItem name="notificationNewUnallocatedTask" valuePropName="checked">
                        <Checkbox>New unallocated task</Checkbox>
                    </FormItem>
                    <Divider plain orientation="left">
                        Complaints
                    </Divider>
                    <FormItem name="notificationComplaintCreated" valuePropName="checked">
                        <Checkbox>Complaint submitted</Checkbox>
                    </FormItem>
                    <FormItem name="notificationComplaintCreatedInMyExternalOrg" valuePropName="checked">
                        <Checkbox>Complaint created for my handling party</Checkbox>
                    </FormItem>
                    <FormItem name="notificationAssignedToComplaintAsOwner" valuePropName="checked">
                        <Checkbox>Assigned as owner</Checkbox>
                    </FormItem>
                    <FormItem name="notificationComplaintIndicatorChanged" valuePropName="checked">
                        <Checkbox>Complaint indicator changed</Checkbox>
                    </FormItem>
                    <FormItem name="notificationComplaintPublicSubmitted" valuePropName="checked">
                        <Checkbox>Public complaint submitted</Checkbox>
                    </FormItem>
                    <Divider plain orientation="left">
                        Incidents
                    </Divider>
                    <FormItem name="notificationIncidentSubmitted" valuePropName="checked">
                        <Checkbox>Incident submitted</Checkbox>
                    </FormItem>
                    <FormItem name="notificationIncidentAssignedAsOwner" valuePropName="checked">
                        <Checkbox>Assigned as owner</Checkbox>
                    </FormItem>
                    <FormItem name="notificationIncidentIndicatorChanged" valuePropName="checked">
                        <Checkbox>Incident indicator changed</Checkbox>
                    </FormItem>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit" loading={isUpdating}>
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Card>
    );
}
