import { errorMessage } from '@client/components/Common/errorMessage';
import { Link } from '@client/components/Common/Link';
import { message } from '@client/components/Common/message';
import { emailTemplates, smsTemplates } from '@client/global/constants';
import { ClaimFormState } from '@client/hooks/Claim/useClaimFormState';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { trpc } from '@client/trpc/client';
import { navRoutes } from '@shared/navigation/navRoutes';
import { generatePath } from '@shared/navigation/utils';
import { ContactCollection } from '@shared/types/contact';
import { capitalize } from '@shared/utils/string';
import { Button, Card, Dropdown, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

type Props = {
    formState: ClaimFormState;
    isPublicForm?: boolean;
};

export const Confirmation = observer(({ formState, isPublicForm = false }: Props) => {
    const orgId = useOrgId();
    const sendSmsMutation = trpc.communication.sendSms.useMutation();
    const sendEmailMutation = trpc.communication.sendEmail.useMutation();

    const sendSms = async (phoneNumber: string) => {
        try {
            message.loading(`Sending SMS...`);

            await sendSmsMutation.mutateAsync({
                to: phoneNumber,
                templateId: smsTemplates.claim_summary_link,
                data: {
                    lodgementLink: `${window.location.origin}${generatePath(navRoutes.public_lodgementSummary, {
                        accessKey: formState.claimAccessKey!,
                    })}`,
                },
            });

            message.destroy();
            message.success(`SMS sent.`);
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };

    const sendEmail = async (email: string) => {
        try {
            message.loading(`Sending email...`);

            await sendEmailMutation.mutateAsync({
                to: email,
                templateId: emailTemplates.claim_summary_link,
                data: {
                    linkText: `${window.location.origin}${generatePath(navRoutes.public_lodgementSummary, {
                        accessKey: formState.claimAccessKey!,
                    })}`,
                    linkHtml: `<a href="${window.location.origin}${generatePath(navRoutes.public_lodgementSummary, {
                        accessKey: formState.claimAccessKey!,
                    })}">link</a>`,
                },
            });

            message.destroy();
            message.success(`Email sent.`);
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };

    const phoneNumbers = useMemo(() => {
        const aryContact = Object.values((formState.claim.contacts || {}) as ContactCollection);
        return (
            aryContact
                ?.filter((el) => el.phone)
                .map((el) => ({
                    label: `${capitalize(el.type).replace('_', ' ')} - ${el.firstName} ${el.lastName}`,
                    phoneNumber: el.phone,
                })) || []
        );
    }, [formState.claim]);

    const emails = useMemo(() => {
        const aryContact = Object.values((formState.claim.contacts || {}) as ContactCollection);
        return (
            aryContact
                ?.filter((el) => el.email)
                .map((el) => ({
                    label: `${capitalize(el.type).replace('_', ' ')} - ${el.firstName} ${el.lastName}`,
                    email: el.email,
                })) || []
        );
    }, [formState.claim]);

    return (
        <Card>
            <div className="flex flex-col items-center p-4">
                <Typography.Title level={4}>Thank you!</Typography.Title>
            </div>
            <div className="m-auto flex flex-col">
                <div className="flex flex-col items-center p-4">
                    <div className="pb-4">
                        Your claim number is <span className="text-xl font-bold">#{formState.claimId}</span>
                    </div>
                    {!isPublicForm && (
                        <>
                            <div className="pb-4">Summary of your claim</div>
                            <Space>
                                <Dropdown
                                    menu={{
                                        items: phoneNumbers.map((pn) => ({
                                            key: pn.label,
                                            label: (
                                                <>
                                                    <div>
                                                        <Typography.Text type="secondary">{pn.label}</Typography.Text>
                                                    </div>
                                                    {pn.phoneNumber}
                                                </>
                                            ),
                                            onClick: async () => {
                                                await sendSms(pn.phoneNumber!);
                                            },
                                        })),
                                    }}
                                    disabled={!phoneNumbers.length}
                                >
                                    <Button type="primary">Send SMS</Button>
                                </Dropdown>
                                <Dropdown
                                    menu={{
                                        items: emails.map((email) => ({
                                            key: email.label,
                                            label: (
                                                <>
                                                    <div>
                                                        <Typography.Text type="secondary">
                                                            {email.label}
                                                        </Typography.Text>
                                                    </div>
                                                    {email.email}
                                                </>
                                            ),
                                            onClick: async () => {
                                                await sendEmail(email.email!);
                                            },
                                        })),
                                    }}
                                    disabled={!emails.length}
                                >
                                    <Button type="primary">Send e-mail</Button>
                                </Dropdown>
                                <Link
                                    to="/console/$orgId/claims/details/$id/manage"
                                    params={{
                                        orgId,
                                        id: BigInt(formState.claimId!),
                                    }}
                                    type="primary"
                                >
                                    Show summary
                                </Link>
                            </Space>
                        </>
                    )}
                </div>
            </div>
        </Card>
    );
});
