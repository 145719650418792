import { message } from '@client/components/Common/message';
import { NumberInput } from '@client/components/Common/NumberInput';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Drawer, Form, Input, Space } from 'antd';
import { useState } from 'react';
export const Route = createFileRoute('/admin-console/products/$serviceId')({
    component: EditService,
    staticData: {
        ui: {
            title: 'Edit Service',
            subtitle: 'Edit service details.',
        },
        displayType: 'overlay',
    },
    params: {
        parse: (params) => {
            return {
                serviceId: Number(params.serviceId),
            };
        },
    },
});

function EditService() {
    const { serviceId } = Route.useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const { trpc, trpcUtils } = useTrpc();
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const { data: service } = trpc.admin.service.getService.useQuery({ id: serviceId });

    const updateServiceMutation = trpc.admin.service.updateService.useMutation();

    const handleSave = async () => {
        try {
            setSaving(true);
            message.loading('Saving...');
            const values = await form.validateFields();
            await updateServiceMutation.mutateAsync({
                id: serviceId,
                set: {
                    name: values.name,
                    description: values.description,
                    unitPrice: values.unitPrice,
                },
            });
            await trpcUtils.admin.service.invalidate();
            message.success('Service updated successfully');
            setOpen(false);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Drawer
            title="Edit Service"
            open={open}
            onClose={() => setOpen(false)}
            afterOpenChange={(open) => {
                if (!open) {
                    navigate({
                        to: '/admin-console/products',
                    });
                }
            }}
            width={500}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button type="primary" onClick={handleSave} loading={saving}>
                        Save Changes
                    </Button>
                </Space>
            }
        >
            {service && (
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        name: service.name,
                        code: service.code,
                        description: service.description || undefined,
                        unitPrice: service.unitPrice,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter the service name' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="code" label="Code">
                        <Input readOnly className="bg-gray-50" />
                    </Form.Item>

                    <Form.Item name="description" label="Description">
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                    </Form.Item>

                    <Form.Item
                        name="unitPrice"
                        label="Unit Price"
                        rules={[{ required: true, message: 'Please enter the unit price' }]}
                    >
                        <NumberInput className="w-full" precision={3} valueType="decimal" prefix="$" />
                    </Form.Item>
                </Form>
            )}
        </Drawer>
    );
}
