import { grey } from '@ant-design/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useUser } from '@client/hooks/User/useUser';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { listQuestionnaireInputSchema } from '@server/schemas/questionnaire';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Popconfirm, Table } from 'antd';
import { useState } from 'react';
import { QuestionnaireAction, QuestionnaireEdit } from './QuestionnaireEdit';

export const Route = createFileRoute('/console/$orgId/configuration/questionnaire')({
    staticData: {
        breadcrumb: {
            title: 'Questionnaire',
        },
        ui: {
            title: 'Questionnaire configuration',
        },
        modules: ['claim'],
    },
    component: ConfigurationQuestionnaire,
});

type Questionnaire = RouterOutputs['questionnaire']['listQuestionnaires']['rows'][number];

function ConfigurationQuestionnaire() {
    const user = useUser();
    const [questionnaireAction, setQuestionnaireAction] = useState<QuestionnaireAction>(['none']);
    const [queryParams, setQueryParams] = useZodState(listQuestionnaireInputSchema);
    const { data, isLoading, refetch } = trpc.questionnaire.listQuestionnaires.useQuery(queryParams);
    const { mutateAsync: deleteQuestionnaire } = trpc.questionnaire.deleteQuestionnaire.useMutation();

    const { tableProps } = useAntdTable<Questionnaire>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setQueryParams((prev) => ({
                ...prev,
                ...options,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) =>
                    user.isOrgAdmin && (
                        <Popconfirm
                            title="Are you sure?"
                            onPopupClick={(e) => e.stopPropagation()}
                            onConfirm={async () => {
                                try {
                                    await deleteQuestionnaire({
                                        id: value,
                                    });

                                    void refetch();
                                } catch (e) {
                                    errorMessage.show(e);
                                }
                            }}
                        >
                            <Button
                                type="text"
                                size="small"
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Popconfirm>
                    ),
            },
        ],
    });

    return (
        <>
            <QuestionnaireEdit
                action={questionnaireAction}
                onClose={() => {
                    setQuestionnaireAction(['none']);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setQuestionnaireAction(['add']);
                    }}
                >
                    Add new
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setQuestionnaireAction(['edit', id]),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
