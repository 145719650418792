import { modal } from '@client/components/Common/modal';
import { RiskAppetiteLabel } from '@client/components/Risk/RiskAppetiteLabel';
import {
    RiskAppetiteStatementAction,
    RiskAppetiteStatementAdd,
} from '@client/components/Risk/RiskAppetiteStatementAdd';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Table } from 'antd';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/risk/ras/')({
    component: RiskAppetiteStatementSearch,
});

type RiskCategoryWithAppetite =
    RouterOutputs['riskAppetiteStatement']['getRiskCategoryTreeWithAppetites']['rows'][number];

function RiskAppetiteStatementSearch() {
    const navigate = useNavigate();
    const orgId = useOrgId();
    const [action, setAction] = useState<RiskAppetiteStatementAction>(['none']);
    const [input, setInput] = useState<RouterInputs['riskAppetiteStatement']['getRiskCategoryTreeWithAppetites']>({
        where: undefined,
        limit: 50,
        offset: 0,
    });

    const { data, isPending, isRefetching, refetch } =
        trpc.riskAppetiteStatement.getRiskCategoryTreeWithAppetites.useQuery(input);

    const { tableProps } = useAntdTable<RiskCategoryWithAppetite>({
        data: {
            rows: data?.rows,
            loading: isPending || isRefetching,
            total: data?.total,
        },
        paginationConfig: { hideOnSinglePage: true, pageSize: 50 },
        onQueryVariableChange: (options) => {
            setInput({
                ...input,
                limit: options?.limit,
                offset: options?.offset,
                orderBy: options?.orderBy?.length ? options?.orderBy : input.orderBy,
            });
        },
        columns: [
            {
                title: 'Risk Category',
                dataIndex: 'category.name',
            },
            {
                title: 'Risk Appetite',
                dataIndex: 'riskAppetite',
                render: (_, record) => (
                    <RiskAppetiteLabel
                        description={record.riskAppetite?.appetite?.description || ''}
                        color={record.riskAppetite?.appetite?.color}
                        name={record.riskAppetite?.appetite?.name}
                        isDeleted={record.riskAppetite?.isDeleted}
                    />
                ),
                className: 'whitespace-nowrap w-0 px-5',
                fixed: 'right',
            },
            {
                title: 'Summary',
                dataIndex: 'riskAppetite.summary',
            },
        ],
    });

    const handleRowClick = (record: RiskCategoryWithAppetite, e: React.MouseEvent) => {
        if (record?.riskAppetite?.id) {
            navigate(
                {
                    to: '/console/$orgId/risk/ras/details/$id',
                    params: {
                        orgId,
                        id: record.riskAppetite.id,
                    },
                },
                e,
            );
        } else if (record?.category?.id) {
            if (record.categoryLevel === 1) {
                setAction(['add', record.category.id, record.categoryHierarchy.map((c) => c.name)]);
                return;
            }
            const ancestors = record.categoryHierarchy.slice(0, -1);

            for (let i = 0; i < ancestors.length; i++) {
                const ancestorId = ancestors[i].id;
                const ancestorName = ancestors[i].name;
                const ancestorLevel = i + 1;

                const findAncestor = (categories: RiskCategoryWithAppetite[]): RiskCategoryWithAppetite | undefined => {
                    for (const category of categories) {
                        if (category.categoryLevel === ancestorLevel && category.category.id === ancestorId) {
                            return category;
                        }
                        if (category.children.length > 0) {
                            const found = findAncestor(category.children);
                            if (found) return found;
                        }
                    }
                    return undefined;
                };

                const ancestor = findAncestor(data?.rows || []);

                if (!ancestor?.riskAppetite || ancestor.riskAppetite.isDeleted) {
                    modal.warning({
                        title: 'Action Required',
                        content: `Before assigning a risk appetite to "${record.category.name}", please first define a risk appetite for the parent category "${ancestorName}". Risk appetites must be assigned in hierarchical order.`,
                    });
                    return;
                }
            }

            setAction(['add', record.category.id, record.categoryHierarchy.map((c) => c.name)]);
        }
    };

    return (
        <>
            <Card size="small">
                <Table
                    {...tableProps}
                    indentSize={30}
                    size="small"
                    scroll={{ x: 'max-content' }}
                    onRow={(record) => ({
                        className: 'cursor-pointer',
                        onClick: (e) => handleRowClick(record, e),
                    })}
                />
            </Card>

            <RiskAppetiteStatementAdd
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />
        </>
    );
}
