import { RichText } from '@client/components/Common/RichText';
import { Filters } from '@client/components/Filters';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { listFaqsInputSchema } from '@server/schemas/faq';
import { skipToken } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Col, Form, Row, Select, Space, Spin, Table, Tag } from 'antd';
import ReactPlayer from 'react-player';

export const Route = createFileRoute('/console/$orgId/faq')({
    staticData: {
        breadcrumb: {
            title: 'FAQ',
        },
        roles: ['user', 'org_admin'],
    },
    component: FaqSearch,
});

function VideoPlayer({ videoFileKey }: { videoFileKey?: string }) {
    const { data, isLoading } = trpc.file.getDownloadUrl.useQuery(
        videoFileKey
            ? {
                  key: videoFileKey,
              }
            : skipToken,
    );

    if (!videoFileKey) {
        return null;
    }

    return (
        <Spin spinning={isLoading} size="large" tip="Loading video...">
            <div className="flex justify-center">
                <ReactPlayer
                    wrapper={({ children }) => <div className="min-h-20 max-w-[640px] shadow-2xl">{children}</div>}
                    url={data}
                    controls
                />
            </div>
        </Spin>
    );
}

type Faq = RouterOutputs['faq']['listFaqs']['rows'][number];

type Filter = {
    fullTextSearch: string;
    categories: string[];
};

const FormItem = Form.Item<Faq>;

function FaqSearch() {
    const [form] = Form.useForm<Filter>();

    const [input, setInput] = useZodState(listFaqsInputSchema);

    const { data: faqs, isPending } = trpc.faq.listFaqs.useQuery(input);
    const { data: categories } = trpc.faq.getFaqCategories.useQuery();

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: faqs?.rows,
            loading: isPending,
            total: faqs?.total,
        },
        onQueryVariableChange(options) {
            setInput((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : prev.orderBy,
            }));
        },
        columns: [
            {
                className: 'flex justify-between gap-4 cursor-pointer items-center',
                render: (_, item) => (
                    <>
                        <h4>{item.title}</h4>
                        <Space className="flex-wrap justify-end">
                            {item.categories?.map((category: string) => (
                                <Tag key={category} color="blue" className="m-0">
                                    {category}
                                </Tag>
                            ))}
                        </Space>
                    </>
                ),
            },
        ],
    });

    function onSearch(searchValue?: string) {
        const item = form.getFieldsValue();
        const where: { AND: NonNullable<typeof input.where>[] } = { AND: [] };

        if (!where.AND) {
            return;
        }

        if (item.categories) {
            where.AND.push({
                OR: item.categories.map((item) => ({
                    categories: {
                        array_contains: item,
                    },
                })),
            });
        }

        if (searchValue) {
            where.AND.push({
                OR: [
                    { title: { contains: searchValue, mode: 'insensitive' } },
                    { content: { contains: searchValue, mode: 'insensitive' } },
                ],
            });
        }

        setInput((prev) => ({
            ...prev,
            where: {
                ...prev.where,
                ...where,
            },
        }));
    }

    return (
        <>
            <PageLayout.Header
                features={[
                    {
                        feature: 'search',
                        onSearch,
                    },
                    {
                        feature: 'filters',
                        form,
                        onSearch() {
                            onSearch();
                        },
                        children: (
                            <Filters.Item>
                                <FormItem label="Categories" name="categories">
                                    <Select
                                        mode="multiple"
                                        options={categories?.map((item) => ({
                                            value: item,
                                        }))}
                                    />
                                </FormItem>
                            </Filters.Item>
                        ),
                    },
                ]}
            />

            <Card>
                <Table
                    showHeader={false}
                    size="small"
                    expandable={{
                        expandRowByClick: true,
                        expandedRowRender: ({ content, videoFileKey }) => (
                            <Row className="flex justify-center py-6">
                                <Col xxl={16} xl={16} lg={20} md={24} sm={24} xs={24}>
                                    <Card variant="borderless">
                                        <RichText data={content} className="prose mb-6" />
                                        <VideoPlayer videoFileKey={videoFileKey || ''} />
                                    </Card>
                                </Col>
                            </Row>
                        ),
                        rowExpandable: () => true,
                    }}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
