import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useTrpc } from '@client/hooks/useTrpc';
import { type RouterInputs } from '@client/trpc/client';
import { AU_STATES, ISO_COUNTRY_CODES } from '@shared/definitions/location';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Form, Input, Select } from 'antd';
import { useEffect } from 'react';

export const Route = createFileRoute('/console/$orgId/configuration/billing/billing-info')({
    component: BillingInfo,
});

type BillingInfoFormData = RouterInputs['billing']['upsertBillingInfo']['set'];

function BillingInfo() {
    const [form] = Form.useForm<BillingInfoFormData>();
    const { trpc, trpcUtils } = useTrpc();

    const { data: billingInfo, isLoading } = trpc.billing.getBillingInfo.useQuery();
    const upsertBillingInfoMutation = trpc.billing.upsertBillingInfo.useMutation({
        onSuccess: () => {
            message.success('Billing information updated successfully');
        },
    });

    useEffect(() => {
        if (billingInfo) {
            form.setFieldsValue({
                fullName: billingInfo.fullName,
                address: billingInfo.address,
                address2: billingInfo.address2 ?? undefined,
                city: billingInfo.city,
                state: billingInfo.state ?? undefined,
                postalCode: billingInfo.postalCode,
                country: billingInfo.country,
                phone: billingInfo.phone ?? undefined,
                taxId: billingInfo.taxId ?? undefined,
                vatNumber: billingInfo.vatNumber ?? undefined,
                email: billingInfo.email,
            });
        }
    }, [billingInfo, form]);

    const onFinish = async (values: BillingInfoFormData) => {
        try {
            message.loading('Updating billing information...');
            await upsertBillingInfoMutation.mutateAsync({
                set: values,
            });
            await trpcUtils.billing.getBillingInfo.invalidate();
            message.success('Billing information updated successfully');
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    };

    return (
        <div className="flex justify-center">
            <Card loading={isLoading} title="Billing Information">
                <div className="mb-6">
                    <p className="text-gray-500">
                        Please provide your billing details for invoice processing. All fields marked with * are
                        required.
                    </p>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    className="mx-auto max-w-xl"
                    initialValues={{ country: 'AU' }}
                >
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        rules={[{ required: true, message: 'Please enter your full name' }]}
                        help="Enter the full name as it should appear on invoices and billing documents"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email address' },
                            { type: 'email', message: 'Please enter a valid email address' },
                        ]}
                        help="Email address for receiving invoices and billing notifications"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[{ required: true, message: 'Please enter your address' }]}
                        help="Enter your primary billing address (street name and number)"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Address Line 2"
                        name="address2"
                        help="Additional address information (suite, unit number, building, etc.)"
                    >
                        <Input />
                    </Form.Item>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[{ required: true, message: 'Please enter your city' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="State"
                            name="state"
                            rules={[{ required: true, message: 'Please select your state' }]}
                            help="Select your state/territory"
                        >
                            <Select
                                options={AU_STATES.map((state) => ({
                                    value: state,
                                    label: state,
                                }))}
                                placeholder="Select state"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Postal Code"
                            name="postalCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your postal code',
                                },
                            ]}
                            help="Enter your 4-digit postal code"
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="Country"
                        name="country"
                        rules={[{ required: true, message: 'Please enter your country' }]}
                        help="Currently only supporting Australian businesses"
                    >
                        <Select
                            options={Object.entries(ISO_COUNTRY_CODES).map(([code, name]) => ({
                                value: code,
                                label: name,
                            }))}
                            disabled={true}
                            placeholder="Select country"
                        />
                    </Form.Item>

                    <Form.Item label="Phone" name="phone" help="Contact number for billing-related inquiries">
                        <Input />
                    </Form.Item>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <Form.Item label="Tax ID" name="taxId" help="Your Australian Business Number (ABN)">
                            <Input />
                        </Form.Item>

                        {/* <Form.Item
                                label="VAT Number"
                                name="vatNumber"
                                help="Your VAT registration number (if applicable)"
                            >
                                <Input />
                            </Form.Item> */}
                    </div>

                    <Form.Item className="mb-0">
                        <Button type="primary" htmlType="submit" loading={upsertBillingInfoMutation.isPending}>
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}
