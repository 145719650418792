import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/complaints')({
    staticData: {
        breadcrumb: {
            title: 'Complaints',
        },
    },
    component: Outlet,
});
