import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/debug')({
    staticData: {
        breadcrumb: {
            title: 'Debug',
            inactive: true,
        },
        featureFlag: 'enableDebugInfo',
    },
    component: Outlet,
});
