import React, { useEffect } from 'react';

type Props = {
    dep?: any;
};

export function ScrollToTopOnMount({ dep }: Props) {
    useEffect(() => {
        window.setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 0);
    }, [dep]);
    return <></>;
}
