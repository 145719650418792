import { ThemeConfig } from 'antd/es/config-provider/context';

export const theme: ThemeConfig = {
    cssVar: true,
    token: {
        colorPrimary: '#FA7832',
        colorLink: '#FA7832',
        colorLinkHover: '#DA5005',
        colorLinkActive: '#DA5005',
        colorPrimaryHover: '#F8661C',
    },
};
