import { ApolloClient } from '@apollo/client';
import { CompleteTaskMutation, CompleteTaskMutationVariables } from '@client/__generated__/graphql';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { CompleteTask } from '@client/graphql/mutations/task';
import { RouterOutputs } from '@client/trpc/client';
import { TASK_DUE_DATE_SETTINGS, TASK_STATUSES } from '@shared/definitions/task';
import { NodeTypeEnum } from '@shared/types/workflow';
import { differenceInBusinessDays, isBefore, isSameDay } from 'date-fns';
import { parseISOToDate } from './dateTime';

export function isTaskOverDue(dueDate: Date | string, isCompleted: boolean | null | undefined = false) {
    if (isCompleted) {
        return false;
    }

    const currentDate = new Date();
    const parsedDueDate = dueDate instanceof Date ? dueDate : parseISOToDate(dueDate);
    return isBefore(parsedDueDate, currentDate) && !isSameDay(parsedDueDate, currentDate);
}

export async function completeTask(client: ApolloClient<object>, taskId: bigint, orgId: bigint) {
    try {
        message.loading('Completing task...');
        const { data } = await client.mutate<CompleteTaskMutation, CompleteTaskMutationVariables>({
            mutation: CompleteTask,
            variables: {
                id: taskId,
                orgId: orgId,
            },
        });
        if (data?.updateTaskByPk?.id === taskId) {
            message.success('Task Completed!');
            return Promise.resolve();
        } else {
            return Promise.reject(new Error("Task wasn't completed"));
        }
    } catch (e) {
        errorMessage.show(e);
        return Promise.reject(e);
    }
}

export function getActionName(action: NodeTypeEnum | null) {
    const names: { [key in NodeTypeEnum]?: string } = {
        [NodeTypeEnum.allocateSupplier]: 'Allocate a supplier',
        [NodeTypeEnum.changeStatus]: 'Change status',
        [NodeTypeEnum.changeTriageOutcome]: 'Change triage outcome',
        [NodeTypeEnum.makeDecision]: 'Make a decision',
        [NodeTypeEnum.makePayment]: 'Make a payment',
        [NodeTypeEnum.updateReserve]: 'Update reserve',
        [NodeTypeEnum.sendMessageEmail]: 'Send a message - email',
        [NodeTypeEnum.sendMessageText]: 'Send a message - text',
    };
    return action ? names[action] : '';
}

export const t = {
    TASK_RELATED_ENTITIES: {
        null: 'Ad-hoc',
        claim: 'Claim',
        complaint: 'Complaint',
        obligation: 'Obligation',
        risk: 'Risk',
        control: 'Control',
        controlTest: 'Control Test',
        incident: 'Incident',
    },
    TASK_STATUSES: {
        NOT_APPLICABLE: '-',
        OVERDUE: 'Overdue',
        DUE_SOON: 'Due soon',
        NOT_DUE: 'Not due',
        DONE: 'Done',
    },
};

/**
 * @deprecated Use t.TASK_RELATED_ENTITIES instead
 */
export function getTaskRelatedEntityTitle({ relatedEntity }: Pick<RouterOutputs['task']['getTask'], 'relatedEntity'>) {
    if (!relatedEntity) {
        return 'Ad-hoc';
    }

    return {
        claim: 'Claim',
        complaint: 'Complaint',
        obligation: 'Obligation',
        risk: 'Risk',
        control: 'Control',
        controlTest: 'Control Test',
        incident: 'Incident',
    }[relatedEntity];
}

export function getTaskStatus({
    dueDate,
    isCompleted,
}: Pick<RouterOutputs['task']['getTask'], 'dueDate' | 'isCompleted'>) {
    if (isCompleted) {
        return TASK_STATUSES.DONE;
    }

    if (!dueDate) {
        return TASK_STATUSES.NOT_APPLICABLE;
    }

    const diffInDays = differenceInBusinessDays(dueDate, new Date());

    /*
     * Notes:
     *  Overdue = due today or in the past,
     *  Due soon = due tomorrow or up to 3 days in the future,
     *  Not due = due in more than 3 days
     */
    const status =
        diffInDays < 1
            ? TASK_STATUSES.OVERDUE
            : diffInDays <= TASK_DUE_DATE_SETTINGS.DUE_SOON_DAYS_COUNT
              ? TASK_STATUSES.DUE_SOON
              : TASK_STATUSES.NOT_DUE;

    return status;
}

export function getTaskStatusUI(task: Pick<RouterOutputs['task']['getTask'], 'dueDate' | 'isCompleted'>) {
    const TASK_STATUS_COLORS = {
        NOT_APPLICABLE: 'volcano',
        OVERDUE: 'red',
        DUE_SOON: 'orange',
        NOT_DUE: 'green',
        DONE: 'gray',
    } as const satisfies Record<keyof typeof TASK_STATUSES, string>;

    const status = getTaskStatus(task);

    return {
        label: t.TASK_STATUSES[status],
        color: TASK_STATUS_COLORS[status],
    };
}
