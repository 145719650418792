import { trpc } from '@client/trpc/client';

export type TrpcClient = ReturnType<typeof trpc.useUtils>['client'];

/**
 * @deprecated
 * useTrpc is preferred over useTrpcClient.
 */
export function useTrpcClient() {
    const utils = trpc.useUtils();
    return utils.client;
}
