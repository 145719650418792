import { gql } from '@client/__generated__';

export const PdsVersionByInsurerIdAndProductTypeId = gql(/* GraphQL */ `
    query PdsVersionByInsurerIdAndProductTypeId($productTypeId: bigint!, $insurerId: bigint!) {
        PdsVersion(
            where: { Pds: { productTypeId: { _eq: $productTypeId }, insurerId: { _eq: $insurerId } } }
            orderBy: [{ name: ASC }]
        ) {
            id
            name
            questionnaireId
            Questionnaire {
                definition
            }
            workflowId
            Workflow {
                definition
            }
        }
    }
`);

export const PdsVersionByPk = gql(/* GraphQL */ `
    query PdsVersionByPk($id: bigint!, $orgId: bigint!) {
        PdsVersionByPk(id: $id, orgId: $orgId) {
            id
            name
            questionnaireId
            endDate
            pdsId
            startDate
            workflowId
        }
    }
`);

export const PdsVersionWithQuestionnaireByPk = gql(/* GraphQL */ `
    query PdsVersionWithQuestionnaireByPk($id: bigint!, $orgId: bigint!) {
        PdsVersionByPk(id: $id, orgId: $orgId) {
            id
            name
            questionnaireId
            endDate
            pdsId
            startDate
            workflowId
            Pds {
                productTypeId
                insurerId
            }
            Questionnaire {
                id
                definition
                name
            }
        }
    }
`);
