import React, { useState } from 'react';
import Joi from 'joi';
import { Alert } from 'antd';

type Severity = 'error' | 'warning';

interface State {
    title?: string | undefined;
    message?: any;
    severity?: Severity;
    showIcon?: boolean;
}

export function useInlineError(options?: { title?: string; severity?: Severity; showIcon?: boolean }) {
    const defaults = { ...options, ...{ severity: 'error', title: 'Error', showIcon: true } };
    const [state, setState] = useState<State>({
        title: defaults.title,
        message: '',
        severity: defaults.severity as Severity,
        showIcon: defaults.showIcon,
    });

    const renderMessage = (message: any) => {
        const joi = message as Joi.ValidationResult;
        if (message.name === 'Error' || typeof message === 'string') {
            return message.message;
        } else if (joi.error?.isJoi) {
            const details = joi.error.details;
            if (details.length > 1) {
                return (
                    <ul className="pl-4">
                        {details.map((er) => (
                            <li>{er.message}</li>
                        ))}
                    </ul>
                );
            } else {
                return details[0].message;
            }
        } else if (React.isValidElement(message)) {
            return <>{message}</>;
        } else {
            return <>[message]</>;
        }
    };

    return {
        InlineError: (props: { className?: string }) => (
            <>
                {state.message && (
                    <div {...props}>
                        <Alert
                            message={state.title}
                            type={state.severity}
                            description={renderMessage(state.message)}
                            showIcon
                        />
                    </div>
                )}
            </>
        ),
        isDisplayed: !!state.message,
        setError: ({
            title,
            message,
            severity,
            showIcon,
        }: {
            title?: string;
            message?: any;
            severity?: Severity;
            showIcon?: boolean;
        }) => {
            setState({
                ...state,
                ...{
                    ...(title && { title }),
                    ...(message && { message }),
                    ...(severity && { severity }),
                    ...(showIcon && { showIcon }),
                },
            });
        },
        clearError: () => {
            setState({
                ...state,
                ...{ message: '' },
            });
        },
    };
}
