import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance')({
    staticData: {
        breadcrumb: {
            title: 'Compliance',
            inactive: true,
        },
        roles: ['user', 'org_admin'],
        modules: ['compliance'],
        restrictToEmployee: false,
    },
    component: Outlet,
});
