import { useRef, useState } from 'react';
import { InputWrapper, InputWrapperRef, VInputProps } from '@client/components/Common/ViewForm/InputWrapper';
import { DatePicker, DatePickerProps } from '@client/components/Common/DatePicker';
import { uuid } from '@shared/utils/general';
import { DefaultFormData } from '@client/stores/FormStore/types';

type Props<TFormData extends DefaultFormData> = Omit<VInputProps<TFormData>, 'hideSaveButton' | 'hideCancelButton'> &
    Pick<DatePickerProps, 'format' | 'showTime' | 'maxDate' | 'minDate'>;

export function VDatePicker<TFormData extends DefaultFormData>({
    format,
    showTime,
    maxDate,
    minDate,
    buttonsPosition = 'hidden',
    ...props
}: Props<TFormData>) {
    const [id] = useState(uuid());
    const inputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<InputWrapperRef | null>(null);

    return (
        <InputWrapper
            focusFn={() => inputRef.current?.focus()}
            innerRef={(ref) => (wrapperRef.current = ref)}
            containerId={id}
            buttonsPosition={buttonsPosition}
            useAntdErrorMessages
            {...props}
        >
            <DatePicker
                ref={inputRef}
                format={format}
                showTime={showTime}
                maxDate={maxDate}
                minDate={minDate}
                onChange={() => void wrapperRef.current?.save()}
                getPopupContainer={() => document.getElementById(id)!}
                defaultOpen
            />
        </InputWrapper>
    );
}
