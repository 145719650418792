import { NotFound } from '@client/components/NotFound';
import { AuthCallback, Route as AuthCallbackRoute } from '@client/routes/~(authentication)/~callback/~route';
import { routeTree } from '@client/routeTree.gen';
import { trpcQueryUtils } from '@client/trpc/client';
import { CommonBreadCrumbItem } from '@client/utils/navigation';
import { AppModule } from '@shared/definitions/app';
import { Role, UserFlag } from '@shared/definitions/user';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

AuthCallbackRoute.update({
    component: AuthCallback,
});

export const router = createRouter({
    routeTree,
    defaultNotFoundComponent: NotFound,
    defaultPreload: 'intent',
    context: {
        trpcUtils: undefined!,
        ldClient: undefined!,
    },
});

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }

    interface StaticDataRouteOption {
        /** Overlay is used for drawers, modals, etc. */
        displayType?: 'default' | 'overlay';
        /** Metadata is used for the page title in the browser tab */
        metadata?: {
            title: string;
        };
        /** Breadcrumb is used for the breadcrumb in the page */
        breadcrumb?: CommonBreadCrumbItem & {
            title?: string;
        };
        /** UI is used for the page title, subtitle, and variant */
        ui?: {
            title?: string;
            subtitle?: string;
            variant?: 'default' | 'compact';
        };
        /** Roles are used to restrict access to the page */
        roles?: Role[];
        /** Modules are used to restrict access to the page */
        modules?: AppModule[];
        userFlags?: UserFlag[];
        featureFlag?: keyof ReturnType<typeof useFlags>;
        restrictToEmployee?: boolean;
        superAdminOnly?: boolean;
        privilegedSuperAdminOnly?: boolean;
    }
}

export function TanstackRouterProvider() {
    const ldClient = useLDClient();

    return <RouterProvider router={router} context={{ trpcUtils: trpcQueryUtils, ldClient }} />;
}
