import { EditOutlined, LockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import clsx, { ClassValue } from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export type VFieldLayoutProps = {
    readonly?: boolean;
    label: string | null | undefined;
    layout?: 'vertical' | 'horizontal';
    icon?: ReactNode;
    /**
     * An input control
     */
    children?: ReactNode;
    editMode?: boolean;
    onRequestEdit?: () => void;
    value: any;
    useEditPopover?: boolean;
    disableTooltip?: boolean;
    customTooltip?: ReactNode;
    helpMessage?: string;
    classNames?: {
        root?: ClassValue;
        label?: ClassValue;
        container?: ClassValue;
        viewContent?: ClassValue;
    };
};

export const VFieldLayout = observer(
    ({
        readonly = false,
        label,
        icon,
        layout = 'vertical',
        editMode = false,
        onRequestEdit,
        value,
        useEditPopover,
        disableTooltip = false,
        customTooltip,
        helpMessage,
        children,
        classNames,
    }: VFieldLayoutProps) => (
        <div
            className={clsx(
                'group/root max-w-full',
                !editMode && 'inline-flex',
                layout === 'vertical' && 'w-full flex-col',
                layout === 'horizontal' && 'flex-row',
                classNames?.root,
            )}
        >
            {label && (
                <div
                    className={clsx(
                        'text-sm font-semibold text-gray-900',
                        'group-[.flex-row]/root:self-center group-[.flex-col]/root:pb-1',
                        "group-[.flex-row]/root:after:pr-1 group-[.flex-row]/root:after:content-[':']",
                        classNames?.label,
                    )}
                >
                    {icon}
                    {label}
                    {readonly && <LockOutlined className="ml-1 text-xs text-gray-400" />}
                    {helpMessage && (
                        <Tooltip title={<div className="text-sm text-gray-600">{helpMessage}</div>} color="white">
                            <QuestionCircleOutlined className="ml-1 text-sm text-gray-400" />
                        </Tooltip>
                    )}
                </div>
            )}
            {readonly || !editMode ? (
                <div
                    className={clsx(
                        'group/container transition duration-300',
                        !readonly && 'transition-all hover:-m-1 hover:rounded-sm hover:bg-gray-200 hover:p-1',
                        classNames?.container,
                    )}
                    onClick={() => {
                        if (readonly || useEditPopover) {
                            return;
                        }

                        onRequestEdit?.();
                    }}
                >
                    {useEditPopover ? (
                        <Popover
                            placement="rightTop"
                            arrow={false}
                            classNames={{
                                root: '[&_.ant-popover-inner]:bg-transparent [&_.ant-popover-inner]:border-none [&_.ant-popover-inner]:shadow-none [&_.ant-popover-inner]:p-0 [&_.ant-popover-inner]:ml-[2px] [&_.ant-popover-inner]:mt-[-1px]',
                            }}
                            content={
                                <Button
                                    onClick={() => {
                                        onRequestEdit?.();
                                    }}
                                    icon={<EditOutlined />}
                                    size="small"
                                    title="Edit"
                                    className="drop-shadow-xl"
                                    tabIndex={0}
                                />
                            }
                        >
                            <Tooltip
                                title={readonly && !disableTooltip ? customTooltip || 'Read-only' : null}
                                placement="topLeft"
                            >
                                <div className={clsx('flex font-normal text-gray-600', classNames?.viewContent)}>
                                    {value}
                                </div>
                            </Tooltip>
                        </Popover>
                    ) : (
                        <Tooltip
                            title={disableTooltip ? null : readonly ? customTooltip || 'Read-only' : customTooltip}
                            placement="topLeft"
                        >
                            <div className={clsx('flex font-normal text-gray-600', classNames?.viewContent)}>
                                {value}
                            </div>
                        </Tooltip>
                    )}
                </div>
            ) : (
                <div className="group/container font-normal text-gray-600">{children}</div>
            )}
        </div>
    ),
);
