import { message } from '@client/components/Common/message';
import { useFormatter } from '@client/hooks/useFormatter';
import { trpc } from '@client/trpc/client';
import { htmlContainsText, sanitizeHtml } from '@client/utils/text';
import { Button, Form, Typography } from 'antd';
import { RichTextInput } from '../../Common/RichTextInput';
import { User } from '../../Common/User';

type Comment = {
    content: string;
};

const FormItem = Form.Item<Comment>;

export function TaskComments({ taskId }: { taskId: bigint }) {
    const { formatDateToRelative } = useFormatter();

    const { data, refetch } = trpc.comment.listComments.useQuery({
        where: { taskId },
        orderBy: { createdAt: 'desc' },
    });
    const { mutate } = trpc.comment.createComment.useMutation();

    const [form] = Form.useForm<Comment>();

    function onFinish(values: Comment) {
        message.loading('Saving...');

        mutate(
            {
                ...values,
                taskId,
            },
            {
                onSuccess() {
                    message.success('Saved.');
                    form.resetFields();
                    void refetch();
                },
            },
        );
    }

    return (
        <div>
            {!!data?.rows.length && (
                <div className="mb-10 flex flex-col gap-4">
                    {data?.rows.map((item) => (
                        <div key={item.id}>
                            <div className="flex items-center">
                                <User
                                    className="mr-3 flex items-center"
                                    photoUrl={item.CreatedBy?.photoUrl}
                                    name={item.CreatedBy?.fullName}
                                    showPopover={false}
                                />
                                <span className="mr-6 font-semibold text-gray-600">left a comment</span>
                                <span className="font-semibold text-gray-600">
                                    {formatDateToRelative(item.createdAt)}
                                </span>
                            </div>
                            <div
                                className="m-0 mt-4 text-gray-600"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(item.content),
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
            <Typography.Title level={5}>Write a comment</Typography.Title>
            <Form form={form} onFinish={onFinish}>
                <FormItem
                    name="content"
                    rules={[
                        {
                            async validator(_, value) {
                                if (!htmlContainsText(value)) {
                                    throw new Error('Field is required');
                                }
                            },
                        },
                    ]}
                >
                    <RichTextInput />
                </FormItem>
                <div className="flex justify-end">
                    <Button type="primary" htmlType="submit">
                        Post comment
                    </Button>
                </div>
            </Form>
        </div>
    );
}
