import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { RichTextInput } from '@client/components/Common/RichTextInput';
import { UserSelector } from '@client/components/Common/UserSelector';
import { useCreateSignalStore } from '@client/context/signal';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useSafePath } from '@client/hooks/useSafePath';
import { RouterInputs, trpc } from '@client/trpc/client';
import {
    CONTROL_FREQUENCY,
    CONTROL_STATUSES,
    CONTROL_TYPES,
    ControlFrequency,
    ControlStatus,
    ControlType,
} from '@shared/definitions/control';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { ControlContext, ControlSignals } from '../../ControlContext';

export const Route = createFileRoute('/console/$orgId/controls/register/add')({
    staticData: {
        breadcrumb: {
            title: 'New control',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: ControlAdd,
});

type ControlInput = RouterInputs['control']['createControl'];

function ControlAdd() {
    const [form] = Form.useForm<ControlInput>();
    const orgId = useOrgId();
    const navigate = useNavigate();
    const signalStore = useCreateSignalStore<ControlSignals>();
    const { mutateAsync: createControl, isPending: createControlLoading } = trpc.control.createControl.useMutation();

    const safePath = useSafePath<ControlInput>();

    const onFinish = async (values: ControlInput) => {
        try {
            message.loading('Saving...');
            const { id } = await createControl(values);
            message.success('Saved.');

            navigate({
                to: '/console/$orgId/controls/register/details/$id',
                params: {
                    orgId,
                    id,
                },
            });
        } catch (e) {
            errorMessage.show(e);
        }
    };

    return (
        <ControlContext.Provider value={signalStore}>
            <Card>
                <Typography.Title level={4} className="mb-4 mt-0">
                    Add Control
                </Typography.Title>
                <Form form={form} id="controlEdit" layout="vertical" onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item label="Status" name={safePath('status')} required rules={[{ required: true }]}>
                                <Select
                                    options={Object.keys(CONTROL_STATUSES).map((key) => ({
                                        label: CONTROL_STATUSES[key as ControlStatus],
                                        value: key,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                label="Control Owner"
                                name={safePath('ownerId')}
                                required
                                rules={[{ required: true }]}
                            >
                                <UserSelector />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                label="Frequency"
                                name={safePath('frequency')}
                                required
                                rules={[{ required: true }]}
                            >
                                <Select
                                    options={Object.keys(CONTROL_FREQUENCY).map((key) => ({
                                        label: CONTROL_FREQUENCY[key as ControlFrequency],
                                        value: key,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item label="Type" name={safePath('type')} required rules={[{ required: true }]}>
                                <Select
                                    options={Object.keys(CONTROL_TYPES).map((key) => ({
                                        label: CONTROL_TYPES[key as ControlType],
                                        value: key,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Control name"
                                name={safePath('name')}
                                required
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Control objective"
                                name={safePath('objective')}
                                required
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Control Description"
                                name={safePath('description')}
                                required
                                rules={[{ required: true }]}
                            >
                                <RichTextInput />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Space className="flex justify-end">
                    <Button
                        onClick={() =>
                            navigate({
                                to: '/console/$orgId/controls/register',
                                params: {
                                    orgId,
                                },
                            })
                        }
                    >
                        Cancel
                    </Button>
                    <Space>
                        <Button type="primary" htmlType="submit" form="controlEdit" loading={createControlLoading}>
                            Save
                        </Button>
                    </Space>
                </Space>
            </Card>
        </ControlContext.Provider>
    );
}
