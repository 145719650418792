import { grey } from '@ant-design/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@client/__generated__';
import { CommTemplateBoolExp, CommTemplateDsQuery } from '@client/__generated__/graphql';
import { errorMessage } from '@client/components/Common/errorMessage';
import { CommTemplateDs } from '@client/graphql/queries/commTemplate';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useTableDataSource } from '@client/hooks/Table/useTableDataSource';
import { commonActionColumn } from '@client/utils/table';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Popconfirm, Table } from 'antd';
import { useState } from 'react';
import { CommTemplateEdit } from './CommTemplateEdit';

type CommTemplate = CommTemplateDsQuery['CommTemplate'][number];

export const Route = createFileRoute('/console/$orgId/configuration/comms-templates')({
    staticData: {
        breadcrumb: {
            title: 'Communication templates',
        },
        ui: {
            title: 'Communication templates configuration',
        },
        modules: ['claim'],
    },
    component: ConfigurationCommsTemplates,
});

function ConfigurationCommsTemplates() {
    const orgId = useOrgId();
    const [getCommTemplateDs] = useLazyQuery(CommTemplateDs);
    const [deleteCommTemplateByPk] = useMutation(DeleteCommTemplateByPk);
    const [commTemplateIdToEdit, setCommTemplateIdToEdit] = useState<number | undefined>();

    const { tableProps, refetch, setLoading } = useTableDataSource<CommTemplate, CommTemplateBoolExp>({
        rowKey: 'id',
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
            },
            {
                dataIndex: 'name',
                title: 'Name',
                sorter: true,
            },
            {
                dataIndex: 'internalName',
                title: 'Internal Name',
                sorter: true,
            },
            {
                dataIndex: 'channel',
                title: 'Channel',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Popconfirm
                        title="Are you sure?"
                        onPopupClick={(e) => e.stopPropagation()}
                        onConfirm={async () => {
                            try {
                                setLoading(true);
                                await deleteCommTemplateByPk({
                                    variables: {
                                        id: value,
                                        orgId,
                                    },
                                });

                                void refetch('network-only');
                            } catch (e) {
                                errorMessage.show(e);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >
                        <Button
                            type="text"
                            size="small"
                            icon={<DeleteOutlined style={{ color: grey.primary }} />}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                ),
            },
        ],
        getResults: async (options) => {
            try {
                const { data } = await getCommTemplateDs({
                    variables: {
                        limit: options!.limit! || 10,
                        offset: options!.offset! || 0,
                        ...(options?.orderBy && { orderBy: options.orderBy }),
                    },
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                return {
                    rows: data?.CommTemplate,
                    total: data?.CommTemplateAggregate?.aggregate?.count || 0,
                };
            } catch (e) {
                return { error: e };
            }
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
        onError: (error) => errorMessage.show(error),
    });

    return (
        <>
            <CommTemplateEdit
                commTemplateId={commTemplateIdToEdit}
                open={!!commTemplateIdToEdit}
                onClose={() => {
                    setCommTemplateIdToEdit(undefined);
                    void refetch('network-only');
                }}
            />
            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setCommTemplateIdToEdit(-1);
                    }}
                >
                    Add new
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setCommTemplateIdToEdit(id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const DeleteCommTemplateByPk = gql(/* GraphQL */ `
    mutation DeleteCommTemplateByPk($id: bigint!, $orgId: bigint!) {
        deleteCommTemplateByPk(id: $id, orgId: $orgId) {
            id
        }
    }
`);
