import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/controls/library')({
    staticData: {
        breadcrumb: {
            title: 'Controls library',
        },
    },
    component: Outlet,
});
