import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { useTrpc } from '@client/hooks/useTrpc';
import { createFileRoute } from '@tanstack/react-router';
import { Table, Typography, Tag } from 'antd';
import { DateTime } from 'luxon';
import type { Stripe } from 'stripe';

export const Route = createFileRoute('/console/$orgId/configuration/billing/invoices')({
    component: Invoices,
});

function Invoices() {
    const { trpc } = useTrpc();
    const { data, isPending } = trpc.billing.listInvoices.useQuery();
    const { fDateTimeLxn } = useFormatter();

    const { tableProps } = useAntdTable<Stripe.Invoice>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            total: data?.total,
            loading: isPending,
        },
        columns: [
            {
                title: 'Invoice Number',
                dataIndex: 'number',
                key: 'number',
                render: (text: string) => <Typography.Text strong>{text}</Typography.Text>,
            },
            {
                title: 'Date',
                dataIndex: 'created',
                key: 'created',
                render: (timestamp: number) => DateTime.fromSeconds(timestamp).toFormat(fDateTimeLxn),
            },
            {
                title: 'Amount',
                dataIndex: 'total',
                key: 'total',
                render: (amount: number) =>
                    new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(amount / 100),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status: string) => {
                    const color = status === 'paid' ? 'success' : status === 'open' ? 'warning' : 'error';
                    return <Tag color={color}>{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>;
                },
            },
            {
                title: 'PDF',
                key: 'pdf',
                render: (_, record: Stripe.Invoice) => (
                    <Typography.Link href={record.hosted_invoice_url || '#'} target="_blank">
                        Open
                    </Typography.Link>
                ),
            },
        ],
        paginationConfig: {
            pageSize: 10,
            showSizeChanger: true,
        },
    });

    return <Table {...tableProps} />;
}
