import { DatePicker } from '@client/components/Common/DatePicker';
import { HandlingPartySelect } from '@client/components/Common/HandlingPartySelect';
import { Link } from '@client/components/Common/Link';
import { message } from '@client/components/Common/message';
import { modal } from '@client/components/Common/modal';
import { UserSelector } from '@client/components/Common/UserSelector';
import { ProductSelector } from '@client/components/Complaint/ProductSelector';
import { EmailDetailsAction, EmailDetailsDrawer } from '@client/components/EmailClassification/EmailDetailsDrawer';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUser } from '@client/hooks/User/useUser';
import { useSafePath } from '@client/hooks/useSafePath';
import { RouterInputs, trpc } from '@client/trpc/client';
import { requiredFieldRule } from '@client/utils/form.ts';
import { debounce } from '@client/utils/general';
import { skipToken } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Button, Card, Divider, Form, Input, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { z } from 'zod';

export const Route = createFileRoute('/console/$orgId/compliance/incidents/add')({
    staticData: {
        metadata: {
            title: 'New incident',
        },
        ui: {
            variant: 'compact',
        },
    },
    validateSearch: zodValidator(
        z.object({
            emailClassificationId: z.coerce.bigint().optional().catch(undefined),
        }),
    ),
    component: IncidentAdd,
});

type Incident = RouterInputs['incident']['createIncident'];

const FormItem = Form.Item<Incident>;

function IncidentAdd() {
    const orgId = useOrgId();
    const user = useUser();
    const { emailClassificationId } = Route.useSearch();
    const { fDateShortDayJs } = useFormatter();
    const { data: impactedProducts, loading: loadingProducts } = useConfigServiceLoader((configService) =>
        configService.fetchIncident_ProductsImpacted(),
    );
    const { data: emailClassification } = trpc.emailClassification.getEmailClassification.useQuery(
        emailClassificationId ? { id: emailClassificationId } : skipToken,
    );
    const { mutateAsync: createIncident, isPending } = trpc.incident.createIncident.useMutation();
    const navigate = useNavigate();

    const [emailDetailsAction, setEmailDetailsAction] = useState<EmailDetailsAction>(['hide']);

    const [form] = Form.useForm<Incident>();
    const safePath = useSafePath<Incident>();
    const handlingPartyIds = Form.useWatch(({ handlingPartyIds }) => handlingPartyIds, form);
    const reportedByOther = Form.useWatch(({ reportedByOther }) => reportedByOther, form);
    const ownerId = Form.useWatch(({ ownerId }) => ownerId, form);

    useEffect(() => {
        if (emailClassification) {
            const extractedData = emailClassification.extractedData || ({} as any);
            invariant(extractedData.issueType === 'incident', 'Email classification is not an incident.');

            form.setFieldsValue({
                description: emailClassification.summary || extractedData.description || undefined,
            });
        }
    }, [emailClassification]);

    const onFinish = debounce(async (values: Incident) => {
        message.loading('Saving...');

        const { id } = await createIncident({ isSignificant: false, ...values, emailClassificationId });

        message.success('Saved.');

        if (!user.isOrgAdmin && !values.handlingPartyIds.some((item) => user.externalOrgIds.includes(Number(item)))) {
            message.destroy();
            modal.warning({
                content:
                    "Your incident has been logged, but it won't appear in your incident list because it's assigned to a Handling Party you don't have access to.",
                onOk() {
                    navigate({ to: '/console/$orgId/compliance/incidents', params: { orgId } });
                },
            });
            return;
        }

        navigate({
            to: '/console/$orgId/compliance/incidents/details/$id/$section',
            params: { orgId, id, section: 'manage' },
        });
    });

    return (
        <>
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{ reportedById: user.id }}
                labelWrap
            >
                <Card>
                    {emailClassification && (
                        <div className="mb-4 flex justify-end">
                            <Button onClick={() => setEmailDetailsAction(['view', emailClassification.id])}>
                                View Incident Details
                            </Button>
                        </div>
                    )}
                    <Divider orientation="left">Incident</Divider>
                    <FormItem
                        label="When did you become aware of the incident?"
                        name="dtIdentified"
                        rules={[{ required: true, message: 'Field is required' }]}
                    >
                        <DatePicker className="w-full" format={fDateShortDayJs} maxDate={new Date()} />
                    </FormItem>
                    <FormItem
                        label="Which product or service does it impact?"
                        name="productImpacted"
                        rules={[{ required: true, message: 'Field is required' }]}
                    >
                        <Select
                            loading={loadingProducts}
                            options={impactedProducts?.map((item) => ({ label: item, value: item }))}
                        />
                    </FormItem>
                    <FormItem
                        label="Describe what happened"
                        name="description"
                        rules={[{ required: true, message: 'Field is required' }]}
                    >
                        <Input.TextArea rows={4} />
                    </FormItem>
                    <FormItem
                        label="Reported by"
                        required
                        dependencies={[safePath('reportedByOther')]}
                        name="reportedById"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value && !reportedByOther) throw new Error('Field is required');
                                },
                            },
                        ]}
                        validateTrigger={['onSelect']}
                    >
                        <UserSelector
                            showOtherUser
                            removeAnonymousAccount={false}
                            otherValue={reportedByOther || undefined}
                            onChangeOther={(value) => {
                                form.setFieldValue('reportedByOther', value);
                            }}
                            valueType="bigint"
                        />
                    </FormItem>
                    <FormItem name="reportedByOther" hidden />
                    <FormItem
                        label="Handling parties"
                        name="handlingPartyIds"
                        required
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value?.length) {
                                        throw new Error('Field is required');
                                    }
                                },
                            },
                        ]}
                        getValueProps={(value) => value}
                    >
                        <HandlingPartySelect
                            onChange={() => {
                                form.resetFields(['ownerId']);
                            }}
                            showAllOrgs
                        />
                    </FormItem>
                    <FormItem label="Owner" name="ownerId" rules={requiredFieldRule} validateTrigger={['onSelect']}>
                        <UserSelector value={ownerId} handlingPartyIds={handlingPartyIds} valueType="bigint" />
                    </FormItem>
                    <FormItem label="Products" name="products">
                        <ProductSelector />
                    </FormItem>
                    <FormItem label="Policy#" name="policyNo">
                        <Input />
                    </FormItem>
                    <FormItem label="Claim#" name="externalClaimId">
                        <Input />
                    </FormItem>
                </Card>
                <Space className="mt-2 flex justify-end">
                    <Link to="/console/$orgId/compliance/incidents" params={{ orgId }}>
                        Cancel
                    </Link>
                    <Button type="primary" htmlType="submit" loading={isPending}>
                        Save
                    </Button>
                </Space>
            </Form>

            <EmailDetailsDrawer
                action={emailDetailsAction}
                onClose={() => setEmailDetailsAction(['hide'])}
                title="Incident Details - Email"
            />
        </>
    );
}
