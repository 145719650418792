import { errorMessage } from '@client/components/Common/errorMessage';
import { useAntdInfiniteTable } from '@client/hooks/Table/useAntdInfiniteTable';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterOutputs } from '@client/trpc/client';

import { isTRPCErrorWithCode } from '@client/utils/error';
import { createFileRoute } from '@tanstack/react-router';
import { TRPCClientError } from '@trpc/client';
import { App, Button, Input, Table, Typography } from 'antd';

import { DateTime } from 'luxon';
import { useState } from 'react';
export const Route = createFileRoute('/console/$orgId/debug/test')({
    staticData: { breadcrumb: { title: 'Test' } },
    component: TestPage,
});

function TestPage() {
    const { trpcClient, trpc } = useTrpc();
    const [email, setEmail] = useState('');
    const { message } = App.useApp();

    const handleEmailVerification = async () => {
        try {
            message.info(`Verifying email: ${email}`);
            const res = await trpcClient.test.testEmailVerification.query();
            message.info(`Email verified: ${res}`);
        } catch (error) {
            errorMessage.show(error);
        }
    };

    const handleGetOpenAiResult = async () => {
        try {
            const res = await trpcClient.test.getOpenAiResult.query();
            console.log(res);
        } catch (error) {
            errorMessage.show(error);
        }
    };

    const handleTestDate = () => {
        const now = DateTime.now();
        const created = DateTime.now().minus({ hours: 6 });
        console.log(Math.ceil(now.diff(created, 'days').days));
    };

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
        trpc.incident.listIncidents.useInfiniteQuery(
            {
                limit: 20,
                orderBy: { createdAt: 'desc' },
            },
            {
                getNextPageParam: (lastPage, pages) => {
                    console.log(lastPage, pages);
                    const alreadyFetched = pages.flatMap((page) => page.rows).length;
                    const total = lastPage.total;
                    return alreadyFetched < total ? alreadyFetched : null;
                },
            },
        );

    const { tableProps, containerProps } = useAntdInfiniteTable<
        RouterOutputs['incident']['listIncidents']['rows'][number]
    >({
        rowKey: 'id',
        data: data ?? { pages: [], pageParams: [] },
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        scroll: {
            scrollToFirstRowOnSort: true,
        },
        columns: [
            {
                dataIndex: 'id',
                render: (_, record) => <Typography.Text>{record.id}</Typography.Text>,
            },

            {
                dataIndex: 'status',
                render: (_, record) => <Typography.Text>{record.status}</Typography.Text>,
            },
        ],
    });

    return (
        <div>
            <div className="h-[400px]">
                <div {...containerProps}>
                    <Table {...tableProps} size="small" showHeader={false} />
                </div>
            </div>

            <Button onClick={handleTestDate}>test date</Button>
            <Button onClick={handleGetOpenAiResult}>Get OpenAI Result</Button>
            <Button onClick={handleEmailVerification}>Email Verification</Button>
            <Input
                placeholder="Enter email"
                style={{ width: 300, marginRight: 8 }}
                onChange={(e) => setEmail(e.target.value)}
            />

            <Button
                onClick={async () => {
                    try {
                        await trpcClient.test.unauthorized.query();
                    } catch (error) {
                        errorMessage.show(error);
                    }
                }}
            >
                Generate Unauthorized Error
            </Button>
            <Button
                onClick={async () => {
                    try {
                        await trpcClient.test.badRequest.query();
                    } catch (error) {
                        console.log(error);
                        console.log(isTRPCErrorWithCode(error, 'invite.linkExpired'));
                        if (error instanceof TRPCClientError) {
                            errorMessage.show(error);
                        } else {
                            errorMessage.show(error);
                        }
                    }
                }}
            >
                Generate Bad Request Error
            </Button>
            <Button
                onClick={async () => {
                    const now = DateTime.now();
                    const nowIso = now.toISO();
                    console.log(nowIso, now.toJSDate());
                    console.log(DateTime.fromISO(nowIso).toJSDate());
                    console.log(DateTime.utc().toISO());
                }}
            >
                Date test
            </Button>
        </div>
    );
}
