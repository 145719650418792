import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { RouterInputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Input, Popconfirm, Space, Table } from 'antd';
import { format } from 'date-fns';
import { useState } from 'react';
import { AiPromptAction, AiPromptEdit } from './AiPromptEdit';

export const Route = createFileRoute('/admin-console/ai-prompts')({
    staticData: {
        breadcrumb: {
            title: 'AI Prompts',
        },
        ui: {
            subtitle: 'List of AI prompts.',
        },
    },
    component: AiPrompts,
});

function AiPrompts() {
    const [aiPromptAction, setAiPromptAction] = useState<AiPromptAction>(['none']);
    const [queryVariables, setQueryVariables] = useState<RouterInputs['aiPrompt']['listAiPrompts']>({
        limit: 10,
        offset: 0,
        orderBy: { id: 'desc' },
    });

    const { data: aiPrompts, isLoading, refetch } = trpc.aiPrompt.listAiPrompts.useQuery(queryVariables);
    const { mutate: deleteAiPrompt } = trpc.aiPrompt.deleteAiPrompt.useMutation();

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: aiPrompts?.rows || [],
            total: aiPrompts?.total || 0,
            loading: isLoading,
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                className: 'whitespace-nowrap',
                render: (value) => BigInt(value).toString(),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                className: 'whitespace-nowrap',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                className: 'whitespace-nowrap',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                className: 'whitespace-nowrap',
            },
            {
                title: 'Category',
                dataIndex: 'category',
                className: 'whitespace-nowrap',
                render: (category: string) =>
                    category === 'CHAT' ? 'Chat' : category === 'ASSISTANT' ? 'Assistant' : category,
            },
            {
                title: 'Assistant ID',
                dataIndex: 'assistantId',
                className: 'whitespace-nowrap',
                render: (text: string | null) => (text ? <div className="max-w-60 truncate">{text}</div> : '-'),
            },
            {
                title: 'Content',
                dataIndex: 'content',
                className: 'whitespace-nowrap',
                render: (text: string) => <div className="max-w-60 truncate">{text}</div>,
            },
            {
                title: 'Model',
                dataIndex: 'model',
                className: 'whitespace-nowrap',
                render: (text: string) => <div className="max-w-60 truncate">{text}</div>,
            },
            {
                title: 'Pricing Model',
                dataIndex: 'pricingModel',
                className: 'whitespace-nowrap',
                render: (text: string) => <div className="max-w-60 truncate">{text}</div>,
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                className: 'whitespace-nowrap',
                render: (date: string) => (date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '-'),
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                className: 'whitespace-nowrap',
                render: (date: string) => (date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '-'),
            },
            {
                ...commonActionColumn,
                align: 'center',
                className: 'h-full',
                render: (_, { id }) => (
                    <Space>
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                setAiPromptAction(['edit', id]);
                            }}
                        />
                        <Popconfirm
                            title="Delete AI Prompt"
                            description="Are you sure you want to delete this prompt?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteAiPrompt({ id }, { onSuccess: () => void refetch() })}
                        >
                            <Button type="link" icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} />
                        </Popconfirm>
                    </Space>
                ),
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                ...options,
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    return (
        <>
            <Space className="mb-4 flex justify-between">
                <Input.Search
                    placeholder="Search prompts"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        setQueryVariables({
                            ...queryVariables,
                            where: {
                                ...queryVariables.where,
                                OR: [{ name: { contains: value } }, { title: { contains: value } }],
                            },
                        });
                    }}
                />

                <Button type="primary" icon={<PlusOutlined />} onClick={() => setAiPromptAction(['create'])}>
                    Create Prompt
                </Button>
            </Space>

            <Card>
                <Table {...tableProps} size="small" scroll={{ x: true }} />
            </Card>

            <AiPromptEdit
                action={aiPromptAction}
                onClose={() => {
                    setAiPromptAction(['none']);
                    void refetch();
                }}
            />
        </>
    );
}
