import { observer } from 'mobx-react-lite';
import { VInputProps, InputWrapper, InputWrapperRef } from '@client/components/Common/ViewForm/InputWrapper';
import { Switch } from 'antd';
import { useRef } from 'react';
import { debounce } from '@client/utils/general';
import { DefaultFormData } from '@client/stores/FormStore/types';

type Props<TFormData extends DefaultFormData> = Omit<
    VInputProps<TFormData>,
    'rules' | 'valueOverride' | 'formatValueFn' | 'additionalFields'
>;

export const VSwitch = observer(<TFormData extends DefaultFormData>(props: Props<TFormData>) => {
    const wrapperRef = useRef<InputWrapperRef | null>(null);

    const onChange = debounce(async () => {
        await wrapperRef.current?.save();
    });

    return (
        <InputWrapper
            innerRef={(ref) => {
                wrapperRef.current = ref;
            }}
            permanentEditMode
            focusFn={() => {}}
            hideCancelButton
            hideSaveButton
            {...props}
        >
            <Switch onChange={onChange} />
        </InputWrapper>
    );
});
