import { DRAWER_CLOSING_ANIMATION_TIME } from '@client/utils/components';
import { MAIN_CONTENT_CONTAINER_ID } from '@client/utils/ui';
import {
    AnyRouter,
    NavigateOptions as NavigateOptionsOriginal,
    RegisteredRouter,
    useChildMatches,
    // eslint-disable-next-line curium/import/import
    useNavigate as useNavigateOriginal,
    useParentMatches,
    useRouter,
} from '@tanstack/react-router';
import { RoutePaths } from '@tanstack/router-core';
import { KeyboardEvent, MouseEvent } from 'react';

export type ConstrainLiteral<T, TConstraint, TDefault = TConstraint> = (T & TConstraint) | TDefault;
export type FromPathOption<TRouter extends AnyRouter, TFrom> = ConstrainLiteral<
    TFrom,
    RoutePaths<TRouter['routeTree']>
>;

export type NavigateOptions<
    TRouter extends RegisteredRouter,
    TTo extends string | undefined,
    TFrom extends string = string,
    TMaskFrom extends string = TFrom,
    TMaskTo extends string = '',
> =
    | (NavigateOptionsOriginal<TRouter, TFrom, TTo, TMaskFrom, TMaskTo> & {
          target?: '_blank';
          moveInHistory?: undefined;
          moveInMatches?: undefined;
          delay?: number;
          fromComponent?: 'drawer';
          scrollToTop?: boolean;
      })
    | { moveInHistory: 'back'; moveInMatches?: undefined; delay?: number; fromComponent?: 'drawer' }
    | { moveInHistory: 'forward'; moveInMatches?: undefined; delay?: number; fromComponent?: 'drawer' }
    // -2 | -1 | 1 | 2 is temporary solution to omit 0 when using type 'number'
    | {
          moveInMatches: -2 | -1 | 1 | 2;
          moveInHistory?: undefined;
          delay?: number;
          fromComponent?: 'drawer';
          keepSearch?: boolean;
      };

export function useNavigate<
    TRouter extends AnyRouter = RegisteredRouter,
    TDefaultFrom extends string = string,
>(defaultOptions?: { from?: FromPathOption<TRouter, TDefaultFrom> }) {
    const { history, buildLocation } = useRouter();
    const parentMatches = useParentMatches();
    const childMatches = useChildMatches();
    const navigate = useNavigateOriginal<TRouter, TDefaultFrom>(defaultOptions);

    return <
        TRouter extends RegisteredRouter,
        TTo extends string | undefined,
        TFrom extends string = TDefaultFrom,
        TMaskFrom extends string = TFrom,
        TMaskTo extends string = '',
    >(
        options: NavigateOptions<TRouter, TTo, TFrom, TMaskFrom, TMaskTo>,
        event?: MouseEvent | KeyboardEvent,
    ) => {
        function helper() {
            if (options.moveInMatches !== undefined) {
                const { moveInMatches } = options;

                navigate({
                    to:
                        moveInMatches < 0
                            ? parentMatches.at(moveInMatches)?.pathname
                            : childMatches[moveInMatches - 1]?.pathname,
                    ...(options.keepSearch && {
                        search(prev: any) {
                            return prev;
                        },
                    }),
                });
                return;
            }

            if (options.moveInHistory === 'back') {
                history.back();
                return;
            }

            if (options.moveInHistory === 'forward') {
                history.forward();
                return;
            }

            if (event?.ctrlKey || event?.metaKey || options?.target === '_blank') {
                window.open(options.href || buildLocation(options as any).href, '_blank');
                return;
            }

            if (options?.scrollToTop && !options.hash) {
                const container = document.getElementById(MAIN_CONTENT_CONTAINER_ID);
                container?.scrollTo(0, 0);
            }

            void navigate(options);
        }

        if (options.delay === undefined && options.fromComponent === undefined) {
            helper();
            return;
        }

        const fromComponentDelay =
            options.fromComponent && { drawer: DRAWER_CLOSING_ANIMATION_TIME }[options.fromComponent];

        setTimeout(helper, options.delay || fromComponentDelay);

        // if (options.hash) {
        //     setTimeout(() => {
        //         const element = document.getElementById(options.hash)
        //         element?.scrollIntoView({ behavior: 'smooth' });
        //     });
        // }
    };
}
