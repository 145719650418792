import Decimal from 'decimal.js';
import rfdc from 'rfdc';

export const mapObjectKeysToValues = <O extends Record<string, any>>(object: O) =>
    Object.fromEntries(Object.keys(object).map((key) => [key, key])) as { [K in keyof O]: K };

export const mapObjectValues = <O extends Record<string, any>, R>(
    object: O,
    callback: ([key, value]: [keyof O, O[keyof O]], currentObject: O) => R,
) =>
    Object.fromEntries(
        Object.entries(object || {}).map(([key, value]) => [key, callback([key, value], object)]),
    ) as Record<keyof O, R>;

export const stabilizeNullFields = <O extends Record<string, any>>(object: O) =>
    Object.fromEntries(Object.entries(object).map(([key, value]) => [key, value === null ? undefined : value])) as {
        [K in keyof O]: null extends O[K] ? Exclude<O[K], null> | undefined : O[K];
    };

export function objectToJsonValue<O extends Record<string, any>>(
    object: O,
): {
    [K in keyof O]: O[K] extends unknown
        ? any
        : O[K] extends Decimal
          ? string
          : O[K] extends Date
            ? string
            : O[K] extends bigint
              ? number
              : O[K];
} {
    return Object.fromEntries(
        Object.entries(object).map(([key, value]) => {
            let modifiedValue = value;

            if (Decimal.isDecimal(value)) {
                modifiedValue = value.toString();
            }

            if (value instanceof Date) {
                modifiedValue = value.toISOString();
            }

            if (typeof value === 'bigint') {
                modifiedValue = Number(value);
            }

            return [key, modifiedValue];
        }),
    ) as any;
}

/**
 * Deep clone an object using structured clone algorithm and delete properties
 *
 * !!!!! Be careful with Decimal - after transforming object decimal values not pass Decimal.isDecimal !!!!!
 *
 * @param obj Object to clone
 * @returns Cloned object
 **/
export function deleteObjectProperties<T extends Record<string, unknown>, K extends keyof T>(
    obj: T,
    ...keys: K[]
): Omit<T, K> {
    const clonedObject = rfdc()(obj);

    keys.forEach((key) => {
        delete clonedObject[key];
    });

    return clonedObject;
}
