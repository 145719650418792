import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Empty, Row, Skeleton, Timeline, Typography, Upload } from 'antd';
import { errorMessage } from '@client/components/Common/errorMessage';
import {
    OrderBy,
    CommChannelEnum,
    CommDirectionEnum,
    CommLogWithNotesQuery,
    CommLogWithNotesQueryVariables,
} from '@client/__generated__/graphql';
import { gold } from '@ant-design/colors';
import { CommLogTitle } from './CommLogTitle';
import { CommLogMessage } from './CommLogMessage';
import { OverrideProperties } from 'type-fest';
import { S3FileCollection } from '@shared/types/S3File';
import { ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useIsOffline } from '@client/hooks/useIsOffline';
import { gql } from '@client/__generated__';
import { useQuery } from '@apollo/client';
import { useClaimSignalContext } from '../ClaimSignalContext';
import { UploadDraggerOverlay } from '@client/components/Common/UploadDraggerOverlay';
import { useEmailUpload } from '@client/hooks/Claim/useEmailUpload';
import { claimFormStore } from '../store';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import './index.sass';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/comms')({
    validateSearch: zodValidator(
        z.object({
            messageId: z.string().optional(),
        }),
    ),
    component: Comms,
});

export type CommLogAndNote = OverrideProperties<
    CommLogWithNotesQuery['CommLogAndNote'][number],
    { tags: string[]; files: S3FileCollection }
>;

export type CommLog = Omit<CommLogAndNote, 'note' | 'authorId' | 'type' | 'complaintId' | 'NoteAuthor'>;

function Comms() {
    const { id } = claimFormStore.use.formData();
    const isOffline = useIsOffline();
    const { signals } = useClaimSignalContext();
    const { handleEmailUpload, loading: uploadingEmail } = useEmailUpload(id);

    const [messages, setMessages] = useState<CommLogAndNote[]>([]);
    const [variables, setVariables] = useState<CommLogWithNotesQueryVariables>({
        where: { claimId: { _eq: id } },
        orderBy: [{ createdAt: OrderBy.desc }],
        limit: 5,
        offset: 0,
    });

    useEffect(() => {
        if (!uploadingEmail) {
            void refetch();
        }
    }, [signals.commsUpdated, uploadingEmail]);

    const { data, loading, refetch } = useQuery(CommLogWithNotes, {
        variables: variables,
        fetchPolicy: 'network-only',
        pollInterval: 10000,
        skip: isOffline,
        onError: (error) => {
            errorMessage.show(error);
        },
    });

    useEffect(() => {
        if (data?.CommLogAndNote) {
            setMessages((data.CommLogAndNote || []) as CommLogAndNote[]);
        }
    }, [data]);

    const total = useMemo(() => data?.CommLogAndNoteAggregate?.aggregate?.count || 0, [data]);

    useEffect(() => {
        setVariables((prev) => ({
            ...prev,
            ...{ where: { claimId: { _eq: id } } },
        }));
    }, [id]);

    const generateCommLogItems = (messages: CommLogAndNote[]) => {
        return messages.map((el) => ({
            children: <CommLogMessage commLog={el} />,
            label: (
                <CommLogTitle
                    direction={el.direction as CommDirectionEnum}
                    createdAt={el.createdAt}
                    channel={el.channel as CommChannelEnum}
                    authorName={el.NoteAuthor?.fullName || ''}
                />
            ),
        }));
    };

    return (
        <>
            <Outlet />

            <UploadDraggerOverlay
                description="Drop .eml file here to upload"
                customRequest={handleEmailUpload}
                showUploadList={false}
                accept=".eml"
            />

            {loading && messages.length === 0 ? (
                <Skeleton active />
            ) : messages.length > 0 ? (
                <>
                    <div className="crm-timeline-vertical-left-container relative mt-4">
                        <Button
                            type="primary"
                            shape="circle"
                            title="Refresh"
                            icon={<ReloadOutlined />}
                            size="small"
                            className="absolute right-0 top-0 z-10"
                            onClick={() => {
                                void refetch();
                            }}
                        />
                        <Upload
                            customRequest={handleEmailUpload}
                            showUploadList={false}
                            accept=".eml"
                            className="absolute right-10 top-0 z-10"
                        >
                            <Button type="primary" icon={<UploadOutlined />} size="small" loading={uploadingEmail}>
                                Upload email
                            </Button>
                        </Upload>
                        <Timeline
                            mode="left"
                            pending={loading && <p style={{ color: gold.primary }}>Loading...</p>}
                            items={generateCommLogItems(messages)}
                        />
                    </div>
                    <Row>
                        <Col span={4} offset={10}>
                            {total > messages.length && !loading && (
                                <Button
                                    block
                                    onClick={() => {
                                        setVariables((prevState) => ({
                                            ...prevState,
                                            limit: prevState.limit! + 5,
                                        }));
                                    }}
                                >
                                    Show more
                                </Button>
                            )}
                        </Col>
                    </Row>
                </>
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<Typography.Text disabled>No messages</Typography.Text>}
                >
                    <Upload customRequest={handleEmailUpload} showUploadList={false} accept=".eml">
                        <Button type="primary" icon={<UploadOutlined />} loading={uploadingEmail}>
                            Upload email
                        </Button>
                    </Upload>
                </Empty>
            )}
        </>
    );
}

export const CommLogWithNotes = gql(/* GraphQL */ `
    query CommLogWithNotes(
        $orderBy: [CommLogAndNoteOrderBy!]
        $where: CommLogAndNoteBoolExp = {}
        $limit: Int = 5
        $offset: Int = 0
    ) {
        CommLogAndNote(orderBy: $orderBy, where: $where, limit: $limit, offset: $offset) {
            id
            orgId
            claimId
            recordType
            createdAt
            from
            fromId
            to
            inReplyTo
            subject
            messageId
            tags
            summary
            files
            duration
            direction
            contentSanitized
            content
            channel
            cc
            bcc
            attachments
            Notification {
                id
                isActive
            }
            note
            authorId
            type
            complaintId
            NoteAuthor {
                fullName
            }
        }
        CommLogAndNoteAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
