import { selectOrg, useAuthStore } from '@client/stores/AuthStore';
import { useOrgSettingsStore } from '@client/stores/OrgSettingStore';
import { SYSTEM_ORG_IDS } from '@shared/definitions/org';
import { useMatch } from '@tanstack/react-router';

/**
 * Returns the orgId from the URL or the auth store. If the orgId is not found, it returns -999.
 * Even if the orgId is returned, it does not guarantee that the user is authorized to access the org.
 *
 * @returns {number} - The orgId
 *
 **/
export function useOrgId(): bigint {
    const org = useAuthStore(selectOrg);
    const orgSettingsStore = useOrgSettingsStore();

    const publicOrgMatch = useMatch({ from: '/public/$orgId', shouldThrow: false });
    const consoleMatch = useMatch({ from: '/console/$orgId', shouldThrow: false });
    const adminConsoleMatch = useMatch({ from: '/admin-console', shouldThrow: false });

    if (publicOrgMatch?.params.orgId) {
        return publicOrgMatch.params.orgId;
    }

    if (org) {
        return org.id;
    }

    if (consoleMatch?.params.orgId) {
        return consoleMatch.params.orgId;
    }

    if (adminConsoleMatch) {
        return SYSTEM_ORG_IDS.SYSTEM_CONSOLE;
    }

    if (orgSettingsStore.orgSetting?.orgId) {
        return BigInt(orgSettingsStore.orgSetting.orgId);
    }

    return SYSTEM_ORG_IDS.NOT_APPLICABLE;
}
