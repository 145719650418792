import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { EventType } from '@shared/types/event';

export function useUserEvent() {
    const trpcClient = useTrpcClient();

    const insertEvent = async (event: EventType, details?: string) => {
        await trpcClient.event.createUserEvent.mutate({
            event,
            details,
        });
    };

    return { insertEvent };
}
