import { DatePicker } from '@client/components/Common/DatePicker';
import { HandlingPartySelect } from '@client/components/Common/HandlingPartySelect';
import { message } from '@client/components/Common/message';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useOverlayPage } from '@client/hooks/useOverlayPage';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterInputs } from '@client/trpc/client';
import { debounce } from '@client/utils/general';
import { REPORT_TYPES } from '@shared/definitions/report';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Drawer, Form, Select } from 'antd';
import { useId } from 'react';

export const Route = createFileRoute('/console/$orgId/reporting/add')({
    staticData: {
        displayType: 'overlay',
        metadata: {
            title: 'Generate a new report',
        },
    },
    component: ReportAdd,
});

type Report = RouterInputs['report']['generateReport'];

const FormItem = Form.Item<Report>;

function generateLastTenPeriodsForAsicReport() {
    const periods: string[] = [];
    const currentDate = new Date();

    let currentYear = currentDate.getFullYear();
    let currentPeriod = currentDate.getMonth() < 6 ? 1 : 2;

    for (let i = 0; i < 10; i++) {
        periods.unshift(`${currentPeriod}/${currentYear}`);

        if (currentPeriod === 1) {
            currentPeriod = 2;
            currentYear--;
        } else {
            currentPeriod = 1;
        }
    }

    return periods;
}

function ReportAdd() {
    const orgId = useOrgId();
    const formId = useId();
    const { trpcUtils, trpc } = useTrpc();
    const { fDateShortDayJs } = useFormatter();
    const navigate = useNavigate();
    const { mutate, isPending } = trpc.report.generateReport.useMutation();

    const [form] = Form.useForm<Report>();
    const report = Form.useWatch(({ report }) => report, form);
    const startDate = Form.useWatch(({ startDate }) => startDate, form);
    const endDate = Form.useWatch(({ endDate }) => endDate, form);

    const { onClose, ...drawerProps } = useOverlayPage({
        form,
        onAfterClose() {
            void trpcUtils.report.invalidate();
            navigate({ to: '/console/$orgId/reporting', params: { orgId } });
        },
    });

    const onFinish = debounce((values: Report) => {
        message.loading('Saving...');

        mutate(values, {
            onSuccess() {
                message.success('Saved.');
                onClose();
            },
        });
    });

    return (
        <Drawer
            onClose={onClose}
            width={500}
            title="Generate a new report"
            footer={
                <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form={formId} loading={isPending}>
                        Save
                    </Button>
                </>
            }
            {...drawerProps}
        >
            <Form id={formId} layout="vertical" form={form} onFinish={onFinish}>
                <FormItem label="Report" name="report" rules={[{ required: true }]}>
                    <Select
                        options={[
                            {
                                label: 'Complaints',
                                options: [
                                    {
                                        value: REPORT_TYPES.COMPLAINTS,
                                        label: 'Complaints',
                                    },
                                    {
                                        value: REPORT_TYPES.COMPLAINTS_ASIC_2023,
                                        label: 'Complaints - ASIC 2023',
                                    },
                                ],
                            },
                            {
                                label: 'Incidents',
                                options: [
                                    {
                                        value: REPORT_TYPES.INCIDENTS,
                                        label: 'Incidents',
                                    },
                                ],
                            },
                            {
                                label: 'Staff training',
                                options: [
                                    {
                                        value: REPORT_TYPES.GENERAL_STAFF_TRAINING_RECORD,
                                        label: 'General staff training record',
                                    },
                                ],
                            },
                        ]}
                    />
                </FormItem>
                {report === REPORT_TYPES.COMPLAINTS_ASIC_2023 && (
                    <FormItem label="Reporting period" required name="asicReportingPeriod" rules={[{ required: true }]}>
                        <Select
                            options={generateLastTenPeriodsForAsicReport()
                                .map((item) => ({ value: item }))
                                .reverse()}
                        />
                    </FormItem>
                )}
                {[REPORT_TYPES.COMPLAINTS, REPORT_TYPES.INCIDENTS, REPORT_TYPES.GENERAL_STAFF_TRAINING_RECORD].includes(
                    report as any,
                ) && (
                    <>
                        <FormItem label="Start date" name="startDate" rules={[{ required: true }]}>
                            <DatePicker className="w-full" format={fDateShortDayJs} maxDate={endDate} />
                        </FormItem>
                        <Form.Item label="End date" name="endDate" rules={[{ required: true }]}>
                            <DatePicker className="w-full" format={fDateShortDayJs} minDate={startDate} />
                        </Form.Item>
                    </>
                )}
                {report && (
                    <Form.Item label="Handling parties" name="handlingParties" rules={[{ required: true }]}>
                        <HandlingPartySelect autoSelectAll forAdminShowAllOrgs />
                    </Form.Item>
                )}
            </Form>
        </Drawer>
    );
}
