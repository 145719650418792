import { DocumentLibrary } from '@client/components/Common/Documents/DocumentLibrary';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { trpc } from '@client/trpc/client';
import { COI_DETAILS_SECTIONS, CoiDetailsSection } from '@shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';
import { Skeleton, Tabs } from 'antd';
import { CoiDetailsManage } from './CoiDetailsManage';
import { conflictOfInterestFormStore } from './store';

export const Route = createFileRoute('/console/$orgId/compliance/coi/details/$id/$section')({
    staticData: {
        breadcrumb: {
            title: 'Conflict of interest details',
        },
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as CoiDetailsSection,
            };
        },
    },
    component: CoiDetails,
});

function CoiDetails() {
    const orgId = useOrgId();
    const { id, section } = Route.useParams();
    const trpcClient = useTrpcClient();
    const updateConflictOfInterest = trpc.conflictOfInterest.updateConflictOfInterest.useMutation();
    const navigate = useNavigate();

    const { formData: conflictOfInterest } = conflictOfInterestFormStore.useInit({
        entity: 'conflict of interest',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.loading('Saving...');

                await updateConflictOfInterest.mutateAsync({
                    id: currentValue.id,
                    set: newValue,
                });

                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.conflictOfInterest.getConflictOfInterest.query({
                    id,
                });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(e) {
            errorMessage.show(e);
        },
    });

    return id === conflictOfInterest?.id ? (
        <Tabs
            type="line"
            activeKey={section}
            onTabClick={(key) => {
                navigate({
                    to: '/console/$orgId/compliance/coi/details/$id/$section',
                    params: {
                        orgId,
                        id,
                        section: key as CoiDetailsSection,
                    },
                });
            }}
            items={[
                {
                    key: COI_DETAILS_SECTIONS.MANAGE,
                    label: 'Manage',
                    children: <CoiDetailsManage />,
                },
                {
                    key: COI_DETAILS_SECTIONS.LIBRARY,
                    label: 'Library',
                    children: (
                        <DocumentLibrary entityId={Number(conflictOfInterest.id)} entityType="conflictOfInterest" />
                    ),
                },
            ]}
        />
    ) : (
        <Skeleton active />
    );
}
