type InternalName = string;

export type SystemTask = {
    internalName: InternalName;
    title: string;
    relatedEntity: 'obligation' | 'complaint' | 'risk' | 'control' | 'test';
    description: string;
};

export const SYSTEM_TASKS = {
    new_complaint: {
        internalName: 'new_complaint',
        title: 'Publish new obligation in your obligations register',
        relatedEntity: 'obligation',
        description:
            'A new obligation has been added to your obligations register from the Curium Obligations Library. It is currently in draft mode and requires further configuration by you.Also, it may have some suggested controls that you could implement to ensure this obligation is fulfilled by your organisation so be sure to pay close attention to this and add configure any controls you deem necessary.',
    },
    replacement_obligation: {
        internalName: 'replacement_obligation',
        title: 'Publish replacement obligation in your obligations register',
        relatedEntity: 'obligation',
        description:
            'A legislation, regulation or contract has changed. This new obligation will replace the old one on {effectiveDate} (of new obligation). it’s time to publish this obligation. The old obligation will automatically expire on {{effectiveToDate}} (of old obligation).Original obligation: {originalObligationLink} New obligation: {{newObligationLink}}',
    },
    add_control_to_obligation: {
        internalName: 'add_control_to_obligation',
        title: 'Add a control to an obligation',
        relatedEntity: 'obligation',
        description:
            'This obligation does not have any controls. Add at least one control from the controls register or create a new one.',
    },
    add_control_to_risk: {
        internalName: 'add_control_to_risk',
        title: 'Add a control to a risk',
        relatedEntity: 'risk',
        description:
            'This risk does not have any controls. Add at least one control from the controls register or create a new one.',
    },
    publish_control: {
        internalName: 'publish_control',
        title: 'Publish control',
        relatedEntity: 'control',
        description:
            'This control is currently in draft status. Publish this control, ensuring you have scheduled tests associated with it.',
    },
    add_test_to_control: {
        internalName: 'add_test_to_control',
        title: 'Add test to control',
        relatedEntity: 'control',
        description:
            'This control does not currently have any scheduled control tests. Add a new test and/or schedule a test with a due date to ensure this control remains effective.',
    },
    rerun_failed_test: {
        internalName: 'rerun_failed_test',
        title: 'Re-run failed test',
        relatedEntity: 'test',
        description:
            "This test failed in it's latest run. In order to ensure that the associated control remains effective you should make any necessary changes and re-run the test to ensure it passes.",
    },
    reconfigure_risk_matrix: {
        internalName: 'reconfigure_risk_matrix',
        title: 'Reconfigure risk matrix',
        relatedEntity: 'risk',
        description:
            'The risk matrix has been changed. You need to re-assess all existing risks that are currently configured with the old matrix according to the new matrix.',
    },
} as const;

export const TASK_STATUSES = {
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    OVERDUE: 'OVERDUE',
    DUE_SOON: 'DUE_SOON',
    NOT_DUE: 'NOT_DUE',
    DONE: 'DONE',
} as const;

export type TaskStatus = keyof typeof TASK_STATUSES;

export const TASK_DUE_DATE_SETTINGS = {
    DUE_SOON_DAYS_COUNT: 3,
} as const;

export const TASK_RELATED_ENTITIES = [
    'obligation',
    'complaint',
    'incident',
    'control',
    'controlTest',
    'risk',
    'claim',
] as const;

export type TaskRelatedEntity = (typeof TASK_RELATED_ENTITIES)[number];
