import { CuriumErrorCode } from '@shared/definitions/errorCode';
import { TRPCClientError } from '@trpc/client';

export class RecordNotFoundError extends Error {
    constructor() {
        super('Record not found');
    }
}

/**
 * Check if a TRPCClientError has a specific domain code
 * @param error - The TRPCClientError to check
 * @param code - The domain code to check for
 * @returns True if the error has the specified domain code, false otherwise
 */
export function isTRPCErrorWithCode(error: unknown, code: CuriumErrorCode) {
    return error instanceof TRPCClientError && error.data?.domainCode === code;
}
