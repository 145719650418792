import { Tabs } from 'antd';
import { ConfigurationExpenses } from './Expenses';
import { ConfigurationBilling } from './Billing';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/expenses-and-billing')({
    staticData: {
        breadcrumb: {
            title: 'Expenses & billing',
        },
        ui: {
            title: 'Expenses & billing configuration',
        },
        modules: ['billing'],
    },
    component: ConfigurationExpensesAndBilling,
});

function ConfigurationExpensesAndBilling() {
    return (
        <Tabs
            type="line"
            items={[
                {
                    key: 'expenses',
                    label: 'Expenses',
                    children: <ConfigurationExpenses />,
                },
                {
                    key: 'billing',
                    label: 'Billing offices',
                    children: <ConfigurationBilling />,
                },
            ]}
        />
    );
}
