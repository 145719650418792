import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useOrgId } from '@client/hooks/Org/useOrgId';

export const Route = createFileRoute('/console/$orgId/risk/')({
    component() {
        const orgId = useOrgId();

        return <Navigate to="/console/$orgId/risk/ras" params={{ orgId }} />;
    },
});
