import { BarChartOutlined } from '@ant-design/icons';
import { DatePicker } from '@client/components/Common/DatePicker';
import { FormField } from '@client/components/Common/FormField';
import { HandlingPartySelect } from '@client/components/Common/HandlingPartySelect';
import { Link } from '@client/components/Common/Link';
import { NewTabLink } from '@client/components/Common/NewTabLink';
import { User } from '@client/components/Common/User';
import { UserSelectorMultiple } from '@client/components/Common/UserSelectorMultiple';
import { ComplaintIssueSelect } from '@client/components/Complaint/ComplaintIssueSelect';
import { OutcomeSelector } from '@client/components/Complaint/OutcomeSelector';
import { ProductSelector } from '@client/components/Complaint/ProductSelector';
import { Filters } from '@client/components/Filters';
import { FlagAutoComplete } from '@client/components/Flag/FlagAutoComplete';
import { Alerts } from '@client/components/Indicator/Alerts';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFilterNavigationItem } from '@client/hooks/useFilterNavigationItem';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { STATUSES } from '@shared/definitions/complaint';
import { ComplaintSources } from '@shared/types/complaint';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Col, Form, Input, InputNumber, Row, Select, Table, Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { listComplaintsInputSchema } from 'server/schemas/complaint';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/')({
    staticData: { ui: { title: 'Complaints', subtitle: 'Create, track, and manage complaints' } },
    component: ComplaintsSearch,
});

type Complaint = RouterOutputs['complaint']['listComplaints']['rows'][number];

type Filters = {
    id: number | undefined;
    complainantName: string | undefined;
    issues: string[] | undefined;
    products: string[] | undefined;
    status: string[] | undefined;
    outcomes: string[] | undefined;
    dtReceivedRange: [Date | null, Date | null] | null;
    flag: string | undefined;
    handlingPartyIds: number[] | undefined;
    ownerIds: bigint[] | undefined;
    source: string | undefined;
    externalClaimId: string | undefined;
};

const FormItem = Form.Item<Filters>;

function ComplaintsSearch() {
    const orgId = useOrgId();
    const { formatDateTime } = useFormatter();
    const filterNavigationItem = useFilterNavigationItem();
    const navigate = useNavigate();

    const [queryParams, setQueryParams] = useZodState(listComplaintsInputSchema);
    const { data, isLoading } = trpc.complaint.listComplaints.useQuery(queryParams);

    const [form] = Form.useForm<Filters>();
    const ownerIds = Form.useWatch<Filters['ownerIds']>('ownerIds', form);
    const handlingPartyIds = Form.useWatch<Filters['handlingPartyIds']>('handlingPartyIds', form);

    const { tableProps } = useAntdTable<Complaint>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setQueryParams((prev) => ({
                ...prev,
                limit: options?.limit ?? prev.limit,
                offset: options?.offset ?? prev.offset,
                orderBy: options?.orderBy ?? prev.orderBy,
            }));
        },
        columns: [
            {
                key: 'indicator',
                render: (_, item) => <Alerts id={BigInt(item.id)} entity="complaint" key={item.id.toString()} />,
            },
            { title: 'Complaint#', dataIndex: 'id', sorter: true },
            {
                title: 'Owner',
                dataIndex: 'Owner.name',
                sorter: true,
                render: (_, item) => (
                    <User userId={item.Owner?.userId} name={item.Owner?.fullName} photoUrl={item.Owner?.photoUrl} />
                ),
            },
            {
                title: 'Complainant',
                dataIndex: 'complainantLastName',
                sorter: true,
                render: (_, item) => `${item.complainantFirstName} ${item.complainantLastName}`,
            },
            { title: 'Policy#', dataIndex: 'policyNo', sorter: true },
            {
                title: 'Issues',
                dataIndex: 'issues',
                sorter: true,
                render: (value?: string[]) =>
                    value?.map?.((item, i) => (
                        <Tag color="volcano" key={i}>
                            {item}
                        </Tag>
                    )),
            },
            {
                title: 'Products',
                dataIndex: 'products',
                sorter: true,
                render: (value?: string[]) =>
                    value?.map?.((item) => (
                        <Tag color="blue" key={item}>
                            {item}
                        </Tag>
                    )),
            },
            { title: 'Status', dataIndex: 'status', sorter: true, render: (value) => value && <Tag>{value}</Tag> },
            {
                title: 'Outcomes',
                dataIndex: 'outcomes',
                sorter: true,
                render: (value?: string[]) =>
                    value?.map((item) => (
                        <Tag color={clsx(item === 'Rejected' && 'red', item === 'Accepted' && 'green')} key={item}>
                            {item}
                        </Tag>
                    )),
            },
            {
                title: 'Date registered',
                dataIndex: 'createdAt',
                sorter: true,
                render: (value) => formatDateTime(value),
            },
            { title: 'Received on', dataIndex: 'dtReceived', sorter: true, render: (value) => formatDateTime(value) },
            { title: 'Claim#', render: (_, item) => item.claimId || item.externalClaimId },
            { title: 'Source', dataIndex: 'source', sorter: true },
            {
                ...commonActionColumn,
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/compliance/complaints/details/$id/manage"
                        params={{ orgId, id: value }}
                    />
                ),
            },
        ],
        paginationConfig: { defaultPageSize: 10, hideOnSinglePage: true, showSizeChanger: true },
    });

    const onSearch = () => {
        const values = form.getFieldsValue();
        const conditions: NonNullable<typeof queryParams.where>[] = [];

        if (values.id) {
            conditions.push({ id: values.id });
        }

        if (values.complainantName) {
            const complainantNames = values.complainantName.split(' ');

            conditions.push({
                OR: [
                    {
                        OR: complainantNames.map((item) => ({
                            complainantFirstName: { contains: item, mode: 'insensitive' },
                        })),
                    },
                    {
                        OR: complainantNames.map((item) => ({
                            complainantLastName: { contains: item, mode: 'insensitive' },
                        })),
                    },
                ],
            });
        }

        if (values.issues?.length) {
            conditions.push({ OR: values.issues.map((item) => ({ issues: { array_contains: item } })) });
        }

        if (values.products?.length) {
            conditions.push({ OR: values.products.map((item) => ({ products: { array_contains: item } })) });
        }

        if (values.status?.length) {
            conditions.push({ status: { in: values.status } });
        }

        if (values.outcomes?.length) {
            conditions.push({ OR: values.outcomes.map((item) => ({ outcomes: { array_contains: item } })) });
        }

        if (values.dtReceivedRange?.[0]) {
            conditions.push({
                dtReceived: {
                    gte: DateTime.fromJSDate(values.dtReceivedRange[0]).startOf('day').toJSDate(),
                },
            });
        }

        if (values.dtReceivedRange?.[1]) {
            conditions.push({
                dtReceived: {
                    lte: DateTime.fromJSDate(values.dtReceivedRange[1]).endOf('day').toJSDate(),
                },
            });
        }

        if (values.flag) {
            conditions.push({ Flags: { some: { labelActive: { contains: values.flag, mode: 'insensitive' } } } });
        }

        if (values.handlingPartyIds?.length) {
            conditions.push({ HandlingParties: { some: { handlingPartyId: { in: values.handlingPartyIds } } } });
        }

        if (values.ownerIds?.length) {
            conditions.push({ Owner: { userId: { in: values.ownerIds } } });
        }

        if (values.source) {
            conditions.push({ source: values.source });
        }

        if (values.externalClaimId) {
            conditions.push({ externalClaimId: { contains: values.externalClaimId, mode: 'insensitive' } });
        }

        console.log({ AND: conditions });

        setQueryParams((prev) => ({
            ...prev,
            where: conditions.length ? { AND: conditions } : undefined,
        }));
    };

    return (
        <>
            <PageLayout.Header
                features={[
                    {
                        feature: 'filters',
                        form,
                        onSearch,
                        children: (
                            <>
                                <Filters.Item>
                                    <FormItem label="Complaint#" name="id">
                                        <InputNumber className="w-full" min="0" />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Complainant name" name="complainantName">
                                        <Input />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Issue" name="issues">
                                        <ComplaintIssueSelect />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Product" name="products">
                                        <ProductSelector />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Status" name="status">
                                        <Select
                                            options={STATUSES.map(({ label }) => ({ value: label }))}
                                            mode="multiple"
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Outcome" name="outcomes">
                                        <OutcomeSelector />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <FormItem label="Date received from" name={['dtReceivedRange', 0]}>
                                                <DatePicker className="w-full" allowClear placeholder="" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Date received to" name={['dtReceivedRange', 1]}>
                                                <DatePicker className="w-full" allowClear placeholder="" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Flag" name="flag">
                                        <FlagAutoComplete filterOption allowClear />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Handling parties" name="handlingPartyIds">
                                        <HandlingPartySelect forAdminShowAllOrgs />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Owner" name="ownerIds">
                                        <UserSelectorMultiple
                                            value={ownerIds || []}
                                            handlingPartyIds={handlingPartyIds}
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Source" name="source">
                                        <Select
                                            options={[
                                                { label: '-', value: '' },
                                                ...Object.values(ComplaintSources).map((item) => ({ value: item })),
                                            ]}
                                        />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Claim#" name="externalClaimId">
                                        <Input className="w-full" min="0" />
                                    </FormItem>
                                </Filters.Item>
                            </>
                        ),
                        columns: 3,
                    },
                ]}
                rightAddon={
                    filterNavigationItem({ to: '/console/$orgId/dashboard/$section' }) && (
                        <Tooltip title="Dashboard">
                            <Link
                                to="/console/$orgId/dashboard/$section"
                                params={{ orgId, section: 'complaints' }}
                                type="text"
                                icon={<BarChartOutlined />}
                                className="text-base"
                            />
                        </Tooltip>
                    )
                }
                primaryActionAddon={
                    <Link.Add
                        to="/console/$orgId/compliance/complaints/add"
                        params={{ orgId }}
                        label="New complaint"
                        type="primary"
                    />
                }
            />

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    expandable={{
                        expandedRowRender: (item) => (
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <FormField
                                        label="Flags"
                                        disableFormatting
                                        value={
                                            <ul>
                                                {item.Flags.map(({ id, labelActive }) => (
                                                    <li key={id}>{labelActive}</li>
                                                ))}
                                            </ul>
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormField
                                        label="Handling parties"
                                        disableFormatting
                                        value={
                                            <ul>
                                                {item.HandlingParties.map((el) => (
                                                    <li key={el.handlingPartyId}>{el.HandlingParty?.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    />
                                </Col>
                            </Row>
                        ),
                        rowExpandable: (item) => !!item.Flags.length || !!item.HandlingParties.length,
                    }}
                    {...tableProps}
                    onRow={({ id }) => ({
                        onClick: (e) => {
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/complaints/details/$id/manage',
                                    params: { orgId, id },
                                },
                                e,
                            );
                        },
                        className: 'cursor-pointer',
                    })}
                />
            </Card>
        </>
    );
}
