import { Chart } from '@antv/g2';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterOutputs } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Spin } from 'antd';
import { useEffect, useRef } from 'react';

export const Route = createFileRoute('/console/$orgId/configuration/billing/usage')({
    component: Usage,
});

type Usage = RouterOutputs['billing']['getUsageByDate'];

function Usage() {
    const { trpc } = useTrpc();
    const chartRef = useRef<HTMLDivElement>(null);

    // Setup transactions query
    const { data: transactionsData, isLoading: transactionsLoading } = trpc.billing.getUsageByDate.useQuery({
        days: 30,
    });

    // TODO: use useChart hook
    useEffect(() => {
        if (!chartRef.current || !transactionsData || transactionsLoading) return;

        // Clear previous chart instance
        chartRef.current.innerHTML = '';

        // Create the chart
        const chart = new Chart({
            container: chartRef.current,
            autoFit: true,
            height: 400,
        });

        // Configure the chart
        chart
            .interval()
            .data(transactionsData)
            .encode('x', 'date')
            .encode('y', 'amount')
            .axis('y', {
                title: 'Amount ($)',
                grid: true,
            })
            .axis('x', {
                title: 'Date',
                tickCount: 7,
            })
            .style('maxWidth', 20)
            .animate('enter', { type: 'fadeIn' });

        // Render the chart
        chart.render();

        // Cleanup function
        return () => {
            chart.destroy();
        };
    }, [transactionsData, transactionsLoading]);

    return (
        <Card title="Usage in last 30 days">
            {transactionsLoading ? (
                <div className="flex h-[400px] items-center justify-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div ref={chartRef} />
            )}
        </Card>
    );
}
