import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { LoadingOverlay } from '@client/components/Common/LoadingOverlay';
import { notification } from '@client/components/Common/Notification';
import { VFieldLayout, VFieldLayoutProps } from '@client/components/Common/ViewForm/VFieldLayout';
import { useClickOutsideDetector } from '@client/hooks/useClickOutsideDetector';
import { DefaultFormData, Field, FormStore, FormStoreInner } from '@client/stores/FormStore/types';
import { Button, Form, Modal, Space, Spin } from 'antd';
import { Rule } from 'antd/es/form';
import { FormInstance } from 'antd/lib';
import clsx from 'clsx';
import { ReactNode, useEffect, useRef, useState } from 'react';

export type InputWrapperRef = {
    save: () => Promise<void>;
};

// TODO: use readonly from formStore.use.useReadonly() by default, not from props

export type VInputProps<TFormData extends DefaultFormData> = Pick<
    VFieldLayoutProps,
    'readonly' | 'layout' | 'icon' | 'disableTooltip' | 'customTooltip' | 'helpMessage'
> & {
    field: Field<TFormData>;
    label: string;
    rules?: Rule[];
    className?: string;
    onSave?: (
        value: any,
        formStoreActions: Pick<FormStoreInner<TFormData>, 'save' | 'getFieldValue' | 'cancelEditing' | 'stopEditing'>,
        formInstance: FormInstance<TFormData>,
    ) => Promise<void>;
    valueOverride?: unknown;
    formStore: FormStore<TFormData>;
    formatValueFn?: (value: any, item: TFormData) => ReactNode;
    formatInputValueFn?: (value: any) => string;
    additionalFields?: string[];
    buttonsPosition?: 'right' | 'bottom' | 'hidden';
    editInModal?: boolean;
    editModal?: {
        title?: string;
        width?: number;
    };
    layoutClassNames?: VFieldLayoutProps['classNames'];
};

type Props<TFormData extends DefaultFormData> = VInputProps<TFormData> & {
    formOverride?: FormInstance;
    permanentEditMode?: boolean;
    /**
     * An input control
     */
    children?: ReactNode;
    addonAfter?: ReactNode;
    /**
     * A function that moves the focus to the input control
     */
    focusFn: () => void;
    hideSaveButton?: boolean;
    hideCancelButton?: boolean;
    /**
     * Container ID for rendering input popup (e.g. for Select control). This is important for the blur detection.
     */
    containerId?: string;
    innerRef?: (ref: InputWrapperRef) => void;
    useEditPopover?: boolean;
    // Useful for list inputs
    useAntdErrorMessages?: boolean;
    loading?: boolean;
    beforeCancelEditing?: () => void;
};

export function InputWrapper<TFormData extends DefaultFormData = DefaultFormData>({
    readonly,
    field,
    label,
    rules,
    className,
    icon,
    layout = 'vertical',
    onSave,
    focusFn,
    hideSaveButton,
    hideCancelButton,
    containerId,
    valueOverride,
    formStore,
    innerRef,
    formatValueFn,
    useEditPopover,
    additionalFields,
    formOverride,
    addonAfter,
    permanentEditMode,
    children,
    formatInputValueFn,
    buttonsPosition = 'bottom',
    useAntdErrorMessages,
    loading,
    helpMessage,
    editInModal,
    editModal,
    disableTooltip,
    customTooltip,
    layoutClassNames,
    beforeCancelEditing,
}: Props<TFormData>) {
    const formData = formStore.use.formData({ throwOnUndefined: false });
    const formStoreField = formStore.use.field?.();
    const getFieldValue = formStore.use.getFieldValue();
    const cancelEditing = formStore.use.cancelEditing();
    const stopEditing = formStore.use.stopEditing();
    const save = formStore.use.save();
    const isFieldEditing = formStore.use.isFieldEditing();
    const setFieldForEditing = formStore.use.setFieldForEditing();
    const [isSaving, setIsSaving] = useState(false);

    const [form] = Form.useForm(formOverride);

    const ref = useRef<InputWrapperRef>({
        async save() {
            setIsSaving(true);
            try {
                await form.validateFields([field]);

                if (form.getFieldValue(field) === getFieldValue(field) && !additionalFields?.length) {
                    beforeCancelEditing?.();
                    cancelEditing();
                    return;
                }

                if (onSave) {
                    await onSave(form.getFieldValue(field), { save, getFieldValue, cancelEditing, stopEditing }, form);
                    return;
                }

                await save({ [field]: form.getFieldValue(field) } as Partial<TFormData>);
            } catch (e) {
                console.error('Validation failed:', e);
            } finally {
                setIsSaving(false);
            }
        },
    });

    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        innerRef?.(ref.current);
    }, []);

    const resetEditing = () => {
        beforeCancelEditing?.();
        cancelEditing();
        form.setFieldValue(field, getFieldValue(field));
        notification.info({ message: 'Editing canceled. The changes were not saved.' });
    };

    useEffect(() => {
        if (isFieldEditing(field) || permanentEditMode) {
            setError(undefined);
            form.resetFields();

            form.setFieldValue(
                field,
                formatInputValueFn ? formatInputValueFn(getFieldValue(field)) : getFieldValue(field),
            );
        }

        if (permanentEditMode) {
            return;
        }

        if (isFieldEditing(field)) {
            setTimeout(() => {
                focusFn();
            }, 100);
        }

        // Handle ESC key
        const handler = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && isFieldEditing(field)) {
                resetEditing();
            }
        };

        document.addEventListener('keydown', handler);

        return () => document.removeEventListener('keydown', handler);
    }, [formStoreField]);

    const editMode = permanentEditMode || isFieldEditing(field);

    const internalRef = useClickOutsideDetector(() => {
        if (permanentEditMode || editInModal) {
            return;
        }

        void ref.current.save();
    });

    const editField = loading ? (
        <Spin />
    ) : editInModal ? (
        <div ref={internalRef} className="relative" id={containerId}>
            <Form.Item
                name={String(field)}
                rules={rules}
                label={label}
                rootClassName={clsx(
                    '[&_.ant-form-item-label]:hidden mb-0',
                    !useAntdErrorMessages && !editInModal && '**:font-normal [&_.ant-form-item-explain]:hidden',
                )}
            >
                {children}
            </Form.Item>
            {addonAfter}
            {error && !useAntdErrorMessages && !editInModal && (
                <div className="absolute left-0 right-[50px] z-50 mt-[1px] text-wrap rounded-md border border-solid border-gray-300 bg-gray-50 p-1 text-xs font-semibold text-red-500 drop-shadow-lg">
                    {error}
                </div>
            )}
        </div>
    ) : (
        <LoadingOverlay loading={isSaving} spinnerPosition="end" blur>
            <div ref={internalRef} className="relative" id={containerId}>
                <Form.Item
                    name={String(field)}
                    rules={rules}
                    label={label}
                    rootClassName={clsx(
                        '[&_.ant-form-item-label]:hidden mb-0',
                        !useAntdErrorMessages && !editInModal && '**:font-normal [&_.ant-form-item-explain]:hidden',
                    )}
                >
                    {children}
                </Form.Item>
                {addonAfter}
                {error && !useAntdErrorMessages && !editInModal && (
                    <div className="absolute left-0 right-[50px] z-50 mt-[1px] text-wrap rounded-md border border-solid border-gray-300 bg-gray-50 p-1 text-xs font-semibold text-red-500 drop-shadow-lg">
                        {error}
                    </div>
                )}
                {buttonsPosition !== 'hidden' && !editInModal && (
                    <Space
                        size={2}
                        className={clsx(
                            'absolute z-50',
                            buttonsPosition === 'bottom' && 'bottom-0 right-0 translate-y-full pt-0.5',
                            buttonsPosition === 'right' && 'right-0 top-1/2 -translate-y-1/2 translate-x-full pl-0.5',
                        )}
                    >
                        {!hideSaveButton && (
                            <Button
                                onClick={() => ref.current.save()}
                                icon={<CheckOutlined />}
                                size="small"
                                className="drop-shadow-xl"
                                disabled={isSaving}
                            />
                        )}
                        {!hideCancelButton && (
                            <Button
                                htmlType="button"
                                icon={<CloseOutlined />}
                                onClick={resetEditing}
                                size="small"
                                className="drop-shadow-xl"
                                disabled={isSaving}
                            />
                        )}
                    </Space>
                )}
            </div>
        </LoadingOverlay>
    );

    const value = formatValueFn
        ? formatValueFn(getFieldValue(field), formData!) || '-'
        : valueOverride
          ? valueOverride
          : getFieldValue(field) || '-';

    return (
        <Form
            className={className}
            form={form}
            onFieldsChange={(changedFields) => setError(changedFields[0].errors?.[0])}
        >
            <VFieldLayout
                label={label}
                editMode={editMode}
                value={value}
                icon={icon}
                readonly={readonly}
                onRequestEdit={() => setFieldForEditing(field)}
                layout={layout}
                useEditPopover={useEditPopover}
                helpMessage={helpMessage}
                disableTooltip={disableTooltip}
                customTooltip={customTooltip}
                classNames={layoutClassNames}
            >
                {editInModal ? (
                    <>
                        <Modal
                            open
                            title={editModal?.title || label}
                            onOk={ref.current.save}
                            okText="Save"
                            onCancel={resetEditing}
                            centered
                            className="max-h-[80vh] overflow-y-auto"
                            width={editModal?.width}
                            confirmLoading={isSaving}
                            cancelButtonProps={{ disabled: isSaving }}
                        >
                            {editField}
                        </Modal>
                        {value}
                    </>
                ) : (
                    editField
                )}
            </VFieldLayout>
            {additionalFields?.map((item) => <Form.Item key={item} name={item} className="hidden" />)}
        </Form>
    );
}
