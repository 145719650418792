import { navRoutes } from '../navigation/navRoutes';
import { generatePath } from '../navigation/utils';
import { S3File, S3FileCollection } from '../types/S3File';

export function filesToFileCollections(files: S3File[] | S3File): S3FileCollection {
    if (!files) return {};
    const fileCollection: S3FileCollection = {};
    const _files = Array.isArray(files) ? files : [files];
    _files.forEach((file) => {
        fileCollection[file.id] = file;
    });
    return fileCollection;
}

export function getExternalUrlForSecuredFile(file: S3File, orgId: number): string {
    return (
        (process.env.APP_BASE_URL || window.location.origin) +
        generatePath(
            navRoutes.getFile,
            {
                orgId: orgId.toString(),
            },
            { key: encodeURIComponent(file.key), name: encodeURIComponent(file.name) },
        )
    );
}

export function formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
}

export function fileExtension(filename: string) {
    const re = /(?:\.([^.]+))?$/;

    const result = re.exec(filename);
    if (result && result.length > 0) {
        return result[1];
    }
    return undefined;
}

export function makeFileNamesUnique<T extends { name: string }>(files: T[]) {
    const map: Record<string, number> = {};

    return files.map((item) => {
        if (map[item.name]) {
            map[item.name] += 1;

            const extensionIndex = item.name.lastIndexOf('.');

            return {
                ...item,
                name: `${
                    extensionIndex === -1 ? item.name : item.name.slice(0, extensionIndex)
                } (${map[item.name]})${extensionIndex === -1 ? '' : item.name.slice(extensionIndex)}`,
            };
        }

        map[item.name] = 1;
        return item;
    });
}
