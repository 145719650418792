import { addBusinessDays } from 'date-fns';
import { DateTime } from 'luxon';
import { TASK_DUE_DATE_SETTINGS } from '../definitions/task';

export function getTaskOverdueDate() {
    return DateTime.now().endOf('day').toJSDate();
}

export function getTaskDueSoonDate() {
    return addBusinessDays(getTaskOverdueDate(), TASK_DUE_DATE_SETTINGS.DUE_SOON_DAYS_COUNT);
}
