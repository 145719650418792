import { TrainingRecordEdit } from '@client/components/Training/TrainingRecordEdit';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/training/search/add')({
    staticData: {
        displayType: 'overlay',
        metadata: {
            title: 'New training record',
        },
    },
    component: TrainingRecordEdit,
});
