import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Link } from '@client/components/Common/Link';
import { UserSelectorMultiple } from '@client/components/Common/UserSelectorMultiple';
import { Filters } from '@client/components/Filters';
import { Tabs } from '@client/components/Navigation/Tabs';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUser } from '@client/hooks/User/useUser';
import { RouterInputs } from '@client/trpc/client';
import { t } from '@client/utils/task';
import { TASK_RELATED_ENTITIES, TASK_STATUSES } from '@shared/definitions/task';
import { Falsy, isTruthy } from '@shared/utils/boolean';
import { getTaskDueSoonDate, getTaskOverdueDate } from '@shared/utils/task';
import { createFileRoute, useLocation } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Form, Select } from 'antd';
import { z } from 'zod';

const filtersSchema = z
    .object({
        assignedToIds: z.array(z.coerce.bigint()).catch([]),
        relatedEntities: z.array(z.union([z.enum(TASK_RELATED_ENTITIES), z.null()])).catch([]),
        statuses: z.array(z.enum([TASK_STATUSES.OVERDUE, TASK_STATUSES.DUE_SOON, TASK_STATUSES.NOT_DUE])).catch([]),
    })
    .partial();

export const Route = createFileRoute('/console/$orgId/workload-manager/tasks')({
    validateSearch: zodValidator(filtersSchema),
    component: Tasks,
});

export function useGetWorkloadManagerTaskFilters() {
    const { assignedToIds, relatedEntities, statuses } = Route.useSearch();

    const filters: (Falsy | RouterInputs['task']['listTasks']['where'])[] = [
        assignedToIds?.length && { assignedToId: { in: assignedToIds } },
        relatedEntities?.length && {
            OR: [
                ...(relatedEntities.includes(null)
                    ? ([{ relatedEntity: null }] satisfies RouterInputs['task']['listTasks']['where'][])
                    : []),
                ...(relatedEntities.filter((item) => item !== null).length
                    ? ([
                          { relatedEntity: { in: relatedEntities.filter((item) => item !== null) } },
                      ] satisfies RouterInputs['task']['listTasks']['where'][])
                    : []),
            ],
        },
        statuses?.length && {
            OR: [
                ...(statuses.includes('OVERDUE') ? [{ dueDate: { lte: getTaskOverdueDate() } }] : []),
                ...(statuses.includes('DUE_SOON')
                    ? [{ dueDate: { gt: getTaskOverdueDate(), lte: getTaskDueSoonDate() } }]
                    : []),
                ...(statuses.includes('NOT_DUE') ? [{ dueDate: { gt: getTaskDueSoonDate() } }] : []),
            ],
        },
    ];

    return { AND: filters.filter(isTruthy) } satisfies RouterInputs['task']['listTasks']['where'];
}

type FiltersForm = z.infer<typeof filtersSchema>;

const FormItem = Form.Item<FiltersForm>;

function Tasks() {
    const orgId = useOrgId();
    const user = useUser();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [form] = Form.useForm<FiltersForm>();

    return (
        <Tabs
            features={[
                {
                    feature: 'filters',
                    form,
                    onSearch(values) {
                        navigate({
                            to: '.',
                            params: { orgId },
                            search(prev) {
                                return {
                                    ...prev,
                                    assignedToIds: values?.assignedToIds,
                                    relatedEntities: values?.relatedEntities,
                                    statuses: values?.statuses,
                                };
                            },
                        });
                    },
                    children: (
                        <>
                            {user.isOrgAdmin && (
                                <Filters.Item>
                                    <FormItem label="Assignee" name="assignedToIds">
                                        <UserSelectorMultiple />
                                    </FormItem>
                                </Filters.Item>
                            )}
                            <Filters.Item>
                                <FormItem label="Type" name="relatedEntities">
                                    <Select
                                        options={[
                                            ...TASK_RELATED_ENTITIES.map((item) => ({
                                                label: t.TASK_RELATED_ENTITIES[item],
                                                value: item,
                                            })),
                                            { label: t.TASK_RELATED_ENTITIES.null, value: null },
                                        ]}
                                        mode="multiple"
                                    />
                                </FormItem>
                            </Filters.Item>
                            <Filters.Item>
                                <FormItem label="Status" name="statuses">
                                    <Select
                                        options={[
                                            TASK_STATUSES.OVERDUE,
                                            TASK_STATUSES.DUE_SOON,
                                            TASK_STATUSES.NOT_DUE,
                                        ].map((item) => ({ label: t.TASK_STATUSES[item], value: item }))}
                                        mode="multiple"
                                    />
                                </FormItem>
                            </Filters.Item>
                        </>
                    ),
                },
            ]}
            primaryActionAddon={
                <Link.Add
                    to={`${pathname}/add` as any}
                    search={((prev: any) => prev) as any}
                    label="New task"
                    type="primary"
                />
            }
            type="inner"
            items={[
                {
                    icon: <UnorderedListOutlined />,
                    label: 'List view',
                    to: '/console/$orgId/workload-manager/tasks/list',
                    onNavigate(options, e) {
                        navigate(
                            {
                                to: '/console/$orgId/workload-manager/tasks/list',
                                params: { orgId },
                                search(prev) {
                                    return prev;
                                },
                                ...options,
                            },
                            e,
                        );
                    },
                },
                {
                    icon: <CalendarOutlined />,
                    label: 'Calendar view',
                    to: '/console/$orgId/workload-manager/tasks/calendar',
                    onNavigate(options, e) {
                        navigate(
                            {
                                to: '/console/$orgId/workload-manager/tasks/calendar',
                                params: { orgId },
                                search(prev) {
                                    return prev;
                                },
                                ...options,
                            },
                            e,
                        );
                    },
                },
            ]}
        />
    );
}
