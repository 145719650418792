import { Button } from '@client/components/Common/Button';
import { ComponentPreview } from '@client/components/Common/ComponentPreview';
import { errorMessage } from '@client/components/Common/errorMessage';
import { Highlight } from '@client/components/Common/Highlight';
import { Link } from '@client/components/Common/Link';
import { message } from '@client/components/Common/message';
import { ControlSelect } from '@client/components/Control/ControlSelect';
import { Filters } from '@client/components/Filters';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { CountrySelect } from '@client/components/Obligation/CountrySelect';
import { LegislationSelect } from '@client/components/Obligation/LegislationSelect';
import { SubObligationSelect } from '@client/components/Obligation/SubObligationSelect';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { useUser } from '@client/hooks/User/useUser';
import { useZodState } from '@client/hooks/useZodState';
import { RouterInputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { htmlToText } from '@client/utils/text.ts';
import { OBLIGATION_STATUSES } from '@shared/definitions/obligation';
import { ObligationStatus } from '@shared/types/obligation';
import { Falsy, isTruthy } from '@shared/utils/boolean';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Form, InputNumber, Popconfirm, Select, Space, Table, Tag } from 'antd';
import { listObligationsInputSchema } from 'server/schemas/obligation';

export const Route = createFileRoute('/console/$orgId/compliance/obligations/')({
    staticData: {
        ui: {
            title: 'Obligations',
            subtitle: 'Create, track, and manage obligations',
        },
    },
    component: ObligationSearch,
});

type FiltersForm = {
    order: number;
    countries: string[];
    legislationIds: bigint[];
    sections: string[];
    controlIds: bigint[];
    status: string;
};

const FormItem = Form.Item<FiltersForm>;

function ObligationSearch() {
    const orgId = useOrgId();
    const user = useUser();
    const { search } = Route.useSearch();
    const resetObligationToLibraryVersionMutation = trpc.obligation.resetObligationToLibraryVersion.useMutation();
    const { mutate: deleteObligation } = trpc.obligation.deleteObligation.useMutation();
    const navigate = useNavigate();

    const [input, setInput] = useZodState(listObligationsInputSchema);

    const { data, isPending, refetch } = trpc.obligation.listObligations.useQuery(input);

    const [form] = Form.useForm<FiltersForm>();

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setInput((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? [...options.orderBy, { order: 'desc' }] : prev.orderBy,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'order',
                className: 'align-top',
                sorter: true,
            },
            {
                title: 'Identification code',
                dataIndex: 'identificationCode',
                className: 'align-top',
                sorter: true,
                render: (value, record) => {
                    const idPrefix = record?.Legislation?.idPrefix || '';
                    return `${idPrefix}${value || ''}`;
                },
            },
            {
                title: 'Legislation',
                dataIndex: 'Legislation.name',
                className: 'align-top',
                sorter: true,
                render: (_, { Legislation }) => <Highlight data={Legislation?.name} highlights={[search]} />,
            },
            {
                title: 'Sub obligation',
                dataIndex: 'section',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => (
                    <ComponentPreview>
                        <Highlight data={value} highlights={[search]} />
                    </ComponentPreview>
                ),
            },
            {
                title: 'Core obligation',
                dataIndex: 'coreObligation',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => (
                    <ComponentPreview>
                        <Highlight data={value} highlights={[search]} />
                    </ComponentPreview>
                ),
            },
            {
                title: 'What it means',
                dataIndex: 'whatItMeans',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => (
                    <ComponentPreview>{<Highlight data={htmlToText(value)} highlights={[search]} />}</ComponentPreview>
                ),
            },
            {
                title: 'Federal register of legislation URLs',
                dataIndex: 'federalRegisterOfLegislationURLs',
                className: 'max-w-xs align-top',
                render: (value?: { url: string; name?: string }[]) => (
                    <ComponentPreview noOfLines={3}>
                        {value?.map((link) => (
                            <a
                                key={Math.random().toString(16)}
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className="block"
                            >
                                {link.name || link.url}
                            </a>
                        ))}
                    </ComponentPreview>
                ),
            },
            {
                title: 'Regulator',
                dataIndex: 'regulator',
            },
            {
                title: 'Key controls',
                dataIndex: 'Controls',
                render: (_, { Controls }) => (
                    <Space size={[0, 8]} wrap>
                        {Controls?.map((item) => <Tag key={item.Control.id}>{item.Control.objective}</Tag>)}
                    </Space>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (value: ObligationStatus) => OBLIGATION_STATUSES[value] || value,
            },
            {
                title: 'Applies to',
                render: (_, { HandlingParties }) =>
                    HandlingParties?.map((item) => <Tag key={item.HandlingParty.id}>{item.HandlingParty.name}</Tag>),
            },
            {
                ...commonActionColumn,
                render: (_, { libraryItemId, order, id }) => (
                    <Space>
                        {libraryItemId !== null && (
                            <Popconfirm
                                title={`Are you sure you want to reset obligation #${order} to library version?`}
                                onPopupClick={(e) => e.stopPropagation()}
                                onConfirm={async () => {
                                    try {
                                        await resetObligationToLibraryVersionMutation.mutateAsync({
                                            id,
                                        });

                                        message.success('Obligation reset to library version.');
                                        void refetch();
                                    } catch (e) {
                                        errorMessage.show(e);
                                    }
                                }}
                            >
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Reset
                                </Button>
                            </Popconfirm>
                        )}
                        <Link.NewTab
                            to="/console/$orgId/compliance/obligations/details/$id/$section"
                            params={{
                                orgId,
                                id,
                                section: 'manage',
                            }}
                        />
                        {user?.isOrgAdmin && (
                            <Button.Delete
                                onConfirm={() => {
                                    message.loading('Deleting...');

                                    deleteObligation(
                                        { id },
                                        {
                                            onSuccess() {
                                                message.success('Deleted.');
                                                void refetch();
                                            },
                                        },
                                    );
                                }}
                            />
                        )}
                    </Space>
                ),
            },
        ],
    });

    function onSearch({ searchValue, values }: { searchValue?: string; values?: FiltersForm }) {
        const filters: (Falsy | RouterInputs['obligation']['listObligations']['where'])[] = [
            searchValue && {
                OR: [
                    { section: { contains: searchValue, mode: 'insensitive' } },
                    { coreObligation: { contains: searchValue, mode: 'insensitive' } },
                    { whatItMeans: { contains: searchValue, mode: 'insensitive' } },
                    {
                        Obligation_Control: {
                            some: {
                                Control: {
                                    OR: [
                                        {
                                            Owner: {
                                                OR: [
                                                    {
                                                        User: {
                                                            email: {
                                                                contains: searchValue,
                                                                mode: 'insensitive',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        firstName: {
                                                            contains: searchValue,
                                                            mode: 'insensitive',
                                                        },
                                                    },
                                                    {
                                                        lastName: {
                                                            contains: searchValue,
                                                            mode: 'insensitive',
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            ControlTests: {
                                                some: {
                                                    Tester: {
                                                        OR: [
                                                            {
                                                                User: {
                                                                    email: {
                                                                        contains: searchValue,
                                                                        mode: 'insensitive',
                                                                    },
                                                                },
                                                            },
                                                            {
                                                                firstName: {
                                                                    contains: searchValue,
                                                                    mode: 'insensitive',
                                                                },
                                                            },
                                                            {
                                                                lastName: {
                                                                    contains: searchValue,
                                                                    mode: 'insensitive',
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                ],
            },
            values?.controlIds?.length && { Obligation_Control: { some: { controlId: { in: values.controlIds } } } },
            values?.legislationIds?.length && { legislationId: { in: values.legislationIds } },
            values?.order && { order: values.order },
            values?.countries?.length && { country: { in: values.countries } },
            values?.sections?.length && { section: { in: values.sections } },
            values?.status && { status: values.status },
        ];

        const where: RouterInputs['obligation']['listObligations']['where'] = {
            AND: filters.filter(isTruthy),
        };

        setInput((prev) => ({ ...prev, where }));
    }

    return (
        <>
            <PageLayout.Header
                features={[
                    {
                        feature: 'search',
                        onSearch(value) {
                            onSearch({ searchValue: value });
                        },
                    },
                    {
                        feature: 'filters',
                        form,
                        onSearch(values) {
                            onSearch({ values });
                        },
                        children: (
                            <>
                                <Filters.Item>
                                    <FormItem label="ID" name="order">
                                        <InputNumber className="w-full" />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Country" name="countries">
                                        <CountrySelect mode="multiple" />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Legislation" name="legislationIds">
                                        <LegislationSelect />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Sub Obligation" name="sections">
                                        <SubObligationSelect />
                                    </FormItem>
                                </Filters.Item>

                                <Filters.Item>
                                    <FormItem label="Key Controls" name="controlIds">
                                        <ControlSelect mode="multiple" showSearch />
                                    </FormItem>
                                </Filters.Item>
                                <Filters.Item>
                                    <FormItem label="Status" name="status">
                                        <Select
                                            options={Object.entries(OBLIGATION_STATUSES).map(([key, value]) => ({
                                                label: value,
                                                value: key,
                                            }))}
                                            allowClear
                                        />
                                    </FormItem>
                                </Filters.Item>
                            </>
                        ),
                    },
                ]}
                primaryActionAddon={
                    <Link.Add
                        to="/console/$orgId/compliance/obligations/add"
                        params={{ orgId }}
                        type="primary"
                        label="New obligation"
                    />
                }
            />

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick(e) {
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/obligations/details/$id/$section',
                                    params: {
                                        orgId,
                                        id,
                                        section: 'manage',
                                    },
                                },
                                e,
                            );
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
