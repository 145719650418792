import { createFileRoute, Navigate } from '@tanstack/react-router';

export const Route = createFileRoute('/public/new-complaint/$orgId')({
    component: NewComplaint,
});

function NewComplaint() {
    const { orgId } = Route.useParams();

    return <Navigate to="/public/$orgId/complaint/new" params={{ orgId: BigInt(orgId) }} />;
}
