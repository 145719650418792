import React, { useCallback, useState } from 'react';
import { Spin } from 'antd';

interface Props {
    delay?: number;
    tip?: string;
    wrapperClassName?: string;
    children?: React.ReactNode;
    makeFullHeight?: boolean;
}

interface State {
    size?: 'small' | 'large' | 'default';
    wrapperClassName?: string;
    delay?: number;
    tip?: string;
    spinning?: boolean;
    makeFullHeight?: boolean;
}

export function useSpin(options?: State) {
    const defaults = { ...({ size: 'default', spinning: false, makeFullHeight: false } as State), ...options };
    const [state, setState] = useState<State>({
        size: defaults.size,
        wrapperClassName: defaults.wrapperClassName,
        delay: defaults.delay,
        tip: defaults.tip,
        spinning: defaults.spinning,
        makeFullHeight: defaults.makeFullHeight,
    });

    return {
        Spin: useCallback(
            (props: Props) => {
                const { makeFullHeight, ...rest } = { ...state, ...props };
                if (makeFullHeight) {
                    rest.wrapperClassName = rest.wrapperClassName ?? '' + ' spin-h-full'.trim();
                }
                if (state.spinning) {
                    return <Spin {...rest}>{props.children}</Spin>;
                } else {
                    return <>{props.children}</>;
                }
            },
            [state]
        ),
        isSpinning: state.spinning,
        setSpinning: (spinning: boolean) => {
            setState({
                ...state,
                ...{
                    spinning,
                },
            });
        },
    };
}
