import { router } from '@client/components/Providers/TanstackRouterProvider';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging') {
    Sentry.init({
        dsn: 'https://f9fdd54d90cbb7d44fea170d0f44c6e8@o4508222925242368.ingest.us.sentry.io/4508285831217152',
        ignoreErrors: ['Non-Error promise rejection captured with value:'],
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
            Sentry.browserTracingIntegration(),
            Sentry.httpClientIntegration(),
            Sentry.extraErrorDataIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.httpContextIntegration(),
            Sentry.moduleMetadataIntegration(),
            Sentry.functionToStringIntegration(),
            Sentry.globalHandlersIntegration({ onerror: true, onunhandledrejection: true }),
            Sentry.contextLinesIntegration(),
            Sentry.breadcrumbsIntegration({
                console: true,
                fetch: true,
                history: true,
                sentry: true,
                dom: true,
                xhr: true,
            }),
            Sentry.tanstackRouterBrowserTracingIntegration(router),
        ],
        tracesSampleRate: 0.25,
        environment: import.meta.env.VITE_ENV,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setTag('in-maintenance-mode', false);
}
