import { UserAddOutlined } from '@ant-design/icons';
import { PageContent } from '@client/components/Layout/PageContent';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useFormatter } from '@client/hooks/useFormatter';
import { RouterInputs, trpc } from '@client/trpc/client';
import { USER_SYSTEM_USAGES, UserSystemUsage } from '@shared/definitions/user';
import { createFileRoute } from '@tanstack/react-router';
import { Avatar, Button, Card, Checkbox, Table, Tag } from 'antd';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { EditUser, EditUserAction } from './EditUser';
import { InviteUser } from './InviteUser';

export const Route = createFileRoute('/admin-console/organizations/$orgId/users')({
    staticData: {
        metadata: {
            title: 'Users',
        },
        ui: {
            title: 'Users',
        },
    },
    component: UsersInOrganization,
});

type QueryVariables = RouterInputs['user']['listUsers'];

const DEFAULT_FILTER = {
    AND: [{ userId: { gt: 1 } }, { User: { isSupportAccount: false } }],
} satisfies QueryVariables['where'];

function UsersInOrganization() {
    const { orgId } = Route.useParams();
    const { formatDate } = useFormatter();

    const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false);
    const [userAction, setUserAction] = useState<EditUserAction>(['none']);
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        orgId: BigInt(orgId),
        where: DEFAULT_FILTER,
        orderBy: [{ firstName: 'asc' }],
        limit: 10,
        offset: 0,
    });

    const { data, isLoading, refetch } = trpc.user.listUsers.useQuery({
        orgId,
        where: queryVariables.where,
        orderBy: queryVariables.orderBy,
        limit: queryVariables.limit,
        offset: queryVariables.offset,
    });

    const { tableProps } = useAntdTable({
        rowKey: 'userId',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy,
            }));
        },
        columns: [
            {
                dataIndex: 'userId',
                title: 'ID',
                sorter: true,
                align: 'center',
                fixed: 'left',
                render: (value) => BigInt(value).toString(),
            },
            {
                dataIndex: 'firstName',
                title: 'First name',
                sorter: true,
                fixed: 'left',
            },
            {
                title: 'Last name',
                dataIndex: 'lastName',
                sorter: true,
                fixed: 'left',
            },
            {
                title: 'Position',
                dataIndex: 'position',
                sorter: true,
            },
            {
                dataIndex: 'email',
                title: 'Email',
                sorter: true,
                render: (email) => <a href={`mailto:${email}`}>{email}</a>,
            },
            {
                dataIndex: 'photoUrl',
                title: 'Photo',
                render: (photoUrl) => <Avatar src={photoUrl} />,
            },
            {
                dataIndex: 'disabled',
                title: 'Disabled',
                sorter: true,
                render: (value) => <Checkbox checked={!!value} />,
                align: 'center',
            },
            {
                dataIndex: 'canViewUnallocatedClaims',
                title: 'View unallocated claims',
                sorter: true,
                render: (value) => <Checkbox checked={!!value} />,
                align: 'center',
            },
            {
                dataIndex: 'systemUsage',
                title: 'Primary use of the system',
                sorter: true,
                render: (value) => USER_SYSTEM_USAGES[value as UserSystemUsage] || value,
            },
            {
                dataIndex: 'roles',
                title: 'Roles',
                align: 'center',
                render: (value) => (
                    <>
                        {(Array.isArray(value) ? value : []).map((el) => (
                            <Tag key={el}>{el}</Tag>
                        ))}
                    </>
                ),
            },
            {
                title: 'Last login',
                dataIndex: 'lastLoginAt',
                sorter: true,
                render: (value) => formatDate(value, 'date-time'),
            },
            {
                dataIndex: 'createdAt',
                title: 'Created',
                render: (value) => formatDate(value, 'date-time'),
            },
        ],
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    const onSearch = (searchValue: string) => {
        const where: QueryVariables['where'] = { OR: [] } as const;
        invariant(where.OR, 'where.OR is not defined');

        if (searchValue) {
            where.OR.push({ firstName: { contains: `%${searchValue}%` } });
            where.OR.push({ lastName: { contains: `%${searchValue}%` } });
            where.OR.push({ User: { email: { contains: `%${searchValue}%` } } });
        }

        setQueryVariables((prev) => ({
            ...prev,
            where: {
                AND: [...DEFAULT_FILTER.AND, ...(where.OR?.length ? [where] : [])],
            },
        }));
    };

    return (
        <PageContent>
            <PageLayout.Header
                features={[
                    {
                        feature: 'search',
                        onSearch,
                    },
                ]}
                primaryActionAddon={
                    <Button type="primary" icon={<UserAddOutlined />} onClick={() => setOpenInviteUserDialog(true)}>
                        Invite user
                    </Button>
                }
            />

            <InviteUser
                open={openInviteUserDialog}
                orgId={orgId}
                onClose={() => {
                    setOpenInviteUserDialog(false);
                    void refetch();
                }}
            />
            <EditUser
                action={userAction}
                orgId={orgId}
                onClose={() => {
                    setUserAction(['none']);
                    void refetch();
                }}
            />
            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    {...tableProps}
                    size="small"
                    onRow={(item) => ({
                        className: 'cursor-pointer',
                        onClick() {
                            setUserAction(['edit', item.userId]);
                        },
                    })}
                />
            </Card>
        </PageContent>
    );
}
