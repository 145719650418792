import { RouterOutputs } from '@client/trpc/client';
import { getTaskStatusUI } from '@client/utils/task';
import { Tag } from 'antd';
import clsx from 'clsx';

export function TaskStatus({
    data,
    size = 'middle',
}: {
    data: Pick<RouterOutputs['task']['getTask'], 'dueDate' | 'isCompleted'>;
    size?: 'middle' | 'large';
}) {
    const { label, color } = getTaskStatusUI(data);

    return (
        <Tag className={clsx(size === 'large' && 'rounded-md px-2 py-0.5 text-lg')} color={color}>
            {label}
        </Tag>
    );
}
