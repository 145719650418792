import { message } from '@client/components/Common/message';
import { VComplaintBrandSelector } from '@client/components/Common/ViewForm/VComplaintBrandSelector';
import { VDatePicker } from '@client/components/Common/ViewForm/VDatePicker';
import { VDropdown } from '@client/components/Common/ViewForm/VDropdown';
import { VHandlingPartySelect } from '@client/components/Common/ViewForm/VHandlingPartySelect';
import { VNumberInput } from '@client/components/Common/ViewForm/VNumberInput';
import { VPreferredChannelSelect } from '@client/components/Common/ViewForm/VPreferredChannelSelect';
import { VReadOnly } from '@client/components/Common/ViewForm/VReadOnly';
import { VSwitch } from '@client/components/Common/ViewForm/VSwitch';
import { VTextArea } from '@client/components/Common/ViewForm/VTextArea';
import { VTextInput } from '@client/components/Common/ViewForm/VTextInput';
import { VUserSelector } from '@client/components/Common/ViewForm/VUserSelector';
import { VComplaintIssueSelector } from '@client/components/Complaint/ViewForm/VComplaintIssueSelector';
import { TaskList } from '@client/components/Task/TaskList';
import { ManageTimeline } from '@client/components/Timeline/ManageTimeline';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useFormatter } from '@client/hooks/useFormatter';
import { requiredFieldRule } from '@client/utils/form';
import {
    AGES,
    CHANNELS,
    COMPLAINANT_SOLUTIONS,
    COMPLAINANT_TYPES,
    GENDERS,
    OUTCOMES,
    PRODUCTS,
    RELATED_TO_AFS_LICENSEE,
    RELATIONSHIPS,
} from '@shared/definitions/complaint';
import { COMPLAINANT_TYPE } from '@shared/types/complaint';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Card, Col, Row, Space, Tag } from 'antd';
import { useEffect } from 'react';
import { useCanEditComplaint } from '../hooks';
import { complaintFormStore } from '../store';
import { ManageFlags } from './ManageFlags';
import { ManageNotes } from './ManageNotes';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/details/$id/manage')({
    component: ComplaintDetailsManage,
});

function ComplaintDetailsManage() {
    const complaint = complaintFormStore.use.formData();
    const canEdit = useCanEditComplaint();
    const setFieldForEditing = complaintFormStore.use.setFieldForEditing();
    const { formatDate, fDateShortDayJs, formatCurrency } = useFormatter();

    const { data: brand } = useConfigServiceLoader((configService) => configService.fetchComplaintBrandConfiguration());

    useEffect(() => {
        if (!complaint.complainantGender && complaint.complainantType === COMPLAINANT_TYPE.INDIVIDUAL) {
            message.info('Please select gender.');
            setFieldForEditing('complainantGender');
        }
    }, [complaint.complainantType]);

    useEffect(() => {
        if (complaint.outcomes?.includes('Decision changed') && !complaint.monetaryRemedy) {
            message.info('Please enter monetary remedy amount.');
            setFieldForEditing('monetaryRemedy');
        }
    }, [complaint.outcomes]);

    const disabled = !canEdit;

    return (
        <>
            <Outlet />
            <Space direction="vertical" size={16} className="w-full">
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card title="Complainant" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={8}>
                                    <VTextInput
                                        label="First name"
                                        field="complainantFirstName"
                                        readonly={disabled}
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Surname"
                                        field="complainantLastName"
                                        readonly={disabled}
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Relationship"
                                        field="relationship"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        options={RELATIONSHIPS.map((value) => ({ value }))}
                                        readonly={disabled}
                                        enableOtherOption
                                    />
                                </Col>

                                <Col span={8}>
                                    <VDropdown
                                        label="Type"
                                        field="complainantType"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        options={COMPLAINANT_TYPES.map(({ label }) => ({
                                            label,
                                            value: label,
                                        }))}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VSwitch
                                        label="Referred by AFCA"
                                        field="referredByAfca"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                {complaint.referredByAfca && (
                                    <>
                                        <Col span={8}>
                                            <VDatePicker
                                                label="Date referred (AFCA)"
                                                field="referredByAfcaDate"
                                                formStore={complaintFormStore}
                                                formatValueFn={(value) => formatDate(value)}
                                                format={fDateShortDayJs}
                                                readonly={disabled}
                                                rules={requiredFieldRule}
                                            />
                                        </Col>

                                        <Col span={8}>
                                            <VTextInput
                                                label="Reference# (AFCA)"
                                                field="afcaReferenceNo"
                                                formStore={complaintFormStore}
                                                readonly={disabled}
                                            />
                                        </Col>
                                    </>
                                )}
                                <Col span={8}>
                                    <VDropdown
                                        label="Gender"
                                        field="complainantGender"
                                        formStore={complaintFormStore}
                                        options={GENDERS.map(({ label }) => ({
                                            label,
                                            value: label,
                                        }))}
                                        readonly={disabled}
                                        rules={
                                            complaint.complainantType === COMPLAINANT_TYPE.INDIVIDUAL
                                                ? requiredFieldRule
                                                : undefined
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Age"
                                        field="complainantAge"
                                        formStore={complaintFormStore}
                                        options={AGES.map(({ label }) => ({ label, value: label }))}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VTextInput
                                        label="Postcode"
                                        field="complainantPostcode"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Channel"
                                        field="channel"
                                        formStore={complaintFormStore}
                                        options={CHANNELS.map(({ label }) => ({
                                            label,
                                            value: label,
                                        }))}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VPreferredChannelSelect
                                        label="Preferred channel"
                                        channelFieldName="preferredChannel"
                                        contactFieldName="preferredChannelValue"
                                        formStore={complaintFormStore}
                                        valueOverride={complaint.preferredChannel}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VPreferredChannelSelect
                                        label="Preferred channel - contact"
                                        channelFieldName="preferredChannel"
                                        contactFieldName="preferredChannelValue"
                                        formStore={complaintFormStore}
                                        valueOverride={complaint.preferredChannelValue}
                                        readonly={disabled}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card title="Handling info" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={8}>
                                    <VReadOnly label="Complaint#" field="id" formStore={complaintFormStore} />
                                </Col>
                                <Col span={8}>
                                    <VReadOnly
                                        label="Date received"
                                        field="dtReceived"
                                        formStore={complaintFormStore}
                                        formatValueFn={(value) => formatDate(value)}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VUserSelector
                                        label="Owner"
                                        field="ownerId"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                        handlingPartyIds={complaint.handlingPartyIds}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VHandlingPartySelect
                                        label="Handling parties"
                                        field="handlingPartyIds"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                        valueOverride={
                                            <div className="flex flex-wrap gap-1">
                                                {complaint.HandlingParties?.length
                                                    ? complaint.HandlingParties.map((item) => (
                                                          <Tag
                                                              key={item.HandlingParty.id}
                                                              rootClassName="whitespace-normal m-0"
                                                          >
                                                              {item.HandlingParty?.name}
                                                          </Tag>
                                                      ))
                                                    : '-'}
                                            </div>
                                        }
                                        showAllOrgs
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Products"
                                        field="products"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                        formatValueFn={(value) => value?.join?.(', ')}
                                        options={PRODUCTS.map(({ label }) => ({
                                            value: label,
                                        }))}
                                        multiple
                                        maxCount={3}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Policy#"
                                        field="policyNo"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VReadOnly label="Status" field="status" formStore={complaintFormStore} />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Claim#"
                                        field="externalClaimId"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Is the complaint about the authorized representative of an AFS licensee?"
                                        field="afsLicenseeRelated"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                        options={RELATED_TO_AFS_LICENSEE.map(({ label }) => ({
                                            value: label,
                                        }))}
                                    />
                                </Col>

                                {(brand?.isDisplayedOnExternalForm || brand?.isDisplayedOnInternalForm) && (
                                    <Col span={8}>
                                        <VComplaintBrandSelector
                                            label="Brand"
                                            field="brand"
                                            formStore={complaintFormStore}
                                            formatValueFn={(value) => value?.split?.(';')?.join(' > ')}
                                            readonly={disabled}
                                            allowClear
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card title="Nature of complaint" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={24}>
                                    <VComplaintIssueSelector
                                        label="Issues"
                                        field="issues"
                                        formStore={complaintFormStore}
                                        valueOverride={complaint.issues?.join?.(', ')}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VTextArea
                                        label="Incident description"
                                        field="incidentDescription"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VDropdown
                                        label="Solution the complainant is seeking"
                                        field="complainantSolution"
                                        formStore={complaintFormStore}
                                        options={COMPLAINANT_SOLUTIONS.map((value) => ({
                                            value,
                                        }))}
                                        readonly={disabled}
                                        enableOtherOption
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Outcome details" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={24}>
                                    <VDropdown
                                        label="Outcomes"
                                        field="outcomes"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                        allowClear
                                        formatValueFn={(value) => value?.join?.(', ')}
                                        options={OUTCOMES.map(({ label }) => ({
                                            value: label,
                                        }))}
                                        multiple
                                        maxCount={3}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VNumberInput
                                        label="Monetary remedy amount"
                                        field="monetaryRemedy"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                        formatValueFn={(value) => formatCurrency(value, { maximumFractionDigits: 0 })}
                                        roundingMode="up"
                                        rules={
                                            complaint.outcomes?.includes('Decision changed')
                                                ? [
                                                      ...requiredFieldRule,
                                                      {
                                                          validator: async (_: unknown, value) => {
                                                              if (value && value <= 0) {
                                                                  return Promise.reject(
                                                                      'Amount must be greater than $0',
                                                                  );
                                                              }
                                                              return Promise.resolve();
                                                          },
                                                      },
                                                  ]
                                                : []
                                        }
                                    />
                                </Col>

                                <Col span={24}>
                                    <VTextArea
                                        label="Outcome details"
                                        field="outcomeDetails"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={24}>
                                    <VSwitch
                                        label="Was the complaint solved in favour of the customer?"
                                        field="wasSolvedInFavourOfCustomer"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <ManageFlags complaintId={complaint.id} disabled={disabled} />
                    </Col>

                    <Col span={24}>
                        <ManageTimeline entity="complaint" entityId={complaint.id} disabled={disabled} />
                    </Col>

                    <Col xxl={12} md={24}>
                        <TaskList relatedEntity="complaint" relatedEntityId={complaint.id} hideAddTask={disabled} />
                    </Col>
                    <Col xxl={12} md={24}>
                        <ManageNotes complaintId={complaint.id} disabled={disabled} />
                    </Col>
                </Row>
            </Space>
        </>
    );
}
