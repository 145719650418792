import { EmailConfig } from './EmailConfig';
import { LogoConfig } from './LogoConfig';
import { PhoneNumber } from './PhoneNumber';
import { Reporting } from './Reporting';
import { Tabs } from 'antd';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/system')({
    staticData: {
        breadcrumb: {
            title: 'System',
        },
        ui: {
            title: 'System configuration',
        },
    },
    component: ConfigurationSystem,
});

function ConfigurationSystem() {
    return (
        <Tabs
            type="line"
            defaultActiveKey="email"
            items={[
                {
                    key: 'email',
                    label: 'E-mail',
                    children: <EmailConfig />,
                },
                {
                    key: 'logo',
                    label: 'Logo',
                    children: <LogoConfig />,
                },
                {
                    key: 'reporting',
                    label: 'Reporting',
                    children: <Reporting />,
                },
                {
                    key: 'phone',
                    label: 'Phone number',
                    destroyInactiveTabPane: true,
                    children: <PhoneNumber />,
                },
            ]}
        />
    );
}
