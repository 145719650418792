import { PlusOutlined } from '@ant-design/icons';
import { Button } from '@client/components/Common/Button';
import { message } from '@client/components/Common/message';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useZodState } from '@client/hooks/useZodState';
import { trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { listFaqsInputSchema } from '@server/schemas/faq';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Space, Table, Tag } from 'antd';
import { useState } from 'react';
import { FaqEdit, FaqEditAction } from './FaqEdit';

export const Route = createFileRoute('/admin-console/faq')({
    staticData: {
        ui: {
            title: 'FAQ configuration',
        },
    },
    component: Faqs,
});

function Faqs() {
    const { mutate, isPending: isDeleting } = trpc.admin.faq.deleteFaq.useMutation();

    const [faqEditAction, setFaqEditAction] = useState<FaqEditAction>(['none']);
    const [input, setInput] = useZodState(listFaqsInputSchema);

    const { data, isPending, refetch } = trpc.faq.listFaqs.useQuery(input);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending || isDeleting,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setInput((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options.orderBy : prev.orderBy,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                sorter: true,
            },
            {
                title: 'Categories',
                dataIndex: 'categories',
                className: 'max-w-xs',
                render: (_, { categories }) => (
                    <Space className="flex-wrap">
                        {categories?.map((item: string) => (
                            <Tag key={item} color="blue" className="m-0">
                                {item}
                            </Tag>
                        ))}
                    </Space>
                ),
            },
            {
                title: 'Modules',
                dataIndex: 'modules',
                className: 'max-w-xs',
                render: (_, { modules }) => (
                    <Space className="flex-wrap">
                        {modules?.map((item: string) => (
                            <Tag key={item} color="green">
                                {item}
                            </Tag>
                        ))}
                    </Space>
                ),
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <Button.Edit onClick={() => setFaqEditAction(['edit', value])} />
                        <Button.Delete
                            onConfirm={() =>
                                mutate(
                                    { id: value },
                                    {
                                        onSuccess() {
                                            message.success('Deleted.');
                                            void refetch();
                                        },
                                    },
                                )
                            }
                        />
                    </Space>
                ),
            },
        ],
    });

    return (
        <>
            <PageLayout.Header
                primaryActionAddon={
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => setFaqEditAction(['add'])}>
                        New FAQ item
                    </Button>
                }
            />
            <FaqEdit
                action={faqEditAction}
                onClose={() => {
                    setFaqEditAction(['none']);
                    void refetch();
                }}
            />

            <Card>
                <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick() {
                            setFaqEditAction(['edit', id]);
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
