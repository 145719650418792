import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { useAppStateStore } from '@client/stores/AppStateStore';
import { sleep } from '@client/utils/general';
import { refreshSwCache } from '@client/utils/serviceWorkerUtils';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Typography } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { z } from 'zod';

const validateSearch = z.object({
    ol: z.string().optional(),
});

export const Route = createFileRoute('/maintenance')({
    component: Maintenance,
    validateSearch: zodValidator(validateSearch),
});

function Maintenance() {
    const { inMaintenanceMode, redirectDestinationAfterMaintenance } = useFlags();
    const navigate = useNavigate();
    const appState = useAppStateStore.getState();
    const { trpcClient } = useTrpc();
    const { ol } = Route.useSearch();

    useEffect(() => {
        const redirect = async () => {
            if (inMaintenanceMode) {
                return;
            }

            await sleep(3000);

            const appBuildVersion = await trpcClient.appSetting.getAppBuildVersion.query();

            await refreshSwCache();

            if (appBuildVersion) {
                appState.actions.setAppBuildVersion(appBuildVersion.version);
                appState.actions.setAppBuildLastUpdatedAt(appBuildVersion.updatedAt);
            }

            if (redirectDestinationAfterMaintenance === 'previous-location' && !!ol) {
                // Redirect to the previous location but add timestamp to the url to avoid caching.
                window.location.href = `${ol}${ol.includes('?') ? '&' : '?'}t=${Date.now()}`;
            } else if (redirectDestinationAfterMaintenance === 'homepage') {
                navigate({ to: '/' });
            } else if (redirectDestinationAfterMaintenance === 'login') {
                navigate({ to: '/login' });
            }
        };

        void redirect();
    }, [inMaintenanceMode, redirectDestinationAfterMaintenance]);

    return inMaintenanceMode ? (
        <div className="flex h-screen w-full items-center justify-center bg-amber-300">
            <div className="text-lg">
                <Typography.Title level={2}>Under Maintenance</Typography.Title>
                <Typography.Text>The site is undergoing upgrade. We'll be back soon.</Typography.Text>
            </div>
        </div>
    ) : (
        <div className="flex h-screen w-full items-center justify-center bg-green-100">
            <div className="text-center text-lg">
                <CheckCircleFilled className="mb-4 text-6xl text-green-500" />
                <Typography.Title level={2}>Maintenance Completed</Typography.Title>
                <Typography.Text className="mb-2 block">
                    The system has been successfully updated and is now back online.
                </Typography.Text>
                <Typography.Text type="secondary" italic>
                    You will be redirected back to the application shortly...
                </Typography.Text>
            </div>
        </div>
    );
}
