import { createLocalStoragePersister } from '@client/utils/zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { AppState } from './types';

const storage = createLocalStoragePersister<AppState>();

export const useAppStateStore = create<AppState>()(
    persist<AppState>(
        (set, get) => ({
            isOnline: true,
            cancelUploadSignal: null,
            uploadCanceler: null,
            pendingUploads: [],
            appBuildVersion: '',
            appBuildLastUpdatedAt: null,
            claimLodgementSettings: {
                fieldRequiredMessage: 'Please complete this section before continuing the claim.',
            },
            drawer: {
                show: false,
            },
            actions: {
                setIsOnline: (isOnline) => set({ isOnline }),
                addPendingUpload: (upload) =>
                    set((state) => ({
                        pendingUploads: [...state.pendingUploads, { ...upload, status: 'uploading', percent: 0 }],
                    })),

                removePendingUpload: (uid) =>
                    set((state) => ({
                        pendingUploads: state.pendingUploads.filter((upload) => upload.uid !== uid),
                    })),

                cancelCurrentUpload: () => {
                    const { uploadCanceler } = get();
                    uploadCanceler?.();
                    set({ pendingUploads: [], uploadCanceler: null });
                },

                setUploadCanceler: (canceler) => set({ uploadCanceler: canceler }),

                updatePendingUploadProgress: (uid, percent) =>
                    set((state) => ({
                        pendingUploads: state.pendingUploads.map((upload) =>
                            upload.uid === uid ? { ...upload, percent } : upload,
                        ),
                    })),
                setAppBuildVersion: (version) => set({ appBuildVersion: version }),
                setAppBuildLastUpdatedAt: (lastUpdatedAt) => set({ appBuildLastUpdatedAt: lastUpdatedAt }),
            },
        }),
        {
            name: 'appState',
            storage,
            partialize: (state) =>
                Object.fromEntries(
                    Object.entries(state).filter(([key]) => ['appBuildVersion'].includes(key)),
                ) as AppState,
        },
    ),
);
