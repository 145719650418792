import { grey } from '@ant-design/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@client/__generated__';
import {
    OrderBy,
    TaskTemplateBoolExp,
    TaskTemplateDsQuery,
    TaskTemplateDsQueryVariables,
} from '@client/__generated__/graphql';
import { errorMessage } from '@client/components/Common/errorMessage';
import { TaskTemplateDs } from '@client/graphql/queries/taskManager';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useTableDataSource } from '@client/hooks/Table/useTableDataSource';
import { commonActionColumn } from '@client/utils/table';
import { textToHtml } from '@client/utils/text';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Popconfirm, Table } from 'antd';
import { useEffect, useState } from 'react';
import { NewTaskTemplate } from './NewTaskTemplate';

type TaskTemplate = TaskTemplateDsQuery['TaskTemplate'][number];

export const Route = createFileRoute('/console/$orgId/configuration/task-manager')({
    staticData: {
        breadcrumb: {
            title: 'Task manager',
        },
        modules: ['claim'],
    },
    component: TaskManager,
});

function TaskManager() {
    const orgId = useOrgId();
    const [openNewTaskDrawer, setOpenNewTaskDrawer] = useState(false);
    const [drawerMode, setDrawerMode] = useState<'add' | 'edit'>('add');
    const [taskIdToEdit, setTaskIdToEdit] = useState<number | undefined>();

    const [taskTemplateDs] = useLazyQuery(TaskTemplateDs);
    const [deleteTaskTemplate] = useMutation(DeleteTaskTemplate);

    const { tableProps, error, refetch, setLoading } = useTableDataSource<TaskTemplate, TaskTemplateBoolExp>({
        rowKey: 'id',
        defaultOrderBy: [{ title: OrderBy.asc }],
        columns: [
            {
                title: 'Type',
                dataIndex: 'type',
                sorter: true,
            },
            {
                title: 'Task title',
                dataIndex: 'title',
                sorter: true,
            },
            {
                title: 'Action',
                dataIndex: 'action',
                sorter: true,
            },
            {
                title: 'Related to',
                dataIndex: 'relatedTo',
                sorter: true,
            },
            {
                title: 'Description',
                dataIndex: 'description',
                sorter: true,
                render: (value) => textToHtml(value),
            },
            {
                title: 'Category',
                dataIndex: 'category',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Popconfirm
                        title="Are you sure?"
                        onPopupClick={(e) => e.stopPropagation()}
                        onConfirm={async () => {
                            try {
                                setLoading(true);
                                await deleteTaskTemplate({
                                    variables: {
                                        id: value,
                                        orgId,
                                    },
                                });

                                void refetch('network-only');
                            } catch (e) {
                                errorMessage.show(e);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >
                        <Button
                            size="small"
                            type="text"
                            icon={<DeleteOutlined style={{ color: grey.primary }} />}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                ),
            },
        ],
        getResults: async (options) => {
            try {
                const where: TaskTemplateBoolExp = options?.where ? options.where : {};
                const { data } = await taskTemplateDs({
                    variables: {
                        limit: options!.limit! || 10,
                        offset: options!.offset! || 0,
                        ...(options?.orderBy && { orderBy: options.orderBy }),
                        where,
                    } as TaskTemplateDsQueryVariables,
                    fetchPolicy: options?.fetchPolicy || 'network-only',
                });
                return {
                    rows: data?.TaskTemplate,
                    total: data?.TaskTemplateAggregate.aggregate?.count,
                };
            } catch (e) {
                return { error: e };
            }
        },
        onError: (error) => errorMessage.show(error),
        paginationConfig: {
            defaultPageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: true,
        },
    });

    useEffect(() => {
        if (error) {
            errorMessage.show(error);
        }
    }, [error]);

    return (
        <>
            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setDrawerMode('add');
                        setOpenNewTaskDrawer(true);
                    }}
                >
                    New task
                </Button>
            </div>
            <NewTaskTemplate
                open={openNewTaskDrawer}
                onClose={() => {
                    setOpenNewTaskDrawer(false);
                    void refetch('network-only');
                }}
                mode={drawerMode}
                taskId={taskIdToEdit}
            />
            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => {
                            setTaskIdToEdit(id);
                            setDrawerMode('edit');
                            setOpenNewTaskDrawer(true);
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const DeleteTaskTemplate = gql(/* GraphQL */ `
    mutation DeleteTaskTemplate($id: bigint!, $orgId: bigint!) {
        updateTaskTemplateByPk(pkColumns: { id: $id, orgId: $orgId }, _set: { isDeleted: true }) {
            id
        }
    }
`);
