import { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { forwardRef, ReactNode, Ref, RefAttributes } from 'react';

export function forwardRefWithGenerics<T, P = any>(
    render: (props: P, ref: Ref<T>) => ReactNode,
): (props: P & RefAttributes<T>) => ReactNode {
    return forwardRef(render as any) as any;
}

export const disableFutureDateTimeProps: Pick<DatePickerProps, 'disabledDate' | 'disabledTime'> = {
    disabledDate(current: Dayjs | null) {
        return !!current && current.isAfter(dayjs());
    },

    disabledTime(date: Dayjs) {
        const now = dayjs();

        if (!date.isSame(now, 'day')) {
            return {};
        }

        return {
            disabledHours() {
                return Array.from({ length: 24 }, (_, i) => i).filter((item) => item > now.hour());
            },

            disabledMinutes(selectedHour) {
                return selectedHour === now.hour()
                    ? Array.from({ length: 60 }, (_, i) => i).filter((item) => item > now.minute())
                    : [];
            },

            disabledSeconds(selectedHour, selectedMinute) {
                return selectedHour === now.hour() && selectedMinute === now.minute()
                    ? Array.from({ length: 60 }, (_, i) => i).filter((item) => item > now.second())
                    : [];
            },
        };
    },
};

export const DRAWER_CLOSING_ANIMATION_TIME = 300;
