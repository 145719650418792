import { observer } from 'mobx-react-lite';
import { RefSelectProps, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { InputWrapper, InputWrapperRef, VInputProps } from '@client/components/Common/ViewForm/InputWrapper';
import { errorMessage } from '@client/components/Common/errorMessage';
import { uuid } from '@shared/utils/general';
import { DefaultFormData } from '@client/stores/FormStore/types';

type Props<Value = any, TFormData extends DefaultFormData = DefaultFormData> = VInputProps<TFormData> & {
    loaderFn?: () => Promise<Array<{ label?: string; value: Value }>>;
    options?: Array<{ label?: string; value: Value }>;
    maxTagCount?: number;
};

export const VTagsSelect = observer(
    <Value = any, TFormData extends DefaultFormData = DefaultFormData>({
        loaderFn,
        options,
        buttonsPosition = 'right',
        maxTagCount,
        ...props
    }: Props<Value, TFormData>) => {
        const inputRef = useRef<RefSelectProps>(null);
        const wrapperRef = useRef<InputWrapperRef | null>(null);
        const [opts, setOpts] = useState<{ label?: string; value: Value }[]>(options || []);
        const [loading, setLoading] = useState(false);
        const [selectedTags, setSelectedTags] = useState<Value[]>([]);
        const [id] = useState(uuid());

        useEffect(() => {
            const init = async () => {
                if (loaderFn) {
                    setLoading(true);
                    try {
                        const data = await loaderFn();
                        setOpts(data);
                    } catch (e) {
                        errorMessage.show(e);
                    } finally {
                        setLoading(false);
                    }
                }
                if (options) {
                    setOpts(options);
                }
            };
            void init();
        }, []);

        const _opts = opts.map(({ label, value }) => ({
            label: label || String(value),
            value,
        }));

        const handleChange = (value: Value[]) => {
            setSelectedTags(value);
        };

        return (
            <InputWrapper<TFormData>
                innerRef={(ref) => {
                    wrapperRef.current = ref;
                }}
                focusFn={() => {
                    inputRef.current?.focus();
                }}
                containerId={id}
                buttonsPosition={buttonsPosition}
                {...props}
            >
                <Select
                    ref={inputRef}
                    mode="tags"
                    loading={loading}
                    options={_opts}
                    value={selectedTags}
                    onChange={handleChange}
                    defaultOpen
                    showSearch={false}
                    getPopupContainer={() => document.getElementById(id)!}
                    dropdownStyle={{ display: 'none' }}
                    maxTagCount={maxTagCount}
                    popupClassName="min-w-max"
                />
            </InputWrapper>
        );
    },
);
