import { message } from '@client/components/Common/message';
import { useTrpc } from '@client/hooks/useTrpc';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'antd';
import { useState } from 'react';
export const Route = createFileRoute('/admin-console/admin-playground')({
    component: AdminPlayground,
    staticData: {
        ui: {
            title: 'Admin Playground',
            subtitle: 'Page for running various admin test',
        },
        breadcrumb: {
            title: 'Admin Playground',
        },
        privilegedSuperAdminOnly: true,
    },
});

function AdminPlayground() {
    const { trpc } = useTrpc();
    const { mutateAsync: indicatorNotificationTest } = trpc.admin.playground.indicatorNotificationTest.useMutation();
    const { mutateAsync: stripeTest } = trpc.admin.playground.stripeTest.useMutation();
    const { mutateAsync: getPdf } = trpc.test.getPdf.useMutation();
    trpc.test.badRequest.useQuery();
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const handleIndicatorNotificationTest = async () => {
        try {
            await indicatorNotificationTest();
        } catch (error) {
            console.error(error);
            message.error('Failed to test indicator notification');
        }
    };

    const handleStripeTest = async () => {
        try {
            const result = await stripeTest();
            console.log(result);
        } catch (error) {
            console.error(error);
            message.error('Failed to test stripe');
        }
    };

    const handleGetPdf = async () => {
        try {
            const result = await getPdf();
            setPdfUrl(result);
        } catch (error) {
            console.error(error);
            message.error('Failed to get pdf');
        }
    };

    return (
        <div>
            <Button onClick={handleIndicatorNotificationTest}>Test indicator notification</Button>
            <Button onClick={handleStripeTest}>Test stripe</Button>
            <Button onClick={handleGetPdf}>Get pdf</Button>
            {pdfUrl && (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    {pdfUrl}
                </a>
            )}
        </div>
    );
}
