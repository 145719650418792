import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useSearchParams } from '@client/hooks/useSearchParams';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { navRoutes } from '@shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';
import { Alert } from 'antd';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/console/$orgId/get-file')({
    staticData: {
        breadcrumb: {
            title: 'Get file',
        },
    },
    component: GetFile,
});

function GetFile() {
    const { key } = useSearchParams(navRoutes.getFile);
    const [downloadedSuccessfully, setDownloadedSuccessfully] = useState(false);
    const trpcClient = useTrpcClient();

    const downloadFile = async () => {
        try {
            message.loading('Downloading file...');

            const decodedKey = decodeURIComponent(key!);

            const url = await trpcClient.file.getDownloadUrl.query({
                key: decodedKey,
            });
            window.open(url, '_self');
            setDownloadedSuccessfully(true);
            message.success('File downloaded successfully.');
        } catch (e) {
            errorMessage.show(e);
        }
    };

    useEffect(() => {
        if (key) {
            void downloadFile();
        }
    }, [key]);

    return downloadedSuccessfully ? (
        <Alert
            message="File downloaded successfully."
            description="You can close this tab now."
            type="success"
            showIcon
        />
    ) : (
        <Alert message="File download failed." description="Please try again." type="error" showIcon />
    );
}
