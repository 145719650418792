import * as Sentry from '@sentry/react';
import Joi from 'joi';
import LogRocket from 'logrocket';
import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import { getLaunchDarklyProvider } from './components/Providers/LaunchDarklyProvider';
import { TanstackRouterProvider } from './components/Providers/TanstackRouterProvider';
import { UIProvider } from './components/Providers/UIProvider';
import './config/sentry';
import { unregisterServiceWorkers } from './utils/serviceWorkerUtils';
configure({ enforceActions: 'never' });

//@ts-expect-error BigInt.prototype.toJSON is not defined in TypeScript
BigInt.prototype.toJSON = function () {
    const int = Number.parseInt(this.toString());
    return int ?? this.toString();
};

(async () => {
    const LaunchDarklyProvider = await getLaunchDarklyProvider();

    try {
        if (['production', 'staging'].includes(import.meta.env.VITE_ENV)) {
            LogRocket.init('cuen3v/curium');
        }
    } catch (e) {
        console.error('Error initializing LogRocket', e);
    }

    try {
        // We will remove after some time
        await unregisterServiceWorkers();
    } catch (e) {
        console.error('Error unregistering service workers', e);
    }

    const container = document.getElementById('root');
    const root = createRoot(container!);

    window.Joi = Joi;

    root.render(
        <LaunchDarklyProvider>
            <UIProvider>
                <TanstackRouterProvider />
            </UIProvider>
        </LaunchDarklyProvider>,
    );
})();

window.addEventListener(
    'error',
    function (event) {
        if (event.target instanceof HTMLScriptElement) {
            console.error('Failed to load script:', event.target.src);
            Sentry.captureException(event, {
                level: 'error',
                tags: {
                    script: event.target.src,
                },
                extra: {
                    info: 'Failed to load script - global error handler',
                },
            });
        }
    },
    true,
);
