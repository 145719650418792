import { gql } from '@client/__generated__/gql';

export const CompleteTask = gql(/* GraphQL */ `
    mutation CompleteTask($id: bigint!, $orgId: bigint!) {
        updateTaskByPk(pkColumns: { id: $id, orgId: $orgId }, _set: { isCompleted: true }) {
            id
        }
    }
`);

export const UpdateTask = gql(/* GraphQL */ `
    mutation UpdateTask($id: bigint!, $orgId: bigint!, $task: TaskSetInput = {}) {
        updateTaskByPk(pkColumns: { id: $id, orgId: $orgId }, _set: $task) {
            id
        }
    }
`);

export const InsertTask = gql(/* GraphQL */ `
    mutation InsertTask($object: TaskInsertInput = {}) {
        insertTaskOne(object: $object) {
            id
        }
    }
`);
