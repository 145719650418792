import { useMatches, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { isRoute, navRoutes } from '@shared/navigation/navRoutes';

export function useDocumentTitle() {
    const matches = useMatches();
    const match = matches.at(-1);
    const { id } = useParams({ strict: false });

    useEffect(() => {
        if (match?.staticData.metadata?.title) {
            document.title = `${match?.staticData.metadata.title} ${id ? ` - ${id}` : ''} - Curium`;

            return;
        }

        let title = 'Curium';

        let key: keyof typeof navRoutes;

        for (key in navRoutes) {
            const route = navRoutes[key];

            if (
                !isRoute(route) ||
                !route.title ||
                (route.path !== match?.routeId && `${route.path}/` !== match?.routeId)
            ) {
                continue;
            }

            title = `${route.title} ${id ? ` - ${id}` : ''} - Curium`;
            break;
        }

        document.title = title;
    }, [match]);
}
