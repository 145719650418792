/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/~__root';
import { Route as SignUpRouteImport } from './routes/~sign-up/~route';
import { Route as PublicRouteImport } from './routes/~public/~route';
import { Route as NotFoundImport } from './routes/~not-found';
import { Route as MaintenanceRouteImport } from './routes/~maintenance/~route';
import { Route as InviteRouteImport } from './routes/~invite/~route';
import { Route as AdminConsoleRouteImport } from './routes/~admin-console/~route';
import { Route as IndexImport } from './routes/~index';
import { Route as PublicOrgIdRouteImport } from './routes/~public/~$orgId/~route';
import { Route as ConsoleOrgIdRouteImport } from './routes/~console.$orgId/~route';
import { Route as AdminConsoleUsersRouteImport } from './routes/~admin-console/~users/~route';
import { Route as AdminConsoleReportsRouteImport } from './routes/~admin-console/~reports/~route';
import { Route as AdminConsoleProductsRouteImport } from './routes/~admin-console/~products/~route';
import { Route as AdminConsoleOrganizationsRouteImport } from './routes/~admin-console/~organizations/~route';
import { Route as AdminConsoleObligationsRouteImport } from './routes/~admin-console/~obligations/~route';
import { Route as AdminConsoleNewOrganizationRouteImport } from './routes/~admin-console/~new-organization/~route';
import { Route as AdminConsoleFaqRouteImport } from './routes/~admin-console/~faq/~route';
import { Route as AdminConsoleControlsRouteImport } from './routes/~admin-console/~controls/~route';
import { Route as AdminConsoleConfigurationRouteImport } from './routes/~admin-console/~configuration/~route';
import { Route as AdminConsoleCommTemplatesRouteImport } from './routes/~admin-console/~comm-templates/~route';
import { Route as AdminConsoleAiPromptsRouteImport } from './routes/~admin-console/~ai-prompts/~route';
import { Route as AdminConsoleAdvancedRouteImport } from './routes/~admin-console/~advanced/~route';
import { Route as AdminConsoleAdminPlaygroundImport } from './routes/~admin-console/~admin-playground';
import { Route as authenticationPaymentPendingImport } from './routes/~(authentication)/~payment-pending';
import { Route as authenticationLogoutinRouteImport } from './routes/~(authentication)/~logoutin/~route';
import { Route as authenticationLogoutRouteImport } from './routes/~(authentication)/~logout/~route';
import { Route as authenticationLoginRouteImport } from './routes/~(authentication)/~login/~route';
import { Route as authenticationCallbackRouteImport } from './routes/~(authentication)/~callback/~route';
import { Route as authenticationAdminLoginRouteImport } from './routes/~(authentication)/~admin-login/~route';
import { Route as AdminConsoleIndexImport } from './routes/~admin-console/~index';
import { Route as PublicNewComplaintOrgIdRouteImport } from './routes/~public/~new-complaint.$orgId/~route';
import { Route as PublicNewComplaintByKeyKeyRouteImport } from './routes/~public/~new-complaint-by-key.$key/~route';
import { Route as PublicLodgementSummaryAccessKeyRouteImport } from './routes/~public/~lodgement-summary.$accessKey/~route';
import { Route as ConsoleOrgIdWorkloadManagerRouteImport } from './routes/~console.$orgId/~workload-manager/~route';
import { Route as ConsoleOrgIdUserSettingsRouteImport } from './routes/~console.$orgId/~user-settings/~route';
import { Route as ConsoleOrgIdToolsOnlyHomePageImport } from './routes/~console.$orgId/~tools-only-home-page';
import { Route as ConsoleOrgIdToolsRouteImport } from './routes/~console.$orgId/~tools/~route';
import { Route as ConsoleOrgIdRiskRouteImport } from './routes/~console.$orgId/~risk/~route';
import { Route as ConsoleOrgIdReportingRouteImport } from './routes/~console.$orgId/~reporting/~route';
import { Route as ConsoleOrgIdGetFileRouteImport } from './routes/~console.$orgId/~get-file/~route';
import { Route as ConsoleOrgIdFaqRouteImport } from './routes/~console.$orgId/~faq/~route';
import { Route as ConsoleOrgIdDebugRouteImport } from './routes/~console.$orgId/~debug/~route';
import { Route as ConsoleOrgIdDashboardRouteImport } from './routes/~console.$orgId/~dashboard/~route';
import { Route as ConsoleOrgIdControlsRouteImport } from './routes/~console.$orgId/~controls/~route';
import { Route as ConsoleOrgIdConfigurationRouteImport } from './routes/~console.$orgId/~configuration/~route';
import { Route as ConsoleOrgIdComplianceRouteImport } from './routes/~console.$orgId/~compliance/~route';
import { Route as ConsoleOrgIdClaimsRouteImport } from './routes/~console.$orgId/~claims/~route';
import { Route as ConsoleOrgIdClaimModuleOnlyHomePageImport } from './routes/~console.$orgId/~claim-module-only-home-page';
import { Route as AdminConsoleProductsServiceIdImport } from './routes/~admin-console/~products/~$serviceId';
import { Route as AdminConsoleOrganizationsOrgIdRouteImport } from './routes/~admin-console/~organizations/~$orgId/~route';
import { Route as AdminConsoleObligationsLibraryRouteImport } from './routes/~admin-console/~obligations/~library/~route';
import { Route as AdminConsoleControlsLibraryRouteImport } from './routes/~admin-console/~controls/~library/~route';
import { Route as AdminConsoleConfigurationObligationsRouteImport } from './routes/~admin-console/~configuration/~obligations/~route';
import { Route as AdminConsoleAdvancedSystemLogsImport } from './routes/~admin-console/~advanced/~system-logs';
import { Route as AdminConsoleAdvancedMonitoringImport } from './routes/~admin-console/~advanced/~monitoring';
import { Route as AdminConsoleAdvancedJobsRouteImport } from './routes/~admin-console/~advanced/~jobs/~route';
import { Route as AdminConsoleAdvancedIndicatorsRouteImport } from './routes/~admin-console/~advanced/~indicators/~route';
import { Route as AdminConsoleAdvancedDbUpgradesImport } from './routes/~admin-console/~advanced/~db-upgrades';
import { Route as AdminConsoleAdvancedAppSettingsImport } from './routes/~admin-console/~advanced/~app-settings';
import { Route as authenticationChangeOrgOrgIdRouteImport } from './routes/~(authentication)/~change-org.$orgId/~route';
import { Route as ConsoleOrgIdIndexImport } from './routes/~console.$orgId/~index';
import { Route as AdminConsoleObligationsIndexImport } from './routes/~admin-console/~obligations/~index';
import { Route as AdminConsoleControlsIndexImport } from './routes/~admin-console/~controls/~index';
import { Route as AdminConsoleConfigurationIndexImport } from './routes/~admin-console/~configuration/~index';
import { Route as AdminConsoleAdvancedIndexImport } from './routes/~admin-console/~advanced/~index';
import { Route as PublicOrgIdComplaintNewRouteImport } from './routes/~public/~$orgId/~complaint.new/~route';
import { Route as ConsoleOrgIdWorkloadManagerTasksRouteImport } from './routes/~console.$orgId/~workload-manager/~tasks/~route';
import { Route as ConsoleOrgIdWorkloadManagerEmailsRouteImport } from './routes/~console.$orgId/~workload-manager/~emails/~route';
import { Route as ConsoleOrgIdUserSettingsProfileImport } from './routes/~console.$orgId/~user-settings/~profile';
import { Route as ConsoleOrgIdUserSettingsNotificationsImport } from './routes/~console.$orgId/~user-settings/~notifications';
import { Route as ConsoleOrgIdToolsWebsiteCheckerRouteImport } from './routes/~console.$orgId/~tools/~website-checker/~route';
import { Route as ConsoleOrgIdToolsAutoDetectRouteImport } from './routes/~console.$orgId/~tools/~auto-detect/~route';
import { Route as ConsoleOrgIdRiskRegisterRouteImport } from './routes/~console.$orgId/~risk/~register/~route';
import { Route as ConsoleOrgIdRiskRasRouteImport } from './routes/~console.$orgId/~risk/~ras/~route';
import { Route as ConsoleOrgIdReportingAddImport } from './routes/~console.$orgId/~reporting/~add';
import { Route as ConsoleOrgIdDebugTestRouteImport } from './routes/~console.$orgId/~debug/~test/~route';
import { Route as ConsoleOrgIdDashboardSectionRouteImport } from './routes/~console.$orgId/~dashboard/~$section/~route';
import { Route as ConsoleOrgIdControlsTestsRouteImport } from './routes/~console.$orgId/~controls/~tests/~route';
import { Route as ConsoleOrgIdControlsRegisterRouteImport } from './routes/~console.$orgId/~controls/~register/~route';
import { Route as ConsoleOrgIdConfigurationUsersRouteImport } from './routes/~console.$orgId/~configuration/~users/~route';
import { Route as ConsoleOrgIdConfigurationTasksRouteImport } from './routes/~console.$orgId/~configuration/~tasks/~route';
import { Route as ConsoleOrgIdConfigurationTaskManagerRouteImport } from './routes/~console.$orgId/~configuration/~task-manager/~route';
import { Route as ConsoleOrgIdConfigurationSystemRouteImport } from './routes/~console.$orgId/~configuration/~system/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersRouteImport } from './routes/~console.$orgId/~configuration/~suppliers/~route';
import { Route as ConsoleOrgIdConfigurationStatisticsRouteImport } from './routes/~console.$orgId/~configuration/~statistics/~route';
import { Route as ConsoleOrgIdConfigurationRiskRouteImport } from './routes/~console.$orgId/~configuration/~risk/~route';
import { Route as ConsoleOrgIdConfigurationQuestionnaireRouteImport } from './routes/~console.$orgId/~configuration/~questionnaire/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesRouteImport } from './routes/~console.$orgId/~configuration/~product-types/~route';
import { Route as ConsoleOrgIdConfigurationPaymentsRouteImport } from './routes/~console.$orgId/~configuration/~payments/~route';
import { Route as ConsoleOrgIdConfigurationInsurersRouteImport } from './routes/~console.$orgId/~configuration/~insurers/~route';
import { Route as ConsoleOrgIdConfigurationHandlingPartiesRouteImport } from './routes/~console.$orgId/~configuration/~handling-parties/~route';
import { Route as ConsoleOrgIdConfigurationExpensesAndBillingRouteImport } from './routes/~console.$orgId/~configuration/~expenses-and-billing/~route';
import { Route as ConsoleOrgIdConfigurationDocumentsRouteImport } from './routes/~console.$orgId/~configuration/~documents/~route';
import { Route as ConsoleOrgIdConfigurationComplianceRouteImport } from './routes/~console.$orgId/~configuration/~compliance/~route';
import { Route as ConsoleOrgIdConfigurationCommsTemplatesRouteImport } from './routes/~console.$orgId/~configuration/~comms-templates/~route';
import { Route as ConsoleOrgIdConfigurationClaimRouteImport } from './routes/~console.$orgId/~configuration/~claim/~route';
import { Route as ConsoleOrgIdConfigurationBillingRouteImport } from './routes/~console.$orgId/~configuration/~billing/~route';
import { Route as ConsoleOrgIdConfigurationAssistanceRouteImport } from './routes/~console.$orgId/~configuration/~assistance/~route';
import { Route as ConsoleOrgIdComplianceTrainingRouteImport } from './routes/~console.$orgId/~compliance/~training/~route';
import { Route as ConsoleOrgIdComplianceObligationsRouteImport } from './routes/~console.$orgId/~compliance/~obligations/~route';
import { Route as ConsoleOrgIdComplianceIncidentsRouteImport } from './routes/~console.$orgId/~compliance/~incidents/~route';
import { Route as ConsoleOrgIdComplianceComplaintsRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~route';
import { Route as ConsoleOrgIdComplianceCoiRouteImport } from './routes/~console.$orgId/~compliance/~coi/~route';
import { Route as ConsoleOrgIdClaimsLodgeRouteImport } from './routes/~console.$orgId/~claims/~lodge/~route';
import { Route as AdminConsoleOrganizationsOrgIdWalletImport } from './routes/~admin-console/~organizations/~$orgId/~wallet';
import { Route as AdminConsoleOrganizationsOrgIdUsersRouteImport } from './routes/~admin-console/~organizations/~$orgId/~users/~route';
import { Route as AdminConsoleOrganizationsOrgIdSubscriptionsImport } from './routes/~admin-console/~organizations/~$orgId/~subscriptions';
import { Route as AdminConsoleObligationsLibraryAddRouteImport } from './routes/~admin-console/~obligations/~library/~add/~route';
import { Route as AdminConsoleAdvancedJobsRecurringImport } from './routes/~admin-console/~advanced/~jobs/~recurring';
import { Route as AdminConsoleAdvancedJobsOnDemandImport } from './routes/~admin-console/~advanced/~jobs/~on-demand';
import { Route as AdminConsoleAdvancedJobsDynamicRecurringImport } from './routes/~admin-console/~advanced/~jobs/~dynamic-recurring';
import { Route as AdminConsoleAdvancedIndicatorsIncidentsImport } from './routes/~admin-console/~advanced/~indicators/~incidents';
import { Route as AdminConsoleAdvancedIndicatorsComplaintsImport } from './routes/~admin-console/~advanced/~indicators/~complaints';
import { Route as ConsoleOrgIdUserSettingsIndexImport } from './routes/~console.$orgId/~user-settings/~index';
import { Route as ConsoleOrgIdToolsIndexImport } from './routes/~console.$orgId/~tools/~index';
import { Route as ConsoleOrgIdRiskIndexImport } from './routes/~console.$orgId/~risk/~index';
import { Route as ConsoleOrgIdDebugIndexImport } from './routes/~console.$orgId/~debug/~index';
import { Route as ConsoleOrgIdDashboardIndexImport } from './routes/~console.$orgId/~dashboard/~index';
import { Route as ConsoleOrgIdControlsIndexImport } from './routes/~console.$orgId/~controls/~index';
import { Route as ConsoleOrgIdConfigurationIndexImport } from './routes/~console.$orgId/~configuration/~index';
import { Route as ConsoleOrgIdComplianceIndexImport } from './routes/~console.$orgId/~compliance/~index';
import { Route as ConsoleOrgIdClaimsIndexImport } from './routes/~console.$orgId/~claims/~index';
import { Route as AdminConsoleObligationsLibraryIndexImport } from './routes/~admin-console/~obligations/~library/~index';
import { Route as AdminConsoleControlsLibraryIndexImport } from './routes/~admin-console/~controls/~library/~index';
import { Route as AdminConsoleAdvancedJobsIndexImport } from './routes/~admin-console/~advanced/~jobs/~index';
import { Route as PublicOrgIdInvoiceViewAccessKeyRouteImport } from './routes/~public/~$orgId/~invoice.view.$accessKey/~route';
import { Route as ConsoleOrgIdWorkloadManagerTasksListRouteImport } from './routes/~console.$orgId/~workload-manager/~tasks/~list/~route';
import { Route as ConsoleOrgIdWorkloadManagerTasksCalendarRouteImport } from './routes/~console.$orgId/~workload-manager/~tasks/~calendar/~route';
import { Route as ConsoleOrgIdToolsAutoDetectInboxRouteImport } from './routes/~console.$orgId/~tools/~auto-detect/~inbox/~route';
import { Route as ConsoleOrgIdToolsAutoDetectDashboardImport } from './routes/~console.$orgId/~tools/~auto-detect/~dashboard';
import { Route as ConsoleOrgIdControlsRegisterAddRouteImport } from './routes/~console.$orgId/~controls/~register/~add/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersAddImport } from './routes/~console.$orgId/~configuration/~suppliers/~add';
import { Route as ConsoleOrgIdConfigurationRiskSectionRouteImport } from './routes/~console.$orgId/~configuration/~risk/~$section/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteImport } from './routes/~console.$orgId/~configuration/~product-types/~$productTypeId/~route';
import { Route as ConsoleOrgIdConfigurationBillingUsageImport } from './routes/~console.$orgId/~configuration/~billing/~usage';
import { Route as ConsoleOrgIdConfigurationBillingTransactionsImport } from './routes/~console.$orgId/~configuration/~billing/~transactions';
import { Route as ConsoleOrgIdConfigurationBillingInvoicesImport } from './routes/~console.$orgId/~configuration/~billing/~invoices';
import { Route as ConsoleOrgIdConfigurationBillingBillingInfoImport } from './routes/~console.$orgId/~configuration/~billing/~billing-info';
import { Route as ConsoleOrgIdConfigurationBillingBalanceImport } from './routes/~console.$orgId/~configuration/~billing/~balance';
import { Route as ConsoleOrgIdComplianceTrainingSearchRouteImport } from './routes/~console.$orgId/~compliance/~training/~search/~route';
import { Route as ConsoleOrgIdComplianceObligationsAddRouteImport } from './routes/~console.$orgId/~compliance/~obligations/~add/~route';
import { Route as ConsoleOrgIdComplianceIncidentsAddImport } from './routes/~console.$orgId/~compliance/~incidents/~add';
import { Route as ConsoleOrgIdComplianceComplaintsAddRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~add/~route';
import { Route as ConsoleOrgIdComplianceCoiAddRouteImport } from './routes/~console.$orgId/~compliance/~coi/~add/~route';
import { Route as ConsoleOrgIdClaimsViewIdRouteImport } from './routes/~console.$orgId/~claims/~view.$id/~route';
import { Route as ConsoleOrgIdClaimsEditIdRouteImport } from './routes/~console.$orgId/~claims/~edit.$id/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~route';
import { Route as AdminConsoleObligationsLibraryIdSectionRouteImport } from './routes/~admin-console/~obligations/~library/~$id.$section/~route';
import { Route as AdminConsoleControlsLibraryIdSectionRouteImport } from './routes/~admin-console/~controls/~library/~$id.$section/~route';
import { Route as ConsoleOrgIdRiskRegisterIndexImport } from './routes/~console.$orgId/~risk/~register/~index';
import { Route as ConsoleOrgIdRiskRasIndexImport } from './routes/~console.$orgId/~risk/~ras/~index';
import { Route as ConsoleOrgIdControlsRegisterIndexImport } from './routes/~console.$orgId/~controls/~register/~index';
import { Route as ConsoleOrgIdConfigurationSuppliersIndexImport } from './routes/~console.$orgId/~configuration/~suppliers/~index';
import { Route as ConsoleOrgIdConfigurationRiskIndexImport } from './routes/~console.$orgId/~configuration/~risk/~index';
import { Route as ConsoleOrgIdConfigurationProductTypesIndexImport } from './routes/~console.$orgId/~configuration/~product-types/~index';
import { Route as ConsoleOrgIdComplianceTrainingIndexImport } from './routes/~console.$orgId/~compliance/~training/~index';
import { Route as ConsoleOrgIdComplianceObligationsIndexImport } from './routes/~console.$orgId/~compliance/~obligations/~index';
import { Route as ConsoleOrgIdComplianceIncidentsIndexImport } from './routes/~console.$orgId/~compliance/~incidents/~index';
import { Route as ConsoleOrgIdComplianceComplaintsIndexImport } from './routes/~console.$orgId/~compliance/~complaints/~index';
import { Route as ConsoleOrgIdComplianceCoiIndexImport } from './routes/~console.$orgId/~compliance/~coi/~index';
import { Route as PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteImport } from './routes/~public/~claim-lodgement.$orgId.$pdsVersionId.$questionnaireId.$productTypeId/~route';
import { Route as ConsoleOrgIdWorkloadManagerTasksListAddImport } from './routes/~console.$orgId/~workload-manager/~tasks/~list/~add';
import { Route as ConsoleOrgIdWorkloadManagerTasksCalendarAddImport } from './routes/~console.$orgId/~workload-manager/~tasks/~calendar/~add';
import { Route as ConsoleOrgIdRiskRasDetailsIdRouteImport } from './routes/~console.$orgId/~risk/~ras/~details.$id/~route';
import { Route as ConsoleOrgIdControlsRegisterDetailsIdRouteImport } from './routes/~console.$orgId/~controls/~register/~details.$id/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersDetailsIdRouteImport } from './routes/~console.$orgId/~configuration/~suppliers/~details.$id/~route';
import { Route as ConsoleOrgIdConfigurationBillingPaymentSuccessImport } from './routes/~console.$orgId/~configuration/~billing/~payment.success';
import { Route as ConsoleOrgIdComplianceTrainingUserUserIdRouteImport } from './routes/~console.$orgId/~compliance/~training/~user.$userId/~route';
import { Route as ConsoleOrgIdComplianceTrainingSearchAddImport } from './routes/~console.$orgId/~compliance/~training/~search/~add';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~product-type-data/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~policy-info/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdManageRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~manage/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdLibraryRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~library/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdHistoryRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~history/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdFinancialsRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~financials/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdExpensesRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~expenses/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdContactsRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~contacts/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdCommsRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~comms/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexImport } from './routes/~console.$orgId/~configuration/~product-types/~$productTypeId/~index';
import { Route as PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteImport } from './routes/~public/~$orgId/~claim.lodgement.$pdsVersionId.$questionnaireId.$productTypeId/~route';
import { Route as ConsoleOrgIdWorkloadManagerTasksListDetailsIdImport } from './routes/~console.$orgId/~workload-manager/~tasks/~list/~details.$id';
import { Route as ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdImport } from './routes/~console.$orgId/~workload-manager/~tasks/~calendar/~details.$id';
import { Route as ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationImport } from './routes/~console.$orgId/~tools/~auto-detect/~inbox/~$id/~review-email-classification';
import { Route as ConsoleOrgIdRiskRegisterDetailsIdSectionRouteImport } from './routes/~console.$orgId/~risk/~register/~details.$id.$section/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteImport } from './routes/~console.$orgId/~configuration/~product-types/~$productTypeId/~pds.$pdsId/~route';
import { Route as ConsoleOrgIdComplianceTrainingUserUserIdAddImport } from './routes/~console.$orgId/~compliance/~training/~user.$userId/~add';
import { Route as ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteImport } from './routes/~console.$orgId/~compliance/~obligations/~details.$id.$section/~route';
import { Route as ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteImport } from './routes/~console.$orgId/~compliance/~incidents/~details.$id.$section/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~manage/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~library/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~history/~route';
import { Route as ConsoleOrgIdComplianceCoiDetailsIdSectionRouteImport } from './routes/~console.$orgId/~compliance/~coi/~details.$id.$section/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdExpensesAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~expenses/~add/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdCommsAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~comms/~add/~route';
import { Route as ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdImport } from './routes/~console.$orgId/~compliance/~training/~user.$userId/~edit.$recordId';
import { Route as ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~manage/~strategies.add/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~manage/~flag.add/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~expenses/~$expenseId.edit/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~comms/~message.$messageId/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~manage/~note.add/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~manage/~flag.add/~route';

// Create/Update Routes

const SignUpRouteRoute = SignUpRouteImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any);

const PublicRouteRoute = PublicRouteImport.update({
  id: '/public',
  path: '/public',
  getParentRoute: () => rootRoute,
} as any);

const NotFoundRoute = NotFoundImport.update({
  id: '/not-found',
  path: '/not-found',
  getParentRoute: () => rootRoute,
} as any);

const MaintenanceRouteRoute = MaintenanceRouteImport.update({
  id: '/maintenance',
  path: '/maintenance',
  getParentRoute: () => rootRoute,
} as any);

const InviteRouteRoute = InviteRouteImport.update({
  id: '/invite',
  path: '/invite',
  getParentRoute: () => rootRoute,
} as any);

const AdminConsoleRouteRoute = AdminConsoleRouteImport.update({
  id: '/admin-console',
  path: '/admin-console',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const PublicOrgIdRouteRoute = PublicOrgIdRouteImport.update({
  id: '/$orgId',
  path: '/$orgId',
  getParentRoute: () => PublicRouteRoute,
} as any);

const ConsoleOrgIdRouteRoute = ConsoleOrgIdRouteImport.update({
  id: '/console/$orgId',
  path: '/console/$orgId',
  getParentRoute: () => rootRoute,
} as any);

const AdminConsoleUsersRouteRoute = AdminConsoleUsersRouteImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleReportsRouteRoute = AdminConsoleReportsRouteImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleProductsRouteRoute = AdminConsoleProductsRouteImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleOrganizationsRouteRoute =
  AdminConsoleOrganizationsRouteImport.update({
    id: '/organizations',
    path: '/organizations',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleObligationsRouteRoute =
  AdminConsoleObligationsRouteImport.update({
    id: '/obligations',
    path: '/obligations',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleNewOrganizationRouteRoute =
  AdminConsoleNewOrganizationRouteImport.update({
    id: '/new-organization',
    path: '/new-organization',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleFaqRouteRoute = AdminConsoleFaqRouteImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleControlsRouteRoute = AdminConsoleControlsRouteImport.update({
  id: '/controls',
  path: '/controls',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleConfigurationRouteRoute =
  AdminConsoleConfigurationRouteImport.update({
    id: '/configuration',
    path: '/configuration',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleCommTemplatesRouteRoute =
  AdminConsoleCommTemplatesRouteImport.update({
    id: '/comm-templates',
    path: '/comm-templates',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleAiPromptsRouteRoute = AdminConsoleAiPromptsRouteImport.update(
  {
    id: '/ai-prompts',
    path: '/ai-prompts',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any,
);

const AdminConsoleAdvancedRouteRoute = AdminConsoleAdvancedRouteImport.update({
  id: '/advanced',
  path: '/advanced',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleAdminPlaygroundRoute =
  AdminConsoleAdminPlaygroundImport.update({
    id: '/admin-playground',
    path: '/admin-playground',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const authenticationPaymentPendingRoute =
  authenticationPaymentPendingImport.update({
    id: '/(authentication)/payment-pending',
    path: '/payment-pending',
    getParentRoute: () => rootRoute,
  } as any);

const authenticationLogoutinRouteRoute =
  authenticationLogoutinRouteImport.update({
    id: '/(authentication)/logoutin',
    path: '/logoutin',
    getParentRoute: () => rootRoute,
  } as any);

const authenticationLogoutRouteRoute = authenticationLogoutRouteImport.update({
  id: '/(authentication)/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any);

const authenticationLoginRouteRoute = authenticationLoginRouteImport.update({
  id: '/(authentication)/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any);

const authenticationCallbackRouteRoute =
  authenticationCallbackRouteImport.update({
    id: '/(authentication)/callback',
    path: '/callback',
    getParentRoute: () => rootRoute,
  } as any);

const authenticationAdminLoginRouteRoute =
  authenticationAdminLoginRouteImport.update({
    id: '/(authentication)/admin-login',
    path: '/admin-login',
    getParentRoute: () => rootRoute,
  } as any);

const AdminConsoleIndexRoute = AdminConsoleIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const PublicNewComplaintOrgIdRouteRoute =
  PublicNewComplaintOrgIdRouteImport.update({
    id: '/new-complaint/$orgId',
    path: '/new-complaint/$orgId',
    getParentRoute: () => PublicRouteRoute,
  } as any);

const PublicNewComplaintByKeyKeyRouteRoute =
  PublicNewComplaintByKeyKeyRouteImport.update({
    id: '/new-complaint-by-key/$key',
    path: '/new-complaint-by-key/$key',
    getParentRoute: () => PublicRouteRoute,
  } as any);

const PublicLodgementSummaryAccessKeyRouteRoute =
  PublicLodgementSummaryAccessKeyRouteImport.update({
    id: '/lodgement-summary/$accessKey',
    path: '/lodgement-summary/$accessKey',
    getParentRoute: () => PublicRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerRouteRoute =
  ConsoleOrgIdWorkloadManagerRouteImport.update({
    id: '/workload-manager',
    path: '/workload-manager',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdUserSettingsRouteRoute =
  ConsoleOrgIdUserSettingsRouteImport.update({
    id: '/user-settings',
    path: '/user-settings',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdToolsOnlyHomePageRoute =
  ConsoleOrgIdToolsOnlyHomePageImport.update({
    id: '/tools-only-home-page',
    path: '/tools-only-home-page',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdToolsRouteRoute = ConsoleOrgIdToolsRouteImport.update({
  id: '/tools',
  path: '/tools',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdRiskRouteRoute = ConsoleOrgIdRiskRouteImport.update({
  id: '/risk',
  path: '/risk',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdReportingRouteRoute = ConsoleOrgIdReportingRouteImport.update(
  {
    id: '/reporting',
    path: '/reporting',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any,
);

const ConsoleOrgIdGetFileRouteRoute = ConsoleOrgIdGetFileRouteImport.update({
  id: '/get-file',
  path: '/get-file',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdFaqRouteRoute = ConsoleOrgIdFaqRouteImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdDebugRouteRoute = ConsoleOrgIdDebugRouteImport.update({
  id: '/debug',
  path: '/debug',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdDashboardRouteRoute = ConsoleOrgIdDashboardRouteImport.update(
  {
    id: '/dashboard',
    path: '/dashboard',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any,
);

const ConsoleOrgIdControlsRouteRoute = ConsoleOrgIdControlsRouteImport.update({
  id: '/controls',
  path: '/controls',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdConfigurationRouteRoute =
  ConsoleOrgIdConfigurationRouteImport.update({
    id: '/configuration',
    path: '/configuration',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceRouteRoute =
  ConsoleOrgIdComplianceRouteImport.update({
    id: '/compliance',
    path: '/compliance',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsRouteRoute = ConsoleOrgIdClaimsRouteImport.update({
  id: '/claims',
  path: '/claims',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdClaimModuleOnlyHomePageRoute =
  ConsoleOrgIdClaimModuleOnlyHomePageImport.update({
    id: '/claim-module-only-home-page',
    path: '/claim-module-only-home-page',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const AdminConsoleProductsServiceIdRoute =
  AdminConsoleProductsServiceIdImport.update({
    id: '/$serviceId',
    path: '/$serviceId',
    getParentRoute: () => AdminConsoleProductsRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdRouteRoute =
  AdminConsoleOrganizationsOrgIdRouteImport.update({
    id: '/$orgId',
    path: '/$orgId',
    getParentRoute: () => AdminConsoleOrganizationsRouteRoute,
  } as any);

const AdminConsoleObligationsLibraryRouteRoute =
  AdminConsoleObligationsLibraryRouteImport.update({
    id: '/library',
    path: '/library',
    getParentRoute: () => AdminConsoleObligationsRouteRoute,
  } as any);

const AdminConsoleControlsLibraryRouteRoute =
  AdminConsoleControlsLibraryRouteImport.update({
    id: '/library',
    path: '/library',
    getParentRoute: () => AdminConsoleControlsRouteRoute,
  } as any);

const AdminConsoleConfigurationObligationsRouteRoute =
  AdminConsoleConfigurationObligationsRouteImport.update({
    id: '/obligations',
    path: '/obligations',
    getParentRoute: () => AdminConsoleConfigurationRouteRoute,
  } as any);

const AdminConsoleAdvancedSystemLogsRoute =
  AdminConsoleAdvancedSystemLogsImport.update({
    id: '/system-logs',
    path: '/system-logs',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedMonitoringRoute =
  AdminConsoleAdvancedMonitoringImport.update({
    id: '/monitoring',
    path: '/monitoring',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsRouteRoute =
  AdminConsoleAdvancedJobsRouteImport.update({
    id: '/jobs',
    path: '/jobs',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedIndicatorsRouteRoute =
  AdminConsoleAdvancedIndicatorsRouteImport.update({
    id: '/indicators',
    path: '/indicators',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedDbUpgradesRoute =
  AdminConsoleAdvancedDbUpgradesImport.update({
    id: '/db-upgrades',
    path: '/db-upgrades',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedAppSettingsRoute =
  AdminConsoleAdvancedAppSettingsImport.update({
    id: '/app-settings',
    path: '/app-settings',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const authenticationChangeOrgOrgIdRouteRoute =
  authenticationChangeOrgOrgIdRouteImport.update({
    id: '/(authentication)/change-org/$orgId',
    path: '/change-org/$orgId',
    getParentRoute: () => rootRoute,
  } as any);

const ConsoleOrgIdIndexRoute = ConsoleOrgIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const AdminConsoleObligationsIndexRoute =
  AdminConsoleObligationsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConsoleObligationsRouteRoute,
  } as any);

const AdminConsoleControlsIndexRoute = AdminConsoleControlsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminConsoleControlsRouteRoute,
} as any);

const AdminConsoleConfigurationIndexRoute =
  AdminConsoleConfigurationIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConsoleConfigurationRouteRoute,
  } as any);

const AdminConsoleAdvancedIndexRoute = AdminConsoleAdvancedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminConsoleAdvancedRouteRoute,
} as any);

const PublicOrgIdComplaintNewRouteRoute =
  PublicOrgIdComplaintNewRouteImport.update({
    id: '/complaint/new',
    path: '/complaint/new',
    getParentRoute: () => PublicOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerTasksRouteRoute =
  ConsoleOrgIdWorkloadManagerTasksRouteImport.update({
    id: '/tasks',
    path: '/tasks',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerEmailsRouteRoute =
  ConsoleOrgIdWorkloadManagerEmailsRouteImport.update({
    id: '/emails',
    path: '/emails',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerRouteRoute,
  } as any);

const ConsoleOrgIdUserSettingsProfileRoute =
  ConsoleOrgIdUserSettingsProfileImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => ConsoleOrgIdUserSettingsRouteRoute,
  } as any);

const ConsoleOrgIdUserSettingsNotificationsRoute =
  ConsoleOrgIdUserSettingsNotificationsImport.update({
    id: '/notifications',
    path: '/notifications',
    getParentRoute: () => ConsoleOrgIdUserSettingsRouteRoute,
  } as any);

const ConsoleOrgIdToolsWebsiteCheckerRouteRoute =
  ConsoleOrgIdToolsWebsiteCheckerRouteImport.update({
    id: '/website-checker',
    path: '/website-checker',
    getParentRoute: () => ConsoleOrgIdToolsRouteRoute,
  } as any);

const ConsoleOrgIdToolsAutoDetectRouteRoute =
  ConsoleOrgIdToolsAutoDetectRouteImport.update({
    id: '/auto-detect',
    path: '/auto-detect',
    getParentRoute: () => ConsoleOrgIdToolsRouteRoute,
  } as any);

const ConsoleOrgIdRiskRegisterRouteRoute =
  ConsoleOrgIdRiskRegisterRouteImport.update({
    id: '/register',
    path: '/register',
    getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
  } as any);

const ConsoleOrgIdRiskRasRouteRoute = ConsoleOrgIdRiskRasRouteImport.update({
  id: '/ras',
  path: '/ras',
  getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
} as any);

const ConsoleOrgIdReportingAddRoute = ConsoleOrgIdReportingAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => ConsoleOrgIdReportingRouteRoute,
} as any);

const ConsoleOrgIdDebugTestRouteRoute = ConsoleOrgIdDebugTestRouteImport.update(
  {
    id: '/test',
    path: '/test',
    getParentRoute: () => ConsoleOrgIdDebugRouteRoute,
  } as any,
);

const ConsoleOrgIdDashboardSectionRouteRoute =
  ConsoleOrgIdDashboardSectionRouteImport.update({
    id: '/$section',
    path: '/$section',
    getParentRoute: () => ConsoleOrgIdDashboardRouteRoute,
  } as any);

const ConsoleOrgIdControlsTestsRouteRoute =
  ConsoleOrgIdControlsTestsRouteImport.update({
    id: '/tests',
    path: '/tests',
    getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
  } as any);

const ConsoleOrgIdControlsRegisterRouteRoute =
  ConsoleOrgIdControlsRegisterRouteImport.update({
    id: '/register',
    path: '/register',
    getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationUsersRouteRoute =
  ConsoleOrgIdConfigurationUsersRouteImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationTasksRouteRoute =
  ConsoleOrgIdConfigurationTasksRouteImport.update({
    id: '/tasks',
    path: '/tasks',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationTaskManagerRouteRoute =
  ConsoleOrgIdConfigurationTaskManagerRouteImport.update({
    id: '/task-manager',
    path: '/task-manager',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSystemRouteRoute =
  ConsoleOrgIdConfigurationSystemRouteImport.update({
    id: '/system',
    path: '/system',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersRouteRoute =
  ConsoleOrgIdConfigurationSuppliersRouteImport.update({
    id: '/suppliers',
    path: '/suppliers',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationStatisticsRouteRoute =
  ConsoleOrgIdConfigurationStatisticsRouteImport.update({
    id: '/statistics',
    path: '/statistics',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationRiskRouteRoute =
  ConsoleOrgIdConfigurationRiskRouteImport.update({
    id: '/risk',
    path: '/risk',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationQuestionnaireRouteRoute =
  ConsoleOrgIdConfigurationQuestionnaireRouteImport.update({
    id: '/questionnaire',
    path: '/questionnaire',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesRouteRoute =
  ConsoleOrgIdConfigurationProductTypesRouteImport.update({
    id: '/product-types',
    path: '/product-types',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationPaymentsRouteRoute =
  ConsoleOrgIdConfigurationPaymentsRouteImport.update({
    id: '/payments',
    path: '/payments',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationInsurersRouteRoute =
  ConsoleOrgIdConfigurationInsurersRouteImport.update({
    id: '/insurers',
    path: '/insurers',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationHandlingPartiesRouteRoute =
  ConsoleOrgIdConfigurationHandlingPartiesRouteImport.update({
    id: '/handling-parties',
    path: '/handling-parties',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute =
  ConsoleOrgIdConfigurationExpensesAndBillingRouteImport.update({
    id: '/expenses-and-billing',
    path: '/expenses-and-billing',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationDocumentsRouteRoute =
  ConsoleOrgIdConfigurationDocumentsRouteImport.update({
    id: '/documents',
    path: '/documents',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationComplianceRouteRoute =
  ConsoleOrgIdConfigurationComplianceRouteImport.update({
    id: '/compliance',
    path: '/compliance',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationCommsTemplatesRouteRoute =
  ConsoleOrgIdConfigurationCommsTemplatesRouteImport.update({
    id: '/comms-templates',
    path: '/comms-templates',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationClaimRouteRoute =
  ConsoleOrgIdConfigurationClaimRouteImport.update({
    id: '/claim',
    path: '/claim',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingRouteRoute =
  ConsoleOrgIdConfigurationBillingRouteImport.update({
    id: '/billing',
    path: '/billing',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationAssistanceRouteRoute =
  ConsoleOrgIdConfigurationAssistanceRouteImport.update({
    id: '/assistance',
    path: '/assistance',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingRouteRoute =
  ConsoleOrgIdComplianceTrainingRouteImport.update({
    id: '/training',
    path: '/training',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsRouteRoute =
  ConsoleOrgIdComplianceObligationsRouteImport.update({
    id: '/obligations',
    path: '/obligations',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsRouteRoute =
  ConsoleOrgIdComplianceIncidentsRouteImport.update({
    id: '/incidents',
    path: '/incidents',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsRouteRoute =
  ConsoleOrgIdComplianceComplaintsRouteImport.update({
    id: '/complaints',
    path: '/complaints',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiRouteRoute =
  ConsoleOrgIdComplianceCoiRouteImport.update({
    id: '/coi',
    path: '/coi',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdClaimsLodgeRouteRoute =
  ConsoleOrgIdClaimsLodgeRouteImport.update({
    id: '/lodge',
    path: '/lodge',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdWalletRoute =
  AdminConsoleOrganizationsOrgIdWalletImport.update({
    id: '/wallet',
    path: '/wallet',
    getParentRoute: () => AdminConsoleOrganizationsOrgIdRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdUsersRouteRoute =
  AdminConsoleOrganizationsOrgIdUsersRouteImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => AdminConsoleOrganizationsOrgIdRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdSubscriptionsRoute =
  AdminConsoleOrganizationsOrgIdSubscriptionsImport.update({
    id: '/subscriptions',
    path: '/subscriptions',
    getParentRoute: () => AdminConsoleOrganizationsOrgIdRouteRoute,
  } as any);

const AdminConsoleObligationsLibraryAddRouteRoute =
  AdminConsoleObligationsLibraryAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => AdminConsoleObligationsLibraryRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsRecurringRoute =
  AdminConsoleAdvancedJobsRecurringImport.update({
    id: '/recurring',
    path: '/recurring',
    getParentRoute: () => AdminConsoleAdvancedJobsRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsOnDemandRoute =
  AdminConsoleAdvancedJobsOnDemandImport.update({
    id: '/on-demand',
    path: '/on-demand',
    getParentRoute: () => AdminConsoleAdvancedJobsRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsDynamicRecurringRoute =
  AdminConsoleAdvancedJobsDynamicRecurringImport.update({
    id: '/dynamic-recurring',
    path: '/dynamic-recurring',
    getParentRoute: () => AdminConsoleAdvancedJobsRouteRoute,
  } as any);

const AdminConsoleAdvancedIndicatorsIncidentsRoute =
  AdminConsoleAdvancedIndicatorsIncidentsImport.update({
    id: '/incidents',
    path: '/incidents',
    getParentRoute: () => AdminConsoleAdvancedIndicatorsRouteRoute,
  } as any);

const AdminConsoleAdvancedIndicatorsComplaintsRoute =
  AdminConsoleAdvancedIndicatorsComplaintsImport.update({
    id: '/complaints',
    path: '/complaints',
    getParentRoute: () => AdminConsoleAdvancedIndicatorsRouteRoute,
  } as any);

const ConsoleOrgIdUserSettingsIndexRoute =
  ConsoleOrgIdUserSettingsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdUserSettingsRouteRoute,
  } as any);

const ConsoleOrgIdToolsIndexRoute = ConsoleOrgIdToolsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdToolsRouteRoute,
} as any);

const ConsoleOrgIdRiskIndexRoute = ConsoleOrgIdRiskIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
} as any);

const ConsoleOrgIdDebugIndexRoute = ConsoleOrgIdDebugIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdDebugRouteRoute,
} as any);

const ConsoleOrgIdDashboardIndexRoute = ConsoleOrgIdDashboardIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdDashboardRouteRoute,
  } as any,
);

const ConsoleOrgIdControlsIndexRoute = ConsoleOrgIdControlsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
} as any);

const ConsoleOrgIdConfigurationIndexRoute =
  ConsoleOrgIdConfigurationIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIndexRoute =
  ConsoleOrgIdComplianceIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdClaimsIndexRoute = ConsoleOrgIdClaimsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
} as any);

const AdminConsoleObligationsLibraryIndexRoute =
  AdminConsoleObligationsLibraryIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConsoleObligationsLibraryRouteRoute,
  } as any);

const AdminConsoleControlsLibraryIndexRoute =
  AdminConsoleControlsLibraryIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConsoleControlsLibraryRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsIndexRoute =
  AdminConsoleAdvancedJobsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConsoleAdvancedJobsRouteRoute,
  } as any);

const PublicOrgIdInvoiceViewAccessKeyRouteRoute =
  PublicOrgIdInvoiceViewAccessKeyRouteImport.update({
    id: '/invoice/view/$accessKey',
    path: '/invoice/view/$accessKey',
    getParentRoute: () => PublicOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerTasksListRouteRoute =
  ConsoleOrgIdWorkloadManagerTasksListRouteImport.update({
    id: '/list',
    path: '/list',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerTasksRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerTasksCalendarRouteRoute =
  ConsoleOrgIdWorkloadManagerTasksCalendarRouteImport.update({
    id: '/calendar',
    path: '/calendar',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerTasksRouteRoute,
  } as any);

const ConsoleOrgIdToolsAutoDetectInboxRouteRoute =
  ConsoleOrgIdToolsAutoDetectInboxRouteImport.update({
    id: '/inbox',
    path: '/inbox',
    getParentRoute: () => ConsoleOrgIdToolsAutoDetectRouteRoute,
  } as any);

const ConsoleOrgIdToolsAutoDetectDashboardRoute =
  ConsoleOrgIdToolsAutoDetectDashboardImport.update({
    id: '/dashboard',
    path: '/dashboard',
    getParentRoute: () => ConsoleOrgIdToolsAutoDetectRouteRoute,
  } as any);

const ConsoleOrgIdControlsRegisterAddRouteRoute =
  ConsoleOrgIdControlsRegisterAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdControlsRegisterRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersAddRoute =
  ConsoleOrgIdConfigurationSuppliersAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationRiskSectionRouteRoute =
  ConsoleOrgIdConfigurationRiskSectionRouteImport.update({
    id: '/$section',
    path: '/$section',
    getParentRoute: () => ConsoleOrgIdConfigurationRiskRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRoute =
  ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteImport.update({
    id: '/$productTypeId',
    path: '/$productTypeId',
    getParentRoute: () => ConsoleOrgIdConfigurationProductTypesRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingUsageRoute =
  ConsoleOrgIdConfigurationBillingUsageImport.update({
    id: '/usage',
    path: '/usage',
    getParentRoute: () => ConsoleOrgIdConfigurationBillingRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingTransactionsRoute =
  ConsoleOrgIdConfigurationBillingTransactionsImport.update({
    id: '/transactions',
    path: '/transactions',
    getParentRoute: () => ConsoleOrgIdConfigurationBillingRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingInvoicesRoute =
  ConsoleOrgIdConfigurationBillingInvoicesImport.update({
    id: '/invoices',
    path: '/invoices',
    getParentRoute: () => ConsoleOrgIdConfigurationBillingRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingBillingInfoRoute =
  ConsoleOrgIdConfigurationBillingBillingInfoImport.update({
    id: '/billing-info',
    path: '/billing-info',
    getParentRoute: () => ConsoleOrgIdConfigurationBillingRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingBalanceRoute =
  ConsoleOrgIdConfigurationBillingBalanceImport.update({
    id: '/balance',
    path: '/balance',
    getParentRoute: () => ConsoleOrgIdConfigurationBillingRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingSearchRouteRoute =
  ConsoleOrgIdComplianceTrainingSearchRouteImport.update({
    id: '/search',
    path: '/search',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsAddRouteRoute =
  ConsoleOrgIdComplianceObligationsAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceObligationsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsAddRoute =
  ConsoleOrgIdComplianceIncidentsAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceIncidentsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsAddRouteRoute =
  ConsoleOrgIdComplianceComplaintsAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiAddRouteRoute =
  ConsoleOrgIdComplianceCoiAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceCoiRouteRoute,
  } as any);

const ConsoleOrgIdClaimsViewIdRouteRoute =
  ConsoleOrgIdClaimsViewIdRouteImport.update({
    id: '/view/$id',
    path: '/view/$id',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const ConsoleOrgIdClaimsEditIdRouteRoute =
  ConsoleOrgIdClaimsEditIdRouteImport.update({
    id: '/edit/$id',
    path: '/edit/$id',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdRouteRoute =
  ConsoleOrgIdClaimsDetailsIdRouteImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const AdminConsoleObligationsLibraryIdSectionRouteRoute =
  AdminConsoleObligationsLibraryIdSectionRouteImport.update({
    id: '/$id/$section',
    path: '/$id/$section',
    getParentRoute: () => AdminConsoleObligationsLibraryRouteRoute,
  } as any);

const AdminConsoleControlsLibraryIdSectionRouteRoute =
  AdminConsoleControlsLibraryIdSectionRouteImport.update({
    id: '/$id/$section',
    path: '/$id/$section',
    getParentRoute: () => AdminConsoleControlsLibraryRouteRoute,
  } as any);

const ConsoleOrgIdRiskRegisterIndexRoute =
  ConsoleOrgIdRiskRegisterIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdRiskRegisterRouteRoute,
  } as any);

const ConsoleOrgIdRiskRasIndexRoute = ConsoleOrgIdRiskRasIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdRiskRasRouteRoute,
} as any);

const ConsoleOrgIdControlsRegisterIndexRoute =
  ConsoleOrgIdControlsRegisterIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdControlsRegisterRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersIndexRoute =
  ConsoleOrgIdConfigurationSuppliersIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationRiskIndexRoute =
  ConsoleOrgIdConfigurationRiskIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationRiskRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesIndexRoute =
  ConsoleOrgIdConfigurationProductTypesIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationProductTypesRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingIndexRoute =
  ConsoleOrgIdComplianceTrainingIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsIndexRoute =
  ConsoleOrgIdComplianceObligationsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdComplianceObligationsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsIndexRoute =
  ConsoleOrgIdComplianceIncidentsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdComplianceIncidentsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsIndexRoute =
  ConsoleOrgIdComplianceComplaintsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiIndexRoute =
  ConsoleOrgIdComplianceCoiIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdComplianceCoiRouteRoute,
  } as any);

const PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute =
  PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteImport.update(
    {
      id: '/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId',
      path: '/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId',
      getParentRoute: () => PublicRouteRoute,
    } as any,
  );

const ConsoleOrgIdWorkloadManagerTasksListAddRoute =
  ConsoleOrgIdWorkloadManagerTasksListAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerTasksListRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute =
  ConsoleOrgIdWorkloadManagerTasksCalendarAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerTasksCalendarRouteRoute,
  } as any);

const ConsoleOrgIdRiskRasDetailsIdRouteRoute =
  ConsoleOrgIdRiskRasDetailsIdRouteImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdRiskRasRouteRoute,
  } as any);

const ConsoleOrgIdControlsRegisterDetailsIdRouteRoute =
  ConsoleOrgIdControlsRegisterDetailsIdRouteImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdControlsRegisterRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute =
  ConsoleOrgIdConfigurationSuppliersDetailsIdRouteImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationBillingPaymentSuccessRoute =
  ConsoleOrgIdConfigurationBillingPaymentSuccessImport.update({
    id: '/payment/success',
    path: '/payment/success',
    getParentRoute: () => ConsoleOrgIdConfigurationBillingRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute =
  ConsoleOrgIdComplianceTrainingUserUserIdRouteImport.update({
    id: '/user/$userId',
    path: '/user/$userId',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingSearchAddRoute =
  ConsoleOrgIdComplianceTrainingSearchAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingSearchRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute =
  ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteImport.update({
    id: '/product-type-data',
    path: '/product-type-data',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute =
  ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteImport.update({
    id: '/policy-info',
    path: '/policy-info',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdManageRouteRoute =
  ConsoleOrgIdClaimsDetailsIdManageRouteImport.update({
    id: '/manage',
    path: '/manage',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute =
  ConsoleOrgIdClaimsDetailsIdLibraryRouteImport.update({
    id: '/library',
    path: '/library',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute =
  ConsoleOrgIdClaimsDetailsIdHistoryRouteImport.update({
    id: '/history',
    path: '/history',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute =
  ConsoleOrgIdClaimsDetailsIdFinancialsRouteImport.update({
    id: '/financials',
    path: '/financials',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute =
  ConsoleOrgIdClaimsDetailsIdExpensesRouteImport.update({
    id: '/expenses',
    path: '/expenses',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdContactsRouteRoute =
  ConsoleOrgIdClaimsDetailsIdContactsRouteImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdCommsRouteRoute =
  ConsoleOrgIdClaimsDetailsIdCommsRouteImport.update({
    id: '/comms',
    path: '/comms',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute =
  ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () =>
      ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRoute,
  } as any);

const PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute =
  PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteImport.update(
    {
      id: '/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId',
      path: '/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId',
      getParentRoute: () => PublicOrgIdRouteRoute,
    } as any,
  );

const ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute =
  ConsoleOrgIdWorkloadManagerTasksListDetailsIdImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerTasksListRouteRoute,
  } as any);

const ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute =
  ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdWorkloadManagerTasksCalendarRouteRoute,
  } as any);

const ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute =
  ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationImport.update({
    id: '/$id/review-email-classification',
    path: '/$id/review-email-classification',
    getParentRoute: () => ConsoleOrgIdToolsAutoDetectInboxRouteRoute,
  } as any);

const ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute =
  ConsoleOrgIdRiskRegisterDetailsIdSectionRouteImport.update({
    id: '/details/$id/$section',
    path: '/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdRiskRegisterRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute =
  ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteImport.update({
    id: '/pds/$pdsId',
    path: '/pds/$pdsId',
    getParentRoute: () =>
      ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingUserUserIdAddRoute =
  ConsoleOrgIdComplianceTrainingUserUserIdAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute =
  ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteImport.update({
    id: '/details/$id/$section',
    path: '/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdComplianceObligationsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute =
  ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteImport.update({
    id: '/details/$id/$section',
    path: '/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdComplianceIncidentsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport.update({
    id: '/manage',
    path: '/manage',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteImport.update({
    id: '/library',
    path: '/library',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteImport.update({
    id: '/history',
    path: '/history',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute =
  ConsoleOrgIdComplianceCoiDetailsIdSectionRouteImport.update({
    id: '/details/$id/$section',
    path: '/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdComplianceCoiRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdExpensesAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdCommsAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdCommsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute =
  ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdImport.update({
    id: '/edit/$recordId',
    path: '/edit/$recordId',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteImport.update({
    id: '/strategies/add',
    path: '/strategies/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdManageRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteImport.update({
    id: '/flag/add',
    path: '/flag/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdManageRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute =
  ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteImport.update({
    id: '/$expenseId/edit',
    path: '/$expenseId/edit',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute =
  ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteImport.update({
    id: '/message/$messageId',
    path: '/message/$messageId',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdCommsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteImport.update({
    id: '/note/add',
    path: '/note/add',
    getParentRoute: () =>
      ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteImport.update({
    id: '/flag/add',
    path: '/flag/add',
    getParentRoute: () =>
      ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console': {
      id: '/admin-console';
      path: '/admin-console';
      fullPath: '/admin-console';
      preLoaderRoute: typeof AdminConsoleRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/invite': {
      id: '/invite';
      path: '/invite';
      fullPath: '/invite';
      preLoaderRoute: typeof InviteRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/maintenance': {
      id: '/maintenance';
      path: '/maintenance';
      fullPath: '/maintenance';
      preLoaderRoute: typeof MaintenanceRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/not-found': {
      id: '/not-found';
      path: '/not-found';
      fullPath: '/not-found';
      preLoaderRoute: typeof NotFoundImport;
      parentRoute: typeof rootRoute;
    };
    '/public': {
      id: '/public';
      path: '/public';
      fullPath: '/public';
      preLoaderRoute: typeof PublicRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/sign-up': {
      id: '/sign-up';
      path: '/sign-up';
      fullPath: '/sign-up';
      preLoaderRoute: typeof SignUpRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console/': {
      id: '/admin-console/';
      path: '/';
      fullPath: '/admin-console/';
      preLoaderRoute: typeof AdminConsoleIndexImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/(authentication)/admin-login': {
      id: '/(authentication)/admin-login';
      path: '/admin-login';
      fullPath: '/admin-login';
      preLoaderRoute: typeof authenticationAdminLoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/callback': {
      id: '/(authentication)/callback';
      path: '/callback';
      fullPath: '/callback';
      preLoaderRoute: typeof authenticationCallbackRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/login': {
      id: '/(authentication)/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof authenticationLoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/logout': {
      id: '/(authentication)/logout';
      path: '/logout';
      fullPath: '/logout';
      preLoaderRoute: typeof authenticationLogoutRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/logoutin': {
      id: '/(authentication)/logoutin';
      path: '/logoutin';
      fullPath: '/logoutin';
      preLoaderRoute: typeof authenticationLogoutinRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/payment-pending': {
      id: '/(authentication)/payment-pending';
      path: '/payment-pending';
      fullPath: '/payment-pending';
      preLoaderRoute: typeof authenticationPaymentPendingImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console/admin-playground': {
      id: '/admin-console/admin-playground';
      path: '/admin-playground';
      fullPath: '/admin-console/admin-playground';
      preLoaderRoute: typeof AdminConsoleAdminPlaygroundImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/advanced': {
      id: '/admin-console/advanced';
      path: '/advanced';
      fullPath: '/admin-console/advanced';
      preLoaderRoute: typeof AdminConsoleAdvancedRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/ai-prompts': {
      id: '/admin-console/ai-prompts';
      path: '/ai-prompts';
      fullPath: '/admin-console/ai-prompts';
      preLoaderRoute: typeof AdminConsoleAiPromptsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/comm-templates': {
      id: '/admin-console/comm-templates';
      path: '/comm-templates';
      fullPath: '/admin-console/comm-templates';
      preLoaderRoute: typeof AdminConsoleCommTemplatesRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/configuration': {
      id: '/admin-console/configuration';
      path: '/configuration';
      fullPath: '/admin-console/configuration';
      preLoaderRoute: typeof AdminConsoleConfigurationRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/controls': {
      id: '/admin-console/controls';
      path: '/controls';
      fullPath: '/admin-console/controls';
      preLoaderRoute: typeof AdminConsoleControlsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/faq': {
      id: '/admin-console/faq';
      path: '/faq';
      fullPath: '/admin-console/faq';
      preLoaderRoute: typeof AdminConsoleFaqRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/new-organization': {
      id: '/admin-console/new-organization';
      path: '/new-organization';
      fullPath: '/admin-console/new-organization';
      preLoaderRoute: typeof AdminConsoleNewOrganizationRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/obligations': {
      id: '/admin-console/obligations';
      path: '/obligations';
      fullPath: '/admin-console/obligations';
      preLoaderRoute: typeof AdminConsoleObligationsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/organizations': {
      id: '/admin-console/organizations';
      path: '/organizations';
      fullPath: '/admin-console/organizations';
      preLoaderRoute: typeof AdminConsoleOrganizationsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/products': {
      id: '/admin-console/products';
      path: '/products';
      fullPath: '/admin-console/products';
      preLoaderRoute: typeof AdminConsoleProductsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/reports': {
      id: '/admin-console/reports';
      path: '/reports';
      fullPath: '/admin-console/reports';
      preLoaderRoute: typeof AdminConsoleReportsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/users': {
      id: '/admin-console/users';
      path: '/users';
      fullPath: '/admin-console/users';
      preLoaderRoute: typeof AdminConsoleUsersRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/console/$orgId': {
      id: '/console/$orgId';
      path: '/console/$orgId';
      fullPath: '/console/$orgId';
      preLoaderRoute: typeof ConsoleOrgIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/public/$orgId': {
      id: '/public/$orgId';
      path: '/$orgId';
      fullPath: '/public/$orgId';
      preLoaderRoute: typeof PublicOrgIdRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/admin-console/advanced/': {
      id: '/admin-console/advanced/';
      path: '/';
      fullPath: '/admin-console/advanced/';
      preLoaderRoute: typeof AdminConsoleAdvancedIndexImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/configuration/': {
      id: '/admin-console/configuration/';
      path: '/';
      fullPath: '/admin-console/configuration/';
      preLoaderRoute: typeof AdminConsoleConfigurationIndexImport;
      parentRoute: typeof AdminConsoleConfigurationRouteImport;
    };
    '/admin-console/controls/': {
      id: '/admin-console/controls/';
      path: '/';
      fullPath: '/admin-console/controls/';
      preLoaderRoute: typeof AdminConsoleControlsIndexImport;
      parentRoute: typeof AdminConsoleControlsRouteImport;
    };
    '/admin-console/obligations/': {
      id: '/admin-console/obligations/';
      path: '/';
      fullPath: '/admin-console/obligations/';
      preLoaderRoute: typeof AdminConsoleObligationsIndexImport;
      parentRoute: typeof AdminConsoleObligationsRouteImport;
    };
    '/console/$orgId/': {
      id: '/console/$orgId/';
      path: '/';
      fullPath: '/console/$orgId/';
      preLoaderRoute: typeof ConsoleOrgIdIndexImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/(authentication)/change-org/$orgId': {
      id: '/(authentication)/change-org/$orgId';
      path: '/change-org/$orgId';
      fullPath: '/change-org/$orgId';
      preLoaderRoute: typeof authenticationChangeOrgOrgIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console/advanced/app-settings': {
      id: '/admin-console/advanced/app-settings';
      path: '/app-settings';
      fullPath: '/admin-console/advanced/app-settings';
      preLoaderRoute: typeof AdminConsoleAdvancedAppSettingsImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/db-upgrades': {
      id: '/admin-console/advanced/db-upgrades';
      path: '/db-upgrades';
      fullPath: '/admin-console/advanced/db-upgrades';
      preLoaderRoute: typeof AdminConsoleAdvancedDbUpgradesImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/indicators': {
      id: '/admin-console/advanced/indicators';
      path: '/indicators';
      fullPath: '/admin-console/advanced/indicators';
      preLoaderRoute: typeof AdminConsoleAdvancedIndicatorsRouteImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/jobs': {
      id: '/admin-console/advanced/jobs';
      path: '/jobs';
      fullPath: '/admin-console/advanced/jobs';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsRouteImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/monitoring': {
      id: '/admin-console/advanced/monitoring';
      path: '/monitoring';
      fullPath: '/admin-console/advanced/monitoring';
      preLoaderRoute: typeof AdminConsoleAdvancedMonitoringImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/system-logs': {
      id: '/admin-console/advanced/system-logs';
      path: '/system-logs';
      fullPath: '/admin-console/advanced/system-logs';
      preLoaderRoute: typeof AdminConsoleAdvancedSystemLogsImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/configuration/obligations': {
      id: '/admin-console/configuration/obligations';
      path: '/obligations';
      fullPath: '/admin-console/configuration/obligations';
      preLoaderRoute: typeof AdminConsoleConfigurationObligationsRouteImport;
      parentRoute: typeof AdminConsoleConfigurationRouteImport;
    };
    '/admin-console/controls/library': {
      id: '/admin-console/controls/library';
      path: '/library';
      fullPath: '/admin-console/controls/library';
      preLoaderRoute: typeof AdminConsoleControlsLibraryRouteImport;
      parentRoute: typeof AdminConsoleControlsRouteImport;
    };
    '/admin-console/obligations/library': {
      id: '/admin-console/obligations/library';
      path: '/library';
      fullPath: '/admin-console/obligations/library';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryRouteImport;
      parentRoute: typeof AdminConsoleObligationsRouteImport;
    };
    '/admin-console/organizations/$orgId': {
      id: '/admin-console/organizations/$orgId';
      path: '/$orgId';
      fullPath: '/admin-console/organizations/$orgId';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdRouteImport;
      parentRoute: typeof AdminConsoleOrganizationsRouteImport;
    };
    '/admin-console/products/$serviceId': {
      id: '/admin-console/products/$serviceId';
      path: '/$serviceId';
      fullPath: '/admin-console/products/$serviceId';
      preLoaderRoute: typeof AdminConsoleProductsServiceIdImport;
      parentRoute: typeof AdminConsoleProductsRouteImport;
    };
    '/console/$orgId/claim-module-only-home-page': {
      id: '/console/$orgId/claim-module-only-home-page';
      path: '/claim-module-only-home-page';
      fullPath: '/console/$orgId/claim-module-only-home-page';
      preLoaderRoute: typeof ConsoleOrgIdClaimModuleOnlyHomePageImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/claims': {
      id: '/console/$orgId/claims';
      path: '/claims';
      fullPath: '/console/$orgId/claims';
      preLoaderRoute: typeof ConsoleOrgIdClaimsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/compliance': {
      id: '/console/$orgId/compliance';
      path: '/compliance';
      fullPath: '/console/$orgId/compliance';
      preLoaderRoute: typeof ConsoleOrgIdComplianceRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/configuration': {
      id: '/console/$orgId/configuration';
      path: '/configuration';
      fullPath: '/console/$orgId/configuration';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/controls': {
      id: '/console/$orgId/controls';
      path: '/controls';
      fullPath: '/console/$orgId/controls';
      preLoaderRoute: typeof ConsoleOrgIdControlsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/dashboard': {
      id: '/console/$orgId/dashboard';
      path: '/dashboard';
      fullPath: '/console/$orgId/dashboard';
      preLoaderRoute: typeof ConsoleOrgIdDashboardRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/debug': {
      id: '/console/$orgId/debug';
      path: '/debug';
      fullPath: '/console/$orgId/debug';
      preLoaderRoute: typeof ConsoleOrgIdDebugRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/faq': {
      id: '/console/$orgId/faq';
      path: '/faq';
      fullPath: '/console/$orgId/faq';
      preLoaderRoute: typeof ConsoleOrgIdFaqRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/get-file': {
      id: '/console/$orgId/get-file';
      path: '/get-file';
      fullPath: '/console/$orgId/get-file';
      preLoaderRoute: typeof ConsoleOrgIdGetFileRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/reporting': {
      id: '/console/$orgId/reporting';
      path: '/reporting';
      fullPath: '/console/$orgId/reporting';
      preLoaderRoute: typeof ConsoleOrgIdReportingRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/risk': {
      id: '/console/$orgId/risk';
      path: '/risk';
      fullPath: '/console/$orgId/risk';
      preLoaderRoute: typeof ConsoleOrgIdRiskRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/tools': {
      id: '/console/$orgId/tools';
      path: '/tools';
      fullPath: '/console/$orgId/tools';
      preLoaderRoute: typeof ConsoleOrgIdToolsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/tools-only-home-page': {
      id: '/console/$orgId/tools-only-home-page';
      path: '/tools-only-home-page';
      fullPath: '/console/$orgId/tools-only-home-page';
      preLoaderRoute: typeof ConsoleOrgIdToolsOnlyHomePageImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/user-settings': {
      id: '/console/$orgId/user-settings';
      path: '/user-settings';
      fullPath: '/console/$orgId/user-settings';
      preLoaderRoute: typeof ConsoleOrgIdUserSettingsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/workload-manager': {
      id: '/console/$orgId/workload-manager';
      path: '/workload-manager';
      fullPath: '/console/$orgId/workload-manager';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/public/lodgement-summary/$accessKey': {
      id: '/public/lodgement-summary/$accessKey';
      path: '/lodgement-summary/$accessKey';
      fullPath: '/public/lodgement-summary/$accessKey';
      preLoaderRoute: typeof PublicLodgementSummaryAccessKeyRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/public/new-complaint-by-key/$key': {
      id: '/public/new-complaint-by-key/$key';
      path: '/new-complaint-by-key/$key';
      fullPath: '/public/new-complaint-by-key/$key';
      preLoaderRoute: typeof PublicNewComplaintByKeyKeyRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/public/new-complaint/$orgId': {
      id: '/public/new-complaint/$orgId';
      path: '/new-complaint/$orgId';
      fullPath: '/public/new-complaint/$orgId';
      preLoaderRoute: typeof PublicNewComplaintOrgIdRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/admin-console/advanced/jobs/': {
      id: '/admin-console/advanced/jobs/';
      path: '/';
      fullPath: '/admin-console/advanced/jobs/';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsIndexImport;
      parentRoute: typeof AdminConsoleAdvancedJobsRouteImport;
    };
    '/admin-console/controls/library/': {
      id: '/admin-console/controls/library/';
      path: '/';
      fullPath: '/admin-console/controls/library/';
      preLoaderRoute: typeof AdminConsoleControlsLibraryIndexImport;
      parentRoute: typeof AdminConsoleControlsLibraryRouteImport;
    };
    '/admin-console/obligations/library/': {
      id: '/admin-console/obligations/library/';
      path: '/';
      fullPath: '/admin-console/obligations/library/';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryIndexImport;
      parentRoute: typeof AdminConsoleObligationsLibraryRouteImport;
    };
    '/console/$orgId/claims/': {
      id: '/console/$orgId/claims/';
      path: '/';
      fullPath: '/console/$orgId/claims/';
      preLoaderRoute: typeof ConsoleOrgIdClaimsIndexImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/compliance/': {
      id: '/console/$orgId/compliance/';
      path: '/';
      fullPath: '/console/$orgId/compliance/';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIndexImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/configuration/': {
      id: '/console/$orgId/configuration/';
      path: '/';
      fullPath: '/console/$orgId/configuration/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/controls/': {
      id: '/console/$orgId/controls/';
      path: '/';
      fullPath: '/console/$orgId/controls/';
      preLoaderRoute: typeof ConsoleOrgIdControlsIndexImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/dashboard/': {
      id: '/console/$orgId/dashboard/';
      path: '/';
      fullPath: '/console/$orgId/dashboard/';
      preLoaderRoute: typeof ConsoleOrgIdDashboardIndexImport;
      parentRoute: typeof ConsoleOrgIdDashboardRouteImport;
    };
    '/console/$orgId/debug/': {
      id: '/console/$orgId/debug/';
      path: '/';
      fullPath: '/console/$orgId/debug/';
      preLoaderRoute: typeof ConsoleOrgIdDebugIndexImport;
      parentRoute: typeof ConsoleOrgIdDebugRouteImport;
    };
    '/console/$orgId/risk/': {
      id: '/console/$orgId/risk/';
      path: '/';
      fullPath: '/console/$orgId/risk/';
      preLoaderRoute: typeof ConsoleOrgIdRiskIndexImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/console/$orgId/tools/': {
      id: '/console/$orgId/tools/';
      path: '/';
      fullPath: '/console/$orgId/tools/';
      preLoaderRoute: typeof ConsoleOrgIdToolsIndexImport;
      parentRoute: typeof ConsoleOrgIdToolsRouteImport;
    };
    '/console/$orgId/user-settings/': {
      id: '/console/$orgId/user-settings/';
      path: '/';
      fullPath: '/console/$orgId/user-settings/';
      preLoaderRoute: typeof ConsoleOrgIdUserSettingsIndexImport;
      parentRoute: typeof ConsoleOrgIdUserSettingsRouteImport;
    };
    '/admin-console/advanced/indicators/complaints': {
      id: '/admin-console/advanced/indicators/complaints';
      path: '/complaints';
      fullPath: '/admin-console/advanced/indicators/complaints';
      preLoaderRoute: typeof AdminConsoleAdvancedIndicatorsComplaintsImport;
      parentRoute: typeof AdminConsoleAdvancedIndicatorsRouteImport;
    };
    '/admin-console/advanced/indicators/incidents': {
      id: '/admin-console/advanced/indicators/incidents';
      path: '/incidents';
      fullPath: '/admin-console/advanced/indicators/incidents';
      preLoaderRoute: typeof AdminConsoleAdvancedIndicatorsIncidentsImport;
      parentRoute: typeof AdminConsoleAdvancedIndicatorsRouteImport;
    };
    '/admin-console/advanced/jobs/dynamic-recurring': {
      id: '/admin-console/advanced/jobs/dynamic-recurring';
      path: '/dynamic-recurring';
      fullPath: '/admin-console/advanced/jobs/dynamic-recurring';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsDynamicRecurringImport;
      parentRoute: typeof AdminConsoleAdvancedJobsRouteImport;
    };
    '/admin-console/advanced/jobs/on-demand': {
      id: '/admin-console/advanced/jobs/on-demand';
      path: '/on-demand';
      fullPath: '/admin-console/advanced/jobs/on-demand';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsOnDemandImport;
      parentRoute: typeof AdminConsoleAdvancedJobsRouteImport;
    };
    '/admin-console/advanced/jobs/recurring': {
      id: '/admin-console/advanced/jobs/recurring';
      path: '/recurring';
      fullPath: '/admin-console/advanced/jobs/recurring';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsRecurringImport;
      parentRoute: typeof AdminConsoleAdvancedJobsRouteImport;
    };
    '/admin-console/obligations/library/add': {
      id: '/admin-console/obligations/library/add';
      path: '/add';
      fullPath: '/admin-console/obligations/library/add';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryAddRouteImport;
      parentRoute: typeof AdminConsoleObligationsLibraryRouteImport;
    };
    '/admin-console/organizations/$orgId/subscriptions': {
      id: '/admin-console/organizations/$orgId/subscriptions';
      path: '/subscriptions';
      fullPath: '/admin-console/organizations/$orgId/subscriptions';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdSubscriptionsImport;
      parentRoute: typeof AdminConsoleOrganizationsOrgIdRouteImport;
    };
    '/admin-console/organizations/$orgId/users': {
      id: '/admin-console/organizations/$orgId/users';
      path: '/users';
      fullPath: '/admin-console/organizations/$orgId/users';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdUsersRouteImport;
      parentRoute: typeof AdminConsoleOrganizationsOrgIdRouteImport;
    };
    '/admin-console/organizations/$orgId/wallet': {
      id: '/admin-console/organizations/$orgId/wallet';
      path: '/wallet';
      fullPath: '/admin-console/organizations/$orgId/wallet';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdWalletImport;
      parentRoute: typeof AdminConsoleOrganizationsOrgIdRouteImport;
    };
    '/console/$orgId/claims/lodge': {
      id: '/console/$orgId/claims/lodge';
      path: '/lodge';
      fullPath: '/console/$orgId/claims/lodge';
      preLoaderRoute: typeof ConsoleOrgIdClaimsLodgeRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/compliance/coi': {
      id: '/console/$orgId/compliance/coi';
      path: '/coi';
      fullPath: '/console/$orgId/compliance/coi';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/complaints': {
      id: '/console/$orgId/compliance/complaints';
      path: '/complaints';
      fullPath: '/console/$orgId/compliance/complaints';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/incidents': {
      id: '/console/$orgId/compliance/incidents';
      path: '/incidents';
      fullPath: '/console/$orgId/compliance/incidents';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/obligations': {
      id: '/console/$orgId/compliance/obligations';
      path: '/obligations';
      fullPath: '/console/$orgId/compliance/obligations';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/training': {
      id: '/console/$orgId/compliance/training';
      path: '/training';
      fullPath: '/console/$orgId/compliance/training';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/configuration/assistance': {
      id: '/console/$orgId/configuration/assistance';
      path: '/assistance';
      fullPath: '/console/$orgId/configuration/assistance';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationAssistanceRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/billing': {
      id: '/console/$orgId/configuration/billing';
      path: '/billing';
      fullPath: '/console/$orgId/configuration/billing';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/claim': {
      id: '/console/$orgId/configuration/claim';
      path: '/claim';
      fullPath: '/console/$orgId/configuration/claim';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationClaimRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/comms-templates': {
      id: '/console/$orgId/configuration/comms-templates';
      path: '/comms-templates';
      fullPath: '/console/$orgId/configuration/comms-templates';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationCommsTemplatesRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/compliance': {
      id: '/console/$orgId/configuration/compliance';
      path: '/compliance';
      fullPath: '/console/$orgId/configuration/compliance';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationComplianceRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/documents': {
      id: '/console/$orgId/configuration/documents';
      path: '/documents';
      fullPath: '/console/$orgId/configuration/documents';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationDocumentsRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/expenses-and-billing': {
      id: '/console/$orgId/configuration/expenses-and-billing';
      path: '/expenses-and-billing';
      fullPath: '/console/$orgId/configuration/expenses-and-billing';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/handling-parties': {
      id: '/console/$orgId/configuration/handling-parties';
      path: '/handling-parties';
      fullPath: '/console/$orgId/configuration/handling-parties';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationHandlingPartiesRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/insurers': {
      id: '/console/$orgId/configuration/insurers';
      path: '/insurers';
      fullPath: '/console/$orgId/configuration/insurers';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationInsurersRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/payments': {
      id: '/console/$orgId/configuration/payments';
      path: '/payments';
      fullPath: '/console/$orgId/configuration/payments';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationPaymentsRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/product-types': {
      id: '/console/$orgId/configuration/product-types';
      path: '/product-types';
      fullPath: '/console/$orgId/configuration/product-types';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/questionnaire': {
      id: '/console/$orgId/configuration/questionnaire';
      path: '/questionnaire';
      fullPath: '/console/$orgId/configuration/questionnaire';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationQuestionnaireRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/risk': {
      id: '/console/$orgId/configuration/risk';
      path: '/risk';
      fullPath: '/console/$orgId/configuration/risk';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRiskRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/statistics': {
      id: '/console/$orgId/configuration/statistics';
      path: '/statistics';
      fullPath: '/console/$orgId/configuration/statistics';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationStatisticsRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/suppliers': {
      id: '/console/$orgId/configuration/suppliers';
      path: '/suppliers';
      fullPath: '/console/$orgId/configuration/suppliers';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/system': {
      id: '/console/$orgId/configuration/system';
      path: '/system';
      fullPath: '/console/$orgId/configuration/system';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSystemRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/task-manager': {
      id: '/console/$orgId/configuration/task-manager';
      path: '/task-manager';
      fullPath: '/console/$orgId/configuration/task-manager';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationTaskManagerRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/tasks': {
      id: '/console/$orgId/configuration/tasks';
      path: '/tasks';
      fullPath: '/console/$orgId/configuration/tasks';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationTasksRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/users': {
      id: '/console/$orgId/configuration/users';
      path: '/users';
      fullPath: '/console/$orgId/configuration/users';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationUsersRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/controls/register': {
      id: '/console/$orgId/controls/register';
      path: '/register';
      fullPath: '/console/$orgId/controls/register';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/controls/tests': {
      id: '/console/$orgId/controls/tests';
      path: '/tests';
      fullPath: '/console/$orgId/controls/tests';
      preLoaderRoute: typeof ConsoleOrgIdControlsTestsRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/dashboard/$section': {
      id: '/console/$orgId/dashboard/$section';
      path: '/$section';
      fullPath: '/console/$orgId/dashboard/$section';
      preLoaderRoute: typeof ConsoleOrgIdDashboardSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdDashboardRouteImport;
    };
    '/console/$orgId/debug/test': {
      id: '/console/$orgId/debug/test';
      path: '/test';
      fullPath: '/console/$orgId/debug/test';
      preLoaderRoute: typeof ConsoleOrgIdDebugTestRouteImport;
      parentRoute: typeof ConsoleOrgIdDebugRouteImport;
    };
    '/console/$orgId/reporting/add': {
      id: '/console/$orgId/reporting/add';
      path: '/add';
      fullPath: '/console/$orgId/reporting/add';
      preLoaderRoute: typeof ConsoleOrgIdReportingAddImport;
      parentRoute: typeof ConsoleOrgIdReportingRouteImport;
    };
    '/console/$orgId/risk/ras': {
      id: '/console/$orgId/risk/ras';
      path: '/ras';
      fullPath: '/console/$orgId/risk/ras';
      preLoaderRoute: typeof ConsoleOrgIdRiskRasRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/console/$orgId/risk/register': {
      id: '/console/$orgId/risk/register';
      path: '/register';
      fullPath: '/console/$orgId/risk/register';
      preLoaderRoute: typeof ConsoleOrgIdRiskRegisterRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/console/$orgId/tools/auto-detect': {
      id: '/console/$orgId/tools/auto-detect';
      path: '/auto-detect';
      fullPath: '/console/$orgId/tools/auto-detect';
      preLoaderRoute: typeof ConsoleOrgIdToolsAutoDetectRouteImport;
      parentRoute: typeof ConsoleOrgIdToolsRouteImport;
    };
    '/console/$orgId/tools/website-checker': {
      id: '/console/$orgId/tools/website-checker';
      path: '/website-checker';
      fullPath: '/console/$orgId/tools/website-checker';
      preLoaderRoute: typeof ConsoleOrgIdToolsWebsiteCheckerRouteImport;
      parentRoute: typeof ConsoleOrgIdToolsRouteImport;
    };
    '/console/$orgId/user-settings/notifications': {
      id: '/console/$orgId/user-settings/notifications';
      path: '/notifications';
      fullPath: '/console/$orgId/user-settings/notifications';
      preLoaderRoute: typeof ConsoleOrgIdUserSettingsNotificationsImport;
      parentRoute: typeof ConsoleOrgIdUserSettingsRouteImport;
    };
    '/console/$orgId/user-settings/profile': {
      id: '/console/$orgId/user-settings/profile';
      path: '/profile';
      fullPath: '/console/$orgId/user-settings/profile';
      preLoaderRoute: typeof ConsoleOrgIdUserSettingsProfileImport;
      parentRoute: typeof ConsoleOrgIdUserSettingsRouteImport;
    };
    '/console/$orgId/workload-manager/emails': {
      id: '/console/$orgId/workload-manager/emails';
      path: '/emails';
      fullPath: '/console/$orgId/workload-manager/emails';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerEmailsRouteImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerRouteImport;
    };
    '/console/$orgId/workload-manager/tasks': {
      id: '/console/$orgId/workload-manager/tasks';
      path: '/tasks';
      fullPath: '/console/$orgId/workload-manager/tasks';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksRouteImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerRouteImport;
    };
    '/public/$orgId/complaint/new': {
      id: '/public/$orgId/complaint/new';
      path: '/complaint/new';
      fullPath: '/public/$orgId/complaint/new';
      preLoaderRoute: typeof PublicOrgIdComplaintNewRouteImport;
      parentRoute: typeof PublicOrgIdRouteImport;
    };
    '/console/$orgId/compliance/coi/': {
      id: '/console/$orgId/compliance/coi/';
      path: '/';
      fullPath: '/console/$orgId/compliance/coi/';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiIndexImport;
      parentRoute: typeof ConsoleOrgIdComplianceCoiRouteImport;
    };
    '/console/$orgId/compliance/complaints/': {
      id: '/console/$orgId/compliance/complaints/';
      path: '/';
      fullPath: '/console/$orgId/compliance/complaints/';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsIndexImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsRouteImport;
    };
    '/console/$orgId/compliance/incidents/': {
      id: '/console/$orgId/compliance/incidents/';
      path: '/';
      fullPath: '/console/$orgId/compliance/incidents/';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsIndexImport;
      parentRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
    };
    '/console/$orgId/compliance/obligations/': {
      id: '/console/$orgId/compliance/obligations/';
      path: '/';
      fullPath: '/console/$orgId/compliance/obligations/';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsIndexImport;
      parentRoute: typeof ConsoleOrgIdComplianceObligationsRouteImport;
    };
    '/console/$orgId/compliance/training/': {
      id: '/console/$orgId/compliance/training/';
      path: '/';
      fullPath: '/console/$orgId/compliance/training/';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingIndexImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingRouteImport;
    };
    '/console/$orgId/configuration/product-types/': {
      id: '/console/$orgId/configuration/product-types/';
      path: '/';
      fullPath: '/console/$orgId/configuration/product-types/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
    };
    '/console/$orgId/configuration/risk/': {
      id: '/console/$orgId/configuration/risk/';
      path: '/';
      fullPath: '/console/$orgId/configuration/risk/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRiskIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRiskRouteImport;
    };
    '/console/$orgId/configuration/suppliers/': {
      id: '/console/$orgId/configuration/suppliers/';
      path: '/';
      fullPath: '/console/$orgId/configuration/suppliers/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/console/$orgId/controls/register/': {
      id: '/console/$orgId/controls/register/';
      path: '/';
      fullPath: '/console/$orgId/controls/register/';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterIndexImport;
      parentRoute: typeof ConsoleOrgIdControlsRegisterRouteImport;
    };
    '/console/$orgId/risk/ras/': {
      id: '/console/$orgId/risk/ras/';
      path: '/';
      fullPath: '/console/$orgId/risk/ras/';
      preLoaderRoute: typeof ConsoleOrgIdRiskRasIndexImport;
      parentRoute: typeof ConsoleOrgIdRiskRasRouteImport;
    };
    '/console/$orgId/risk/register/': {
      id: '/console/$orgId/risk/register/';
      path: '/';
      fullPath: '/console/$orgId/risk/register/';
      preLoaderRoute: typeof ConsoleOrgIdRiskRegisterIndexImport;
      parentRoute: typeof ConsoleOrgIdRiskRegisterRouteImport;
    };
    '/admin-console/controls/library/$id/$section': {
      id: '/admin-console/controls/library/$id/$section';
      path: '/$id/$section';
      fullPath: '/admin-console/controls/library/$id/$section';
      preLoaderRoute: typeof AdminConsoleControlsLibraryIdSectionRouteImport;
      parentRoute: typeof AdminConsoleControlsLibraryRouteImport;
    };
    '/admin-console/obligations/library/$id/$section': {
      id: '/admin-console/obligations/library/$id/$section';
      path: '/$id/$section';
      fullPath: '/admin-console/obligations/library/$id/$section';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryIdSectionRouteImport;
      parentRoute: typeof AdminConsoleObligationsLibraryRouteImport;
    };
    '/console/$orgId/claims/details/$id': {
      id: '/console/$orgId/claims/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/claims/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/claims/edit/$id': {
      id: '/console/$orgId/claims/edit/$id';
      path: '/edit/$id';
      fullPath: '/console/$orgId/claims/edit/$id';
      preLoaderRoute: typeof ConsoleOrgIdClaimsEditIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/claims/view/$id': {
      id: '/console/$orgId/claims/view/$id';
      path: '/view/$id';
      fullPath: '/console/$orgId/claims/view/$id';
      preLoaderRoute: typeof ConsoleOrgIdClaimsViewIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/compliance/coi/add': {
      id: '/console/$orgId/compliance/coi/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/coi/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceCoiRouteImport;
    };
    '/console/$orgId/compliance/complaints/add': {
      id: '/console/$orgId/compliance/complaints/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/complaints/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsRouteImport;
    };
    '/console/$orgId/compliance/incidents/add': {
      id: '/console/$orgId/compliance/incidents/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/incidents/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsAddImport;
      parentRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
    };
    '/console/$orgId/compliance/obligations/add': {
      id: '/console/$orgId/compliance/obligations/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/obligations/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceObligationsRouteImport;
    };
    '/console/$orgId/compliance/training/search': {
      id: '/console/$orgId/compliance/training/search';
      path: '/search';
      fullPath: '/console/$orgId/compliance/training/search';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingRouteImport;
    };
    '/console/$orgId/configuration/billing/balance': {
      id: '/console/$orgId/configuration/billing/balance';
      path: '/balance';
      fullPath: '/console/$orgId/configuration/billing/balance';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingBalanceImport;
      parentRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
    };
    '/console/$orgId/configuration/billing/billing-info': {
      id: '/console/$orgId/configuration/billing/billing-info';
      path: '/billing-info';
      fullPath: '/console/$orgId/configuration/billing/billing-info';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingBillingInfoImport;
      parentRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
    };
    '/console/$orgId/configuration/billing/invoices': {
      id: '/console/$orgId/configuration/billing/invoices';
      path: '/invoices';
      fullPath: '/console/$orgId/configuration/billing/invoices';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingInvoicesImport;
      parentRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
    };
    '/console/$orgId/configuration/billing/transactions': {
      id: '/console/$orgId/configuration/billing/transactions';
      path: '/transactions';
      fullPath: '/console/$orgId/configuration/billing/transactions';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingTransactionsImport;
      parentRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
    };
    '/console/$orgId/configuration/billing/usage': {
      id: '/console/$orgId/configuration/billing/usage';
      path: '/usage';
      fullPath: '/console/$orgId/configuration/billing/usage';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingUsageImport;
      parentRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
    };
    '/console/$orgId/configuration/product-types/$productTypeId': {
      id: '/console/$orgId/configuration/product-types/$productTypeId';
      path: '/$productTypeId';
      fullPath: '/console/$orgId/configuration/product-types/$productTypeId';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
    };
    '/console/$orgId/configuration/risk/$section': {
      id: '/console/$orgId/configuration/risk/$section';
      path: '/$section';
      fullPath: '/console/$orgId/configuration/risk/$section';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRiskSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRiskRouteImport;
    };
    '/console/$orgId/configuration/suppliers/add': {
      id: '/console/$orgId/configuration/suppliers/add';
      path: '/add';
      fullPath: '/console/$orgId/configuration/suppliers/add';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersAddImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/console/$orgId/controls/register/add': {
      id: '/console/$orgId/controls/register/add';
      path: '/add';
      fullPath: '/console/$orgId/controls/register/add';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterAddRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRegisterRouteImport;
    };
    '/console/$orgId/tools/auto-detect/dashboard': {
      id: '/console/$orgId/tools/auto-detect/dashboard';
      path: '/dashboard';
      fullPath: '/console/$orgId/tools/auto-detect/dashboard';
      preLoaderRoute: typeof ConsoleOrgIdToolsAutoDetectDashboardImport;
      parentRoute: typeof ConsoleOrgIdToolsAutoDetectRouteImport;
    };
    '/console/$orgId/tools/auto-detect/inbox': {
      id: '/console/$orgId/tools/auto-detect/inbox';
      path: '/inbox';
      fullPath: '/console/$orgId/tools/auto-detect/inbox';
      preLoaderRoute: typeof ConsoleOrgIdToolsAutoDetectInboxRouteImport;
      parentRoute: typeof ConsoleOrgIdToolsAutoDetectRouteImport;
    };
    '/console/$orgId/workload-manager/tasks/calendar': {
      id: '/console/$orgId/workload-manager/tasks/calendar';
      path: '/calendar';
      fullPath: '/console/$orgId/workload-manager/tasks/calendar';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerTasksRouteImport;
    };
    '/console/$orgId/workload-manager/tasks/list': {
      id: '/console/$orgId/workload-manager/tasks/list';
      path: '/list';
      fullPath: '/console/$orgId/workload-manager/tasks/list';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksListRouteImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerTasksRouteImport;
    };
    '/public/$orgId/invoice/view/$accessKey': {
      id: '/public/$orgId/invoice/view/$accessKey';
      path: '/invoice/view/$accessKey';
      fullPath: '/public/$orgId/invoice/view/$accessKey';
      preLoaderRoute: typeof PublicOrgIdInvoiceViewAccessKeyRouteImport;
      parentRoute: typeof PublicOrgIdRouteImport;
    };
    '/console/$orgId/configuration/product-types/$productTypeId/': {
      id: '/console/$orgId/configuration/product-types/$productTypeId/';
      path: '/';
      fullPath: '/console/$orgId/configuration/product-types/$productTypeId/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/comms': {
      id: '/console/$orgId/claims/details/$id/comms';
      path: '/comms';
      fullPath: '/console/$orgId/claims/details/$id/comms';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/contacts': {
      id: '/console/$orgId/claims/details/$id/contacts';
      path: '/contacts';
      fullPath: '/console/$orgId/claims/details/$id/contacts';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdContactsRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/expenses': {
      id: '/console/$orgId/claims/details/$id/expenses';
      path: '/expenses';
      fullPath: '/console/$orgId/claims/details/$id/expenses';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/financials': {
      id: '/console/$orgId/claims/details/$id/financials';
      path: '/financials';
      fullPath: '/console/$orgId/claims/details/$id/financials';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/history': {
      id: '/console/$orgId/claims/details/$id/history';
      path: '/history';
      fullPath: '/console/$orgId/claims/details/$id/history';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/library': {
      id: '/console/$orgId/claims/details/$id/library';
      path: '/library';
      fullPath: '/console/$orgId/claims/details/$id/library';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/manage': {
      id: '/console/$orgId/claims/details/$id/manage';
      path: '/manage';
      fullPath: '/console/$orgId/claims/details/$id/manage';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/policy-info': {
      id: '/console/$orgId/claims/details/$id/policy-info';
      path: '/policy-info';
      fullPath: '/console/$orgId/claims/details/$id/policy-info';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/product-type-data': {
      id: '/console/$orgId/claims/details/$id/product-type-data';
      path: '/product-type-data';
      fullPath: '/console/$orgId/claims/details/$id/product-type-data';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id': {
      id: '/console/$orgId/compliance/complaints/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/compliance/complaints/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsRouteImport;
    };
    '/console/$orgId/compliance/training/search/add': {
      id: '/console/$orgId/compliance/training/search/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/training/search/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingSearchAddImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingSearchRouteImport;
    };
    '/console/$orgId/compliance/training/user/$userId': {
      id: '/console/$orgId/compliance/training/user/$userId';
      path: '/user/$userId';
      fullPath: '/console/$orgId/compliance/training/user/$userId';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingRouteImport;
    };
    '/console/$orgId/configuration/billing/payment/success': {
      id: '/console/$orgId/configuration/billing/payment/success';
      path: '/payment/success';
      fullPath: '/console/$orgId/configuration/billing/payment/success';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationBillingPaymentSuccessImport;
      parentRoute: typeof ConsoleOrgIdConfigurationBillingRouteImport;
    };
    '/console/$orgId/configuration/suppliers/details/$id': {
      id: '/console/$orgId/configuration/suppliers/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/configuration/suppliers/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/console/$orgId/controls/register/details/$id': {
      id: '/console/$orgId/controls/register/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/controls/register/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRegisterRouteImport;
    };
    '/console/$orgId/risk/ras/details/$id': {
      id: '/console/$orgId/risk/ras/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/risk/ras/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdRiskRasDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRasRouteImport;
    };
    '/console/$orgId/workload-manager/tasks/calendar/add': {
      id: '/console/$orgId/workload-manager/tasks/calendar/add';
      path: '/add';
      fullPath: '/console/$orgId/workload-manager/tasks/calendar/add';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarAddImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteImport;
    };
    '/console/$orgId/workload-manager/tasks/list/add': {
      id: '/console/$orgId/workload-manager/tasks/list/add';
      path: '/add';
      fullPath: '/console/$orgId/workload-manager/tasks/list/add';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksListAddImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerTasksListRouteImport;
    };
    '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': {
      id: '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId';
      path: '/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId';
      fullPath: '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId';
      preLoaderRoute: typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/console/$orgId/claims/details/$id/comms/add': {
      id: '/console/$orgId/claims/details/$id/comms/add';
      path: '/add';
      fullPath: '/console/$orgId/claims/details/$id/comms/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteImport;
    };
    '/console/$orgId/claims/details/$id/expenses/add': {
      id: '/console/$orgId/claims/details/$id/expenses/add';
      path: '/add';
      fullPath: '/console/$orgId/claims/details/$id/expenses/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteImport;
    };
    '/console/$orgId/compliance/coi/details/$id/$section': {
      id: '/console/$orgId/compliance/coi/details/$id/$section';
      path: '/details/$id/$section';
      fullPath: '/console/$orgId/compliance/coi/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceCoiRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/history': {
      id: '/console/$orgId/compliance/complaints/details/$id/history';
      path: '/history';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/history';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/library': {
      id: '/console/$orgId/compliance/complaints/details/$id/library';
      path: '/library';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/library';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/manage': {
      id: '/console/$orgId/compliance/complaints/details/$id/manage';
      path: '/manage';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/manage';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/incidents/details/$id/$section': {
      id: '/console/$orgId/compliance/incidents/details/$id/$section';
      path: '/details/$id/$section';
      fullPath: '/console/$orgId/compliance/incidents/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
    };
    '/console/$orgId/compliance/obligations/details/$id/$section': {
      id: '/console/$orgId/compliance/obligations/details/$id/$section';
      path: '/details/$id/$section';
      fullPath: '/console/$orgId/compliance/obligations/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceObligationsRouteImport;
    };
    '/console/$orgId/compliance/training/user/$userId/add': {
      id: '/console/$orgId/compliance/training/user/$userId/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/training/user/$userId/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdAddImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteImport;
    };
    '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': {
      id: '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId';
      path: '/pds/$pdsId';
      fullPath: '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteImport;
    };
    '/console/$orgId/risk/register/details/$id/$section': {
      id: '/console/$orgId/risk/register/details/$id/$section';
      path: '/details/$id/$section';
      fullPath: '/console/$orgId/risk/register/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRegisterRouteImport;
    };
    '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification': {
      id: '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification';
      path: '/$id/review-email-classification';
      fullPath: '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification';
      preLoaderRoute: typeof ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationImport;
      parentRoute: typeof ConsoleOrgIdToolsAutoDetectInboxRouteImport;
    };
    '/console/$orgId/workload-manager/tasks/calendar/details/$id': {
      id: '/console/$orgId/workload-manager/tasks/calendar/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/workload-manager/tasks/calendar/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteImport;
    };
    '/console/$orgId/workload-manager/tasks/list/details/$id': {
      id: '/console/$orgId/workload-manager/tasks/list/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/workload-manager/tasks/list/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdWorkloadManagerTasksListDetailsIdImport;
      parentRoute: typeof ConsoleOrgIdWorkloadManagerTasksListRouteImport;
    };
    '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': {
      id: '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId';
      path: '/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId';
      fullPath: '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId';
      preLoaderRoute: typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteImport;
      parentRoute: typeof PublicOrgIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/comms/message/$messageId': {
      id: '/console/$orgId/claims/details/$id/comms/message/$messageId';
      path: '/message/$messageId';
      fullPath: '/console/$orgId/claims/details/$id/comms/message/$messageId';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteImport;
    };
    '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': {
      id: '/console/$orgId/claims/details/$id/expenses/$expenseId/edit';
      path: '/$expenseId/edit';
      fullPath: '/console/$orgId/claims/details/$id/expenses/$expenseId/edit';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteImport;
    };
    '/console/$orgId/claims/details/$id/manage/flag/add': {
      id: '/console/$orgId/claims/details/$id/manage/flag/add';
      path: '/flag/add';
      fullPath: '/console/$orgId/claims/details/$id/manage/flag/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteImport;
    };
    '/console/$orgId/claims/details/$id/manage/strategies/add': {
      id: '/console/$orgId/claims/details/$id/manage/strategies/add';
      path: '/strategies/add';
      fullPath: '/console/$orgId/claims/details/$id/manage/strategies/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteImport;
    };
    '/console/$orgId/compliance/training/user/$userId/edit/$recordId': {
      id: '/console/$orgId/compliance/training/user/$userId/edit/$recordId';
      path: '/edit/$recordId';
      fullPath: '/console/$orgId/compliance/training/user/$userId/edit/$recordId';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': {
      id: '/console/$orgId/compliance/complaints/details/$id/manage/flag/add';
      path: '/flag/add';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/manage/flag/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/manage/note/add': {
      id: '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
      path: '/note/add';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport;
    };
  }
}

// Create and export the route tree

interface AdminConsoleAdvancedIndicatorsRouteRouteChildren {
  AdminConsoleAdvancedIndicatorsComplaintsRoute: typeof AdminConsoleAdvancedIndicatorsComplaintsRoute;
  AdminConsoleAdvancedIndicatorsIncidentsRoute: typeof AdminConsoleAdvancedIndicatorsIncidentsRoute;
}

const AdminConsoleAdvancedIndicatorsRouteRouteChildren: AdminConsoleAdvancedIndicatorsRouteRouteChildren =
  {
    AdminConsoleAdvancedIndicatorsComplaintsRoute:
      AdminConsoleAdvancedIndicatorsComplaintsRoute,
    AdminConsoleAdvancedIndicatorsIncidentsRoute:
      AdminConsoleAdvancedIndicatorsIncidentsRoute,
  };

const AdminConsoleAdvancedIndicatorsRouteRouteWithChildren =
  AdminConsoleAdvancedIndicatorsRouteRoute._addFileChildren(
    AdminConsoleAdvancedIndicatorsRouteRouteChildren,
  );

interface AdminConsoleAdvancedJobsRouteRouteChildren {
  AdminConsoleAdvancedJobsIndexRoute: typeof AdminConsoleAdvancedJobsIndexRoute;
  AdminConsoleAdvancedJobsDynamicRecurringRoute: typeof AdminConsoleAdvancedJobsDynamicRecurringRoute;
  AdminConsoleAdvancedJobsOnDemandRoute: typeof AdminConsoleAdvancedJobsOnDemandRoute;
  AdminConsoleAdvancedJobsRecurringRoute: typeof AdminConsoleAdvancedJobsRecurringRoute;
}

const AdminConsoleAdvancedJobsRouteRouteChildren: AdminConsoleAdvancedJobsRouteRouteChildren =
  {
    AdminConsoleAdvancedJobsIndexRoute: AdminConsoleAdvancedJobsIndexRoute,
    AdminConsoleAdvancedJobsDynamicRecurringRoute:
      AdminConsoleAdvancedJobsDynamicRecurringRoute,
    AdminConsoleAdvancedJobsOnDemandRoute:
      AdminConsoleAdvancedJobsOnDemandRoute,
    AdminConsoleAdvancedJobsRecurringRoute:
      AdminConsoleAdvancedJobsRecurringRoute,
  };

const AdminConsoleAdvancedJobsRouteRouteWithChildren =
  AdminConsoleAdvancedJobsRouteRoute._addFileChildren(
    AdminConsoleAdvancedJobsRouteRouteChildren,
  );

interface AdminConsoleAdvancedRouteRouteChildren {
  AdminConsoleAdvancedIndexRoute: typeof AdminConsoleAdvancedIndexRoute;
  AdminConsoleAdvancedAppSettingsRoute: typeof AdminConsoleAdvancedAppSettingsRoute;
  AdminConsoleAdvancedDbUpgradesRoute: typeof AdminConsoleAdvancedDbUpgradesRoute;
  AdminConsoleAdvancedIndicatorsRouteRoute: typeof AdminConsoleAdvancedIndicatorsRouteRouteWithChildren;
  AdminConsoleAdvancedJobsRouteRoute: typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
  AdminConsoleAdvancedMonitoringRoute: typeof AdminConsoleAdvancedMonitoringRoute;
  AdminConsoleAdvancedSystemLogsRoute: typeof AdminConsoleAdvancedSystemLogsRoute;
}

const AdminConsoleAdvancedRouteRouteChildren: AdminConsoleAdvancedRouteRouteChildren =
  {
    AdminConsoleAdvancedIndexRoute: AdminConsoleAdvancedIndexRoute,
    AdminConsoleAdvancedAppSettingsRoute: AdminConsoleAdvancedAppSettingsRoute,
    AdminConsoleAdvancedDbUpgradesRoute: AdminConsoleAdvancedDbUpgradesRoute,
    AdminConsoleAdvancedIndicatorsRouteRoute:
      AdminConsoleAdvancedIndicatorsRouteRouteWithChildren,
    AdminConsoleAdvancedJobsRouteRoute:
      AdminConsoleAdvancedJobsRouteRouteWithChildren,
    AdminConsoleAdvancedMonitoringRoute: AdminConsoleAdvancedMonitoringRoute,
    AdminConsoleAdvancedSystemLogsRoute: AdminConsoleAdvancedSystemLogsRoute,
  };

const AdminConsoleAdvancedRouteRouteWithChildren =
  AdminConsoleAdvancedRouteRoute._addFileChildren(
    AdminConsoleAdvancedRouteRouteChildren,
  );

interface AdminConsoleConfigurationRouteRouteChildren {
  AdminConsoleConfigurationIndexRoute: typeof AdminConsoleConfigurationIndexRoute;
  AdminConsoleConfigurationObligationsRouteRoute: typeof AdminConsoleConfigurationObligationsRouteRoute;
}

const AdminConsoleConfigurationRouteRouteChildren: AdminConsoleConfigurationRouteRouteChildren =
  {
    AdminConsoleConfigurationIndexRoute: AdminConsoleConfigurationIndexRoute,
    AdminConsoleConfigurationObligationsRouteRoute:
      AdminConsoleConfigurationObligationsRouteRoute,
  };

const AdminConsoleConfigurationRouteRouteWithChildren =
  AdminConsoleConfigurationRouteRoute._addFileChildren(
    AdminConsoleConfigurationRouteRouteChildren,
  );

interface AdminConsoleControlsLibraryRouteRouteChildren {
  AdminConsoleControlsLibraryIndexRoute: typeof AdminConsoleControlsLibraryIndexRoute;
  AdminConsoleControlsLibraryIdSectionRouteRoute: typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
}

const AdminConsoleControlsLibraryRouteRouteChildren: AdminConsoleControlsLibraryRouteRouteChildren =
  {
    AdminConsoleControlsLibraryIndexRoute:
      AdminConsoleControlsLibraryIndexRoute,
    AdminConsoleControlsLibraryIdSectionRouteRoute:
      AdminConsoleControlsLibraryIdSectionRouteRoute,
  };

const AdminConsoleControlsLibraryRouteRouteWithChildren =
  AdminConsoleControlsLibraryRouteRoute._addFileChildren(
    AdminConsoleControlsLibraryRouteRouteChildren,
  );

interface AdminConsoleControlsRouteRouteChildren {
  AdminConsoleControlsIndexRoute: typeof AdminConsoleControlsIndexRoute;
  AdminConsoleControlsLibraryRouteRoute: typeof AdminConsoleControlsLibraryRouteRouteWithChildren;
}

const AdminConsoleControlsRouteRouteChildren: AdminConsoleControlsRouteRouteChildren =
  {
    AdminConsoleControlsIndexRoute: AdminConsoleControlsIndexRoute,
    AdminConsoleControlsLibraryRouteRoute:
      AdminConsoleControlsLibraryRouteRouteWithChildren,
  };

const AdminConsoleControlsRouteRouteWithChildren =
  AdminConsoleControlsRouteRoute._addFileChildren(
    AdminConsoleControlsRouteRouteChildren,
  );

interface AdminConsoleObligationsLibraryRouteRouteChildren {
  AdminConsoleObligationsLibraryIndexRoute: typeof AdminConsoleObligationsLibraryIndexRoute;
  AdminConsoleObligationsLibraryAddRouteRoute: typeof AdminConsoleObligationsLibraryAddRouteRoute;
  AdminConsoleObligationsLibraryIdSectionRouteRoute: typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
}

const AdminConsoleObligationsLibraryRouteRouteChildren: AdminConsoleObligationsLibraryRouteRouteChildren =
  {
    AdminConsoleObligationsLibraryIndexRoute:
      AdminConsoleObligationsLibraryIndexRoute,
    AdminConsoleObligationsLibraryAddRouteRoute:
      AdminConsoleObligationsLibraryAddRouteRoute,
    AdminConsoleObligationsLibraryIdSectionRouteRoute:
      AdminConsoleObligationsLibraryIdSectionRouteRoute,
  };

const AdminConsoleObligationsLibraryRouteRouteWithChildren =
  AdminConsoleObligationsLibraryRouteRoute._addFileChildren(
    AdminConsoleObligationsLibraryRouteRouteChildren,
  );

interface AdminConsoleObligationsRouteRouteChildren {
  AdminConsoleObligationsIndexRoute: typeof AdminConsoleObligationsIndexRoute;
  AdminConsoleObligationsLibraryRouteRoute: typeof AdminConsoleObligationsLibraryRouteRouteWithChildren;
}

const AdminConsoleObligationsRouteRouteChildren: AdminConsoleObligationsRouteRouteChildren =
  {
    AdminConsoleObligationsIndexRoute: AdminConsoleObligationsIndexRoute,
    AdminConsoleObligationsLibraryRouteRoute:
      AdminConsoleObligationsLibraryRouteRouteWithChildren,
  };

const AdminConsoleObligationsRouteRouteWithChildren =
  AdminConsoleObligationsRouteRoute._addFileChildren(
    AdminConsoleObligationsRouteRouteChildren,
  );

interface AdminConsoleOrganizationsOrgIdRouteRouteChildren {
  AdminConsoleOrganizationsOrgIdSubscriptionsRoute: typeof AdminConsoleOrganizationsOrgIdSubscriptionsRoute;
  AdminConsoleOrganizationsOrgIdUsersRouteRoute: typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  AdminConsoleOrganizationsOrgIdWalletRoute: typeof AdminConsoleOrganizationsOrgIdWalletRoute;
}

const AdminConsoleOrganizationsOrgIdRouteRouteChildren: AdminConsoleOrganizationsOrgIdRouteRouteChildren =
  {
    AdminConsoleOrganizationsOrgIdSubscriptionsRoute:
      AdminConsoleOrganizationsOrgIdSubscriptionsRoute,
    AdminConsoleOrganizationsOrgIdUsersRouteRoute:
      AdminConsoleOrganizationsOrgIdUsersRouteRoute,
    AdminConsoleOrganizationsOrgIdWalletRoute:
      AdminConsoleOrganizationsOrgIdWalletRoute,
  };

const AdminConsoleOrganizationsOrgIdRouteRouteWithChildren =
  AdminConsoleOrganizationsOrgIdRouteRoute._addFileChildren(
    AdminConsoleOrganizationsOrgIdRouteRouteChildren,
  );

interface AdminConsoleOrganizationsRouteRouteChildren {
  AdminConsoleOrganizationsOrgIdRouteRoute: typeof AdminConsoleOrganizationsOrgIdRouteRouteWithChildren;
}

const AdminConsoleOrganizationsRouteRouteChildren: AdminConsoleOrganizationsRouteRouteChildren =
  {
    AdminConsoleOrganizationsOrgIdRouteRoute:
      AdminConsoleOrganizationsOrgIdRouteRouteWithChildren,
  };

const AdminConsoleOrganizationsRouteRouteWithChildren =
  AdminConsoleOrganizationsRouteRoute._addFileChildren(
    AdminConsoleOrganizationsRouteRouteChildren,
  );

interface AdminConsoleProductsRouteRouteChildren {
  AdminConsoleProductsServiceIdRoute: typeof AdminConsoleProductsServiceIdRoute;
}

const AdminConsoleProductsRouteRouteChildren: AdminConsoleProductsRouteRouteChildren =
  {
    AdminConsoleProductsServiceIdRoute: AdminConsoleProductsServiceIdRoute,
  };

const AdminConsoleProductsRouteRouteWithChildren =
  AdminConsoleProductsRouteRoute._addFileChildren(
    AdminConsoleProductsRouteRouteChildren,
  );

interface AdminConsoleRouteRouteChildren {
  AdminConsoleIndexRoute: typeof AdminConsoleIndexRoute;
  AdminConsoleAdminPlaygroundRoute: typeof AdminConsoleAdminPlaygroundRoute;
  AdminConsoleAdvancedRouteRoute: typeof AdminConsoleAdvancedRouteRouteWithChildren;
  AdminConsoleAiPromptsRouteRoute: typeof AdminConsoleAiPromptsRouteRoute;
  AdminConsoleCommTemplatesRouteRoute: typeof AdminConsoleCommTemplatesRouteRoute;
  AdminConsoleConfigurationRouteRoute: typeof AdminConsoleConfigurationRouteRouteWithChildren;
  AdminConsoleControlsRouteRoute: typeof AdminConsoleControlsRouteRouteWithChildren;
  AdminConsoleFaqRouteRoute: typeof AdminConsoleFaqRouteRoute;
  AdminConsoleNewOrganizationRouteRoute: typeof AdminConsoleNewOrganizationRouteRoute;
  AdminConsoleObligationsRouteRoute: typeof AdminConsoleObligationsRouteRouteWithChildren;
  AdminConsoleOrganizationsRouteRoute: typeof AdminConsoleOrganizationsRouteRouteWithChildren;
  AdminConsoleProductsRouteRoute: typeof AdminConsoleProductsRouteRouteWithChildren;
  AdminConsoleReportsRouteRoute: typeof AdminConsoleReportsRouteRoute;
  AdminConsoleUsersRouteRoute: typeof AdminConsoleUsersRouteRoute;
}

const AdminConsoleRouteRouteChildren: AdminConsoleRouteRouteChildren = {
  AdminConsoleIndexRoute: AdminConsoleIndexRoute,
  AdminConsoleAdminPlaygroundRoute: AdminConsoleAdminPlaygroundRoute,
  AdminConsoleAdvancedRouteRoute: AdminConsoleAdvancedRouteRouteWithChildren,
  AdminConsoleAiPromptsRouteRoute: AdminConsoleAiPromptsRouteRoute,
  AdminConsoleCommTemplatesRouteRoute: AdminConsoleCommTemplatesRouteRoute,
  AdminConsoleConfigurationRouteRoute:
    AdminConsoleConfigurationRouteRouteWithChildren,
  AdminConsoleControlsRouteRoute: AdminConsoleControlsRouteRouteWithChildren,
  AdminConsoleFaqRouteRoute: AdminConsoleFaqRouteRoute,
  AdminConsoleNewOrganizationRouteRoute: AdminConsoleNewOrganizationRouteRoute,
  AdminConsoleObligationsRouteRoute:
    AdminConsoleObligationsRouteRouteWithChildren,
  AdminConsoleOrganizationsRouteRoute:
    AdminConsoleOrganizationsRouteRouteWithChildren,
  AdminConsoleProductsRouteRoute: AdminConsoleProductsRouteRouteWithChildren,
  AdminConsoleReportsRouteRoute: AdminConsoleReportsRouteRoute,
  AdminConsoleUsersRouteRoute: AdminConsoleUsersRouteRoute,
};

const AdminConsoleRouteRouteWithChildren =
  AdminConsoleRouteRoute._addFileChildren(AdminConsoleRouteRouteChildren);

interface PublicOrgIdRouteRouteChildren {
  PublicOrgIdComplaintNewRouteRoute: typeof PublicOrgIdComplaintNewRouteRoute;
  PublicOrgIdInvoiceViewAccessKeyRouteRoute: typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute: typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
}

const PublicOrgIdRouteRouteChildren: PublicOrgIdRouteRouteChildren = {
  PublicOrgIdComplaintNewRouteRoute: PublicOrgIdComplaintNewRouteRoute,
  PublicOrgIdInvoiceViewAccessKeyRouteRoute:
    PublicOrgIdInvoiceViewAccessKeyRouteRoute,
  PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute:
    PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute,
};

const PublicOrgIdRouteRouteWithChildren =
  PublicOrgIdRouteRoute._addFileChildren(PublicOrgIdRouteRouteChildren);

interface PublicRouteRouteChildren {
  PublicOrgIdRouteRoute: typeof PublicOrgIdRouteRouteWithChildren;
  PublicLodgementSummaryAccessKeyRouteRoute: typeof PublicLodgementSummaryAccessKeyRouteRoute;
  PublicNewComplaintByKeyKeyRouteRoute: typeof PublicNewComplaintByKeyKeyRouteRoute;
  PublicNewComplaintOrgIdRouteRoute: typeof PublicNewComplaintOrgIdRouteRoute;
  PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute: typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
}

const PublicRouteRouteChildren: PublicRouteRouteChildren = {
  PublicOrgIdRouteRoute: PublicOrgIdRouteRouteWithChildren,
  PublicLodgementSummaryAccessKeyRouteRoute:
    PublicLodgementSummaryAccessKeyRouteRoute,
  PublicNewComplaintByKeyKeyRouteRoute: PublicNewComplaintByKeyKeyRouteRoute,
  PublicNewComplaintOrgIdRouteRoute: PublicNewComplaintOrgIdRouteRoute,
  PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute:
    PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute,
};

const PublicRouteRouteWithChildren = PublicRouteRoute._addFileChildren(
  PublicRouteRouteChildren,
);

interface ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute,
    ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute:
      ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdCommsRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute,
    ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute:
      ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute,
    ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdManageRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsDetailsIdRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdCommsRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  ConsoleOrgIdClaimsDetailsIdContactsRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  ConsoleOrgIdClaimsDetailsIdManageRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdCommsRouteRoute:
      ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren,
    ConsoleOrgIdClaimsDetailsIdContactsRouteRoute:
      ConsoleOrgIdClaimsDetailsIdContactsRouteRoute,
    ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute:
      ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren,
    ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute:
      ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute,
    ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute:
      ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute,
    ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute:
      ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute,
    ConsoleOrgIdClaimsDetailsIdManageRouteRoute:
      ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren,
    ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute:
      ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute,
    ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute:
      ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsRouteRouteChildren {
  ConsoleOrgIdClaimsIndexRoute: typeof ConsoleOrgIdClaimsIndexRoute;
  ConsoleOrgIdClaimsLodgeRouteRoute: typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  ConsoleOrgIdClaimsDetailsIdRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  ConsoleOrgIdClaimsEditIdRouteRoute: typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  ConsoleOrgIdClaimsViewIdRouteRoute: typeof ConsoleOrgIdClaimsViewIdRouteRoute;
}

const ConsoleOrgIdClaimsRouteRouteChildren: ConsoleOrgIdClaimsRouteRouteChildren =
  {
    ConsoleOrgIdClaimsIndexRoute: ConsoleOrgIdClaimsIndexRoute,
    ConsoleOrgIdClaimsLodgeRouteRoute: ConsoleOrgIdClaimsLodgeRouteRoute,
    ConsoleOrgIdClaimsDetailsIdRouteRoute:
      ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren,
    ConsoleOrgIdClaimsEditIdRouteRoute: ConsoleOrgIdClaimsEditIdRouteRoute,
    ConsoleOrgIdClaimsViewIdRouteRoute: ConsoleOrgIdClaimsViewIdRouteRoute,
  };

const ConsoleOrgIdClaimsRouteRouteWithChildren =
  ConsoleOrgIdClaimsRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceCoiRouteRouteChildren {
  ConsoleOrgIdComplianceCoiIndexRoute: typeof ConsoleOrgIdComplianceCoiIndexRoute;
  ConsoleOrgIdComplianceCoiAddRouteRoute: typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute: typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdComplianceCoiRouteRouteChildren: ConsoleOrgIdComplianceCoiRouteRouteChildren =
  {
    ConsoleOrgIdComplianceCoiIndexRoute: ConsoleOrgIdComplianceCoiIndexRoute,
    ConsoleOrgIdComplianceCoiAddRouteRoute:
      ConsoleOrgIdComplianceCoiAddRouteRoute,
    ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute:
      ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute,
  };

const ConsoleOrgIdComplianceCoiRouteRouteWithChildren =
  ConsoleOrgIdComplianceCoiRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceCoiRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren {
  ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

const ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren: ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren =
  {
    ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute,
  };

const ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren {
  ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
}

const ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren: ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren =
  {
    ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren,
  };

const ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren =
  ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceComplaintsRouteRouteChildren {
  ConsoleOrgIdComplianceComplaintsIndexRoute: typeof ConsoleOrgIdComplianceComplaintsIndexRoute;
  ConsoleOrgIdComplianceComplaintsAddRouteRoute: typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
}

const ConsoleOrgIdComplianceComplaintsRouteRouteChildren: ConsoleOrgIdComplianceComplaintsRouteRouteChildren =
  {
    ConsoleOrgIdComplianceComplaintsIndexRoute:
      ConsoleOrgIdComplianceComplaintsIndexRoute,
    ConsoleOrgIdComplianceComplaintsAddRouteRoute:
      ConsoleOrgIdComplianceComplaintsAddRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren,
  };

const ConsoleOrgIdComplianceComplaintsRouteRouteWithChildren =
  ConsoleOrgIdComplianceComplaintsRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceComplaintsRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceIncidentsRouteRouteChildren {
  ConsoleOrgIdComplianceIncidentsIndexRoute: typeof ConsoleOrgIdComplianceIncidentsIndexRoute;
  ConsoleOrgIdComplianceIncidentsAddRoute: typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute: typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdComplianceIncidentsRouteRouteChildren: ConsoleOrgIdComplianceIncidentsRouteRouteChildren =
  {
    ConsoleOrgIdComplianceIncidentsIndexRoute:
      ConsoleOrgIdComplianceIncidentsIndexRoute,
    ConsoleOrgIdComplianceIncidentsAddRoute:
      ConsoleOrgIdComplianceIncidentsAddRoute,
    ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute:
      ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute,
  };

const ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren =
  ConsoleOrgIdComplianceIncidentsRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceIncidentsRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceObligationsRouteRouteChildren {
  ConsoleOrgIdComplianceObligationsIndexRoute: typeof ConsoleOrgIdComplianceObligationsIndexRoute;
  ConsoleOrgIdComplianceObligationsAddRouteRoute: typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute: typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdComplianceObligationsRouteRouteChildren: ConsoleOrgIdComplianceObligationsRouteRouteChildren =
  {
    ConsoleOrgIdComplianceObligationsIndexRoute:
      ConsoleOrgIdComplianceObligationsIndexRoute,
    ConsoleOrgIdComplianceObligationsAddRouteRoute:
      ConsoleOrgIdComplianceObligationsAddRouteRoute,
    ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute:
      ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute,
  };

const ConsoleOrgIdComplianceObligationsRouteRouteWithChildren =
  ConsoleOrgIdComplianceObligationsRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceObligationsRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren {
  ConsoleOrgIdComplianceTrainingSearchAddRoute: typeof ConsoleOrgIdComplianceTrainingSearchAddRoute;
}

const ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren: ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren =
  {
    ConsoleOrgIdComplianceTrainingSearchAddRoute:
      ConsoleOrgIdComplianceTrainingSearchAddRoute,
  };

const ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren =
  ConsoleOrgIdComplianceTrainingSearchRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren {
  ConsoleOrgIdComplianceTrainingUserUserIdAddRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
}

const ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren: ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren =
  {
    ConsoleOrgIdComplianceTrainingUserUserIdAddRoute:
      ConsoleOrgIdComplianceTrainingUserUserIdAddRoute,
    ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute:
      ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute,
  };

const ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren =
  ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceTrainingRouteRouteChildren {
  ConsoleOrgIdComplianceTrainingIndexRoute: typeof ConsoleOrgIdComplianceTrainingIndexRoute;
  ConsoleOrgIdComplianceTrainingSearchRouteRoute: typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
}

const ConsoleOrgIdComplianceTrainingRouteRouteChildren: ConsoleOrgIdComplianceTrainingRouteRouteChildren =
  {
    ConsoleOrgIdComplianceTrainingIndexRoute:
      ConsoleOrgIdComplianceTrainingIndexRoute,
    ConsoleOrgIdComplianceTrainingSearchRouteRoute:
      ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren,
    ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute:
      ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren,
  };

const ConsoleOrgIdComplianceTrainingRouteRouteWithChildren =
  ConsoleOrgIdComplianceTrainingRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceTrainingRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceRouteRouteChildren {
  ConsoleOrgIdComplianceIndexRoute: typeof ConsoleOrgIdComplianceIndexRoute;
  ConsoleOrgIdComplianceCoiRouteRoute: typeof ConsoleOrgIdComplianceCoiRouteRouteWithChildren;
  ConsoleOrgIdComplianceComplaintsRouteRoute: typeof ConsoleOrgIdComplianceComplaintsRouteRouteWithChildren;
  ConsoleOrgIdComplianceIncidentsRouteRoute: typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  ConsoleOrgIdComplianceObligationsRouteRoute: typeof ConsoleOrgIdComplianceObligationsRouteRouteWithChildren;
  ConsoleOrgIdComplianceTrainingRouteRoute: typeof ConsoleOrgIdComplianceTrainingRouteRouteWithChildren;
}

const ConsoleOrgIdComplianceRouteRouteChildren: ConsoleOrgIdComplianceRouteRouteChildren =
  {
    ConsoleOrgIdComplianceIndexRoute: ConsoleOrgIdComplianceIndexRoute,
    ConsoleOrgIdComplianceCoiRouteRoute:
      ConsoleOrgIdComplianceCoiRouteRouteWithChildren,
    ConsoleOrgIdComplianceComplaintsRouteRoute:
      ConsoleOrgIdComplianceComplaintsRouteRouteWithChildren,
    ConsoleOrgIdComplianceIncidentsRouteRoute:
      ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren,
    ConsoleOrgIdComplianceObligationsRouteRoute:
      ConsoleOrgIdComplianceObligationsRouteRouteWithChildren,
    ConsoleOrgIdComplianceTrainingRouteRoute:
      ConsoleOrgIdComplianceTrainingRouteRouteWithChildren,
  };

const ConsoleOrgIdComplianceRouteRouteWithChildren =
  ConsoleOrgIdComplianceRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationBillingRouteRouteChildren {
  ConsoleOrgIdConfigurationBillingBalanceRoute: typeof ConsoleOrgIdConfigurationBillingBalanceRoute;
  ConsoleOrgIdConfigurationBillingBillingInfoRoute: typeof ConsoleOrgIdConfigurationBillingBillingInfoRoute;
  ConsoleOrgIdConfigurationBillingInvoicesRoute: typeof ConsoleOrgIdConfigurationBillingInvoicesRoute;
  ConsoleOrgIdConfigurationBillingTransactionsRoute: typeof ConsoleOrgIdConfigurationBillingTransactionsRoute;
  ConsoleOrgIdConfigurationBillingUsageRoute: typeof ConsoleOrgIdConfigurationBillingUsageRoute;
  ConsoleOrgIdConfigurationBillingPaymentSuccessRoute: typeof ConsoleOrgIdConfigurationBillingPaymentSuccessRoute;
}

const ConsoleOrgIdConfigurationBillingRouteRouteChildren: ConsoleOrgIdConfigurationBillingRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationBillingBalanceRoute:
      ConsoleOrgIdConfigurationBillingBalanceRoute,
    ConsoleOrgIdConfigurationBillingBillingInfoRoute:
      ConsoleOrgIdConfigurationBillingBillingInfoRoute,
    ConsoleOrgIdConfigurationBillingInvoicesRoute:
      ConsoleOrgIdConfigurationBillingInvoicesRoute,
    ConsoleOrgIdConfigurationBillingTransactionsRoute:
      ConsoleOrgIdConfigurationBillingTransactionsRoute,
    ConsoleOrgIdConfigurationBillingUsageRoute:
      ConsoleOrgIdConfigurationBillingUsageRoute,
    ConsoleOrgIdConfigurationBillingPaymentSuccessRoute:
      ConsoleOrgIdConfigurationBillingPaymentSuccessRoute,
  };

const ConsoleOrgIdConfigurationBillingRouteRouteWithChildren =
  ConsoleOrgIdConfigurationBillingRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationBillingRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteChildren {
  ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
}

const ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteChildren: ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute:
      ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute,
    ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute:
      ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute,
  };

const ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteWithChildren =
  ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationProductTypesRouteRouteChildren {
  ConsoleOrgIdConfigurationProductTypesIndexRoute: typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteWithChildren;
}

const ConsoleOrgIdConfigurationProductTypesRouteRouteChildren: ConsoleOrgIdConfigurationProductTypesRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationProductTypesIndexRoute:
      ConsoleOrgIdConfigurationProductTypesIndexRoute,
    ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRoute:
      ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteWithChildren,
  };

const ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren =
  ConsoleOrgIdConfigurationProductTypesRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationProductTypesRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationRiskRouteRouteChildren {
  ConsoleOrgIdConfigurationRiskIndexRoute: typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  ConsoleOrgIdConfigurationRiskSectionRouteRoute: typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
}

const ConsoleOrgIdConfigurationRiskRouteRouteChildren: ConsoleOrgIdConfigurationRiskRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationRiskIndexRoute:
      ConsoleOrgIdConfigurationRiskIndexRoute,
    ConsoleOrgIdConfigurationRiskSectionRouteRoute:
      ConsoleOrgIdConfigurationRiskSectionRouteRoute,
  };

const ConsoleOrgIdConfigurationRiskRouteRouteWithChildren =
  ConsoleOrgIdConfigurationRiskRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationRiskRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationSuppliersRouteRouteChildren {
  ConsoleOrgIdConfigurationSuppliersIndexRoute: typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  ConsoleOrgIdConfigurationSuppliersAddRoute: typeof ConsoleOrgIdConfigurationSuppliersAddRoute;
  ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute: typeof ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute;
}

const ConsoleOrgIdConfigurationSuppliersRouteRouteChildren: ConsoleOrgIdConfigurationSuppliersRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationSuppliersIndexRoute:
      ConsoleOrgIdConfigurationSuppliersIndexRoute,
    ConsoleOrgIdConfigurationSuppliersAddRoute:
      ConsoleOrgIdConfigurationSuppliersAddRoute,
    ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute:
      ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute,
  };

const ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren =
  ConsoleOrgIdConfigurationSuppliersRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationSuppliersRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationRouteRouteChildren {
  ConsoleOrgIdConfigurationIndexRoute: typeof ConsoleOrgIdConfigurationIndexRoute;
  ConsoleOrgIdConfigurationAssistanceRouteRoute: typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  ConsoleOrgIdConfigurationBillingRouteRoute: typeof ConsoleOrgIdConfigurationBillingRouteRouteWithChildren;
  ConsoleOrgIdConfigurationClaimRouteRoute: typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  ConsoleOrgIdConfigurationCommsTemplatesRouteRoute: typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  ConsoleOrgIdConfigurationComplianceRouteRoute: typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  ConsoleOrgIdConfigurationDocumentsRouteRoute: typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute: typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  ConsoleOrgIdConfigurationHandlingPartiesRouteRoute: typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  ConsoleOrgIdConfigurationInsurersRouteRoute: typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  ConsoleOrgIdConfigurationPaymentsRouteRoute: typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  ConsoleOrgIdConfigurationProductTypesRouteRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren;
  ConsoleOrgIdConfigurationQuestionnaireRouteRoute: typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  ConsoleOrgIdConfigurationRiskRouteRoute: typeof ConsoleOrgIdConfigurationRiskRouteRouteWithChildren;
  ConsoleOrgIdConfigurationStatisticsRouteRoute: typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  ConsoleOrgIdConfigurationSuppliersRouteRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren;
  ConsoleOrgIdConfigurationSystemRouteRoute: typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  ConsoleOrgIdConfigurationTaskManagerRouteRoute: typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  ConsoleOrgIdConfigurationTasksRouteRoute: typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  ConsoleOrgIdConfigurationUsersRouteRoute: typeof ConsoleOrgIdConfigurationUsersRouteRoute;
}

const ConsoleOrgIdConfigurationRouteRouteChildren: ConsoleOrgIdConfigurationRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationIndexRoute: ConsoleOrgIdConfigurationIndexRoute,
    ConsoleOrgIdConfigurationAssistanceRouteRoute:
      ConsoleOrgIdConfigurationAssistanceRouteRoute,
    ConsoleOrgIdConfigurationBillingRouteRoute:
      ConsoleOrgIdConfigurationBillingRouteRouteWithChildren,
    ConsoleOrgIdConfigurationClaimRouteRoute:
      ConsoleOrgIdConfigurationClaimRouteRoute,
    ConsoleOrgIdConfigurationCommsTemplatesRouteRoute:
      ConsoleOrgIdConfigurationCommsTemplatesRouteRoute,
    ConsoleOrgIdConfigurationComplianceRouteRoute:
      ConsoleOrgIdConfigurationComplianceRouteRoute,
    ConsoleOrgIdConfigurationDocumentsRouteRoute:
      ConsoleOrgIdConfigurationDocumentsRouteRoute,
    ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute:
      ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute,
    ConsoleOrgIdConfigurationHandlingPartiesRouteRoute:
      ConsoleOrgIdConfigurationHandlingPartiesRouteRoute,
    ConsoleOrgIdConfigurationInsurersRouteRoute:
      ConsoleOrgIdConfigurationInsurersRouteRoute,
    ConsoleOrgIdConfigurationPaymentsRouteRoute:
      ConsoleOrgIdConfigurationPaymentsRouteRoute,
    ConsoleOrgIdConfigurationProductTypesRouteRoute:
      ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren,
    ConsoleOrgIdConfigurationQuestionnaireRouteRoute:
      ConsoleOrgIdConfigurationQuestionnaireRouteRoute,
    ConsoleOrgIdConfigurationRiskRouteRoute:
      ConsoleOrgIdConfigurationRiskRouteRouteWithChildren,
    ConsoleOrgIdConfigurationStatisticsRouteRoute:
      ConsoleOrgIdConfigurationStatisticsRouteRoute,
    ConsoleOrgIdConfigurationSuppliersRouteRoute:
      ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren,
    ConsoleOrgIdConfigurationSystemRouteRoute:
      ConsoleOrgIdConfigurationSystemRouteRoute,
    ConsoleOrgIdConfigurationTaskManagerRouteRoute:
      ConsoleOrgIdConfigurationTaskManagerRouteRoute,
    ConsoleOrgIdConfigurationTasksRouteRoute:
      ConsoleOrgIdConfigurationTasksRouteRoute,
    ConsoleOrgIdConfigurationUsersRouteRoute:
      ConsoleOrgIdConfigurationUsersRouteRoute,
  };

const ConsoleOrgIdConfigurationRouteRouteWithChildren =
  ConsoleOrgIdConfigurationRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationRouteRouteChildren,
  );

interface ConsoleOrgIdControlsRegisterRouteRouteChildren {
  ConsoleOrgIdControlsRegisterIndexRoute: typeof ConsoleOrgIdControlsRegisterIndexRoute;
  ConsoleOrgIdControlsRegisterAddRouteRoute: typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  ConsoleOrgIdControlsRegisterDetailsIdRouteRoute: typeof ConsoleOrgIdControlsRegisterDetailsIdRouteRoute;
}

const ConsoleOrgIdControlsRegisterRouteRouteChildren: ConsoleOrgIdControlsRegisterRouteRouteChildren =
  {
    ConsoleOrgIdControlsRegisterIndexRoute:
      ConsoleOrgIdControlsRegisterIndexRoute,
    ConsoleOrgIdControlsRegisterAddRouteRoute:
      ConsoleOrgIdControlsRegisterAddRouteRoute,
    ConsoleOrgIdControlsRegisterDetailsIdRouteRoute:
      ConsoleOrgIdControlsRegisterDetailsIdRouteRoute,
  };

const ConsoleOrgIdControlsRegisterRouteRouteWithChildren =
  ConsoleOrgIdControlsRegisterRouteRoute._addFileChildren(
    ConsoleOrgIdControlsRegisterRouteRouteChildren,
  );

interface ConsoleOrgIdControlsRouteRouteChildren {
  ConsoleOrgIdControlsIndexRoute: typeof ConsoleOrgIdControlsIndexRoute;
  ConsoleOrgIdControlsRegisterRouteRoute: typeof ConsoleOrgIdControlsRegisterRouteRouteWithChildren;
  ConsoleOrgIdControlsTestsRouteRoute: typeof ConsoleOrgIdControlsTestsRouteRoute;
}

const ConsoleOrgIdControlsRouteRouteChildren: ConsoleOrgIdControlsRouteRouteChildren =
  {
    ConsoleOrgIdControlsIndexRoute: ConsoleOrgIdControlsIndexRoute,
    ConsoleOrgIdControlsRegisterRouteRoute:
      ConsoleOrgIdControlsRegisterRouteRouteWithChildren,
    ConsoleOrgIdControlsTestsRouteRoute: ConsoleOrgIdControlsTestsRouteRoute,
  };

const ConsoleOrgIdControlsRouteRouteWithChildren =
  ConsoleOrgIdControlsRouteRoute._addFileChildren(
    ConsoleOrgIdControlsRouteRouteChildren,
  );

interface ConsoleOrgIdDashboardRouteRouteChildren {
  ConsoleOrgIdDashboardIndexRoute: typeof ConsoleOrgIdDashboardIndexRoute;
  ConsoleOrgIdDashboardSectionRouteRoute: typeof ConsoleOrgIdDashboardSectionRouteRoute;
}

const ConsoleOrgIdDashboardRouteRouteChildren: ConsoleOrgIdDashboardRouteRouteChildren =
  {
    ConsoleOrgIdDashboardIndexRoute: ConsoleOrgIdDashboardIndexRoute,
    ConsoleOrgIdDashboardSectionRouteRoute:
      ConsoleOrgIdDashboardSectionRouteRoute,
  };

const ConsoleOrgIdDashboardRouteRouteWithChildren =
  ConsoleOrgIdDashboardRouteRoute._addFileChildren(
    ConsoleOrgIdDashboardRouteRouteChildren,
  );

interface ConsoleOrgIdDebugRouteRouteChildren {
  ConsoleOrgIdDebugIndexRoute: typeof ConsoleOrgIdDebugIndexRoute;
  ConsoleOrgIdDebugTestRouteRoute: typeof ConsoleOrgIdDebugTestRouteRoute;
}

const ConsoleOrgIdDebugRouteRouteChildren: ConsoleOrgIdDebugRouteRouteChildren =
  {
    ConsoleOrgIdDebugIndexRoute: ConsoleOrgIdDebugIndexRoute,
    ConsoleOrgIdDebugTestRouteRoute: ConsoleOrgIdDebugTestRouteRoute,
  };

const ConsoleOrgIdDebugRouteRouteWithChildren =
  ConsoleOrgIdDebugRouteRoute._addFileChildren(
    ConsoleOrgIdDebugRouteRouteChildren,
  );

interface ConsoleOrgIdReportingRouteRouteChildren {
  ConsoleOrgIdReportingAddRoute: typeof ConsoleOrgIdReportingAddRoute;
}

const ConsoleOrgIdReportingRouteRouteChildren: ConsoleOrgIdReportingRouteRouteChildren =
  {
    ConsoleOrgIdReportingAddRoute: ConsoleOrgIdReportingAddRoute,
  };

const ConsoleOrgIdReportingRouteRouteWithChildren =
  ConsoleOrgIdReportingRouteRoute._addFileChildren(
    ConsoleOrgIdReportingRouteRouteChildren,
  );

interface ConsoleOrgIdRiskRasRouteRouteChildren {
  ConsoleOrgIdRiskRasIndexRoute: typeof ConsoleOrgIdRiskRasIndexRoute;
  ConsoleOrgIdRiskRasDetailsIdRouteRoute: typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
}

const ConsoleOrgIdRiskRasRouteRouteChildren: ConsoleOrgIdRiskRasRouteRouteChildren =
  {
    ConsoleOrgIdRiskRasIndexRoute: ConsoleOrgIdRiskRasIndexRoute,
    ConsoleOrgIdRiskRasDetailsIdRouteRoute:
      ConsoleOrgIdRiskRasDetailsIdRouteRoute,
  };

const ConsoleOrgIdRiskRasRouteRouteWithChildren =
  ConsoleOrgIdRiskRasRouteRoute._addFileChildren(
    ConsoleOrgIdRiskRasRouteRouteChildren,
  );

interface ConsoleOrgIdRiskRegisterRouteRouteChildren {
  ConsoleOrgIdRiskRegisterIndexRoute: typeof ConsoleOrgIdRiskRegisterIndexRoute;
  ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute: typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdRiskRegisterRouteRouteChildren: ConsoleOrgIdRiskRegisterRouteRouteChildren =
  {
    ConsoleOrgIdRiskRegisterIndexRoute: ConsoleOrgIdRiskRegisterIndexRoute,
    ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute:
      ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute,
  };

const ConsoleOrgIdRiskRegisterRouteRouteWithChildren =
  ConsoleOrgIdRiskRegisterRouteRoute._addFileChildren(
    ConsoleOrgIdRiskRegisterRouteRouteChildren,
  );

interface ConsoleOrgIdRiskRouteRouteChildren {
  ConsoleOrgIdRiskIndexRoute: typeof ConsoleOrgIdRiskIndexRoute;
  ConsoleOrgIdRiskRasRouteRoute: typeof ConsoleOrgIdRiskRasRouteRouteWithChildren;
  ConsoleOrgIdRiskRegisterRouteRoute: typeof ConsoleOrgIdRiskRegisterRouteRouteWithChildren;
}

const ConsoleOrgIdRiskRouteRouteChildren: ConsoleOrgIdRiskRouteRouteChildren = {
  ConsoleOrgIdRiskIndexRoute: ConsoleOrgIdRiskIndexRoute,
  ConsoleOrgIdRiskRasRouteRoute: ConsoleOrgIdRiskRasRouteRouteWithChildren,
  ConsoleOrgIdRiskRegisterRouteRoute:
    ConsoleOrgIdRiskRegisterRouteRouteWithChildren,
};

const ConsoleOrgIdRiskRouteRouteWithChildren =
  ConsoleOrgIdRiskRouteRoute._addFileChildren(
    ConsoleOrgIdRiskRouteRouteChildren,
  );

interface ConsoleOrgIdToolsAutoDetectInboxRouteRouteChildren {
  ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute: typeof ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute;
}

const ConsoleOrgIdToolsAutoDetectInboxRouteRouteChildren: ConsoleOrgIdToolsAutoDetectInboxRouteRouteChildren =
  {
    ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute:
      ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute,
  };

const ConsoleOrgIdToolsAutoDetectInboxRouteRouteWithChildren =
  ConsoleOrgIdToolsAutoDetectInboxRouteRoute._addFileChildren(
    ConsoleOrgIdToolsAutoDetectInboxRouteRouteChildren,
  );

interface ConsoleOrgIdToolsAutoDetectRouteRouteChildren {
  ConsoleOrgIdToolsAutoDetectDashboardRoute: typeof ConsoleOrgIdToolsAutoDetectDashboardRoute;
  ConsoleOrgIdToolsAutoDetectInboxRouteRoute: typeof ConsoleOrgIdToolsAutoDetectInboxRouteRouteWithChildren;
}

const ConsoleOrgIdToolsAutoDetectRouteRouteChildren: ConsoleOrgIdToolsAutoDetectRouteRouteChildren =
  {
    ConsoleOrgIdToolsAutoDetectDashboardRoute:
      ConsoleOrgIdToolsAutoDetectDashboardRoute,
    ConsoleOrgIdToolsAutoDetectInboxRouteRoute:
      ConsoleOrgIdToolsAutoDetectInboxRouteRouteWithChildren,
  };

const ConsoleOrgIdToolsAutoDetectRouteRouteWithChildren =
  ConsoleOrgIdToolsAutoDetectRouteRoute._addFileChildren(
    ConsoleOrgIdToolsAutoDetectRouteRouteChildren,
  );

interface ConsoleOrgIdToolsRouteRouteChildren {
  ConsoleOrgIdToolsIndexRoute: typeof ConsoleOrgIdToolsIndexRoute;
  ConsoleOrgIdToolsAutoDetectRouteRoute: typeof ConsoleOrgIdToolsAutoDetectRouteRouteWithChildren;
  ConsoleOrgIdToolsWebsiteCheckerRouteRoute: typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
}

const ConsoleOrgIdToolsRouteRouteChildren: ConsoleOrgIdToolsRouteRouteChildren =
  {
    ConsoleOrgIdToolsIndexRoute: ConsoleOrgIdToolsIndexRoute,
    ConsoleOrgIdToolsAutoDetectRouteRoute:
      ConsoleOrgIdToolsAutoDetectRouteRouteWithChildren,
    ConsoleOrgIdToolsWebsiteCheckerRouteRoute:
      ConsoleOrgIdToolsWebsiteCheckerRouteRoute,
  };

const ConsoleOrgIdToolsRouteRouteWithChildren =
  ConsoleOrgIdToolsRouteRoute._addFileChildren(
    ConsoleOrgIdToolsRouteRouteChildren,
  );

interface ConsoleOrgIdUserSettingsRouteRouteChildren {
  ConsoleOrgIdUserSettingsIndexRoute: typeof ConsoleOrgIdUserSettingsIndexRoute;
  ConsoleOrgIdUserSettingsNotificationsRoute: typeof ConsoleOrgIdUserSettingsNotificationsRoute;
  ConsoleOrgIdUserSettingsProfileRoute: typeof ConsoleOrgIdUserSettingsProfileRoute;
}

const ConsoleOrgIdUserSettingsRouteRouteChildren: ConsoleOrgIdUserSettingsRouteRouteChildren =
  {
    ConsoleOrgIdUserSettingsIndexRoute: ConsoleOrgIdUserSettingsIndexRoute,
    ConsoleOrgIdUserSettingsNotificationsRoute:
      ConsoleOrgIdUserSettingsNotificationsRoute,
    ConsoleOrgIdUserSettingsProfileRoute: ConsoleOrgIdUserSettingsProfileRoute,
  };

const ConsoleOrgIdUserSettingsRouteRouteWithChildren =
  ConsoleOrgIdUserSettingsRouteRoute._addFileChildren(
    ConsoleOrgIdUserSettingsRouteRouteChildren,
  );

interface ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteChildren {
  ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute;
  ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute;
}

const ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteChildren: ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteChildren =
  {
    ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute:
      ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute,
    ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute:
      ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute,
  };

const ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteWithChildren =
  ConsoleOrgIdWorkloadManagerTasksCalendarRouteRoute._addFileChildren(
    ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteChildren,
  );

interface ConsoleOrgIdWorkloadManagerTasksListRouteRouteChildren {
  ConsoleOrgIdWorkloadManagerTasksListAddRoute: typeof ConsoleOrgIdWorkloadManagerTasksListAddRoute;
  ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute: typeof ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute;
}

const ConsoleOrgIdWorkloadManagerTasksListRouteRouteChildren: ConsoleOrgIdWorkloadManagerTasksListRouteRouteChildren =
  {
    ConsoleOrgIdWorkloadManagerTasksListAddRoute:
      ConsoleOrgIdWorkloadManagerTasksListAddRoute,
    ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute:
      ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute,
  };

const ConsoleOrgIdWorkloadManagerTasksListRouteRouteWithChildren =
  ConsoleOrgIdWorkloadManagerTasksListRouteRoute._addFileChildren(
    ConsoleOrgIdWorkloadManagerTasksListRouteRouteChildren,
  );

interface ConsoleOrgIdWorkloadManagerTasksRouteRouteChildren {
  ConsoleOrgIdWorkloadManagerTasksCalendarRouteRoute: typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteWithChildren;
  ConsoleOrgIdWorkloadManagerTasksListRouteRoute: typeof ConsoleOrgIdWorkloadManagerTasksListRouteRouteWithChildren;
}

const ConsoleOrgIdWorkloadManagerTasksRouteRouteChildren: ConsoleOrgIdWorkloadManagerTasksRouteRouteChildren =
  {
    ConsoleOrgIdWorkloadManagerTasksCalendarRouteRoute:
      ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteWithChildren,
    ConsoleOrgIdWorkloadManagerTasksListRouteRoute:
      ConsoleOrgIdWorkloadManagerTasksListRouteRouteWithChildren,
  };

const ConsoleOrgIdWorkloadManagerTasksRouteRouteWithChildren =
  ConsoleOrgIdWorkloadManagerTasksRouteRoute._addFileChildren(
    ConsoleOrgIdWorkloadManagerTasksRouteRouteChildren,
  );

interface ConsoleOrgIdWorkloadManagerRouteRouteChildren {
  ConsoleOrgIdWorkloadManagerEmailsRouteRoute: typeof ConsoleOrgIdWorkloadManagerEmailsRouteRoute;
  ConsoleOrgIdWorkloadManagerTasksRouteRoute: typeof ConsoleOrgIdWorkloadManagerTasksRouteRouteWithChildren;
}

const ConsoleOrgIdWorkloadManagerRouteRouteChildren: ConsoleOrgIdWorkloadManagerRouteRouteChildren =
  {
    ConsoleOrgIdWorkloadManagerEmailsRouteRoute:
      ConsoleOrgIdWorkloadManagerEmailsRouteRoute,
    ConsoleOrgIdWorkloadManagerTasksRouteRoute:
      ConsoleOrgIdWorkloadManagerTasksRouteRouteWithChildren,
  };

const ConsoleOrgIdWorkloadManagerRouteRouteWithChildren =
  ConsoleOrgIdWorkloadManagerRouteRoute._addFileChildren(
    ConsoleOrgIdWorkloadManagerRouteRouteChildren,
  );

interface ConsoleOrgIdRouteRouteChildren {
  ConsoleOrgIdIndexRoute: typeof ConsoleOrgIdIndexRoute;
  ConsoleOrgIdClaimModuleOnlyHomePageRoute: typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  ConsoleOrgIdClaimsRouteRoute: typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  ConsoleOrgIdComplianceRouteRoute: typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  ConsoleOrgIdConfigurationRouteRoute: typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  ConsoleOrgIdControlsRouteRoute: typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  ConsoleOrgIdDashboardRouteRoute: typeof ConsoleOrgIdDashboardRouteRouteWithChildren;
  ConsoleOrgIdDebugRouteRoute: typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  ConsoleOrgIdFaqRouteRoute: typeof ConsoleOrgIdFaqRouteRoute;
  ConsoleOrgIdGetFileRouteRoute: typeof ConsoleOrgIdGetFileRouteRoute;
  ConsoleOrgIdReportingRouteRoute: typeof ConsoleOrgIdReportingRouteRouteWithChildren;
  ConsoleOrgIdRiskRouteRoute: typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  ConsoleOrgIdToolsRouteRoute: typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  ConsoleOrgIdToolsOnlyHomePageRoute: typeof ConsoleOrgIdToolsOnlyHomePageRoute;
  ConsoleOrgIdUserSettingsRouteRoute: typeof ConsoleOrgIdUserSettingsRouteRouteWithChildren;
  ConsoleOrgIdWorkloadManagerRouteRoute: typeof ConsoleOrgIdWorkloadManagerRouteRouteWithChildren;
}

const ConsoleOrgIdRouteRouteChildren: ConsoleOrgIdRouteRouteChildren = {
  ConsoleOrgIdIndexRoute: ConsoleOrgIdIndexRoute,
  ConsoleOrgIdClaimModuleOnlyHomePageRoute:
    ConsoleOrgIdClaimModuleOnlyHomePageRoute,
  ConsoleOrgIdClaimsRouteRoute: ConsoleOrgIdClaimsRouteRouteWithChildren,
  ConsoleOrgIdComplianceRouteRoute:
    ConsoleOrgIdComplianceRouteRouteWithChildren,
  ConsoleOrgIdConfigurationRouteRoute:
    ConsoleOrgIdConfigurationRouteRouteWithChildren,
  ConsoleOrgIdControlsRouteRoute: ConsoleOrgIdControlsRouteRouteWithChildren,
  ConsoleOrgIdDashboardRouteRoute: ConsoleOrgIdDashboardRouteRouteWithChildren,
  ConsoleOrgIdDebugRouteRoute: ConsoleOrgIdDebugRouteRouteWithChildren,
  ConsoleOrgIdFaqRouteRoute: ConsoleOrgIdFaqRouteRoute,
  ConsoleOrgIdGetFileRouteRoute: ConsoleOrgIdGetFileRouteRoute,
  ConsoleOrgIdReportingRouteRoute: ConsoleOrgIdReportingRouteRouteWithChildren,
  ConsoleOrgIdRiskRouteRoute: ConsoleOrgIdRiskRouteRouteWithChildren,
  ConsoleOrgIdToolsRouteRoute: ConsoleOrgIdToolsRouteRouteWithChildren,
  ConsoleOrgIdToolsOnlyHomePageRoute: ConsoleOrgIdToolsOnlyHomePageRoute,
  ConsoleOrgIdUserSettingsRouteRoute:
    ConsoleOrgIdUserSettingsRouteRouteWithChildren,
  ConsoleOrgIdWorkloadManagerRouteRoute:
    ConsoleOrgIdWorkloadManagerRouteRouteWithChildren,
};

const ConsoleOrgIdRouteRouteWithChildren =
  ConsoleOrgIdRouteRoute._addFileChildren(ConsoleOrgIdRouteRouteChildren);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/admin-console': typeof AdminConsoleRouteRouteWithChildren;
  '/invite': typeof InviteRouteRoute;
  '/maintenance': typeof MaintenanceRouteRoute;
  '/not-found': typeof NotFoundRoute;
  '/public': typeof PublicRouteRouteWithChildren;
  '/sign-up': typeof SignUpRouteRoute;
  '/admin-console/': typeof AdminConsoleIndexRoute;
  '/admin-login': typeof authenticationAdminLoginRouteRoute;
  '/callback': typeof authenticationCallbackRouteRoute;
  '/login': typeof authenticationLoginRouteRoute;
  '/logout': typeof authenticationLogoutRouteRoute;
  '/logoutin': typeof authenticationLogoutinRouteRoute;
  '/payment-pending': typeof authenticationPaymentPendingRoute;
  '/admin-console/admin-playground': typeof AdminConsoleAdminPlaygroundRoute;
  '/admin-console/advanced': typeof AdminConsoleAdvancedRouteRouteWithChildren;
  '/admin-console/ai-prompts': typeof AdminConsoleAiPromptsRouteRoute;
  '/admin-console/comm-templates': typeof AdminConsoleCommTemplatesRouteRoute;
  '/admin-console/configuration': typeof AdminConsoleConfigurationRouteRouteWithChildren;
  '/admin-console/controls': typeof AdminConsoleControlsRouteRouteWithChildren;
  '/admin-console/faq': typeof AdminConsoleFaqRouteRoute;
  '/admin-console/new-organization': typeof AdminConsoleNewOrganizationRouteRoute;
  '/admin-console/obligations': typeof AdminConsoleObligationsRouteRouteWithChildren;
  '/admin-console/organizations': typeof AdminConsoleOrganizationsRouteRouteWithChildren;
  '/admin-console/products': typeof AdminConsoleProductsRouteRouteWithChildren;
  '/admin-console/reports': typeof AdminConsoleReportsRouteRoute;
  '/admin-console/users': typeof AdminConsoleUsersRouteRoute;
  '/console/$orgId': typeof ConsoleOrgIdRouteRouteWithChildren;
  '/public/$orgId': typeof PublicOrgIdRouteRouteWithChildren;
  '/admin-console/advanced/': typeof AdminConsoleAdvancedIndexRoute;
  '/admin-console/configuration/': typeof AdminConsoleConfigurationIndexRoute;
  '/admin-console/controls/': typeof AdminConsoleControlsIndexRoute;
  '/admin-console/obligations/': typeof AdminConsoleObligationsIndexRoute;
  '/console/$orgId/': typeof ConsoleOrgIdIndexRoute;
  '/change-org/$orgId': typeof authenticationChangeOrgOrgIdRouteRoute;
  '/admin-console/advanced/app-settings': typeof AdminConsoleAdvancedAppSettingsRoute;
  '/admin-console/advanced/db-upgrades': typeof AdminConsoleAdvancedDbUpgradesRoute;
  '/admin-console/advanced/indicators': typeof AdminConsoleAdvancedIndicatorsRouteRouteWithChildren;
  '/admin-console/advanced/jobs': typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
  '/admin-console/advanced/monitoring': typeof AdminConsoleAdvancedMonitoringRoute;
  '/admin-console/advanced/system-logs': typeof AdminConsoleAdvancedSystemLogsRoute;
  '/admin-console/configuration/obligations': typeof AdminConsoleConfigurationObligationsRouteRoute;
  '/admin-console/controls/library': typeof AdminConsoleControlsLibraryRouteRouteWithChildren;
  '/admin-console/obligations/library': typeof AdminConsoleObligationsLibraryRouteRouteWithChildren;
  '/admin-console/organizations/$orgId': typeof AdminConsoleOrganizationsOrgIdRouteRouteWithChildren;
  '/admin-console/products/$serviceId': typeof AdminConsoleProductsServiceIdRoute;
  '/console/$orgId/claim-module-only-home-page': typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  '/console/$orgId/claims': typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  '/console/$orgId/compliance': typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  '/console/$orgId/configuration': typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  '/console/$orgId/controls': typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  '/console/$orgId/dashboard': typeof ConsoleOrgIdDashboardRouteRouteWithChildren;
  '/console/$orgId/debug': typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  '/console/$orgId/faq': typeof ConsoleOrgIdFaqRouteRoute;
  '/console/$orgId/get-file': typeof ConsoleOrgIdGetFileRouteRoute;
  '/console/$orgId/reporting': typeof ConsoleOrgIdReportingRouteRouteWithChildren;
  '/console/$orgId/risk': typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  '/console/$orgId/tools': typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  '/console/$orgId/tools-only-home-page': typeof ConsoleOrgIdToolsOnlyHomePageRoute;
  '/console/$orgId/user-settings': typeof ConsoleOrgIdUserSettingsRouteRouteWithChildren;
  '/console/$orgId/workload-manager': typeof ConsoleOrgIdWorkloadManagerRouteRouteWithChildren;
  '/public/lodgement-summary/$accessKey': typeof PublicLodgementSummaryAccessKeyRouteRoute;
  '/public/new-complaint-by-key/$key': typeof PublicNewComplaintByKeyKeyRouteRoute;
  '/public/new-complaint/$orgId': typeof PublicNewComplaintOrgIdRouteRoute;
  '/admin-console/advanced/jobs/': typeof AdminConsoleAdvancedJobsIndexRoute;
  '/admin-console/controls/library/': typeof AdminConsoleControlsLibraryIndexRoute;
  '/admin-console/obligations/library/': typeof AdminConsoleObligationsLibraryIndexRoute;
  '/console/$orgId/claims/': typeof ConsoleOrgIdClaimsIndexRoute;
  '/console/$orgId/compliance/': typeof ConsoleOrgIdComplianceIndexRoute;
  '/console/$orgId/configuration/': typeof ConsoleOrgIdConfigurationIndexRoute;
  '/console/$orgId/controls/': typeof ConsoleOrgIdControlsIndexRoute;
  '/console/$orgId/dashboard/': typeof ConsoleOrgIdDashboardIndexRoute;
  '/console/$orgId/debug/': typeof ConsoleOrgIdDebugIndexRoute;
  '/console/$orgId/risk/': typeof ConsoleOrgIdRiskIndexRoute;
  '/console/$orgId/tools/': typeof ConsoleOrgIdToolsIndexRoute;
  '/console/$orgId/user-settings/': typeof ConsoleOrgIdUserSettingsIndexRoute;
  '/admin-console/advanced/indicators/complaints': typeof AdminConsoleAdvancedIndicatorsComplaintsRoute;
  '/admin-console/advanced/indicators/incidents': typeof AdminConsoleAdvancedIndicatorsIncidentsRoute;
  '/admin-console/advanced/jobs/dynamic-recurring': typeof AdminConsoleAdvancedJobsDynamicRecurringRoute;
  '/admin-console/advanced/jobs/on-demand': typeof AdminConsoleAdvancedJobsOnDemandRoute;
  '/admin-console/advanced/jobs/recurring': typeof AdminConsoleAdvancedJobsRecurringRoute;
  '/admin-console/obligations/library/add': typeof AdminConsoleObligationsLibraryAddRouteRoute;
  '/admin-console/organizations/$orgId/subscriptions': typeof AdminConsoleOrganizationsOrgIdSubscriptionsRoute;
  '/admin-console/organizations/$orgId/users': typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  '/admin-console/organizations/$orgId/wallet': typeof AdminConsoleOrganizationsOrgIdWalletRoute;
  '/console/$orgId/claims/lodge': typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  '/console/$orgId/compliance/coi': typeof ConsoleOrgIdComplianceCoiRouteRouteWithChildren;
  '/console/$orgId/compliance/complaints': typeof ConsoleOrgIdComplianceComplaintsRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents': typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  '/console/$orgId/compliance/obligations': typeof ConsoleOrgIdComplianceObligationsRouteRouteWithChildren;
  '/console/$orgId/compliance/training': typeof ConsoleOrgIdComplianceTrainingRouteRouteWithChildren;
  '/console/$orgId/configuration/assistance': typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  '/console/$orgId/configuration/billing': typeof ConsoleOrgIdConfigurationBillingRouteRouteWithChildren;
  '/console/$orgId/configuration/claim': typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  '/console/$orgId/configuration/comms-templates': typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  '/console/$orgId/configuration/compliance': typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  '/console/$orgId/configuration/documents': typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  '/console/$orgId/configuration/expenses-and-billing': typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  '/console/$orgId/configuration/handling-parties': typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  '/console/$orgId/configuration/insurers': typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  '/console/$orgId/configuration/payments': typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  '/console/$orgId/configuration/product-types': typeof ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren;
  '/console/$orgId/configuration/questionnaire': typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  '/console/$orgId/configuration/risk': typeof ConsoleOrgIdConfigurationRiskRouteRouteWithChildren;
  '/console/$orgId/configuration/statistics': typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  '/console/$orgId/configuration/suppliers': typeof ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren;
  '/console/$orgId/configuration/system': typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  '/console/$orgId/configuration/task-manager': typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  '/console/$orgId/configuration/tasks': typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  '/console/$orgId/configuration/users': typeof ConsoleOrgIdConfigurationUsersRouteRoute;
  '/console/$orgId/controls/register': typeof ConsoleOrgIdControlsRegisterRouteRouteWithChildren;
  '/console/$orgId/controls/tests': typeof ConsoleOrgIdControlsTestsRouteRoute;
  '/console/$orgId/dashboard/$section': typeof ConsoleOrgIdDashboardSectionRouteRoute;
  '/console/$orgId/debug/test': typeof ConsoleOrgIdDebugTestRouteRoute;
  '/console/$orgId/reporting/add': typeof ConsoleOrgIdReportingAddRoute;
  '/console/$orgId/risk/ras': typeof ConsoleOrgIdRiskRasRouteRouteWithChildren;
  '/console/$orgId/risk/register': typeof ConsoleOrgIdRiskRegisterRouteRouteWithChildren;
  '/console/$orgId/tools/auto-detect': typeof ConsoleOrgIdToolsAutoDetectRouteRouteWithChildren;
  '/console/$orgId/tools/website-checker': typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
  '/console/$orgId/user-settings/notifications': typeof ConsoleOrgIdUserSettingsNotificationsRoute;
  '/console/$orgId/user-settings/profile': typeof ConsoleOrgIdUserSettingsProfileRoute;
  '/console/$orgId/workload-manager/emails': typeof ConsoleOrgIdWorkloadManagerEmailsRouteRoute;
  '/console/$orgId/workload-manager/tasks': typeof ConsoleOrgIdWorkloadManagerTasksRouteRouteWithChildren;
  '/public/$orgId/complaint/new': typeof PublicOrgIdComplaintNewRouteRoute;
  '/console/$orgId/compliance/coi/': typeof ConsoleOrgIdComplianceCoiIndexRoute;
  '/console/$orgId/compliance/complaints/': typeof ConsoleOrgIdComplianceComplaintsIndexRoute;
  '/console/$orgId/compliance/incidents/': typeof ConsoleOrgIdComplianceIncidentsIndexRoute;
  '/console/$orgId/compliance/obligations/': typeof ConsoleOrgIdComplianceObligationsIndexRoute;
  '/console/$orgId/compliance/training/': typeof ConsoleOrgIdComplianceTrainingIndexRoute;
  '/console/$orgId/configuration/product-types/': typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  '/console/$orgId/configuration/risk/': typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  '/console/$orgId/configuration/suppliers/': typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  '/console/$orgId/controls/register/': typeof ConsoleOrgIdControlsRegisterIndexRoute;
  '/console/$orgId/risk/ras/': typeof ConsoleOrgIdRiskRasIndexRoute;
  '/console/$orgId/risk/register/': typeof ConsoleOrgIdRiskRegisterIndexRoute;
  '/admin-console/controls/library/$id/$section': typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
  '/admin-console/obligations/library/$id/$section': typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
  '/console/$orgId/claims/details/$id': typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/claims/edit/$id': typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  '/console/$orgId/claims/view/$id': typeof ConsoleOrgIdClaimsViewIdRouteRoute;
  '/console/$orgId/compliance/coi/add': typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  '/console/$orgId/compliance/complaints/add': typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  '/console/$orgId/compliance/incidents/add': typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  '/console/$orgId/compliance/obligations/add': typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  '/console/$orgId/compliance/training/search': typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  '/console/$orgId/configuration/billing/balance': typeof ConsoleOrgIdConfigurationBillingBalanceRoute;
  '/console/$orgId/configuration/billing/billing-info': typeof ConsoleOrgIdConfigurationBillingBillingInfoRoute;
  '/console/$orgId/configuration/billing/invoices': typeof ConsoleOrgIdConfigurationBillingInvoicesRoute;
  '/console/$orgId/configuration/billing/transactions': typeof ConsoleOrgIdConfigurationBillingTransactionsRoute;
  '/console/$orgId/configuration/billing/usage': typeof ConsoleOrgIdConfigurationBillingUsageRoute;
  '/console/$orgId/configuration/product-types/$productTypeId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteWithChildren;
  '/console/$orgId/configuration/risk/$section': typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
  '/console/$orgId/configuration/suppliers/add': typeof ConsoleOrgIdConfigurationSuppliersAddRoute;
  '/console/$orgId/controls/register/add': typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  '/console/$orgId/tools/auto-detect/dashboard': typeof ConsoleOrgIdToolsAutoDetectDashboardRoute;
  '/console/$orgId/tools/auto-detect/inbox': typeof ConsoleOrgIdToolsAutoDetectInboxRouteRouteWithChildren;
  '/console/$orgId/workload-manager/tasks/calendar': typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteWithChildren;
  '/console/$orgId/workload-manager/tasks/list': typeof ConsoleOrgIdWorkloadManagerTasksListRouteRouteWithChildren;
  '/public/$orgId/invoice/view/$accessKey': typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  '/console/$orgId/claims/details/$id/comms': typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/contacts': typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  '/console/$orgId/claims/details/$id/expenses': typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/financials': typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  '/console/$orgId/claims/details/$id/history': typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  '/console/$orgId/claims/details/$id/library': typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  '/console/$orgId/claims/details/$id/manage': typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/policy-info': typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  '/console/$orgId/claims/details/$id/product-type-data': typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id': typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/compliance/training/search/add': typeof ConsoleOrgIdComplianceTrainingSearchAddRoute;
  '/console/$orgId/compliance/training/user/$userId': typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  '/console/$orgId/configuration/billing/payment/success': typeof ConsoleOrgIdConfigurationBillingPaymentSuccessRoute;
  '/console/$orgId/configuration/suppliers/details/$id': typeof ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute;
  '/console/$orgId/controls/register/details/$id': typeof ConsoleOrgIdControlsRegisterDetailsIdRouteRoute;
  '/console/$orgId/risk/ras/details/$id': typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  '/console/$orgId/workload-manager/tasks/calendar/add': typeof ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute;
  '/console/$orgId/workload-manager/tasks/list/add': typeof ConsoleOrgIdWorkloadManagerTasksListAddRoute;
  '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/add': typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/add': typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  '/console/$orgId/compliance/coi/details/$id/$section': typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/history': typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/library': typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents/details/$id/$section': typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/obligations/details/$id/$section': typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/add': typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
  '/console/$orgId/risk/register/details/$id/$section': typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
  '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification': typeof ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute;
  '/console/$orgId/workload-manager/tasks/calendar/details/$id': typeof ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute;
  '/console/$orgId/workload-manager/tasks/list/details/$id': typeof ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute;
  '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/message/$messageId': typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
  '/console/$orgId/claims/details/$id/manage/flag/add': typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/claims/details/$id/manage/strategies/add': typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/edit/$recordId': typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/note/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/invite': typeof InviteRouteRoute;
  '/maintenance': typeof MaintenanceRouteRoute;
  '/not-found': typeof NotFoundRoute;
  '/public': typeof PublicRouteRouteWithChildren;
  '/sign-up': typeof SignUpRouteRoute;
  '/admin-console': typeof AdminConsoleIndexRoute;
  '/admin-login': typeof authenticationAdminLoginRouteRoute;
  '/callback': typeof authenticationCallbackRouteRoute;
  '/login': typeof authenticationLoginRouteRoute;
  '/logout': typeof authenticationLogoutRouteRoute;
  '/logoutin': typeof authenticationLogoutinRouteRoute;
  '/payment-pending': typeof authenticationPaymentPendingRoute;
  '/admin-console/admin-playground': typeof AdminConsoleAdminPlaygroundRoute;
  '/admin-console/ai-prompts': typeof AdminConsoleAiPromptsRouteRoute;
  '/admin-console/comm-templates': typeof AdminConsoleCommTemplatesRouteRoute;
  '/admin-console/faq': typeof AdminConsoleFaqRouteRoute;
  '/admin-console/new-organization': typeof AdminConsoleNewOrganizationRouteRoute;
  '/admin-console/organizations': typeof AdminConsoleOrganizationsRouteRouteWithChildren;
  '/admin-console/products': typeof AdminConsoleProductsRouteRouteWithChildren;
  '/admin-console/reports': typeof AdminConsoleReportsRouteRoute;
  '/admin-console/users': typeof AdminConsoleUsersRouteRoute;
  '/public/$orgId': typeof PublicOrgIdRouteRouteWithChildren;
  '/admin-console/advanced': typeof AdminConsoleAdvancedIndexRoute;
  '/admin-console/configuration': typeof AdminConsoleConfigurationIndexRoute;
  '/admin-console/controls': typeof AdminConsoleControlsIndexRoute;
  '/admin-console/obligations': typeof AdminConsoleObligationsIndexRoute;
  '/console/$orgId': typeof ConsoleOrgIdIndexRoute;
  '/change-org/$orgId': typeof authenticationChangeOrgOrgIdRouteRoute;
  '/admin-console/advanced/app-settings': typeof AdminConsoleAdvancedAppSettingsRoute;
  '/admin-console/advanced/db-upgrades': typeof AdminConsoleAdvancedDbUpgradesRoute;
  '/admin-console/advanced/indicators': typeof AdminConsoleAdvancedIndicatorsRouteRouteWithChildren;
  '/admin-console/advanced/monitoring': typeof AdminConsoleAdvancedMonitoringRoute;
  '/admin-console/advanced/system-logs': typeof AdminConsoleAdvancedSystemLogsRoute;
  '/admin-console/configuration/obligations': typeof AdminConsoleConfigurationObligationsRouteRoute;
  '/admin-console/organizations/$orgId': typeof AdminConsoleOrganizationsOrgIdRouteRouteWithChildren;
  '/admin-console/products/$serviceId': typeof AdminConsoleProductsServiceIdRoute;
  '/console/$orgId/claim-module-only-home-page': typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  '/console/$orgId/faq': typeof ConsoleOrgIdFaqRouteRoute;
  '/console/$orgId/get-file': typeof ConsoleOrgIdGetFileRouteRoute;
  '/console/$orgId/reporting': typeof ConsoleOrgIdReportingRouteRouteWithChildren;
  '/console/$orgId/tools-only-home-page': typeof ConsoleOrgIdToolsOnlyHomePageRoute;
  '/console/$orgId/workload-manager': typeof ConsoleOrgIdWorkloadManagerRouteRouteWithChildren;
  '/public/lodgement-summary/$accessKey': typeof PublicLodgementSummaryAccessKeyRouteRoute;
  '/public/new-complaint-by-key/$key': typeof PublicNewComplaintByKeyKeyRouteRoute;
  '/public/new-complaint/$orgId': typeof PublicNewComplaintOrgIdRouteRoute;
  '/admin-console/advanced/jobs': typeof AdminConsoleAdvancedJobsIndexRoute;
  '/admin-console/controls/library': typeof AdminConsoleControlsLibraryIndexRoute;
  '/admin-console/obligations/library': typeof AdminConsoleObligationsLibraryIndexRoute;
  '/console/$orgId/claims': typeof ConsoleOrgIdClaimsIndexRoute;
  '/console/$orgId/compliance': typeof ConsoleOrgIdComplianceIndexRoute;
  '/console/$orgId/configuration': typeof ConsoleOrgIdConfigurationIndexRoute;
  '/console/$orgId/controls': typeof ConsoleOrgIdControlsIndexRoute;
  '/console/$orgId/dashboard': typeof ConsoleOrgIdDashboardIndexRoute;
  '/console/$orgId/debug': typeof ConsoleOrgIdDebugIndexRoute;
  '/console/$orgId/risk': typeof ConsoleOrgIdRiskIndexRoute;
  '/console/$orgId/tools': typeof ConsoleOrgIdToolsIndexRoute;
  '/console/$orgId/user-settings': typeof ConsoleOrgIdUserSettingsIndexRoute;
  '/admin-console/advanced/indicators/complaints': typeof AdminConsoleAdvancedIndicatorsComplaintsRoute;
  '/admin-console/advanced/indicators/incidents': typeof AdminConsoleAdvancedIndicatorsIncidentsRoute;
  '/admin-console/advanced/jobs/dynamic-recurring': typeof AdminConsoleAdvancedJobsDynamicRecurringRoute;
  '/admin-console/advanced/jobs/on-demand': typeof AdminConsoleAdvancedJobsOnDemandRoute;
  '/admin-console/advanced/jobs/recurring': typeof AdminConsoleAdvancedJobsRecurringRoute;
  '/admin-console/obligations/library/add': typeof AdminConsoleObligationsLibraryAddRouteRoute;
  '/admin-console/organizations/$orgId/subscriptions': typeof AdminConsoleOrganizationsOrgIdSubscriptionsRoute;
  '/admin-console/organizations/$orgId/users': typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  '/admin-console/organizations/$orgId/wallet': typeof AdminConsoleOrganizationsOrgIdWalletRoute;
  '/console/$orgId/claims/lodge': typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  '/console/$orgId/configuration/assistance': typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  '/console/$orgId/configuration/billing': typeof ConsoleOrgIdConfigurationBillingRouteRouteWithChildren;
  '/console/$orgId/configuration/claim': typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  '/console/$orgId/configuration/comms-templates': typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  '/console/$orgId/configuration/compliance': typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  '/console/$orgId/configuration/documents': typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  '/console/$orgId/configuration/expenses-and-billing': typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  '/console/$orgId/configuration/handling-parties': typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  '/console/$orgId/configuration/insurers': typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  '/console/$orgId/configuration/payments': typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  '/console/$orgId/configuration/questionnaire': typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  '/console/$orgId/configuration/statistics': typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  '/console/$orgId/configuration/system': typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  '/console/$orgId/configuration/task-manager': typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  '/console/$orgId/configuration/tasks': typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  '/console/$orgId/configuration/users': typeof ConsoleOrgIdConfigurationUsersRouteRoute;
  '/console/$orgId/controls/tests': typeof ConsoleOrgIdControlsTestsRouteRoute;
  '/console/$orgId/dashboard/$section': typeof ConsoleOrgIdDashboardSectionRouteRoute;
  '/console/$orgId/debug/test': typeof ConsoleOrgIdDebugTestRouteRoute;
  '/console/$orgId/reporting/add': typeof ConsoleOrgIdReportingAddRoute;
  '/console/$orgId/tools/auto-detect': typeof ConsoleOrgIdToolsAutoDetectRouteRouteWithChildren;
  '/console/$orgId/tools/website-checker': typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
  '/console/$orgId/user-settings/notifications': typeof ConsoleOrgIdUserSettingsNotificationsRoute;
  '/console/$orgId/user-settings/profile': typeof ConsoleOrgIdUserSettingsProfileRoute;
  '/console/$orgId/workload-manager/emails': typeof ConsoleOrgIdWorkloadManagerEmailsRouteRoute;
  '/console/$orgId/workload-manager/tasks': typeof ConsoleOrgIdWorkloadManagerTasksRouteRouteWithChildren;
  '/public/$orgId/complaint/new': typeof PublicOrgIdComplaintNewRouteRoute;
  '/console/$orgId/compliance/coi': typeof ConsoleOrgIdComplianceCoiIndexRoute;
  '/console/$orgId/compliance/complaints': typeof ConsoleOrgIdComplianceComplaintsIndexRoute;
  '/console/$orgId/compliance/incidents': typeof ConsoleOrgIdComplianceIncidentsIndexRoute;
  '/console/$orgId/compliance/obligations': typeof ConsoleOrgIdComplianceObligationsIndexRoute;
  '/console/$orgId/compliance/training': typeof ConsoleOrgIdComplianceTrainingIndexRoute;
  '/console/$orgId/configuration/product-types': typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  '/console/$orgId/configuration/risk': typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  '/console/$orgId/configuration/suppliers': typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  '/console/$orgId/controls/register': typeof ConsoleOrgIdControlsRegisterIndexRoute;
  '/console/$orgId/risk/ras': typeof ConsoleOrgIdRiskRasIndexRoute;
  '/console/$orgId/risk/register': typeof ConsoleOrgIdRiskRegisterIndexRoute;
  '/admin-console/controls/library/$id/$section': typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
  '/admin-console/obligations/library/$id/$section': typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
  '/console/$orgId/claims/details/$id': typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/claims/edit/$id': typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  '/console/$orgId/claims/view/$id': typeof ConsoleOrgIdClaimsViewIdRouteRoute;
  '/console/$orgId/compliance/coi/add': typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  '/console/$orgId/compliance/complaints/add': typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  '/console/$orgId/compliance/incidents/add': typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  '/console/$orgId/compliance/obligations/add': typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  '/console/$orgId/compliance/training/search': typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  '/console/$orgId/configuration/billing/balance': typeof ConsoleOrgIdConfigurationBillingBalanceRoute;
  '/console/$orgId/configuration/billing/billing-info': typeof ConsoleOrgIdConfigurationBillingBillingInfoRoute;
  '/console/$orgId/configuration/billing/invoices': typeof ConsoleOrgIdConfigurationBillingInvoicesRoute;
  '/console/$orgId/configuration/billing/transactions': typeof ConsoleOrgIdConfigurationBillingTransactionsRoute;
  '/console/$orgId/configuration/billing/usage': typeof ConsoleOrgIdConfigurationBillingUsageRoute;
  '/console/$orgId/configuration/risk/$section': typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
  '/console/$orgId/configuration/suppliers/add': typeof ConsoleOrgIdConfigurationSuppliersAddRoute;
  '/console/$orgId/controls/register/add': typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  '/console/$orgId/tools/auto-detect/dashboard': typeof ConsoleOrgIdToolsAutoDetectDashboardRoute;
  '/console/$orgId/tools/auto-detect/inbox': typeof ConsoleOrgIdToolsAutoDetectInboxRouteRouteWithChildren;
  '/console/$orgId/workload-manager/tasks/calendar': typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteWithChildren;
  '/console/$orgId/workload-manager/tasks/list': typeof ConsoleOrgIdWorkloadManagerTasksListRouteRouteWithChildren;
  '/public/$orgId/invoice/view/$accessKey': typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  '/console/$orgId/configuration/product-types/$productTypeId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  '/console/$orgId/claims/details/$id/comms': typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/contacts': typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  '/console/$orgId/claims/details/$id/expenses': typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/financials': typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  '/console/$orgId/claims/details/$id/history': typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  '/console/$orgId/claims/details/$id/library': typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  '/console/$orgId/claims/details/$id/manage': typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/policy-info': typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  '/console/$orgId/claims/details/$id/product-type-data': typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id': typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/compliance/training/search/add': typeof ConsoleOrgIdComplianceTrainingSearchAddRoute;
  '/console/$orgId/compliance/training/user/$userId': typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  '/console/$orgId/configuration/billing/payment/success': typeof ConsoleOrgIdConfigurationBillingPaymentSuccessRoute;
  '/console/$orgId/configuration/suppliers/details/$id': typeof ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute;
  '/console/$orgId/controls/register/details/$id': typeof ConsoleOrgIdControlsRegisterDetailsIdRouteRoute;
  '/console/$orgId/risk/ras/details/$id': typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  '/console/$orgId/workload-manager/tasks/calendar/add': typeof ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute;
  '/console/$orgId/workload-manager/tasks/list/add': typeof ConsoleOrgIdWorkloadManagerTasksListAddRoute;
  '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/add': typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/add': typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  '/console/$orgId/compliance/coi/details/$id/$section': typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/history': typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/library': typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents/details/$id/$section': typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/obligations/details/$id/$section': typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/add': typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
  '/console/$orgId/risk/register/details/$id/$section': typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
  '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification': typeof ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute;
  '/console/$orgId/workload-manager/tasks/calendar/details/$id': typeof ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute;
  '/console/$orgId/workload-manager/tasks/list/details/$id': typeof ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute;
  '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/message/$messageId': typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
  '/console/$orgId/claims/details/$id/manage/flag/add': typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/claims/details/$id/manage/strategies/add': typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/edit/$recordId': typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/note/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/admin-console': typeof AdminConsoleRouteRouteWithChildren;
  '/invite': typeof InviteRouteRoute;
  '/maintenance': typeof MaintenanceRouteRoute;
  '/not-found': typeof NotFoundRoute;
  '/public': typeof PublicRouteRouteWithChildren;
  '/sign-up': typeof SignUpRouteRoute;
  '/admin-console/': typeof AdminConsoleIndexRoute;
  '/(authentication)/admin-login': typeof authenticationAdminLoginRouteRoute;
  '/(authentication)/callback': typeof authenticationCallbackRouteRoute;
  '/(authentication)/login': typeof authenticationLoginRouteRoute;
  '/(authentication)/logout': typeof authenticationLogoutRouteRoute;
  '/(authentication)/logoutin': typeof authenticationLogoutinRouteRoute;
  '/(authentication)/payment-pending': typeof authenticationPaymentPendingRoute;
  '/admin-console/admin-playground': typeof AdminConsoleAdminPlaygroundRoute;
  '/admin-console/advanced': typeof AdminConsoleAdvancedRouteRouteWithChildren;
  '/admin-console/ai-prompts': typeof AdminConsoleAiPromptsRouteRoute;
  '/admin-console/comm-templates': typeof AdminConsoleCommTemplatesRouteRoute;
  '/admin-console/configuration': typeof AdminConsoleConfigurationRouteRouteWithChildren;
  '/admin-console/controls': typeof AdminConsoleControlsRouteRouteWithChildren;
  '/admin-console/faq': typeof AdminConsoleFaqRouteRoute;
  '/admin-console/new-organization': typeof AdminConsoleNewOrganizationRouteRoute;
  '/admin-console/obligations': typeof AdminConsoleObligationsRouteRouteWithChildren;
  '/admin-console/organizations': typeof AdminConsoleOrganizationsRouteRouteWithChildren;
  '/admin-console/products': typeof AdminConsoleProductsRouteRouteWithChildren;
  '/admin-console/reports': typeof AdminConsoleReportsRouteRoute;
  '/admin-console/users': typeof AdminConsoleUsersRouteRoute;
  '/console/$orgId': typeof ConsoleOrgIdRouteRouteWithChildren;
  '/public/$orgId': typeof PublicOrgIdRouteRouteWithChildren;
  '/admin-console/advanced/': typeof AdminConsoleAdvancedIndexRoute;
  '/admin-console/configuration/': typeof AdminConsoleConfigurationIndexRoute;
  '/admin-console/controls/': typeof AdminConsoleControlsIndexRoute;
  '/admin-console/obligations/': typeof AdminConsoleObligationsIndexRoute;
  '/console/$orgId/': typeof ConsoleOrgIdIndexRoute;
  '/(authentication)/change-org/$orgId': typeof authenticationChangeOrgOrgIdRouteRoute;
  '/admin-console/advanced/app-settings': typeof AdminConsoleAdvancedAppSettingsRoute;
  '/admin-console/advanced/db-upgrades': typeof AdminConsoleAdvancedDbUpgradesRoute;
  '/admin-console/advanced/indicators': typeof AdminConsoleAdvancedIndicatorsRouteRouteWithChildren;
  '/admin-console/advanced/jobs': typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
  '/admin-console/advanced/monitoring': typeof AdminConsoleAdvancedMonitoringRoute;
  '/admin-console/advanced/system-logs': typeof AdminConsoleAdvancedSystemLogsRoute;
  '/admin-console/configuration/obligations': typeof AdminConsoleConfigurationObligationsRouteRoute;
  '/admin-console/controls/library': typeof AdminConsoleControlsLibraryRouteRouteWithChildren;
  '/admin-console/obligations/library': typeof AdminConsoleObligationsLibraryRouteRouteWithChildren;
  '/admin-console/organizations/$orgId': typeof AdminConsoleOrganizationsOrgIdRouteRouteWithChildren;
  '/admin-console/products/$serviceId': typeof AdminConsoleProductsServiceIdRoute;
  '/console/$orgId/claim-module-only-home-page': typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  '/console/$orgId/claims': typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  '/console/$orgId/compliance': typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  '/console/$orgId/configuration': typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  '/console/$orgId/controls': typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  '/console/$orgId/dashboard': typeof ConsoleOrgIdDashboardRouteRouteWithChildren;
  '/console/$orgId/debug': typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  '/console/$orgId/faq': typeof ConsoleOrgIdFaqRouteRoute;
  '/console/$orgId/get-file': typeof ConsoleOrgIdGetFileRouteRoute;
  '/console/$orgId/reporting': typeof ConsoleOrgIdReportingRouteRouteWithChildren;
  '/console/$orgId/risk': typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  '/console/$orgId/tools': typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  '/console/$orgId/tools-only-home-page': typeof ConsoleOrgIdToolsOnlyHomePageRoute;
  '/console/$orgId/user-settings': typeof ConsoleOrgIdUserSettingsRouteRouteWithChildren;
  '/console/$orgId/workload-manager': typeof ConsoleOrgIdWorkloadManagerRouteRouteWithChildren;
  '/public/lodgement-summary/$accessKey': typeof PublicLodgementSummaryAccessKeyRouteRoute;
  '/public/new-complaint-by-key/$key': typeof PublicNewComplaintByKeyKeyRouteRoute;
  '/public/new-complaint/$orgId': typeof PublicNewComplaintOrgIdRouteRoute;
  '/admin-console/advanced/jobs/': typeof AdminConsoleAdvancedJobsIndexRoute;
  '/admin-console/controls/library/': typeof AdminConsoleControlsLibraryIndexRoute;
  '/admin-console/obligations/library/': typeof AdminConsoleObligationsLibraryIndexRoute;
  '/console/$orgId/claims/': typeof ConsoleOrgIdClaimsIndexRoute;
  '/console/$orgId/compliance/': typeof ConsoleOrgIdComplianceIndexRoute;
  '/console/$orgId/configuration/': typeof ConsoleOrgIdConfigurationIndexRoute;
  '/console/$orgId/controls/': typeof ConsoleOrgIdControlsIndexRoute;
  '/console/$orgId/dashboard/': typeof ConsoleOrgIdDashboardIndexRoute;
  '/console/$orgId/debug/': typeof ConsoleOrgIdDebugIndexRoute;
  '/console/$orgId/risk/': typeof ConsoleOrgIdRiskIndexRoute;
  '/console/$orgId/tools/': typeof ConsoleOrgIdToolsIndexRoute;
  '/console/$orgId/user-settings/': typeof ConsoleOrgIdUserSettingsIndexRoute;
  '/admin-console/advanced/indicators/complaints': typeof AdminConsoleAdvancedIndicatorsComplaintsRoute;
  '/admin-console/advanced/indicators/incidents': typeof AdminConsoleAdvancedIndicatorsIncidentsRoute;
  '/admin-console/advanced/jobs/dynamic-recurring': typeof AdminConsoleAdvancedJobsDynamicRecurringRoute;
  '/admin-console/advanced/jobs/on-demand': typeof AdminConsoleAdvancedJobsOnDemandRoute;
  '/admin-console/advanced/jobs/recurring': typeof AdminConsoleAdvancedJobsRecurringRoute;
  '/admin-console/obligations/library/add': typeof AdminConsoleObligationsLibraryAddRouteRoute;
  '/admin-console/organizations/$orgId/subscriptions': typeof AdminConsoleOrganizationsOrgIdSubscriptionsRoute;
  '/admin-console/organizations/$orgId/users': typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  '/admin-console/organizations/$orgId/wallet': typeof AdminConsoleOrganizationsOrgIdWalletRoute;
  '/console/$orgId/claims/lodge': typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  '/console/$orgId/compliance/coi': typeof ConsoleOrgIdComplianceCoiRouteRouteWithChildren;
  '/console/$orgId/compliance/complaints': typeof ConsoleOrgIdComplianceComplaintsRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents': typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  '/console/$orgId/compliance/obligations': typeof ConsoleOrgIdComplianceObligationsRouteRouteWithChildren;
  '/console/$orgId/compliance/training': typeof ConsoleOrgIdComplianceTrainingRouteRouteWithChildren;
  '/console/$orgId/configuration/assistance': typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  '/console/$orgId/configuration/billing': typeof ConsoleOrgIdConfigurationBillingRouteRouteWithChildren;
  '/console/$orgId/configuration/claim': typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  '/console/$orgId/configuration/comms-templates': typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  '/console/$orgId/configuration/compliance': typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  '/console/$orgId/configuration/documents': typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  '/console/$orgId/configuration/expenses-and-billing': typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  '/console/$orgId/configuration/handling-parties': typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  '/console/$orgId/configuration/insurers': typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  '/console/$orgId/configuration/payments': typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  '/console/$orgId/configuration/product-types': typeof ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren;
  '/console/$orgId/configuration/questionnaire': typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  '/console/$orgId/configuration/risk': typeof ConsoleOrgIdConfigurationRiskRouteRouteWithChildren;
  '/console/$orgId/configuration/statistics': typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  '/console/$orgId/configuration/suppliers': typeof ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren;
  '/console/$orgId/configuration/system': typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  '/console/$orgId/configuration/task-manager': typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  '/console/$orgId/configuration/tasks': typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  '/console/$orgId/configuration/users': typeof ConsoleOrgIdConfigurationUsersRouteRoute;
  '/console/$orgId/controls/register': typeof ConsoleOrgIdControlsRegisterRouteRouteWithChildren;
  '/console/$orgId/controls/tests': typeof ConsoleOrgIdControlsTestsRouteRoute;
  '/console/$orgId/dashboard/$section': typeof ConsoleOrgIdDashboardSectionRouteRoute;
  '/console/$orgId/debug/test': typeof ConsoleOrgIdDebugTestRouteRoute;
  '/console/$orgId/reporting/add': typeof ConsoleOrgIdReportingAddRoute;
  '/console/$orgId/risk/ras': typeof ConsoleOrgIdRiskRasRouteRouteWithChildren;
  '/console/$orgId/risk/register': typeof ConsoleOrgIdRiskRegisterRouteRouteWithChildren;
  '/console/$orgId/tools/auto-detect': typeof ConsoleOrgIdToolsAutoDetectRouteRouteWithChildren;
  '/console/$orgId/tools/website-checker': typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
  '/console/$orgId/user-settings/notifications': typeof ConsoleOrgIdUserSettingsNotificationsRoute;
  '/console/$orgId/user-settings/profile': typeof ConsoleOrgIdUserSettingsProfileRoute;
  '/console/$orgId/workload-manager/emails': typeof ConsoleOrgIdWorkloadManagerEmailsRouteRoute;
  '/console/$orgId/workload-manager/tasks': typeof ConsoleOrgIdWorkloadManagerTasksRouteRouteWithChildren;
  '/public/$orgId/complaint/new': typeof PublicOrgIdComplaintNewRouteRoute;
  '/console/$orgId/compliance/coi/': typeof ConsoleOrgIdComplianceCoiIndexRoute;
  '/console/$orgId/compliance/complaints/': typeof ConsoleOrgIdComplianceComplaintsIndexRoute;
  '/console/$orgId/compliance/incidents/': typeof ConsoleOrgIdComplianceIncidentsIndexRoute;
  '/console/$orgId/compliance/obligations/': typeof ConsoleOrgIdComplianceObligationsIndexRoute;
  '/console/$orgId/compliance/training/': typeof ConsoleOrgIdComplianceTrainingIndexRoute;
  '/console/$orgId/configuration/product-types/': typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  '/console/$orgId/configuration/risk/': typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  '/console/$orgId/configuration/suppliers/': typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  '/console/$orgId/controls/register/': typeof ConsoleOrgIdControlsRegisterIndexRoute;
  '/console/$orgId/risk/ras/': typeof ConsoleOrgIdRiskRasIndexRoute;
  '/console/$orgId/risk/register/': typeof ConsoleOrgIdRiskRegisterIndexRoute;
  '/admin-console/controls/library/$id/$section': typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
  '/admin-console/obligations/library/$id/$section': typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
  '/console/$orgId/claims/details/$id': typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/claims/edit/$id': typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  '/console/$orgId/claims/view/$id': typeof ConsoleOrgIdClaimsViewIdRouteRoute;
  '/console/$orgId/compliance/coi/add': typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  '/console/$orgId/compliance/complaints/add': typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  '/console/$orgId/compliance/incidents/add': typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  '/console/$orgId/compliance/obligations/add': typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  '/console/$orgId/compliance/training/search': typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  '/console/$orgId/configuration/billing/balance': typeof ConsoleOrgIdConfigurationBillingBalanceRoute;
  '/console/$orgId/configuration/billing/billing-info': typeof ConsoleOrgIdConfigurationBillingBillingInfoRoute;
  '/console/$orgId/configuration/billing/invoices': typeof ConsoleOrgIdConfigurationBillingInvoicesRoute;
  '/console/$orgId/configuration/billing/transactions': typeof ConsoleOrgIdConfigurationBillingTransactionsRoute;
  '/console/$orgId/configuration/billing/usage': typeof ConsoleOrgIdConfigurationBillingUsageRoute;
  '/console/$orgId/configuration/product-types/$productTypeId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdRouteRouteWithChildren;
  '/console/$orgId/configuration/risk/$section': typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
  '/console/$orgId/configuration/suppliers/add': typeof ConsoleOrgIdConfigurationSuppliersAddRoute;
  '/console/$orgId/controls/register/add': typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  '/console/$orgId/tools/auto-detect/dashboard': typeof ConsoleOrgIdToolsAutoDetectDashboardRoute;
  '/console/$orgId/tools/auto-detect/inbox': typeof ConsoleOrgIdToolsAutoDetectInboxRouteRouteWithChildren;
  '/console/$orgId/workload-manager/tasks/calendar': typeof ConsoleOrgIdWorkloadManagerTasksCalendarRouteRouteWithChildren;
  '/console/$orgId/workload-manager/tasks/list': typeof ConsoleOrgIdWorkloadManagerTasksListRouteRouteWithChildren;
  '/public/$orgId/invoice/view/$accessKey': typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  '/console/$orgId/claims/details/$id/comms': typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/contacts': typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  '/console/$orgId/claims/details/$id/expenses': typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/financials': typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  '/console/$orgId/claims/details/$id/history': typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  '/console/$orgId/claims/details/$id/library': typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  '/console/$orgId/claims/details/$id/manage': typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/policy-info': typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  '/console/$orgId/claims/details/$id/product-type-data': typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id': typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/compliance/training/search/add': typeof ConsoleOrgIdComplianceTrainingSearchAddRoute;
  '/console/$orgId/compliance/training/user/$userId': typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  '/console/$orgId/configuration/billing/payment/success': typeof ConsoleOrgIdConfigurationBillingPaymentSuccessRoute;
  '/console/$orgId/configuration/suppliers/details/$id': typeof ConsoleOrgIdConfigurationSuppliersDetailsIdRouteRoute;
  '/console/$orgId/controls/register/details/$id': typeof ConsoleOrgIdControlsRegisterDetailsIdRouteRoute;
  '/console/$orgId/risk/ras/details/$id': typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  '/console/$orgId/workload-manager/tasks/calendar/add': typeof ConsoleOrgIdWorkloadManagerTasksCalendarAddRoute;
  '/console/$orgId/workload-manager/tasks/list/add': typeof ConsoleOrgIdWorkloadManagerTasksListAddRoute;
  '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/add': typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/add': typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  '/console/$orgId/compliance/coi/details/$id/$section': typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/history': typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/library': typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents/details/$id/$section': typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/obligations/details/$id/$section': typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/add': typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
  '/console/$orgId/risk/register/details/$id/$section': typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
  '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification': typeof ConsoleOrgIdToolsAutoDetectInboxIdReviewEmailClassificationRoute;
  '/console/$orgId/workload-manager/tasks/calendar/details/$id': typeof ConsoleOrgIdWorkloadManagerTasksCalendarDetailsIdRoute;
  '/console/$orgId/workload-manager/tasks/list/details/$id': typeof ConsoleOrgIdWorkloadManagerTasksListDetailsIdRoute;
  '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/message/$messageId': typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
  '/console/$orgId/claims/details/$id/manage/flag/add': typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/claims/details/$id/manage/strategies/add': typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/edit/$recordId': typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/note/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/admin-console'
    | '/invite'
    | '/maintenance'
    | '/not-found'
    | '/public'
    | '/sign-up'
    | '/admin-console/'
    | '/admin-login'
    | '/callback'
    | '/login'
    | '/logout'
    | '/logoutin'
    | '/payment-pending'
    | '/admin-console/admin-playground'
    | '/admin-console/advanced'
    | '/admin-console/ai-prompts'
    | '/admin-console/comm-templates'
    | '/admin-console/configuration'
    | '/admin-console/controls'
    | '/admin-console/faq'
    | '/admin-console/new-organization'
    | '/admin-console/obligations'
    | '/admin-console/organizations'
    | '/admin-console/products'
    | '/admin-console/reports'
    | '/admin-console/users'
    | '/console/$orgId'
    | '/public/$orgId'
    | '/admin-console/advanced/'
    | '/admin-console/configuration/'
    | '/admin-console/controls/'
    | '/admin-console/obligations/'
    | '/console/$orgId/'
    | '/change-org/$orgId'
    | '/admin-console/advanced/app-settings'
    | '/admin-console/advanced/db-upgrades'
    | '/admin-console/advanced/indicators'
    | '/admin-console/advanced/jobs'
    | '/admin-console/advanced/monitoring'
    | '/admin-console/advanced/system-logs'
    | '/admin-console/configuration/obligations'
    | '/admin-console/controls/library'
    | '/admin-console/obligations/library'
    | '/admin-console/organizations/$orgId'
    | '/admin-console/products/$serviceId'
    | '/console/$orgId/claim-module-only-home-page'
    | '/console/$orgId/claims'
    | '/console/$orgId/compliance'
    | '/console/$orgId/configuration'
    | '/console/$orgId/controls'
    | '/console/$orgId/dashboard'
    | '/console/$orgId/debug'
    | '/console/$orgId/faq'
    | '/console/$orgId/get-file'
    | '/console/$orgId/reporting'
    | '/console/$orgId/risk'
    | '/console/$orgId/tools'
    | '/console/$orgId/tools-only-home-page'
    | '/console/$orgId/user-settings'
    | '/console/$orgId/workload-manager'
    | '/public/lodgement-summary/$accessKey'
    | '/public/new-complaint-by-key/$key'
    | '/public/new-complaint/$orgId'
    | '/admin-console/advanced/jobs/'
    | '/admin-console/controls/library/'
    | '/admin-console/obligations/library/'
    | '/console/$orgId/claims/'
    | '/console/$orgId/compliance/'
    | '/console/$orgId/configuration/'
    | '/console/$orgId/controls/'
    | '/console/$orgId/dashboard/'
    | '/console/$orgId/debug/'
    | '/console/$orgId/risk/'
    | '/console/$orgId/tools/'
    | '/console/$orgId/user-settings/'
    | '/admin-console/advanced/indicators/complaints'
    | '/admin-console/advanced/indicators/incidents'
    | '/admin-console/advanced/jobs/dynamic-recurring'
    | '/admin-console/advanced/jobs/on-demand'
    | '/admin-console/advanced/jobs/recurring'
    | '/admin-console/obligations/library/add'
    | '/admin-console/organizations/$orgId/subscriptions'
    | '/admin-console/organizations/$orgId/users'
    | '/admin-console/organizations/$orgId/wallet'
    | '/console/$orgId/claims/lodge'
    | '/console/$orgId/compliance/coi'
    | '/console/$orgId/compliance/complaints'
    | '/console/$orgId/compliance/incidents'
    | '/console/$orgId/compliance/obligations'
    | '/console/$orgId/compliance/training'
    | '/console/$orgId/configuration/assistance'
    | '/console/$orgId/configuration/billing'
    | '/console/$orgId/configuration/claim'
    | '/console/$orgId/configuration/comms-templates'
    | '/console/$orgId/configuration/compliance'
    | '/console/$orgId/configuration/documents'
    | '/console/$orgId/configuration/expenses-and-billing'
    | '/console/$orgId/configuration/handling-parties'
    | '/console/$orgId/configuration/insurers'
    | '/console/$orgId/configuration/payments'
    | '/console/$orgId/configuration/product-types'
    | '/console/$orgId/configuration/questionnaire'
    | '/console/$orgId/configuration/risk'
    | '/console/$orgId/configuration/statistics'
    | '/console/$orgId/configuration/suppliers'
    | '/console/$orgId/configuration/system'
    | '/console/$orgId/configuration/task-manager'
    | '/console/$orgId/configuration/tasks'
    | '/console/$orgId/configuration/users'
    | '/console/$orgId/controls/register'
    | '/console/$orgId/controls/tests'
    | '/console/$orgId/dashboard/$section'
    | '/console/$orgId/debug/test'
    | '/console/$orgId/reporting/add'
    | '/console/$orgId/risk/ras'
    | '/console/$orgId/risk/register'
    | '/console/$orgId/tools/auto-detect'
    | '/console/$orgId/tools/website-checker'
    | '/console/$orgId/user-settings/notifications'
    | '/console/$orgId/user-settings/profile'
    | '/console/$orgId/workload-manager/emails'
    | '/console/$orgId/workload-manager/tasks'
    | '/public/$orgId/complaint/new'
    | '/console/$orgId/compliance/coi/'
    | '/console/$orgId/compliance/complaints/'
    | '/console/$orgId/compliance/incidents/'
    | '/console/$orgId/compliance/obligations/'
    | '/console/$orgId/compliance/training/'
    | '/console/$orgId/configuration/product-types/'
    | '/console/$orgId/configuration/risk/'
    | '/console/$orgId/configuration/suppliers/'
    | '/console/$orgId/controls/register/'
    | '/console/$orgId/risk/ras/'
    | '/console/$orgId/risk/register/'
    | '/admin-console/controls/library/$id/$section'
    | '/admin-console/obligations/library/$id/$section'
    | '/console/$orgId/claims/details/$id'
    | '/console/$orgId/claims/edit/$id'
    | '/console/$orgId/claims/view/$id'
    | '/console/$orgId/compliance/coi/add'
    | '/console/$orgId/compliance/complaints/add'
    | '/console/$orgId/compliance/incidents/add'
    | '/console/$orgId/compliance/obligations/add'
    | '/console/$orgId/compliance/training/search'
    | '/console/$orgId/configuration/billing/balance'
    | '/console/$orgId/configuration/billing/billing-info'
    | '/console/$orgId/configuration/billing/invoices'
    | '/console/$orgId/configuration/billing/transactions'
    | '/console/$orgId/configuration/billing/usage'
    | '/console/$orgId/configuration/product-types/$productTypeId'
    | '/console/$orgId/configuration/risk/$section'
    | '/console/$orgId/configuration/suppliers/add'
    | '/console/$orgId/controls/register/add'
    | '/console/$orgId/tools/auto-detect/dashboard'
    | '/console/$orgId/tools/auto-detect/inbox'
    | '/console/$orgId/workload-manager/tasks/calendar'
    | '/console/$orgId/workload-manager/tasks/list'
    | '/public/$orgId/invoice/view/$accessKey'
    | '/console/$orgId/configuration/product-types/$productTypeId/'
    | '/console/$orgId/claims/details/$id/comms'
    | '/console/$orgId/claims/details/$id/contacts'
    | '/console/$orgId/claims/details/$id/expenses'
    | '/console/$orgId/claims/details/$id/financials'
    | '/console/$orgId/claims/details/$id/history'
    | '/console/$orgId/claims/details/$id/library'
    | '/console/$orgId/claims/details/$id/manage'
    | '/console/$orgId/claims/details/$id/policy-info'
    | '/console/$orgId/claims/details/$id/product-type-data'
    | '/console/$orgId/compliance/complaints/details/$id'
    | '/console/$orgId/compliance/training/search/add'
    | '/console/$orgId/compliance/training/user/$userId'
    | '/console/$orgId/configuration/billing/payment/success'
    | '/console/$orgId/configuration/suppliers/details/$id'
    | '/console/$orgId/controls/register/details/$id'
    | '/console/$orgId/risk/ras/details/$id'
    | '/console/$orgId/workload-manager/tasks/calendar/add'
    | '/console/$orgId/workload-manager/tasks/list/add'
    | '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/add'
    | '/console/$orgId/claims/details/$id/expenses/add'
    | '/console/$orgId/compliance/coi/details/$id/$section'
    | '/console/$orgId/compliance/complaints/details/$id/history'
    | '/console/$orgId/compliance/complaints/details/$id/library'
    | '/console/$orgId/compliance/complaints/details/$id/manage'
    | '/console/$orgId/compliance/incidents/details/$id/$section'
    | '/console/$orgId/compliance/obligations/details/$id/$section'
    | '/console/$orgId/compliance/training/user/$userId/add'
    | '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId'
    | '/console/$orgId/risk/register/details/$id/$section'
    | '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification'
    | '/console/$orgId/workload-manager/tasks/calendar/details/$id'
    | '/console/$orgId/workload-manager/tasks/list/details/$id'
    | '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/message/$messageId'
    | '/console/$orgId/claims/details/$id/expenses/$expenseId/edit'
    | '/console/$orgId/claims/details/$id/manage/flag/add'
    | '/console/$orgId/claims/details/$id/manage/strategies/add'
    | '/console/$orgId/compliance/training/user/$userId/edit/$recordId'
    | '/console/$orgId/compliance/complaints/details/$id/manage/flag/add'
    | '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/invite'
    | '/maintenance'
    | '/not-found'
    | '/public'
    | '/sign-up'
    | '/admin-console'
    | '/admin-login'
    | '/callback'
    | '/login'
    | '/logout'
    | '/logoutin'
    | '/payment-pending'
    | '/admin-console/admin-playground'
    | '/admin-console/ai-prompts'
    | '/admin-console/comm-templates'
    | '/admin-console/faq'
    | '/admin-console/new-organization'
    | '/admin-console/organizations'
    | '/admin-console/products'
    | '/admin-console/reports'
    | '/admin-console/users'
    | '/public/$orgId'
    | '/admin-console/advanced'
    | '/admin-console/configuration'
    | '/admin-console/controls'
    | '/admin-console/obligations'
    | '/console/$orgId'
    | '/change-org/$orgId'
    | '/admin-console/advanced/app-settings'
    | '/admin-console/advanced/db-upgrades'
    | '/admin-console/advanced/indicators'
    | '/admin-console/advanced/monitoring'
    | '/admin-console/advanced/system-logs'
    | '/admin-console/configuration/obligations'
    | '/admin-console/organizations/$orgId'
    | '/admin-console/products/$serviceId'
    | '/console/$orgId/claim-module-only-home-page'
    | '/console/$orgId/faq'
    | '/console/$orgId/get-file'
    | '/console/$orgId/reporting'
    | '/console/$orgId/tools-only-home-page'
    | '/console/$orgId/workload-manager'
    | '/public/lodgement-summary/$accessKey'
    | '/public/new-complaint-by-key/$key'
    | '/public/new-complaint/$orgId'
    | '/admin-console/advanced/jobs'
    | '/admin-console/controls/library'
    | '/admin-console/obligations/library'
    | '/console/$orgId/claims'
    | '/console/$orgId/compliance'
    | '/console/$orgId/configuration'
    | '/console/$orgId/controls'
    | '/console/$orgId/dashboard'
    | '/console/$orgId/debug'
    | '/console/$orgId/risk'
    | '/console/$orgId/tools'
    | '/console/$orgId/user-settings'
    | '/admin-console/advanced/indicators/complaints'
    | '/admin-console/advanced/indicators/incidents'
    | '/admin-console/advanced/jobs/dynamic-recurring'
    | '/admin-console/advanced/jobs/on-demand'
    | '/admin-console/advanced/jobs/recurring'
    | '/admin-console/obligations/library/add'
    | '/admin-console/organizations/$orgId/subscriptions'
    | '/admin-console/organizations/$orgId/users'
    | '/admin-console/organizations/$orgId/wallet'
    | '/console/$orgId/claims/lodge'
    | '/console/$orgId/configuration/assistance'
    | '/console/$orgId/configuration/billing'
    | '/console/$orgId/configuration/claim'
    | '/console/$orgId/configuration/comms-templates'
    | '/console/$orgId/configuration/compliance'
    | '/console/$orgId/configuration/documents'
    | '/console/$orgId/configuration/expenses-and-billing'
    | '/console/$orgId/configuration/handling-parties'
    | '/console/$orgId/configuration/insurers'
    | '/console/$orgId/configuration/payments'
    | '/console/$orgId/configuration/questionnaire'
    | '/console/$orgId/configuration/statistics'
    | '/console/$orgId/configuration/system'
    | '/console/$orgId/configuration/task-manager'
    | '/console/$orgId/configuration/tasks'
    | '/console/$orgId/configuration/users'
    | '/console/$orgId/controls/tests'
    | '/console/$orgId/dashboard/$section'
    | '/console/$orgId/debug/test'
    | '/console/$orgId/reporting/add'
    | '/console/$orgId/tools/auto-detect'
    | '/console/$orgId/tools/website-checker'
    | '/console/$orgId/user-settings/notifications'
    | '/console/$orgId/user-settings/profile'
    | '/console/$orgId/workload-manager/emails'
    | '/console/$orgId/workload-manager/tasks'
    | '/public/$orgId/complaint/new'
    | '/console/$orgId/compliance/coi'
    | '/console/$orgId/compliance/complaints'
    | '/console/$orgId/compliance/incidents'
    | '/console/$orgId/compliance/obligations'
    | '/console/$orgId/compliance/training'
    | '/console/$orgId/configuration/product-types'
    | '/console/$orgId/configuration/risk'
    | '/console/$orgId/configuration/suppliers'
    | '/console/$orgId/controls/register'
    | '/console/$orgId/risk/ras'
    | '/console/$orgId/risk/register'
    | '/admin-console/controls/library/$id/$section'
    | '/admin-console/obligations/library/$id/$section'
    | '/console/$orgId/claims/details/$id'
    | '/console/$orgId/claims/edit/$id'
    | '/console/$orgId/claims/view/$id'
    | '/console/$orgId/compliance/coi/add'
    | '/console/$orgId/compliance/complaints/add'
    | '/console/$orgId/compliance/incidents/add'
    | '/console/$orgId/compliance/obligations/add'
    | '/console/$orgId/compliance/training/search'
    | '/console/$orgId/configuration/billing/balance'
    | '/console/$orgId/configuration/billing/billing-info'
    | '/console/$orgId/configuration/billing/invoices'
    | '/console/$orgId/configuration/billing/transactions'
    | '/console/$orgId/configuration/billing/usage'
    | '/console/$orgId/configuration/risk/$section'
    | '/console/$orgId/configuration/suppliers/add'
    | '/console/$orgId/controls/register/add'
    | '/console/$orgId/tools/auto-detect/dashboard'
    | '/console/$orgId/tools/auto-detect/inbox'
    | '/console/$orgId/workload-manager/tasks/calendar'
    | '/console/$orgId/workload-manager/tasks/list'
    | '/public/$orgId/invoice/view/$accessKey'
    | '/console/$orgId/configuration/product-types/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms'
    | '/console/$orgId/claims/details/$id/contacts'
    | '/console/$orgId/claims/details/$id/expenses'
    | '/console/$orgId/claims/details/$id/financials'
    | '/console/$orgId/claims/details/$id/history'
    | '/console/$orgId/claims/details/$id/library'
    | '/console/$orgId/claims/details/$id/manage'
    | '/console/$orgId/claims/details/$id/policy-info'
    | '/console/$orgId/claims/details/$id/product-type-data'
    | '/console/$orgId/compliance/complaints/details/$id'
    | '/console/$orgId/compliance/training/search/add'
    | '/console/$orgId/compliance/training/user/$userId'
    | '/console/$orgId/configuration/billing/payment/success'
    | '/console/$orgId/configuration/suppliers/details/$id'
    | '/console/$orgId/controls/register/details/$id'
    | '/console/$orgId/risk/ras/details/$id'
    | '/console/$orgId/workload-manager/tasks/calendar/add'
    | '/console/$orgId/workload-manager/tasks/list/add'
    | '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/add'
    | '/console/$orgId/claims/details/$id/expenses/add'
    | '/console/$orgId/compliance/coi/details/$id/$section'
    | '/console/$orgId/compliance/complaints/details/$id/history'
    | '/console/$orgId/compliance/complaints/details/$id/library'
    | '/console/$orgId/compliance/complaints/details/$id/manage'
    | '/console/$orgId/compliance/incidents/details/$id/$section'
    | '/console/$orgId/compliance/obligations/details/$id/$section'
    | '/console/$orgId/compliance/training/user/$userId/add'
    | '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId'
    | '/console/$orgId/risk/register/details/$id/$section'
    | '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification'
    | '/console/$orgId/workload-manager/tasks/calendar/details/$id'
    | '/console/$orgId/workload-manager/tasks/list/details/$id'
    | '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/message/$messageId'
    | '/console/$orgId/claims/details/$id/expenses/$expenseId/edit'
    | '/console/$orgId/claims/details/$id/manage/flag/add'
    | '/console/$orgId/claims/details/$id/manage/strategies/add'
    | '/console/$orgId/compliance/training/user/$userId/edit/$recordId'
    | '/console/$orgId/compliance/complaints/details/$id/manage/flag/add'
    | '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
  id:
    | '__root__'
    | '/'
    | '/admin-console'
    | '/invite'
    | '/maintenance'
    | '/not-found'
    | '/public'
    | '/sign-up'
    | '/admin-console/'
    | '/(authentication)/admin-login'
    | '/(authentication)/callback'
    | '/(authentication)/login'
    | '/(authentication)/logout'
    | '/(authentication)/logoutin'
    | '/(authentication)/payment-pending'
    | '/admin-console/admin-playground'
    | '/admin-console/advanced'
    | '/admin-console/ai-prompts'
    | '/admin-console/comm-templates'
    | '/admin-console/configuration'
    | '/admin-console/controls'
    | '/admin-console/faq'
    | '/admin-console/new-organization'
    | '/admin-console/obligations'
    | '/admin-console/organizations'
    | '/admin-console/products'
    | '/admin-console/reports'
    | '/admin-console/users'
    | '/console/$orgId'
    | '/public/$orgId'
    | '/admin-console/advanced/'
    | '/admin-console/configuration/'
    | '/admin-console/controls/'
    | '/admin-console/obligations/'
    | '/console/$orgId/'
    | '/(authentication)/change-org/$orgId'
    | '/admin-console/advanced/app-settings'
    | '/admin-console/advanced/db-upgrades'
    | '/admin-console/advanced/indicators'
    | '/admin-console/advanced/jobs'
    | '/admin-console/advanced/monitoring'
    | '/admin-console/advanced/system-logs'
    | '/admin-console/configuration/obligations'
    | '/admin-console/controls/library'
    | '/admin-console/obligations/library'
    | '/admin-console/organizations/$orgId'
    | '/admin-console/products/$serviceId'
    | '/console/$orgId/claim-module-only-home-page'
    | '/console/$orgId/claims'
    | '/console/$orgId/compliance'
    | '/console/$orgId/configuration'
    | '/console/$orgId/controls'
    | '/console/$orgId/dashboard'
    | '/console/$orgId/debug'
    | '/console/$orgId/faq'
    | '/console/$orgId/get-file'
    | '/console/$orgId/reporting'
    | '/console/$orgId/risk'
    | '/console/$orgId/tools'
    | '/console/$orgId/tools-only-home-page'
    | '/console/$orgId/user-settings'
    | '/console/$orgId/workload-manager'
    | '/public/lodgement-summary/$accessKey'
    | '/public/new-complaint-by-key/$key'
    | '/public/new-complaint/$orgId'
    | '/admin-console/advanced/jobs/'
    | '/admin-console/controls/library/'
    | '/admin-console/obligations/library/'
    | '/console/$orgId/claims/'
    | '/console/$orgId/compliance/'
    | '/console/$orgId/configuration/'
    | '/console/$orgId/controls/'
    | '/console/$orgId/dashboard/'
    | '/console/$orgId/debug/'
    | '/console/$orgId/risk/'
    | '/console/$orgId/tools/'
    | '/console/$orgId/user-settings/'
    | '/admin-console/advanced/indicators/complaints'
    | '/admin-console/advanced/indicators/incidents'
    | '/admin-console/advanced/jobs/dynamic-recurring'
    | '/admin-console/advanced/jobs/on-demand'
    | '/admin-console/advanced/jobs/recurring'
    | '/admin-console/obligations/library/add'
    | '/admin-console/organizations/$orgId/subscriptions'
    | '/admin-console/organizations/$orgId/users'
    | '/admin-console/organizations/$orgId/wallet'
    | '/console/$orgId/claims/lodge'
    | '/console/$orgId/compliance/coi'
    | '/console/$orgId/compliance/complaints'
    | '/console/$orgId/compliance/incidents'
    | '/console/$orgId/compliance/obligations'
    | '/console/$orgId/compliance/training'
    | '/console/$orgId/configuration/assistance'
    | '/console/$orgId/configuration/billing'
    | '/console/$orgId/configuration/claim'
    | '/console/$orgId/configuration/comms-templates'
    | '/console/$orgId/configuration/compliance'
    | '/console/$orgId/configuration/documents'
    | '/console/$orgId/configuration/expenses-and-billing'
    | '/console/$orgId/configuration/handling-parties'
    | '/console/$orgId/configuration/insurers'
    | '/console/$orgId/configuration/payments'
    | '/console/$orgId/configuration/product-types'
    | '/console/$orgId/configuration/questionnaire'
    | '/console/$orgId/configuration/risk'
    | '/console/$orgId/configuration/statistics'
    | '/console/$orgId/configuration/suppliers'
    | '/console/$orgId/configuration/system'
    | '/console/$orgId/configuration/task-manager'
    | '/console/$orgId/configuration/tasks'
    | '/console/$orgId/configuration/users'
    | '/console/$orgId/controls/register'
    | '/console/$orgId/controls/tests'
    | '/console/$orgId/dashboard/$section'
    | '/console/$orgId/debug/test'
    | '/console/$orgId/reporting/add'
    | '/console/$orgId/risk/ras'
    | '/console/$orgId/risk/register'
    | '/console/$orgId/tools/auto-detect'
    | '/console/$orgId/tools/website-checker'
    | '/console/$orgId/user-settings/notifications'
    | '/console/$orgId/user-settings/profile'
    | '/console/$orgId/workload-manager/emails'
    | '/console/$orgId/workload-manager/tasks'
    | '/public/$orgId/complaint/new'
    | '/console/$orgId/compliance/coi/'
    | '/console/$orgId/compliance/complaints/'
    | '/console/$orgId/compliance/incidents/'
    | '/console/$orgId/compliance/obligations/'
    | '/console/$orgId/compliance/training/'
    | '/console/$orgId/configuration/product-types/'
    | '/console/$orgId/configuration/risk/'
    | '/console/$orgId/configuration/suppliers/'
    | '/console/$orgId/controls/register/'
    | '/console/$orgId/risk/ras/'
    | '/console/$orgId/risk/register/'
    | '/admin-console/controls/library/$id/$section'
    | '/admin-console/obligations/library/$id/$section'
    | '/console/$orgId/claims/details/$id'
    | '/console/$orgId/claims/edit/$id'
    | '/console/$orgId/claims/view/$id'
    | '/console/$orgId/compliance/coi/add'
    | '/console/$orgId/compliance/complaints/add'
    | '/console/$orgId/compliance/incidents/add'
    | '/console/$orgId/compliance/obligations/add'
    | '/console/$orgId/compliance/training/search'
    | '/console/$orgId/configuration/billing/balance'
    | '/console/$orgId/configuration/billing/billing-info'
    | '/console/$orgId/configuration/billing/invoices'
    | '/console/$orgId/configuration/billing/transactions'
    | '/console/$orgId/configuration/billing/usage'
    | '/console/$orgId/configuration/product-types/$productTypeId'
    | '/console/$orgId/configuration/risk/$section'
    | '/console/$orgId/configuration/suppliers/add'
    | '/console/$orgId/controls/register/add'
    | '/console/$orgId/tools/auto-detect/dashboard'
    | '/console/$orgId/tools/auto-detect/inbox'
    | '/console/$orgId/workload-manager/tasks/calendar'
    | '/console/$orgId/workload-manager/tasks/list'
    | '/public/$orgId/invoice/view/$accessKey'
    | '/console/$orgId/configuration/product-types/$productTypeId/'
    | '/console/$orgId/claims/details/$id/comms'
    | '/console/$orgId/claims/details/$id/contacts'
    | '/console/$orgId/claims/details/$id/expenses'
    | '/console/$orgId/claims/details/$id/financials'
    | '/console/$orgId/claims/details/$id/history'
    | '/console/$orgId/claims/details/$id/library'
    | '/console/$orgId/claims/details/$id/manage'
    | '/console/$orgId/claims/details/$id/policy-info'
    | '/console/$orgId/claims/details/$id/product-type-data'
    | '/console/$orgId/compliance/complaints/details/$id'
    | '/console/$orgId/compliance/training/search/add'
    | '/console/$orgId/compliance/training/user/$userId'
    | '/console/$orgId/configuration/billing/payment/success'
    | '/console/$orgId/configuration/suppliers/details/$id'
    | '/console/$orgId/controls/register/details/$id'
    | '/console/$orgId/risk/ras/details/$id'
    | '/console/$orgId/workload-manager/tasks/calendar/add'
    | '/console/$orgId/workload-manager/tasks/list/add'
    | '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/add'
    | '/console/$orgId/claims/details/$id/expenses/add'
    | '/console/$orgId/compliance/coi/details/$id/$section'
    | '/console/$orgId/compliance/complaints/details/$id/history'
    | '/console/$orgId/compliance/complaints/details/$id/library'
    | '/console/$orgId/compliance/complaints/details/$id/manage'
    | '/console/$orgId/compliance/incidents/details/$id/$section'
    | '/console/$orgId/compliance/obligations/details/$id/$section'
    | '/console/$orgId/compliance/training/user/$userId/add'
    | '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId'
    | '/console/$orgId/risk/register/details/$id/$section'
    | '/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification'
    | '/console/$orgId/workload-manager/tasks/calendar/details/$id'
    | '/console/$orgId/workload-manager/tasks/list/details/$id'
    | '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/message/$messageId'
    | '/console/$orgId/claims/details/$id/expenses/$expenseId/edit'
    | '/console/$orgId/claims/details/$id/manage/flag/add'
    | '/console/$orgId/claims/details/$id/manage/strategies/add'
    | '/console/$orgId/compliance/training/user/$userId/edit/$recordId'
    | '/console/$orgId/compliance/complaints/details/$id/manage/flag/add'
    | '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AdminConsoleRouteRoute: typeof AdminConsoleRouteRouteWithChildren;
  InviteRouteRoute: typeof InviteRouteRoute;
  MaintenanceRouteRoute: typeof MaintenanceRouteRoute;
  NotFoundRoute: typeof NotFoundRoute;
  PublicRouteRoute: typeof PublicRouteRouteWithChildren;
  SignUpRouteRoute: typeof SignUpRouteRoute;
  authenticationAdminLoginRouteRoute: typeof authenticationAdminLoginRouteRoute;
  authenticationCallbackRouteRoute: typeof authenticationCallbackRouteRoute;
  authenticationLoginRouteRoute: typeof authenticationLoginRouteRoute;
  authenticationLogoutRouteRoute: typeof authenticationLogoutRouteRoute;
  authenticationLogoutinRouteRoute: typeof authenticationLogoutinRouteRoute;
  authenticationPaymentPendingRoute: typeof authenticationPaymentPendingRoute;
  ConsoleOrgIdRouteRoute: typeof ConsoleOrgIdRouteRouteWithChildren;
  authenticationChangeOrgOrgIdRouteRoute: typeof authenticationChangeOrgOrgIdRouteRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminConsoleRouteRoute: AdminConsoleRouteRouteWithChildren,
  InviteRouteRoute: InviteRouteRoute,
  MaintenanceRouteRoute: MaintenanceRouteRoute,
  NotFoundRoute: NotFoundRoute,
  PublicRouteRoute: PublicRouteRouteWithChildren,
  SignUpRouteRoute: SignUpRouteRoute,
  authenticationAdminLoginRouteRoute: authenticationAdminLoginRouteRoute,
  authenticationCallbackRouteRoute: authenticationCallbackRouteRoute,
  authenticationLoginRouteRoute: authenticationLoginRouteRoute,
  authenticationLogoutRouteRoute: authenticationLogoutRouteRoute,
  authenticationLogoutinRouteRoute: authenticationLogoutinRouteRoute,
  authenticationPaymentPendingRoute: authenticationPaymentPendingRoute,
  ConsoleOrgIdRouteRoute: ConsoleOrgIdRouteRouteWithChildren,
  authenticationChangeOrgOrgIdRouteRoute:
    authenticationChangeOrgOrgIdRouteRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "~__root.tsx",
      "children": [
        "/",
        "/admin-console",
        "/invite",
        "/maintenance",
        "/not-found",
        "/public",
        "/sign-up",
        "/(authentication)/admin-login",
        "/(authentication)/callback",
        "/(authentication)/login",
        "/(authentication)/logout",
        "/(authentication)/logoutin",
        "/(authentication)/payment-pending",
        "/console/$orgId",
        "/(authentication)/change-org/$orgId"
      ]
    },
    "/": {
      "filePath": "~index.tsx"
    },
    "/admin-console": {
      "filePath": "~admin-console/~route.tsx",
      "children": [
        "/admin-console/",
        "/admin-console/admin-playground",
        "/admin-console/advanced",
        "/admin-console/ai-prompts",
        "/admin-console/comm-templates",
        "/admin-console/configuration",
        "/admin-console/controls",
        "/admin-console/faq",
        "/admin-console/new-organization",
        "/admin-console/obligations",
        "/admin-console/organizations",
        "/admin-console/products",
        "/admin-console/reports",
        "/admin-console/users"
      ]
    },
    "/invite": {
      "filePath": "~invite/~route.tsx"
    },
    "/maintenance": {
      "filePath": "~maintenance/~route.tsx"
    },
    "/not-found": {
      "filePath": "~not-found.tsx"
    },
    "/public": {
      "filePath": "~public/~route.tsx",
      "children": [
        "/public/$orgId",
        "/public/lodgement-summary/$accessKey",
        "/public/new-complaint-by-key/$key",
        "/public/new-complaint/$orgId",
        "/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId"
      ]
    },
    "/sign-up": {
      "filePath": "~sign-up/~route.tsx"
    },
    "/admin-console/": {
      "filePath": "~admin-console/~index.tsx",
      "parent": "/admin-console"
    },
    "/(authentication)/admin-login": {
      "filePath": "~(authentication)/~admin-login/~route.tsx"
    },
    "/(authentication)/callback": {
      "filePath": "~(authentication)/~callback/~route.tsx"
    },
    "/(authentication)/login": {
      "filePath": "~(authentication)/~login/~route.tsx"
    },
    "/(authentication)/logout": {
      "filePath": "~(authentication)/~logout/~route.tsx"
    },
    "/(authentication)/logoutin": {
      "filePath": "~(authentication)/~logoutin/~route.tsx"
    },
    "/(authentication)/payment-pending": {
      "filePath": "~(authentication)/~payment-pending.tsx"
    },
    "/admin-console/admin-playground": {
      "filePath": "~admin-console/~admin-playground.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/advanced": {
      "filePath": "~admin-console/~advanced/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/advanced/",
        "/admin-console/advanced/app-settings",
        "/admin-console/advanced/db-upgrades",
        "/admin-console/advanced/indicators",
        "/admin-console/advanced/jobs",
        "/admin-console/advanced/monitoring",
        "/admin-console/advanced/system-logs"
      ]
    },
    "/admin-console/ai-prompts": {
      "filePath": "~admin-console/~ai-prompts/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/comm-templates": {
      "filePath": "~admin-console/~comm-templates/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/configuration": {
      "filePath": "~admin-console/~configuration/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/configuration/",
        "/admin-console/configuration/obligations"
      ]
    },
    "/admin-console/controls": {
      "filePath": "~admin-console/~controls/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/controls/",
        "/admin-console/controls/library"
      ]
    },
    "/admin-console/faq": {
      "filePath": "~admin-console/~faq/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/new-organization": {
      "filePath": "~admin-console/~new-organization/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/obligations": {
      "filePath": "~admin-console/~obligations/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/obligations/",
        "/admin-console/obligations/library"
      ]
    },
    "/admin-console/organizations": {
      "filePath": "~admin-console/~organizations/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/organizations/$orgId"
      ]
    },
    "/admin-console/products": {
      "filePath": "~admin-console/~products/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/products/$serviceId"
      ]
    },
    "/admin-console/reports": {
      "filePath": "~admin-console/~reports/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/users": {
      "filePath": "~admin-console/~users/~route.tsx",
      "parent": "/admin-console"
    },
    "/console/$orgId": {
      "filePath": "~console.$orgId/~route.tsx",
      "children": [
        "/console/$orgId/",
        "/console/$orgId/claim-module-only-home-page",
        "/console/$orgId/claims",
        "/console/$orgId/compliance",
        "/console/$orgId/configuration",
        "/console/$orgId/controls",
        "/console/$orgId/dashboard",
        "/console/$orgId/debug",
        "/console/$orgId/faq",
        "/console/$orgId/get-file",
        "/console/$orgId/reporting",
        "/console/$orgId/risk",
        "/console/$orgId/tools",
        "/console/$orgId/tools-only-home-page",
        "/console/$orgId/user-settings",
        "/console/$orgId/workload-manager"
      ]
    },
    "/public/$orgId": {
      "filePath": "~public/~$orgId/~route.tsx",
      "parent": "/public",
      "children": [
        "/public/$orgId/complaint/new",
        "/public/$orgId/invoice/view/$accessKey",
        "/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId"
      ]
    },
    "/admin-console/advanced/": {
      "filePath": "~admin-console/~advanced/~index.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/configuration/": {
      "filePath": "~admin-console/~configuration/~index.tsx",
      "parent": "/admin-console/configuration"
    },
    "/admin-console/controls/": {
      "filePath": "~admin-console/~controls/~index.tsx",
      "parent": "/admin-console/controls"
    },
    "/admin-console/obligations/": {
      "filePath": "~admin-console/~obligations/~index.tsx",
      "parent": "/admin-console/obligations"
    },
    "/console/$orgId/": {
      "filePath": "~console.$orgId/~index.tsx",
      "parent": "/console/$orgId"
    },
    "/(authentication)/change-org/$orgId": {
      "filePath": "~(authentication)/~change-org.$orgId/~route.tsx"
    },
    "/admin-console/advanced/app-settings": {
      "filePath": "~admin-console/~advanced/~app-settings.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/advanced/db-upgrades": {
      "filePath": "~admin-console/~advanced/~db-upgrades.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/advanced/indicators": {
      "filePath": "~admin-console/~advanced/~indicators/~route.tsx",
      "parent": "/admin-console/advanced",
      "children": [
        "/admin-console/advanced/indicators/complaints",
        "/admin-console/advanced/indicators/incidents"
      ]
    },
    "/admin-console/advanced/jobs": {
      "filePath": "~admin-console/~advanced/~jobs/~route.tsx",
      "parent": "/admin-console/advanced",
      "children": [
        "/admin-console/advanced/jobs/",
        "/admin-console/advanced/jobs/dynamic-recurring",
        "/admin-console/advanced/jobs/on-demand",
        "/admin-console/advanced/jobs/recurring"
      ]
    },
    "/admin-console/advanced/monitoring": {
      "filePath": "~admin-console/~advanced/~monitoring.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/advanced/system-logs": {
      "filePath": "~admin-console/~advanced/~system-logs.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/configuration/obligations": {
      "filePath": "~admin-console/~configuration/~obligations/~route.tsx",
      "parent": "/admin-console/configuration"
    },
    "/admin-console/controls/library": {
      "filePath": "~admin-console/~controls/~library/~route.tsx",
      "parent": "/admin-console/controls",
      "children": [
        "/admin-console/controls/library/",
        "/admin-console/controls/library/$id/$section"
      ]
    },
    "/admin-console/obligations/library": {
      "filePath": "~admin-console/~obligations/~library/~route.tsx",
      "parent": "/admin-console/obligations",
      "children": [
        "/admin-console/obligations/library/",
        "/admin-console/obligations/library/add",
        "/admin-console/obligations/library/$id/$section"
      ]
    },
    "/admin-console/organizations/$orgId": {
      "filePath": "~admin-console/~organizations/~$orgId/~route.tsx",
      "parent": "/admin-console/organizations",
      "children": [
        "/admin-console/organizations/$orgId/subscriptions",
        "/admin-console/organizations/$orgId/users",
        "/admin-console/organizations/$orgId/wallet"
      ]
    },
    "/admin-console/products/$serviceId": {
      "filePath": "~admin-console/~products/~$serviceId.tsx",
      "parent": "/admin-console/products"
    },
    "/console/$orgId/claim-module-only-home-page": {
      "filePath": "~console.$orgId/~claim-module-only-home-page.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/claims": {
      "filePath": "~console.$orgId/~claims/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/claims/",
        "/console/$orgId/claims/lodge",
        "/console/$orgId/claims/details/$id",
        "/console/$orgId/claims/edit/$id",
        "/console/$orgId/claims/view/$id"
      ]
    },
    "/console/$orgId/compliance": {
      "filePath": "~console.$orgId/~compliance/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/compliance/",
        "/console/$orgId/compliance/coi",
        "/console/$orgId/compliance/complaints",
        "/console/$orgId/compliance/incidents",
        "/console/$orgId/compliance/obligations",
        "/console/$orgId/compliance/training"
      ]
    },
    "/console/$orgId/configuration": {
      "filePath": "~console.$orgId/~configuration/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/configuration/",
        "/console/$orgId/configuration/assistance",
        "/console/$orgId/configuration/billing",
        "/console/$orgId/configuration/claim",
        "/console/$orgId/configuration/comms-templates",
        "/console/$orgId/configuration/compliance",
        "/console/$orgId/configuration/documents",
        "/console/$orgId/configuration/expenses-and-billing",
        "/console/$orgId/configuration/handling-parties",
        "/console/$orgId/configuration/insurers",
        "/console/$orgId/configuration/payments",
        "/console/$orgId/configuration/product-types",
        "/console/$orgId/configuration/questionnaire",
        "/console/$orgId/configuration/risk",
        "/console/$orgId/configuration/statistics",
        "/console/$orgId/configuration/suppliers",
        "/console/$orgId/configuration/system",
        "/console/$orgId/configuration/task-manager",
        "/console/$orgId/configuration/tasks",
        "/console/$orgId/configuration/users"
      ]
    },
    "/console/$orgId/controls": {
      "filePath": "~console.$orgId/~controls/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/controls/",
        "/console/$orgId/controls/register",
        "/console/$orgId/controls/tests"
      ]
    },
    "/console/$orgId/dashboard": {
      "filePath": "~console.$orgId/~dashboard/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/dashboard/",
        "/console/$orgId/dashboard/$section"
      ]
    },
    "/console/$orgId/debug": {
      "filePath": "~console.$orgId/~debug/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/debug/",
        "/console/$orgId/debug/test"
      ]
    },
    "/console/$orgId/faq": {
      "filePath": "~console.$orgId/~faq/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/get-file": {
      "filePath": "~console.$orgId/~get-file/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/reporting": {
      "filePath": "~console.$orgId/~reporting/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/reporting/add"
      ]
    },
    "/console/$orgId/risk": {
      "filePath": "~console.$orgId/~risk/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/risk/",
        "/console/$orgId/risk/ras",
        "/console/$orgId/risk/register"
      ]
    },
    "/console/$orgId/tools": {
      "filePath": "~console.$orgId/~tools/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/tools/",
        "/console/$orgId/tools/auto-detect",
        "/console/$orgId/tools/website-checker"
      ]
    },
    "/console/$orgId/tools-only-home-page": {
      "filePath": "~console.$orgId/~tools-only-home-page.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/user-settings": {
      "filePath": "~console.$orgId/~user-settings/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/user-settings/",
        "/console/$orgId/user-settings/notifications",
        "/console/$orgId/user-settings/profile"
      ]
    },
    "/console/$orgId/workload-manager": {
      "filePath": "~console.$orgId/~workload-manager/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/workload-manager/emails",
        "/console/$orgId/workload-manager/tasks"
      ]
    },
    "/public/lodgement-summary/$accessKey": {
      "filePath": "~public/~lodgement-summary.$accessKey/~route.tsx",
      "parent": "/public"
    },
    "/public/new-complaint-by-key/$key": {
      "filePath": "~public/~new-complaint-by-key.$key/~route.tsx",
      "parent": "/public"
    },
    "/public/new-complaint/$orgId": {
      "filePath": "~public/~new-complaint.$orgId/~route.tsx",
      "parent": "/public"
    },
    "/admin-console/advanced/jobs/": {
      "filePath": "~admin-console/~advanced/~jobs/~index.tsx",
      "parent": "/admin-console/advanced/jobs"
    },
    "/admin-console/controls/library/": {
      "filePath": "~admin-console/~controls/~library/~index.tsx",
      "parent": "/admin-console/controls/library"
    },
    "/admin-console/obligations/library/": {
      "filePath": "~admin-console/~obligations/~library/~index.tsx",
      "parent": "/admin-console/obligations/library"
    },
    "/console/$orgId/claims/": {
      "filePath": "~console.$orgId/~claims/~index.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/compliance/": {
      "filePath": "~console.$orgId/~compliance/~index.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/configuration/": {
      "filePath": "~console.$orgId/~configuration/~index.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/controls/": {
      "filePath": "~console.$orgId/~controls/~index.tsx",
      "parent": "/console/$orgId/controls"
    },
    "/console/$orgId/dashboard/": {
      "filePath": "~console.$orgId/~dashboard/~index.tsx",
      "parent": "/console/$orgId/dashboard"
    },
    "/console/$orgId/debug/": {
      "filePath": "~console.$orgId/~debug/~index.tsx",
      "parent": "/console/$orgId/debug"
    },
    "/console/$orgId/risk/": {
      "filePath": "~console.$orgId/~risk/~index.tsx",
      "parent": "/console/$orgId/risk"
    },
    "/console/$orgId/tools/": {
      "filePath": "~console.$orgId/~tools/~index.tsx",
      "parent": "/console/$orgId/tools"
    },
    "/console/$orgId/user-settings/": {
      "filePath": "~console.$orgId/~user-settings/~index.tsx",
      "parent": "/console/$orgId/user-settings"
    },
    "/admin-console/advanced/indicators/complaints": {
      "filePath": "~admin-console/~advanced/~indicators/~complaints.tsx",
      "parent": "/admin-console/advanced/indicators"
    },
    "/admin-console/advanced/indicators/incidents": {
      "filePath": "~admin-console/~advanced/~indicators/~incidents.tsx",
      "parent": "/admin-console/advanced/indicators"
    },
    "/admin-console/advanced/jobs/dynamic-recurring": {
      "filePath": "~admin-console/~advanced/~jobs/~dynamic-recurring.tsx",
      "parent": "/admin-console/advanced/jobs"
    },
    "/admin-console/advanced/jobs/on-demand": {
      "filePath": "~admin-console/~advanced/~jobs/~on-demand.tsx",
      "parent": "/admin-console/advanced/jobs"
    },
    "/admin-console/advanced/jobs/recurring": {
      "filePath": "~admin-console/~advanced/~jobs/~recurring.tsx",
      "parent": "/admin-console/advanced/jobs"
    },
    "/admin-console/obligations/library/add": {
      "filePath": "~admin-console/~obligations/~library/~add/~route.tsx",
      "parent": "/admin-console/obligations/library"
    },
    "/admin-console/organizations/$orgId/subscriptions": {
      "filePath": "~admin-console/~organizations/~$orgId/~subscriptions.tsx",
      "parent": "/admin-console/organizations/$orgId"
    },
    "/admin-console/organizations/$orgId/users": {
      "filePath": "~admin-console/~organizations/~$orgId/~users/~route.tsx",
      "parent": "/admin-console/organizations/$orgId"
    },
    "/admin-console/organizations/$orgId/wallet": {
      "filePath": "~admin-console/~organizations/~$orgId/~wallet.tsx",
      "parent": "/admin-console/organizations/$orgId"
    },
    "/console/$orgId/claims/lodge": {
      "filePath": "~console.$orgId/~claims/~lodge/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/compliance/coi": {
      "filePath": "~console.$orgId/~compliance/~coi/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/coi/",
        "/console/$orgId/compliance/coi/add",
        "/console/$orgId/compliance/coi/details/$id/$section"
      ]
    },
    "/console/$orgId/compliance/complaints": {
      "filePath": "~console.$orgId/~compliance/~complaints/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/complaints/",
        "/console/$orgId/compliance/complaints/add",
        "/console/$orgId/compliance/complaints/details/$id"
      ]
    },
    "/console/$orgId/compliance/incidents": {
      "filePath": "~console.$orgId/~compliance/~incidents/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/incidents/",
        "/console/$orgId/compliance/incidents/add",
        "/console/$orgId/compliance/incidents/details/$id/$section"
      ]
    },
    "/console/$orgId/compliance/obligations": {
      "filePath": "~console.$orgId/~compliance/~obligations/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/obligations/",
        "/console/$orgId/compliance/obligations/add",
        "/console/$orgId/compliance/obligations/details/$id/$section"
      ]
    },
    "/console/$orgId/compliance/training": {
      "filePath": "~console.$orgId/~compliance/~training/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/training/",
        "/console/$orgId/compliance/training/search",
        "/console/$orgId/compliance/training/user/$userId"
      ]
    },
    "/console/$orgId/configuration/assistance": {
      "filePath": "~console.$orgId/~configuration/~assistance/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/billing": {
      "filePath": "~console.$orgId/~configuration/~billing/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/billing/balance",
        "/console/$orgId/configuration/billing/billing-info",
        "/console/$orgId/configuration/billing/invoices",
        "/console/$orgId/configuration/billing/transactions",
        "/console/$orgId/configuration/billing/usage",
        "/console/$orgId/configuration/billing/payment/success"
      ]
    },
    "/console/$orgId/configuration/claim": {
      "filePath": "~console.$orgId/~configuration/~claim/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/comms-templates": {
      "filePath": "~console.$orgId/~configuration/~comms-templates/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/compliance": {
      "filePath": "~console.$orgId/~configuration/~compliance/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/documents": {
      "filePath": "~console.$orgId/~configuration/~documents/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/expenses-and-billing": {
      "filePath": "~console.$orgId/~configuration/~expenses-and-billing/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/handling-parties": {
      "filePath": "~console.$orgId/~configuration/~handling-parties/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/insurers": {
      "filePath": "~console.$orgId/~configuration/~insurers/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/payments": {
      "filePath": "~console.$orgId/~configuration/~payments/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/product-types": {
      "filePath": "~console.$orgId/~configuration/~product-types/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/product-types/",
        "/console/$orgId/configuration/product-types/$productTypeId"
      ]
    },
    "/console/$orgId/configuration/questionnaire": {
      "filePath": "~console.$orgId/~configuration/~questionnaire/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/risk": {
      "filePath": "~console.$orgId/~configuration/~risk/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/risk/",
        "/console/$orgId/configuration/risk/$section"
      ]
    },
    "/console/$orgId/configuration/statistics": {
      "filePath": "~console.$orgId/~configuration/~statistics/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/suppliers": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/suppliers/",
        "/console/$orgId/configuration/suppliers/add",
        "/console/$orgId/configuration/suppliers/details/$id"
      ]
    },
    "/console/$orgId/configuration/system": {
      "filePath": "~console.$orgId/~configuration/~system/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/task-manager": {
      "filePath": "~console.$orgId/~configuration/~task-manager/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/tasks": {
      "filePath": "~console.$orgId/~configuration/~tasks/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/users": {
      "filePath": "~console.$orgId/~configuration/~users/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/controls/register": {
      "filePath": "~console.$orgId/~controls/~register/~route.tsx",
      "parent": "/console/$orgId/controls",
      "children": [
        "/console/$orgId/controls/register/",
        "/console/$orgId/controls/register/add",
        "/console/$orgId/controls/register/details/$id"
      ]
    },
    "/console/$orgId/controls/tests": {
      "filePath": "~console.$orgId/~controls/~tests/~route.tsx",
      "parent": "/console/$orgId/controls"
    },
    "/console/$orgId/dashboard/$section": {
      "filePath": "~console.$orgId/~dashboard/~$section/~route.tsx",
      "parent": "/console/$orgId/dashboard"
    },
    "/console/$orgId/debug/test": {
      "filePath": "~console.$orgId/~debug/~test/~route.tsx",
      "parent": "/console/$orgId/debug"
    },
    "/console/$orgId/reporting/add": {
      "filePath": "~console.$orgId/~reporting/~add.tsx",
      "parent": "/console/$orgId/reporting"
    },
    "/console/$orgId/risk/ras": {
      "filePath": "~console.$orgId/~risk/~ras/~route.tsx",
      "parent": "/console/$orgId/risk",
      "children": [
        "/console/$orgId/risk/ras/",
        "/console/$orgId/risk/ras/details/$id"
      ]
    },
    "/console/$orgId/risk/register": {
      "filePath": "~console.$orgId/~risk/~register/~route.tsx",
      "parent": "/console/$orgId/risk",
      "children": [
        "/console/$orgId/risk/register/",
        "/console/$orgId/risk/register/details/$id/$section"
      ]
    },
    "/console/$orgId/tools/auto-detect": {
      "filePath": "~console.$orgId/~tools/~auto-detect/~route.tsx",
      "parent": "/console/$orgId/tools",
      "children": [
        "/console/$orgId/tools/auto-detect/dashboard",
        "/console/$orgId/tools/auto-detect/inbox"
      ]
    },
    "/console/$orgId/tools/website-checker": {
      "filePath": "~console.$orgId/~tools/~website-checker/~route.tsx",
      "parent": "/console/$orgId/tools"
    },
    "/console/$orgId/user-settings/notifications": {
      "filePath": "~console.$orgId/~user-settings/~notifications.tsx",
      "parent": "/console/$orgId/user-settings"
    },
    "/console/$orgId/user-settings/profile": {
      "filePath": "~console.$orgId/~user-settings/~profile.tsx",
      "parent": "/console/$orgId/user-settings"
    },
    "/console/$orgId/workload-manager/emails": {
      "filePath": "~console.$orgId/~workload-manager/~emails/~route.tsx",
      "parent": "/console/$orgId/workload-manager"
    },
    "/console/$orgId/workload-manager/tasks": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~route.tsx",
      "parent": "/console/$orgId/workload-manager",
      "children": [
        "/console/$orgId/workload-manager/tasks/calendar",
        "/console/$orgId/workload-manager/tasks/list"
      ]
    },
    "/public/$orgId/complaint/new": {
      "filePath": "~public/~$orgId/~complaint.new/~route.tsx",
      "parent": "/public/$orgId"
    },
    "/console/$orgId/compliance/coi/": {
      "filePath": "~console.$orgId/~compliance/~coi/~index.tsx",
      "parent": "/console/$orgId/compliance/coi"
    },
    "/console/$orgId/compliance/complaints/": {
      "filePath": "~console.$orgId/~compliance/~complaints/~index.tsx",
      "parent": "/console/$orgId/compliance/complaints"
    },
    "/console/$orgId/compliance/incidents/": {
      "filePath": "~console.$orgId/~compliance/~incidents/~index.tsx",
      "parent": "/console/$orgId/compliance/incidents"
    },
    "/console/$orgId/compliance/obligations/": {
      "filePath": "~console.$orgId/~compliance/~obligations/~index.tsx",
      "parent": "/console/$orgId/compliance/obligations"
    },
    "/console/$orgId/compliance/training/": {
      "filePath": "~console.$orgId/~compliance/~training/~index.tsx",
      "parent": "/console/$orgId/compliance/training"
    },
    "/console/$orgId/configuration/product-types/": {
      "filePath": "~console.$orgId/~configuration/~product-types/~index.tsx",
      "parent": "/console/$orgId/configuration/product-types"
    },
    "/console/$orgId/configuration/risk/": {
      "filePath": "~console.$orgId/~configuration/~risk/~index.tsx",
      "parent": "/console/$orgId/configuration/risk"
    },
    "/console/$orgId/configuration/suppliers/": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~index.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/console/$orgId/controls/register/": {
      "filePath": "~console.$orgId/~controls/~register/~index.tsx",
      "parent": "/console/$orgId/controls/register"
    },
    "/console/$orgId/risk/ras/": {
      "filePath": "~console.$orgId/~risk/~ras/~index.tsx",
      "parent": "/console/$orgId/risk/ras"
    },
    "/console/$orgId/risk/register/": {
      "filePath": "~console.$orgId/~risk/~register/~index.tsx",
      "parent": "/console/$orgId/risk/register"
    },
    "/admin-console/controls/library/$id/$section": {
      "filePath": "~admin-console/~controls/~library/~$id.$section/~route.tsx",
      "parent": "/admin-console/controls/library"
    },
    "/admin-console/obligations/library/$id/$section": {
      "filePath": "~admin-console/~obligations/~library/~$id.$section/~route.tsx",
      "parent": "/admin-console/obligations/library"
    },
    "/console/$orgId/claims/details/$id": {
      "filePath": "~console.$orgId/~claims/~details.$id/~route.tsx",
      "parent": "/console/$orgId/claims",
      "children": [
        "/console/$orgId/claims/details/$id/comms",
        "/console/$orgId/claims/details/$id/contacts",
        "/console/$orgId/claims/details/$id/expenses",
        "/console/$orgId/claims/details/$id/financials",
        "/console/$orgId/claims/details/$id/history",
        "/console/$orgId/claims/details/$id/library",
        "/console/$orgId/claims/details/$id/manage",
        "/console/$orgId/claims/details/$id/policy-info",
        "/console/$orgId/claims/details/$id/product-type-data"
      ]
    },
    "/console/$orgId/claims/edit/$id": {
      "filePath": "~console.$orgId/~claims/~edit.$id/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/claims/view/$id": {
      "filePath": "~console.$orgId/~claims/~view.$id/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/compliance/coi/add": {
      "filePath": "~console.$orgId/~compliance/~coi/~add/~route.tsx",
      "parent": "/console/$orgId/compliance/coi"
    },
    "/console/$orgId/compliance/complaints/add": {
      "filePath": "~console.$orgId/~compliance/~complaints/~add/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints"
    },
    "/console/$orgId/compliance/incidents/add": {
      "filePath": "~console.$orgId/~compliance/~incidents/~add.tsx",
      "parent": "/console/$orgId/compliance/incidents"
    },
    "/console/$orgId/compliance/obligations/add": {
      "filePath": "~console.$orgId/~compliance/~obligations/~add/~route.tsx",
      "parent": "/console/$orgId/compliance/obligations"
    },
    "/console/$orgId/compliance/training/search": {
      "filePath": "~console.$orgId/~compliance/~training/~search/~route.tsx",
      "parent": "/console/$orgId/compliance/training",
      "children": [
        "/console/$orgId/compliance/training/search/add"
      ]
    },
    "/console/$orgId/configuration/billing/balance": {
      "filePath": "~console.$orgId/~configuration/~billing/~balance.tsx",
      "parent": "/console/$orgId/configuration/billing"
    },
    "/console/$orgId/configuration/billing/billing-info": {
      "filePath": "~console.$orgId/~configuration/~billing/~billing-info.tsx",
      "parent": "/console/$orgId/configuration/billing"
    },
    "/console/$orgId/configuration/billing/invoices": {
      "filePath": "~console.$orgId/~configuration/~billing/~invoices.tsx",
      "parent": "/console/$orgId/configuration/billing"
    },
    "/console/$orgId/configuration/billing/transactions": {
      "filePath": "~console.$orgId/~configuration/~billing/~transactions.tsx",
      "parent": "/console/$orgId/configuration/billing"
    },
    "/console/$orgId/configuration/billing/usage": {
      "filePath": "~console.$orgId/~configuration/~billing/~usage.tsx",
      "parent": "/console/$orgId/configuration/billing"
    },
    "/console/$orgId/configuration/product-types/$productTypeId": {
      "filePath": "~console.$orgId/~configuration/~product-types/~$productTypeId/~route.tsx",
      "parent": "/console/$orgId/configuration/product-types",
      "children": [
        "/console/$orgId/configuration/product-types/$productTypeId/",
        "/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId"
      ]
    },
    "/console/$orgId/configuration/risk/$section": {
      "filePath": "~console.$orgId/~configuration/~risk/~$section/~route.tsx",
      "parent": "/console/$orgId/configuration/risk"
    },
    "/console/$orgId/configuration/suppliers/add": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~add.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/console/$orgId/controls/register/add": {
      "filePath": "~console.$orgId/~controls/~register/~add/~route.tsx",
      "parent": "/console/$orgId/controls/register"
    },
    "/console/$orgId/tools/auto-detect/dashboard": {
      "filePath": "~console.$orgId/~tools/~auto-detect/~dashboard.tsx",
      "parent": "/console/$orgId/tools/auto-detect"
    },
    "/console/$orgId/tools/auto-detect/inbox": {
      "filePath": "~console.$orgId/~tools/~auto-detect/~inbox/~route.tsx",
      "parent": "/console/$orgId/tools/auto-detect",
      "children": [
        "/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification"
      ]
    },
    "/console/$orgId/workload-manager/tasks/calendar": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~calendar/~route.tsx",
      "parent": "/console/$orgId/workload-manager/tasks",
      "children": [
        "/console/$orgId/workload-manager/tasks/calendar/add",
        "/console/$orgId/workload-manager/tasks/calendar/details/$id"
      ]
    },
    "/console/$orgId/workload-manager/tasks/list": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~list/~route.tsx",
      "parent": "/console/$orgId/workload-manager/tasks",
      "children": [
        "/console/$orgId/workload-manager/tasks/list/add",
        "/console/$orgId/workload-manager/tasks/list/details/$id"
      ]
    },
    "/public/$orgId/invoice/view/$accessKey": {
      "filePath": "~public/~$orgId/~invoice.view.$accessKey/~route.tsx",
      "parent": "/public/$orgId"
    },
    "/console/$orgId/configuration/product-types/$productTypeId/": {
      "filePath": "~console.$orgId/~configuration/~product-types/~$productTypeId/~index.tsx",
      "parent": "/console/$orgId/configuration/product-types/$productTypeId"
    },
    "/console/$orgId/claims/details/$id/comms": {
      "filePath": "~console.$orgId/~claims/~details.$id/~comms/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id",
      "children": [
        "/console/$orgId/claims/details/$id/comms/add",
        "/console/$orgId/claims/details/$id/comms/message/$messageId"
      ]
    },
    "/console/$orgId/claims/details/$id/contacts": {
      "filePath": "~console.$orgId/~claims/~details.$id/~contacts/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/expenses": {
      "filePath": "~console.$orgId/~claims/~details.$id/~expenses/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id",
      "children": [
        "/console/$orgId/claims/details/$id/expenses/add",
        "/console/$orgId/claims/details/$id/expenses/$expenseId/edit"
      ]
    },
    "/console/$orgId/claims/details/$id/financials": {
      "filePath": "~console.$orgId/~claims/~details.$id/~financials/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/history": {
      "filePath": "~console.$orgId/~claims/~details.$id/~history/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/library": {
      "filePath": "~console.$orgId/~claims/~details.$id/~library/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/manage": {
      "filePath": "~console.$orgId/~claims/~details.$id/~manage/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id",
      "children": [
        "/console/$orgId/claims/details/$id/manage/flag/add",
        "/console/$orgId/claims/details/$id/manage/strategies/add"
      ]
    },
    "/console/$orgId/claims/details/$id/policy-info": {
      "filePath": "~console.$orgId/~claims/~details.$id/~policy-info/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/product-type-data": {
      "filePath": "~console.$orgId/~claims/~details.$id/~product-type-data/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/compliance/complaints/details/$id": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints",
      "children": [
        "/console/$orgId/compliance/complaints/details/$id/history",
        "/console/$orgId/compliance/complaints/details/$id/library",
        "/console/$orgId/compliance/complaints/details/$id/manage"
      ]
    },
    "/console/$orgId/compliance/training/search/add": {
      "filePath": "~console.$orgId/~compliance/~training/~search/~add.tsx",
      "parent": "/console/$orgId/compliance/training/search"
    },
    "/console/$orgId/compliance/training/user/$userId": {
      "filePath": "~console.$orgId/~compliance/~training/~user.$userId/~route.tsx",
      "parent": "/console/$orgId/compliance/training",
      "children": [
        "/console/$orgId/compliance/training/user/$userId/add",
        "/console/$orgId/compliance/training/user/$userId/edit/$recordId"
      ]
    },
    "/console/$orgId/configuration/billing/payment/success": {
      "filePath": "~console.$orgId/~configuration/~billing/~payment.success.tsx",
      "parent": "/console/$orgId/configuration/billing"
    },
    "/console/$orgId/configuration/suppliers/details/$id": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~details.$id/~route.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/console/$orgId/controls/register/details/$id": {
      "filePath": "~console.$orgId/~controls/~register/~details.$id/~route.tsx",
      "parent": "/console/$orgId/controls/register"
    },
    "/console/$orgId/risk/ras/details/$id": {
      "filePath": "~console.$orgId/~risk/~ras/~details.$id/~route.tsx",
      "parent": "/console/$orgId/risk/ras"
    },
    "/console/$orgId/workload-manager/tasks/calendar/add": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~calendar/~add.tsx",
      "parent": "/console/$orgId/workload-manager/tasks/calendar"
    },
    "/console/$orgId/workload-manager/tasks/list/add": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~list/~add.tsx",
      "parent": "/console/$orgId/workload-manager/tasks/list"
    },
    "/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId": {
      "filePath": "~public/~claim-lodgement.$orgId.$pdsVersionId.$questionnaireId.$productTypeId/~route.tsx",
      "parent": "/public"
    },
    "/console/$orgId/claims/details/$id/comms/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~comms/~add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/comms"
    },
    "/console/$orgId/claims/details/$id/expenses/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~expenses/~add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/expenses"
    },
    "/console/$orgId/compliance/coi/details/$id/$section": {
      "filePath": "~console.$orgId/~compliance/~coi/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/compliance/coi"
    },
    "/console/$orgId/compliance/complaints/details/$id/history": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~history/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id"
    },
    "/console/$orgId/compliance/complaints/details/$id/library": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~library/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id"
    },
    "/console/$orgId/compliance/complaints/details/$id/manage": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~manage/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id",
      "children": [
        "/console/$orgId/compliance/complaints/details/$id/manage/flag/add",
        "/console/$orgId/compliance/complaints/details/$id/manage/note/add"
      ]
    },
    "/console/$orgId/compliance/incidents/details/$id/$section": {
      "filePath": "~console.$orgId/~compliance/~incidents/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/compliance/incidents"
    },
    "/console/$orgId/compliance/obligations/details/$id/$section": {
      "filePath": "~console.$orgId/~compliance/~obligations/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/compliance/obligations"
    },
    "/console/$orgId/compliance/training/user/$userId/add": {
      "filePath": "~console.$orgId/~compliance/~training/~user.$userId/~add.tsx",
      "parent": "/console/$orgId/compliance/training/user/$userId"
    },
    "/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId": {
      "filePath": "~console.$orgId/~configuration/~product-types/~$productTypeId/~pds.$pdsId/~route.tsx",
      "parent": "/console/$orgId/configuration/product-types/$productTypeId"
    },
    "/console/$orgId/risk/register/details/$id/$section": {
      "filePath": "~console.$orgId/~risk/~register/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/risk/register"
    },
    "/console/$orgId/tools/auto-detect/inbox/$id/review-email-classification": {
      "filePath": "~console.$orgId/~tools/~auto-detect/~inbox/~$id/~review-email-classification.tsx",
      "parent": "/console/$orgId/tools/auto-detect/inbox"
    },
    "/console/$orgId/workload-manager/tasks/calendar/details/$id": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~calendar/~details.$id.tsx",
      "parent": "/console/$orgId/workload-manager/tasks/calendar"
    },
    "/console/$orgId/workload-manager/tasks/list/details/$id": {
      "filePath": "~console.$orgId/~workload-manager/~tasks/~list/~details.$id.tsx",
      "parent": "/console/$orgId/workload-manager/tasks/list"
    },
    "/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId": {
      "filePath": "~public/~$orgId/~claim.lodgement.$pdsVersionId.$questionnaireId.$productTypeId/~route.tsx",
      "parent": "/public/$orgId"
    },
    "/console/$orgId/claims/details/$id/comms/message/$messageId": {
      "filePath": "~console.$orgId/~claims/~details.$id/~comms/~message.$messageId/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/comms"
    },
    "/console/$orgId/claims/details/$id/expenses/$expenseId/edit": {
      "filePath": "~console.$orgId/~claims/~details.$id/~expenses/~$expenseId.edit/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/expenses"
    },
    "/console/$orgId/claims/details/$id/manage/flag/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~manage/~flag.add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/manage"
    },
    "/console/$orgId/claims/details/$id/manage/strategies/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~manage/~strategies.add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/manage"
    },
    "/console/$orgId/compliance/training/user/$userId/edit/$recordId": {
      "filePath": "~console.$orgId/~compliance/~training/~user.$userId/~edit.$recordId.tsx",
      "parent": "/console/$orgId/compliance/training/user/$userId"
    },
    "/console/$orgId/compliance/complaints/details/$id/manage/flag/add": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~manage/~flag.add/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id/manage"
    },
    "/console/$orgId/compliance/complaints/details/$id/manage/note/add": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~manage/~note.add/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id/manage"
    }
  }
}
ROUTE_MANIFEST_END */
