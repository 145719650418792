import { selectUser, useAuthStore } from '@client/stores/AuthStore';
import invariant from 'tiny-invariant';

export function useUser() {
    const user = useAuthStore(selectUser);

    invariant(user, 'User is undefined.');

    return user;
}
