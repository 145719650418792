/*
We can't logout and go to the login page because Auth0 doesn't allow us to do that. This is workaround.
*/
import { useNavigate } from '@client/hooks/useNavigate';
import logo from '@client/resources/images/logo.svg';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/(authentication)/logoutin')({
    component: LogOutIn,
});

function LogOutIn() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate({ to: '/login' });
        }, 2500);
    }, []);

    return (
        <div className="relative flex min-h-screen w-screen flex-col justify-start bg-gray-100 md:justify-center">
            <div>
                <img
                    src={logo}
                    alt="Logo"
                    className="w-74 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                />
                <div className="absolute left-1/2 top-1/2 mt-20 -translate-x-1/2 -translate-y-1/2 transform text-lg text-gray-600">
                    Redirecting to login page...
                </div>
            </div>
        </div>
    );
}
