import { getStaleTime, StaleTime, TrpcClient } from '@client/trpc/client';
import { ComplaintVulnerability, FlagType, ObligationLegislation, TimelineItem } from '@client/types/common';
import { TaskType } from '@client/types/task';
import { BrandNode, ComplaintBrandConfig } from '@shared/types/complaintBrand';
import { ObligationLibraryLabel } from '@shared/types/obligation';
import { OrgSetting } from '@shared/types/orgSettings';
import { RiskLikelihood, RiskMatrixConfiguration } from '@shared/types/risk';
import { StaffTrainingConfig } from '@shared/types/staffTraining';
import { parseBoolean } from '@shared/utils/parse';

type Options = {
    staleTime: StaleTime;
};

export class ConfigService {
    constructor(private readonly trpcClient: TrpcClient) {}

    async fetchTaskTypes(options?: Options): Promise<TaskType[]> {
        const result = await this.fetchConfiguration('TASK_TYPES', options);
        return JSON.parse(result.value || '{[]}') as TaskType[];
    }

    async fetchClaimFlagTypes(options?: Options) {
        const result = await this.fetchConfiguration('CLAIM_FLAG_TYPES', options);
        return JSON.parse(result.value || '{[]}') as FlagType[];
    }

    async fetchComplaintFlagTypes(options?: Options) {
        const result = await this.fetchConfiguration('COMPLAINT_FLAG_TYPES', options);
        return JSON.parse(result.value || '{[]}') as FlagType[];
    }

    async fetchObligation_LegislationItems(options?: Options) {
        const result = await this.fetchConfiguration('OBLIGATION_LEGISLATION_ITEMS', options);
        return JSON.parse(result.value || '{[]}') as ObligationLegislation[];
    }

    async fetchClaimTimelineItems(options?: Options) {
        const result = await this.fetchConfiguration('CLAIM_TIMELINE_ITEMS', options);
        return JSON.parse(result.value || '{[]}') as TimelineItem[];
    }

    async fetchComplaintVulnerabilities(options?: Options) {
        const result = await this.fetchConfiguration('COMPLAINT_VULNERABILITIES', options);
        return JSON.parse(result.value || '{[]}') as ComplaintVulnerability[];
    }

    async fetchComplaintTimelineItems(options?: Options) {
        const result = await this.fetchConfiguration('COMPLAINT_TIMELINE_ITEMS', options);
        return JSON.parse(result.value || '{[]}') as TimelineItem[];
    }

    async fetchIncidentTimelineItems(options?: Options) {
        const result = await this.fetchConfiguration('INCIDENT_TIMELINE_ITEMS', options);
        return JSON.parse(result.value || '{[]}') as TimelineItem[];
    }

    async fetchClaimStatuses(options?: Options) {
        const result = await this.fetchConfiguration('CLAIM_STATUSES', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchClaimDecisions(options?: Options) {
        const result = await this.fetchConfiguration('CLAIM_DECISIONS', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchIncident_ProductsImpacted(options?: Options) {
        const result = await this.fetchConfiguration('INCIDENT_PRODUCTS_IMPACTED_ITEMS', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchSupplier_Categories(options?: Options) {
        const result = await this.fetchConfiguration('SUPPLIER_CATEGORIES', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchUploadDocTypes(options?: Options) {
        const result = await this.fetchConfiguration('CLAIM_UPLOAD_DOCUMENT_TYPES', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchClaimTriageOutcome(options?: Options) {
        const result = await this.fetchConfiguration('CLAIM_TRIAGE_OUTCOME', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchIncidentRisks(options?: Options) {
        const result = await this.fetchConfiguration('INCIDENT_RISKS', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchIncidentRootCauses(options?: Options) {
        const result = await this.fetchConfiguration('INCIDENT_ROOT_CAUSES', options);
        return JSON.parse(result.value || '{[]}') as string[];
    }

    async fetchStaffTrainingRecord(options?: Options): Promise<StaffTrainingConfig> {
        const result = await this.fetchConfiguration('STAFF_TRAINING_RECORD', options);
        return JSON.parse(result.value) as StaffTrainingConfig;
    }

    async fetchRiskMatrix(options?: Options): Promise<RiskMatrixConfiguration> {
        const result = await this.fetchConfiguration('RISK_MATRIX', options);
        return JSON.parse(result.value || '{}') as RiskMatrixConfiguration;
    }

    async fetchComplaintBrandConfiguration(options?: Options): Promise<ComplaintBrandConfig> {
        const defaultName = (await this.fetchConfiguration('COMPLAINT_BRAND_DEFAULT_NAME', options)).value;
        const isDisplayedOnExternalForm = parseBoolean(
            (await this.fetchConfiguration('COMPLAINT_BRAND_DISPLAY_ON_EXTERNAL_FORM', options)).value || 'false',
        );
        const isDisplayedOnInternalForm = parseBoolean(
            (await this.fetchConfiguration('COMPLAINT_BRAND_DISPLAY_ON_INTERNAL_FORM', options)).value || 'false',
        );
        const brandTree = JSON.parse(
            (await this.fetchConfiguration('COMPLAINT_BRAND_TREE_DEFINITION', options)).value || '[]',
        ) as BrandNode[];

        return {
            brandTree: brandTree,
            isDisplayedOnInternalForm: isDisplayedOnInternalForm,
            isDisplayedOnExternalForm: isDisplayedOnExternalForm,
            defaultBrandName: defaultName,
        };
    }

    async fetchCountryList(options?: Options) {
        const result = await this.fetchConfiguration('COUNTRY_LIST', options);
        return JSON.parse(result.value || '[]') as string[];
    }

    async fetchRiskLikelihoods(options?: Options) {
        const result = await this.fetchConfiguration('RISK_LIKELIHOODS', options);
        return JSON.parse(result.value || '[]') as RiskLikelihood[];
    }

    async fetchAllowedCountryCodes(options?: Options) {
        const result = await this.fetchConfiguration('ALLOWED_COUNTRY_CODES', options);
        return JSON.parse(result.value || '[]') as string[];
    }

    async fetchObligationLibraryLabels(options?: Options) {
        const result = await this.fetchConfiguration('OBLIGATION_LIBRARY_LABELS', options);
        return JSON.parse(result.value || '[]') as ObligationLibraryLabel[];
    }

    async fetchConfiguration(key: OrgSetting, options?: Options) {
        return this.trpcClient.configurationItem.getConfigurationItem.fetch(
            { name: key },
            { staleTime: getStaleTime(options?.staleTime || 'infinite') },
        );
    }
}
