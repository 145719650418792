import { IdcardOutlined, MailOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { unallocatedPhotoUrl } from '@client/global/constants';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { skipToken } from '@tanstack/react-query';
import { useInViewport } from 'ahooks';
import { Avatar, Card, Divider, Popover, Space, Tag, theme, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { PuffLoader } from 'react-spinners';

type Props = {
    photoUrl?: string | null;
    name?: string | null;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    userId?: bigint | null;
    orgId?: bigint;
    showPopover?: boolean;
    unallocatedLabel?: string;
    showAvatar?: boolean;
    inline?: boolean;
    inViewPort?: boolean;
};

const SIZES = {
    small: 18,
    medium: 22,
    large: 28,
} as const;

export function User(props: Omit<Props, 'inViewPort'>) {
    const ref = useRef<HTMLDivElement>(null);
    const [inViewPort] = useInViewport(ref);

    return (
        <div ref={ref}>
            <UserInner {...props} inViewPort={inViewPort ?? false} />
        </div>
    );
}

const UserInner = ({
    name: nameProp,
    photoUrl: photoUrlProp,
    orgId: orgIdProp,
    userId,
    size = 'medium',
    className,
    showPopover = true,
    showAvatar = true,
    unallocatedLabel = 'Unallocated',
    inline = false,
    inViewPort = false,
}: Props) => {
    const currentOrgId = useOrgId();
    const isNeedToLoad = !nameProp;
    const orgId = BigInt(orgIdProp || currentOrgId);
    const [user, setUser] = useState<RouterOutputs['user']['getUser'] | undefined>(undefined);

    const { data, isPending } = trpc.user.getUser.useQuery(
        (isNeedToLoad || showPopover) && userId
            ? {
                  id: BigInt(userId),
                  orgId,
                  ...(showPopover && { select: ['HandlingParties'] }),
              }
            : skipToken,
        {
            enabled: inViewPort && user === undefined,
        },
    );

    useEffect(() => {
        if (data && !user) {
            setUser(data);
        }
    }, [data]);

    const { token } = theme.useToken();

    const name = nameProp || user?.fullName || user?.email;
    const photoUrl = photoUrlProp || user?.photoUrl;

    if (isPending && isNeedToLoad && userId) {
        return (
            <div className={clsx('flex items-center', className)}>
                <PuffLoader color={token.colorPrimary} size={20} />
            </div>
        );
    }

    if (!userId && !name) {
        return (
            <div className={clsx('flex items-center gap-2', className)}>
                {showAvatar && (
                    <Avatar size={SIZES[size]} style={{ minWidth: SIZES[size] }} src={unallocatedPhotoUrl} />
                )}
                {unallocatedLabel}
            </div>
        );
    }

    if (showPopover && userId && user) {
        return (
            <Popover
                className={clsx('items-center gap-2', inline ? 'inline-flex' : 'flex')}
                trigger={['hover', 'focus']}
                placement="right"
                zIndex={2000}
                content={<Content user={user} />}
                mouseEnterDelay={0.7}
            >
                {showAvatar && <Avatar size={SIZES[size]} style={{ minWidth: SIZES[size] }} src={photoUrl} />}
                {name}
            </Popover>
        );
    }

    return (
        <div className={clsx('flex items-center gap-2', inline ? 'inline-flex' : 'flex', className)}>
            {showAvatar && <Avatar size={SIZES[size]} style={{ minWidth: SIZES[size] }} src={photoUrl} />}
            {name}
        </div>
    );
};

type ContentProps = {
    user: RouterOutputs['user']['getUser'];
};

const Content = ({ user }: ContentProps) => {
    const { Text, Title } = Typography;

    return (
        <Card className="w-80 max-w-sm shadow-none" variant="outlined">
            <div className="mb-3 flex items-center gap-3">
                <Avatar size={48} src={user.photoUrl} className="flex-shrink-0 border border-gray-200">
                    {!user.photoUrl && user.fullName?.charAt(0)}
                </Avatar>
                <div className="overflow-hidden">
                    <Title level={5} className="m-0 overflow-hidden text-ellipsis">
                        {user.fullName || 'User'}
                    </Title>
                    <Tooltip title={user.email}>
                        <Text type="secondary" className="block overflow-hidden text-sm text-ellipsis">
                            {user.email}
                        </Text>
                    </Tooltip>
                </div>
            </div>

            <Divider className="my-2" />

            <Space direction="vertical" className="w-full" size="small">
                <div className="flex items-center gap-2">
                    <MailOutlined className="text-blue-500" />
                    <Text strong className="mr-1 whitespace-nowrap text-gray-700">
                        Email:
                    </Text>
                    <a href={`mailto:${user.email}`} className="truncate text-primary hover:underline">
                        {user.email}
                    </a>
                </div>

                <div className="flex items-center gap-2">
                    <UserOutlined className="text-orange-500" />
                    <Text strong className="mr-1 whitespace-nowrap text-gray-700">
                        Name:
                    </Text>
                    <Text className="truncate">{user.fullName || '-'}</Text>
                </div>

                <div className="flex items-center gap-2">
                    <IdcardOutlined className="text-purple-500" />
                    <Text strong className="mr-1 text-gray-700">
                        ID:
                    </Text>
                    <Text className="rounded bg-gray-100 px-2 py-0.5 font-mono text-xs">{String(user.userId)}</Text>
                </div>

                <div className="flex gap-2">
                    <TeamOutlined className="mt-1 text-teal-500" />
                    <div>
                        <Text strong className="mr-1 text-gray-700">
                            Handling parties:
                        </Text>
                        {user?.HandlingParties?.length ? (
                            <div className="mt-1 flex flex-wrap gap-1">
                                {user.HandlingParties.map(({ id, name }) => (
                                    <Tag key={id} color="blue" className="mr-1 mb-1">
                                        {name}
                                    </Tag>
                                ))}
                            </div>
                        ) : (
                            <Text type="secondary">None</Text>
                        )}
                    </div>
                </div>
            </Space>
        </Card>
    );
};
