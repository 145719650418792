import { useEffect } from 'react';
import { createStore, useStore } from 'zustand';
import { get as getObjectValue } from 'object-path';
import { DefaultFormData, FormStore, FormStoreInner } from './types';
import { areDependenciesEqual } from '@client/utils/general';
import { createSelectorsForVanillaStore } from '@client/utils/zustand';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import invariant from 'tiny-invariant';
import { capitalize } from '@client/utils/string';

// TODO: If not initialized, throw an error

// TODO: create ...use.formDataOrThrow method

function createBaseFormStore<TFormData extends DefaultFormData>() {
    return createStore<FormStoreInner<TFormData>>()((set, get) => ({
        field: undefined,
        formData: undefined,
        isReadonly: false,
        isLoading: false,
        isLoaded: false,
        isInitialized: false,
        loadDependencies: [],

        async onSave() {
            throw new Error('onSave method not implemented.');
        },

        async onLoad() {
            throw new Error('onLoad method not implemented.');
        },

        onError: undefined,

        init(options) {
            const { isInitialized } = get();
            set(() => ({
                ...(!isInitialized && { isInitialized: true }),
                ...options,
            }));
        },

        getFieldValue(field) {
            const { formData } = get();
            return formData ? getObjectValue(formData, field) : undefined;
        },

        setFieldForEditing(value) {
            set(() => ({ field: value }));
        },

        isFieldEditing(field) {
            return get().field === field;
        },

        stopEditing() {
            set(() => ({ field: undefined }));
        },

        cancelEditing() {
            set(() => ({ field: undefined }));
        },

        setReadonly(value) {
            set(() => ({ isReadonly: value }));
        },

        /*
         * Load the form data from the server. The method uses the fetchDataFn passed to the constructor.
         * The method sets the form data if the fetch is successful. The method doesn't throw errors and all
         * errors are channeled through onError function passed to the constructor.
         * * @returns true if the load was successful, false otherwise
         */
        async load() {
            const { onLoad, onError, setReadonly } = get();

            try {
                set(() => ({ isLoading: true, isLoaded: false }));

                const result = await onLoad({ setReadonly });

                if (!result.success) {
                    await onError?.(result.error, 'load');
                    return false;
                }

                set(() => ({ formData: result.data, isLoaded: true }));

                return true;
            } catch (e) {
                await onError?.(e, 'unknown');
                return false;
            } finally {
                set(() => ({ isLoading: false }));
            }
        },

        /*
         * Save the form data to the server. The method uses the saveFn passed to the constructor. The method doesn't throw
         * errors and all errors are channeled through onError function.
         * @returns true if the save was successful, false otherwise.
         * @param value The new form data.
         * @param options Options for the save operation.
         * suppressMessages - this param will be passed to the onSave function in case you want to override the info messages.
         */
        async save(
            value,
            options = {
                suppressMessages: false,
                reload: true,
            },
        ) {
            const { formData, onSave, onError, stopEditing, load } = get();

            try {
                if (!formData) {
                    throw new Error('Form data is not set.');
                }

                const result = await onSave(formData, value, options);

                if (!result.success) {
                    await onError?.(result.error, 'save');
                    return false;
                }

                stopEditing();

                if (options?.reload !== false) {
                    await load();
                }

                return true;
            } catch (e) {
                await onError?.(e, 'unknown');
                return false;
            }
        },
    }));
}

export function createFormStore<TFormData extends DefaultFormData>() {
    const baseFormStore = createBaseFormStore<TFormData>();
    const extendedStore = baseFormStore as FormStore<TFormData>;

    createSelectorsForVanillaStore(extendedStore);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extendedStore.use.formData = ({ throwOnUndefined } = { throwOnUndefined: true }) => {
        const formData = useStore(baseFormStore, ({ formData }) => formData);
        const entity = useStore(baseFormStore, ({ entity }) => entity);

        if (!formData && throwOnUndefined) {
            invariant(`${entity ? capitalize(entity) : 'Entity'} is required.`);
        }

        return formData!;
    };

    extendedStore.useInit = (options) => {
        const orgId = useOrgId();
        const loadDependencies = extendedStore.use.loadDependencies?.();
        const formData = extendedStore.use.formData();
        const init = extendedStore.use.init();
        const load = extendedStore.use.load();
        const save = extendedStore.use.save();
        // We need to add orgId, otherwise the form will not be reloaded when the org is changed
        const newDependencies = [...(options.loadDependencies || []), orgId];

        useEffect(() => {
            if (areDependenciesEqual(loadDependencies, newDependencies)) {
                return;
            }

            // We need to clear the form data, other data will be displayed before the new data is loaded
            baseFormStore.setState({
                formData: undefined,
            });
            init(options);
            void load();
        }, newDependencies);

        return {
            formData,
            save,
            load,
        };
    };

    return extendedStore;
}
