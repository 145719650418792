import { grey } from '@ant-design/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ComponentPreview } from '@client/components/Common/ComponentPreview';
import { Indicator } from '@client/components/Common/Indicator';
import { Link } from '@client/components/Common/Link';
import { NewTabLink } from '@client/components/Common/NewTabLink';
import { User } from '@client/components/Common/User';
import { UserSelector } from '@client/components/Common/UserSelector';
import { Filters } from '@client/components/Filters';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { useSafePath } from '@client/hooks/useSafePath';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { getHighestPriorityStatus } from '@client/utils/control';
import { commonActionColumn } from '@client/utils/table';
import { CONTROL_FREQUENCY, CONTROL_STATUSES, ControlFrequency, ControlStatus } from '@shared/definitions/control';
import { Falsy, isTruthy } from '@shared/utils/boolean';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Form, Select, Space, Table, Tooltip } from 'antd';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/controls/register/')({
    staticData: {
        ui: {
            title: 'Control Register',
            subtitle: 'Manage and track your risk controls',
        },
    },
    component: ControlsRegisterSearch,
});

type Control = RouterOutputs['control']['listControls']['rows'][number];

type Filter = {
    ownerId: bigint;
    frequency: string;
};

function ControlsRegisterSearch() {
    const orgId = useOrgId();
    const navigate = useNavigate();

    const [variables, setVariables] = useState<RouterInputs['control']['listControls']>({
        orderBy: { id: 'desc' },
        limit: 10,
        offset: 0,
    });

    const { data, isPending } = trpc.control.listControls.useQuery(variables);

    const [form] = Form.useForm<Filter>();
    const safePath = useSafePath<Filter>();

    const { tableProps } = useAntdTable<Control>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                width: 40,
                render: (_, { status, ControlTests }) => {
                    const { color, title } = getHighestPriorityStatus(status, ControlTests);

                    return (
                        <Indicator
                            tooltip={{
                                title,
                            }}
                            style={{
                                background: color,
                            }}
                        />
                    );
                },
                fixed: 'left',
            },
            {
                title: 'Control No.',
                dataIndex: 'id',
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
            },
            {
                title: 'Objective',
                dataIndex: 'objective',
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (value) => CONTROL_STATUSES[value as ControlStatus] || value,
            },
            {
                title: 'Control Frequency',
                dataIndex: 'frequency',
                render: (value) => CONTROL_FREQUENCY[value as ControlFrequency] || value,
            },
            {
                title: 'Owner',
                dataIndex: 'ownerId',
                render: (value) => <User userId={value} />,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <NewTabLink
                            to="/console/$orgId/controls/register/details/$id"
                            params={{
                                orgId,
                                id: value,
                            }}
                        />
                        <Tooltip title="Delete">
                            <Button
                                type="text"
                                disabled
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Tooltip>
                    </Space>
                ),
            },
        ],
    });

    const onSearch = () => {
        const item = form.getFieldsValue();

        const whereClauses: (Falsy | typeof variables.where)[] = [
            item.ownerId && { ownerId: BigInt(item.ownerId) },
            item.frequency && { frequency: item.frequency },
        ];

        setVariables((prev) => ({
            ...prev,
            where: {
                AND: whereClauses.filter(isTruthy),
            },
        }));
    };

    return (
        <>
            <PageLayout.Header
                features={[
                    {
                        feature: 'filters',
                        form,
                        onSearch,
                        children: (
                            <>
                                <Filters.Item>
                                    <Form.Item label="Control Owner" name={safePath('ownerId')}>
                                        <UserSelector />
                                    </Form.Item>
                                </Filters.Item>
                                <Filters.Item>
                                    <Form.Item label="Frequency" name={safePath('frequency')}>
                                        <Select
                                            options={Object.keys(CONTROL_FREQUENCY).map((key) => ({
                                                label: CONTROL_FREQUENCY[key as ControlFrequency],
                                                value: key,
                                            }))}
                                        />
                                    </Form.Item>
                                </Filters.Item>
                            </>
                        ),
                    },
                ]}
                primaryActionAddon={
                    <Link
                        to="/console/$orgId/controls/register/add"
                        params={{ orgId }}
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Add control
                    </Link>
                }
            />

            <Card>
                <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/controls/register/details/$id',
                                    params: {
                                        orgId,
                                        id,
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
