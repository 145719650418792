import { useAuth0 } from '@auth0/auth0-react';
import { ComponentLoading } from '@client/components/Common/ComponentLoading';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useUserEvent } from '@client/hooks/Logging/useUserEvent';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { useAuthStore } from '@client/stores/AuthStore';
import { logInDev } from '@client/utils/general';
import * as Sentry from '@sentry/react';
import { SYSTEM_ORG_IDS } from '@shared/definitions/org';
import { decodeJwtToken } from '@shared/utils/jwt';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, matchByPath } from '@tanstack/react-router';
import { theme } from 'antd';
import { useEffect, useRef } from 'react';
import { PuffLoader } from 'react-spinners';
import invariant from 'tiny-invariant';

export const Route = createFileRoute('/(authentication)/callback')({ component: AuthCallback });

export function AuthCallback() {
    const queryClient = useQueryClient();
    const trpcClient = useTrpcClient();
    const authStore = useAuthStore();
    const authInProgressRef = useRef(false);
    const { insertEvent } = useUserEvent();
    const { handleRedirectCallback } = useAuth0();
    const navigate = useNavigate();
    const { token } = theme.useToken();

    useEffect(() => {
        const asyncWrapper = async () => {
            Sentry.getCurrentScope().setTransactionName('Login Callback');
            queryClient.clear();

            logInDev('AuthCallback useEffect');

            if (authInProgressRef.current) {
                return;
            }

            try {
                authInProgressRef.current = true;

                logInDev('logging in from /callback');

                const searchParams = new URLSearchParams(window.location.search);
                const error = searchParams.get('error');

                if (error) {
                    if (error === 'login_required') {
                        await errorMessage.showAsync(new Error('Login required'));
                    } else if (searchParams.get('error_description') === 'payment_required_for_login') {
                        navigate({ to: '/payment-pending' });
                        return;
                    } else if (error === 'access_denied') {
                        await errorMessage.showAsync(searchParams.get('error_description'), { title: 'Access Denied' });
                    }
                    console.error('AuthCallback: error', error);
                    authStore.logout();
                    return;
                }

                const appStateWrapper = (await handleRedirectCallback()) as {
                    appState?: { redirectTo?: string; requestOrgId?: string };
                };

                const redirectTo = decodeURIComponent(appStateWrapper?.appState?.redirectTo || '');
                const redirectToOrgMatch = matchByPath('', redirectTo, { to: '/console/$orgId', fuzzy: true });
                const orgId =
                    appStateWrapper?.appState?.requestOrgId ||
                    redirectToOrgMatch?.orgId ||
                    (redirectTo?.includes('/admin-console') && '-1');

                let accessToken = await authStore.getAccessToken(orgId ? BigInt(orgId) : undefined, true);

                const tokenDecoded = decodeJwtToken(accessToken || '');
                // This is a workaround in development to ensure the user metadata is updated in Auth0
                // Auth0 post-login can't load user data from local dev API, so we have to push
                // user metadata to Auth0 after the first SSO login
                if (import.meta.env.VITE_ENV === 'development') {
                    const isFirstSsoLogin = tokenDecoded?.['https://curium.app/claims'].isFirstSsoLogin;

                    if (isFirstSsoLogin) {
                        try {
                            await trpcClient.user.initializeSsoUser.mutate({ accessToken });
                            // Get new access token, it should have the updated user metadata
                            accessToken = await authStore.getAccessToken(orgId ? BigInt(orgId) : undefined, true);
                        } catch (e) {
                            await errorMessage.showAsync(e);
                            navigate({ to: '/' });
                            return;
                        }
                    }
                }

                await authStore.login(accessToken);

                await insertEvent('login', 'User logged in successfully');

                const org = useAuthStore.getState().org;
                invariant(org, 'org is required');

                if (redirectTo && !redirectTo.includes('/login')) {
                    navigate({ href: redirectTo });
                } else {
                    if (org.id === SYSTEM_ORG_IDS.SYSTEM_CONSOLE) {
                        navigate({ to: '/admin-console' });
                    } else {
                        navigate({ to: '/console/$orgId', params: { orgId: org.id } });
                    }
                }
            } catch (e) {
                if (e instanceof Error && e.message.toLowerCase().includes('invalid state')) {
                    // This is a known issue with Auth0 where the state is invalid.
                    // See: https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10

                    await authStore.logout();
                    return;
                } else {
                    await errorMessage.showAsync(e);
                }
                message.loading('Logging out...');
                await authStore.logout();
                navigate({ to: '/login' });
            } finally {
                authInProgressRef.current = false;
                message.destroy();
            }
        };

        void asyncWrapper();
    }, []);

    return (
        <ComponentLoading isFullPage>
            <div className="hover:shadow-3xl mx-auto max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl transition-all duration-500 ease-in-out">
                <div className="mb-6">
                    <img src="/static/curium-logo.svg" alt="Company Logo" className="mx-auto h-16 w-auto" />
                </div>
                <h2 className="text-primary-600 mb-4 text-3xl font-bold">Welcome!</h2>
                <p className="mb-8 text-lg text-gray-700">
                    We're securely authenticating your account. This will only take a moment.
                </p>
                <div className="flex flex-col items-center justify-center">
                    <PuffLoader color={token.colorPrimary} size={40} />
                    <p className="text-md mt-6 text-gray-600">Preparing your personalized experience...</p>
                </div>
            </div>
        </ComponentLoading>
    );
}
