import { useInitMessage } from '@client/components/Common/message';
import { useInitModal } from '@client/components/Common/modal';
import { useInitNotification } from '@client/components/Common/Notification';
import '@client/styles/main.css';
import '@client/styles/main.sass';
import { theme } from '@client/styles/theme';
import { App, ConfigProvider, Spin, theme as themeOriginal } from 'antd';
import clsx from 'clsx';
import { PropsWithChildren, useEffect } from 'react';
import { PuffLoader } from 'react-spinners';

function UISetup({ children }: PropsWithChildren) {
    const { token } = themeOriginal.useToken();

    useInitMessage();
    useInitNotification();
    useInitModal();

    useEffect(() => {
        Spin.setDefaultIndicator(<PuffLoader color={token.colorPrimary} size={30} />);
    }, []);

    return children;
}

export function UIProvider({ children }: PropsWithChildren) {
    return (
        <ConfigProvider
            theme={theme}
            form={{
                requiredMark(label, info) {
                    return info.required ? (
                        <span
                            className={clsx(
                                "pl-2.5 before:mr-1 before:text-xs before:text-error before:content-['*']",
                                'before:absolute before:-translate-x-2.5 before:translate-y-0.5',
                            )}
                        >
                            {label}
                        </span>
                    ) : (
                        label
                    );
                },
            }}
        >
            <App className="flex min-h-screen" message={{ maxCount: 1 }}>
                <UISetup>{children}</UISetup>
            </App>
        </ConfigProvider>
    );
}
