import { createFileRoute } from '@tanstack/react-router';
import { ClaimForm } from '../ClaimLodgement';

export const Route = createFileRoute('/console/$orgId/claims/lodge')({
    staticData: {
        breadcrumb: {
            title: 'Lodge claim',
        },
    },
    component() {
        return <ClaimForm formAction="new" />;
    },
});
