import { CloudSyncOutlined, ReloadOutlined } from '@ant-design/icons';
import { message } from '@client/components/Common/message';
import { notification } from '@client/components/Common/Notification';
import { useAppStateStore } from '@client/stores/AppStateStore';
import { useMatchRoute } from '@tanstack/react-router';
import { Button, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useTrpc } from './useTrpc';

const { Text } = Typography;

export function useAppBuildVersion() {
    const { trpc, trpcClient, trpcUtils } = useTrpc();
    const appState = useAppStateStore.getState();
    const { data: appBuildVersion, refetch } = trpc.appSetting.getAppBuildVersion.useQuery(undefined, {
        refetchInterval: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true,
    });
    const matchRoute = useMatchRoute();
    const match = matchRoute({ to: '/maintenance', fuzzy: true });
    const key = 'app-build-version';

    const [isOpen, setIsOpen] = useState(false);

    useIdleTimer({
        timeout: 1000 * 60 * 5, // 5 minutes
        onPresenceChange: (presence) => {
            if (presence.type === 'active') {
                void refetch();
            }
        },
    });

    // Handle app update
    const handleAppUpdate = async () => {
        notification.destroy(key);
        message.loading('Updating application...', 10);

        // Invalidate the query to get the latest app build version in case it has changed
        await trpcUtils.appSetting.getAppBuildVersion.invalidate();
        const appBuildVersion = await trpcClient.appSetting.getAppBuildVersion.query();
        if (appBuildVersion) {
            const { version: versionFromServer, updatedAt } = appBuildVersion;
            appState.actions.setAppBuildVersion(versionFromServer);
            appState.actions.setAppBuildLastUpdatedAt(updatedAt);
        }
        window.location.href = `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}t=${Date.now()}`;
    };

    useEffect(() => {
        // If we are on the maintenance page, don't show the modal
        if (match || !appBuildVersion) {
            return;
        }
        const { version: versionFromServer, updatedAt } = appBuildVersion;

        // Version has changed and that means we need to reload the app
        if (versionFromServer && appState.appBuildVersion && versionFromServer !== appState.appBuildVersion) {
            if (!isOpen) {
                setIsOpen(true);

                notification.info({
                    key,
                    message: (
                        <Space align="center">
                            <Text strong className="text-lg">
                                New Version Available
                            </Text>
                        </Space>
                    ),
                    description: (
                        <div className="py-1">
                            <Text>
                                A new version of the application is available with the latest features and improvements.
                            </Text>
                        </div>
                    ),
                    duration: 0,
                    placement: 'bottomRight',
                    className: 'app-version-notification',
                    icon: <CloudSyncOutlined style={{ fontSize: '18px', color: '#1890ff' }} />,
                    actions: (
                        <Space className="mt-2">
                            <Button
                                type="text"
                                onClick={() => notification.destroy(key)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                Later
                            </Button>
                            <Button type="primary" icon={<ReloadOutlined />} onClick={handleAppUpdate}>
                                Reload now
                            </Button>
                        </Space>
                    ),
                });
            }
        } else if (appBuildVersion) {
            appState.actions.setAppBuildVersion(versionFromServer);
            appState.actions.setAppBuildLastUpdatedAt(updatedAt);
        }
    }, [appBuildVersion, match]);
}
