import React from 'react';
import './index.sass';
import { Space } from 'antd';

interface Props {
    renderLeft?: () => React.ReactNode;
    renderRight?: () => React.ReactNode;
}

export const ActionBar = ({ renderLeft, renderRight, ...rest }: Props) => {
    return (
        <div className="flex justify-between pt-4" {...rest}>
            <Space>{renderLeft ? renderLeft() : '\u00A0'}</Space>
            <Space>{renderRight && renderRight()}</Space>
        </div>
    );
};
