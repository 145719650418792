import { Drawer, DrawerProps, FormInstance } from 'antd';
import { createContext, FC, ReactNode, useContext as useContextOriginal, useEffect, useState } from 'react';

const SIZES = {
    small: 500,
    medium: 700,
    large: 900,
} as const;

const Context = createContext<{ onClose: () => void }>(undefined!);

export function useContext() {
    const context = useContextOriginal(Context);

    if (!context) {
        throw new Error('useOverlayPageContext must be used within a OverlayPage component.');
    }

    return context;
}

function OverlayPage({
    initForm,
    form,
    afterClose,
    footer,
    size = 'small',
    children,
    ...props
}: Pick<DrawerProps, 'title' | 'children'> & {
    form?: FormInstance;
    initForm?: () => void;
    afterClose: () => void;
    footer?: FC<{ onClose: () => void }>;
    size?: keyof typeof SIZES;
}) {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (open && (form || initForm)) {
            form?.resetFields();
            initForm?.();
        }
    }, [open]);

    function onClose() {
        setOpen(false);
    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width={SIZES[size]}
            afterOpenChange={(open) => {
                if (!open) {
                    afterClose();
                }
            }}
            footer={footer?.({ onClose }) as ReactNode}
            {...props}
        >
            <Context value={{ onClose }}>{children}</Context>
        </Drawer>
    );
}

OverlayPage.useContext = useContext;

export { OverlayPage };
