import { createContext, useContext } from 'react';

type QuestionnaireCtx = {
    isPublic: boolean;
};

export const QuestionnaireContext = createContext<QuestionnaireCtx>({ isPublic: false });

export function useQuestionnaireContext() {
    return useContext(QuestionnaireContext);
}
