import { useDebugStore } from '@client/stores/DebugStore';

/**
 * Check if a JWT token has expired
 * @param exp - The expiration time of the JWT token
 * @returns true if the token has expired, false otherwise
 */
export function isJwtExpired(exp: number) {
    const forceExpired = useDebugStore.getState().isForceJwtExpiredEnabled;
    if (forceExpired) {
        return true;
    }
    return exp < Date.now() / 1000;
}
