import { selectAllSettings, useOrgSettingsStore } from '@client/stores/OrgSettingStore';
import { logInDev } from '@client/utils/general';
import { Input, InputProps, InputRef, Select, Tooltip } from 'antd';
import {
    CountryCode,
    formatIncompletePhoneNumber,
    getCountries,
    getCountryCallingCode,
    parsePhoneNumberFromString,
} from 'libphonenumber-js/max';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

type PhoneNumberInputProps = Omit<InputProps, 'value' | 'onChange' | 'addonBefore'> & {
    value?: string;
    onChange?: (value: string | undefined) => void;
};

export const PhoneNumberInput = ({ value, onChange, ...rest }: PhoneNumberInputProps) => {
    const orgSettings = useOrgSettingsStore(selectAllSettings);
    const ref = useRef<InputRef>(null);
    //const [isFocused, setIsFocused] = useState(false);
    const validCountryCodes = useMemo(() => getCountries(), []);
    const defaultCountry = validCountryCodes.includes(orgSettings.defaultCountryCode as CountryCode)
        ? (orgSettings.defaultCountryCode as CountryCode)
        : 'AU';
    const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountry);
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (!value) {
            return;
        }

        const parsedPhone = parsePhoneNumberFromString(value);

        if (!parsedPhone) {
            return;
        }

        const countryCode = parsedPhone.country;

        if (!countryCode || !validCountryCodes.includes(countryCode)) {
            return;
        }

        setCountryCode(countryCode);
        const callingCode = getCountryCallingCode(countryCode);
        const formattedNumber = formatIncompletePhoneNumber(value, parsedPhone.country);
        setPhoneNumber(formattedNumber.slice(callingCode.length + 1).trim());
    }, [value]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
        if (ref?.current?.input !== document.activeElement) {
            // In case the input is not focused, update the value and format it. This is useful when autofill is used.
            handleInputBlur(e.target.value);
        } else {
            setPhoneNumber(e.target.value);
        }
    };

    const handleInputBlur = (value: string) => {
        setPhoneNumber(value);
        const newPhoneNumber = value.replace(/\D/g, '');
        try {
            const callingCode = getCountryCallingCode(countryCode);
            const formattedNumber = formatIncompletePhoneNumber(`${callingCode}${newPhoneNumber}`, countryCode);
            setPhoneNumber(formattedNumber.slice(callingCode.length).trim());
            const formattedValue = newPhoneNumber ? `+${callingCode}${newPhoneNumber}` : undefined;
            onChange?.(formattedValue);
        } catch (error) {
            logInDev('Invalid phone number:', error);
            setPhoneNumber(value);
            onChange?.(undefined);
        }
    };

    const handleCountryChange = (value: string) => {
        const newCountryCode = value as CountryCode;
        setCountryCode(newCountryCode);
        const callingCode = getCountryCallingCode(newCountryCode);
        const formattedValue = phoneNumber ? `+${callingCode}${phoneNumber.replace(/\D/g, '')}` : undefined;
        onChange?.(formattedValue);
    };

    return (
        <Input
            {...rest}
            ref={ref}
            value={phoneNumber}
            onBlur={(e) => handleInputBlur(e.target.value)}
            onChange={handleInputChange}
            addonBefore={
                <Select
                    style={{ width: 85 }}
                    value={countryCode}
                    onChange={handleCountryChange}
                    options={(orgSettings.allowAllCountryCodes
                        ? validCountryCodes
                        : (orgSettings.allowedCountryCodes as CountryCode[])
                    ).map((code) => {
                        const callingCode = getCountryCallingCode(code);
                        return {
                            value: code,
                            label: <Tooltip title={code}>{`+${callingCode}`}</Tooltip>,
                        };
                    })}
                />
            }
            placeholder="Enter phone number"
        />
    );
};
