import { Tabs } from '@client/components/Navigation/Tabs';
import { ShareableRouteContext } from '@client/types/router';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/advanced/indicators')({
    component: Indicators,
    staticData: { breadcrumb: { title: 'Indicators' } },
    beforeLoad(): ShareableRouteContext {
        return { ui: { title: 'Indicators', subtitle: 'Tools for tracking and managing indicators.' } };
    },
});

function Indicators() {
    return (
        <Tabs
            items={[
                { label: 'Complaints', to: '/admin-console/advanced/indicators/complaints' },
                { label: 'Incidents', to: '/admin-console/advanced/indicators/incidents' },
            ]}
        />
    );
}
