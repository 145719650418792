import { InputWrapper, InputWrapperRef, VInputProps } from '@client/components/Common/ViewForm/InputWrapper';
import { useUser } from '@client/hooks/User/useUser';
import { DefaultFormData, Field } from '@client/stores/FormStore/types';
import { trpc } from '@client/trpc/client';
import { SYSTEM_ACCOUNT_IDS } from '@shared/definitions/user';
import { Form, Input, Select, Typography } from 'antd';
import { ReactNode, useId, useRef, useState } from 'react';
import { User } from '../../User';

type UserOption = {
    label: ReactNode;
    value: number;
    searchValue: string;
    name: string;
};

type Props<TFormData extends DefaultFormData> = VInputProps<TFormData> & {
    handlingPartyIds?: number[] | bigint[];
    /**
     * If true, only shows users that share handling parties with the current user.
     * handlingPartyIds will work as additional filter in this case
     */
    limitToCurrentUserHandlingParties?: boolean;
    removeSystemAccount?: boolean;
    removeAnonymousAccount?: boolean;
    onlyEmployee?: boolean;
    showDisabledAccounts?: boolean;
    otherUserField?: Field<TFormData>;
    loading?: boolean;
    containerId?: string;
};

export const VUserSelector = <TFormData extends DefaultFormData>({
    field,
    formStore,
    handlingPartyIds,
    limitToCurrentUserHandlingParties,
    removeSystemAccount,
    removeAnonymousAccount,
    onlyEmployee,
    showDisabledAccounts,
    onSave,
    otherUserField,
    valueOverride,
    buttonsPosition = 'right',
    containerId,
    ...props
}: Props<TFormData>) => {
    const id = useId();
    const selectRef = useRef(null);
    const wrapperRef = useRef<InputWrapperRef | null>(null);
    const getFieldValue = formStore.use.getFieldValue();
    const save = formStore.use.save();
    formStore.use.formData({ throwOnUndefined: false });

    const currentUser = useUser();

    const [showOtherUserInput, setShowOtherUserInput] = useState(
        !!(otherUserField && getFieldValue(otherUserField) === -1),
    );
    const [form] = Form.useForm();

    const { data: options, isLoading } = trpc.user.listUsersForSelector.useQuery(
        {
            where: {
                AND: [
                    ...(limitToCurrentUserHandlingParties &&
                    !currentUser.isOrgAdmin &&
                    !currentUser.isSuperAdmin &&
                    currentUser?.externalOrgIds?.length
                        ? [
                              {
                                  HandlingParties: {
                                      some: {
                                          handlingPartyId: {
                                              in: currentUser.externalOrgIds,
                                          },
                                      },
                                  },
                              },
                          ]
                        : []),
                    ...(handlingPartyIds?.length
                        ? [
                              {
                                  HandlingParties: {
                                      some: {
                                          handlingPartyId: {
                                              in: handlingPartyIds,
                                          },
                                      },
                                  },
                              },
                          ]
                        : []),
                    ...(removeSystemAccount
                        ? [
                              {
                                  userId: { not: SYSTEM_ACCOUNT_IDS.SYSTEM },
                                  User: { isSupportAccount: { not: true } },
                              },
                          ]
                        : []),
                    ...(removeAnonymousAccount ? [{ userId: { not: SYSTEM_ACCOUNT_IDS.ANONYMOUS } }] : []),
                    ...(!otherUserField ? [{ userId: { not: SYSTEM_ACCOUNT_IDS.OTHER } }] : []),
                    ...(onlyEmployee ? [{ isEmployee: true }] : []),
                    ...(showDisabledAccounts ? [] : [{ disabled: false }]),
                ],
            },
        },
        {
            select({ rows }) {
                const map = rows.reduce(
                    (prev, item) => {
                        const key = item.fullName.toLowerCase() || item.email;
                        return { ...prev, [key]: prev[key] ? prev[key] + 1 : 1 };
                    },
                    {} as Record<string, number>,
                );

                return rows.map((item) => {
                    const key = item.fullName.toLowerCase() || item.email;
                    const name = map[key] > 1 ? `${item.fullName} (${item.email})` : item.fullName;

                    return {
                        value: Number(item.userId),
                        name,
                        label: <User userId={item.userId} photoUrl={item.photoUrl} name={name} showPopover={false} />,
                        searchValue: `${item.fullName} ${item.email}`.toLowerCase(),
                    };
                });
            },
        },
    );

    const onSaveDefault: Props<TFormData>['onSave'] = async (value, formStoreActions, formInstance) => {
        const modifiedValue = BigInt(value);

        if (onSave) {
            await onSave(modifiedValue, formStoreActions, formInstance);
            return;
        }

        void save({
            [field]: modifiedValue,
            ...(otherUserField
                ? {
                      [otherUserField]:
                          modifiedValue === BigInt(SYSTEM_ACCOUNT_IDS.OTHER)
                              ? formInstance.getFieldValue(otherUserField as any)
                              : null,
                  }
                : {}),
        } as Partial<TFormData>);
    };

    return (
        <InputWrapper<TFormData>
            innerRef={(ref) => {
                wrapperRef.current = ref;
            }}
            field={field}
            focusFn={() => {
                const current = selectRef.current as unknown as HTMLInputElement;
                current?.focus();
            }}
            hideSaveButton={!otherUserField}
            containerId={containerId || id}
            formStore={formStore}
            valueOverride={valueOverride || <User userId={getFieldValue(field)} />}
            onSave={onSaveDefault}
            formOverride={form}
            addonAfter={
                showOtherUserInput && (
                    <Input
                        className="mt-2"
                        defaultValue={form.getFieldValue(otherUserField)}
                        onChange={(e) => {
                            form.setFieldValue(otherUserField, e.target.value);
                        }}
                    />
                )
            }
            buttonsPosition={buttonsPosition}
            {...props}
        >
            <Select<UserOption>
                ref={selectRef}
                suffixIcon={null}
                defaultOpen
                loading={isLoading || props.loading}
                options={options}
                filterOption={(input, option) => option?.searchValue?.includes(input.toLowerCase())}
                notFoundContent={isLoading ? <Typography.Text>Loading...</Typography.Text> : 'No data'}
                onSelect={async (value: unknown) => {
                    if (otherUserField) {
                        setShowOtherUserInput(value === SYSTEM_ACCOUNT_IDS.OTHER);
                        return;
                    }

                    await wrapperRef.current?.save();
                }}
                showSearch
                getPopupContainer={() => document.getElementById(containerId || id)!}
                labelRender={(label) => {
                    const user = options?.find((option) => Number(option.value) === Number(label.value));
                    if (user) {
                        return user.label;
                    }
                    return '';
                }}
                placeholder="Select user"
                popupMatchSelectWidth={false}
            />
        </InputWrapper>
    );
};
