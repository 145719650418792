import { ErrorFallback } from '@client/components/Common/ErrorFallback';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { modal } from '@client/components/Common/modal';
import { OfflineOverlay } from '@client/components/Common/OfflineOverlay';
import { DebugInfo } from '@client/components/DebugInfo';
import { useAppBuildVersion } from '@client/hooks/useAppBuildVersion';
import { useDocumentTitle } from '@client/hooks/useDocumentTitle';
import { useInMaintenance } from '@client/hooks/useInMaintenance';
import { useOfflineDetection } from '@client/hooks/useOfflineDetection';
import * as Sentry from '@sentry/react';
import { Outlet } from '@tanstack/react-router';

export function AppProvider() {
    useOfflineDetection();
    useDocumentTitle();
    useAppBuildVersion();
    useInMaintenance();
    errorMessage.init(modal, message);

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <OfflineOverlay />
            <Outlet />
            <DebugInfo />
        </Sentry.ErrorBoundary>
    );
}
