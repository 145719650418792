import { ConfigurationItemEditor } from '@client/components/Configuration/ConfigurationItemEditor';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/documents')({
    staticData: {
        breadcrumb: {
            title: 'Documents',
        },
        ui: {
            title: 'Documents configuration',
        },
        modules: ['compliance', 'claim'],
    },
    component: ConfigurationDocuments,
});

function ConfigurationDocuments() {
    return (
        <ConfigurationItemEditor
            title="Document types"
            settingToEdit={'CLAIM_UPLOAD_DOCUMENT_TYPES'}
            description="Rename, add or remove an upload document type."
            editor={{ height: '150px' }}
        />
    );
}
