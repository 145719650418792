import { ExportOutlined, PlusOutlined } from '@ant-design/icons';
import { createLink, LinkComponent } from '@tanstack/react-router';
import { Button, ButtonProps, Tooltip } from 'antd';
import clsx from 'clsx';
import { FC, forwardRef } from 'react';

export type LinkProps = Omit<ButtonProps, 'href'> & { innerType?: ButtonProps['type'] };

const AntdLinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(({ innerType, className, ...props }, ref) => (
    <Button
        ref={ref}
        type={innerType}
        className={clsx(innerType === 'link' && 'h-auto border-none p-0', className)}
        {...props}
    />
));

const CreatedLinkComponent = createLink(AntdLinkComponent);

export type LinkFC<P extends Record<string, any> = LinkProps> = LinkComponent<FC<P>>;

const Add: LinkFC<Pick<LinkProps, 'type'> & { label: `${'New' | 'Generate'} ${string}` }> = ({
    label,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    ...props
}) => {
    return (
        <Link icon={<PlusOutlined />} {...(props as any)}>
            {label}
        </Link>
    );
};

export const NewTab: LinkFC = (props) => (
    <Tooltip title="Open in new tab">
        <Link
            type="link"
            size="small"
            icon={<ExportOutlined />}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            {...props}
        />
    </Tooltip>
);

const Link: LinkFC & { Add: typeof Add; NewTab: typeof NewTab } = ({ preload, type, innerType, ...props }) => {
    return <CreatedLinkComponent preload={preload || 'intent'} innerType={innerType || type} {...(props as any)} />;
};

Link.Add = Add;
Link.NewTab = NewTab;

export { Link };
