import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { NavTab, useTabNavigation } from '@client/hooks/useTabNavigation';
import { ShareableRouteContext } from '@client/types/router';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Tabs } from 'antd';

export const Route = createFileRoute('/console/$orgId/configuration/billing')({
    staticData: {
        breadcrumb: {
            title: 'Billing & usage',
        },
        modules: ['tools'],
        roles: ['org_admin'],
    },
    beforeLoad(): ShareableRouteContext {
        return {
            ui: {
                title: 'Billing & usage configuration',
            },
        };
    },
    component: Billing,
});

const TAB_ROUTES = [
    {
        label: 'Balance',
        path: '/console/$orgId/configuration/billing/balance',
    },
    {
        label: 'Billing Information',
        path: '/console/$orgId/configuration/billing/billing-info',
    },
    {
        label: 'Invoices',
        path: '/console/$orgId/configuration/billing/invoices',
    },
    {
        label: 'Transactions',
        path: '/console/$orgId/configuration/billing/transactions',
    },
    {
        label: 'Usage',
        path: '/console/$orgId/configuration/billing/usage',
    },
] satisfies NavTab[];

function Billing() {
    const orgId = useOrgId();
    const navigate = useNavigate();

    const { activeKey } = useTabNavigation({
        tabs: TAB_ROUTES,
        defaultTabNavigateTo: () =>
            navigate({
                to: TAB_ROUTES[0].path,
                params: {
                    orgId,
                },
            }),
    });

    return (
        <>
            <Tabs
                activeKey={activeKey}
                items={TAB_ROUTES.map((route) => ({
                    label: route.label,
                    key: route.path,
                }))}
                onTabClick={(path) => {
                    navigate({
                        to: path,
                        params: {
                            orgId,
                        },
                    });
                }}
            />
            <Outlet />
        </>
    );
}
