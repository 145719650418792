import { Link } from '@client/components/Common/Link';
import { User } from '@client/components/Common/User';
import { PageLayout } from '@client/components/Layout/PageLayout';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useNavigate } from '@client/hooks/useNavigate';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Card, Table, Tag } from 'antd';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/compliance/training/search')({
    staticData: {
        ui: {
            title: 'Staff training record',
            subtitle: 'Create, track, and manage staff training records',
        },
    },

    component: TrainingRecordSearch,
});

type UserInOrg = RouterOutputs['trainingRecord']['listTrainingRecordsByUser']['rows'][number];

function TrainingRecordSearch() {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState<RouterInputs['trainingRecord']['listTrainingRecordsByUser']>({
        where: undefined,
        orderBy: { userId: 'desc' },
        limit: 10,
        offset: 0,
    });
    const { data, isLoading } = trpc.trainingRecord.listTrainingRecordsByUser.useQuery(queryParams);

    const config = useConfigServiceLoader((configService) => configService.fetchStaffTrainingRecord());

    const { tableProps } = useAntdTable<UserInOrg>({
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setQueryParams((prev) => ({
                ...prev,
                ...options,
                orderBy: options?.orderBy?.length ? options?.orderBy[0] : queryParams.orderBy,
            }));
        },
        rowKey: 'userId',
        columns: [
            {
                title: 'Employee ID',
                dataIndex: 'userId',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'fullName',
                sorter: true,
                render: (_, record) => (
                    <User userId={record.userId} name={record.fullName} photoUrl={record.User?.photoUrl} />
                ),
            },
            {
                title: 'Position',
                dataIndex: 'position',
                sorter: true,
            },
            {
                title: '# of hours this period',
                dataIndex: 'sumOfHours',
                align: 'center',
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                title: `${config.data?.pointsName} this period`,
                dataIndex: 'sumOfCipCredits',
                align: 'center',
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                ...commonActionColumn,
                dataIndex: 'userId',
                render: (value) => (
                    <Link.NewTab
                        to="/console/$orgId/compliance/training/user/$userId"
                        params={{
                            orgId,
                            userId: value,
                        }}
                    />
                ),
            },
        ],
    });

    return (
        <>
            <Outlet />

            <PageLayout.Header
                primaryActionAddon={
                    <Link.Add
                        label="New Training Record"
                        to="/console/$orgId/compliance/training/search/add"
                        params={{ orgId }}
                        type="primary"
                    />
                }
            />

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ userId }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/training/user/$userId',
                                    params: {
                                        orgId,
                                        userId,
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
