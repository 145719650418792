import { message } from '@client/components/Common/message';
import { notification } from '@client/components/Common/Notification';
import { debounce } from '@client/utils/general';
import { textToHtml } from '@client/utils/text';

export const errorNotification = debounce((error: Error | string | unknown) => {
    console.log('errorNotification', error);
    message.destroy();
    notification.error({
        message: 'Error',
        description: <div dangerouslySetInnerHTML={{ __html: getErrorDescription(error) }} />,
        showProgress: true,
        pauseOnHover: true,
    });
}, 300);

function getErrorDescription(error: Error | string | unknown) {
    return error instanceof Error
        ? textToHtml(error.message)
        : typeof error === 'string'
          ? textToHtml(error)
          : error && typeof error === 'object' && 'toString' in error
            ? textToHtml(error.toString())
            : 'Unknown error';
}
