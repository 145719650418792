import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/incidents')({
    staticData: {
        metadata: {
            title: 'Incidents',
        },
    },
    component: Outlet,
});
