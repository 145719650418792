import { Tabs } from 'antd';
import { PaymentUsers } from './PaymentUsers';
import { PaymentProcesses } from './PaymentProcesses';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/payments')({
    staticData: {
        breadcrumb: {
            title: 'Payments',
        },
        ui: {
            title: 'Payments configuration',
        },
        modules: ['claim'],
        userFlags: ['payment_admin'],
    },
    component: ConfigurationPayments,
});

function ConfigurationPayments() {
    return (
        <Tabs
            type="line"
            defaultActiveKey="processes"
            items={[
                {
                    key: 'processes',
                    label: 'Payment processes',
                    children: <PaymentProcesses />,
                },
                {
                    key: 'users',
                    label: 'Payment users',
                    children: <PaymentUsers />,
                },
            ]}
        />
    );
}
