import { OverlayPage } from '@client/components/Layout/OverlayPage';
import { TaskView } from '@client/components/Task/TaskView';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { Button } from 'antd';

function Content({ id }: { id: bigint }) {
    const { onClose } = OverlayPage.useContext();

    return (
        <TaskView
            taskId={id}
            onUpdate={({ wasCompleted }) => {
                if (wasCompleted) {
                    onClose();
                }
            }}
        />
    );
}

export function TaskDetails({ id }: { id: bigint }) {
    const { trpcUtils } = useTrpc();
    const navigate = useNavigate();

    return (
        <OverlayPage
            title="Task details"
            afterClose={() => {
                void trpcUtils.task.invalidate();
                navigate({ moveInMatches: -1, keepSearch: true });
            }}
            footer={({ onClose }) => <Button onClick={onClose}>Close</Button>}
        >
            <Content id={id} />
        </OverlayPage>
    );
}
