import { grey } from '@ant-design/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { useZodState } from '@client/hooks/useZodState';
import { RouterOutputs, trpc } from '@client/trpc/client';
import { commonActionColumn } from '@client/utils/table';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Checkbox, Popconfirm, Table } from 'antd';
import { useState } from 'react';
import { listInsurerInputSchema } from 'server/schemas/insurer';
import { InsurerAction, InsurerEdit } from './InsurerEdit';

export const Route = createFileRoute('/console/$orgId/configuration/insurers')({
    staticData: {
        breadcrumb: {
            title: 'Insurers',
        },
        ui: {
            title: 'Insurers configuration',
        },
        modules: ['claim'],
    },
    component: ConfigurationInsurers,
});

type Insurer = RouterOutputs['insurer']['listInsurers']['rows'][number];

function ConfigurationInsurers() {
    const [action, setAction] = useState<InsurerAction>(['none']);
    const [queryParams, setQueryParams] = useZodState(listInsurerInputSchema);

    const { data, isLoading, refetch } = trpc.insurer.listInsurers.useQuery(queryParams);
    const { mutateAsync: deleteInsurer } = trpc.insurer.deleteInsurer.useMutation();

    const { tableProps } = useAntdTable<Insurer>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setQueryParams((prev) => ({
                ...prev,
                ...options,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                sorter: true,
            },
            {
                title: 'Apply GST?',
                dataIndex: 'applyGst',
                render: (value) => <Checkbox checked={!!value} onChange={() => {}} />,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Popconfirm
                        title="Are you sure?"
                        onPopupClick={(e) => e.stopPropagation()}
                        onConfirm={async () => {
                            try {
                                await deleteInsurer({
                                    id: value,
                                });
                                void refetch();
                            } catch (e) {
                                errorMessage.show(e);
                            }
                        }}
                    >
                        <Button
                            type="text"
                            size="small"
                            icon={<DeleteOutlined style={{ color: grey.primary }} />}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                ),
            },
        ],
    });

    return (
        <>
            <InsurerEdit
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />
            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    Add new
                </Button>
            </div>
            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setAction(['edit', id]),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
