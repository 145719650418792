import { Tabs } from '@client/components/Navigation/Tabs';
import { ShareableRouteContext } from '@client/types/router';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/tools/auto-detect')({
    staticData: {
        breadcrumb: {
            title: 'Auto-detect',
        },
    },

    beforeLoad(): ShareableRouteContext {
        return {
            ui: {
                title: 'Auto-detect',
            },
        };
    },
    component: ComplianceAiInbox,
});

function ComplianceAiInbox() {
    return (
        <Tabs
            items={[
                {
                    label: 'Inbox',
                    to: '/console/$orgId/tools/auto-detect/inbox',
                },
                {
                    label: 'Dashboard',
                    to: '/console/$orgId/tools/auto-detect/dashboard',
                },
            ]}
        />
    );
}
