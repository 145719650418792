import { FormItem } from '@client/components/Claim/ProductType/FormItem';
import { DriverContact } from '@client/components/Claim/ProductType/ProductTypeTemplates/DriverContact';
import { ActionBar } from '@client/components/Common/ActionBar';
import { ScrollToTopOnMount } from '@client/components/Common/ScrollToTopOnMount';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { ProductTypeDef } from '@shared/types/productType';
import { CardTitle } from '@client/components/Common/CardTitle';
import { ContactCollection } from '@shared/types/contact';

type Result = Record<string, any>;

interface Props {
    productTypeDef: ProductTypeDef;
    goBack: () => void;
    goForward: () => void;
    onSaveDraftOpen?: () => void;
    claimData: {
        productTypeData?: Record<string, any> | null;
        contacts?: ContactCollection | null;
    };
    direction: 'forward' | 'backward';
}

export const ProductDataEntryForm = observer(
    ({ productTypeDef, goBack, goForward, onSaveDraftOpen, claimData, direction }: Props) => {
        const [formData, setFormData] = useState<Result>({});
        const [form] = Form.useForm();
        const [formIndex, setFormIndex] = useState(0);

        useEffect(() => {
            if (!productTypeDef.cards || (productTypeDef.cards && productTypeDef.cards.length === 0)) {
                if (direction === 'backward') {
                    goBack();
                } else {
                    goForward();
                }
                return;
            }
            const data = claimData.productTypeData || {};
            setFormData(data);
            form.setFieldsValue(data);
            if (direction === 'backward') {
                setFormIndex(productTypeDef.cards.length - 1);
            } else {
                setFormIndex(0);
            }
        }, [direction]);

        const goBackInternal = () => {
            if (formIndex === 0) {
                goBack();
            } else {
                setFormIndex((prevState) => prevState - 1);
            }
        };

        const onFinish = (values: object, goNext = true) => {
            const newFormData = { ...formData, ...values };
            setFormData(newFormData);
            if (formIndex === productTypeDef.cards.length - 1) {
                claimData.productTypeData = newFormData;
                if (goNext) {
                    goForward();
                    return;
                }
            }
            if (goNext) {
                setFormIndex((prevState) => prevState + 1);
            }
        };

        const onNext = () => {
            if (formIndex === productTypeDef.cards.length - 1) {
                goForward();
                return;
            }
            setFormIndex((prevState) => prevState + 1);
        };

        const card = useMemo(() => {
            if (!productTypeDef.cards) {
                return undefined;
            }
            return productTypeDef.cards[formIndex];
        }, [formIndex]);

        return (
            <>
                {!!card && (
                    <>
                        {card.type === 'FORM' && (
                            <Form
                                form={form}
                                onFinish={onFinish}
                                name="product_type_form"
                                layout="vertical"
                                size="large"
                            >
                                <ScrollToTopOnMount dep={card.id} />
                                <Card
                                    title={<CardTitle title={card.title} subTitle={card.subTitle} />}
                                    key={formIndex}
                                    className="crm-ant-card-flexible-header"
                                >
                                    {card.fields.map((field, index) => (
                                        <FormItem field={field} index={index} key={index} form={form} />
                                    ))}
                                </Card>
                                <ActionBar
                                    renderLeft={() => (
                                        <Button
                                            type="default"
                                            onClick={goBackInternal}
                                            icon={<ArrowLeftOutlined />}
                                            size="large"
                                        >
                                            Back
                                        </Button>
                                    )}
                                    renderRight={() => (
                                        <>
                                            {onSaveDraftOpen && (
                                                <Button
                                                    icon={<SaveOutlined />}
                                                    onClick={() => {
                                                        const values = form.getFieldsValue();
                                                        onFinish(values, false);
                                                        onSaveDraftOpen();
                                                    }}
                                                    size="large"
                                                >
                                                    Save
                                                </Button>
                                            )}
                                            <Button type="primary" htmlType="submit" size="large">
                                                Next
                                            </Button>
                                        </>
                                    )}
                                />
                            </Form>
                        )}
                        {card.type === 'TEMPLATE' && card.templateId === 'DRIVER_CONTACT' && (
                            <DriverContact
                                claimData={claimData}
                                onBack={goBackInternal}
                                onNext={onNext}
                                onSaveDraftOpen={onSaveDraftOpen}
                                card={card}
                            />
                        )}
                    </>
                )}
            </>
        );
    },
);
