import { Link } from '@client/components/Common/Link';
import { useNavigate } from '@client/hooks/useNavigate';
import logo from '@client/resources/images/logo.svg';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/')({
    component: LandingPage,
});

function LandingPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const reason = searchParams.get('reason');

        if (reason === 'logout') {
            navigate({
                to: '/login',
            });
        }
    }, []);

    return (
        <div className="relative min-h-screen w-screen bg-gray-100 md:justify-center">
            <Link type="primary" size="large" className="absolute right-6 top-4 no-underline" to="/login">
                Log in
            </Link>

            <img
                src={logo}
                alt="Logo"
                className="w-74 absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            />
        </div>
    );
}
