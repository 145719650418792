import { observer } from 'mobx-react-lite';
import { Input, InputRef, GetProp } from 'antd';
import { ReactNode, useRef } from 'react';
import { InputWrapper, VInputProps } from '@client/components/Common/ViewForm/InputWrapper';
import { DefaultFormData } from '@client/stores/FormStore/types';

type Props<TFormData extends DefaultFormData> = VInputProps<TFormData> & {
    prefix?: GetProp<typeof Input, 'prefix'>;
    addonBefore?: ReactNode;
};

export const VTextInput = observer(
    <TFormData extends DefaultFormData>({ prefix, addonBefore, ...props }: Props<TFormData>) => {
        const inputRef = useRef<InputRef>(null);

        return (
            <InputWrapper
                focusFn={() => {
                    inputRef.current?.focus();
                }}
                {...props}
            >
                <Input addonBefore={addonBefore} prefix={prefix} ref={inputRef} />
            </InputWrapper>
        );
    },
);
