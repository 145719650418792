import { complaintFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';
import { DocumentLibrary } from '@client/components/Common/Documents/DocumentLibrary';
import { COMPLAINT_STATUS } from '@shared/types/complaint';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/details/$id/library')({
    component: Library,
});

function Library() {
    const complaint = complaintFormStore.use.formData();

    return (
        <DocumentLibrary
            disableUploading={complaint.status === COMPLAINT_STATUS.CLOSED}
            entityId={Number(complaint.id)}
            entityType="complaint"
        />
    );
}
