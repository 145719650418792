import { useAppStateStore } from '@client/stores/AppStateStore';
import { logInDev } from '@client/utils/general';
import { useEffect } from 'react';

export function useOfflineDetection() {
    const appStateStore = useAppStateStore();

    useEffect(() => {
        const updateOnlineStatus = () => {
            logInDev('Online status changed to:', navigator.onLine);
            appStateStore.actions.setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, [appStateStore]);
}
