import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useApolloClient } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { claimFormStore } from '../../store';
import { ClaimExpenseEdit } from '../ClaimExpenseEdit';
import { ExpenseItemsByClaimId } from '../~route';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/expenses/$expenseId/edit')({
    component: ExpenseEdit,
});

function ExpenseEdit() {
    const orgId = useOrgId();
    const claim = claimFormStore.use.formData();
    const isReadonly = claimFormStore.use.isReadonly();
    const { expenseId } = Route.useParams();
    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    return (
        !isReadonly && (
            <ClaimExpenseEdit
                open={open}
                claimId={claim.id}
                claimInsurerId={claim.insurerId}
                expenseItemId={+expenseId}
                onClose={() => {
                    setOpen(false);

                    void apolloClient.refetchQueries({
                        include: [ExpenseItemsByClaimId],
                    });

                    navigate({
                        to: '/console/$orgId/claims/details/$id/expenses',
                        params: {
                            orgId,
                            id: claim.id,
                        },
                        fromComponent: 'drawer',
                    });
                }}
                claimHandlingPartyIds={claim.HandlingParties.map(({ id }) => id)}
            />
        )
    );
}
