import { DatePicker } from '@client/components/Common/DatePicker';
import { errorMessage } from '@client/components/Common/errorMessage';
import { FileUpload, FileUploadRef } from '@client/components/Common/FileUpload';
import { message } from '@client/components/Common/message';
import { NumberInput } from '@client/components/Common/NumberInput';
import { UserSelectorMultiple } from '@client/components/Common/UserSelectorMultiple';
import { TrainingRecordDefs } from '@client/global/trainingRecord';
import { useConfigServiceLoader } from '@client/hooks/Configuration/useConfigServiceLoader';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterInputs, trpc } from '@client/trpc/client';
import { debounce } from '@client/utils/general';
import { skipToken } from '@tanstack/react-query';
import { useMatches, useParams } from '@tanstack/react-router';
import { Button, Drawer, Form, Input, Select, Skeleton, Space } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';

type Props = {
    recordId?: bigint | null;
};

type TrainingRecord = RouterInputs['trainingRecord']['createTrainingRecord'] & {
    employeeIds: bigint[] | null;
};

const FormItem = Form.Item<TrainingRecord>;

export const TrainingRecordEdit = ({ recordId }: Props) => {
    const matches = useMatches();
    const navigate = useNavigate();
    const { fDateShortDayJs } = useFormatter();
    const { trpcUtils } = useTrpc();
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [open, setOpen] = useState(true);
    const fileUploadRef = useRef<FileUploadRef>(null);

    const config = useConfigServiceLoader((configService) => configService.fetchStaffTrainingRecord());

    const { mutateAsync: createTrainingRecord } = trpc.trainingRecord.createTrainingRecord.useMutation();
    const { mutateAsync: updateTrainingRecord } = trpc.trainingRecord.updateTrainingRecord.useMutation();

    const { userId } = useParams({
        strict: false,
    });

    const action: 'edit' | 'add' = useMemo(() => (recordId ? 'edit' : 'add'), [recordId]);

    const { data, isLoading } = trpc.trainingRecord.getTrainingRecord.useQuery(recordId ? { id: recordId } : skipToken);

    useEffect(() => {
        if (data) {
            form.resetFields();
            form.setFieldsValue(data);
        }
    }, [data]);

    function closeDrawer() {
        form.resetFields();
        fileUploadRef.current?.clearFileList();
        void trpcUtils.trainingRecord.listTrainingRecordsByUser.invalidate();
        void trpcUtils.trainingRecord.listTrainingRecords.invalidate();
        void trpcUtils.trainingPeriod.invalidate();
        setOpen(false);
    }

    const save = debounce(async () => {
        try {
            setSaving(true);
            message.loading('Saving...');
            const values = form.getFieldsValue();

            if (action === 'add') {
                const { trainingRecords } = await createTrainingRecord({
                    ...values,
                    employeeIds: userId ? [userId] : values.employeeIds,
                });

                if (trainingRecords.length > 0) {
                    await fileUploadRef.current?.insertFileList(trainingRecords.map((record) => record.id));
                }
            }

            if (action === 'edit' && recordId) {
                await updateTrainingRecord({
                    id: recordId,
                    set: values,
                });

                await fileUploadRef.current?.insertFileList([recordId!]);
            }

            message.destroy();
            message.success('Saved.');
            closeDrawer();
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    });

    return (
        <Drawer
            open={open}
            onClose={() => {
                closeDrawer();
            }}
            afterOpenChange={(open) => {
                if (!open) {
                    const parentRoute = matches[matches.length - 2];
                    navigate({
                        to: parentRoute.pathname,
                    });
                }
            }}
            width={500}
            title={action === 'edit' ? 'Edit training record' : 'Add new training record'}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => closeDrawer()}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form="trainingRecordForm" loading={saving}>
                        Save
                    </Button>
                </Space>
            }
            maskClosable={false}
        >
            <Form
                id="trainingRecordForm"
                form={form}
                className="mt-5"
                layout="vertical"
                onFinish={save}
                onFinishFailed={(errorInfo) => {
                    form.scrollToField(errorInfo.errorFields[0].name);
                }}
            >
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <FormItem
                            label="Training record name"
                            name="trainingName"
                            required
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </FormItem>
                        <FormItem label="Type" name="type" required>
                            <Select
                                options={TrainingRecordDefs.aryTypes.map((type) => ({
                                    label: type,
                                    value: type,
                                }))}
                            />
                        </FormItem>
                        {!userId && (
                            <FormItem
                                label="Employee(s)"
                                name="employeeIds"
                                required
                                rules={[
                                    {
                                        validator: async (_, value) => {
                                            if (!value?.length) throw new Error('Please select name employee.');
                                        },
                                    },
                                ]}
                            >
                                <UserSelectorMultiple onlyEmployee />
                            </FormItem>
                        )}
                        <FormItem label="Provider" name="provider">
                            <Input />
                        </FormItem>
                        <FormItem label="Date completed" name="dateOfTraining" required rules={[{ required: true }]}>
                            <DatePicker className="w-full" format={fDateShortDayJs} maxDate={new Date()} />
                        </FormItem>
                        <FormItem
                            label="No of hours"
                            name="noOfHours"
                            required
                            rules={[
                                { required: true },
                                {
                                    validator: async (_, value) => {
                                        if (value < 0) throw new Error('Only positive values are allowed');
                                    },
                                },
                            ]}
                        >
                            <NumberInput className="w-full" min={0} valueType="decimal" />
                        </FormItem>
                        <FormItem
                            label={config.data?.pointsName}
                            name="cipCredits"
                            required
                            rules={[
                                { required: true },
                                {
                                    validator: async (_, value) => {
                                        if (value < 0) throw new Error('Only positive values are allowed');
                                    },
                                },
                            ]}
                        >
                            <NumberInput className="w-full" min={0} valueType="decimal" />
                        </FormItem>
                        <FormItem label="Comments" name="comments">
                            <Input.TextArea rows={4} />
                        </FormItem>
                        <FileUpload
                            idPropertyName="trainingRecordId"
                            idValues={recordId ? [recordId] : undefined}
                            ref={fileUploadRef}
                        />
                    </>
                )}
            </Form>
        </Drawer>
    );
};
