import { useUser } from '@client/hooks/User/useUser';
import { trpc } from '@client/trpc/client';
import { SYSTEM_ACCOUNT_IDS } from '@shared/definitions/user';
import { Select } from 'antd';
import { ReactNode } from 'react';
import { User } from '../User';

type Props = {
    readonly?: boolean;
    placeholder?: string;
    /**
     * If true, only shows users that share handling parties with the current user.
     * handlingPartyIds will work as additional filter in this case
     */
    limitToCurrentUserHandlingParties?: boolean;
    handlingPartyIds?: number[] | bigint[];
    removeSystemAccount?: boolean;
    removeAnonymousAccount?: boolean;
    onlyEmployee?: boolean;
    showDisabledAccounts?: boolean;
    showUnallocated?: boolean;
    className?: string;
} & ( // TODO: remove valueType prop after full migration from Hasura to trpc
    | {
          value?: bigint[] | null;
          onChange?: (
              userId: bigint[] | null,
              option: {
                  value: bigint[] | null;
                  label: ReactNode;
              },
          ) => void;
          valueType?: 'bigint';
      }
    | {
          value?: bigint[] | null;
          onChange?: (
              userId: bigint[] | null,
              option: {
                  value: bigint;
                  label: ReactNode;
              },
          ) => void;
          valueType: 'bigint';
      }
);

export function UserSelectorMultiple({
    value,
    valueType = 'bigint',
    onChange,
    handlingPartyIds,
    removeSystemAccount = true,
    removeAnonymousAccount = true,
    onlyEmployee,
    showUnallocated,
    limitToCurrentUserHandlingParties,
    ...props
}: Props) {
    const currentUser = useUser();
    const { data, isPending } = trpc.user.listUsersForSelector.useQuery(
        {
            where: {
                AND: [
                    ...(limitToCurrentUserHandlingParties &&
                    !currentUser.isOrgAdmin &&
                    !currentUser.isSuperAdmin &&
                    currentUser?.externalOrgIds?.length
                        ? [
                              {
                                  HandlingParties: {
                                      some: {
                                          handlingPartyId: {
                                              in: currentUser.externalOrgIds,
                                          },
                                      },
                                  },
                              },
                          ]
                        : []),
                    ...(handlingPartyIds?.length
                        ? [
                              {
                                  HandlingParties: {
                                      some: {
                                          handlingPartyId: {
                                              in: handlingPartyIds,
                                          },
                                      },
                                  },
                              },
                          ]
                        : []),
                    ...(removeSystemAccount
                        ? [
                              {
                                  userId: { not: SYSTEM_ACCOUNT_IDS.SYSTEM },
                                  User: { isSupportAccount: { not: true } },
                              },
                          ]
                        : []),
                    ...(removeAnonymousAccount ? [{ userId: { not: SYSTEM_ACCOUNT_IDS.ANONYMOUS } }] : []),
                    ...(onlyEmployee ? [{ isEmployee: true }] : []),
                ],
            },
        },
        {
            select({ rows }) {
                const map = rows.reduce(
                    (prev, item) => {
                        const key = item.fullName.toLowerCase() || item.email;
                        return { ...prev, [key]: prev[key] ? prev[key] + 1 : 1 };
                    },
                    {} as Record<string, number>,
                );

                const options = rows.map((item) => {
                    const key = item.fullName.toLowerCase() || item.email;
                    const name = map[key] > 1 ? `${item.fullName} (${item.email})` : item.fullName;

                    return {
                        value: valueType === 'bigint' ? item.userId : Number(item.userId),
                        label: <User userId={item.userId} photoUrl={item.photoUrl} name={name} showPopover={false} />,
                        searchValue: `${item.fullName} ${item.email}`.toLowerCase(),
                    };
                });

                if (showUnallocated) {
                    options.push({
                        value: SYSTEM_ACCOUNT_IDS.UNALLOCATED,
                        label: <User />,
                        searchValue: 'unallocated',
                    });
                }

                return options;
            },
        },
    );

    return (
        <Select
            value={value}
            onChange={onChange as any}
            options={data}
            loading={isPending}
            mode="multiple"
            filterOption={(input, option) => !!option?.searchValue?.includes(input.toLowerCase())}
            popupMatchSelectWidth={false}
            allowClear
            {...props}
        />
    );
}
