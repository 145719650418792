import { RouterErrorComponent } from '@client/components/Error/RouterErrorComponent';
import { AppProvider } from '@client/components/Providers/AppProvider';
import { Auth0Provider } from '@client/components/Providers/Auth0Provider';
import { EventEmitterProvider } from '@client/components/Providers/EventEmitterProvider';
import { TrpcProvider } from '@client/components/Providers/TrpcProvider';
import { ApolloSetup } from '@client/graphql';
import { RouteContext } from '@client/types/router';
import { createRootRouteWithContext, stripSearchParams } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

const DEFAULT_VALUES = { search: '' };

export const Route = createRootRouteWithContext<RouteContext>()({
    validateSearch: zodValidator(z.object({ search: z.string().default(DEFAULT_VALUES.search) })),
    search: { middlewares: [stripSearchParams(DEFAULT_VALUES)] },
    component: Root,
    errorComponent: RouterErrorComponent,
});

function Root() {
    return (
        <ApolloSetup>
            <Auth0Provider>
                <TrpcProvider>
                    <EventEmitterProvider>
                        <AppProvider />
                    </EventEmitterProvider>
                </TrpcProvider>
            </Auth0Provider>
        </ApolloSetup>
    );
}
