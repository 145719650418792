import { useMediaQuery } from '@client/hooks/UI/useMediaQuery';
import { dateToDayJs } from '@client/utils/dateTime';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider, StaticDatePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// eslint-disable-next-line curium/import/import
import { DatePicker as AntdDatePicker, Modal, theme } from 'antd';
import type { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';
import { Dayjs } from 'dayjs';
import { DateTime } from 'luxon';
import { forwardRef, useState } from 'react';

export type DatePickerProps = Omit<
    AntDatePickerProps<Dayjs>,
    'value' | 'onChange' | 'minDate' | 'maxDate' | 'defaultValue'
> & {
    value?: Date | null | undefined;
    onChange?: (value: Date | null | undefined) => void;
    minDate?: Date | undefined;
    maxDate?: Date | undefined;
    defaultValue?: Date | undefined;
};

export const DatePicker = forwardRef<any, DatePickerProps>(
    (
        {
            value: valueProp,
            onChange,
            minDate: minDateProp,
            maxDate: maxDateProp,
            defaultValue,
            ...props
        }: DatePickerProps,
        ref,
    ) => {
        const { token } = theme.useToken();
        const isMobile = useMediaQuery('(max-width: 768px)');

        const [visible, setVisible] = useState(false);

        const value = dateToDayJs(valueProp);
        const minDate = dateToDayJs(minDateProp) || undefined;
        const maxDate = dateToDayJs(maxDateProp) || undefined;

        function onMobileDateConfirm(value: Dayjs | null) {
            onChange?.(value ? DateTime.fromJSDate(value.toDate()).toUTC().toJSDate() : null);
            setVisible(false);
        }

        function renderMobileDatePicker() {
            return (
                <>
                    <AntdDatePicker
                        value={value}
                        panelRender={() => null}
                        onClick={() => setVisible(true)}
                        placeholder="Select date"
                        ref={ref}
                        inputReadOnly
                        minDate={minDate}
                        maxDate={maxDate}
                        defaultValue={defaultValue ? dateToDayJs(defaultValue) : undefined}
                        {...props}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Modal open={visible} centered footer={false} closable={false}>
                            <ThemeProvider
                                theme={createTheme({
                                    palette: {
                                        primary: {
                                            main: token.colorPrimary,
                                        },
                                        error: {
                                            main: token.colorError,
                                        },
                                    },
                                    typography: {
                                        fontFamily: token.fontFamily,
                                        fontSize: token.fontSize,
                                    },
                                })}
                            >
                                {props.showTime ? (
                                    <StaticDateTimePicker
                                        value={value}
                                        onClose={() => setVisible(false)}
                                        onAccept={onMobileDateConfirm}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                ) : (
                                    <StaticDatePicker
                                        value={value}
                                        onClose={() => setVisible(false)}
                                        onAccept={onMobileDateConfirm}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                )}
                            </ThemeProvider>
                        </Modal>
                    </LocalizationProvider>
                </>
            );
        }

        function renderDesktopDatePicker() {
            return (
                <AntdDatePicker
                    ref={ref}
                    value={value}
                    onChange={(value) =>
                        onChange?.(value ? DateTime.fromJSDate(value.toDate()).toUTC().toJSDate() : null)
                    }
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultValue={defaultValue ? dateToDayJs(defaultValue) : undefined}
                    {...props}
                />
            );
        }

        return isMobile ? renderMobileDatePicker() : renderDesktopDatePicker();
    },
);
