import { DownloadOutlined } from '@ant-design/icons';
import { errorMessage } from '@client/components/Common/errorMessage';
import { message } from '@client/components/Common/message';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { trpc } from '@client/trpc/client';
import { RecordNotFoundError } from '@client/utils/error';
import { exportObligation } from '@client/utils/obligation';
import { OBLIGATION_DETAILS_SECTIONS, ObligationDetailsSection } from '@shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Drawer, Skeleton, Space, Tabs } from 'antd';
import { useState } from 'react';
import { ObligationBreaches } from './ObligationBreaches';
import { ObligationDetailsManage } from './ObligationDetailsManage';
import { obligationFormStore } from './store';

export const Route = createFileRoute('/console/$orgId/compliance/obligations/details/$id/$section')({
    staticData: {
        breadcrumb: {
            title: 'Obligation details',
        },
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as ObligationDetailsSection,
            };
        },
    },
    component: ObligationDetails,
});

type ObligationBreachesAction = ['none'] | ['view', bigint];

function ObligationDetails() {
    const [obligationBreachesAction, setObligationBreachesAction] = useState<ObligationBreachesAction>(['none']);
    const updateObligationMutation = trpc.obligation.updateObligation.useMutation();
    const trpcClient = useTrpcClient();
    const navigate = useNavigate();
    const orgId = useOrgId();
    const { id } = Route.useParams();

    const { formData: obligation } = obligationFormStore.useInit({
        entity: 'obligation',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.loading('Saving...');

                await updateObligationMutation.mutateAsync({
                    id: currentValue.id,
                    set: newValue,
                });

                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.obligation.getObligation.query({ id });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e as Error };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The obligation you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({
                    to: '/console/$orgId/compliance/obligations',
                    params: { orgId },
                });
                return;
            }

            errorMessage.show(error);
        },
    });

    return (
        <>
            <Drawer
                open={obligationBreachesAction[0] === 'view'}
                width="70%"
                onClose={() => {
                    setObligationBreachesAction(['none']);
                }}
                title="Breaches"
                destroyOnClose
            >
                <ObligationBreaches obligationId={obligationBreachesAction[1]!} />
            </Drawer>

            {obligation && id === obligation.id ? (
                <Tabs
                    type="line"
                    onTabClick={(key) => {
                        navigate({
                            to: '/console/$orgId/compliance/obligations/details/$id/$section',
                            params: {
                                orgId,
                                id,
                                section: key as ObligationDetailsSection,
                            },
                        });
                    }}
                    tabBarExtraContent={{
                        right: (
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => setObligationBreachesAction(['view', obligation.id])}
                                >
                                    View breaches
                                </Button>
                                <Button
                                    type="link"
                                    icon={<DownloadOutlined />}
                                    onClick={() => {
                                        void exportObligation(trpcClient, id);
                                    }}
                                >
                                    Download obligation
                                </Button>
                            </Space>
                        ),
                    }}
                    items={[
                        {
                            key: OBLIGATION_DETAILS_SECTIONS.MANAGE,
                            label: 'Manage obligation',
                            children: <ObligationDetailsManage />,
                        },
                    ]}
                />
            ) : (
                <Skeleton active />
            )}
        </>
    );
}
