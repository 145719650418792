import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@client/__generated__/gql';
import { NoteTypeEnum } from '@client/__generated__/graphql';
import { AdditionalInfo } from '@client/components/Claim/Lodgement/AdditionalInfo';
import { Conditions } from '@client/components/Claim/Lodgement/Conditions';
import { Confirmation } from '@client/components/Claim/Lodgement/Confirmation';
import { ProductDataEntryForm } from '@client/components/Claim/ProductType/ProductDataEntryForm';
import { Questionnaire } from '@client/components/Claim/Questionnaire';
import { errorMessage } from '@client/components/Common/errorMessage';
import { ClaimByPk } from '@client/graphql/queries/claim';
import { PdsVersionByInsurerIdAndProductTypeId } from '@client/graphql/queries/pdsVersion';
import { ProductTypeByPk } from '@client/graphql/queries/productType';
import { useClaimFormState } from '@client/hooks/Claim/useClaimFormState';
import { usePolicyOutsideOfTerms } from '@client/hooks/Claim/usePolicyOutsideOfTerms';
import { useConfigService } from '@client/hooks/Configuration/useConfigService';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useQuestionnaireService } from '@client/services/QuestionnaireService';
import { Card as QCard } from '@shared/model/Card';
import { ProductTypeDef } from '@shared/types/productType';
import { filesToFileCollections } from '@shared/utils/file';
import { useParams } from '@tanstack/react-router';
import { Alert, Button, Card, Col, Row, Skeleton, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useEffect } from 'react';
import { AddNote } from './AddNote';
import { ClaimEvent } from './ClaimEvent';
import './index.sass';
import { PolicyData } from './PolicyData';

interface Props {
    formAction: 'new' | 'edit' | 'view';
}

export const ClaimForm = observer(({ formAction }: Props) => {
    const { id: claimId } = useParams({ strict: false }) as { id: string };
    const state = useClaimFormState();
    const orgId = useOrgId();
    const [getProductType] = useLazyQuery(ProductTypeByPk);
    const [getClaimByPk] = useLazyQuery(ClaimByPk);
    const [getPdsVersionByInsurerIdAndProductTypeId] = useLazyQuery(PdsVersionByInsurerIdAndProductTypeId);
    const [upsertClaim] = useMutation(UpsertClaim);
    const outsidePolicyTerms = usePolicyOutsideOfTerms({
        policyTermFrom: state.claim.policyTermFrom,
        policyStatus: state.claim.policyStatus || '',
    });

    const { claim, ui } = state;
    const configService = useConfigService();
    const service = useQuestionnaireService();

    useEffect(() => {
        const _ = async () => {
            if (claim.productTypeId && claim.insurerId) {
                const res = await getProductType({
                    variables: {
                        id: claim.productTypeId,
                        orgId: claim.insurerId,
                    },
                    fetchPolicy: 'network-only',
                });
                if (res.data?.ProductTypeByPk) {
                    state.productTypeDef = res.data.ProductTypeByPk.definition as ProductTypeDef;
                }
            }
        };
        void _();
    }, [claim.insurerId, claim.productTypeId]);

    useEffect(() => {
        (async () => {
            try {
                ui.formAction = formAction;
                if (claimId) {
                    const { data } = await getClaimByPk({
                        variables: {
                            id: claimId,
                            orgId,
                        },
                        fetchPolicy: 'network-only',
                    });

                    if (data?.ClaimByPk) {
                        errorMessage.show('TODO: Implement claim edit');
                        //state.claim = data?.ClaimByPk;
                    }

                    ui.activeForm = 1;

                    const { data: pdsVersionRes } = await getPdsVersionByInsurerIdAndProductTypeId({
                        variables: {
                            insurerId: claim.insurerId,
                            productTypeId: claim.productTypeId,
                        },
                        fetchPolicy: 'network-only',
                    });

                    service.init(
                        state.claim.formData as unknown as QCard,
                        pdsVersionRes?.PdsVersion?.[0].Questionnaire?.definition,
                    );
                } else {
                    state.statuses = await configService.fetchClaimStatuses({
                        staleTime: 'immediate',
                    });
                    state.decisions = await configService.fetchClaimDecisions({
                        staleTime: 'immediate',
                    });
                }
                ui.initDone = true;
            } catch (e) {
                errorMessage.show(e);
            }
        })();
    }, []);

    const save = async () => {
        claim.formData = service.root ? service.root.toJS() : undefined;
        const files = filesToFileCollections(service.files);
        try {
            ui.isSaving = true;
            const { data } = await upsertClaim({
                variables: {
                    object: {
                        id: claim.id,
                        claimReportedAt: claim.claimReportedAt,
                        claimOccurredAt: claim.claimOccurredAt,
                        phITC: claim.phITC,
                        phCompany: claim.phCompany,
                        phAccountBsb: claim.phAccountBsb,
                        phAccountNo: claim.phAccountNo,
                        phABN: claim.phABN,
                        phBusinessType: claim.phBusinessType,
                        policyNo: claim.policyNo,
                        policyName: claim.policyName,
                        excess: claim.excess,
                        recovery: claim.recovery,
                        recoveryReceived: claim.recoveryReceived,
                        excessReceived: claim.excessReceived,
                        policyStatus: claim.policyStatus,
                        policyOtherInfo: claim.policyOtherInfo,
                        policyTermFrom: claim.policyTermFrom,
                        policyTermTo: claim.policyTermTo,
                        policyIssuedOn: claim.policyIssuedOn,
                        brokerName: claim.brokerName,
                        brokerBranch: claim.brokerBranch,
                        brokerCompany: claim.brokerCompany,
                        phIsRegisteredForGst: claim.phIsRegisteredForGst,
                        externalPolicyData: claim.externalPolicyData,
                        causeOfLoss: (service.getFieldValue('causeOfLoss') || '') as string,
                        formData: claim.formData,
                        pdsConfigItems: claim.pdsConfigItems,
                        notes: claim.notes,
                        jsonData: claim.jsonData,
                        additionalInfo: claim.additionalInfo,
                        decision: claim.decision || state.decisions[0],
                        status: claim.status || state.statuses[0],
                        productTypeId: claim.productTypeId,
                        pdsVersionId: claim.pdsVersionId,
                        contacts: claim.contacts,
                        productTypeData: claim.productTypeData,
                        insurerId: claim.insurerId,
                        ...(state.note && {
                            Notes: {
                                data: [
                                    {
                                        note: state.note,
                                        type: NoteTypeEnum.note,
                                    },
                                ],
                            },
                        }),
                        files,
                        // Files: {
                        //     data: files,
                        //     on_conflict: {
                        //         constraint: File_Constraint.filePkey,
                        //         update_columns: [
                        //             File_Update_Column.id,
                        //             File_Update_Column.description,
                        //             File_Update_Column.key,
                        //             File_Update_Column.mimeType,
                        //             File_Update_Column.name,
                        //             File_Update_Column.categories,
                        //             File_Update_Column.additionalInfo,
                        //             File_Update_Column.internalName,
                        //         ],
                        //     },
                        // },
                    },
                },
            });

            if (data) {
                claim.id = data.insertClaimOne?.id;
                claim.accessKey = data.insertClaimOne?.accessKey;
            }

            ui.isSaved = true;
            ui.setActiveForm(6);
        } catch (e) {
            if (e) {
                errorMessage.show(e);
            }
        } finally {
            ui.isSaving = false;
        }
    };

    return (
        <>
            {ui.activeForm > 0 && (
                <>
                    <div className="notesFloatingIcon">
                        <Tooltip title="Add note">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    ui.displayAddNote = true;
                                }}
                                size="large"
                            />
                        </Tooltip>
                    </div>
                    <AddNote
                        formState={state}
                        open={ui.displayAddNote}
                        readOnly={ui.readOnly}
                        onClose={() => {
                            ui.displayAddNote = false;
                        }}
                    />
                </>
            )}
            <Row justify="center">
                <Col span={22}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            {outsidePolicyTerms && (
                                <Alert
                                    message="Waring"
                                    type="warning"
                                    description="Policy is outside od policy terms."
                                    className="mb-4 pb-4"
                                />
                            )}
                        </Col>
                        <Col span={24}>
                            {ui.isSaving ? (
                                <Card>
                                    <Skeleton active />
                                </Card>
                            ) : (
                                <>
                                    {ui.initDone && (
                                        <>
                                            {ui.activeForm === 0 && (
                                                <PolicyData
                                                    onSave={save}
                                                    state={state}
                                                    goNext={() => {
                                                        service.setQuestionnaireDef(state.questionnaireDef!);
                                                        ui.goToNextForm();
                                                    }}
                                                />
                                            )}
                                            {ui.activeForm === 1 && (
                                                <NarrowLayout>
                                                    <ClaimEvent
                                                        onSave={save}
                                                        state={state}
                                                        goNext={() => {
                                                            ui.goToNextForm();
                                                        }}
                                                        goBack={() => {
                                                            ui.goToPrevForm();
                                                        }}
                                                    />
                                                </NarrowLayout>
                                            )}
                                            {ui.activeForm === 2 && (
                                                <NarrowLayout>
                                                    <ProductDataEntryForm
                                                        claimData={state.claim}
                                                        direction={ui.direction}
                                                        productTypeDef={state.productTypeDef}
                                                        goForward={() => {
                                                            ui.goToNextForm();
                                                        }}
                                                        goBack={() => {
                                                            ui.goToPrevForm();
                                                        }}
                                                    />
                                                </NarrowLayout>
                                            )}
                                            {ui.activeForm === 3 && (
                                                <NarrowLayout>
                                                    <Questionnaire
                                                        onSave={save}
                                                        state={state}
                                                        goBack={() => {
                                                            ui.goToPrevForm();
                                                        }}
                                                        goNext={() => {
                                                            ui.goToNextForm();
                                                        }}
                                                        service={service}
                                                        isPublic={false}
                                                    />
                                                </NarrowLayout>
                                            )}
                                            {ui.activeForm === 4 && (
                                                <NarrowLayout>
                                                    <Conditions
                                                        onSave={save}
                                                        state={state}
                                                        goBack={() => {
                                                            ui.goToPrevForm();
                                                        }}
                                                        goNext={() => {
                                                            ui.goToNextForm();
                                                        }}
                                                    />
                                                </NarrowLayout>
                                            )}
                                            {ui.activeForm === 5 && (
                                                <NarrowLayout>
                                                    <AdditionalInfo
                                                        onSave={save}
                                                        state={state}
                                                        goBack={() => {
                                                            ui.goToPrevForm();
                                                        }}
                                                    />
                                                </NarrowLayout>
                                            )}

                                            {ui.activeForm === 6 && (
                                                <NarrowLayout>
                                                    <Confirmation formState={state} />
                                                </NarrowLayout>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
});

function NarrowLayout({ children }: PropsWithChildren) {
    return (
        <Row justify="center">
            <Col xs={24} sm={16} md={16} lg={14} xl={12}>
                {children}
            </Col>
        </Row>
    );
}

const UpsertClaim = gql(/* GraphQL */ `
    mutation UpsertClaim($object: ClaimInsertInput!) {
        insertClaimOne(
            object: $object
            onConflict: {
                constraint: claims_pkey
                updateColumns: [
                    brokerBranch
                    brokerCompany
                    brokerName
                    claimOccurredAt
                    claimReportedAt
                    externalPolicyData
                    formData
                    phABN
                    phAccountName
                    phAccountBsb
                    phAccountNo
                    phCompany
                    phITC
                    phBusinessType
                    excess
                    recovery
                    excessReceived
                    recoveryReceived
                    policyNo
                    policyStatus
                    policyTermFrom
                    policyTermTo
                    policyIssuedOn
                    policyName
                    causeOfLoss
                    pdsConfigItems
                    phIsRegisteredForGst
                    notes
                    jsonData
                    additionalInfo
                    decision
                    status
                    policyOtherInfo
                ]
            }
        ) {
            id
            accessKey
        }
    }
`);
