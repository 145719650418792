import { useOrgSettingsStore } from '@client/stores/OrgSettingStore';
import * as Sentry from '@sentry/react';
import { SYSTEM_ORG_IDS } from '@shared/definitions/org';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { z } from 'zod';

export const Route = createFileRoute('/public/$orgId')({
    component: PublicOrgSectionLayout,
    params: {
        parse: (params) => {
            return { orgId: z.coerce.bigint().parse(params.orgId) };
        },
    },
});

function PublicOrgSectionLayout() {
    const orgSettingsStore = useOrgSettingsStore();
    const { orgId } = Route.useParams();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const asyncWrapper = async () => {
            if (orgId < SYSTEM_ORG_IDS.FIRST_VALID_ORG_ID) {
                return;
            }

            try {
                await orgSettingsStore.init(orgId);
                setIsInitialized(true);
            } catch (error) {
                Sentry.captureException(error, { tags: { location: 'PublicOrgSection' } });
            }
        };

        void asyncWrapper();
    }, [orgId]);

    return isInitialized ? (
        <Outlet />
    ) : (
        <div className="flex h-full w-full items-center justify-center">
            <Spin spinning size="large" />
        </div>
    );
}
