import { createContext, PropsWithChildren } from 'react';
import { EventEmitter } from '@client/utils/eventEmitter';

type DefaultEvents = Record<string, any>;

export const EventEmitterContext = createContext<EventEmitter<DefaultEvents>>(null as any);

export function EventEmitterProvider({ children }: PropsWithChildren) {
    return <EventEmitterContext.Provider value={new EventEmitter()}>{children}</EventEmitterContext.Provider>;
}
