import { Auth0Client } from '@auth0/auth0-spa-js';
import { message } from '@client/components/Common/message';
import { trpcClient } from '@client/trpc/client';
import { logInDev } from '@client/utils/general';
import { isJwtExpired } from '@client/utils/jwt';
import { createLocalStoragePersister } from '@client/utils/zustand';
import * as Sentry from '@sentry/react';
import { SYSTEM_ORG_IDS } from '@shared/definitions/org';
import { USER_FLAGS } from '@shared/definitions/user';
import { navRoutes } from '@shared/navigation/navRoutes';
import { decodeJwtToken, JwtClaims } from '@shared/utils/jwt';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { AuthStore, AuthStoreOrg } from './types';

const auth0Client = new Auth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}${navRoutes.public_authCallback.path}`,
    },
});

const storage = createLocalStoragePersister<AuthStore>();

export const useAuthStore = create<AuthStore>()(
    persist<AuthStore>(
        (set, get) => ({
            user: undefined,
            org: undefined,
            authTokenExpired: false,

            setApolloClient(apolloClient) {
                set({ apolloClient });
            },

            async login(accessToken) {
                const { setAuthToken, loadUser, loadOrg, apolloClient } = get();
                const claims = decodeJwtToken(accessToken);
                if (!claims) {
                    throw new Error('AuthStore: Claims are undefined.');
                }

                setAuthToken(accessToken);
                if (apolloClient) {
                    apolloClient.resetStore();
                }
                const user = await loadUser(claims);
                const org = await loadOrg(claims);
                set({ user: user, org: org });
                return user;
            },

            async changeOrg(newAccessToken) {
                const { apolloClient, setAuthToken, loadUser, loadOrg } = get();

                if (!apolloClient) {
                    throw new Error('AuthStore: Apollo client is undefined.');
                }

                const claims = decodeJwtToken(newAccessToken);
                if (!claims) {
                    throw new Error('AuthStore: Claims are undefined.');
                }

                setAuthToken(newAccessToken);
                apolloClient.resetStore();
                const user = await loadUser(claims);
                const org = await loadOrg(claims);

                set({ user, org });
            },

            clearAuthToken() {
                set({ accessToken: '' });
            },

            setAuthToken(token) {
                if (!token) {
                    throw new Error('AuthStore: Token is undefined.');
                }

                const { setAuthTokenExpired } = get();

                logInDev('Setting auth token');

                setAuthTokenExpired(false);
                set({ accessToken: token });

                const tokenExpiration = decodeJwtToken(token)?.exp;
                set({ authTokenExpiration: tokenExpiration ? new Date(tokenExpiration * 1000) : undefined });
            },

            getAuthToken: () => get().accessToken,

            setAuthTokenExpired: (value) => {
                set({ authTokenExpired: value });
            },

            setUser(user) {
                set({ user });
            },

            async logout() {
                try {
                    try {
                        // @ts-expect-error This is a ProductFruits type bug
                        window.productFruits.services.destroy();
                    } catch (e) {
                        console.log('Error destroying product fruits', e);
                    }

                    await auth0Client.logout({
                        logoutParams: { returnTo: window.location.origin + navRoutes.public_logOutIn.path },
                    });

                    Sentry.setUser(null);
                } catch (e) {
                    console.error('Error logging out', e);
                } finally {
                    message.destroy();
                }
            },

            // Additional methods and computed properties
            getIsAuthenticated() {
                const { user, accessToken } = get();

                if (!user) {
                    return false;
                }

                if (!accessToken) {
                    return false;
                }

                if (user.data.exp && isJwtExpired(user.data.exp)) {
                    return false;
                }

                return true;
            },

            async loadUser(claims: JwtClaims) {
                const [dbUser, orgs] = await Promise.all([
                    trpcClient.user.getUser.query({
                        id: BigInt(claims['https://curium.app/claims'].userId),
                        orgId: BigInt(claims['https://curium.app/claims'].orgId),
                        select: ['handlingPartyIds'],
                    }),
                    trpcClient.user.getMyOrgs.query(),
                ]);

                const flagConditions = [
                    { condition: dbUser?.isPaymentAdmin, flag: USER_FLAGS.PAYMENT_ADMIN },
                    { condition: dbUser.canViewUnallocatedClaims, flag: USER_FLAGS.CAN_VIEW_UNALLOCATED_CLAIMS },
                ];

                return {
                    data: claims,
                    termsAccepted: dbUser.termsAccepted === true,
                    firstName: dbUser.firstName || '',
                    lastName: dbUser.lastName || '',
                    photoUrl: dbUser.photoUrl || '',
                    signedUpAt: dbUser.createdAt,
                    externalOrgIds: dbUser.handlingPartyIds?.map((el) => Number(el)) || [],
                    isEmployee: dbUser.isEmployee || false,
                    isSupportAccount: dbUser.isSupportAccount || false,
                    canViewUnallocatedClaims: dbUser.canViewUnallocatedClaims || false,
                    canCreatePayment: false,
                    canApprovePayment: false,
                    orgId: dbUser.orgId,
                    id: dbUser.userId,
                    auth0Id: dbUser.auth0Id,
                    email: dbUser.email,
                    isSuperAdmin: dbUser.orgId === SYSTEM_ORG_IDS.SYSTEM_CONSOLE,
                    roles: dbUser.roles,
                    fullName: dbUser.fullName,
                    isOrgAdmin: dbUser.roles.includes('org_admin'),
                    flags: flagConditions.filter(({ condition }) => condition).map(({ flag }) => flag),
                    userOrgs: orgs.map((el) => ({ id: el.id, name: el.name })),
                    isPrivilegedSuperAdmin: dbUser.isPrivilegedSuperAdmin,
                    provider: dbUser.provider,
                    isSsoActivated: dbUser.isSsoActivated,
                    allowMigrationToSso: dbUser.allowMigrationToSso,
                };
            },

            async loadOrg(claims: JwtClaims) {
                if (claims['https://curium.app/claims'].orgId === Number(SYSTEM_ORG_IDS.SYSTEM_CONSOLE)) {
                    return {
                        id: SYSTEM_ORG_IDS.SYSTEM_CONSOLE,
                        name: '',
                        key: '',
                        enabledModules: [],
                    } satisfies AuthStoreOrg;
                }

                const org = await trpcClient.org.getOrg.query({
                    orgId: BigInt(claims['https://curium.app/claims'].orgId),
                });

                if (!org) {
                    throw new Error("AuthStore: Can't load org details.");
                }

                return {
                    id: org.id,
                    name: org.name,
                    key: org.key,
                    enabledModules: org.enabledModules || [],
                } satisfies AuthStoreOrg;
            },

            async reloadUser(accessToken?: string) {
                const { setAuthToken, loadUser, setUser } = get();

                const token = accessToken || get().accessToken;

                if (!token) {
                    throw new Error('AuthStore: Token is undefined.');
                }

                const claims = decodeJwtToken(token);

                if (!claims) {
                    throw new Error('AuthStore: Claims are undefined.');
                }

                if (accessToken) {
                    setAuthToken(accessToken);
                }

                const user = await loadUser(claims);
                setUser(user);
            },

            async getAccessToken(requestedOrgId: bigint | undefined, isLogin: boolean) {
                try {
                    return await auth0Client.getTokenSilently({
                        authorizationParams: {
                            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                            scope: 'openid profile email offline_access',
                            orgId: requestedOrgId,
                            isLogin: isLogin,
                            ignoreCache: true,
                            ...(requestedOrgId === SYSTEM_ORG_IDS.SYSTEM_CONSOLE && { isAdminLogin: true }),
                        },
                        cacheMode: 'off',
                    });
                } catch (e) {
                    if (e instanceof Error && 'error' in e && e.error === 'missing_refresh_token') {
                        message.destroy();
                        message.error({ content: 'Your session has expired. Please log in again.' });
                        window.location.href = `${navRoutes.public_login.path}?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`;
                        setTimeout(() => {
                            message.destroy();
                        }, 5000);
                        return '';
                    } else {
                        throw e;
                    }
                }
            },

            allowMigrationToSso() {
                const { setUser, user } = get();

                if (!user) {
                    throw new Error('AuthStore: User is undefined.');
                }

                setUser({ ...user, allowMigrationToSso: true });
            },
        }),
        {
            name: 'auth',
            storage: storage,
            partialize: (state) =>
                Object.fromEntries(
                    Object.entries(state).filter(([key]) => !['apolloClient', 'allowMigrationToSso'].includes(key)),
                ) as AuthStore,
        },
    ),
);

// Selector functions
export const selectUser = (state: AuthStore) => state.user;
export const selectOrg = (state: AuthStore) => state.org;
