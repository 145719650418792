import { queryClient, trpc, trpcClient } from '@client/trpc/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';

export function TrpcProvider({ children }: PropsWithChildren) {
    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </trpc.Provider>
    );
}
