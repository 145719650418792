import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useOrgId } from '@client/hooks/Org/useOrgId';

export const Route = createFileRoute('/console/$orgId/configuration/risk/')({
    component: RiskConfigurationHome,
});

function RiskConfigurationHome() {
    const orgId = useOrgId();

    return (
        <Navigate
            to="/console/$orgId/configuration/risk/$section"
            params={{
                orgId,
                section: 'appetite',
            }}
        />
    );
}
