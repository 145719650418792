import { Maybe } from '@client/__generated__/graphql';
import { ISSUES } from '@shared/definitions/complaint';
import { Cascader } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import { useEffect, useState } from 'react';

const complaintIssueCascaderOptions = ISSUES.map((v) => {
    return {
        value: v.category,
        label: v.category,
        children:
            v.issues && Array.isArray(v.issues)
                ? v.issues.map((d) => ({
                      value: d.label,
                      label: d.label,
                      children: [],
                  }))
                : [],
    };
});

const cascaderFormat = (value: Maybe<string> | undefined, separator?: string) => {
    return typeof value === 'string' ? value.split(separator || ' - ') : [];
};

interface Props {
    value?: Maybe<string[]> | undefined;
    onChange?: (value: string[]) => void;
    disabled?: boolean;
}

export const ComplaintIssueSelect = ({ value, onChange, ...props }: Props) => {
    const [val, setVal] = useState<Array<string | undefined>>([undefined]);

    const _onChange = (value: Array<string | number | null>, index: number) => {
        val[index] = value && value.length ? value.join(' - ') : '';
        const newVal = val.filter((item) => !!item) as string[];
        onChange?.(newVal);
    };

    useEffect(() => {
        const newVal = (value || []).filter((item) => !!item) as Array<string | undefined>;
        if (newVal.length < 3) {
            newVal.push(undefined);
        }
        setVal(newVal);
    }, [value]);

    return (
        <>
            <Cascader
                allowClear
                suffixIcon={null}
                onClear={() => _onChange([], 0)}
                value={cascaderFormat(val[0])}
                options={complaintIssueCascaderOptions}
                onChange={(value: Array<string | number | null>) => {
                    _onChange(value, 0);
                }}
                displayRender={(label: any) => {
                    return Array.isArray(label) ? label.join(' - ') : label;
                }}
                showSearch={{
                    filter: (inputValue: string, options: DefaultOptionType[]) => {
                        return options.some(
                            (option) =>
                                option.value &&
                                option.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
                        );
                    },
                }}
                placeholder=""
                {...props}
            />
            {val.length > 1 && (
                <Cascader
                    allowClear
                    suffixIcon={null}
                    className="mt-3"
                    onClear={() => _onChange([], 1)}
                    value={cascaderFormat(val[1])}
                    options={complaintIssueCascaderOptions}
                    onChange={(value: Array<string | number | null>) => {
                        _onChange(value, 1);
                    }}
                    displayRender={(label: any) => {
                        return Array.isArray(label) ? label.join(' - ') : label;
                    }}
                    showSearch={{
                        filter: (inputValue: string, options: DefaultOptionType[]) => {
                            return options.some(
                                (option) =>
                                    option.value &&
                                    option.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
                            );
                        },
                    }}
                    placeholder=""
                    {...props}
                />
            )}
            {val.length > 2 && (
                <Cascader
                    allowClear
                    suffixIcon={null}
                    className="mt-3"
                    onClear={() => _onChange([], 2)}
                    value={cascaderFormat(val[2])}
                    options={complaintIssueCascaderOptions}
                    onChange={(value: Array<string | number | null>) => {
                        _onChange(value, 2);
                    }}
                    displayRender={(label: any) => {
                        return Array.isArray(label) ? label.join(' - ') : label;
                    }}
                    showSearch={{
                        filter: (inputValue: string, options: DefaultOptionType[]) => {
                            return options.some(
                                (option) =>
                                    option.value &&
                                    option.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
                            );
                        },
                    }}
                    placeholder=""
                    {...props}
                />
            )}
        </>
    );
};
