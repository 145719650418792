import { RequireAuth } from '@client/components/Auth/RequireAuth';
import { AuthenticatedLayout } from '@client/components/Layout/AuthenticatedLayout';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console')({
    staticData: { superAdminOnly: true },
    component: AdminConsole,
});

function AdminConsole() {
    return (
        <RequireAuth loginUrl="/admin-login">
            <AuthenticatedLayout>
                <Outlet />
            </AuthenticatedLayout>
        </RequireAuth>
    );
}
