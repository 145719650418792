import { useLazyQuery } from '@apollo/client';
import { errorMessage } from '@client/components/Common/errorMessage';
import { PdsVersionByInsurerIdAndProductTypeId } from '@client/graphql/queries/pdsVersion';
import { useFormatter } from '@client/hooks/useFormatter';
import { useTrpcClient } from '@client/hooks/useTrpcClient';
import { useQuestionnaireService } from '@client/services/QuestionnaireService';
import { RouterOutputs } from '@client/trpc/client';
import { formatAddress } from '@client/utils/address';
import { CardBase } from '@shared/model/Card';
import { getPrimaryContactForType } from '@shared/utils/contact';
import { createFileRoute } from '@tanstack/react-router';
import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';

const { Text, Title } = Typography;

const LodgementSummary = () => {
    const formatter = useFormatter();
    const { accessKey } = Route.useParams();
    const formService = useQuestionnaireService();
    const trpcClient = useTrpcClient();
    const { formatDate, formatDateTime } = useFormatter();
    const [pdsVersionByInsurerIdAndProductTypeId] = useLazyQuery(PdsVersionByInsurerIdAndProductTypeId);

    const [claim, setClaim] = useState<RouterOutputs['claim']['getClaimByAccessKey']>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const asyncWrapper = async () => {
            try {
                if (!accessKey) {
                    throw new Error('Access key not found.');
                }

                setLoading(true);

                const claimData = await trpcClient.claim.getClaimByAccessKey.query({
                    accessKey,
                });
                claimData.phAccountNo = null;
                setClaim(claimData);

                const { data: pdsVersionRes } = await pdsVersionByInsurerIdAndProductTypeId({
                    variables: {
                        insurerId: claimData.insurerId,
                        productTypeId: claimData.productTypeId,
                    },
                    fetchPolicy: 'network-only',
                });

                formService.init(
                    claimData.formData as unknown as CardBase,
                    pdsVersionRes?.PdsVersion?.[0].Questionnaire?.definition,
                    false,
                );
            } catch (e) {
                errorMessage.show(e);
            } finally {
                setLoading(false);
            }
        };

        void asyncWrapper();
    }, [accessKey]);

    const brokerContactInfo = useMemo(() => {
        if (claim) {
            return getPrimaryContactForType(claim.contacts, 'broker');
        }
    }, [claim?.contacts]);

    const policyHolderContactInfo = useMemo(() => {
        if (claim) {
            return getPrimaryContactForType(claim.contacts, 'policy_holder');
        }
    }, [claim?.contacts]);

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <>
                    {claim && (
                        <div className="w-1/2 self-center">
                            <Title level={5}>Policy Holder Info</Title>
                            <Row gutter={[16, 8]}>
                                <DataRow
                                    label="Policy Holder"
                                    value={
                                        (policyHolderContactInfo?.firstName || '') +
                                        ' ' +
                                        (policyHolderContactInfo?.lastName || '')
                                    }
                                />
                                <DataRow label="Phone" value={policyHolderContactInfo?.phone} />
                                <DataRow label="E-mail" value={policyHolderContactInfo?.email} />
                            </Row>
                            <Divider />
                            <Title level={5}>Broker Info</Title>
                            <Row gutter={[16, 8]}>
                                <DataRow label="Broker" value={claim.brokerCompany} />
                                <DataRow label="Office" value={brokerContactInfo?.branch} />
                                value=
                                {(brokerContactInfo?.firstName || '') + ' ' + (brokerContactInfo?.lastName || '')}{' '}
                                <DataRow label="Phone" value={brokerContactInfo?.phone} />
                            </Row>
                            <Divider />
                            <Title level={5}>Policy Info</Title>
                            <Row gutter={[16, 8]}>
                                <DataRow label="Policy Name" value={claim.policyName} />
                                <DataRow label="Status" value={claim.policyStatus} />
                                <DataRow
                                    label="Risk Address"
                                    value={formatAddress({
                                        addressLine1: policyHolderContactInfo?.addressLine1,
                                        addressLine2: policyHolderContactInfo?.addressLine2,
                                        suburb: policyHolderContactInfo?.addressSuburb,
                                        city: policyHolderContactInfo?.addressCity,
                                        postcode: policyHolderContactInfo?.phone,
                                        country: policyHolderContactInfo?.addressCountry,
                                    }).join(', ')}
                                />
                                <DataRow label="Policy Start Date" value={formatDateTime(claim.policyTermFrom)} />
                                <DataRow label="Policy End Date" value={formatDateTime(claim.policyTermTo)} />
                                <DataRow label="Policy Issued on" value={formatDate(claim.policyIssuedOn)} />
                            </Row>
                            {formService.getLogicalGroup2Labels().map((label) => (
                                <div key={label}>
                                    <Divider />
                                    <Title level={5}>{label}</Title>
                                    <Row gutter={[16, 8]}>
                                        {formService
                                            .getLogicalGroup2Fields(label, 'titleSystem', formatter)
                                            .map((fld, idx) => (
                                                <DataRow label={fld.label} value={fld.value} key={idx} />
                                            ))}
                                    </Row>
                                </div>
                            ))}
                            <Divider />
                            <Title level={5}>Confirmation</Title>
                            <Row gutter={[16, 8]}>
                                <DataRow
                                    label="The damage claimed is not a result of illegal act"
                                    value={claim.jsonData?.claimNotResultOfIllegalAct === true ? 'Yes' : 'No'}
                                />
                            </Row>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

interface DataRowProps {
    label: React.ReactNode | string;
    value: React.ReactNode | string;
    emptyValue?: string;
    showIfEmpty?: boolean;
}

const DataRow = ({ label, value, emptyValue = '-', showIfEmpty = false }: DataRowProps) => {
    let isEmpty = false;
    if (typeof value === 'string' && value.trim() === '') {
        isEmpty = true;
    } else if (!value) {
        isEmpty = true;
    }

    return (
        <>
            {!isEmpty || showIfEmpty ? (
                <>
                    <Col span={12}>
                        <Text type="secondary">{label}</Text>
                    </Col>
                    <Col span={12}>
                        <div className="text-right">
                            <Text>{value || emptyValue}</Text>
                        </div>
                    </Col>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export const Route = createFileRoute('/public/lodgement-summary/$accessKey')({
    component: LodgementSummary,
});
