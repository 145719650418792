import { errorMessage } from '@client/components/Common/errorMessage';
import { History } from '@client/components/Common/History';
import { message } from '@client/components/Common/message';
import { useNavigate } from '@client/hooks/useNavigate';
import { useTrpc } from '@client/hooks/useTrpc';
import { RouterInputs, trpc } from '@client/trpc/client';
import { RecordNotFoundError } from '@client/utils/error';
import { OBLIGATION_LIBRARY_SECTIONS, ObligationLibrarySection } from '@shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';
import { Skeleton, Tabs } from 'antd';
import { ObligationsLibraryItemEdit } from './ObligationsLibraryItemEdit';
import { obligationsLibraryFormStore } from './store';
import { ObligationLibraryItem } from './types';

type UpdateInput = RouterInputs['admin']['obligationLibrary']['updateObligationLibraryItem']['set'];

export const Route = createFileRoute('/admin-console/obligations/library/$id/$section')({
    staticData: {
        breadcrumb: {
            title: 'Obligation details',
        },
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as ObligationLibrarySection,
            };
        },
    },
    component: ObligationLibraryItemDetails,
});

function ObligationLibraryItemDetails() {
    const { id } = Route.useParams();
    const navigate = useNavigate();
    const { trpcClient } = useTrpc();
    const { mutateAsync: updateObligationLibraryItem } =
        trpc.admin.obligationLibrary.updateObligationLibraryItem.useMutation();
    const { formData: obligation } = obligationsLibraryFormStore.useInit({
        entity: 'obligation',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.loading('Saving...');

                // Separate controlIds from other data
                const { controlIds, ...newData } = newValue as ObligationLibraryItem & { controlIds?: bigint[] };

                // Handle custom labels
                const customLabelUpdates: { [key: string]: string | string[] } = {};
                const regularUpdates: UpdateInput = {};

                Object.keys(newData).forEach((key) => {
                    if (key.startsWith('customLabels.')) {
                        const labelId = key.split('.')[1];
                        customLabelUpdates[labelId] = newData[key as keyof typeof newData] as string | string[];
                    } else {
                        regularUpdates[key as keyof UpdateInput] = newData[key as keyof typeof newData] as any;
                    }
                });

                const updateData: UpdateInput = {
                    ...regularUpdates,
                    customLabels: Object.keys(customLabelUpdates).length
                        ? {
                              ...currentValue.customLabels,
                              ...customLabelUpdates,
                          }
                        : undefined,
                };

                if (controlIds?.length) {
                    updateData.controlIds = controlIds;
                }

                if (Object.keys(updateData).length) {
                    await updateObligationLibraryItem({
                        id: currentValue.id!,
                        set: updateData,
                    });
                }

                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.admin.obligationLibrary.getObligationLibraryItem.query({ id });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e as Error };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync("The obligation you are trying to access either doesn't exist.");
                navigate({ to: '/admin-console/obligations/library' });
                return;
            }

            errorMessage.show(error);
        },
    });

    return obligation && id === obligation.id ? (
        <Tabs
            type="line"
            onTabClick={(key) =>
                navigate({
                    to: '/admin-console/obligations/library/$id/$section',
                    params: {
                        id,
                        section: key as ObligationLibrarySection,
                    },
                })
            }
            items={[
                {
                    key: OBLIGATION_LIBRARY_SECTIONS.DETAILS,
                    label: 'Manage obligation',
                    children: <ObligationsLibraryItemEdit />,
                },
                {
                    key: OBLIGATION_LIBRARY_SECTIONS.HISTORY,
                    label: 'History',
                    children: <History idValue={Number(id)} idPropertyName="obligationLibraryId" />,
                },
            ]}
        />
    ) : (
        <Skeleton active />
    );
}
