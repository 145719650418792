import { useIsOffline } from '@client/hooks/useIsOffline';
import { Modal } from 'antd';

export function OfflineOverlay() {
    const isOffline = useIsOffline();

    return (
        <Modal title="Network Status" open={isOffline} footer={null} closable={false} centered>
            <p>You are currently offline. Please check your internet connection.</p>
        </Modal>
    );
}
