import { DatePicker } from '@client/components/Common/DatePicker';
import { errorMessage } from '@client/components/Common/errorMessage';
import { HandlingPartySelect } from '@client/components/Common/HandlingPartySelect';
import { message } from '@client/components/Common/message';
import { CoiDefs } from '@client/global/conflict';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useFormatter } from '@client/hooks/useFormatter';
import { useNavigate } from '@client/hooks/useNavigate';
import { useSafePath } from '@client/hooks/useSafePath';
import { RouterInputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Divider, Form, Input, InputNumber, Select, Space } from 'antd';

export const Route = createFileRoute('/console/$orgId/compliance/coi/add')({
    staticData: {
        breadcrumb: {
            title: 'New conflict of interest',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: CoiAdd,
});

type ConflictOfInterestInput = RouterInputs['conflictOfInterest']['createConflictOfInterest'];

function CoiAdd() {
    const navigate = useNavigate();
    const orgId = useOrgId();
    const safePath = useSafePath<ConflictOfInterestInput>();
    const { fDateShortDayJs } = useFormatter();
    const { mutateAsync: createConflictOfInterest, isPending } =
        trpc.conflictOfInterest.createConflictOfInterest.useMutation();

    const [form] = Form.useForm<ConflictOfInterestInput>();

    const onFinish = async (values: ConflictOfInterestInput) => {
        try {
            message.loading('Saving...');
            const { id } = await createConflictOfInterest({ ...values });
            message.success('Saved.');

            navigate({
                to: '/console/$orgId/compliance/coi/details/$id/$section',
                params: {
                    id,
                    orgId,
                    section: 'manage',
                },
            });
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };

    return (
        <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            onFinishFailed={(errorInfo) => {
                form.scrollToField(errorInfo.errorFields[0].name);
            }}
        >
            <Card>
                <Divider orientation="left">Personal details</Divider>
                <Form.Item
                    label="First name"
                    name={safePath('firstName')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last name"
                    name={safePath('lastName')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Position" name={safePath('position')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Date advised"
                    name={safePath('dtAdvised')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <DatePicker className="w-full" format={fDateShortDayJs} maxDate={new Date()} />
                </Form.Item>
                <Form.Item
                    label="Severity"
                    name={safePath('severity')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Select
                        options={CoiDefs.arySeverity.map((severity) => ({
                            value: severity,
                        }))}
                    />
                </Form.Item>
                <Divider orientation="left">Conflict of interest</Divider>
                <Form.Item label="Interest disclosed" name={safePath('interestDisclosed')}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Nature of potential conflict" name={safePath('natureOfConflict')}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Party to be notified" name={safePath('partyToBeNotified')}>
                    <Input />
                </Form.Item>
                <Form.Item label="Estimated value" name={safePath('estimatedValue')}>
                    <InputNumber />
                </Form.Item>
                <Divider orientation="left">Action and consequence management</Divider>
                <Form.Item
                    label="Handling parties"
                    name={safePath('handlingPartyIds')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <HandlingPartySelect forAdminShowAllOrgs />
                </Form.Item>
                <Form.Item label="Mechanism chosen to manage the conflict" name={safePath('conflictManagement')}>
                    <Select
                        options={CoiDefs.aryConflictManagement.map((cm) => ({
                            value: cm,
                        }))}
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item label="Solution implemented / Action taken" name={safePath('solutionImplemented')}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Date implemented" name={safePath('dtImplemented')}>
                    <DatePicker className="w-full" format={fDateShortDayJs} maxDate={new Date()} />
                </Form.Item>
                <Form.Item label="Consequence management" name={safePath('consequenceManagement')}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Other comments" name={safePath('comments')}>
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Card>
            <Space className="mt-2 flex justify-end">
                <Button
                    onClick={() => {
                        navigate({
                            to: '/console/$orgId/compliance/coi',
                            params: {
                                orgId,
                            },
                        });
                    }}
                >
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isPending}>
                    Save
                </Button>
            </Space>
        </Form>
    );
}
