import { complaintFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';
import { History } from '@client/components/Common/History';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/details/$id/history')({
    component: ComplaintHistory,
});

function ComplaintHistory() {
    const complaint = complaintFormStore.use.formData();

    return <History idValue={Number(complaint.id)} idPropertyName="complaintId" />;
}
