import { useOrg } from '@client/hooks/Org/useOrg';
import * as Sentry from '@sentry/react';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HomeV1 } from './HomeV1';
import { HomeV2 } from './HomeV2';

export const Route = createFileRoute('/console/$orgId/')({
    staticData: {
        roles: ['user', 'org_admin'],
        restrictToEmployee: false,
    },
    component: Home,
});

function Home() {
    const org = useOrg();
    const { enableHomepageWithTasks } = useFlags();

    Sentry.getCurrentScope().setTransactionName('Home');

    // TODO: This is a temporary solution to redirect to the claims module only home page
    if (org.enabledModules.includes('claim') && org.enabledModules.length === 1) {
        return <Navigate to="/console/$orgId/claim-module-only-home-page" params={{ orgId: org.id }} />;
    }

    if (org.enabledModules.includes('tools') && org.enabledModules.length === 1) {
        return <Navigate to="/console/$orgId/tools-only-home-page" params={{ orgId: org.id }} />;
    }

    return enableHomepageWithTasks ? <HomeV1 /> : <HomeV2 />;
}
