import { message } from '@client/components/Common/message';
import { useIsPublicSection } from '@client/hooks/useIsPublicSection';
import { useNavigate } from '@client/hooks/useNavigate';
import { useAuthStore } from '@client/stores/AuthStore';
import { isAuthRoute } from '@client/utils/auth';
import * as Sentry from '@sentry/react';
import { SYSTEM_ORG_IDS } from '@shared/definitions/org';
import { ErrorComponentProps, useMatch } from '@tanstack/react-router';
import { Button, Result } from 'antd';
import { useEffect } from 'react';
import { z } from 'zod';

export function RouterErrorComponent(props: ErrorComponentProps) {
    const isPublicSection = useIsPublicSection();
    const navigate = useNavigate();

    const match = useMatch({ from: '/console/$orgId', shouldThrow: false });

    const orgIdParseResult = z.bigint().safeParse(match?.params.orgId);

    const { error, reset, info } = props;

    useEffect(() => {
        if (error) {
            Sentry.captureException(error, {
                tags: { location: 'ErrorFallback' },
                level: 'error',
                extra: {
                    orgId: orgIdParseResult.data,
                    isPublicSection,
                    url: window.location.href,
                    userAgent: navigator.userAgent,
                    info,
                },
            });
        }
    }, [error]);

    // TODO: Remove this once we've migrated from Hasura
    //  Ignore errors on auth routes if the token is expired
    // There should be already a notification displayed
    // After migrating from Hasura, this error fallback is no longer needed
    if (useAuthStore.getState().authTokenExpired && isAuthRoute()) {
        return <div />;
    }

    if (error) {
        return (
            <div className="fixed inset-0 flex items-center justify-center">
                <Result
                    className="max-w-(--breakpoint-sm)"
                    status="error"
                    title="Something went wrong"
                    subTitle={
                        <>
                            <p>
                                An unexpected error occurred. Our system administrators have been notified and are
                                looking into it. Please try again or return to the home page.
                            </p>
                            <br />
                            <p className="text-sm text-gray-500">
                                Error details: {error instanceof Error ? error.message : String(error)}
                            </p>
                        </>
                    }
                    extra={[
                        <Button
                            key="retry"
                            type="primary"
                            onClick={() => {
                                message.info('Retrying to recover from error...');
                                reset();
                            }}
                        >
                            Try Again
                        </Button>,
                        <Button
                            key="home"
                            onClick={() => {
                                setTimeout(() => {
                                    reset();
                                }, 2000);

                                message.info('Redirecting...');
                                if (
                                    isPublicSection ||
                                    orgIdParseResult.success === false ||
                                    orgIdParseResult.data < SYSTEM_ORG_IDS.FIRST_VALID_ORG_ID
                                ) {
                                    navigate({ to: '/' });
                                } else {
                                    navigate({ to: '/console/$orgId', params: { orgId: orgIdParseResult.data } });
                                }
                            }}
                        >
                            Back to Home
                        </Button>,
                    ]}
                />
            </div>
        );
    }

    return <div />;
}
