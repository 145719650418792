import { Link } from '@client/components/Common/Link';
import { PageContent } from '@client/components/Layout/PageContent';
import { useAntdTable } from '@client/hooks/Table/useAntdTable';
import { RouterInputs, RouterOutputs, trpc } from '@client/trpc/client';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Input, Table } from 'antd';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/products')({
    staticData: {
        ui: {
            title: 'Products & Services',
            subtitle: 'Manage products and services here.',
        },
    },
    component: Products,
});

type QueryVariables = RouterInputs['admin']['service']['listServices'];
type Service = RouterOutputs['admin']['service']['listServices']['rows'][number];

function Products() {
    const navigate = Route.useNavigate();
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
        orderBy: {
            id: 'desc',
        },
    });

    const { data: services, isLoading } = trpc.admin.service.listServices.useQuery(queryVariables);

    const { tableProps } = useAntdTable<Service>({
        rowKey: 'id',
        data: {
            rows: services?.rows || [],
            total: services?.total || 0,
            loading: isLoading,
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
            },
            {
                dataIndex: 'name',
                title: 'Name',
                sorter: true,
            },
            {
                dataIndex: 'code',
                title: 'Code',
                sorter: true,
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'unitPrice',
                title: 'Unit Price',
                sorter: true,
                render: (value) => `$${Number(value).toFixed(3)}`,
            },
            {
                fixed: 'right',
                align: 'center',
                render: (_, record) => (
                    <Link
                        size="small"
                        to="/admin-console/products/$serviceId"
                        params={{
                            serviceId: record.id,
                        }}
                    >
                        Edit
                    </Link>
                ),
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev: QueryVariables) => ({
                ...prev,
                ...options,
                orderBy: options?.orderBy ?? prev.orderBy,
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    return (
        <PageContent>
            <div className="mb-4 flex items-center justify-start">
                <Input.Search
                    placeholder="Search services"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        if (value) {
                            setQueryVariables({
                                ...queryVariables,
                                where: {
                                    name: { contains: value, mode: 'insensitive' },
                                },
                            });
                        } else {
                            setQueryVariables({
                                ...queryVariables,
                                where: undefined,
                            });
                        }
                    }}
                    className="w-96"
                />
            </div>

            <Card>
                <Table
                    {...tableProps}
                    size="small"
                    onRow={(record) => ({
                        onClick: () => {
                            navigate({
                                to: '/admin-console/products/$serviceId',
                                params: {
                                    serviceId: record.id,
                                },
                            });
                        },
                    })}
                />
            </Card>
        </PageContent>
    );
}
