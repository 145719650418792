import { FlagEdit } from '@client/components/Claim/FlagEdit';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useNavigate } from '@client/hooks/useNavigate';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/manage/flag/add')({
    component: FlagAdd,
});

function FlagAdd() {
    const { id } = Route.useParams();
    const orgId = useOrgId();

    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    return (
        <FlagEdit
            claimId={id}
            open={open}
            onClose={() => {
                setOpen(false);

                navigate({
                    to: '/console/$orgId/claims/details/$id/manage',
                    params: {
                        orgId,
                        id,
                    },
                    fromComponent: 'drawer',
                });
            }}
        />
    );
}
