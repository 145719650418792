import { grey } from '@ant-design/colors';
import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@client/__generated__';
import { FileBoolExp, FileDsQuery } from '@client/__generated__/graphql';
import { InsertFiles } from '@client/graphql/mutations/file';
import { FileDs } from '@client/graphql/queries/file';
import { useOrgId } from '@client/hooks/Org/useOrgId';
import { useUserId } from '@client/hooks/User/useUserId';
import { useSpin } from '@client/hooks/useSpin';
import { useTrpc } from '@client/hooks/useTrpc';
import { CustomRequest } from '@client/types/antd';
import { S3File } from '@shared/types/S3File';
import { fileExtension } from '@shared/utils/file';
import { Button, Popconfirm, Progress, Typography, Upload, theme } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import axios from 'axios';
import mime from 'mime';
import { observer } from 'mobx-react-lite';
import { ReactElement, Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FileIcon } from 'react-file-icon';
import { v4 as uuid } from 'uuid';
import { errorMessage } from '../errorMessage';

const { useToken } = theme;
interface FileExt extends File {
    lastModified: number;
    lastModifiedData: Date;
    name: string;
    size: number;
    type: string;
    uid: string;
}

type Props = {
    onChange?: (files: S3File) => void;
    idPropertyName: 'claimId' | 'complaintId' | 'incidentId' | 'conflictOfInterestId' | 'trainingRecordId';
    idValues?: bigint[];
};

export type FileUploadRef = {
    clearFileList: () => void;
    insertFileList: (idValues: bigint[]) => Promise<void>;
};

/**
 * FileUpload component. This component is used to upload files to the S3 bucket and insert the file into the database.
 * @deprecated Use UploadControlV2 instead
 */
export const FileUpload = observer(
    forwardRef(({ onChange, idPropertyName, idValues }: Props, ref: Ref<FileUploadRef>) => {
        const [fileList, setFileList] = useState<UploadFile[]>([]);
        const [fileListToInsert, setFileListToInsert] = useState<S3File[]>([]);
        const [currentFilename, setCurrentFilename] = useState('');
        const [progress, setProgress] = useState<number>(0);
        const { Spin, setSpinning } = useSpin();
        const userId = useUserId();
        const orgId = useOrgId();
        const [insertFiles] = useMutation(InsertFiles);
        const [fileDs] = useLazyQuery(FileDs, { fetchPolicy: 'network-only' });
        const [deleteFileByPk] = useMutation(DeleteFileByPk);
        const { trpcClient } = useTrpc();
        const { token } = useToken();
        useEffect(() => {
            (async () => {
                if (idValues) {
                    try {
                        setSpinning(true);
                        const where: FileBoolExp = { [idPropertyName]: { _in: idValues } };
                        const { data } = await fileDs({
                            variables: {
                                where,
                                limit: 100,
                                offset: 0,
                            },
                        });
                        const filesList =
                            data?.Files.map((item: FileDsQuery['Files'][number]) => ({
                                fileName: item.internalName!,
                                uid: item.id!,
                                name: item.name!,
                            })) || [];
                        setFileList(filesList);
                    } catch (e) {
                        errorMessage.show(e);
                    } finally {
                        setSpinning(false);
                    }
                }
            })();
        }, []);

        useImperativeHandle<FileUploadRef, any>(ref, () => ({
            clearFileList() {
                setFileList([]);
                setFileListToInsert([]);
            },
            async insertFileList(idValues: bigint[]) {
                const filesToUpload = fileListToInsert.map((s3File: S3File) => ({
                    categories: s3File.categories,
                    key: s3File.key,
                    mimeType: s3File.mimeType,
                    name: s3File.name,
                    description: s3File.description,
                    ...idValues.map((idValue) => ({
                        [idPropertyName]: idValue,
                    }))[0],
                }));

                if (filesToUpload.length) {
                    try {
                        return await insertFiles({
                            variables: {
                                objects: filesToUpload,
                            },
                        });
                    } catch (e) {
                        errorMessage.show(e);
                    }
                }
            },
        }));

        const customRequest: CustomRequest = async (options) => {
            try {
                const file = options.file as FileExt;

                setProgress(1);
                setCurrentFilename(file.name);

                const { url, fields } = await trpcClient.file.getUploadPresignedUrl.mutate({
                    bucketType: 'private',
                    filename: file.name,
                    orgId,
                });

                const formData = new FormData();
                Object.keys(fields).forEach((key) => {
                    formData.append(key, fields[key]);
                });
                formData.append('file', file);
                await axios.post(url, formData, {
                    onUploadProgress: (event) => {
                        const percent = Math.floor((event.loaded / (event.total || 1)) * 100);
                        setProgress(percent);
                        if (percent === 100) {
                            setTimeout(() => setProgress(0), 1000);
                        }
                    },
                });

                setFileList([...fileList, file]);

                const s3File: S3File = {
                    id: uuid(),
                    key: fields['key'] as unknown as string,
                    name: file.name,
                    mimeType: mime.getType(file.name) || '',
                    categories: undefined,
                    description: undefined,
                    createdAt: new Date().toISOString(),
                    uploadedById: Number(userId),
                    size: file.size,
                };

                setFileListToInsert([...fileListToInsert, s3File]);

                onChange?.(s3File);
            } catch (e) {
                errorMessage.show(e);
            } finally {
                setCurrentFilename('');
                setProgress(0);
            }
        };

        const handleRemove: (file: UploadFile) => Promise<void> = async (file: UploadFile) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            const newFileListToInsert = fileListToInsert.slice();

            if (!(file instanceof File)) {
                try {
                    setSpinning(true);
                    await deleteFileByPk({ variables: { id: file.uid } });
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                } catch (e) {
                    errorMessage.show(e);
                } finally {
                    setSpinning(false);
                }
            } else {
                newFileList.splice(index, 1);
                newFileListToInsert.splice(index, 1);
                newFileListToInsert.splice(index, 1);
                setFileList(newFileList);
                setFileListToInsert(newFileListToInsert);
            }
        };
        const itemRender = (originNode: ReactElement, file: UploadFile) => {
            return (
                <div className="mb-2 flex items-center">
                    <div style={{ width: '25px' }} className="mr-2">
                        <FileIcon extension={fileExtension(file.name)} color={token.colorPrimary} />
                    </div>
                    <div className="w-full">{file.name}</div>
                    <Popconfirm
                        title="Are you sure you want to remove this document?"
                        onConfirm={() => handleRemove(file)}
                        okButtonProps={{ danger: true }}
                        okText="Remove"
                    >
                        <Button type="link" size="small">
                            <DeleteFilled style={{ color: grey.primary }} />
                        </Button>
                    </Popconfirm>
                </div>
            );
        };

        return (
            <Spin>
                <Upload
                    fileList={fileList}
                    customRequest={customRequest}
                    defaultFileList={fileList}
                    itemRender={itemRender}
                >
                    <Button icon={<UploadOutlined />} className="mb-3">
                        Upload document
                    </Button>
                    {progress > 0 && (
                        <div className="px-8 pt-4 pb-2">
                            <Progress percent={progress} />
                            <Typography.Text>{currentFilename}</Typography.Text>
                        </div>
                    )}
                </Upload>
            </Spin>
        );
    }),
);

const DeleteFileByPk = gql(/* GraphQL */ `
    mutation DeleteFileByPk($id: uuid = "") {
        updateFileByPk(pkColumns: { id: $id }, _set: { isDeleted: true }) {
            id
        }
    }
`);
